import React, { useState, useTransition } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "redux/store";

import AcWrap from "elements/AcWrap";
import Button from "elements/Button";
import HostInput from "elements/HostInput";
import { inputPriceFormat } from "utils/commaDash";

interface PricingProps {
  onPre?: any;
  onNext?: any;
  pricingData?: any;
}

const AcPricing = ({ onPre, onNext, pricingData }: PricingProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [mon, setMon] = useState({ price: "", disabled: true });
  const [tue, setTue] = useState({ price: "", disabled: true });
  const [wed, setWed] = useState({ price: "", disabled: true });
  const [thu, setThu] = useState({ price: "", disabled: true });
  const [fri, setFri] = useState({ price: "", disabled: true });
  const [sat, setSat] = useState({ price: "", disabled: true });
  const [sun, setSun] = useState({ price: "", disabled: true });

  const preData = useAppSelector((state) => state.space.pricing);

  const page = preData;

  const data = {
    monday: mon.price,
    tuesday: mon.price,
    wednesday: wed.price,
    thursday: thu.price,
    friday: fri.price,
    saturday: sat.price,
    sunday: sun.price,
  };

  const Submit = () => {
    pricingData(data);
  };

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          title="Add-space.Operation & Regular Price*"
          help
          toolTitle="희망 매출을 목표로 운영 및 가격을 설정해주세요"
          toolSubTitle="운영 가능한 요일을 선택하고 가격을 설정해주세요. 이후 캘린더 매니저를 통해서
        세부적인 사항 설정과 수정이 가능합니다.   최근에는 평일 차박러들도 꾸준히
        증가하는 추세이니 운영 설정 시 참고해주세요~!"
        >
          <HostInput
            pricing
            disabled={sun.disabled}
            onChange={(e) => {
              setSun({
                ...sun,
                disabled: !e.target.checked,
              });
            }}
            inputValue={sun.price}
            inputOnChange={(e) => setSun({ ...sun, price: inputPriceFormat(e.target.value) })}
            CheckContent={t("Days-data.Sunday")}
          />
          <HostInput
            onChange={(e) =>
              setMon({
                ...mon,
                disabled: !e.target.checked,
              })
            }
            inputValue={mon.price}
            inputOnChange={(e) => {
              setMon({ ...mon, price: inputPriceFormat(e.target.value) });
            }}
            disabled={mon.disabled}
            pricing
            CheckContent={t("Days-data.Monday")}
          />
          <HostInput
            onChange={(e) =>
              setTue({
                ...tue,
                disabled: !e.target.checked,
              })
            }
            inputValue={tue.price}
            inputOnChange={(e) => {
              setTue({ ...tue, price: inputPriceFormat(e.target.value) });
            }}
            disabled={tue.disabled}
            pricing
            CheckContent={t("Days-data.Tuesday")}
          />
          <HostInput
            onChange={(e) =>
              setWed({
                ...wed,
                disabled: !e.target.checked,
              })
            }
            inputValue={wed.price}
            inputOnChange={(e) => setWed({ ...wed, price: inputPriceFormat(e.target.value) })}
            disabled={wed.disabled}
            pricing
            CheckContent={t("Days-data.Wednesday")}
          />
          <HostInput
            onChange={(e) =>
              setThu({
                ...thu,
                disabled: !e.target.checked,
              })
            }
            inputValue={thu.price}
            inputOnChange={(e) => {
              setThu({ ...thu, price: inputPriceFormat(e.target.value) });
            }}
            disabled={thu.disabled}
            pricing
            CheckContent={t("Days-data.Thursday")}
          />
          <HostInput
            onChange={(e) => setFri({ ...fri, disabled: !e.target.checked })}
            inputOnChange={(e) => {
              setFri({ ...fri, price: inputPriceFormat(e.target.value) });
            }}
            inputValue={fri.price}
            disabled={fri.disabled}
            pricing
            CheckContent={t("Days-data.Friday")}
          />
          <HostInput
          inputValue={sat.price}
            onChange={(e) => setSat({ ...sat, disabled: !e.target.checked })}
            inputOnChange={(e) => {
              setSat({ ...sat, price: inputPriceFormat(e.target.value) });
            }}
            disabled={sat.disabled}
            pricing
            CheckContent={t("Days-data.Saturday")}
          />
        </AcWrap>
        {/* <AcWrap title="Seasonal Rate Setting">
          <div className="text-sm">
            For Seasonal Rate Setting, complete registration of a space with its
            regular price, then go{" "}
            <span className="font-bold">Seasonal Rate</span> under{" "}
            <span className="font-bold">Calendar Manager</span> and set Seasonal
            Rate respectively, in Regualr, Semi-Peak, Peak Season, and Etc
            Title. You can set each season for all registered spaces with its
            price, period all at once
          </div>
        </AcWrap>
        <AcWrap title="Discount Rate Setting">
          <div>
            For Discount Rate Setting, complete registration of a space with its
            regular price, then go{" "}
            <span className="font-bold">Discount Rate</span> under{" "}
            <span className="font-bold">Caneldar Manager</span> and set Discount
            Rate respectively, in Regular and Today’s Hot Deal, and Over 2
            Nights. You can set each space by Discount type with days and
            period.
          </div>
          <div className="mt-4 text-sm">
            <div>
              {" "}
              <span className="text-link font-bold">Regular Discount</span> - is
              setting discount rate by Percentage(%) on certain set period.
              Discounted price will be applied from its original or seasonal
              rate you set by Percentage(%) subtraction.
            </div>
            <div className="my-4">
              <span className="text-link font-bold">Today’s Hot Deal</span> - is
              pre-set discount rate by Percentage(%) for rooms that are not
              reserved on the day. Host can minimize the loss due to vacancy by
              discounting the remaining spaces on the day.
            </div>
            <div>
              <span className="text-link font-bold">Over 2 Nights</span> - is
              applying discount for consecutive 2 nights stay. Discount will be
              applied on whole amount of over 2 nights accommodation price by
              Percentage(%).
            </div>
          </div>
        </AcWrap> */}
        <div className="mt-12 mb-8 justify-between flex">
          <Button
            onClick={() => onPre()}
            btnText={t("Add-space.Previous")}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
          />
          <Button
            isIcon
            src={"register-completed"}
            onClick={() => Submit()}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-space.Register")}
          />
        </div>
      </div>
    </div>
  );
};

export default AcPricing;
