import React, { useEffect, useState } from "react";

import BookingWrap from "components/Booking/bookingWrap";

import Button from "elements/Button";
import DetailInfo from "elements/DetailInfo";
import HostInput from "elements/HostInput";


import { useSelector } from "react-redux";
import { privateData } from "redux/action/private";
import { RootState, useAppDispatch } from "redux/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { changecase, changeSpace } from "utils/changeCase";
import { useTranslation } from "react-i18next";
import { inputPriceComma } from "utils/commaDash";
import { getEveryDiffDay } from "utils/getDiffDay";
import Icons from "elements/Icons";

const BookingRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const state = useLocation();
  const params = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileCode, setMobileCode] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [plateNum, setPlateNum] = useState("");

  const [dayLength, setDayLength] = useState(0);
  const [totalAmount, setTotalAmount] = useState("");

  const [preValue, setPreValue] = useState<any>("");

  const [extra, setExtra] = useState<any>([]);

  const [countryCode, setCountryCode] = useState<any>();

  const _id = params.id;

  const localName = localStorage.getItem("name");
  const localEmail = localStorage.getItem("email");
  const localPhone = localStorage.getItem("phone");
  const memberId = localStorage.getItem("m");
  const localCountyCode: any = localStorage.getItem("CC");

  const dispatch = useAppDispatch();
  const data: any = useSelector<RootState>((state) => state.private);

  useEffect(() => {
    dispatch(privateData(_id ? _id : ""));
  }, [_id]);

  const {
    checkIn,
    checkOut,
    extraOptions,
    unitAmount,
    totalMemberId,
    hasCar,
    totalPrice,
    dayPrice,
  }: any = state.state;

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}guest/main/common/code`,
    }).then((res) => {
      const country = res.data.data.countryCodeMap;
      setCountryCode(country);
    });
  }, []);

  useEffect(() => {
    extraOptions &&
      extraOptions.map((item: any, idx: number) => {
        if (item.amount !== 0) {
          setExtra((prwExtra: any) => [
            ...prwExtra,
            {
              id: item.id,
              name: item.name,
              price: item.price,
              amount: item.amount,
            },
          ]);
        }
      });
  }, [extraOptions]);

  const dataList = data.detailList;
  const {
    title,
    typeInfo,
    currentImage,
    extraPolicy,
    hostInfo,
    advanceDate,
    bookingTime,
    unitCount,
    accessibleVehicle,
    spaceSize,
    policyInfo,
  } = dataList;

  const preExtraValue = [
    {
      amount: extra[0]?.amount,
      id: extra[0]?.id,
      price: extra[0]?.price,
    },
  ];

  const requestPageData = {
    title: title,
    currencyId: 1,
    extraOption: extra,
    mobileCountryCode: mobileCode
      ? mobileCode.replace(/[[a-zA-Z\s]/g, "")
      : localCountyCode,
    price: dayPrice,
    totalPrice: totalPrice,
    unitAmount: unitAmount,
    checkIn: checkIn,
    checkOut: checkOut,
    contactNumber: phoneNum ? phoneNum.replaceAll(/[^0-9]/g, "") : localPhone,
    hasCar: hasCar,
    pgId: Number(_id),
    plateNumber: plateNum,
    totalMemberId: Number(totalMemberId),
    dayLength: dayLength,
    totalAmount: totalAmount,
  };

  const extraPrice = Number(extra[0]?.price) * extra[0]?.amount;

  const privateGroundPrice = Number(totalAmount) - extraPrice;

  const checkData = {
    checkIn: checkIn,
    checkOut: checkOut,
    currencyId: 2,
    currentImage: currentImage?.url,
    extraOption: extra.length !== 0 ? preExtraValue : [],
    hasCar: plateNum ? true : false,
    hostId: hostInfo?.id,
    optionPrice: extra.length !== 0 ? String(extraPrice) : "0",
    pgId: Number(_id),
    pgTitle: title,
    plateNumber: plateNum,
    price: String(extra?.length !== 0 ? privateGroundPrice : totalAmount),
    totalMemberId: Number(memberId),
    totalServerPrice: totalAmount,
    unitAmount: unitAmount,
  };

  const bookingCheck = () => {
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v1/booking/pre-booking",
      data: { ...checkData },
    })
      .then((res) => {
        navigate(`/payment/${_id}`, {
          state: { requestPageData, preChcekData: res.data.data },
          replace: true,
        });
        return res.data.data;
      })
      .catch((err) => {
        const errData = err.response.data;
        if (
          errData.message === "휴대폰 인증 필요한 사용자입니다." &&
          errData.status === 403
        ) {
          alert(errData.message);
          navigate(`/verification/${_id}`, {
            state: { requestPageData, checkData },
            replace: true,
          });
        }
      });
  };

  useEffect(() => {
    const betweenDay = getEveryDiffDay(checkIn, checkOut);

    if (betweenDay !== undefined) {
      const night = betweenDay?.length - 1;
      setDayLength(night);
    }
    setTotalAmount(String(totalPrice));
  }, [checkIn, checkOut, totalPrice]);

  useEffect(() => {
    const preValue = countryCode?.map((item: any) => {
      if (item.replace(/[[a-zA-Z\s]/g, "") === localCountyCode) return item;
    });
    const noUnde = preValue?.filter((preValue: any) => preValue !== undefined);
    setPreValue(noUnde);
  }, [countryCode, localCountyCode]);
  return (
    <div className="lg:max-w-1216 xl:max-w-7xl px-8 mx-auto pb-16">
      <div className="flex text-3.5xl items-center my-9">
        <img
          alt=""
          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/mobile-bookings.svg"
          className="w-12 h-12 mr-6"
        />
        {t("Booking-request.Booking Request")}
      </div>
      <div className="flex">
        <div className="w-[800px]">
          <BookingWrap
            size="M"
            title="Booking-request.Booking Details"
            className="mb-[67px]"
          >
            <div className="w-[full] h-40 mb-8 rounded-lg flex text-left">
              <img
                className="w-66 rounded-lg"
                src={currentImage && currentImage?.url}
                alt=""
              />
              <div className="p-2 ">
                <div className="text-xl font-bold">{title}</div>

                <div className="flex mt-6 h-5">
                  <img
                    src={typeInfo !== undefined ? typeInfo?.iconUrl : ""}
                    className="w-5 h-5 mr-2"
                    alt={typeInfo?.enName}
                  />
                  <div>
                    {t(typeInfo && `Private-Type-data.${typeInfo?.enName}`)}
                  </div>
                </div>
                <div className="flex my-4 h-5">
                  <Icons iconName="available-ground" className="w-5 h-5 mr-2" />
                  <div className="mr-4">X {unitCount}</div>
                  <Icons
                    iconName="accessible-vehicle"
                    className="w-5 h-5 mr-2"
                  />
                  <div>{t(`Parking-accessible-data.${accessibleVehicle}`)}</div>
                </div>
                <div className="flex h-5">
                  <Icons iconName="space-size" className="w-5 h-5 mr-2" />
                  <div>{spaceSize} m²</div>
                </div>
              </div>
            </div>
            <DetailInfo withIcon w276 title="Booking-request.Schedule">
              <div className="w-[272px]">
                <div className="text-grey text-sm">
                  {t("Booking-request.Check in")}
                </div>
                <div className="font-bold">{checkIn}</div>
              </div>
              <div className="w-[213px]">
                <div className="text-grey text-sm">
                  {t("Booking-request.Check out")}
                </div>
                <div className="font-bold">{checkOut}</div>
              </div>
              <div>
                {/* <div className="cursor-pointer text-sm text-link">Edit</div> */}
              </div>
            </DetailInfo>
            <DetailInfo
              nomargin={extra.length === 0}
              withIcon
              w276
              title="Booking-request.Ground Unit"
            >
              <div className="font-bold flex">
                <div className="w-[272px]">{title}</div>
                <div className="w-[131px] mr-[54px]">X {unitAmount}</div>
              </div>
            </DetailInfo>
            {extra.length ? (
              <DetailInfo
                w276
                title="Booking-request.Extra Option(s)"
                className="font-bold"
              >
                {extra &&
                  extra.map((item: any, key: number) => {
                    if (item !== undefined && item.amount !== 0) {
                      return (
                        <div className="flex" key={key}>
                          <div className="w-[272px] mb-2">
                            <div>{item.name}</div>
                          </div>
                          <div className="w-[131px]">
                            <div>X {item.amount}</div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </DetailInfo>
            ) : (
              ""
            )}
          </BookingWrap>
          <BookingWrap
            title="Booking-request.Booking Request By (Traveler)"
            size="M"
            className="mb-16"
          >
            <DetailInfo w276 title="Booking-request.Name*" className="mb-4">
              <HostInput
                input
                inputValue={localName ? localName : ""}
                disabled={localName ? true : false}
                onChange={(e: any) => {
                  setName(e.target.value);
                }}
                className="web:w-[512px]"
                nomargin
              />
            </DetailInfo>
            <DetailInfo
              w276
              title="Booking-request.Email Address*"
              className="mb-4"
            >
              <HostInput
                input
                inputValue={localEmail ? localEmail : ""}
                disabled={localEmail ? true : false}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                className="web:w-[512px]"
                nomargin
              />
            </DetailInfo>
            <DetailInfo
              w276
              title="Booking-request.Contact Number*"
              className="mb-4"
            >
              <HostInput
                select
                selectTrans="Country-code"
                placeholder="AS-Host-info.Select Country Code"
                selected={mobileCode ? mobileCode : preValue}
                onChange={(e: any) => {
                  setMobileCode(e.target.value);
                }}
                options={countryCode}
                className="web:w-[160px] mr-[15px]"
                nomargin
              />
              <HostInput
                placeholder="Booking-request.Contact Number"
                input
                type="text"
                disabled={phoneNum !== "undefined" ? false : true}
                inputValue={phoneNum !== "undefined" ? phoneNum : ""}
                onChange={(e: any) => {
                  setPhoneNum(e.target.value);
                }}
                className="web:w-[335px]"
                nomargin
              />
            </DetailInfo>
            {/* <DetailInfo w276 title="Preferred Language*" className="mb-4">
              <HostInput select className="w-[160px] mr-[15px]" nomargin />
              <HostInput select className="w-[160px] mr-[15px]" nomargin />
              <HostInput select className="w-[160px]" nomargin />
            </DetailInfo> */}
            <DetailInfo w276 title="Booking-request.Plate Number" nomargin>
              <HostInput
                input
                onChange={(e: any) => {
                  setPlateNum(e.target.value);
                }}
                className="web:w-[512px]"
                nomargin
              />
            </DetailInfo>
          </BookingWrap>
          <BookingWrap title="Booking-request.Policy" size="M">
            <div className="w-full rounded-lg border-input-text p-2 bg-[#eee] mb-8">
              <div className="flex mb-2">
                <Icons iconName="warning-yellow" className="mr-2" />
                {t("Booking-request.Notice")}
              </div>
              <div className="text-sm">
                {t(
                  `Booking-request.MUST OUTDOOR will enforce following cancellation policy`
                )}
              </div>
            </div>
            <DetailInfo withIcon w276 title="Booking-request.Check In / Out">
              <div className="mr-[141px]">
                <div>
                  {t("Booking-request.Check in")} :{" "}
                  {bookingTime && bookingTime.checkIn}
                </div>
              </div>
              <div>
                <div>
                  {t("Booking-request.Check out")} :{" "}
                  {bookingTime && bookingTime.checkOut}
                </div>
              </div>
            </DetailInfo>
            <DetailInfo withIcon w276 title="Booking-request.Confirmation">
              <div className="mb-2">
                {t(
                  "Confirmation-data.You will receive booking confirmation-hours",
                  { confirm: "24" }
                )}
              </div>
              <div>
                {t(
                  "Confirmation-data.In the event that you do not receive an email from us"
                )}
              </div>
            </DetailInfo>
            <DetailInfo withIcon w276 title="Booking-request.Advance Notice">
              {advanceDate
                ? t("Advance-notice-data.Booking is not available today")
                : t("Advance-notice-data.Booking is available today")}
            </DetailInfo>
            <DetailInfo
              withIcon
              w276
              title="Booking-request.Additional Rule / Policy"
            >
              {extraPolicy ? extraPolicy : <div>{t("common.N/A")}</div>}
            </DetailInfo>
            <DetailInfo
              withIcon
              w276
              nomargin
              noFlex
              title="Booking-request.Cancellation & Refund Policy"
            >
              <div className="font-bold mb-4 flex">
                <div className="text-pure-red">
                  {t(
                    policyInfo &&
                      `Cancellation-policy-data.${changecase(policyInfo.name)}`
                  )}{" "}
                </div>
                <div>{t("Booking-request.Policy on Cancellation")}</div>
              </div>
              <div>
                {policyInfo?.firstRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}100% refund of the payment",
                      { days: policyInfo?.firstRange }
                    ) + "\n"
                  : ""}
                {policyInfo?.secondRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}70% refund of the payment",
                      { days: policyInfo?.secondRange }
                    ) + "\n"
                  : ""}
                {policyInfo?.thirdRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}50% refund of the payment",
                      { days: policyInfo?.thirdRange }
                    ) + "\n"
                  : ""}
                {policyInfo?.fourthRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}30% refund of the payment",
                      { days: policyInfo?.fourthRange }
                    ) + "\n"
                  : ""}
                {policyInfo?.fifthRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}0% refund of the payment",
                      { days: policyInfo?.fifthRange }
                    )
                  : ""}
              </div>
            </DetailInfo>
          </BookingWrap>
        </div>
        <div className="w-80 ml-8">
          <BookingWrap
            size="S"
            title="Booking-request.Payment Details"
            className="mb-6"
          >
            <div className="mb-4">
              <div className="font-bold mb-4">
                {t("Booking-request.Ground Charge")}
              </div>
              <div className="mb-4">
                <div className="flex justify-between text-sm">
                  <div className="max-w-[150px]">{title}</div>
                  <div>
                    {inputPriceComma(
                      String(
                        extra.length !== 0
                          ? Number(totalAmount) - Number(extraPrice)
                          : totalAmount
                      )
                    )}￦
                  </div>
                </div>
                <div className="text-xs text-grey">
                  {unitAmount} ground unit x {dayLength} night
                </div>
              </div>
              <hr className="text-input-text" />
            </div>
            {extra.length ? (
              <div className="mb-4">
                <div className="font-bold mb-4">
                  {t("Booking-request.Extra Option(s)")}
                </div>
                {extra &&
                  extra.map((item: any, key: number) => {
                    if (item !== undefined && item.amount !== 0) {
                      let priceNum = Number(item.price);
                      return (
                        <div className="mb-2" key={key}>
                          <div>
                            <div className="flex justify-between text-sm">
                              <div>{item.name}</div>
                              <div>
                                {inputPriceComma(
                                  String(priceNum * item.amount)
                                )}￦
                              </div>
                            </div>
                          </div>
                          <div className="text-xs text-grey">
                            {inputPriceComma(String(priceNum))} x {item.amount}￦
                          </div>
                        </div>
                      );
                    }
                  })}
                <hr className="text-input-text" />
              </div>
            ) : (
              ""
            )}
            <div className="flex justify-between font-bold">
              <div>{t("Booking-request.Total Amount")}</div>
              <div>{inputPriceComma(totalPrice)}￦</div>
            </div>
          </BookingWrap>
          <Button
            onClick={() => {
              bookingCheck();
            }}
            className="w-full font-bold rounded-lg bg-light-green text-pure-white text-center py-3"
            btnText={"Booking-request.NEXT"}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingRequest;
