import React, { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import BookingWrap from "components/Booking/bookingWrap";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { changecase, changeSpace } from "utils/changeCase";
import DetailInfo from "elements/DetailInfo";
import Button from "elements/Button";
import Modal from "elements/Modal";
import { useTranslation } from "react-i18next";
import { getEveryDiffDay } from "utils/getDiffDay";
import { changeDate } from "utils/changeDate";
import MoblieHeader from "components/moblieHeader";
import Icons from "elements/Icons";
import NewModal from "elements/newModal";

const HostDetailView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [cancelReservation, setCancelReservation] = useState(false);
  const [cancelMessage, setCancelMessage] = useState("");
  const location: any = useLocation();
  const [pageData, setPageData] = useState<any>([]);
  const [active, setActive] = useState<number[]>([]);

  const [cancelRequestModal, setCancelRequestModal] = useState(false);

  // Booking Status
  // CANCELLED_BY_ADMIN : 어드민(머스트아웃도어)가 취소 (전액환불) => cancelled Booking
  // CANCELLED_BY_HOST : 호스트가 취소 (전액환불) => cancelled Booking
  // CANCELLED_BY_TRAVELER : 여행자가 취소 (환불정책에 따라 취소) => cancelled Booking
  // COMPLETED : 체크아웃 +1일 => booking history
  // CONFIRMED : 체크인 전 [호스트 수락/즉시예약시] => Current Booking
  // DECLINED : 호스트 거절 => cancelled Booking
  // PAYMENT_ERROR : 결제에러 (전액환불) => 안띄움
  // PENDING : 호스트 응답 대기 => Current Booking
  // REQUEST_CANCELLED : 게스트가 Pending 일 때 취소요청 => cancelled Booking

  const { reserveId, pgId } = location?.state;

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/booking/fetch/${pgId}/${reserveId}`,
    })
      .then((res) => {
        setPageData(res.data.data);
        return res.data;
      })
      .catch((err) => {});
  }, [pgId, reserveId]);

  const {
    pgCurrentImage,
    pgTitle,
    typeIcon,
    typeEnName,
    pgUnitAmount,
    pgAccessibleVehicle,
    pgSpaceSize,
    reservationCode,
    amount,
    checkInTime,
    checkOutTime,
    bookingOptions,
    policyName,
    plateNumber,
    travelerMobile,
    travelerMobileCode,
    travelerLanguage,
    price,
    travelerName,
    travelerImage,

    hostInfo,
    id,
    reservationCreatedAt,
    status,
    checkIn,
    checkOut,
    totalPrice,
  } = pageData;

  const isAcceptModalOpen = () => {
    setAcceptModalOpen((acceptModalOpen) => !acceptModalOpen);
  };
  const isDeclineModalOpen = () => {
    setDeclineModalOpen((declineModalOpen) => !declineModalOpen);
  };

  const decline = () => {
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v2/booking/response",
      data: { accepted: false, reservationId: reserveId },
    })
      .then((res) => {
        navigate("/host/book");

        return res;
      })
      .catch((err) => {});
  };
  const accept = () => {
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v2/booking/response",
      data: { accepted: true, reservationId: reserveId },
    })
      .then((res) => {
        navigate("/host/book");
        return res;
      })
      .catch((err) => {});
  };

  const areYouSureModal = () => {
    setCancel((cancel) => !cancel);
    setCancelReservation(false);
  };
  const cancelReservationModal = () => {
    setCancel(false);
    setCancelReservation(true);
  };

  const night = getEveryDiffDay(checkIn, checkOut);

  const now = new Date();

  const cancelBooking = () => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/booking/cancel/${reserveId}?message=${cancelMessage}`,
    })
      .then((res) => {
        setCancelReservation(false);
        setCancelRequestModal(true);
        return res.data.data;
      })
      .catch((err) => {});
  };

  return (
    <div>
      {cancelRequestModal ? (
        <NewModal
          imageUrl="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-red.svg"
          onClick={() => {
            setCancelRequestModal(false);
          }}
          setModalOpen={() => {
            setCancelRequestModal(!cancelRequestModal);
          }}
          title="Booking-box-status.Host requested to cancel reservation"
          subTitle="Booking-box-status.Please wait for the response from the Support Team of admin."
          buttonText={t("Booking-box-status.Done")}
          buttonColor={`bg-pure-white text-black`}
        ></NewModal>
      ) : (
        ""
      )}

      {declineModalOpen ? (
        <Modal className="p-8">
          <img
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-cancelled-void.svg"
            className="w-32 h-32 mx-auto"
            alt=""
          />
          <div className="font-bold mt-8 mb-4">
            {t("Booking-box-status.Decline this booking request?")}
          </div>
          <div className="text-sm mb-8 text-border-black">
            {t("Booking-box-status.Once you decline this booking request")}
          </div>
          <div className="flex justify-between w-full">
            <Button
              onClick={() => decline()}
              className="text-pure-red rounded-lg w-39 h-10 justify-center text-sm "
              btnText={"Decline"}
              isIcon
              src={"status-cancelled-void"}
              iconClassName="mr-2 fill-pure-red"
            />
            <Button
              onClick={() => isDeclineModalOpen()}
              className="items-center flex rounded-lg w-39 h-10 justify-center text-sm "
              btnText={"Cancel"}
            />
          </div>
        </Modal>
      ) : (
        ""
      )}
      {acceptModalOpen ? (
        <Modal className="p-8">
          <img
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-confirmed-approve.svg"
            className="mx-auto w-32 h-32"
            alt=""
          />
          <div className="font-bold mt-8 mb-4">
            {t("Booking-box-status.Accept this booking request?")}
          </div>
          <div className="text-sm mb-8 text-border-black">
            {t("Booking-box-status.Once you accept this booking request")}
          </div>
          <div className="flex justify-between w-full">
            <Button
              onClick={() => accept()}
              className="text-pure-white bg-light-green rounded-lg w-39 h-10 justify-center text-sm "
              btnText={"Booking-box-status.Accept"}
              isIcon
              src={"status-confirmed-approve"}
              iconClassName="mr-2 fill-pure-white"
            />
            <Button
              onClick={() => isAcceptModalOpen()}
              className="items-center flex rounded-lg w-39 h-10 justify-center text-sm "
              btnText={"Booking-box-status.Cancel"}
            />
          </div>
        </Modal>
      ) : (
        ""
      )}
      <div className="p-8 mx-auto lg:max-w-1216 phone:hidden web:block">
        {status === "CONFIRMED" ? (
          <div className="p-4 mb-8 w-full bg-light-green rounded-lg text-pure-white flex items-center">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-confirmed-approve.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="text-2xl font-bold">
                {t("Booking-status-data.Booking request is confirmed")}
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Now welcome your traveler and let them explore outdoor life. Make sure to check Booking # when traveler check-in."
                )}
              </div>
            </div>
          </div>
        ) : status === "PENDING" ? (
          <div className="p-4 mb-8 w-full bg-pending rounded-lg text-pure-white flex items-center">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/pending.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="text-2xl font-bold">
                {t("Booking-status-data.Respond to booking request")}
              </div>
              <div className="text-sm">
                {t("Booking-box-status.Booking request from is as follows", {
                  Traveler: travelerName,
                })}
              </div>
            </div>
          </div>
        ) : status === "REQUEST_CANCELLED" ||
          status === "CANCELLED_BY_TRAVELER" ||
          status === "CANCELLED_BY_HOST" ||
          status === "CANCELLED_BY_ADMIN" ? (
          <div className="p-4 mb-8 w-full bg-pure-white shadow-btn rounded-lg flex items-center">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-cancel-request.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="text-2xl font-bold text-pure-red">
                {t("Booking-status-data.Booking is cancelled")}
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Traveler requested cancellation of a booking."
                )}
              </div>
            </div>
          </div>
        ) : status === "DECLINED" ? (
          <div className="p-4 mb-8 w-full bg-pure-white shadow-btn rounded-lg flex items-center">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-cancelled-void.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="text-2xl font-bold text-pure-red">
                {t("Booking-status-data.Booking request is declined")}
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.You didn't accept traveler’s booking request."
                )}
              </div>
            </div>
          </div>
        ) : status === "PAYMENT_ERROR" ? (
          <div className="p-4 mb-8 w-full bg-pure-white shadow-btn rounded-lg flex items-center">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-failed.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="text-2xl font-bold text-pure-red">
                {t("Booking-status-data.Booking request is failed")}
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.You did not respond to traveler’s booking request, and turned into failed."
                )}
              </div>
            </div>
          </div>
        ) : status === "COMPLETED" ? (
          <div className="p-4 mb-8 w-full shadow-btn rounded-lg flex items-center">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/checked-in.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="text-2xl font-bold">
                {t("Booking-status-data.Completed Booking")}
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Traveler completed an outdoor life. Check traveler’s experience in the review."
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="flex">
          <div className="w-[800px]">
            {status === "PENDING" ? (
              <BookingWrap
                size="M"
                title="Booking-box-status.Respond to booking request"
                pending
                className="mb-8"
              >
                <div className="flex mb-8">
                  <Icons className="w-5 mr-2" iconName="booking-box" />
                  <div>
                    {t(
                      "Booking-box-status.Booking request from is as follows",
                      {
                        Traveler: travelerName,
                      }
                    )}
                  </div>
                </div>
                <div className="flex mb-8">
                  <Icons className="w-5 mr-2" iconName="pending" />
                  <div>
                    {t(
                      "Booking-box-status.Please respond to this booking request before it expires in 24 hours."
                    )}
                  </div>
                </div>
                <div className="flex justify-between">
                  <Button
                    btnText={"Booking-box-status.Accept"}
                    onClick={() => {
                      isAcceptModalOpen();
                    }}
                    className={
                      "rounded-lg w-39 h-10 justify-center text-sm bg-light-green items-center text-pure-white"
                    }
                    isIcon
                    src={"status-confirmed-approve"}
                    iconClassName="w-4 h-4 fill-pure-white"
                  />
                  <Button
                    btnText={"Booking-box-status.Decline"}
                    onClick={() => {
                      isDeclineModalOpen();
                    }}
                    className={
                      "rounded-lg w-39 h-10 justify-center text-sm items-center text-pure-red"
                    }
                    isIcon
                    src={"status-cancelled-void"}
                    iconClassName="w-4 h-4 fill-pure-red"
                  />
                </div>
              </BookingWrap>
            ) : (
              ""
            )}
            <BookingWrap
              size="M"
              title="Booking-box-status.Booking Details"
              className="mb-8"
            >
              <div className="w-[full] h-40 mb-8 rounded-lg flex text-left">
                <img className="w-66  rounded-lg" src={pgCurrentImage} alt="" />
                <div className="p-2 ">
                  <div className="text-xl font-bold max-w-[415px] text-ellipsis overflow-hidden whitespace-nowrap">
                    {pgTitle}
                  </div>

                  <div className="flex items-center mt-6 h-5">
                    <img alt="" src={typeIcon} className="w-5 h-5 mr-2" />
                    <div>{t(`Private-Type-data.${typeEnName}`)}</div>
                  </div>
                  <div className="flex my-4 h-5">
                    <img
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-ground.svg"
                      className="w-5 h-5 mr-2"
                    />
                    <div className="mr-4">X {pgUnitAmount}</div>
                    <img
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                      className="w-5 h-5 mr-2"
                    />
                    <div>
                      {t(`Parking-accessible-data.${pgAccessibleVehicle}`)}
                    </div>
                  </div>
                  <div className="flex h-5">
                    <img
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                      className="w-5 h-5 mr-2"
                    />
                    <div>{pgSpaceSize} m²</div>
                  </div>
                </div>
              </div>
              <DetailInfo withIcon w276 title="Booking-box-status.Booking #">
                <div className="font-bold">{reservationCode}</div>
              </DetailInfo>
              <DetailInfo withIcon w276 title="Booking-box-status.Schedule">
                <div className="w-[272px]">
                  <div className="text-grey text-sm">
                    {t("Booking-box-status.Check-in")}
                  </div>
                  <div className="font-bold">{checkIn}</div>
                </div>
                <div className="w-[213px]">
                  <div className="text-grey text-sm">
                    {t("Booking-box-status.Check-out")}
                  </div>
                  <div className="font-bold">{checkOut}</div>
                </div>
                <div></div>
              </DetailInfo>
              <DetailInfo
                nomargin={bookingOptions?.length === 0}
                withIcon
                w276
                title="Booking-box-status.Ground Unit"
              >
                <div className="font-bold flex">
                  <div className="w-[272px]">{pgTitle}</div>
                  <div className="w-[131px]">X {amount}</div>
                </div>
              </DetailInfo>
              {bookingOptions?.length ? (
                <DetailInfo
                  w276
                  title="Booking-box-status.Extra Option(s)"
                  className="font-bold"
                >
                  {bookingOptions?.map((item: any, key: number) => {
                    const bookingInfo = item?.pgExtraOptionId;
                    if (
                      bookingInfo?.paymentMethod === "Available In Extra Option"
                    ) {
                      return (
                        <div className="flex" key={key}>
                          <div className="w-[272px] mb-2">
                            <div>{bookingInfo?.title}</div>
                          </div>
                          <div className="w-[131px]">
                            <div>X {item.amount}</div>
                          </div>
                        </div>
                      );
                    } else {
                      return <div>-</div>;
                    }
                  })}
                </DetailInfo>
              ) : (
                ""
              )}
            </BookingWrap>

            <BookingWrap size="M" title="Booking-box-status.Policy">
              <div className="w-full mb-8 border-input-text border bg-[#eeeeee] rounded-lg p-2">
                <div className="flex font-bold items-start">
                  <Icons iconName={"warning-yellow"} className="mr-2 w-5 h-5" />
                  <div>{t("Booking-box-status.Check-in")}</div>
                </div>
                <div className="text-sm">
                  {t(
                    "Booking-box-status.MUST OUTDOOR will enforce following cancellation policy"
                  )}
                </div>
              </div>
              <div className="mb-8">
                <div className="font-bold mb-4">
                  {t("Booking-box-status.Check In / Out")}
                </div>
                <div className="flex">
                  <div className="w-[224px]">
                    {t("Booking-box-status.Check-in")} : {checkInTime}
                  </div>
                  <div className="w-[224px]">
                    {t("Booking-box-status.Check-out")} : {checkOutTime}
                  </div>
                </div>
              </div>
              <div>
                <div className="font-bold mb-4 relative flex justify-between">
                  <div className="flex justify-between items-center w-full">
                    <div>
                      {t("Booking-box-status.Cancellation & Refund Policy")}
                    </div>
                    {status === "CONFIRMED" ? (
                      <div
                        onClick={() => areYouSureModal()}
                        className="text-pure-red text-sm font-bold cursor-pointer"
                      >
                        {t("Booking-box-status.Cancel this booking")} {">"}
                      </div>
                    ) : (
                      ""
                    )}
                    {cancel ? (
                      <div
                        className="absolute -right-[370px] -top-[106px] w-[320px] h-[202px] shadow-btn rounded-lg p-4 text-center"
                        style={{ lineHeight: "normal" }}
                      >
                        <div className="font-bold">
                          {t(
                            "Booking-box-status.Are you sure \nyou want to cancel this booking?"
                          )}
                        </div>
                        <div className="text-sm my-4">
                          {t(
                            "Booking-box-status.Host penalty may apply.\nTravelers may claim damages from the host for a cancellation of the reservation."
                          )}
                        </div>
                        <hr />
                        <div
                          className="flex justify-between items-center text-sm mt-4"
                          onClick={() => {
                            setCancel(false);
                          }}
                        >
                          <div className="cursor-pointer">
                            {t("Booking-box-status.Cancel")}
                          </div>
                          <div
                            onClick={() => cancelReservationModal()}
                            className="px-4 py-2 rounded-lg border-red border text-red font-bold cursor-pointer"
                          >
                            {t("Booking-box-status.Next")}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="font-bold mb-4 flex">
                  <div className="text-pure-red">
                    {t(
                      policyName &&
                        `Cancellation-policy-data.${changecase(policyName)}`
                    )}{" "}
                  </div>
                  <div>{t("Booking-box-status.Policy on Cancellation")}</div>
                </div>
              </div>
            </BookingWrap>
          </div>
          <div className="ml-8 w-80 h-full">
            <BookingWrap
              size="S"
              title="Booking-box-status.Booking Request By (Traveler)"
              className="mb-8"
            >
              <div className="flex mb-4">
                {travelerImage ? (
                  <img
                    src={`${travelerImage}`}
                    alt=""
                    className="w-12 h-12 rounded-lg"
                  />
                ) : (
                  <img
                    className="w-12 h-12"
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg"
                    alt=""
                  />
                )}
                <div className="text-left ml-4">
                  <div className="inline-block text-input-text">
                    {t("Booking-box-status.Nickname")}
                  </div>
                  <div className="font-bold">{travelerName}</div>
                </div>
              </div>
              <div className="flex justify-between text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Preferred Language")}
                </div>
                <div className="font-bold">
                  {travelerLanguage ? travelerLanguage : "-"}
                </div>
              </div>
              <div className="flex justify-between text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Plate Number")}
                </div>
                <div className="font-bold">
                  {plateNumber ? plateNumber : "-"}
                </div>
              </div>

              <div className="flex justify-between text-sm">
                <div className="text-input-text">
                  {t("Booking-box-status.Contact Number")}
                </div>
                <div className="font-bold">
                  {travelerMobileCode && travelerMobile
                    ? travelerMobileCode + " " + travelerMobile
                    : "-"}
                </div>
              </div>
            </BookingWrap>
            <BookingWrap
              size="S"
              title="Booking-box-status.Payment Details"
              className="mb-8"
            >
              {status === "REQUEST_CANCELLED" ||
              status === "CANCELLED_BY_TRAVELER" ||
              status === "CANCELLED_BY_HOST" ||
              status === "CANCELLED_BY_ADMIN"
                ? ""
                : status === "DECLINED"
                ? ""
                : status === "PAYMENT_ERROR"
                ? ""
                : ""}
              <div className="mb-4">
                <div className="font-bold mb-4">
                  {t("Booking-box-status.Ground Charge")}
                </div>
                <div className="mb-4">
                  <div className="flex justify-between text-sm">
                    <div className="max-w-[150px]">{pgTitle}</div>
                    <div>$ {Number(price) * amount}</div>
                  </div>
                  <div className="text-xs text-grey">
                    1 ground unit x {night && night?.length - 1} night
                  </div>
                </div>
                <hr className="text-input-text" />
              </div>
              {bookingOptions?.length ? (
                <div className="mb-4">
                  <div className="font-bold mb-4">
                    {t("Booking-box-status.Extra Option(s)")}
                  </div>
                  {bookingOptions?.map((item: any, key: number) => {
                    const bookingInfo = item?.pgExtraOptionId;
                    if (
                      bookingInfo?.paymentMethod === "Available In Extra Option"
                    ) {
                      let priceNum = Number(bookingInfo.price);
                      return (
                        <div className="mb-2" key={key}>
                          <div>
                            <div className="flex justify-between text-sm">
                              <div>{bookingInfo.title}</div>
                              <div>{priceNum * item.amount}</div>
                            </div>
                          </div>
                          <div className="text-xs text-grey">
                            {priceNum} x {item.amount}
                          </div>
                        </div>
                      );
                    }
                  })}
                  <hr className="text-input-text" />
                </div>
              ) : (
                ""
              )}
              <div className="flex justify-between font-bold">
                <div>{t("Booking-box-status.Total Amount")}</div>
                <div>{totalPrice}</div>
              </div>
            </BookingWrap>
            {!cancel && cancelReservation ? (
              <BookingWrap
                title={"Booking-box-status.Cancel Reservation"}
                cancel
              >
                <div className="font-bold">
                  {t("Booking-box-status.Send a request to cancel reservation")}
                </div>
                <div className="text-input-text my-4 text-sm">
                  {t(
                    "Booking-box-status.Traveler will get a full refund. This request will be delivered to the Support Team of admin."
                  )}
                </div>
                <hr className="mb-4" />
                <div className="flex justify-between items-center text-sm mb-4">
                  <div className="text-input-text">
                    {t("Booking-box-status.Date")}
                  </div>
                  <div className="font-bold">{changeDate(now)}</div>
                </div>
                <div className="flex justify-between items-center text-sm mb-4">
                  <div className="text-input-text">
                    {t("Booking-box-status.Booking #")}
                  </div>
                  <div className="font-bold">{reservationCode}</div>
                </div>
                <div className="flex justify-between items-center text-sm mb-4">
                  <div className="text-input-text">
                    {t("Booking-box-status.Host penalty")}
                  </div>
                  <div className="font-bold text-pure-red">
                    {t("Booking-box-status.Calendar block")}
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm mb-4">
                  <div className="text-input-text">
                    {t("Booking-box-status.Missed earning")}
                  </div>
                  <div className="font-bold">{totalPrice}</div>
                </div>
                <div>
                  <div className="text-input-text text-sm">
                    {t("Booking-box-status.Reason for cancellation")}
                  </div>
                  <textarea
                    onChange={(e) => [setCancelMessage(e.target.value)]}
                    rows={2}
                    className={"p-2 text-sm w-full my-2 rounded-lg"}
                    placeholder={t(
                      "Booking-box-status.Please write a reason for cancellation."
                    )}
                  />
                </div>
                <div className="text-xs text-input-text mb-8">
                  {t(
                    "Booking-box-status.Please remind that sending a request is NOT a confirmed cancellation"
                  )}
                </div>
                <div
                  className="flex justify-between items-center text-sm"
                  onClick={() => {}}
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => setCancelReservation(false)}
                  >
                    {t("Booking-box-status.Cancel")}
                  </div>
                  <div
                    onClick={() => {
                      cancelBooking();
                    }}
                    className="px-4 py-2 rounded-lg border-red border text-red font-bold cursor-pointer"
                  >
                    {t("Booking-box-status.Cancel Reservation")}
                  </div>
                </div>
              </BookingWrap>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* 모바일 */}
      <div className="phone:block web:hidden">
        {cancel ? (
          <div
            className="rounded-lg w-[328px] fixed top-2/4 left-2/4 text-center -translate-y-[50%] bg-pure-white shadow-btn p-4 -translate-x-[50%]"
            style={{ lineHeight: "normal" }}
          >
            <div className="font-bold">
              {t(
                "Booking-box-status.Are you sure \nyou want to cancel this booking?"
              )}
            </div>
            <div className="text-sm my-4">
              {t(
                "Booking-box-status.Host penalty may apply.\nTravelers may claim damages from the host for a cancellation of the reservation."
              )}
            </div>
            <hr />
            <div
              className="flex justify-between items-center text-sm mt-4"
              onClick={() => {
                setCancel(false);
              }}
            >
              <div className="cursor-pointer">
                {t("Booking-box-status.Cancel")}
              </div>
              <div
                onClick={() => cancelReservationModal()}
                className="px-4 py-2 rounded-lg border-red border text-red font-bold cursor-pointer"
              >
                {t("Booking-box-status.Next")}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {!cancel && cancelReservation ? (
          <div className="rounded-lg w-[328px] fixed top-2/4 left-2/4 text-center -translate-y-[50%] bg-pure-white shadow-btn -translate-x-[50%]">
            <div className="w-full text-left text-pure-white px-4 py-3 bg-pure-red rounded-t-lg">
              {t("Booking-box-status.Cancel Reservation")}
            </div>
            <div className="p-4">
              <div className="font-bold">
                {t("Booking-box-status.Send a request to cancel reservation")}
              </div>
              <div className="text-input-text my-4 text-sm">
                {t(
                  "Booking-box-status.Traveler will get a full refund. This request will be delivered to the Support Team of admin."
                )}
              </div>
              <hr className="mb-4" />
              <div className="flex justify-between items-center text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Date")}
                </div>
                <div className="font-bold">{changeDate(now)}</div>
              </div>
              <div className="flex justify-between items-center text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Booking #")}
                </div>
                <div className="font-bold">{reservationCode}</div>
              </div>
              <div className="flex justify-between items-center text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Host penalty")}
                </div>
                <div className="font-bold text-pure-red">
                  {t("Booking-box-status.Calendar block")}
                </div>
              </div>
              <div className="flex justify-between items-center text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Missed earning")}
                </div>
                <div className="font-bold">{totalPrice}</div>
              </div>
              <div>
                <div className="text-input-text text-sm">
                  {t("Booking-box-status.Reason for cancellation")}
                </div>
                <textarea
                  onChange={(e) => [setCancelMessage(e.target.value)]}
                  rows={2}
                  className={"p-2 text-sm w-full my-2 rounded-lg"}
                  placeholder={t(
                    "Booking-box-status.Please write a reason for cancellation."
                  )}
                />
              </div>
              <div className="text-xs text-input-text mb-8">
                {t(
                  "Booking-box-status.Please remind that sending a request is NOT a confirmed cancellation"
                )}
              </div>
              <div
                className="flex justify-between items-center text-sm"
                onClick={() => {}}
              >
                <div
                  className="cursor-pointer"
                  onClick={() => setCancelReservation(false)}
                >
                  {t("Booking-box-status.Cancel")}
                </div>
                <div
                  onClick={() => {
                    cancelBooking();
                  }}
                  className="px-4 py-2 rounded-lg border-red border text-red font-bold cursor-pointer"
                >
                  {t("Booking-box-status.Cancel Reservation")}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <MoblieHeader left center="Details" />
        <div className="p-4">
          {status === "CONFIRMED" ? (
            <div className="bg-light-green rounded-lg text-pure-white p-2">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-confirmed-approve.svg"
                  alt=""
                />
                <div className="text-xl font-bold">
                  {t("Booking-status-data.Booking request is confirmed")}
                </div>
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Now welcome your traveler and let them explore outdoor life. Make sure to check Booking # when traveler check-in."
                )}
              </div>
            </div>
          ) : status === "PENDING" ? (
            <div className="bg-tan rounded-lg text-pure-white p-2">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/pending.svg"
                  alt=""
                />
                <div className="text-xl font-bold">
                  {t("Booking-status-data.Respond to booking request")}
                </div>
              </div>
              <div className="text-sm">
                {t("Booking-box-status.Booking request from is as follows", {
                  Traveler: travelerName,
                })}
              </div>
            </div>
          ) : status === "REQUEST_CANCELLED" ||
            status === "CANCELLED_BY_TRAVELER" ||
            status === "CANCELLED_BY_HOST" ||
            status === "CANCELLED_BY_ADMIN" ? (
            <div className="rounded-lg shadow-btn p-2">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-cancel-request.svg"
                  alt=""
                />
                <div className="text-xl font-bold text-pure-red">
                  {t("Booking-status-data.Booking is cancelled")}
                </div>
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Traveler requested cancellation of a booking."
                )}
              </div>
            </div>
          ) : status === "DECLINED" ? (
            <div className="shadow-btn rounded-lg p-2">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-cancelled-void.svg"
                  alt=""
                />
                <div className="text-xl font-bold text-pure-red">
                  {t("Booking-status-data.Booking request is declined")}
                </div>
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.You didn't accept traveler’s booking request."
                )}
              </div>
            </div>
          ) : status === "PAYMENT_ERROR" ? (
            <div className="shadow-btn rounded-lg p-2">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-failed.svg"
                  alt=""
                />
                <div className="text-xl font-bold text-pure-red">
                  {t("Booking-status-data.Booking request is failed")}
                </div>
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.You did not respond to traveler’s booking request, and turned into failed."
                )}
              </div>
            </div>
          ) : status === "COMPLETED" ? (
            <div className="rounded-lg p-2 shadow-btn">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/checked-in.svg"
                  alt=""
                />
                <div className="text-xl font-bold">
                  {t("Booking-status-data.Completed Booking")}
                </div>
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Traveler completed an outdoor life. Check traveler’s experience in the review."
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {status === "PENDING" ? (
            <div className="w-full rounded-lg mt-4 mb-8 border-input-text border">
              <div className="bg-tan text-center rounded-t-lg py-3 text-pure-white">
                {t("Booking-box-status.Respond to booking request")}
              </div>
              <div className="p-4">
                <div className="flex items-center mb-8">
                  <img
                    className="w-5 h-3 mr-2"
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-box.svg"
                    alt=""
                  />
                  <div>
                    {t(
                      "Booking-box-status.Booking request from is as follows",
                      {
                        Traveler: travelerName,
                      }
                    )}
                  </div>
                </div>
                <div className="flex items-center">
                  <img
                    className="w-4 h-4 mr-2"
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/pending.svg"
                    alt=""
                  />
                  <div className="text-sm">
                    {t(
                      "Booking-box-status.Please respond to this booking request before it expires in 24 hours."
                    )}
                  </div>
                </div>
                <div className="flex justify-between mt-4">
                  <Button
                    btnText={"Booking-box-status.Accept"}
                    onClick={() => {
                      isAcceptModalOpen();
                    }}
                    className="py-3 rounded-lg w-[140px] bg-light-green text-pure-white"
                  />
                  <Button
                  onClick={() => {
                    isDeclineModalOpen();
                  }}
                    btnText={"Booking-box-status.Decline"}
                    className="py-3 rounded-lg w-[140px] text-pure-red"
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="w-full">
            <div className="flex justify-between my-4">
              <div className="font-bold text-main-green">
                {t("Booking-box-status.Booking Details")}
              </div>
              <div
                className="flex items-center"
                onClick={() => {
                  !active.includes(0)
                    ? setActive((active) => [...active, 0])
                    : setActive(active.filter((x) => x !== 0));
                }}
              >
                <div className="mr-2 text-sm">
                  {t("Booking-box-status.Details")}
                </div>
                <img
                  className={`${
                    active.includes(0) ? "rotate-180" : ""
                  } w-3 h-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
            </div>
            <div>
              <img
                src={pgCurrentImage ? pgCurrentImage : ""}
                className="rounded-lg w-full max-h-[192px]"
                alt=""
              />
              <div className="font-bold text-main-green mt-2">{pgTitle}</div>
              {active.includes(0) ? (
                <div className="pt-4">
                  <div className="flex items-center mb-2">
                    <img alt="" src={typeIcon} className="w-5 h-5 mr-2" />
                    <div>{t(`Private-Type-data.${typeEnName}`)}</div>
                  </div>
                  <div className="flex items-center mb-2">
                    <img
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-ground.svg"
                      className="w-5 h-5 mr-2"
                    />
                    <div className="mr-4">X {pgUnitAmount}</div>
                  </div>
                  <div className="flex items-center mb-2">
                    <img
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                      className="w-5 h-5 mr-2"
                    />
                    <div>
                      {t(`Parking-accessible-data.${pgAccessibleVehicle}`)}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <img
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                      className="w-5 h-5 mr-2"
                    />
                    <div>{pgSpaceSize} m²</div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <hr className="text-border-light my-4" />
              <div className="flex justify-between">
                <div className="font-bold">
                  {t("Booking-box-status.Booking #")}
                </div>
                <div>
                  <div>{reservationCode}</div>
                  <div className="text-right text-xs text-input-text">
                    {changeDate(reservationCreatedAt)}
                  </div>
                </div>
              </div>
              <hr className="text-border-light my-4" />
              <div>
                <div className="font-bold mb-2">
                  {t("Booking-box-status.Schedule")}
                </div>
                <div className="flex justify-between items-center text-sm">
                  <div>{t("Booking-box-status.Check-in")}</div>
                  <div className="font-bold">{checkIn}</div>
                </div>
                <div className="flex justify-between items-center text-sm">
                  <div>{t("Booking-box-status.Check-out")}</div>
                  <div className="font-bold">{checkOut}</div>
                </div>
              </div>
              <hr className="text-border-light my-4" />
              <div>
                <div className="font-bold mb-2">
                  {t("Booking-box-status.Ground Unit")}
                </div>
                <div className="flex justify-between items-center text-sm">
                  <div className="max-w-[150px]">{pgTitle}</div>
                  <div className="font-bold">X {amount}</div>
                </div>
              </div>
              <hr className="text-border-light my-4" />
              {active.includes(0) && bookingOptions?.length !== 0 ? (
                <div>
                  <div className="font-bold mb-2">
                    {t("Booking-box-status.Extra Option(s)")}
                  </div>
                  <div className="flex justify-between items-center text-sm">
                    <div>{bookingOptions[0]?.pgExtraOptionId?.title}</div>
                    <div className="font-bold">
                      X {bookingOptions[0]?.amount}
                    </div>
                  </div>
                  <hr className="text-border-light my-4" />
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <div
                onClick={() => {
                  !active.includes(1)
                    ? setActive((active) => [...active, 1])
                    : setActive(active.filter((x) => x !== 1));
                }}
                className="flex justify-between items-center"
              >
                <div className="font-bold text-main-green">
                  {t("Booking-box-status.Booking Request By (Traveler)")}
                </div>
                <img
                  className={`${
                    active.includes(1) ? "rotate-180" : ""
                  } w-3 h-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              <div className="flex mt-4">
                <img
                  className="w-12 h-12 mr-4"
                  src={
                    travelerImage
                      ? travelerImage
                      : "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg"
                  }
                  alt=""
                />
                <div className="flex flex-col justify-between">
                  <div className="text-input-text">
                    {t("Booking-box-status.Nickname")}
                  </div>
                  <div className="font-bold">{travelerName}</div>
                </div>
              </div>
              {active.includes(1) ? (
                <div>
                  <div className="flex justify-between items-center text-sm my-4">
                    <div>{t("Booking-box-status.Contact Number")}</div>
                    <div className="font-bold">
                      {travelerMobileCode && travelerMobile
                        ? travelerMobileCode + " " + travelerMobile
                        : "-"}
                    </div>
                  </div>
                  <div className="flex justify-between items-center text-sm mb-4">
                    <div>{t("Booking-box-status.Preferred Language")}</div>
                    <div className="font-bold">
                      {travelerLanguage ? travelerLanguage : "-"}
                    </div>
                  </div>
                  <div className="flex justify-between items-center text-sm">
                    <div>{t("Booking-box-status.Plate Number")}</div>
                    <div className="font-bold">
                      {plateNumber ? plateNumber : "-"}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <hr className="text-border-light my-4" />
            </div>

            <div>
              <div
                className="flex justify-between items-center"
                onClick={() => {
                  !active.includes(2)
                    ? setActive((active) => [...active, 2])
                    : setActive(active.filter((x) => x !== 2));
                }}
              >
                <div className="font-bold text-main-green">
                  {t("Booking-box-status.Payment Details")}
                </div>
                <div className="flex items-center">
                  <div className="mr-2">{totalPrice}</div>
                  <img
                    className={`${
                      active.includes(2) ? "rotate-180" : ""
                    } w-3 h-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
              {active.includes(2) ? (
                <div>
                  <div className="mt-4 font-bold">Ground Charge</div>
                  <div className="flex justify-between items-center text-sm mt-2">
                    <div className="max-w-[150px]">
                      <div>{pgTitle}</div>
                    </div>
                    <div>{Number(price) * amount}</div>
                  </div>
                  <div className="text-xs text-input-text mt-1">
                    1 ground unit x {night && night?.length - 1} night
                  </div>
                  <hr className="text-border-light my-4" />

                  {bookingOptions?.length ? (
                    <div className="mb-4">
                      <div className="font-bold mb-4">
                        {t("Booking-box-status.Extra Option(s)")}
                      </div>
                      {bookingOptions?.map((item: any, key: number) => {
                        const bookingInfo = item?.pgExtraOptionId;
                        if (
                          bookingInfo?.paymentMethod ===
                          "Available In Extra Option"
                        ) {
                          let priceNum = Number(bookingInfo.price);
                          return (
                            <div className="mb-2" key={key}>
                              <div>
                                <div className="flex justify-between text-sm">
                                  <div>{bookingInfo.title}</div>
                                  <div>$ {priceNum * item.amount}</div>
                                </div>
                              </div>
                              <div className="text-xs text-grey">
                                $ {priceNum} x {item.amount}
                              </div>
                            </div>
                          );
                        }
                      })}
                      <hr className="text-border-light my-4" />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex justify-between items-center font-bold">
                    <div>{t("Booking-box-status.Total Amount")}</div>
                    <div>{totalPrice}</div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <hr className="text-border-light my-4" />
            </div>
            <div>
              <div className="flex justify-between my-4">
                <div className="font-bold text-main-green">
                  {t("Booking-box-status.Policy")}
                </div>
              </div>
              <div className="w-full mb-4 border-input-text border bg-[#eeeeee] rounded-lg p-2">
                <div className="flex font-bold items-start mb-2">
                  <img
                    className="mr-2 w-5 h-5"
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                    alt=""
                  />
                  <div className="text-sm font-bold">
                    {t("Booking-box-status.Notice")}
                  </div>
                </div>
                <div className="text-xs">
                  {t(
                    "Booking-box-status.MUST OUTDOOR will enforce following cancellation policy"
                  )}
                </div>
              </div>
              <div>
                <div className="font-bold mb-2">
                  {t("Booking-box-status.Check In / Out")}
                </div>
                <div className="flex justify-between items-center text-sm">
                  <div>{t("Booking-box-status.Check-in")}</div>
                  <div className="font-bold">{checkInTime}</div>
                </div>
                <div className="flex justify-between items-center text-sm">
                  <div>{t("Booking-box-status.Check-out")}</div>
                  <div className="font-bold">{checkOutTime}</div>
                </div>
              </div>
              <hr className="text-border-light my-4" />
              <div>{t("Booking-box-status.Cancellation & Refund Policy")}</div>
              <div className="font-bold flex mt-2">
                <div className="text-pure-red">
                  {t(
                    policyName &&
                      `Cancellation-policy-data.${changecase(policyName)}`
                  )}{" "}
                </div>
                <div>{t("Booking-box-status.Policy on Cancellation")}</div>
              </div>
              {status === "CONFIRMED" ? (
                <div
                  onClick={() => areYouSureModal()}
                  className="cursor-pointer text-pure-red text-sm mt-4 inline-block font-bold"
                >
                  {t("Booking-box-status.Cancel this booking")} {">"}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostDetailView;
