import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import HostWrap from "components/Host/hostWrap";

import Button from "elements/Button";

import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";

import CheckBoxDefault from "elements/CheckBoxDefault";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import HostInput from "elements/HostInput";

interface editGroundData {
  id?: number;
  facilities?: [] | any;
  activities?: [] | any;
  prohibitedList?: [] | any;
  allowedList?: [] | any;
  specialFacility?: string;
}

const EditGroundDetail = ({ id, groundEditData }: editGroundData | any) => {
  const { t } = useTranslation();
  const hostNum = localStorage.getItem("hostNum");

  const [facilityList, setFacilityList] = useState<any>([]);
  const [activityList, setActivityList] = useState<any>([]);
  const [allowedList, setAllowedList] = useState<any>([]);
  const [prohibitList, setProhibitList] = useState<any>([]);

  const [FacilityIcon, setFacilityIcons] = useState<any>([]);
  const [ActivityIcon, setActivityIcons] = useState<any>([]);
  const [AllowedIcons, setAllowedIcons] = useState<any>([]);
  const [ProhibitedIcons, setProhibitedIcons] = useState<any>([]);

  const [specialFacility, setSpecialFacility] = useState("");

  const currentData = {
    facilityIds: facilityList,
    activityIds: activityList,
    allowedIds: allowedList,
    prohibitedIds: prohibitList,
    specialFacility: TextareaReplaceNewLine(specialFacility),
  };

  
  const Facility = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setFacilityList([...facilityList, result]);
    } else if (!checked) {
      const result = Number(item);
      setFacilityList(facilityList.filter((el: any) => el !== result));
    }
  };

  const Activities = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setActivityList([...activityList, result]);
    } else if (!checked) {
      const result = Number(item);
      setActivityList(activityList.filter((el: any) => el !== result));
    }
  };

  const AllowedDetails = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setAllowedList([...allowedList, result]);
    } else if (!checked) {
      const result = Number(item);
      setAllowedList(allowedList.filter((el: any) => el !== result));
    }
  };

  const ProhibitedDetails = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setProhibitList([...prohibitList, result]);
    } else if (!checked) {
      const result = Number(item);
      setProhibitList(prohibitList.filter((el: any) => el !== result));
    }
  };
  const {
    preFacilities,
    preActivities,
    preProhibitedList,
    preAllowedList,
    preSpecialFacility,
  } = groundEditData;

  console.log(groundEditData)

  const preFacValue = preFacilities?.map((item: any) => {
    const value = item.facilityList;
    return value?.id;
  });
  const preActValue = preActivities?.map((item: any) => {
    const value = item.activity;
    return value?.id;
  });
  const preAllValue = preAllowedList?.map((item: any) => {
    const value = item.allowedList;
    return value?.id;
  });
  const preProValue = preProhibitedList?.map((item: any) => {
    const value = item.allowedList;
    return value?.id;
  });

  useEffect(() => {
    if (preFacilities) {
      setFacilityList([...preFacValue]);
    }
    if (preActivities) {
      setActivityList([...preActValue]);
    }
    if (preAllowedList) {
      setAllowedList([...preAllValue]);
    }
    if (preProhibitedList) {
      setProhibitList([...preProValue]);
    }
    setSpecialFacility(preSpecialFacility);
  }, [groundEditData]);

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/private-ground/fetch/icons`,
    })
      .then((res) => {
        const data = res.data.data;
        setFacilityIcons(data.facilities);
        setActivityIcons(data.activities);
        data.restricts.map((item: any) => {
          if (item.allowed === true) {
            setAllowedIcons((pre: any) => [...pre, item]);
          } else {
            setProhibitedIcons((pre: any) => [...pre, item]);
          }
        });
      })
      .catch((err) => {});
  }, []);

  const modiDeta = () => {
    requestToMustOutdoor({
      method: "PATCH",
      url: `/api/v2/private-ground/${id}`,
      data: { ...currentData, hostId: hostNum },
    })
      .then((res) => {
        window.location.reload();
        return res;
      })
      .catch((err) => {});
  };

  return (
    <div>
      <div className="web:flex phone:block">
        <div className="web:w-160 web:mx-0 phone:mx-4">
          <div className="text-left">
            <HostWrap
              help
              host
              title="Add-private-ground.Facility"
              toolTitle="함께 이용 가능한 시설을 알려주세요."
              toolSubTitle="공유지 내 편의시설이 있다면 게스트의 만족도를 더욱 높일 수 있습니다. 나열된 리스트 외 공유지만의 특별한 시설이 있다면 아래 ‘추가 시설’ 섹션에서 정보를 제공해주세요."
            >
              <div
                className={`web:flex web:flex-wrap web:flex-row phone:flex-col text-sm mt-4`}
              >
                {FacilityIcon?.map((item: any, idx: number) => {
                  const iconUrl = item.iconUrl;
                  const enName = item.enName;
                  const id = item.id;
                  if (preFacValue) {
                    return (
                      <CheckBoxDefault
                        key={idx}
                        id={id}
                        imageUrl={iconUrl}
                        defaultChecked={
                          preFacValue?.includes(id) === true ? true : false
                        }
                        title={`Facility-data.${enName}`}
                        value={enName}
                        onChange={(e: any) => {
                          Facility(e.target.checked, e.target.id);
                        }}
                      />
                    );
                  }
                })}
              </div>
            </HostWrap>
            <HostWrap
              help
              host
              title="Add-private-ground.Activities"
              toolTitle="함께 즐길 수 있는 액티비티가 있나요?"
              toolSubTitle="농촌, 갯벌체험 등과 같은 체험활동이나 지역 음료, 술, 먹거리 관련 사업을 운영하고 있다면 게스트들에게 서비스를 제공하고 수익 창출과 홍보효과를 기대할 수 있습니다."
            >
              <div
                className={`web:flex web:flex-wrap web:flex-row phone:flex-col text-sm mt-4`}
              >
                {ActivityIcon?.map((item: any, idx: number) => {
                  const iconUrl = item.iconUrl;
                  const enName = item.enName;
                  const id = item.id;
                  if (preActValue) {
                    return (
                      <CheckBoxDefault
                        key={idx}
                        id={id}
                        imageUrl={iconUrl}
                        defaultChecked={
                          preActValue?.includes(id) === true ? true : false
                        }
                        title={`Activity-data.${enName}`}
                        value={enName}
                        onChange={(e: any) => {
                          Activities(e.target.checked, e.target.id);
                        }}
                      />
                    );
                  }
                })}
              </div>
            </HostWrap>
            <HostWrap
              help
              host
              title="Add-private-ground.Allowed Details"
              toolTitle="공유지 내 캠핑 활동이 가능한가요?"
              toolSubTitle={
                "- 게스트가 야외 공간 이용 시 가능한 캠핑활동이 있다면 설정해주세요. 이를 통해 게스트들의 아웃도어 라이프 경험을 더욱 만족시키고 더 많은 방문자들을 기대할 수 있습니다.\n- 가격정책 섹션에서 유료 옵션 사항으로 제공하여 매출을 상승시킬 수 있습니다. Ex) 텐트피칭/2만원/현장결제"
              }
            >
              <div
                className={`web:flex web:flex-wrap web:flex-row phone:flex-col text-sm mt-4`}
              >
                {AllowedIcons?.map((item: any, idx: number) => {
                  const iconUrl = item.iconUrl;
                  const enName = item.enName;
                  const id = item.id;
                  if (preAllValue) {
                    return (
                      <CheckBoxDefault
                        key={idx}
                        id={id}
                        imageUrl={iconUrl}
                        defaultChecked={
                          preAllValue?.includes(id) === true ? true : false
                        }
                        title={`Allowed-data.${enName}`}
                        value={enName}
                        onChange={(e: any) => {
                          AllowedDetails(e.target.checked, e.target.id);
                        }}
                      />
                    );
                  }
                })}
              </div>
            </HostWrap>
            <HostWrap
              help
              host
              title="Add-private-ground.Prohibited Details"
              toolTitle="안전보다 중요한 것은 없습니다!"
              toolSubTitle="기본적으로 야외에서는 비화식 활동이 권장되고 있습니다. 물론, 호스트님이 공유하는 야외 공간의 특성이나 운영방식을 고려하여 허용 사항과 금지 사항을 자율적으로 설정할 수 있습니다."
            >
              <div
                className={`web:flex web:flex-wrap web:flex-row phone:flex-col text-sm mt-4`}
              >
                {ProhibitedIcons?.map((item: any, idx: number) => {
                  const iconUrl = item.iconUrl;
                  const enName = item.enName;
                  const id = item.id;
                  if (preProValue) {
                    return (
                      <CheckBoxDefault
                        key={idx}
                        id={id}
                        imageUrl={iconUrl}
                        defaultChecked={
                          preProValue?.includes(id) === true ? true : false
                        }
                        title={`Prohibited-data.${enName}`}
                        value={enName}
                        onChange={(e: any) =>
                          ProhibitedDetails(e.target.checked, e.target.id)
                        }
                      />
                    );
                  }
                })}
              </div>
            </HostWrap>
            <HostWrap
              help
              host
              toolTitle="별도의 시설이 있다면 추가해주세요."
              toolSubTitle="공유하는 야외 공간만의 특별한 시설이나 이용 방법 등 추가 내용을 입력해주세요."
              toolEx="Ex) 주변 공중 화장실이나 공공시설에 대한 정보 제공은 게스트 편의에 큰 도움이 됩니다. 5. Pricing (가격 정책)"
              title={"Add-private-ground.Special Facility"}
            >
              <HostInput
                inputLengthPosition="right-0"
                inputMaxLength={500}
                inputValue={
                  preSpecialFacility ? preSpecialFacility : specialFacility
                }
                onChange={(e: any) => setSpecialFacility(e.target.value)}
                textarea
                size="XL"
                rows={8}
                placeholder="Add-private-ground.Please describe the special facility for travelers."
              />
            </HostWrap>
          </div>
          <div className="mt-12 mb-8 justify-end flex">
            <Button
              onClick={() => {
                if (window.confirm("정말 수정하시겠어요?")) {
                  modiDeta();
                }
              }}
              btnText={"SAVE"}
              className="px-4 py-3 rounded-lg border border-input-border-color text-pure-white bg-tan"
            ></Button>
          </div>
        </div>
        <div className="w-full ml-4"></div>
      </div>
    </div>
  );
};

export default EditGroundDetail;
