import HostWrap from "components/Host/hostWrap";
import Button from "elements/Button";
import HostInput from "elements/HostInput";
import { Payment } from "pages/Register/constants";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";

interface pricingEditData {
  priceInfo?: string;
  extraOption?: [] | any;
  id?: number;
}
interface InputItem {
  id: number;
  title: string;
  price: string;
  amount: number;
  maxOfNumber: string;
  paymentMethod: null | string;
  description: string;
}

const EditPricing = ({ id, pricingEditData }: pricingEditData | any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const nextID = useRef<number>(1);
  const hostNum = localStorage.getItem("hostNum");
  const [extraOption, setInputItem] = useState<InputItem[]>([
    {
      id: 0,
      amount: 0,
      title: "",
      price: "",
      maxOfNumber: "",
      paymentMethod: "",
      description: "",
    },
  ]);

  const { prePriceInfo, preExtraOption } = pricingEditData;

  console.log(pricingEditData)

  const preExtra = preExtraOption !== undefined ? preExtraOption : "";

  const [mon, setMon] = useState({ price: "", disabled: true });
  const [tue, setTue] = useState({ price: "", disabled: true });
  const [wed, setWed] = useState({ price: "", disabled: true });
  const [thu, setThu] = useState({ price: "", disabled: true });
  const [fri, setFri] = useState({ price: "", disabled: true });
  const [sat, setSat] = useState({ price: "", disabled: true });
  const [sun, setSun] = useState({ price: "", disabled: true });

  const addInput = () => {
    const input = {
      id: nextID.current,
      title: "",
      price: "",
      amount: 0,
      maxOfNumber: "",
      paymentMethod: null,
      description: "",
    };
    if (nextID.current === 10) {
      return alert("최대 10개까지 가능힙나다.");
    }
    setInputItem([...extraOption, input]);
    nextID.current += 1;
  };
  const deleteInput = (idx: number) => {
    setInputItem(extraOption.filter((item) => item.id !== idx));
    nextID.current -= 1;
  };

  const handleTitle = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    if (idx > extraOption.length) return;

    const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(extraOption));
    inputItemCopy[idx].title = e.target.value;
    setInputItem(inputItemCopy);
  };

  const handlePrice = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    if (idx > extraOption.length) return;

    const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(extraOption));
    inputItemCopy[idx].price = e.target.value;
    setInputItem(inputItemCopy);
  };

  const handlemaxOfNum = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (idx > extraOption.length) return;

    const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(extraOption));
    inputItemCopy[idx].maxOfNumber = String(e.target.value);
    setInputItem(inputItemCopy);
  };

  const handlePayment = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (idx > extraOption.length) return;

    const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(extraOption));
    inputItemCopy[idx].paymentMethod = e.target.value;
    setInputItem(inputItemCopy);
  };

  const handleDesc = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    if (idx > extraOption.length) return;

    const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(extraOption));
    inputItemCopy[idx].description = e.target.value;
    setInputItem(inputItemCopy);
  };

  const extra = extraOption?.map(
    (
      { description, maxOfNumber, paymentMethod, price, title, amount },
      key
    ) => {
      let regExp = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\ '\"\\(\=]/gi;
      const Obj = {
        description: description
          ? TextareaReplaceNewLine(description)
          : preExtra[0]?.description,
        maxOfNumber: maxOfNumber ? maxOfNumber : preExtra[0]?.maxOfNumber,
        paymentMethod: paymentMethod
          ? paymentMethod
          : preExtra[0]?.paymentMethod,
        price: price ? price : preExtra[0]?.price,
        title: title ? title : preExtra[0]?.title,
        amount: amount ? amount : preExtra[0]?.amount,
      };
      return Obj;
    }
  );

  console.log(extra)

  const currentData = {
    extraOption: [...extra],
    priceInfo: {
      mon: mon.price ? mon?.price : prePriceInfo?.mon,
      thu: thu.price ? thu?.price : prePriceInfo?.thu,
      wed: wed.price ? wed?.price : prePriceInfo?.wed,
      tue: tue.price ? tue?.price : prePriceInfo?.tue,
      fri: fri.price ? fri?.price : prePriceInfo?.fri,
      sat: sat.price ? sat?.price : prePriceInfo?.sat,
      sun: sun.price ? sun?.price : prePriceInfo?.sun,
    },
  };

  const modiPric = () => {
    requestToMustOutdoor({
      method: "PATCH",
      url: `/api/v2/private-ground/${id}`,
      data: { ...currentData, hostId: hostNum },
    })
      .then((res) => {
        // window.location.reload();
        console.log(res.data.data)
        return res;
      })
      .catch((err) => {});
  };
  return (
    <div className="web:flex phone:block">
      <div className="web:w-160 web:mx-0 phone:mx-4">
        <div className="text-left">
          <div>
            <HostWrap
              column
              toolTitle="희망 매출을 목표로 운영 및 가격을 설정해주세요."
              toolSubTitle="운영 가능한 요일을 선택하고 가격을 설정해주세요. 이후 캘린더 매니저를 통해서 세부적인 사항 설정과 수정이 가능합니다. 최근에는 평일 차박러들도 꾸준히 증가하는 추세이니 운영 설정 시 참고해주세요~!"
              title="Add-private-ground.Operation & Regular Price*"
              help
              host
            >
              <div className="inline-block">기준가격 변경은 <span onClick={() => {navigate("/host/calendar")}} className="text-link cursor-pointer">캘린더 매니저</span>를 통해 변경 가능합니다.</div>
              <HostInput
                editPricing
                type="number"
                inputValue={prePriceInfo?.sun}
                disabled
                pricing
                CheckContent={t("Days-data.Sunday")}
              />
              <HostInput
                editPricing
                inputValue={prePriceInfo?.mon}
                disabled
                pricing
                CheckContent={t("Days-data.Monday")}
              />
              <HostInput
                editPricing
                inputValue={prePriceInfo?.tue}
                disabled
                pricing
                CheckContent={t("Days-data.Tuesday")}
              />
              <HostInput
                editPricing
                inputValue={prePriceInfo?.wed}
                disabled
                pricing
                CheckContent={t("Days-data.Wednesday")}
              />
              <HostInput
                editPricing
                inputValue={prePriceInfo?.thu}
                disabled
                pricing
                CheckContent={t("Days-data.Thursday")}
              />
              <HostInput
                editPricing
                inputValue={prePriceInfo?.fri}
                disabled
                pricing
                CheckContent={t("Days-data.Friday")}
              />
              <HostInput
                editPricing
                inputValue={prePriceInfo?.sat}
                disabled
                pricing
                CheckContent={t("Days-data.Saturday")}
              />
            </HostWrap>
          </div>
          <div>
            <HostWrap
              toolTitle="추가 옵션 사항을 설정하세요."
              toolSubTitle="야외 공간과 함께 제공할 수 있는 서비스를 추가하여 매출을 올릴 수 있습니다. 추가 옵션은 게스트가 예약 시 함께 진행하는 ‘온라인 결제 방식’ 또는 게스트 방문 시 호스트가 직접 받을 수 있는 ‘현장 결제 방식’으로 설정할 수 있습니다."
              column
              onClick={() => {
                addInput();
              }}
              help
              host
              title="Add-private-ground.Extra Options"
            >
              {extraOption?.map((item, idx) => {
                return (
                  <div key={idx}>
                    <hr className="mt-4 border-input-text" />
                    {idx > 0 ? (
                      <div
                        className="flex justify-end cursor-pointer"
                        onClick={() => {
                          deleteInput(item.id);
                        }}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="pt-6 pb-4 ">
                      <div className="web:flex phone:block">
                        <HostInput
                          inputLengthPosition="web:left-[275px] phone:right-0"
                          inputMaxLength={20}
                          notitlemargin
                          input
                          inputValue={
                            extraOption[0]?.title !== ""
                              ? extraOption[0]?.title
                              : preExtra[0]?.title
                          }
                          onChange={(e) => handleTitle(e, idx)}
                          formTitle={t("Add-private-ground.Name of extra option")}
                          placeholder="Title of extra option"
                          size="M"
                        />
                        <HostInput
                          notitlemargin
                          input
                          type="number"
                          inputValue={
                            extraOption[0]?.price !== ""
                              ? extraOption[0]?.price
                              : preExtra[0]?.price
                          }
                          placeholder="개별 가격"
                          onChange={(e) => handlePrice(e, idx)}
                          formTitle={t("Add-accommodation.Price (Per 1item)")}
                          size="M"
                        />
                      </div>
                      <div className="web:flex phone:block">
                        <HostInput
                          select
                          selected={
                            extraOption[0]?.paymentMethod !== ""
                              ? extraOption[0]?.paymentMethod
                              : preExtra[0]?.paymentMethod
                          }
                          formTitle={t("Add-private-ground.Payment Method")}
                          selectTrans="Extra-payment-method"
                          onChange={(e) => handlePayment(e, idx)}
                          options={Payment}
                          placeholder="Add-private-ground.Select"
                          size="M"
                        />
                        <HostInput
                          input
                          type="number"
                          nomargin
                          inputValue={
                            extraOption[0]?.maxOfNumber !== ""
                              ? extraOption[0]?.maxOfNumber
                              : preExtra[0]?.maxOfNumber
                          }
                          onChange={(e) => handlemaxOfNum(e, idx)}
                          formTitle={t("Add-private-ground.Max of Number")}
                          size="M"
                        />
                      </div>
                      <HostInput
                        textarea
                        onChange={(e) => handleDesc(e, idx)}
                        size="XL"
                        inputValue={
                          extraOption[0]?.description !== ""
                            ? extraOption[0]?.description
                            : preExtra[0]?.description
                        }
                        inputLengthPosition="right-0"
                        inputMaxLength={100}
                        placeholder="Add-private-ground.Please describe inclusion of extra option for your traveler"
                        formTitle={t("Add-private-ground.Description")}
                      />
                    </div>
                  </div>
                );
              })}
            </HostWrap>
          </div>
        </div>
        <div className="mt-12 mb-8 justify-end flex">
          <Button
            btnText={"SAVE"}
            onClick={() => {
              if (window.confirm("정말 수정하시겠어요?")) {
                modiPric();
              }
            }}
            className="px-4 py-3 rounded-lg border border-input-border-color text-pure-white bg-tan"
          ></Button>
        </div>
      </div>
      <div className="w-full ml-4"></div>
    </div>
  );
};

export default EditPricing;
