import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { emailreg, passwordreg } from "utils/regCheck";

import Input from "elements/Input";
import Button from "elements/Button";
import AuthLogo from "components/Auth/authLogo";
import CheckBox from "elements/CheckBox";

import { useAppDispatch } from "redux/store";
import { signupUser, emailcheck } from "redux/action/userInfo";

import Icons from "elements/Icons";
import { useGoogleLogin } from "@react-oauth/google";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [name, SetName] = useState("");
  const [email, setEmail] = useState("");
  const [pw, SetPw] = useState("");
  const [pwCheck, SetPwCheck] = useState("");

  const [checkedList, setCheckedLists] = useState<any[]>([]);

  // 이메일 중복 확인 누른지 확인
  const [useableId, setUseableId] = useState(false);

  // 형식 에러
  const [nameMessage, setNameMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [pwMessage, setPwMessage] = useState("");
  const [pwCheckMessage, setPwCheckMessage] = useState("");

  const [agreeTerm, setAgreeTerm] = useState(false);
  const [agreePolicy, setAgreePolicy] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const [nameErr, setNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [pwErr, setPwErr] = useState(false);
  const [pwCheckErr, setPwCheckErr] = useState(false);
  const data = {
    name: name,
    email: email,
    password: pw,
    checkPassword: pwCheck,
    marketingConsent: marketing,
  };
  const checkuser = {
    email: email,
  };

  const emailCheck = () => {
    if (email === "") {
      setEmailErr(true);
      setEmailMessage("이메일 입력해주세요");
      return;
    } else if (!emailreg(email)) {
      setEmailErr(true);
      setEmailMessage(t("Account.Please enter a valid email format"));
      return;
    } else {
      setUseableId(true);
      dispatch(emailcheck(checkuser))
        .unwrap()
        .then((res) => {
          setEmailErr(false);
          setEmailMessage(t("Account.Email you entered is available"));
          return res;
        })
        .catch((err) => {
          setEmailErr(true);
          setEmailMessage(t("Account.Email you entered is already in use"));
          return;
        });
    }
  };

  const onEnterPress = (e: any) => {
    if (e.key === "Enter") {
      signup();
    }
  };

  const signup = async () => {
    try {
      if (name === "") {
        setNameErr(true);
        setNameMessage("이름을 입력해주세요");
        return;
      } else if (pw === "") {
        setPwErr(true);
        setPwMessage(t("Account.Password is required"));
        return;
      } else if (!passwordreg(pw)) {
        setPwErr(true);
        setPwMessage(
          t("Account.Password should have at least 8 characters long")
        );
        return;
      } else if (pwCheck === "") {
        setPwCheckErr(true);
        setPwCheckMessage("비밀번호 확인 빈칸");
        return;
      } else if (pw !== pwCheck) {
        setPwCheckErr(true);
        setPwCheckMessage("비밀번호 다릅니다");
        return;
      } else if (useableId !== true) {
        //console.log"이메일 중복 체크 고");
        return;
      } else if (agreeTerm !== true) {
        //console.log"개인정보보호정책 체크");
        return;
      } else if (agreePolicy !== true) {
        //console.log"이용약관 동의 체크");
        return;
      }
      await dispatch(signupUser(data)).unwrap();
      navigate("authentication", { replace: true });
    } catch (err) {}
  };

  const CheckContent = [
    {
      id: 1,
      content: "I have read and agree to Terms & Conditions",
      navi: "/terms",
    },
    {
      id: 2,
      content: "I have read and agree to Privacy Policy",
      navi: "/privacy-policy",
    },
    {
      id: 3,
      content: "I agree to receive emails from MUST OUTDOOR",
    },
  ];

  const onChangeNameMessage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentName = e.target.value;
      SetName(currentName);
      if (currentName === "") {
        setNameMessage("이름을 입력해 주세요");
        setNameErr(true);
      } else {
        setNameErr(false);
        setNameMessage("");
      }
    },
    []
  );
  const onChangeEmailMessage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentEmail = e.target.value;
      setEmail(currentEmail);
      if (!emailreg(currentEmail)) {
        setEmailErr(true);
        setEmailMessage(t("Account.Please enter a valid email format"));
      } else {
        setEmailErr(false);
        setEmailMessage("이메일 체크 버튼을 눌러주세요");
      }
    },
    []
  );

  const onChangePasswordMessage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentPassword = e.target.value;
      SetPw(currentPassword);
      if (currentPassword === "") {
        setPwErr(true);
        setPwMessage(t("Account.Password is required"));
      } else if (!passwordreg(currentPassword)) {
        setPwErr(true);
        setPwMessage(
          t("Account.Password should have at least 8 characters long")
        );
      } else {
        setPwErr(false);
        setPwMessage("안전한 비밀번호 입니다.");
      }
    },
    [pw]
  );

  const onChangePasswordCheckMessage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentPasswordCheck = e.target.value;
      SetPwCheck(currentPasswordCheck);
      if (pw === currentPasswordCheck) {
        setPwCheckErr(false);
        setPwCheckMessage("비밀번호를 똑같이 입력");
      } else {
        setPwCheckErr(true);
        setPwCheckMessage("비밀번호가 다름");
      }
    },
    [pw]
  );

  const onCheckedAll = useCallback(
    (checked: any) => {
      if (checked) {
        const checkedListArray: any = [];
        CheckContent.forEach(({ id }) => {
          checkedListArray.push(id);
        });
        setCheckedLists(checkedListArray);
      } else {
        setCheckedLists([]);
      }
    },
    [CheckContent]
  );

  const onCheckedElement = useCallback(
    (checked: any, id: any) => {
      if (checked) {
        setCheckedLists([...checkedList, id]);
      } else {
        setCheckedLists(checkedList.filter((el) => el !== id));
      }
    },
    [checkedList]
  );

  useEffect(() => {
    if (checkedList.includes(3) === true) {
      setMarketing(true);
    } else {
      setMarketing(false);
    }
  }, [checkedList]);
  useEffect(() => {
    if (checkedList.includes(2) === true) {
      setAgreePolicy(true);
    } else {
      setAgreePolicy(false);
    }
  }, [checkedList]);
  useEffect(() => {
    if (checkedList.includes(1) === true) {
      setAgreeTerm(true);
    } else {
      setAgreeTerm(false);
    }
  }, [checkedList]);

  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_DEV_REDIRECT_URI}&response_type=code`;

  const googleAuth = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      console.log(credentialResponse);
      const { access_token, expires_in, scope, token_type } =
        credentialResponse;
      requestToMustOutdoor({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}auth/google/log-in`,
        data: {
          accessToken: access_token,
          expiresIn: expires_in,
          scope: scope,
          tokenType: token_type,
        },
      })
        .then((res) => {
          const { id } = res.data.data;
          localStorage.setItem("m", id);
          // navigate("/sign-up/social-success", { replace: true });
          navigate("/log-in", { replace: true });
          alert("회원가입이 완료되었습니다.");
          //console.logres);
        })
        .catch((err) => {
          const errCode = err.response.data;
          alert(errCode.message);
          // navigate('/log-in', { replace: true });
        });
    },
  });

  return (
    <div>
      <AuthLogo>
        <div>
          <div className="inline-block mt-8">
            <a href={KAKAO_AUTH_URL}>
              <div className="bg-yellow w-80 border-0 px-4 h-8 rounded-lg justify-center relative cursor-pointer items-center shadow-ms text-sm flex">
                <Icons
                  iconName="kakao"
                  className="absolute left-4 bottom-2 w-4 h-4"
                />
                <div>{t("Account.Start with Kakao")}</div>
              </div>
            </a>
            <div
              onClick={() => {
                googleAuth();
              }}
              className="bg-pure-white mt-2 w-80 border-0 px-4 h-8 rounded-lg justify-between relative cursor-pointer items-center shadow-ms text-sm flex"
            >
              <img
                className="w-4 h-4"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/google.svg"
                alt=""
              />
              <div>{t("Account.Start with Google")}</div>
              <div />
            </div>
          </div>
          <div className="flex items-center justify-center my-4">
            <div className="h-1 border-t border-black-grey bg-pure-white w-25 mt-0.5" />
            <div className="mx-6 text-pure-white px-6">or</div>
            <div className="h-1 border-t border-black-grey bg-pure-white w-25 mt-0.5" />
          </div>
        </div>
        <span className="text-pure-white block text-xs">
          {t("Account.Sign up with your email")}
        </span>
        {/*g 회원가입 양식 */}
        <div className="mb-4">
          <div>
            {/* 이름 */}
            <Input
              isIcon
              onChange={(e) => {
                onChangeNameMessage(e);
              }}
              isError={nameErr}
              inputIcon={"user"}
              placeholder={t("Account.Name")}
              type={"text"}
            >
              {nameMessage}
            </Input>
          </div>
          <div>
            {/* 이메일 */}
            <Input
              isIcon
              onChange={(e) => {
                onChangeEmailMessage(e);
              }}
              onClick={() => {
                emailCheck();
              }}
              isBtn
              isError={emailErr}
              type={"email"}
              placeholder={t("Account.Email Address")}
              inputIcon={"email-address"}
            >
              {emailMessage}
            </Input>
          </div>
          <div>
            {/* 비밀번호 */}
            <Input
              isIcon
              password
              onChange={(e) => {
                onChangePasswordMessage(e);
              }}
              type={"password"}
              isError={pwErr}
              placeholder={t("Account.Password")}
              inputIcon={"password"}
            >
              {pwMessage}
            </Input>
          </div>
          <div>
            {/* 비밀번호 검사 */}
            <Input
              onKeyPress={onEnterPress}
              isIcon
              onChange={(e) => {
                onChangePasswordCheckMessage(e);
              }}
              isError={pwCheckErr}
              type={"password"}
              placeholder={t("Account.Re-enter password")}
              inputIcon={"password"}
            >
              {pwCheckMessage}
            </Input>
          </div>
        </div>

        {/* 체크박스 */}
        <div className="text-left inline-block text-xs text-pure-white">
          <div className="flex items-center">
            <input
              onChange={(e) => {
                onCheckedAll(e.target.checked);
              }}
              checked={
                checkedList.length === CheckContent.length ? true : false
              }
              type="checkbox"
            />
            <label>{t(`Account.I accept all`)}</label>
          </div>
          <div className="flex items-center justify-center my-2">
            <div className="h-1 border-t border-black-grey bg-pure-white w-80 mt-0.5" />
          </div>
          {CheckContent &&
            CheckContent.map((item: any, idx) => {
              return (
                <CheckBox
                  key={idx}
                  onChange={(e) => onCheckedElement(e.target.checked, item.id)}
                  checked={checkedList.includes(item.id)}
                  value={item.content}
                  content={t(`Account.${item.content}`)}
                  url={item.navi}
                />
              );
            })}
        </div>

        {/* 하단 버튼  */}
        <div>
          <Button
            onClick={() => {
              signup();
            }}
            className="bg-light-green text-sm inline-block text-pure-white rounded-lg py-2 px-[133px] mt-4"
            btnText={t("Account.Complete")}
          ></Button>
        </div>
        {/* 최하단 계정유무 확인 */}
        <div className="w-[327px] inline-block">
          <div className="text-pure-white text-xs flex justify-between mt-4">
            <div>{t("Account.Already have an account?")}</div>
            <span
              onClick={() => {
                navigate("/log-in");
              }}
              className="font-bold underline text-xs cursor-pointer"
            >
              {t("Account.Login here")}
            </span>
          </div>
        </div>
      </AuthLogo>
    </div>
  );
};

export default SignUp;
