export const isMember = () => {
    return !!localStorage.getItem("isLogin")
}

export const isHost = () => {
    return !!localStorage.getItem("hostNum")
}

export const isRole = () => {
    return localStorage.getItem("role");
}

export const STATUS = () => {
    return localStorage.getItem("STATUS");
}

export const isAdmin = () => {
    return !!localStorage.getItem("role")
}