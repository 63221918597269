import React, { useEffect, useState } from "react";

import Button from "elements/Button";
import HostInput from "elements/HostInput";

import HostWrap from "./hostWrap";
import { BANK_NAME } from "./constant";
import { useTranslation } from "react-i18next";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useNavigate } from "react-router-dom";
import Icons from "elements/Icons";

interface payoutProps {
  bizType?: boolean;
}

const HostPayoutSetting = ({ bizType }: payoutProps) => {
  const navigate = useNavigate();
  const hostNum = localStorage.getItem("hostNum");
  const id = localStorage.getItem("m");
  const [payoutData, setPayoutData] = useState<any>([]);

  const [accountNum, setAccountNum] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bizNum, setBizNum] = useState("");
  const [name, setName] = useState("");
  const [societyNum, setSocietyNum] = useState("");

  const [isBank, setIsBank] = useState(false);

  const data = {
    accountNumber: accountNum,
    bankCode: bankCode,
    bizNumber: "",
    fullName: name,
    hostId: Number(hostNum),
    societyNumber:
      bizType === true
        ? "000101-1111111"
        : societyNum.replace(/(\d{6})(\d{7})/, "$1-$2"),
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    window.scrollTo(0, 0);
  }, [isBank]);

  const backSetting = () => {
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v2/payout/register",
      data,
    })
      .then((res) => {
        setIsBank(true);
        return res.data.data;
      })
      .catch((err) => {});
  };
  const bankDelete = () => {
    requestToMustOutdoor({
      method: "DELETE",
      url: `/api/v2/payout/${payoutData.id}`,
      params: { hostId: hostNum },
    })
      .then((res) => {
        setPayoutData("");
        setIsBank(false);
        return res.data.data;
      })
      .catch((err) => {});
  };

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/payout/${hostNum}`,
    })
      .then((res) => {
        setPayoutData(res.data.data);
        return res.data.data;
      })
      .catch((err) => {});
  }, [hostNum, isBank]);

  const { t } = useTranslation();
  return (
    <div className="phone:px-4 w-full web:w-160 web:px-0">
      <div className="p-2 border flex border-input-text bg-[#eee] rounded-lg my-8 text-sm">
        <Icons iconName="warning-yellow" className="w-8 h-6 mr-2" />
        <div>{t(`AS-PayoutSet.Payout will be made every 5th of a month`)}</div>
      </div>

      <div className="w-full">
        <div>
          <HostWrap
            host
            mainClass="mt-8 w-full"
            subClass="w-full"
            title={"AS-PayoutSet.Your Payout Method"}
          >
            {payoutData.length !== 0 ? (
              <div className="w-full mt-8 mb-4">
                <div className="flex justify-between items-center">
                  <div className="text-xl">
                    {t(`AS-PayoutSet.Bank Transfer Payout`)}
                  </div>
                  <Button
                    onClick={() => bankDelete()}
                    btnText={"AS-PayoutSet.Delete"}
                    className="px-4 rounded-lg border-red border text-red py-2"
                  />
                </div>
                <div>
                  {payoutData?.billingCountry?.replace("_", " ")}{" "}
                  {payoutData.currencyInfo?.name}
                </div>
                <div>
                  {payoutData?.bankName}, {payoutData?.accountNumber}
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center mx-auto">
                <Icons className="mt-8 w-32 h-[117px]" iconName="payouts" />
                <div className="font-bold mt-8 mb-4 text-center">
                  {t(`AS-PayoutSet.Please add your payout method`)}
                </div>
              </div>
            )}
          </HostWrap>
        </div>
        <div>
          <HostWrap host title={"AS-PayoutSet.Billing Country"}>
            <HostInput
              input
              disabled
              inputValue={t(
                `Country-name.${localStorage.getItem("hostCountry")}`
              )}
              size="XL"
              placeholder={"AS-PayoutSet.Bank Name"}
            />
          </HostWrap>
        </div>
        <div>
          <HostWrap host title={"AS-PayoutSet.Payout Method"}>
            <HostInput
              input
              disabled
              inputValue={t("AS-PayoutSet.Bank Transfer Payout")}
              size="XL"
              placeholder={"AS-PayoutSet.Bank Name"}
            />
          </HostWrap>
          <div className="my-8 text-sm">
            <div>{t(`AS-PayoutSet.Please provide your bank details`)}</div>

            <div className="mt-2">
              <div>{t("AS-PayoutSet.Get paid in 3–5 business days")}</div>
              <div>
                {t(
                  "AS-PayoutSet.Weekends and holidays may extend processing time"
                )}
              </div>
              <div>
                {t(
                  "AS-PayoutSet.A-Is there any handling charge for wire transfer"
                )}
              </div>
            </div>
          </div>
          <HostWrap title="" mainClass="mt-0">
            <HostInput
              input
              disabled
              inputValue={t(
                `Country-name.${localStorage.getItem("hostCountry")}`
              )}
              formTitle="AS-PayoutSet.Bank Country"
              notitlemargin
              size="XL"
            />
          </HostWrap>
          <HostWrap title="" mainClass="mt-0">
            <HostInput
              disabled
              formTitle="Currency.Currency"
              notitlemargin
              input
              inputValue={"Korean Won(KRW)"}
              size="XL"
            />
          </HostWrap>
          <HostWrap title="" mainClass="mt-0">
            <HostInput
              selectTrans="Kor-bank-name"
              options={BANK_NAME}
              onChange={(e) => {
                setBankCode(e.target.value);
              }}
              placeholder={"AS-PayoutSet.Bank Name"}
              notitlemargin
              formTitle="AS-PayoutSet.Bank Name"
              select
              size="XL"
            />
          </HostWrap>
          <HostWrap title="" mainClass="mt-0">
            <HostInput
              placeholder="AS-PayoutSet.Account Number"
              notitlemargin
              onChange={(e) => {
                setAccountNum(e.target.value);
              }}
              type="number"
              formTitle="AS-PayoutSet.Account Number"
              input
              size="XL"
            />
          </HostWrap>
          <HostWrap title="" mainClass="mt-0">
            <HostInput
              placeholder="AS-PayoutSet.Account Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              notitlemargin
              formTitle="AS-PayoutSet.Account Name"
              input
              size="XL"
            />
          </HostWrap>
          {bizType === false ? (
            <HostWrap title="" mainClass="mt-0">
              <HostInput
                onChange={(e) => {
                  setSocietyNum(e.target.value);
                }}
                inputMaxLength={13}
                notitlemargin
                inputLengthPosition="right-0"
                placeholder="AS-PayoutSet.Resident registration number"
                type="number"
                formTitle="AS-PayoutSet.Resident registration number"
                input
                size="XL"
              />
            </HostWrap>
          ) : (
            ""
          )}
          <div className="flex justify-start items-center">
            <Button
              onClick={() => backSetting()}
              className="px-4 py-3 mt-8 rounded-lg bg-tan text-pure-white"
              isIcon
              src={"save"}
              iconClassName="fill-pure-white"
              btnText={"AS-PayoutSet.Save"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostPayoutSetting;
