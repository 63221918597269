import React from "react";


import HostWrap from "./hostWrap";
import HostPayoutCard from "elements/hostPayoutCard";
import { useTranslation } from "react-i18next";
import Icons from "elements/Icons";

const HostPayoutDetails = () => {
  const { t } = useTranslation();
  return (
    <div className="phone:px-4 web:px-0">
      <div className="p-2 border flex phone:max-w-[1024px] border-input-text bg-[#eee] rounded-lg my-8 text-sm">
        <Icons iconName="warning-yellow" className="w-8 h-6 mr-2" />
        <div>{t("AS-PayoutSet.Payout is net income you will receive")}</div>
      </div>

      <div>
        <HostWrap
          host
          mainClass="mt-8 w-full"
          subClass="w-full"
          title={"AS-PayoutSet.Upcoming Payout"}
        >
          <div className="flex flex-col items-center justify-center mx-auto">
            <Icons className="mt-8 w-32 h-[117px]" iconName="payouts" />
            {/* 페이지에 따라 나눌 화면 */}
            <div className="font-bold mt-8 mb-4">
              {t(
                `AS-PayoutSet.Please add your payout method in Payout Setting!`
              )}
            </div>
            <div className="text-sm">
              {t(
                `AS-PayoutSet.If you do not add your payout method, you cannot get your payout.`
              )}
            </div>
          </div>
        </HostWrap>
      </div>
      <hr className="mt-8 text-light-grey" />
      <HostWrap
        host
        column
        mainClass="mt-8 w-full"
        subClass="w-full"
        title={"AS-PayoutSet.Completed Payouts"}
      >
        {/* <HostPayoutCard head date="2022/08/17" />
        <HostPayoutCard date="2022/09/17" /> */}
      </HostWrap>
    </div>
  );
};

export default HostPayoutDetails;
