import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/store";

import { generalInfo } from "redux/slice/accommodationSlice";
import axios from "axios";

import AcWrap from "elements/AcWrap";
import Button from "elements/Button";
import HostInput from "elements/HostInput";
import TouristSpot from "elements/TouristSpot";

import KakaoMap from "components/Map/KakaoMap";
import DaumAddressSearch from "components/Map/DaumAddressSearch";

import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

interface GeneProps {
  onNext?: any;
  geneData?: any;
}

const AcGeneralInfo = ({ onNext, geneData }: GeneProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const imaegRef = useRef<HTMLInputElement>(null);

  const [googleMapInfo, setGoogleMapInfo] = useState();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [extraAddress, setExtraAddress] = useState("");
  const [coords, setCoords] = useState({ longitude: 0, latitude: 0 });
  const [directions, setDirections] = useState("");

  const [touristSpotData, setTouristSpotData] = useState({
    description: "",
    photo: "",
    photoUrl: "",
    title: "",
  });

  const [tourist, setTourist] = useState<any>([]);

  const pageData = {
    title,
    description: TextareaReplaceNewLine(description),
    address: address,
    country: "KR",
    state: state,
    city: city,
    zipCode: zipCode,
    extraAddress: extraAddress,
    ...coords,
    directions: directions,
    destinationId: 49,
    touristSpots: [touristSpotData],
  };

  const [fullAddress, setFullAddress] = useState("");
  const preData = useAppSelector((state) => state.accommodation.generalInfo);

  const page = preData;

  useEffect(() => {
    setTitle(page?.title);
    setDescription(page?.description);
    setState(page?.state);
    setCity(page?.city);
    setAddress(page?.address);
    setZipCode(page?.zipCode);
    setExtraAddress(page?.extraAddress);
    setFullAddress(page?.state + " " + page?.city + " " + page?.address);
    setCoords({
      latitude: Number(page?.latitude),
      longitude: Number(page?.longitude),
    });
    setDirections(page?.directions);
    setTouristSpotData({
      description: page?.touristSpots[0]?.description
        ? page?.touristSpots[0]?.description
        : "",
      photo: page?.touristSpots[0]?.photo ? page?.touristSpots[0]?.photo : "",
      photoUrl: page?.touristSpots[0]?.photoUrl
        ? page?.touristSpots[0]?.photoUrl
        : "",
      title: page?.touristSpots[0]?.title ? page?.touristSpots[0]?.title : "",
    });
  }, [
    page?.address,
    page?.city,
    page?.description,
    page?.zipCode,
    page?.directions,
    page?.extraAddress,
    page?.latitude,
    page?.longitude,
    page?.state,
    page?.title,
    page?.touristSpots,
  ]);

  useEffect(() => {
    const data: any = googleMapInfo;
    let fullAddress = data?.address;
    let extraAddress = "";
    if (data) {
      const state = data?.sido;
      const city = data?.sigungu;
      const roadname = data?.roadname;
      const addressData = data?.roadAddressEnglish.split(",");
      const addressnum = addressData[0];
      const zipCode = data?.zonecode;
      setState(state);
      setCity(city);
      setAddress(roadname + " " + addressnum);
      setZipCode(zipCode);
    }

    if (data?.addressType === "R") {
      if (data?.bname !== "") {
        extraAddress += data.bname;
      }
      if (data?.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data?.buildingName}` : data?.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setFullAddress(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
  }, [googleMapInfo, fullAddress]);

  useEffect(() => {
    if (fullAddress !== "") {
      const config = {
        headers: {
          Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`,
        },
      };
      const url =
        "https://dapi.kakao.com/v2/local/search/address.json?query=" +
        fullAddress;
      axios.get(url, config).then(function(result) {
        // API호출
        if (
          result?.data !== undefined ||
          result?.data !== null ||
          result.data.documents.length !== 0
        ) {
          if (result?.data?.documents[0]?.x && result?.data?.documents[0]?.y) {
            // Kakao Local API로 검색한 주소 정보 및 위도, 경도값 저장
            const lng = result.data.documents[0].x;
            const lat = result.data.documents[0].y;
            setCoords({
              longitude: Number(lng),
              latitude: Number(lat),
            });
          }
        }
      });
    }
  }, [fullAddress]);

  const handlePageData = () => {
    geneData(pageData);
    dispatch(generalInfo(pageData));
    onNext();
  };

  const handleImage = (e: any) => {
    let file = e.target.files[0];
    let fileSize = file?.size;
    
    console.log(file)

    const data = {
      multipartFiles: file,
    };

    if (fileSize > 1024 ** 2 * 5) {
      return alert("파일 사이즈는 5mb 보다 작아야합니다.");
    } else {
      requestToMustOutdoor({
        method: "POST",
        url: "/guest/upload/file",
        headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
        data,
      })
        .then((res) => {
          const data = res.data.data;
          console.log(res);
          const imgName = data[0];
          // => 이렇게 되어있는거 보니까 ["f4fc6a9b-6a1f-46a9-be35-321515acb401.jpeg"] 이렇게 받았던거같아요
          // imgUrl_ = "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/ + f4fc6a9b-6a1f-46a9-be35-321515acb401.jpeg "
          const imgUrl_ = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${imgName}`;
          setTouristSpotData({
            ...touristSpotData,
            photo: imgName,
            photoUrl: imgUrl_,
          });

          return data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleClick = () => {
    imaegRef.current?.click();
  };

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          inputValue={title}
          title="Add-accommodation.Title*"
          placeholder="Add-accommodation.Name of Accommodation"
          input
          help
          toolTitle="Tooltip-add-accommodation.Gen info title-tooltip title"
          toolSubTitle={"Tooltip-add-accommodation.Gen info title-tooltip desc"}
          inputMaxLength={50}
          />
        <AcWrap
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          row={5}
          inputValue={description}
          title="Add-accommodation.Description*"
          placeholder="Add-accommodation.Describe the accommodation for traveler"
          textArea
          help
          toolTitle="Tooltip-add-accommodation.Gen info description-tooltip title"
          toolSubTitle="Tooltip-add-accommodation.Gen info description-tooltip desc"
          inputMaxLength={500}
          />
        <AcWrap
          title="Add-accommodation.Accommodation Address*"
          help
          toolTitle="Tooltip-add-accommodation.Gen info ac address-tooltip title"
          toolSubTitle="Tooltip-add-accommodation.Gen info ac address-tooltip desc"
        >
          <div className="flex items-baseline mt-4">
            <DaumAddressSearch setGoogleMapInfo={setGoogleMapInfo} />
          </div>
          <HostInput
            input
            size="XL"
            inputValue={"KR"}
            formTitle={t("Add-accommodation.Country / Region")}
            disabled={true}
          />
          <HostInput
            input
            size="XL"
            disabled={true}
            inputValue={
              fullAddress === undefined
                ? page?.state + " " + page?.city + " " + page?.address
                : fullAddress
            }
            formTitle={t("Add-accommodation.Address")}
          />
          <AcWrap
            tooltipHidden
            input
            inputValue={extraAddress}
            onChange={(e) => {
              setExtraAddress(e.target.value);
            }}
            placeholder="상세주소를 입력해주세요"
            miniTitle="Add-accommodation.Rest Address (optional)"
            inputMaxLength={50}
          />
        </AcWrap>
        <AcWrap
          title="Add-accommodation.Map View"
          toolTitle="잠깐! 한번 더 확인해주세요."
          toolSubTitle="입력한 주소가 정확한지 한번 더 체크해주세요. 지금 설정된 주소는 이후 호스트가 직접 수정 할 수 없으며, 주소 변경을 원하는 경우 '머스트 아웃도어' 관리자에게 요청이 필요합니다."
        >
          <KakaoMap
            AddInfo={{ lat: coords?.latitude, lng: coords?.longitude }}
          />
        </AcWrap>
        <AcWrap
          textArea
          onChange={(e) => {
            setDirections(e.target.value);
          }}
          inputValue={directions}
          placeholder="Add-accommodation.Please describe how to get there"
          title="Add-accommodation.How To Get There"
          help
          toolTitle="Tooltip-add-accommodation.Gen info how to get-tooltip title"
          toolSubTitle="Tooltip-add-accommodation.Gen info how to get-tooltip desc"
          row={5}
          inputMaxLength={500}
          />
        <AcWrap
          title="Add-accommodation.Recommending Tourist Spot"
          help
          // addItem
          // addOnClick={() => {
            //   setTourist((pre: any) => [pre, <TouristSpot />]);
            // }}
            toolTitle="Tooltip-add-accommodation.Gen info tour spot-tooltip title"
            toolSubTitle="Tooltip-add-accommodation.Gen info tour spot-tooltip desc"
        >
          <div className="mb-8">
            <hr className="text-input-text" />
            <div className="flex web:flex-row phone:flex-col items-center mt-8">
              <input
                type="file"
                className="hidden"
                onChange={(e) => {
                  handleImage(e);
                  e.target.value = "";
                }}
                accept=".jpg, .jpeg, .png, .pdf"
                ref={imaegRef}
              />
              <div
                onClick={() => handleClick()}
                className="web:mr-8 phone:mr-0 phone:w-full web:w-48"
              >
                <div className="text-sm">
                  {t("Add-accommodation.Featured Image")}
                </div>
                {touristSpotData?.photoUrl !== "" ? (
                  <img
                    className="web:w-48 phone:w-full h-[141px] rounded-lg"
                    src={touristSpotData?.photoUrl}
                    alt=""
                  />
                ) : (
                  <div className="w-full cursor-pointer h-[141px] border justify-center border-input-border-color mt-2 rounded-lg flex flex-col items-center">
                    <img
                      className="w-[60px] h-[60px]"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
                      alt=""
                    />
                    <div className="mt-2 text-sm">
                      {t("Add-accommodation.Add Gallery")}
                    </div>
                  </div>
                )}
              </div>
              <div className="grow web:max-w-[416px] w-full text-sm web:mt-0 phone:mt-4">
                <div>
                  <div>{t("Add-accommodation.Name of Tourist Spot")}</div>
                  <input
                    defaultValue={
                      touristSpotData?.title ? touristSpotData?.title : ""
                    }
                    onChange={(e) => {
                      setTouristSpotData({
                        ...touristSpotData,
                        title: e.target.value,
                      });
                    }}
                    name="title"
                    className="touristInput h-[33px]"
                  />
                </div>
                <div className="mt-4">
                  <div>
                    {t("Add-accommodation.Description of Tourist Spot")}
                  </div>
                  <textarea
                    onChange={(e) => {
                      setTouristSpotData({
                        ...touristSpotData,
                        description: e.target.value,
                      });
                    }}
                    defaultValue={
                      touristSpotData?.description
                        ? touristSpotData?.description
                        : ""
                    }
                    name="description"
                    rows={2}
                    className="touristInput h-[67px]"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* {tourist?.map((item: any, idx: number) => {
            return ( */}
          {/* <TouristSpot
            preData={touristSpot}
            createTouristSpot={setTouristSpot}
          /> */}
          {/*
          })} */}
        </AcWrap>
        <div className="text-right">
          <Button
            onClick={() => handlePageData()}
            btnText={"Add-accommodation.Continue"}
            className="inline-block bg-light-green rounded-lg py-3 px-4 text-pure-white"
          />
        </div>
      </div>
    </div>
  );
};

export default AcGeneralInfo;
