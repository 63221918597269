//selectType page 카드
import React, { useState } from "react";

import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icons from "./Icons";

interface HostCardProps {
  onClick?: () => void;
  id: number;
  className?: string;
  title: string;
  cardIcon: string;
  subTitle: string;
  content: string;
  mobileContent?: string;
  requirement: string;
  info: string;
  timeRequired: string;
  isPossible?: boolean;
  bizType: string[] | string;
}

const HostCard = ({
  onClick,
  className,
  id,
  bizType,
  title,
  cardIcon,
  subTitle,
  content,
  requirement,
  info,
  timeRequired,
  isPossible,
}: HostCardProps) => {
  const [mobileTab, setMobileTab] = useState<any>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const itemStatus = JSON.parse(localStorage.getItem("itemStatus") as string);

  const typeSelect = (type: string) => {
    const hasAc = itemStatus && itemStatus?.hasAc;
    const hasPg = itemStatus && itemStatus?.hasPg;
    const hasAt = itemStatus && itemStatus?.hasAt;
    const acInfo = itemStatus && itemStatus?.acInfo !== null;
    const atInfo = itemStatus && itemStatus?.atInfo !== null;
    const pgInfo = itemStatus && itemStatus?.pgInfo !== null;

    if (type === "AC" && acInfo) {
      alert("이미 아웃도어 숙박 호스트 입니다");
      navigate("/host/myaccommodation", { replace: true });
      localStorage.setItem("currentType", "AC");
      localStorage.setItem("STATUS", "HOST");
    } else if (type === "PG" && pgInfo) {
      alert("이미 유휴 야외공간 호스트 입니다");
      navigate("/host/myprivate", { replace: true });
      localStorage.setItem("STATUS", "HOST");
      localStorage.setItem("currentType", "PG");
    } else if (type === "AT" && atInfo) {
      alert("이미 아웃도어 액티비티 호스트 입니다");
      localStorage.setItem("currentType", "AT");
      localStorage.setItem("STATUS", "HOST");
    } else {
      navigate("/host-info", { state: bizType });
    }
  };

  console.log(mobileTab);

  return (
    <div>
      <div className="web:inline-flex phone:hidden flex-row justify-between">
        <div className="w-80 inline-block bg-pure-white relative h-[448px] text-center p-8 shadow-selectCard rounded-2xl hover:-translate-5 ">
          <div className="text-[22px] font-bold">
            {t(`Partner-select.${title}`)}
          </div>
          <Icons
            extension="png"
            iconName={cardIcon}
            className="inline-block m-8 w-24 h-24"
          />
          <div className="font-bold text-base mb-4">
            {t(`Partner-select.${subTitle}`)}
          </div>
          <div className="text-sm">{t(`Partner-select.${content}`)}</div>
        </div>
        <div className="typeHover text-left text-sm px-8 pb-8 pt-16 w-80 h-[448px] absolute opacity-0 hover:opacity-100 hover:bg-[rgba(0,0,0,0.8)] rounded-2xl text-pure-white">
          <div className="flex items-center">
            <Icons iconName="warning-yellow" className="w-4 h-4" />
            <p className="ml-2">{t(`Partner-select.${requirement}`)}</p>
          </div>
          <div className="flex my-4">
            <p>{t(`Partner-select.${info}`)}</p>
          </div>
          <div className="flex items-center">
            <Icons iconName="select-time" className="w-4 h-4" />
            <p className="ml-2">{t(`Partner-select.${timeRequired}`)}</p>
          </div>
          <div className="text-base">
            {isPossible ? (
              <Button
                btnSize="small"
                onClick={() => {
                  typeSelect(bizType[0]);
                }}
                className="block absolute bg-light-green  bottom-8 w-[256px] rounded-2xl mx-[auto] text-center"
                btnText={t("Partner-select.Start Hosting")}
              />
            ) : (
              <Button
                btnSize="small"
                onClick={() => {
                  window.alert("준비중입니다 :)");
                }}
                className="block absolute transition-transform text-pure-white opacity-70 font-bold bg-grey bottom-8 w-[256px] rounded-2xl mx-[auto] text-center"
                btnText={t("Partner-select.Coming Soon")}
              />
            )}
          </div>
        </div>
      </div>
      <div className="web:hidden phone:inline-block text-left">
        <div
          onClick={() => {
            mobileTab !== id ? setMobileTab(id) : setMobileTab(0);
          }}
          className="w-[328px] transition-all bg-pure-white shadow-selectCard rounded-2xl mb-4 p-4 cursor-pointer"
        >
          <div className="inline-block text-xl font-bold mb-4">
            {t(`Partner-select.${title}`)}
          </div>
          <div className="flex items-center">
            <Icons extension="png" iconName={cardIcon} className="w-16 h-16" />
            <div className="ml-4 font-bold">
              {t(`Partner-select.${subTitle}`)}
            </div>
          </div>
          {mobileTab === id ? (
            <div>
              <div className="my-4 text-tiny">
                {t(`Partner-select.${content}`)}
              </div>
              <div className="flex items-center">
                <img
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                  className="w-4 h-4"
                  alt=""
                />
                <p className="font-bold text-tiny ml-2">
                  {t(`Partner-select.${requirement}`)}
                </p>
              </div>
              <div className="text-tiny my-2">
                {t(`Partner-select.${info}`)}
              </div>
              <div className="flex items-center">
                <img
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/select-time-bk.svg"
                  className="w-4 h-4"
                  alt=""
                />
                <p className="ml-2 text-tiny">
                  {t(`Partner-select.${timeRequired}`)}
                </p>
              </div>
              {isPossible ? (
                <Button
                  onClick={() => {
                    typeSelect(bizType[0]);
                  }}
                  className="mt-4 font-bold rounded-2xl text-pure-white py-3 bg-light-green"
                  btnText={t("Partner-select.Start Hosting")}
                />
              ) : (
                <Button
                  onClick={() => {
                    window.alert("준비중입니다 :)");
                  }}
                  className="mt-4 font-bold rounded-2xl text-pure-white py-3 bg-grey opacity-70"
                  btnText={t("Partner-select.Coming Soon")}
                />
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default HostCard;
