import React from 'react';

import AuthUtil from "components/Auth/AuthUtil";
import HeadLogo from "components/Auth/authLogo";
import Button from "elements/Button";
import { useNavigate } from 'react-router-dom';

const EmailExist = () => {
    const navigate = useNavigate()
    return (
        <HeadLogo>
          <AuthUtil
            AuthIcon={"warning-red"}
            Red
            title={"User email already exists"}
            subTitle={"You’ve previously signed up with the email account"}
            and
            content={`Please try other login with the email account`}
          />
          <div className="text-base text-pure-white">
            <Button
              onClick={() => {
                navigate('/log-in')
              }}
              className="inline-block mx-auto mt-2 mb-4 bg-light-green text-pure-white text-xs rounded-lg py-2 w-[320px]"
              btnText={"Account.Continue to Login"}
            />
          </div>
        </HeadLogo>
    );
};

export default EmailExist;