import React from "react";

interface MainCardLayoutProps {
  children?: any;
  gainsboro?: boolean;
  className?: string;
}

const MainCardLayout = ({
  children,
  gainsboro,
  className,
}: MainCardLayoutProps) => {
  return (
    <div
      className={` ${className}
        ${gainsboro ? "bg-[#f8f8f8]" : "bg-pure-white"} phone:px-4 phone:py-8 phone:text-left
        web:p-8 web:text-center `}
    >
      <div className="lg:max-w-1216 mx-auto">
      {children}
      </div>
    </div>
  );
};
export default MainCardLayout;
