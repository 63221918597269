import React from 'react';
import Icons from './Icons';

const ComingSoon = () => {
    return (
        <div className='flex flex-col text-center items-center justify-center my-40 max-w-[678px] mx-auto'>
            <Icons className='w-64 h-64' iconName="website-design" extension='png' />
            <div className='text-5xl font-bold mt-16 mb-8'>해당 서비스는 <span className='text-main-green'>오픈 준비중</span> 입니다.</div>
            <div className='font-bold text-2xl'>
                현재 페이지는 보다 좋은 서비스를 제공하기 위해 준비중에 있습니다.<br/>
                빠른 시일내에 준비하여 찾아 뵙도록 하겠습니다.
            </div>
        </div>
    );
};

export default ComingSoon;