import { createSlice } from "@reduxjs/toolkit";
import { commonData } from "redux/action/common";


export interface common {
    data: Object;
    countryCodeMap: Object;
    currency: Object
    language: Object
}

const initialState = {
    data: {}
}


const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(commonData.fulfilled, (state, action) => {
                state.data = action.payload
            })
    }
})

export default commonSlice.reducer