import ToolTip from "elements/ToolTip";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AddButton from "../../elements/AddButton";

interface HostWrapProps {
  tooltipHidden?: boolean;
  title?: string;
  subTitle?: string;
  className?: string;
  mainClass?: string;
  subClass?: string;
  onClick?: () => void;
  onChange?: (e: any) => void;
  addBtn?: boolean;
  host?: boolean;
  addBtnTitle?: any;
  column?: boolean;
  children?: any;
  placeholder?: string;
  toolTitle?: string;
  toolSubTitle?: string;
  toolEx?: string;

  help?: boolean;
}

const HostWrap = ({
  onClick,
  toolTitle,
  toolSubTitle,
  toolEx,
  title,
  subTitle,
  subClass,
  addBtn,
  host,

  addBtnTitle,
  mainClass,
  children,
  column,

  help,
  tooltipHidden
}: HostWrapProps) => {
  const { t } = useTranslation();
  const [helpWrap, setHelpWrap] = useState(false);

  const outSide = useRef<any>();

  useEffect(() => {
    const clickOutside = (e: any) => {
      if (helpWrap && outSide.current && !outSide.current.contains(e.target)) {
        setHelpWrap(false);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  });
  return (
    <div>
      <div className="web:block">
        <ToolTip
        className={tooltipHidden || (!toolTitle && !toolEx && !toolSubTitle)  ? "tooltipHidden" : "tooltipContent"}
          toolTitle={toolTitle}
          toolEx={toolEx}
          toolSubTitle={toolSubTitle}
        >
          <div
            className={`${mainClass} ${
              mainClass ? "" : "web:mt-12 phone:mt-8"
            } web:w-160 phone:min-w-[full]`}
          >
            <div className="web:inline-block phone:block">
              <div className="flex items-center">
                {title ? (
                  <div className="text-base font-bold">
                    {t(`${title}`)}
                  </div>
                ) : (
                  ""
                )}
                {help ? (
                  <img
                    onClick={() => setHelpWrap((helpWrap) => !helpWrap)}
                    alt=""
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/question.svg"
                    className="phone:block web:hidden ml-2 w-4 cursor-pointer h-4"
                  />
                ) : (
                  ""
                )}
                {helpWrap ? (
                  <div
                    className="w-full h-4 absolute"
                    ref={outSide}
                    onClick={(e) => {
                      if (outSide.current === e.target) {
                        setHelpWrap(false);
                      }
                    }}
                  >
                    <div className="transition-all absolute w-52 right-0 rounded-lg min-h-[160px] top-0 p-2 bg-toolTipBg z-[1000]">
                      <div className="font-bold">{toolTitle ? toolTitle : ""}</div>
                      {toolTitle ? <hr className="my-2 text-silver" /> : ""}
                      <div className="my-2 text-sm">{toolSubTitle ? toolSubTitle : ""}</div>
                      <div className="text-xs text-input-text">{toolEx ? toolEx : ""}</div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {subTitle ? <div className={`${help ? "ml-2" : ""} text-sm`}>{t(subTitle)}</div> : ""}
                {addBtn ? (
                  <AddButton
                    onClick={onClick}
                    className="ml-2"
                    title={addBtnTitle}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={`web:flex ${
                column ? "flex-col" : "flex-row"
              } ${subClass} ${subClass ? "" : "web:w-160"} `}
            >
              {children}
            </div>
          </div>
        </ToolTip>
      </div>
    </div>
  );
};

export default HostWrap;
