import Button from "elements/Button";
import Icons from "elements/Icons";
import Modal from "elements/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

interface hostBookingListCardProps {
  status?: string;
  traveler?: string;
  listing?: string;
  checkIn?: string;
  checkOut?: string;
  bookingNum?: string;
  itemNum?: string;
  pgId?: string;
  unitAmount?: string;
  extraOptions?: any;
}

const HostBookingListCard = ({
  status,
  traveler,
  listing,
  checkIn,
  checkOut,
  bookingNum,
  itemNum,
  pgId,
  unitAmount,
  extraOptions,
}: hostBookingListCardProps) => {
  const { t } = useTranslation();
  const Ids = { reserveId: itemNum, pgId: pgId };

  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const navigate = useNavigate();

  const closeModal = () => {
    setInfoModalOpen((infoModalOpen: any) => !infoModalOpen);
  };

  const requestAccept = () => {
    setInfoModalOpen(false);
    setAcceptModalOpen(true);
  };
  const requestDecline = () => {
    setInfoModalOpen(false);
    setDeclineModalOpen(true);
  };

  const isAcceptModalOpen = () => {
    setAcceptModalOpen((acceptModalOpen) => !acceptModalOpen);
  };
  const isDeclineModalOpen = () => {
    setDeclineModalOpen((declineModalOpen) => !declineModalOpen);
  };

  const decline = () => {
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v2/booking/response",
      data: { accepted: false, reservationId: itemNum },
    })
      .then((res) => {
        setDeclineModalOpen(false);
        window.location.reload();
        return res;
      })
      .catch((err) => {});
  };
  const accept = () => {
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v2/booking/response",
      data: { accepted: true, reservationId: itemNum },
    })
      .then((res) => {
        setAcceptModalOpen(false);
        window.location.reload();
        return res;
      })
      .catch((err) => {});
  };

  return (
    <>
      {infoModalOpen ? (
        <Modal
          headerWrap
          setModalOpen={() => {
            setInfoModalOpen(!infoModalOpen);
          }}
          headerTitle="Booking-status-data.Respond to booking request"
        >
          <div className="flex mb-4 text-sm">
            <Icons className="mr-2 w-5 h-5" iconName="booking-box" />
            <div>
              {t("Booking-box-status.Booking request from is as follows", {
                Traveler: traveler,
              })}
            </div>
          </div>
          <div className="text-left">
            <div className="flex text-sm mb-4">
              <div className="w-39 mr-4 text-input-text">
                {t("Booking-box-status.Booking #")}
              </div>
              <div className="text-link">{bookingNum}</div>
            </div>
            <div className="flex text-sm mb-4">
              <div className="w-39 mr-4 text-input-text">
                {t("Booking-box-status.Schedule")}
              </div>
              <div className="w-[162px] mr-4">
                <div className="text-xs text-input-text mb-1">
                  {t("Booking-box-status.Check-in")}
                </div>
                <div className="font-bold">{checkIn}</div>
              </div>
              <div>
                <div className="text-xs text-input-text mb-1">
                  {t("Booking-box-status.Check-out")}
                </div>
                <div className="font-bold">{checkOut}</div>
              </div>
            </div>
            <div className="flex text-sm mb-4 font-bold">
              <div className="w-39 mr-4 font-bold text-input-text">
                {t("Booking-box-status.Ground Unit")}
              </div>
              <div className="w-[162px] mr-4 whitespace-normal">{listing}</div>
              <div>X {unitAmount}</div>
            </div>
            <div className="flex text-sm mb-4">
              <div className="w-39 mr-4 font-bold text-input-text">
                {t("Booking-box-status.Extra Option(s)")}
              </div>
              <div>
                {extraOptions?.map(({ name, amount }: any, i: number) => {
                  if (amount !== 0) {
                    return (
                      <div className="flex font-bold" key={i}>
                        <div className="w-[162px] mr-4">{name}</div>
                        <div className="w-[162px]">X {amount}</div>
                      </div>
                    );
                  } else {
                    return <div className="font-bold">-</div>;
                  }
                })}
              </div>
            </div>
          </div>
          <div className="flex text-sm items-center mb-8">
            <Icons className="mr-2 w-4 h-4" iconName="pending" />
            <div>
              {t(
                "Booking-box-status.Please respond to this booking request before it expires in 24 hours."
              )}
            </div>
          </div>
          <div className="flex justify-between">
            <Button
              onClick={() => {
                requestAccept();
              }}
              className="text-pure-white bg-light-green rounded-lg w-39 h-10 justify-center text-sm "
              btnText={"Booking-box-status.Accept"}
              isIcon
              src={"status-confirmed-approve"}
              iconClassName="mr-2 fill-pure-white"
            />
            <Button
              onClick={() => {
                requestDecline();
              }}
              className="text-pure-red rounded-lg w-39 h-10 justify-center text-sm "
              btnText={"Booking-box-status.Decline"}
              isIcon
              src={"status-cancelled-void"}
              iconClassName="mr-2 fill-pure-red"
            />
          </div>
        </Modal>
      ) : (
        ""
      )}

      {declineModalOpen ? (
        <Modal className="p-8">
          <img
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-cancelled-void.svg"
            style={{ fill: "black" }}
            alt=""
            className="w-32 h-32 mx-auto"
          />
          <div className="font-bold mt-8 mb-4">
            {t("Booking-box-status.Decline this booking request?")}
          </div>
          <div className="text-sm mb-8 text-border-black">
            {t("Booking-box-status.Once you decline this booking request")}
          </div>
          <div className="flex justify-between w-full">
            <Button
              onClick={() => decline()}
              className="text-pure-red rounded-lg w-39 h-10 justify-center text-sm "
              btnText={"Booking-box-status.Decline"}
              isIcon
              src={"status-cancelled-void"}
              iconClassName="mr-2 fill-pure-red"
            />
            <Button
              onClick={() => isDeclineModalOpen()}
              className="items-center flex rounded-lg w-39 h-10 justify-center text-sm "
              btnText={"Booking-box-status.Cancel"}
            />
          </div>
        </Modal>
      ) : (
        ""
      )}
      {acceptModalOpen ? (
        <Modal className="p-8">
          <img
            className="w-32 h-32 mx-auto"
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-confirmed-approve.svg"
            alt=""
          />
          <div className="font-bold mt-8 mb-4">
            {t("Booking-box-status.Accept this booking request?")}
          </div>
          <div className="text-sm mb-8 text-border-black">
            {t("Booking-box-status.Once you accept this booking request")}
          </div>
          <div className="flex justify-between w-full">
            <Button
              onClick={() => accept()}
              className="text-pure-white bg-light-green rounded-lg w-39 h-10 justify-center text-sm "
              btnText={"Booking-box-status.Accept"}
              isIcon
              src={"status-confirmed-approve"}
              iconClassName="mr-2 fill-pure-white"
            />
            <Button
              onClick={() => isAcceptModalOpen()}
              className="items-center flex rounded-lg w-39 h-10 justify-center text-sm "
              btnText={"Booking-box-status.Cancel"}
            />
          </div>
        </Modal>
      ) : (
        ""
      )}
      <tbody>
        <tr>
          {status === "PENDING" ? (
            <td
              className="p-4 hover:underline cursor-pointer phone:text-tan web:text-black hover:text-tan"
              onClick={() => {
                closeModal();
              }}
            >
              {t("Booking-status-data.Respond")}
            </td>
          ) : status === "REQUEST_CANCELLED" ||
            status === "CANCELLED_BY_TRAVELER" ||
            status === "CANCELLED_BY_HOST" ||
            status === "CANCELLED_BY_ADMIN" ? (
            <td className="text-pure-red p-4">
              {t("Booking-status-data.Cancelled")}
            </td>
          ) : status === "DECLINED" ? (
            <td className="text-pure-red p-4">
              {t("Booking-status-data.Cancelled")}
            </td>
          ) : status === "PAYMENT_ERROR" || status === "FAILED" ? (
            <td className="text-pure-red p-4">
              {t("Booking-status-data.Failed")}
            </td>
          ) : status === "CONFIRMED" ? (
            <td className="text-light-green p-4">
              {t("Booking-status-data.Confirmed")}
            </td>
          ) : status === "COMPLETED" ? (
            <td className="text-link p-4">
              {t("Booking-status-data.Completed")}
            </td>
          ) : (
            ""
          )}
          <td className="p-4">{traveler}</td>
          <td className="p-4">{listing}</td>
          <td className="p-4">{checkIn}</td>
          <td className="p-4">{checkOut}</td>
          <td className="p-4">{bookingNum}</td>
          <td className="p-4">
            <img
              onClick={() => {
                navigate(`/host/${itemNum}`, { state: Ids });
              }}
              className="w-6 h-6 mx-auto cursor-pointer"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/voucher.svg"
              alt=""
            />
          </td>
        </tr>
        {/* <div className="flex text-sm items-center p-4">
          <div
            className={`w-[112px] mr-[11px] ${
              status === "PENDING"
                ? "bg-pending rounded-lg p-2 text-pure-white"
                : ""
            }`}
          >
            {status === "PENDING" ? (
              <div className="flex font-bold cursor-pointer">
                <Icon className="fill-pure-white mr-2" src="pending" />
                <div
                  onClick={() => {
                    closeModal();
                  }}
                  className="hover:underline"
                >
                  Respond
                </div>
              </div>
            ) : status === "REQUEST_CANCELLED" ||
              status === "CANCELLED_BY_TRAVELER" ||
              status === "CANCELLED_BY_HOST" ||
              status === "CANCELLED_BY_ADMIN" ? (
              <div className="flex font-bold text-pure-red">
                <div>Cancelled</div>
              </div>
            ) : status === "DECLINED" ? (
              <div className="flex font-bold text-pure-red">
                <div>Cancelled</div>
              </div>
            ) : status === "PAYMENT_ERROR" ? (
              <div className="flex font-bold text-pure-red">
                <div>Failed</div>
              </div>
            ) : status === "CONFIRMED" ? (
              <div className="flex text-light-green font-bold">
                <div>Confirmed</div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="w-[140px] mr-[13px]">{traveler}</div>
          <div className="w-[161px] mr-[13px]">{listing}</div>
          <div className="w-[100px] mr-[13px]">{checkIn}</div>
          <div className="w-[100px] mr-[13px]">{checkOut}</div>
          <div className="w-[95px] mr-[13px]">{bookingNum}</div>
          <div
            onClick={() => {
              navigate(`/host/${itemNum}`, { state: itemNum });
            }}
            className="flex rounded-lg items-center py-2 px-[6px] cursor-pointer border border-main-green"
          >
            <div className="text-main-green font-bold">Details</div>
          </div>
        </div> */}
      </tbody>
    </>
  );
};
export default HostBookingListCard;
