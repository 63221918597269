import React from "react";

import Button from "elements/Button";


import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icons from "elements/Icons";

interface AuthUtilProps {
  onClick?: () => void;
  className?: string;
  AuthIcon?: any;
  title?: string;
  Red?: boolean;
  email?: any;
  subTitle?: string;
  content?: string;
  and?: boolean;
}
const AuthUtil = ({
  onClick,
  className,
  AuthIcon,
  title,
  Red,
  email,
  and,
  subTitle,
  content,
}: AuthUtilProps) => {
  const { t } = useTranslation();
  return (
    <div className="text-center max-w-[500px] mx-auto">
      <Icons
        iconName={AuthIcon}
        className={`fill-light-green w-40 h-40 mt-8 text-center inline-block`}
      />
      <div className="text-pure-white">
        {Red ? (
          <div className="my-8 text-2xl text-pure-red font-bold">
            {t(`Account.${title}`)}
          </div>
        ) : (
          <div className="my-8 text-2xl text-light-green font-bold">
            {t(`Account.${title}`)}
          </div>
        )}
        {subTitle ? <div className="mb-4">{t(`Account.${subTitle}`)}</div> : ""}
        {and ? <span className="mb-4">&</span> : ""}
        {email ? <span>{email}</span> : ""}
        {content ? <div className="my-4">{t(`Account.${content}`)}</div> : ""}
      </div>
    </div>
  );
};

export default AuthUtil;
