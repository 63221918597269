import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import BookingWrap from "components/Booking/bookingWrap";

import Button from "elements/Button";
import DetailInfo from "elements/DetailInfo";
import HostInput from "elements/HostInput";
import { changecase } from "utils/changeCase";
import { inputPriceComma } from "utils/commaDash";

const AcBookingRequest = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state }: any = useLocation();

  const detailData = state.detailData;

  const { checkIn, checkOut, policyInfo, extraPolicy, advanced } =
    state.detailData;

  const {
    startDate,
    endDate,
    extraGuest,
    selectedDatePrice,
    carAmount,
    extraOption,
  } = state.selectedData;

  const {
    title,
    availableExtraGuest,
    extraCarCharge,
    imageList,
    totalGuestAmount,
    minStay,
    bedAmount,
    spaceSize,
    typeInfo,
    description,
  } = state.selectedSpace;

  return (
    <div className="lg:max-w-1216 xl:max-w-7xl px-8 mx-auto pb-16">
      <div className="flex text-3.5xl items-center my-9">
        <img
          alt=""
          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/mobile-bookings.svg"
          className="w-12 h-12 mr-6"
        />
        {t("booking.Booking Request")}
      </div>
      <div className="flex">
        <div className="w-[800px]">
          <BookingWrap size="M" title="Booking Details" className="mb-[67px]">
            <div className="w-[full] h-40 mb-8 rounded-lg flex text-left">
              <img
                className="w-66 h-full rounded-lg mr-2"
                src={imageList[0]?.url}
                alt=""
              />
              <div className="flex-1 flex justify-between flex-col py-2">
                <div className="font-bold">
                  <span className="text-main-green text-xl">{title}</span> at{" "}
                  {detailData?.title}
                </div>
                <div>
                  <div className="mb-4 flex items-center">
                    <img
                      className="w-5 h-5 mr-2"
                      src={typeInfo?.iconUrl}
                      alt=""
                    />
                    <div>{typeInfo?.enName}</div>
                  </div>
                  <div className="max-w-[382px] w-full flex justify-between">
                    <div className="flex justify-between">
                      <img
                        className="w-5 h-5 mr-2"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/guest-occupancy.svg"
                        alt=""
                      />
                      <div>X {totalGuestAmount}</div>
                    </div>
                    <div className="flex justify-between">
                      <img
                        className="w-5 h-5 mr-2"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/bed.svg"
                        alt=""
                      />
                      <div>X {bedAmount}</div>
                    </div>
                    <div className="flex justify-between">
                      <img
                        className="w-5 h-5 mr-2"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/min-stay.svg"
                        alt=""
                      />
                      <div>X {minStay} Days</div>
                    </div>
                    <div className="flex justify-between">
                      <img
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                        className="w-5 h-5 mr-2"
                        alt=""
                      />
                      <div>{spaceSize} ㎡</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DetailInfo withIcon w276 title="Schedule">
              <div className="w-[272px]">
                <div className="text-grey text-sm">Check in</div>
                <div className="font-bold">{startDate}</div>
              </div>
              <div className="flex-1 ">
                <div className="text-grey text-sm">Check out</div>
                <div className="font-bold">{endDate}</div>
              </div>
            </DetailInfo>
            <DetailInfo withIcon w276 title="Guest">
              {extraGuest?.map(
                (
                  item: {
                    current: number;
                    price: string;
                    title: string;
                    type: string;
                  },
                  idx: number
                ) => {
                  const title = item.title;
                  const current = item.current;
                  return (
                    <div className="flex items-center mb-2 last:mb-0" key={idx}>
                      <div className="w-[272px]">{title}</div>
                      <div className="flex-1">X {current}</div>
                    </div>
                  );
                }
              )}
            </DetailInfo>
            <DetailInfo withIcon w276 title="Parking">
              <div className="flex items-center">
                <div className="w-[272px]">Total Number of Car</div>
                <div className="flex-1">X {carAmount}</div>
              </div>
            </DetailInfo>
            <DetailInfo w276 title="Extra Option(s)" className="font-bold">
              {extraOption &&
                extraOption.map((item: any, key: number) => {
                  const title = item.title;
                  const amount = item.amount;
                  if (item !== undefined && item.amount !== 0) {
                    return (
                      <div className="flex items-center" key={key}>
                        <div className="w-[272px]">
                          <div>{title}</div>
                        </div>
                        <div className="flex-1">
                          <div>X {amount}</div>
                        </div>
                      </div>
                    );
                  }
                })}
            </DetailInfo>
          </BookingWrap>
          <BookingWrap
            title="Booking Request By (Traveler)"
            size="M"
            className="mb-16"
          >
            <DetailInfo w276 title="Name*" className="mb-4">
              <HostInput
                input
                // inputValue={localName ? localName : ""}
                // disabled={localName ? true : false}
                // onChange={(e: any) => {
                //   setName(e.target.value);
                // }}
                className="web:w-[512px]"
                nomargin
              />
            </DetailInfo>
            <DetailInfo w276 title="Email Address*" className="mb-4">
              <HostInput
                input
                // inputValue={localEmail ? localEmail : ""}
                // disabled={localEmail ? true : false}
                // onChange={(e: any) => {
                //   setEmail(e.target.value);
                // }}
                className="web:w-[512px]"
                nomargin
              />
            </DetailInfo>
            <DetailInfo w276 title="Contact Number*" className="mb-4">
              <HostInput
                select
                placeholder="Select Country Code"
                // disabled={preValue?.length !== 0 ? true : false}
                // selected={mobileCode ? mobileCode : preValue}
                // onChange={(e: any) => {
                //   setMobileCode(e.target.value);
                // }}
                // options={countryCode}
                className="web:w-[160px] mr-[15px]"
                nomargin
              />
              <HostInput
                placeholder="Your Contact Number"
                input
                type="text"
                // disabled={localPhone ? true : false}
                // inputValue={phoneNum ? phoneNum : localPhone}
                // onChange={(e: any) => {
                //   setPhoneNum(e.target.value);
                // }}
                className="web:w-[335px]"
                nomargin
              />
            </DetailInfo>
            <DetailInfo w276 title="Plate Number" nomargin>
              <HostInput
                input
                // onChange={(e: any) => {
                //   setPlateNum(e.target.value);
                // }}
                className="web:w-[512px]"
                nomargin
              />
            </DetailInfo>
          </BookingWrap>
          <BookingWrap title="Policy" size="M">
            <div className="w-full rounded-lg border-input-text p-2 bg-[#eee] mb-8">
              <div className="flex mb-2">
                <img
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                  alt=""
                  className="mr-2"
                />

                {t("package.Notice")}
              </div>
              <div className="text-sm">
                {t(
                  `policy.Must Outdoor will enforce following cancellation policy`
                )}
              </div>
            </div>
            <DetailInfo withIcon w276 title="Check In / Out">
              <div className="mr-[141px]">
                <div>Check In : {checkIn}</div>
              </div>
              <div>
                <div>Check Out : {checkOut}</div>
              </div>
            </DetailInfo>
            <DetailInfo withIcon w276 title="Confirmation">
              <div className="mb-2">
                {t(
                  "policy.You will receive confirmation of your booking availability",
                  { confirm: "24" }
                )}
              </div>
              <div>
                {t(
                  "policy.In the event that you do not receive an email from us"
                )}
              </div>
            </DetailInfo>
            <DetailInfo withIcon w276 title="Advance Notice">
              {advanced
                ? t("booking.Booking is not available today")
                : t("booking.Booking is available today")}
            </DetailInfo>
            <DetailInfo withIcon w276 title="Additional Rule / Policy">
              {extraPolicy ? extraPolicy : <div>{t("common.N/A")}</div>}
            </DetailInfo>
            <DetailInfo
              withIcon
              w276
              nomargin
              noFlex
              title="Cancellation & Refund Policy"
            >
              <div className="font-bold mb-4 flex">
                <div className="text-pure-red">
                  {t(policyInfo && `policy.${changecase(policyInfo.name)}`)}{" "}
                </div>
                <div>{t("Booking-request.Policy on Cancellation")}</div>
              </div>
              <div>
                {policyInfo?.firstRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}100% refund of the payment",
                      { days: policyInfo?.firstRange }
                    ) + "\n"
                  : ""}
                {policyInfo?.secondRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}70% refund of the payment",
                      { days: policyInfo?.secondRange }
                    ) + "\n"
                  : ""}
                {policyInfo?.thirdRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}50% refund of the payment",
                      { days: policyInfo?.thirdRange }
                    ) + "\n"
                  : ""}
                {policyInfo?.fourthRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}30% refund of the payment",
                      { days: policyInfo?.fourthRange }
                    ) + "\n"
                  : ""}
                {policyInfo?.fifthRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}0% refund of the payment",
                      { days: policyInfo?.fifthRange }
                    )
                  : ""}
              </div>
            </DetailInfo>
          </BookingWrap>
        </div>
        <div className="w-80 ml-8">
          <BookingWrap size="S" title="Payment Details" className="mb-6">
            <div className="mb-4">
              <div className="font-bold mb-4">{t("host.Space Charge")}</div>
              <div>
                <div className="flex justify-between text-sm">
                  <div className="max-w-[150px]">
                    <div>space Title</div>
                    <div className="text-xs text-input-text mt-1">
                      x 1 Space
                    </div>
                  </div>
                  <div>
                    {inputPriceComma(selectedDatePrice)}원
                    {/* {t("common.원")}{" "}
                    {inputPriceComma(
                      String(
                        extra.length !== 0
                          ? Number(totalAmount) - Number(extraPrice)
                          : totalAmount
                      )
                    )} */}
                  </div>
                </div>
              </div>
              <hr className="text-input-text my-4" />
            </div>
            <div className="mb-4">
              <div className="font-bold mb-4">
                {t("host.Extra Guest Charge")}
              </div>
              {extraGuest?.map(
                (
                  item: {
                    current: number;
                    price: string;
                    title: string;
                    type: string;
                  },
                  idx: number
                ) => {
                  const title = item.title;
                  const current = item.current;
                  const price = item.price;
                  return (
                    <div
                      className="flex justify-between text-sm mb-2 last:mb-0"
                      key={idx}
                    >
                      <div className="max-w-[150px]">
                        <div>{title}</div>
                        <div className="text-xs text-input-text mt-1">
                          {current} Extra Guest
                        </div>
                      </div>
                      <div>
                        {inputPriceComma(String(Number(price) * current))}원
                      </div>
                    </div>
                  );
                }
              )}
              <hr className="text-input-text my-4" />
            </div>
            <div className="mb-4">
              <div className="font-bold mb-4">{t("host.Extra Car Charge")}</div>
              <div>
                <div className="flex justify-between text-sm">
                  <div className="max-w-[150px]">
                    <div>Extra Car</div>
                    <div className="text-xs text-input-text mt-1">
                      {carAmount} Extra Guest
                    </div>
                  </div>
                  <div>
                    {inputPriceComma(
                      String(Number(extraCarCharge) * carAmount)
                    )}{" "}
                    원
                  </div>
                </div>
              </div>
              <hr className="text-input-text my-4" />
            </div>
            <div className="mb-4">
              <div className="font-bold mb-4">{t("host.Sub Total")}</div>
              <div>
                <div className="flex justify-between text-sm">
                  <div className="max-w-[150px]">Child (age 4-12)</div>
                  <div>10000원</div>
                </div>
              </div>
              <hr className="text-input-text my-4" />
            </div>
            <div className="mb-4">
              <div className="font-bold mb-4">{t("host.Extra Option(s)")}</div>
              {extraOption &&
                extraOption?.map((item: any, idx: number) => {
                  const title = item.title;
                  const amount = item.amount;
                  const price = Number(item.price);
                  return (
                    <div key={idx}>
                      <div className="flex justify-between text-sm">
                        <div className="max-w-[150px]">
                          <div>{title}</div>
                          <div className="text-xs text-input-text mt-1">
                            $ {price} x {amount}
                          </div>
                        </div>
                        <div>{inputPriceComma(String(amount * price))}원</div>
                      </div>
                    </div>
                  );
                })}
              <hr className="text-input-text my-4" />
            </div>
            <div className="flex justify-between font-bold">
              <div>{t("booking.Total Amount")}</div>
              <div>{t("common.원")} 100</div>
            </div>
          </BookingWrap>
          <Button
            // onClick={() => {
            //   bookingCheck();
            // }}
            className="w-full font-bold rounded-lg bg-light-green text-pure-white text-center py-3"
            btnText={"NEXT"}
          />
        </div>
      </div>
    </div>
  );
};

export default AcBookingRequest;
