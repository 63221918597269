import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface CheckBoxProps {
  content: any;
  className?: string;
  subContent?: string;
  underline?: string;
  icon?: boolean;
  name?: string;
  checkImg?: any;
  defaultChecked?:any;
  onChange?: (e: any) => void
  onClick?: (e:any) => void
  checked?: any;
  value?: string;
  url?: any;
}

function CheckBox({
  content,
  icon,
  onClick,
  name,
  checkImg,
  defaultChecked,
  className,
  checked,
  subContent,
  value,
  onChange,
  url,
}: CheckBoxProps) {
  const navigate = useNavigate();
  return (
    <>
      <div  className={`inputWrap ${className} flex ${icon ? 'mb-4 mr-8' : 'mb-1'} items-center`}>
        <input defaultChecked={defaultChecked} name={name} onClick={onClick} value={value} onChange={onChange} checked={checked} type="checkbox" />
        {checkImg ? (
          <img className="w-6 h-6 mr-1" src={checkImg} alt="" />
        ) : ""}
        <label>
          {content}
          {subContent ? 
            <span onClick={() => {
                navigate(url)
            }} className="font-bold cursor-pointer underline">
              {subContent}
            </span> : ''}
        </label>
      </div>
    </>
  );
}

export default CheckBox;
