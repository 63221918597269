import React from "react";
import ReactDOM from "react-dom/client";

import ScrollToTop from "utils/scrollTop";
import { BrowserRouter } from "react-router-dom";

import { CookiesProvider } from "react-cookie";
import { createBrowserHistory } from "history";

import "./index.css";
import App from "./App";

import { I18nextProvider } from "react-i18next";
import i18n from "locales/i18n";

import { GoogleOAuthProvider } from '@react-oauth/google';

import { QueryClientProvider, QueryClient } from "react-query";
import { store } from "./redux/store";
import { Provider } from "react-redux";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const history = createBrowserHistory();
// 리다이렉트 [#! 에러 뜨면 해당 페이지로 이동]
if (root) {
  const path = (/#!(\/.*)$/.exec(window.location.href) || [])[1];
  if (path) {
    history.replace("http://dev-fe.mustoutdoor.com" + path);
  }

  root.render(
    <I18nextProvider i18n={i18n}>
      <GoogleOAuthProvider clientId={"448692888620-i2vuav37q7s1fu6hhov743764nn1non2.apps.googleusercontent.com"}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <BrowserRouter>
              <CookiesProvider>
                <ScrollToTop />
                <App />
              </CookiesProvider>
            </BrowserRouter>
          </Provider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </I18nextProvider>
  );
}
