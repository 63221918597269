export const changeDate = (date:any) => {
    const dateValue = new Date(date);
    const year = dateValue?.getFullYear();
    const month = dateValue?.getMonth() + 1;
    const _day = dateValue?.getDate().toString();
    const _dateValue =
    year +
    "-" +
    ("00" + month.toString()).slice(-2) +
    "-" +
    ("00" + _day).slice(-2)
    return _dateValue
}

export const changeDataGetAll = (date:any) => {
    const dateValue = new Date(date);
    const year = dateValue.getFullYear();
    const month = ('0' + (dateValue.getMonth() + 1)).slice(-2);
    const day = ('0' + dateValue.getDate()).slice(-2);
    const hours = ('0' + dateValue.getHours()).slice(-2); 
    const minutes = ('0' + dateValue.getMinutes()).slice(-2);
    const seconds = ('0' + dateValue.getSeconds()).slice(-2); 

    const dateString = year + '-' + month  + '-' + day + " " + hours + ':' + minutes  + ':' + seconds;
    return dateString
}

export const returnDate = (date:any) => {
    const dateValue = new Date(date);
    const year = dateValue?.getFullYear();
    const month = dateValue?.getMonth() + 1;
    const _day = dateValue?.getDate().toString();
    const result = `${year}-${month}-${_day}`
    return result
}