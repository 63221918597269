import React from 'react';

const DestinationImage = () => {
let imageUrl = `https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/Partner-with-us-2.jpg`
  return  (
  <div>
    <div className="w-full web:h-[300px] phone:h-32 bg-center bg-cover" style={{backgroundImage:`url(${imageUrl})` }} />
  </div>
)};

export default DestinationImage;
