import React, { useEffect, useState } from "react";

import { languages } from "locales/i18n";
import { useTranslation } from "react-i18next";
import { useLanguage } from "hooks/useLanguage";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/store";

import { logout } from "redux/slice/userSlice";

import HeaderAccount from "./HeaderAccount";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

import SideMenu from "elements/sideMenu";
import Icons from "elements/Icons";
import FullSizeModal from "elements/fullSizeModal";
import HostingTypeCard from "elements/HostingTypeCard";
import ToastAlert from "elements/ToastAlert";

const Header = () => {
  const pathName = window.location.pathname;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onChangeLanguage } = useLanguage();

  const [logOutToastAlert, setLogOutToastAlert] = useState(false);

  const [burgerOpen, setBurgerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [country, setCountry] = useState<any>([]);
  const [state, setState] = useState<any>([]);
  const [city, setCity] = useState<any>([]);

  const [currency, setCurrency] = useState("");
  const [searchTab, setSearchTab] = useState(false);

  const [openCountry, setOpenCountry] = useState<any>([]);

  const [typeSelectModal, setTypeSelectModal] = useState(false);

  const changelang = (code: string) => {
    onChangeLanguage(code);
  };

  const language = localStorage.getItem("i18nextLng");
  const currencyValue = localStorage.getItem("currency");
  const isLogin = !!localStorage.getItem("isLogin");
  const status = localStorage.getItem("STATUS");
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");
  const name = localStorage.getItem("name");
  const profile = localStorage.getItem("profile");

  const itemStatus = JSON.parse(localStorage.getItem("itemStatus") as string);

  const currentType = localStorage.getItem("currentType");

  const acInfo = itemStatus?.acInfo !== null;
  const atInfo = itemStatus?.atInfo !== null;
  const pgInfo = itemStatus?.pgInfo !== null;

  const selectCard = [
    {
      id: 1,
      title: "Private Ground",
      src: "partner-private-ground",
      subTitle: "This type is suitable for-private ground",
      content: "You can make a profit by utilizing private property",
      timeRequired: "During Time PG",
      info: "Edit Private Ground information",
      bizType: "PG",
      hasBtn: "You have a registered private ground.",
      commonBtn: "Start Hosting",

      bizStatus: pgInfo,
    },
    {
      id: 2,
      title: "Outdoor Accommodation",
      src: "partner-accommodation",
      subTitle: "This type is suitable for-outdoor accommodation",
      content: "Share eco-friendly accommodations",
      info: "Edit Outdoor Accommodation information",
      timeRequired: "During Time AC",
      bizType: "AC",
      hasBtn: "You have a registered accommodation.",
      commonBtn: "Start Hosting",

      bizStatus: acInfo,
    },
    // {
    //   id: 3,
    //   title: "Outdoor Activity",
    //   src: "partner-activity",
    //   subTitle: "This type is suitable for-outdoor activity",
    //   content: "Share all activities globally",
    //   info: "Edit Outdoor Activity information",
    //   timeRequired: "During Time AT",
    //   bizType: "AT",
    //   hasBtn: "You have a registered activity.",
    //   commonBtn: "Start Hosting",

    //   bizStatus: undefined,
    // },
  ];

  const switchToHost = () => {
    setTypeSelectModal(true);
    // localStorage.setItem("STATUS", "HOST");
    // if (PG === "true") {
    //   navigate("host/myprivate", { replace: true });
    // } else if (AC === "true")
    //   navigate("host/myaccommodation", { replace: true });
  };

  const switchToTraveler = () => {
    localStorage.removeItem("currentType");
    localStorage.setItem("STATUS", "MEMBER");
    navigate("/", { replace: true });
  };

  const burgerClick = () => {
    setBurgerOpen((burgerOpen) => !burgerOpen);
  };

  const countryResult = country?.filter(
    (v: any, i: any) => country.indexOf(v) === i
  );
  const stateResult = state?.filter((v: any, i: any) => state.indexOf(v) === i);

  const List = [
    {
      name: countryResult,
      stateList: [
        stateResult?.map((stateItem: string) => {
          return {
            name: stateItem,
            cityList: city?.map((cityItem: any) => {
              if (stateItem === cityItem?.state) {
                return {
                  city: cityItem?.city,
                  country: cityItem?.country,
                  description: cityItem?.description,
                  id: cityItem?.id,
                  imgUrl: cityItem?.imgUrl,
                  name: cityItem?.name,
                  state: cityItem?.state,
                };
              }
            }),
          };
        }),
      ],
    },
  ];

  useEffect(() => {
    requestToMustOutdoor("/guest/main/choice/destination")
      .then((res) => {
        const data = res.data.data;
        data.map((item: any) => {
          setCountry((pre: any) => [...pre, item.country]);
          setState((pre: any) => [...pre, item.state]);
          setCity((pre: any) => [
            ...pre,
            {
              state: item.state,
              name: item.name,
              description: item.description,
              imgUrl: item.imageUrl,
              country: item.country,
              city: item.city,
              id: item.id,
            },
          ]);
        });
        return res.data.data;
      })
      .catch((err) => {});
  }, []);

  const searchToggle = () => {
    setSearchTab((searchTab) => !searchTab);
  };

  return (
    <div
      className={`${
        pathName === "/" ||
        pathName === "/host/myprivate" ||
        pathName === "/host/myaccommodation" ||
        pathName === "/about" ||
        pathName === "/partner" ||
        pathName === "/how-it-works" ||
        pathName === "/faq" ||
        pathName === "/contact" ||
        pathName === "/terms" ||
        pathName === "/privacy-policy" ||
        pathName.split("/")[1] === "/log-in" ||
        pathName.split("/")[1] === "/sign-up" ||
        pathName.split("/")[1] + "/" + pathName.split("/")[2] === "search/type"
          ? "web:block phone:block"
          : "web:block phone:hidden"
      }`}
    >
      {logOutToastAlert ? (
        <ToastAlert
          text={"😄 로그아웃 되었습니다."}
          type="green"
          setToastState={setLogOutToastAlert}
        />
      ) : (
        ""
      )}
      {typeSelectModal ? (
        <FullSizeModal
          isOpen={() => {
            localStorage.setItem("STATUS", "MEMBER");
            setTypeSelectModal((typeSelectModal) => !typeSelectModal);
          }}
          selectHostingType
        >
          <div className="web:mb-16 phone:mb-8 text-center text-pure-white">
            <div className="web:mb-8 web:text-[28px] phone:mb-4 phone:text-xl font-bold">
              {t("Host-dashboard.Host Dashboard")}
            </div>
            <div>
              {t("Host-dashboard.Please select the type you want to switch")}
            </div>
          </div>
          <div className="flex web:flex-row phone:flex-col justify-around">
            {selectCard.map(
              (
                {
                  id,
                  title,
                  subTitle,
                  content,
                  src,
                  info,
                  bizType,
                  hasBtn,
                  commonBtn,
                  bizStatus,
                  timeRequired,
                },
                idx
              ) => {
                return (
                  // 카드 컴포넌트
                  <HostingTypeCard
                    id={id}
                    timeRequired={timeRequired}
                    bizStatus={bizStatus}
                    closeModal={() => setTypeSelectModal(false)}
                    key={idx}
                    title={title}
                    cardIcon={src}
                    subTitle={subTitle}
                    content={content}
                    info={info}
                    bizType={bizType}
                    hasBtn={hasBtn}
                    commonBtn={commonBtn}
                  />
                );
              }
            )}
          </div>
        </FullSizeModal>
      ) : (
        ""
      )}
      <SideMenu
        setIsOpenTab={setSearchTab}
        isOpenTab={searchTab}
        tabTitle="Menu.Search Destination"
      >
        <div>
          {List.map((item: any, idx: number) => {
            const country = item?.name;
            const state = item?.stateList[0];
            return (
              <div
                onClick={() => {
                  !openCountry.includes(idx)
                    ? setOpenCountry((openCountry: any) => [
                        ...openCountry,
                        idx,
                      ])
                    : setOpenCountry(
                        openCountry?.filter((x: any) => x !== idx)
                      );
                }}
                key={idx}
              >
                <div className="cursor-pointer border-b pb-4 border-input-text flex items-center justify-between">
                  <div className="font-bold">{country}</div>
                  <img
                    className={`${
                      openCountry.includes(idx) ? "rotate-180" : ""
                    } w-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                    alt=""
                  />
                </div>
                {openCountry?.includes(idx) ? (
                  <div>
                    {state?.map((stateItem: any, idx: number) => {
                      return (
                        <div key={idx} className="mt-4">
                          <div>{stateItem.name}</div>
                          <div className="flex border-b border-input-text pb-4 overflow-x-scroll whitespace-nowrap noScroll">
                            {stateItem?.cityList?.map(
                              (item: any, idx: number) => {
                                if (stateItem.name && item?.state) {
                                  return (
                                    <div
                                      key={idx}
                                      onClick={() => {
                                        setSearchOpen(false);
                                        setSearchTab(false);
                                        navigate(`/search/${item.id}`, {
                                          state: {
                                            imageUrl: item.imgUrl,
                                            name: item.name,
                                            desc: item.description,
                                            city: item.city,
                                          },
                                        });
                                      }}
                                      className="mr-4 last:mr-0 py-2 text-input-border-color cursor-pointer hover:text-light-green"
                                    >
                                      {item.city}
                                    </div>
                                  );
                                }
                              }
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </SideMenu>
      <div className="xl:h-16 web:px-8 phone:px-3 mx-auto py-3 px-4 flex justify-between items-center web:border-none phone:border-input-text phone:border-b">
        <img
          onClick={() => {
            // if (status === "HOST" && role === "HOST") {
            //   navigate("host/myprivate");
            // } else {
            navigate("/");
            // }
          }}
          alt="Must Outdoor Logo"
          src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/Logo/must-logo-color.png`}
          className="
        cursor-pointer
        web:w-[212px] web:h-10
        tablet:w-[212px] tablet:h-10
        phone:w-32 phone:h-6"
        />
        <div>
          <div className="phone:flex web:hidden">
            <div className="flex">
              {/* <Icons
                onClick={() => {
                  searchToggle();
                }}
                className="w-6 h-6 mr-4"
                iconName={"search"}
              /> */}
              <Icons
                onClick={() => {
                  burgerClick();
                }}
                className="w-6 h-6"
                iconName={"hamburger-menu"}
              />
            </div>
          </div>
          {/* <div
          className={`transition-all h-full overflow-hidden w-full z-[10000] web:hidden phone:block bg-[#eee] fixed top-0 ${
            searchOpen ? "left-0" : "right-[-1024px]"
          }`}
        > */}

          <div
            className={`transition-all h-full overflow-hidden w-full z-[10000] web:hidden phone:block bg-[#eee] fixed top-0 ${
              burgerOpen ? "right-0" : "right-[-1024px]"
            } `}
          >
            <div className="flex justify-between p-4">
              <img
                className="w-32 h-6"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/Logo/must-logo-color.png"
                alt="must-logo-color"
              />
              <img
                onClick={() => {
                  burgerClick();
                }}
                src={`${process.env.REACT_APP_S3_URL}menu-close.svg`}
                className="inline-block"
                alt="menu-close"
              />
            </div>
            <div className="bg-light-green w-full h-24 p-4 flex">
              <img
                className="rounded-lg w-16 h-16"
                src={`${
                  profile
                    ? profile
                    : `https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg`
                }`}
                alt="my-account-avatar"
              />
              <div className="ml-4 flex flex-col justify-around text-pure-white">
                {isLogin === false && role === null && status === null ? (
                  <div className="text-xl">My Account</div>
                ) : (
                  <div>
                    <div>{name}</div>
                    <div>{email}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="px-4">
              {isLogin === false && role === null && status === null ? (
                // 회원가입 / 로그인
                <div>
                  <div
                    className="py-4 border-t cursor-pointer border-input-border-color"
                    onClick={() => {
                      navigate("/log-in");
                      setBurgerOpen(false);
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="log-in"
                        ></Icons>
                        {t(`Menu.Login`)}
                      </div>
                    </div>
                  </div>
                  <div
                    className="py-4 border-b border-input-border-color cursor-pointer"
                    onClick={() => {
                      navigate("/sign-up");
                      setBurgerOpen(false);
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="sign-up"
                        ></Icons>
                        {t(`Menu.Sign Up`)}
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="py-4 border-b border-input-border-color cursor-pointer"
                    onClick={() => {
                      navigate("/partner");
                      setBurgerOpen(false);
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="partner-with-us"
                        ></Icons>
                        {t("Menu.Become a Host")}
                      </div>
                    </div>
                  </div> */}
                </div>
              ) : isLogin === true &&
                role === "MEMBER" &&
                status === "MEMBER" ? (
                // 호스트되기 / 나의 계정
                <div>
                  <div
                    onClick={() => {
                      navigate("/partner");
                      setBurgerOpen(false);
                    }}
                    className="py-4 border-t cursor-pointer border-input-border-color"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="partner-with-us"
                        ></Icons>
                        {t("Menu.Become a Host")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/member/profile");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="my-profile"
                        ></Icons>
                        {t("Menu.My Profile")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/member/wishlist");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="wishlist-black"
                        ></Icons>
                        {t("Menu.Wish List")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/member/message");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="message"
                        ></Icons>

                        {t("Menu.Message Box")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/member/booking");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="booking-box"
                        ></Icons>
                        {t("Menu.Booking Box")}
                      </div>
                    </div>
                  </div>
                </div>
              ) : isLogin === true && role === "HOST" && status === "MEMBER" ? (
                // 호스트로 전환 / 나의 계정
                <div>
                  <div
                    onClick={() => {
                      navigate("/member/profile");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="my-profile"
                        ></Icons>
                        {t("Menu.My Profile")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/member/wishlist");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="wishlist-black"
                        ></Icons>
                        {t("Menu.Wish List")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/member/message");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="message"
                        ></Icons>
                        {t("Menu.Message Box")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/member/booking");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="booking-box"
                        ></Icons>
                        {t("Menu.Booking Box")}
                      </div>
                    </div>
                  </div>
                  <div
                    className="py-4 cursor-pointer"
                    onClick={() => {
                      switchToHost();
                      setBurgerOpen(false);
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="switch-host-traveler"
                        ></Icons>
                        {t("Menu.Switch to Host")}
                      </div>
                    </div>
                  </div>
                </div>
              ) : isLogin === true &&
                role === "HOST" &&
                status === "HOST" &&
                currentType === "PG" ? (
                // 여행자로 전환 / 호스트 계정
                <div>
                  <div
                    onClick={() => {
                      navigate("/host/myprivate");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="private-ground"
                        ></Icons>
                        {t("Menu.My Private Ground")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/host/calendar");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="calendar-manager"
                        ></Icons>
                        {t("Menu.Calendar Manager")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/host/message");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="message"
                        ></Icons>
                        {t("Menu.Message Box")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/host/book");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="booking-status"
                        ></Icons>
                        {t("Menu.Booking Status")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/host/account");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="account-setting"
                        ></Icons>
                        {t("Menu.Account Setting")}
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/host/myprivate");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <Icons
                        className="w-5 h-5 mr-2"
                        iconName="statistics"
                      ></Icons>
                      {t("Menu.Statistics")}
                    </div>
                  </div>
                  <div
                    className="py-4 cursor-pointer"
                    onClick={() => {
                      switchToTraveler();
                      setBurgerOpen(false);
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Icons
                          className="w-5 h-5 mr-2"
                          iconName="switch-host-traveler"
                        ></Icons>
                        {t("Menu.Switch to Traveler")}
                      </div>
                    </div>
                  </div>
                </div>
              ) : isLogin === true &&
                role === "HOST" &&
                status === "HOST" &&
                currentType === "AC" ? (
                // 여행자로 전환 / 호스트 계정
                <div>
                  <div
                    onClick={() => {
                      navigate("host/myaccommodation");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <Icons
                        className="w-5 h-5 mr-2"
                        iconName="private-ground"
                      ></Icons>
                      {t("Menu.My Accommodation")}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/host/calendar");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <Icons
                        className="w-5 h-5 mr-2"
                        iconName="calendar-manager"
                      ></Icons>
                      {t("Menu.Calendar Manager")}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/host/myprivate");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <Icons
                        className="w-5 h-5 mr-2"
                        iconName="message"
                      ></Icons>
                      {t("Menu.Message Box")}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/host/book");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <Icons
                        className="w-5 h-5 mr-2"
                        iconName="booking-status"
                      ></Icons>
                      {t("Menu.Booking Status")}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/host/account");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <Icons
                        className="w-5 h-5 mr-2"
                        iconName="account-setting"
                      ></Icons>
                      {t("Menu.Account Setting")}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/host/myprivate");
                      setBurgerOpen(false);
                    }}
                    className="py-4 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <Icons
                        className="w-5 h-5 mr-2"
                        iconName="statistics"
                      ></Icons>
                      {t("Menu.Statistics")}
                    </div>
                  </div>
                  <div
                    className="py-4 cursor-pointerg"
                    onClick={() => {
                      switchToTraveler();
                      setBurgerOpen(false);
                    }}
                  >
                    <div className="flex items-center">
                      <Icons
                        className="w-5 h-5 mr-2"
                        iconName="switch-host-traveler"
                      ></Icons>
                      {t("Menu.Switch to Traveler")}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {isLogin ? (
                <div
                  onClick={() => {
                    setLogOutToastAlert(true);
                    dispatch(logout());
                    setBurgerOpen(false);
                    navigate("/", { replace: true });
                    return window.location.reload;
                  }}
                  className="py-4 border-b cursor-pointer border-input-border-color"
                >
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <Icons
                        className="w-5 h-5 mr-2"
                        iconName="log-out"
                      ></Icons>
                      {t("Menu.Log out")}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                onClick={() => {
                  navigate("/partner");
                  setBurgerOpen(false);
                }}
                className="py-4 cursor-pointer"
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <Icons
                      className="w-5 h-5 mr-2"
                      iconName="partner-with-us"
                    ></Icons>
                    {t("Menu.Become a Host")}
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center phone:py-4">
                <div className="flex items-center">
                  <Icons className="w-5 h-5 mr-2" iconName="currency"></Icons>
                  <div>{t("Currency.Currency")}</div>
                </div>
                <select
                  defaultValue={
                    currencyValue === "krw"
                      ? "krw"
                      : currencyValue === "usd"
                      ? "usd"
                      : ""
                  }
                  onChange={(e) => {
                    setCurrency(e.target.value);
                    localStorage.setItem("currency", e.target.value);
                  }}
                  className="bg-[#eee] border-none cursor-pointer"
                >
                  <option value={"krw"}>{"KRW"}</option>
                  <option value={"usd"}>{"USD"}</option>
                  <option value={"eur"}>{"EUR"}</option>
                  {/* <option value={"gbp"}>{"GBP"}</option>
                <option value={"jpy"}>{"JPY"}</option>
                <option value={"cny"}>{"CNY"}</option>
                <option value={"cad"}>{"CAD"}</option>
                <option value={"aud"}>{"AUD"}</option>
                <option value={"twd"}>{"TWD"}</option>
                <option value={"hkd"}>{"HKD"}</option>
                <option value={"myr_rm"}>{"MYR RM"}</option>
                <option value={"thb"}>{"THB"}</option>
              <option value={"php"}>{"PHP"}</option> */}
                </select>
              </div>
              <div className="flex justify-between items-center phone:py-4">
                <div className="flex items-center">
                  <Icons
                    className="w-5 h-5 mr-2"
                    iconName="language-select"
                  ></Icons>
                  <div>Language</div>
                </div>
                <select
                  onChange={(e) => changelang(e.target.value)}
                  defaultValue={
                    language === "e"
                      ? "e"
                      : language === "k"
                      ? "k"
                      : language === "j"
                      ? "j"
                      : ""
                  }
                  className="bg-[#eee] border-none cursor-pointer"
                >
                  {languages.map((lang: any) => (
                    <option key={lang.code} value={lang.code}>
                      {lang.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="web:flex justify-between items-center phone:hidden">
            <select
              className="border-none"
              defaultValue={
                language === "e"
                  ? "e"
                  : language === "k"
                  ? "k"
                  : language === "j"
                  ? "j"
                  : ""
              }
              onChange={(e) => changelang(e.target.value)}
            >
              {languages.map((lang: any) => (
                <option key={lang.code} value={lang.code}>
                  {lang.text}
                </option>
              ))}
            </select>

            <div className="pl-6">
              <select
                defaultValue={
                  currencyValue === "krw"
                    ? "krw"
                    : currencyValue === "usd"
                    ? "usd"
                    : ""
                }
                onChange={(e) => {
                  setCurrency(e.target.value);
                  localStorage.setItem("currency", e.target.value);
                }}
                className="border-none"
              >
                <option value={"krw"}>{t("Currency.KRW")}</option>
                {/* <option value={"usd"}>{"USD"}</option> */}
                {/* <option>{"JPY"}</option> */}
              </select>
            </div>
            <div>
              {isLogin === false && role === null && status === null ? (
                // 회원가입 / 로그인
                <div className="flex">
                  <div
                    onClick={() => {
                      navigate("/partner");
                    }}
                    className="pl-6 cursor-pointer rounded-lg hover:text-main-green"
                  >
                    {t("Menu.Become a Host")}
                  </div>
                  <div
                    onClick={() => {
                      navigate("sign-up");
                    }}
                    className="cursor-pointer pl-6"
                  >
                    {t(`Menu.Sign Up`)}
                  </div>
                  <div
                    onClick={() => {
                      navigate("/log-in");
                    }}
                    className="cursor-pointer pl-6"
                  >
                    {t(`Menu.Login`)}
                  </div>
                </div>
              ) : isLogin === true &&
                role === "MEMBER" &&
                status === "MEMBER" ? (
                // 호스트되기 / 나의 계정
                <div className="flex">
                  <div
                    onClick={() => {
                      navigate("/partner");
                    }}
                    className="pl-6 cursor-pointer"
                  >
                    <div className="rounded-lg hover:text-main-green">
                      {t("Menu.Become a Host")}
                    </div>
                  </div>
                  <div className="pl-6 group">
                    <div className="cursor-pointer pl-2">
                      {t("Menu.My Account")}
                    </div>
                    <div className="z-100 absolute w-40 bg-pure-white right-5 rounded-lg">
                      <HeaderAccount
                        isMember
                        className="group-hover:block hidden rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              ) : isLogin === true && role === "HOST" && status === "MEMBER" ? (
                // 호스트로 전환 / 나의 계정
                <div className="flex">
                  <div className="pl-6 cursor-pointer">
                    <div
                      onClick={() => {
                        switchToHost();
                      }}
                    >
                      <div className="cursor-pointer rounded-lg hover:text-main-green">
                        {t("Menu.Switch to Host")}
                      </div>
                    </div>
                  </div>
                  <div className="pl-6 group">
                    <div className="cursor-pointer pl-2">
                      {t("Menu.My Account")}
                    </div>
                    <div className="z-100 absolute w-40 bg-pure-white right-5 rounded-lg">
                      <HeaderAccount
                        isMember
                        className="group-hover:block hidden rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              ) : isLogin === true && role === "HOST" && status === "HOST" ? (
                // 여행자로 전환 / 호스트 계정
                <div className="flex">
                  <div className="pl-6 cursor-pointer">
                    <div
                      onClick={() => {
                        switchToTraveler();
                      }}
                      className="myAccountContent"
                    >
                      <div className="cursor-pointer tracking-normal rounded-lg hover:text-main-green">
                        {t("Menu.Switch to Traveler")}
                      </div>
                    </div>
                  </div>
                  <div className="pl-6 group">
                    <div className="cursor-pointer pl-2">
                      {t("Menu.Host Account")}
                    </div>
                    <div className="z-100 absolute w-40 bg-pure-white right-5 rounded-lg">
                      {currentType === "PG" ? (
                        <HeaderAccount
                          isHost
                          className="group-hover:block hidden rounded-lg"
                        />
                      ) : currentType === "AC" ? (
                        <HeaderAccount
                          isAC
                          className="group-hover:block hidden rounded-lg"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
