import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import BookingCard from "./bookingCard";

const MemberCurrentBooking = () => {
  const {t} = useTranslation();
  const [booking, setBooking] = useState<any>();

  const id = localStorage.getItem("m")
  
  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v1/booking/fetch/${id}`,
    })
      .then((res) => {
        const value = res.data.data;

        const current = value.map((item: any) => {
          if (item.status === "CONFIRMED" || item.status === "PENDING") {
            return item;
          }
        });
        const noUnde = current.filter(function (x: any) {
          return x !== undefined;
        });
        setBooking(noUnde);
        return res.data.data;
      })
      .catch((err) => {
        //console.logerr);
      });
  }, []);
  
  return (
    <div>
      <div className="web:text-2xl phone:text-base phone:px-4 web:px-0 mb-8">
        {t("Booking-box-status.You have {{number}} upcoming booking.", {number: booking && booking.length ? booking.length : "0" })}
      </div>
      <div className="flex web:justify-start phone:flex-col web:flex-row phone:justify-center items-center flex-wrap">
        {booking &&
          booking.map(
            (
              {
                id,
                pgId,
                hostName,
                pgTitle,
                currentImage,
                status,
                checkIn,
                checkOut,
                resoCode,
              }: any,
              idx: number
            ) => {
                return (
                  <BookingCard
                    key={idx}
                    id={id}
                    pgId={pgId}
                    title={pgTitle}
                    checkIn={checkIn}
                    checkOut={checkOut}
                    bookingStatus={status}
                    host={hostName}
                    imageUrl={currentImage}
                    bookingCode={resoCode}
                  />
                );
              }
          )}
      </div>
    </div>
  );
};

export default MemberCurrentBooking;
