import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import { extraOption } from "redux/slice/accommodationSlice";

import AcWrap from "elements/AcWrap";
import Button from "elements/Button";
import ExtraOption from "elements/ExtraOption";
import { useTranslation } from "react-i18next";
import { inputPriceFormat } from "utils/commaDash";

interface ExtraOpionProps {
  onPre?: any;
  onNext?: any;
  extraData?: any;
}

interface ExtraOptionValueProps {
  amount: number;
  description: string;
  maxOfNumber: number;
  paymentMethod: string;
  price: string;
  title: string;
}

const AcExtraOptions = ({ onNext, onPre, extraData }: ExtraOpionProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const preData = useAppSelector((state) => state.accommodation.extraOptions);
  const page: any = preData;

  const [extraOptionData, setExtraOptionData] = useState<{
    amount: number;
    description: string;
    maxOfNumber: number;
    paymentMethod: string;
    price: string;
    title: string;
  }>({
    amount: 0,
    description: "",
    maxOfNumber: 0,
    paymentMethod: "",
    price: "",
    title: "",
  });

  useEffect(() => {
    setExtraOptionData({
      amount: 0,
      description: page[0]?.description ? page[0]?.description : "",
      maxOfNumber: page[0]?.maxOfNumber ? page[0]?.maxOfNumber : "'",
      paymentMethod: page[0]?.paymentMethod ? page[0]?.paymentMethod : "",
      price: page[0]?.price ? page[0]?.price : "",
      title: page[0]?.title ? page[0]?.title : "",
    });
  }, [page]);

  const [addExtra, setAddExtra] = useState([0]);

  const extraOptionValue: any = extraOptionData && extraOptionData;

  const extraOptionResult = [
    {
      amount: 0,
      description: extraOptionValue?.description,
      maxOfNumber: extraOptionValue?.maxOfNumber,
      paymentMethod: extraOptionValue?.paymentMethod,
      price: extraOptionValue?.price,
      title: extraOptionValue?.title,
    },
  ];

  const handlePageData = () => {
    extraData(extraOptionResult);
    dispatch(extraOption(extraOptionResult));
    onNext();
  };

  const addDiv = () => {
    let counterArr = [...addExtra];
    let counter = counterArr.slice(-1)[0];
    counter += 1;
    counterArr.push(counter);
    setAddExtra(counterArr);
  };

  // const handleExtraData = (e: any, idx:number) => {

  //   setExtraOptionData((pre) => [...pre, ...extraOptionData?.map((item, itemIdx) => (
  //     itemIdx === idx ?  : ""
  //   ))])
  // };

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          // addItem
          // addOnClick={() => {
          //   addDiv();
          // }}
          help
          toolTitle="Tooltip-add-accommodation.Extra option-tooltip title"
          toolSubTitle="Tooltip-add-accommodation.Extra option-tooltip desc"
          toolEx="Tooltip-add-accommodation.Extra option-tooltip ex"
          title="Add-accommodation.Extra Options"
        >
          {/* <ExtraOption
            preData={extraOptionData}
            setExtraOption={setExtraOptionData}
          /> */}
          <div>
            <hr className="text-input-text" />
            <div className="w-full mt-8">
              <div className="flex web:flex-row phone:flex-col items-center justify-between text-sm">
                <div className="web:w-76 phone:w-full">
                  <div>{t("Add-accommodation.Name of extra option")}</div>
                  <input
                    value={extraOptionData?.title ? extraOptionData?.title : ""}
                    name="title"
                    onChange={(e) => {
                      setExtraOptionData({
                        ...extraOptionData,
                        title: e.target.value,
                      });
                    }}
                    className="extraInput"
                    type="text"
                  />
                </div>
                <div className="phone:mt-2 web:mt-0 web:w-76 phone:w-full">
                  <div>{t("Add-private-ground.Price (Per 1 item)")}</div>
                  <input
                    value={extraOptionData.price ? extraOptionData.price : ""}
                    onChange={(e) => {
                      setExtraOptionData({
                        ...extraOptionData,
                        price: inputPriceFormat(e.target.value),
                      });
                    }}
                    onWheel={(e: any) => e.target.blur()}
                    name="price"
                    className="extraInput"
                  />
                </div>
              </div>
              <div className="flex web:flex-row phone:flex-col items-center mt-4 justify-between text-sm">
                <div className="web:w-76 phone:w-full">
                  <div>{t("Add-accommodation.Payment Method")}</div>
                  <select
                    value={
                      extraOptionData?.paymentMethod
                        ? extraOptionData?.paymentMethod
                        : ""
                    }
                    onChange={(e) => {
                      setExtraOptionData({
                        ...extraOptionData,
                        paymentMethod: e.target.value,
                      });
                    }}
                    name="paymentMethod"
                    className="extraInput"
                  >
                    <option value="">{t("Add-accommodation.Select")}</option>
                    <option value={"AVAILABLE_IN_EXTRA_OPTION"}>
                      {t("Extra-payment-method.Available in Extra Option")}
                    </option>
                    <option value={"On-site Payment"}>
                      {t("Extra-payment-method.On-site Payment")}
                    </option>
                  </select>
                </div>
                <div className="phone:mt-2 web:mt-0 web:w-76 phone:w-full">
                  <div>{t("Add-private-ground.Max of Number")}</div>
                  <input
                    value={
                      extraOptionData.maxOfNumber
                        ? extraOptionData.maxOfNumber
                        : ""
                    }
                    onChange={(e) => {
                      setExtraOptionData({
                        ...extraOptionData,
                        maxOfNumber: Number(e.target.value),
                      });
                    }}
                    onWheel={(e: any) => e.target.blur()}
                    name="maxOfNumber"
                    className="extraInput"
                    type="number"
                  />
                </div>
              </div>
              <AcWrap
                name="description"
                tooltipHidden
                inputValue={
                  extraOptionData?.description
                    ? extraOptionData?.description
                    : ""
                }
                onChange={(e) => {
                  setExtraOptionData({
                    ...extraOptionData,
                    description: e.target.value,
                  });
                }}
                placeholder="Add-accommodation.Please describe inclusion of extra option for your traveler"
                miniTitle="Add-private-ground.Description"
                textArea
              />
            </div>
          </div>
        </AcWrap>
        <div className="flex justify-between items-center">
          <Button
            onClick={() => onPre()}
            btnText={"Add-accommodation.Previous"}
            className="inline-block bg-light-green rounded-lg py-3 px-4 text-pure-white"
          />
          <Button
            onClick={() => handlePageData()}
            btnText={"Add-accommodation.Continue"}
            className="inline-block bg-light-green rounded-lg py-3 px-4 text-pure-white"
          />
        </div>
      </div>
    </div>
  );
};

export default AcExtraOptions;
