import MoblieHeader from "components/moblieHeader";
import HostCard from "elements/HostCard";

import { useTranslation } from "react-i18next";

const SelectType = () => {
  const {t} = useTranslation();
  const _userName = localStorage.getItem("name");
  const selectCard = [
    {
      id: 1,
      title: "Private Ground",
      src: "partner-private-ground",
      subTitle:
        "This type is suitable for-private ground",
      content:
        "You can make a profit by utilizing private property",
      requirement: "Preparation items",
      info: "Preparation items for registration private ground",
      timeRequired: "During Time PG",
      bizType: ["PG"],
      isPossible: true,
      selected: false,
    },
    {
      id: 2,
      title: "Outdoor Accommodation",
      src: "partner-accommodation",
      subTitle:
        "This type is suitable for-outdoor accommodation",
      content:
        "Share eco-friendly accommodations",
      requirement: "Preparation items",
      info: "Preparation items for registration outdoor accommodation",
      timeRequired: "During Time AC",
      bizType: ["AC"],
      isPossible: true,
      selected: false,
    },
    // {
    //   id: 3,
    //   title: "Outdoor Activity",
    //   src: "partner-activity",
    //   subTitle:
    //     "This type is suitable for-outdoor activity",
    //   content:
    //     "Share all activities globally",
    //   requirement: "Preparation items",
    //   info: "Preparation items for registration outdoor activity",
    //   timeRequired: "During Time AT",
    //   bizType: ["AT"],
    //   isPossible: false,
    //   selected: false,
    // },
  ];
  return (
    <div>
      <MoblieHeader left center="Start Hosting" />
      <div className="text-center lg:max-w-1216 web:px-8 web:py-8 web:mx-auto phone:px-4 phone:pt-8 phone:pb-4">
        <div className="web:mb-16 phone:mb-8 text-center">
          <div className="web:mb-8 web:text-[28px] phone:mb-4 phone:text-xl font-bold web:block phone:hidden">
            {t(`Partner-select.What is attractive outdoor life type`, {name: _userName ? _userName : "호스트"})}
          </div>
         <div>
            {t(`Partner-select.Please select the type you want to share`)}
         </div>
        </div>
        <div className="flex web:flex-row phone:flex-col justify-around">
          {selectCard.map(
            (
              {
                id,
                title,
                subTitle,
                content,
                src,
                requirement,
                info,
                timeRequired,
                isPossible,
                bizType,
              },
              idx
            ) => {
              return (
                // 카드 컴포넌트
                <HostCard
                id={id}
                  key={idx}
                  title={title}
                  cardIcon={src}
                  subTitle={subTitle}
                  content={content}
                  requirement={requirement}
                  info={info}
                  timeRequired={timeRequired}
                  isPossible={isPossible}
                  bizType={bizType}
                />
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectType;
