import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { t } from "i18next";

import MainCustomSlider from "components/mainCustomSlider";
import MoblieHeader from "components/moblieHeader";

import Card from "elements/Card";

import { inputPriceComma } from "utils/commaDash";
import { isMobile } from "utils/isMobile";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useTranslation } from "react-i18next";
import { isLogin } from "utils/roleStatus";

const LocationSearchResult = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [cardList, setCardList] = useState<any>([]);
  const [wishList, setWishList] = useState([]);

  const memberId = localStorage.getItem("m");
  const state: any = useLocation();
  const imageUrl = state.state?.imageUrl;
  const name = state.state?.name;
  const desc = state.state?.desc;
  const city = state.state?.city;

  const getCart = useCallback(() => {
    if(isLogin()) {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v1/cart/private-ground/${memberId}`,
      }).then((res) => {
        const data = res.data.data;
        setWishList(data);
        return data;
      });
    }
  },[memberId])

  useEffect(() => {
    getCart()
  }, [memberId]);

  const wishListIds = wishList?.map((item: any) => {
    const id = item.id;
    return id;
  });

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/guest/private-ground/fetch/destination/${id}`,
    }).then((res) => {
      const value = res.data.data;
      setCardList(value);
      return res.data.data;
    });
  }, [id]);

  const nameSlice = name.split(" ");
  const nameResult = nameSlice[0] + " " + nameSlice[1];
  return (
    <div>
      <MoblieHeader left center={city} />
      <div
        style={{ backgroundImage: `url(${imageUrl})` }}
        className="bg-center w-full bg-cover text-pure-white web:px-16 web:py-8 phone:p-4 items-center"
      >
        <div className="max-w-[500px] font-bold mb-4 web:text-5xl phone:text-base inline-block mt-[97px]">
          <div>{nameResult}</div>
          <div>{nameSlice[2]}</div>
        </div>

        <div className="phone:hidden web:block">{desc}</div>
      </div>
      <div className="web:p-8 phone:pt-2 phone:px-4 web:max-w-1216 xl:max-w-1280 mx-auto ">
        <div className="phone:block web:hidden mb-4">{desc}</div>
        <div className="font-bold web:text-2xl phone:text-base web:mb-8 phone:mb-2">
          OUTDOOR ADVENTURES IN {city}
        </div>

        <div>
          <div className="web:text-2xl phone:text-base font-bold mb-4">
            {t("Search-result.Private Ground in {{location}}", {
              location: city,
              number: cardList?.length,
            })}
          </div>
          {cardList.length > 3 || isMobile() ? (
            <MainCustomSlider>
              {cardList.map((item: any, i: number) => {
                const price = item.priceInfo;
                let arr = Object.values(price);
                const listingId = item.id;
                const toNum = arr.map((i) => Number(i));
                const noZero = toNum.filter((e) => e !== 0);
                const priceValue = Math.min.apply(null, noZero);
                return (
                  <div key={i} className="text-center">
                    <Card
                      FreePrivate
                      onClick={() => {
                        navigate(`/details/${item.id}`, { state: item.id });
                      }}
                      memberId={Number(memberId)}
                      listingId={Number(listingId)}
                      country={item.country}
                      wish={wishListIds?.includes(listingId) ? true : false}
                      price={inputPriceComma(String(priceValue))}
                      image={item.image.url}
                      locationThemes={item.locationThemes}
                      state={t(`Kor-state.${item.state}`)}
                      title={item.title}
                      type={item.typeInfo}
                    />
                  </div>
                );
              })}
            </MainCustomSlider>
          ) : !isMobile() && cardList.length < 3 ? (
            <div className={`flex flex-wrap start justify-start gap-4`}>
              {cardList?.map((item: any, i: number) => {
                const price = item.priceInfo;
                let arr = Object.values(price);
                const toNum = arr.map((i) => Number(i));
                const noZero = toNum.filter((e) => e !== 0);
                const priceValue = Math.min.apply(null, noZero);
                return (
                  <div key={i} className="text-center web:mr-4 phone:mr-2">
                    <Card
                      onClick={() => {
                        navigate(`/details/${item.id}`, { state: item.id });
                      }}
                      country={item.country}
                      price={inputPriceComma(String(priceValue))}
                      image={item.image.url}
                      locationThemes={item.locationThemes}
                      state={t(`Kor-state.${item.state}`)}
                      title={item.title}
                      type={item.typeInfo}
                    />
                  </div>
                );
              })}
            </div>
          ) : !isMobile() && cardList.length > 3 ? (
            <MainCustomSlider>
              {cardList.map((item: any, i: number) => {
                const price = item.priceInfo;
                let arr = Object.values(price);
                const toNum = arr.map((i) => Number(i));
                const noZero = toNum.filter((e: number | any) => e !== 0 && e !== "");
                const priceValue = Math.min.apply(null, noZero);
                return (
                  <div key={i} className="text-center">
                    <Card
                      onClick={() => {
                        navigate(`/details/${item.id}`, { state: item.id });
                      }}
                      country={item.country}
                      price={inputPriceComma(String(priceValue))}
                      image={item.image.url}
                      locationThemes={item.locationThemes}
                      state={t(`Kor-state.${item.state}`)}
                      title={item.title}
                      type={item.typeInfo}
                    />
                  </div>
                );
              })}
            </MainCustomSlider>
          ) : (
            ""
          )}
          <div
            className="cursor-pointer hover:text-light-green inline-block phone:text-xs web:text-base"
            onClick={() => {
              navigate(`type/${id}`, { state: city });
            }}
          >
            {t(`Search-result.See All Private Ground`)} ({cardList?.length}){" "}
            {">"}
          </div>
          <hr className="my-8 text-input-text" />
        </div>

        {/* <div>
          <div className="text-2xl font-bold mb-4">
            69 Outdoor Activities In {city}
          </div>
          <div>See All Activities (69) {">"}</div>
        <hr className="my-8 text-input-text" />
        </div> */}
      </div>
    </div>
  );
};

export default LocationSearchResult;
