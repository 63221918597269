import React, { useEffect, useState } from "react";

import AcWrap from "elements/AcWrap";
import { useTranslation } from "react-i18next";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import Button from "elements/Button";

interface GeneProps {
    onNext?: any;
    geneData?: any;
  }

const EditSpaceGeneralInfo = ({ onNext, geneData }: GeneProps) => {
  const { t } = useTranslation();

  const [types, setTypes] = useState([]);

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/space/type`,
    })
      .then((res) => {
        const data = res.data.data;
        setTypes(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const nextPage = () => {
    // geneData(spaceGeneData);
    // dispatch(spGeneralInfo(spaceGeneData));
    onNext();
  };

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          //   inputValue={spaceGeneData?.title}
          title={t("Add-space.Title*")}
          //   onChange={(e) => {
          //     setSpaceGeneData({ ...spaceGeneData, title: e.target.value });
          //   }}
          help
          placeholder={"Add-space.Name of Space"}
          input
          inputMaxLength={50}
          toolTitle="공간의 이름을 입력해주세요."
          toolSubTitle="해당 방 또는 캠프 사이트 특색에 맞춰 이름을 만들어 주세요."
          toolEx="Ex) 럭셔리 사파리 텐트, 계곡옆 데크 사이트 A, 트리하우스 등"
        />
        <AcWrap
          //   inputValue={spaceGeneData?.description}
          //   onChange={(e) => {
          //     setSpaceGeneData({ ...spaceGeneData, description: e.target.value });
          //   }}
          title="Add-space.Description*"
          textArea
          inputMaxLength={500}
          placeholder="Add-space.Describe the space for traveler"
          row={5}
          toolTitle="공간에 대해 설명해주세요."
          toolSubTitle="게스트들이 해당 공간에 대해 알기 쉽도록 특색과 매력적인 부분들을 
          설명해주세요."
        />
        <AcWrap
          help
          toolTitle="공간 타입 선택과 유닛수를 설정해주세요."
          toolSubTitle="먼저, 해당 공간에 가장 적절한 타입을 1개 선택해주세요. 그리고 운영 가능한 
        유닛수를 설정하세요. (하루에 예약이 가능한 수량)"
          title="Add-space.Space Type*"
        >
          <div className="mb-4">
            <div className="text-sm">{t("Add-space.Selected space type")}</div>
            <div
              //   onClick={() => {
              //     setTypeSelect(true);
              //   }}
              className="acSubInput cursor-pointer"
            >
              {/* {types && type !== 0 && types
                ? types?.map((item: any, idx) => {
                    const id = item.id;
                    const category = item.category;
                    const iconUrl = item.iconUrl;
                    const enName = item.enName;
                    if (type === id) {
                      return (
                        <div key={idx} className="flex text-sm items-center">
                          <img
                            className="w-5 h-5 mr-2"
                            src={`${
                              category === "Glamping Types"
                                ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/glamping-type.svg"
                                : category === "Caravan Types"
                                ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/caravan-type.svg"
                                : category === "Campground Types"
                                ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/campground-type.svg"
                                : category === "Eco Friendly Types"
                                ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/eco-friendly-type.svg"
                                : ""
                            }`}
                            alt={enName}
                          />
                          <div>{t(`Space-type-data.${category}`)}</div>
                          <div className="mx-4">|</div>
                          <img
                            className="w-5 h-5 mr-2"
                            src={iconUrl}
                            alt={enName}
                          />
                          <div>{t(`Space-type-data.${enName}`)}</div>
                        </div>
                      );
                    }
                  })
                :  */}
              {t("Add-space.Select Space Type")}
              {/* } */}
            </div>
          </div>
          <div>
            <div className="text-sm">{t("Add-space.Number of Unit")}</div>
            <select
              //   value={spaceGeneData?.unitCount}
              //   onChange={(e) => {
              //     setSpaceGeneData({
              //       ...spaceGeneData,
              //       unitCount: Number(e.target.value),
              //     });
              //   }}
              className="acSubInput"
            >
              <option value="">{t(`Add-space.Select`)}</option>
              {/* {unitNumber?.map((item, idx) => {
                return (
                  <option key={idx} value={item}>
                    {item}
                  </option>
                );
              })} */}
            </select>
          </div>
        </AcWrap>

        <AcWrap
          help
          toolTitle="공간 및 운영에 관한 정보입니다."
          toolSubTitle="수용 가능한 기준 인원, 침대 개수, 최소 숙박 일수, 공간 사이즈를 설정해주세요."
          title="Add-space.Space Details*"
        >
          <div className="flex web:flex-row phone:flex-col justify-between mb-4">
            <div className="web:mb-0 phone:mb-2">
              <div className="text-sm">
                {t(`Add-space.Number of Guest* (Occupancy)`)}
              </div>
              <select
                // value={spaceGeneData?.totalGuestAmount}
                // onChange={(e) => {
                //   setSpaceGeneData({
                //     ...spaceGeneData,
                //     totalGuestAmount: Number(e.target.value),
                //   });
                // }}
                className="acSubMinInput"
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {/* {numberOfGuest?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  );
                })} */}
              </select>
            </div>
            <div>
              <div className="text-sm">{t(`Add-space.Number of Beds*`)}</div>
              <select
                // value={spaceGeneData?.bedAmount}
                // onChange={(e) => {
                //   setSpaceGeneData({
                //     ...spaceGeneData,
                //     bedAmount: Number(e.target.value),
                //   });
                // }}
                className="acSubMinInput"
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {/* {unitNumber?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  );
                })} */}
              </select>
            </div>
          </div>
          <div className="flex web:flex-row phone:flex-col justify-between">
            <div className="web:mb-0 phone:mb-2">
              <div className="text-sm">{t(`Add-space.Minimum Stay(day)*`)}</div>
              <select
                // value={spaceGeneData?.minStay}
                // onChange={(e) => {
                //   setSpaceGeneData({
                //     ...spaceGeneData,
                //     minStay: Number(e.target.value),
                //   });
                // }}
                className="acSubMinInput"
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {/* {unitNumber?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  );
                })} */}
              </select>
            </div>
            <div>
              <div className="text-sm">{t(`Add-space.Space Size`)}</div>
              <div className="relative">
                <input
                  // defaultValue={spaceGeneData?.spaceSize}
                  // onChange={(e) => {
                  //   const currentSpace = Number(e.target.value);
                  //   setSpaceGeneData({
                  //     ...spaceGeneData,
                  //     spaceSize: String(currentSpace.toFixed(2)),
                  //   });
                  // }}
                  // onInput={(e) => {
                  //   inputMaxLength(e, 3);
                  // }}
                  onWheel={(e: any) => e.target.blur()}
                  placeholder={t("Add-space.Input space size")}
                  type="number"
                  className="acSubMinInput"
                />
                <div className="absolute top-[14.5px] right-2 text-sm">㎡</div>
              </div>
            </div>
          </div>
        </AcWrap>

        <AcWrap
          help
          toolTitle="추가 인원 정책을 설정해주세요."
          toolSubTitle="추가 가능 여부 및 인원, 그리고 연령에 맞춰 가격을 설정해주세요. 가격 입력 
        부분을 공란으로 두실 경우 무료로 설정됩니다."
          title="Add-space.Extra Guests"
        >
          <div className="mb-4">
            <div className="text-sm">
              {t(`Add-space.Available Extra Guests`)}
            </div>
            <select
              //   value={spaceGeneData?.availableExtraGuest}
              //   onChange={(e) => {
              //     setSpaceGeneData({
              //       ...spaceGeneData,
              //       availableExtraGuest: Number(e.target.value),
              //     });
              //   }}
              className="acSubInput mt-2"
            >
              <option value="0">{t(`Add-space.None`)}</option>
              {/* {extraGuest?.map((item, idx) => {
                return (
                  <option key={idx} value={item}>
                    {item}
                  </option>
                );
              })} */}
            </select>
          </div>

          <div className="flex web:flex-row phone:flex-col justify-between mb-4">
            <div className="web:mb-0 phone:mb-2">
              <div className="text-sm">{t(`Add-space.Guest Title`)}</div>
              <div className="acSubMinInput">
                {t(`Add-space.Adults (Age 13+)`)}
              </div>
            </div>
            <div>
              <div className="text-sm">
                {t(`Add-space.Extra Price / Guest`)}
              </div>
              <div className="relative">
                <input
                  onWheel={(e: any) => e.target.blur()}
                  //   value={spaceGeneData?.adultExtraCharge}
                  //   onChange={(e) => {
                  //     setSpaceGeneData({
                  //       ...spaceGeneData,
                  //       adultExtraCharge: inputPriceFormat(e.target.value),
                  //     });
                  //   }}
                  placeholder={t("Add-space.Input price")}
                  className="acSubMinInput pl-6"
                />
                <div className="absolute top-[16px] text-sm left-[8px]">₩</div>
              </div>
            </div>
          </div>
          <div className="flex web:flex-row phone:flex-col justify-between mb-4">
            <div className="acSubMinInput">
              {t(`Add-space.Children (Ages 2–12)`)}
            </div>
            <div className="relative">
              <input
                onWheel={(e: any) => e.target.blur()}
                // value={spaceGeneData?.childrenExtraCharge}
                // onChange={(e) => {
                //   setSpaceGeneData({
                //     ...spaceGeneData,
                //     childrenExtraCharge: inputPriceFormat(e.target.value),
                //   });
                // }}
                placeholder={t("Add-space.Input price")}
                className="acSubMinInput pl-6"
              />
              <div className="absolute top-[16px] text-sm left-[8px]">₩</div>
            </div>
          </div>
          <div className="flex web:flex-row phone:flex-col justify-between">
            <div className="acSubMinInput">
              {t(`Add-space.Infant (Under 2)`)}
            </div>
            <div className="relative">
              <input
                onWheel={(e: any) => e.target.blur()}
                // value={spaceGeneData?.infantExtraCharge}
                // onChange={(e) => {
                //   setSpaceGeneData({
                //     ...spaceGeneData,
                //     infantExtraCharge: inputPriceFormat(e.target.value),
                //   });
                // }}
                placeholder={t("Add-space.Input price")}
                className="acSubMinInput pl-6"
              />
              <div className="absolute top-[16px] text-sm left-[8px]">₩</div>
            </div>
          </div>
        </AcWrap>
        <AcWrap
          help
          toolTitle="해당 공간의 주차 기준을 설정해주세요."
          toolSubTitle="먼저, 주차 타입, 접근 가능 차량의 크기, 그리고 주차 가능 대수를 선택해주세요.
        만약 해당 공간(사이트)의 추가 주차가 가능하다면,  추가 가능 대수와 가격을
        설정해주세요."
          title="Add-space.Parking Details*"
        >
          <div className="flex web:flex-row phone:flex-col justify-between mb-4">
            <div className="web:mb-0 phone:mb-2">
              <div className="text-sm">{t(`Add-space.Parking Type*`)}</div>
              <select
                // value={spaceGeneData?.parkingType}
                // onChange={(e) => {
                //   setSpaceGeneData({
                //     ...spaceGeneData,
                //     parkingType: e.target.value,
                //   });
                // }}
                className="acSubMinInput"
                name=""
                id=""
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {/* {ParkingType?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {t(`Parking-accessible-data.${item}`)}
                    </option>
                  );
                })} */}
              </select>
            </div>
            <div>
              <div className="text-sm">{t(`Add-space.Accessible Vehicle`)}</div>
              <select
                // disabled={
                //   spaceGeneData.parkingType === "No Parking Lot" ? true : false
                // }
                // onChange={(e) => {
                //   setSpaceGeneData({
                //     ...spaceGeneData,
                //     accessibleVehicle: e.target.value,
                //   });
                // }}
                className="acSubMinInput"
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {/* {Vehicle?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {t(`Parking-accessible-data.${item}`)}
                    </option>
                  );
                })} */}
              </select>
            </div>
          </div>
          <div className="flex web:flex-row phone:flex-col justify-between mb-4">
            <div className="web:mb-0 phone:mb-2">
              <div className="text-sm">{t(`Add-space.Number of Car*`)}</div>
              <select
                // disabled={
                //   spaceGeneData.parkingType === "No Parking Lot" ? true : false
                // }
                // value={spaceGeneData?.carAmount}
                // onChange={(e) => {
                //   setSpaceGeneData({
                //     ...spaceGeneData,
                //     carAmount: Number(e.target.value),
                //   });
                // }}
                className="acSubMinInput"
                name=""
                id=""
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {/* {extraGuest?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {item} {t(`Parking-accessible-data.Car`)}
                    </option>
                  );
                })} */}
              </select>
            </div>
            <div>
              <div className="text-sm">
                {t(`Add-space.Available Extra Car*`)}
              </div>
              <select
                // disabled={
                //   spaceGeneData.parkingType === "No Parking Lot" ? true : false
                // }
                // value={spaceGeneData?.extraCarAmount}
                // onChange={(e) => {
                //   setSpaceGeneData({
                //     ...spaceGeneData,
                //     extraCarAmount: Number(e.target.value),
                //   });
                // }}
                className="acSubMinInput"
                name=""
                id=""
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {/* {extraGuest?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {item} {t(`Parking-accessible-data.Extra Car`)}
                    </option>
                  );
                })} */}
              </select>
            </div>
          </div>
          <div>
            <div className="text-sm">
              {t(`Add-space.Extra Price / per car*`)}
            </div>
            <div className="relative">
              <input
                onWheel={(e: any) => e.target.blur()}
                // disabled={
                //   spaceGeneData.parkingType === "No Parking Lot" ? true : false
                // }
                // value={spaceGeneData?.extraCarCharge}
                // onChange={(e) => {
                //   setSpaceGeneData({
                //     ...spaceGeneData,
                //     extraCarCharge: inputPriceFormat(e.target.value),
                //   });
                // }}
                placeholder={t("Add-space.Input price")}
                className="acSubMinInput pl-5"
              />
              <div className="absolute top-[16px] text-sm left-[8px]">₩</div>
            </div>
          </div>
        </AcWrap>
        <div className="mt-12 mb-8 justify-end flex">
          <Button
            onClick={() => nextPage()}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText="Add-space.Continue"
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default EditSpaceGeneralInfo;
