import React, { useEffect, useState } from "react";

import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import MemberCurrentBooking from "components/Member/memberCurrentBooking";
import MemberBookingHistory from "components/Member/memberBookingHistory";
import MemberCancellledBooking from "components/Member/memberCancelledBooking";
import MoblieHeader from "components/moblieHeader";
import { useTranslation } from "react-i18next";
import { isLogin } from "utils/roleStatus";
import { useNavigate } from "react-router-dom";

const BookingBox = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [tabNum, setTabNum] = useState(0);
  const tabInfo = [
    { id: 0, title: "Upcoming" },
    { id: 1, title: "Completed" },
    { id: 2, title: "Cancelled" },
  ];
  const ChangeTab = (id: number) => {
    setTabNum(id);
  };

  useEffect(() => {
    if(!isLogin()) {
      navigate("/log-in", {state:{nextPath: "/member/booking"}})
    }
  },[])

  return (
    <div>
    <MoblieHeader left center="Menu.Booking Box" centerTitleImage="mobile-bookings"/>
    <div className="text-center web:pt-8 web:px-8 phone:px-0 phone:pt-0 pb-[100px] mx-auto lg:max-w-1216">
      <div className="flex">
        <HostAccount traveler />
        <HostAccountHeader
          titleIcon="mobile-bookings"
          title="Menu.Booking Box"
        >
          <div className="border-b w-full h-11 flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll">
            {tabInfo.map((item, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => {
                    ChangeTab(item.id);
                  }}
                  className={`${
                    item.id === tabNum
                      ? "bg-light-green text-pure-white"
                      : " text-input-text"
                  } cursor-pointer px-4 items-center flex hover:bg-light-green focus:bg-light-green rounded-t-lg hover:text-pure-white`}
                >
                  {t(`Booking-box-status.${item.title}`)}
                </div>
              );
            })}
          </div>
          <div className="pt-8">
          {tabNum === 0 ? (
                <MemberCurrentBooking/>
          ) : 
          tabNum === 1 ? (
              <MemberBookingHistory/>
          ) : 
          tabNum === 2 ? (
              <MemberCancellledBooking/>
          ) : ""
        }
        </div>
        </HostAccountHeader>
      </div>
      </div>
    </div>
  );
};

export default BookingBox;
