export const inputPriceComma = (string: string) => {
    string = String(string);
    return string.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}

export const uncomma = (str: string) => {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
};

export const inputPriceFormat = (str:string) => {
    const comma = (str:string) => {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    };
    const uncomma = (str:string) => {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    };
    return comma(uncomma(str));
  };