import Button from "elements/Button";
import InquiryModal from "elements/InquiryModal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";

interface InquiryCardProps {
  answered?: string;
  content?: string;
  memeberName?: string;
  answer?: string;
  hostName?: string;
  createdAt?: any;
  onClick?: () => void;
  onRespond?: any;
  respond?: boolean;
  respondInput?: boolean;
  hostId?: number;
  id?: number;
  memberId?: number;
}

const HostInquiryCard = ({
  answered,
  hostName,
  content,
  memeberName,
  createdAt,
  onClick,
  onRespond,
  answer,
  respond,
  respondInput,
  hostId,
  id,
  memberId,
}: InquiryCardProps) => {
  const { t } = useTranslation();
  const [inquiryValue, setInquiryValue] = useState("");
  const [inquiryModal, setInquiryModal] = useState(false);

  const answerInquiry = () => {
    requestToMustOutdoor({
      method: "PATCH",
      url: `/api/v2/qna/${id}`,
      data: {
        content: TextareaReplaceNewLine(inquiryValue),
        hostId: hostId,
        memberId: memberId,
        acId: 1,
        atId: 1,
        pgId: 1,
      },
    }).then((res) => {
      const data = res.data.data;
      window.location.reload();
      return data;
    });
  };

  const deleteInquiry = () => {
    requestToMustOutdoor({
      method: "DELETE",
      url: `/api/v2/qna/${id}`,
    }).then((res) => {
      const data = res.data.data;
      window.location.reload();
      return data;
    });
  };

  return (
    <tbody>
      <tr>
        {/* {answered ? (
          <td className="text-light-green font-bold text-sm p-4">
            {t("Message-box.Answered")}
          </td>
        ) : (
          <td className="text-tan font-bold text-sm p-4">
            {t("Message-box.Unanswered")}
          </td>
        )} */}
        <td
          className={`p-4 text-sm w-full ${
            (respond && !answered) || (respond && answered)
              ? "bg-[#fbfbfb]"
              : ""
          }`}
        >
          <div onClick={onClick} className="cursor-pointer">
            <div className="flex items-center justify-between mb-2">
              {answered ? (
                <div className="text-light-green font-bold text-sm">
                  {t("Message-box.Answered")}
                </div>
              ) : (
                <div className="text-tan font-bold text-sm">
                  {t("Message-box.Unanswered")}
                </div>
              )}

              <div>{createdAt}</div>
            </div>
            <div>
              {t("Message-box.Traveler inquiry from {{product_title}}")}
            </div>
            <div className="my-2 font-bold">{memeberName}</div>
            <div className="cursor-pointer">{content}</div>
          </div>
          {respond && !answered ? (
            <div
              onClick={onRespond}
              className="cursor-pointer text-sm mt-2 text-link"
            >
              <div
                onClick={() => {
                  setInquiryModal(true);
                }}
              >
                {t("Message-box.Respond")} {">"}
              </div>
            </div>
          ) : respond && answered ? (
            <div className="w-full mt-2 rounded-lg bg-border-light p-2">
              <div>{t("Message-box.Host Response")}</div>
              <div className="my-2">{hostName}</div>
              <div>{answer}</div>
              <div
                onClick={() => {
                  deleteInquiry();
                }}
                className="mt-2 cursor-pointer text-pure-red"
              >
                {t("Message-box.Delete")}
              </div>
            </div>
          ) : (
            ""
          )}
          {respondInput && respond && inquiryModal ? (
            <InquiryModal>
              <div className="bg-pure-white web:h-full phone:h-[600px] phone:overflow-y-scroll rounded-lg p-8">
                <div className="flex justify-between items-center">
                  <div className="text-2xl font-bold mb-4">1:1 문의 답변</div>
                  <img
                    onClick={() => {
                      setInquiryModal(false);
                    }}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/menu-close.svg"
                    alt=""
                    className="w-5 h-5 mr-2 cursor-pointer"
                  />
                </div>
                <div className="flex items-center justify-between">
                  <div>
                    {t("Message-box.Traveler inquiry from {{product_title}}", {
                      product_title: "product title",
                    })}
                  </div>
                  <div>{createdAt}</div>
                </div>
                <div className="my-2 font-bold">{memeberName}</div>
                <div>{content}</div>
                <textarea
                  onChange={(e) => {
                    setInquiryValue(e.target.value);
                  }}
                  className="acInput my-4"
                  rows={8}
                  placeholder={t("Message-box.Please enter your answer.")}
                />
                <div className="text-sm">
                  {t(
                    "Message-box.You can find answers to your inquiries in the 1:1 inquiry in the message box."
                  )}
                </div>
                <div className="my-8 text-center">
                  <Button
                    onClick={() => answerInquiry()}
                    btnText={"Message-box.Submit"}
                    className="py-3 px-14 inline-block text-pure-white rounded-lg bg-light-green"
                  />
                </div>
                <div className="border border-input-border-color bg-[#f8f8f8] flex flex-col justify-between p-4 web:h-[200px] phone:h-[400px] text-sm">
                  <div className="text-base font-bold flex items-center">
                    <img
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                      alt=""
                      className="w-5 h-5 mr-2"
                    />
                    <div>{t("Message-box.Notice on writing 1:1 Inquiry")}</div>
                  </div>
                  <div>
                    {t(
                      "Message-box.1:1 inquiry allows travellers to contact their hosts"
                    )}
                  </div>
                  <div>
                    {t("Message-box.To protect your personal information")}
                  </div>
                  <div>
                    {t(
                      "Message-box.If the traveler directly make payment transaction"
                    )}
                  </div>
                  <div>
                    {t(
                      "Message-box.In order to minimize safe transactions and disputes"
                    )}
                  </div>
                </div>
              </div>
            </InquiryModal>
          ) : (
            <div></div>
          )}
        </td>
        {/* <td className="text-right p-4 text-sm web:min-w-[111px] phone:min-w-0">{createdAt}</td> */}
      </tr>
    </tbody>
  );
};

export default HostInquiryCard;
