import React, { useEffect, useState } from "react";

import { t } from "i18next";

import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import MoblieHeader from "components/moblieHeader";

import SpaceAmenities from "./spaceAmenities";
import SpaceGallery from "./spaceGallery";
import SpaceGeneralInfo from "./spaceGeneralInfo";
import SpacePricing from "./spacePricing";
import { useTranslation } from "react-i18next";
import { useDidMountEffect } from "hooks/useDidMountEffect";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useLocation, useNavigate } from "react-router-dom";
import NewModal from "elements/newModal";
import { useAppDispatch } from "redux/store";
import { spAmenities, spGallery, spGeneralInfo } from "redux/slice/spaceSlice";

const AddSpace = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state }: any = useLocation();
  const { t } = useTranslation();
  const [tabNum, setTabNum] = useState(0);
  const hostNum = localStorage.getItem("hostNum");

  const [geneData, setGeneData] = useState([]);
  const [galleryData, setGalleryData] = useState<any>([]);
  const [amenityData, setAmenityData] = useState([]);
  const [priceData, setPriceData] = useState([]);

  const [registModal, setRegistModal] = useState(false);
  const [blankModal, setBlankModal] = useState(false);

  const acId = state?.acId;

  const tabTitle = [
    { id: 0, title: "General Info" },
    { id: 1, title: "Space Gallery" },
    { id: 2, title: "Space Amenities" },
    { id: 3, title: "Operation" },
  ];

  const handleOnNext = () => {
    setTabNum(tabNum + 1);
    window.scrollTo(0, 0);
  };
  const handleOnPrev = () => {
    setTabNum(tabNum - 1);
    window.scrollTo(0, 0);
  };

  const data = {
    acId: Number(acId),
    ...geneData,
    hostId: Number(hostNum),
    currentImage: galleryData?.currentImage ? galleryData?.currentImage : "",
    imageList: galleryData?.imageList ? galleryData?.imageList : "",
    ...amenityData,
    ...priceData,
  };

  const Submit = async () => {
    try {
      requestToMustOutdoor
        .post("/api/v2/space", data)
        .then((res) => {
          dispatch(spGeneralInfo(""));
          dispatch(spGallery(""));
          dispatch(spAmenities(""));
          setRegistModal(true);
          return res.data.data;
        })
        .catch((err) => {
          setBlankModal(true);
          return err;
        });
    } catch {}
  };

  useDidMountEffect(() => {
    Submit();
  }, [priceData]);

  // 새로고침 막기
  useEffect(() => {
    // 새로고침 막기(조건 부여 가능)
    window.onbeforeunload = function() {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <div className="web:text-center phone:text-left web:p-8 mx-auto lg:max-w-1216">
      <MoblieHeader left center="Add Space" />
      <div className="web:flex">
        <HostAccount />
        <HostAccountHeader
          titleIcon="outdoor-accommodation"
          title="Add-space.Add Space"
        >
          <div
            className="border-b h-11 flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll"
          >
            {registModal ? (
              <NewModal
                onClick={() => {
                  navigate("/host/myaccommodation", { replace: true });
                }}
                setModalOpen={() => {
                  setRegistModal(!registModal);
                }}
                imageUrl="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/register-completed-color.svg"
                title={"Add-space.Completed to add Space registration!"}
                buttonText={t("Add-space.Go to My Accommodation")}
                buttonColor={`bg-light-green text-pure-white`}
              >
                <div className="mt-2">
                  <div>
                    {t(
                      "Add-space.Go My Accommodation and check your registered space."
                    )}
                  </div>
                  <div>
                    {t(
                      "Add-space.Your registered space will be screening and authorized"
                    )}
                  </div>
                </div>
              </NewModal>
            ) : (
              ""
            )}
            {blankModal ? (
              <NewModal
                onClick={() => {
                  setBlankModal(false);
                }}
                setModalOpen={() => {
                  setBlankModal(!blankModal);
                }}
                imageUrl="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-red.svg"
                title={t("Add-space.Please Fill Out All Required Information")}
                buttonText={t("Add-space.Continue to fill out")}
                buttonColor={`bg-light-green text-pure-white`}
              >
                <div className="mt-4">
                  {t(
                    "Add-space.You cannot register your space unless you complete"
                  )}
                </div>
              </NewModal>
            ) : (
              ""
            )}
            {tabTitle.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={`
                  ${
                    item.id === tabNum
                      ? "bg-light-green text-pure-white"
                      : " text-input-text"
                  } px-4 items-center flex focus:bg-light-green rounded-t-lg`}
                >
                  {t(`Add-space.${item.title}`)}
                </div>
              );
            })}
          </div>
          {tabNum === 0 ? (
            <SpaceGeneralInfo
              geneData={setGeneData}
              onNext={() => {
                handleOnNext();
              }}
            />
          ) : tabNum === 1 ? (
            <SpaceGallery
              galleryData={setGalleryData}
              onNext={() => {
                handleOnNext();
              }}
              onPre={handleOnPrev}
            />
          ) : tabNum === 2 ? (
            <SpaceAmenities
              amenityData={setAmenityData}
              onNext={() => {
                handleOnNext();
              }}
              onPre={handleOnPrev}
            />
          ) : tabNum === 3 ? (
            <SpacePricing
              pricingData={setPriceData}
              onNext={() => {
                handleOnNext();
              }}
              onPre={handleOnPrev}
            />
          ) : (
            ""
          )}
        </HostAccountHeader>
      </div>
    </div>
  );
};

export default AddSpace;
