import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";

type miniCardProps = {
  onClick?: () => void;
  className?: string;
  url?: string;
  image?: string;
  title?: any;
};

const defaultProps = {
  url: "/search?keyword=gapyeong",
  title: "Gapyeong, S. Korea",
  image:
    "http://www.mustoutdoor.com/wp-content/uploads/2017/07/Taean-Dulegil-Main-400x300.jpg",
};

const CardMini = ({ className, onClick, url, title, image }: miniCardProps) => {
  return (
    <div
      onClick={onClick}
      className={`w-66 web:h-48 phone:h-40 relative cursor-pointer inline-block ${className}`}
    >
      <img
        className="bg-cover bg-center rounded-lg shadow-miniCard web:h-48 w-66 phone:h-40"
        // style={{ backgroundImage: `url('${image}')` }}
        src={image}
        alt=""
      />
      <div className="text-center p-2 w-full left-0 bottom-0 absolute">
        <span className="text-pure-white uppercase text-lg"> {title} </span>
      </div>
    </div>
  );
};

CardMini.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    url: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

CardMini.defaultProps = {
  className: "",
  data: defaultProps,
};
export default CardMini;
