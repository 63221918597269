import React from "react";

import BookingStatus from "pages/Host/bookingStatus";
import { useNavigate } from "react-router-dom";
import Icons from "elements/Icons";
import { useTranslation } from "react-i18next";

interface BookingCardProps {
  bookingNum?: string;
  checkIn?: string;
  checkOut?: string;
  className?: string;

  reviewClick?: any;

  id?: number;
  pgId?: number;
  host?: string;
  title?: string;
  imageUrl?: string;
  bookingCode?: string;

  bookingStatus?: string;
}

const BookingCard = ({
  id,
  title,
  pgId,
  host,
  bookingCode,
  bookingStatus,
  imageUrl,
  reviewClick,
  checkIn,
  checkOut,
}: BookingCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ids = { reserveId: id, pgId: pgId };
  return (
    <div
      className={`w-66 min-h-[302px] mr-4 web:[&:nth-child(3n)]:mr-0 rounded-lg shadow-ms inline-block mb-4`}
    >
      <div
        onClick={() => {
          navigate(`/member/${id}`, { state: ids });
        }}
        className="inline-flex w-full"
      >
        <img
          src={`${imageUrl}`}
          alt=""
          className="w-full h-40 rounded-t-lg relative"
        />
        <div className="absolute text-sm cursor-pointer font-bold opacity-0 hover:opacity-[0.7] hover:bg-[#222] text-pure-white text-center w-66 rounded-t-lg h-40 items-center flex flex-col justify-center">
          <Icons
            className="mb-4 w-12 h-12 fill-pure-white"
            iconName={"voucher"}
          />
          <div>View Details</div>
        </div>
      </div>
      <div className="w-full min-h-[109px] p-2 flex flex-col justify-between">
        <div className="font-bold mb-2 w-[260px] whitespace-nowrap overflow-hidden text-ellipsis">
          {title}
        </div>
        <div className="flex mb-2">
          <div className="w-[138px]">
            <div className="text-xs text-input-text">
              {t("Booking-box-status.Check-in")}
            </div>
            <div className="text-sm">{checkIn}</div>
          </div>
          <div>
            <div className="text-xs text-input-text">
              {t("Booking-box-status.Check-out")}
            </div>
            <div className="text-sm">{checkOut}</div>
          </div>
        </div>
        <div className="flex">
          <div className="w-[138px]">
            <div className="text-xs text-input-text">
              {t("Booking-box-status.Host")}
            </div>
            <div className="text-sm">{host}</div>
          </div>
          <div>
            <div className="text-xs text-input-text">
              {t("Booking-box-status.Booking #")}
            </div>
            <div className="text-sm">{bookingCode}</div>
          </div>
        </div>
      </div>
      {bookingStatus === "CONFIRMED" ? (
        <div className="fill-light-yellow font-bold text-center py-2 text-sm rounded-b-lg bg-light-green text-pure-white flex justify-center items-center">
          <Icons iconName={`status-confirmed-approve`} className="w-4 h-4" />
          <div className="ml-2">{t("Booking-status-data.Confirmed")}</div>
        </div>
      ) : bookingStatus === "PENDING" ? (
        <div className="fill-light-yellow font-bold text-center py-2 text-sm rounded-b-lg bg-pending text-pure-white flex justify-center items-center">
          <Icons
            iconName={`pending`}
            className="mr-2 fill-pure-white w-4 h-4"
          />
          <div>{t("Booking-status-data.Pending")}</div>
        </div>
      ) : bookingStatus === "REQUEST_CANCELLED" ||
        bookingStatus === "CANCELLED_BY_TRAVELER" ||
        bookingStatus === "CANCELLED_BY_HOST" ||
        bookingStatus === "CANCELLED_BY_ADMIN" ? (
        <div className="shadow-btn fill-light-yellow font-bold text-center py-2 text-sm rounded-b-lg text-pure-red flex justify-center items-center">
          <Icons
            iconName={`status-warning`}
            className="mr-2 fill-pure-red w-4 h-4"
          />
          <div>{t("Booking-status-data.Cancelled")}</div>
        </div>
      ) : bookingStatus === "DECLINED" ? (
        <div className="shadow-btn h-[33px] fill-light-yellow font-bold text-center py-2 text-sm rounded-b-lg text-pure-red flex justify-center items-center">
          <Icons
            iconName={`status-cancelled-void`}
            className="mr-2 fill-pure-red w-4 h-4"
          />
          <div>{t("Booking-status-data.Declined")}</div>
        </div>
      ) : bookingStatus === "PAYMENT_ERROR" ? (
        <div className="shadow-btn h-[33px] fill-light-yellow font-bold text-center py-2 text-sm rounded-b-lg text-pure-red flex justify-center items-center">
          <Icons
            iconName={`status-failed`}
            className="mr-2 fill-link w-4 h-4"
          />
          <div>Failed</div>
        </div>
      ) : bookingStatus === "COMPLETED" ? (
        <div
          //  onClick={reviewClick}
          className="shadow-btn h-[33px] bg-yellow font-bold text-center py-2 text-sm rounded-b-lg flex justify-center items-center"
        >
          <Icons iconName={`leave-review`} className="mr-2 w-4 h-4" />
          <div>리뷰쓰기 준비중</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BookingCard;
