import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import k from './k.json';
import e from './e.json';
import j from './j.json';

export const languages = [
  {
    text: '한국어',
    code: 'k',
  },
  {
    text: 'English',
    code: 'e',
  },
  {
    text: '日本語',
    code: 'j',
  },
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      k,
      e,
      j
    },
    // lng: 'k',
    fallbackLng: 'k',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    nsSeparator: "",
    interpolation: {
      escapeValue: false,
      formatSeparator: '.',
    },
    react: {
      // wait: true,
      nsMode: 'default',
      useSuspense: true,
    },
  });

export default i18n;
