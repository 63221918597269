import React, { useEffect, useState } from "react";
import { addDays, addMonths, format } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";
import moment from "moment";

import "react-day-picker/dist/style.css";
import { changeDate, returnDate } from "utils/changeDate";
import { getDiffDay, getEveryDiffDay } from "utils/getDiffDay";

interface selectedDateProps {
  from_?: any;
  to_?: any;
  calendars?: any;
  datePrice?: any;
  advanceDate?: boolean;
}

const DayPickerCompo = ({
  from_,
  to_,
  calendars,
  datePrice,
  advanceDate,
}: selectedDateProps) => {
  const css = `
    * {
        --rdp-accent-color: #538a40 !important;
        --rdp-outline-selected: #538a40
    }
    .rdp-day_today { 
    color: #538a40;
    font-size: 130%; 
    }
    `;

  const [range, setRange] = useState<DateRange | any>();
  const [selectStartDay, setSelectStartDay] = useState("");

  // 2022-01-01
  const startChangeDate =
    range?.from !== undefined ? changeDate(range?.from) : "";
  const endChangeDate = range?.to !== undefined ? changeDate(range?.to) : "";

  // // 2022-1-1
  const startReturnDate =
    range?.from !== undefined ? returnDate(range?.from) : "";
  const endReturnDate = range?.to !== undefined ? returnDate(range?.to) : "";

  let footer;
  if (range?.from) {
    if (!range.to) {
      footer = (
        <div className="flex font-bold text-light-green justify-center">
          {moment(range.from).format("YYYY년 MM월 DD일 부터")}
        </div>
      );
    } else if (range.to) {
      footer = (
        <div className="font-bold text-light-green px-4 mt-2">
          <div className="text-left">
            {moment(range.from).format("YYYY년 MM월 DD일 부터")} ~
          </div>
          <div className="text-right">
            {moment(range.to).format("YYYY년 MM월 DD일 까지")}
          </div>
        </div>
      );
    }
  }

  useEffect(() => {
    const start = new Date(range?.from);
    const value = returnDate(start.setDate(start.getDate() + 1));
    const splitValue = value.split("-");
    const result = splitValue[0] + "/" + splitValue[1] + "/" + splitValue[2];

    const calendarValue = calendars
      .map((item: any) => {
        const status = item.status;
        const date = item.date;
        const availableAmount = item.availableAmount;
        if (status === "UNAVAILABLE" || availableAmount === 0) {
          const dateValue = date[0] + "-" + date[1] + "-" + date[2];
          const getDiff = getDiffDay(result, dateValue);
          return { date: dateValue, diff: getDiff };
        }
      })
      .filter((x: any) => x !== undefined);

    // const noUnde = calendarValue.filter((x:any) => x !== undefined)
    const diffValue = calendarValue
      .map((item: { diff: number; date: string }) => {
        if (item.diff! >= 0) {
          return item;
        }
      })
      .filter((x: any) => x !== undefined);

    const selectedResult = diffValue[0]?.date;

    setSelectStartDay(selectedResult);
  }, [calendars, range?.from]);

  useEffect(() => {
    if (startChangeDate !== "" && endChangeDate !== "") {
      const selectResult = calendars?.map((item: any) => {
        const startDay = startChangeDate;
        const endDay = endChangeDate;
        const diffDay = getEveryDiffDay(startDay, endDay);

        const itemDay = item?.date;
        const itemResultDay =
          itemDay[0] +
          "-" +
          ("00" + itemDay[1]).slice(-2) +
          "-" +
          ("00" + itemDay[2]).slice(-2);
        if (diffDay?.includes(itemResultDay)) {
          return item;
        }
      });

      const selectedNoUndefined = selectResult?.filter(
        (x: any) => x !== undefined
      );

      const priceObject = selectedNoUndefined?.map((item: any) => {
        const price = Number(item.price);
        return price;
      });
      priceObject.pop();
      
      const sum = priceObject.length !== 0 ? priceObject?.reduce((a: number, b: number) => a + b) : [];  
      datePrice(sum);
    }
  }, [calendars, datePrice, endChangeDate, startChangeDate]);

  const from = range?.from;
  const to = range?.to;
  const startTime = () => {
    const dateValue = changeDate(from);
    return dateValue;
  };
  const endTime = () => {
    const dateValue = changeDate(to);
    return dateValue;
  };

  useEffect(() => {
    from_(startTime());
    to_(endTime());
  }, [endTime, startTime]);

  const disabledDay = calendars?.map((item: any) => {
    const status = item.status;
    const date = item.date;
    const availableAmount = item.availableAmount;
    if (status === "UNAVAILABLE" || availableAmount === 0) {
      const value = date[0] + "-" + date[1] + "-" + date[2];
      const selectDay = new Date(selectStartDay);
      const disDay = returnDate(selectDay.setDate(selectDay.getDate() + 1));
      const disabledDay = disDay.split("-");
      const result =
        disabledDay[0] + "-" + disabledDay[1] + "-" + disabledDay[2];
      return selectStartDay === value ? result : value;
    }
  });

  const noUndefinedDisabledDay = disabledDay?.filter(
    (x: string) => x !== undefined
  );

  const data = noUndefinedDisabledDay?.map((item: any) => {
    return item ? new Date(item) : [new Date(2021 / 10 / 10)];
  });

  const yesterday = moment().subtract(advanceDate ? 1 : 0, "day");

  const disabledDays = [
    ...data,
    (day: any) => day > addMonths(new Date(), 3),
    (day: any) => day < yesterday,
  ];

  const disabledMap = disabledDays?.map((item: any) => {
    const disabledValue = moment(item);
    return disabledValue;
  });

  const disabledCheck = disabledMap?.map((item) => {
    return moment(item).isBetween(startTime(), endTime());
  });
  const disalbledValue = disabledCheck?.includes(true);

  useEffect(() => {
    if (disalbledValue === true) {
      setRange("");
    } else {
    }
  }, [disalbledValue]);

  useEffect(() => {
    if (range?.from) {
    }
  }, [range?.from, range]);

  return (
    <>
      <style>{css}</style>
      <DayPicker
        mode="range"
        today={new Date()}
        defaultMonth={new Date()}
        max={5}
        disabled={disabledDays}
        selected={disalbledValue ? false : range}
        onSelect={setRange}
        footer={footer}
      />
    </>
  );
};

export default DayPickerCompo;
