import React, { useEffect, useRef, useState } from "react";

import Button from "elements/Button";
import DetailInfo from "elements/DetailInfo";
import DetailTheme from "elements/DetailTheme";

import GoogleMap from "components/GoogleMap";

import { changecase, changeFacility } from "utils/changeCase";
import { useSelector } from "react-redux";
import { previewData } from "redux/action/private";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "redux/store";
import { useTranslation } from "react-i18next";
import SideMenu from "elements/sideMenu";
import { inputPriceComma } from "utils/commaDash";
import FullSizeModal from "elements/fullSizeModal";
import MoblieHeader from "components/moblieHeader";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import Icons from "elements/Icons";
import { changeDate } from "utils/changeDate";

const Preview = () => {
  const { t } = useTranslation();
  const value = useLocation();
  const navigate = useNavigate();
  const [previewData, setPreviewData] = useState([]);

  const [AV, setAccessibleVehicle] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [touristModal, setTouristModal] = useState(false);
  const [touristDetail, setTouristDetail] = useState<any>([]);

  const [newImageList, setNewImageList] = useState<any[]>([]);

  const [specialTab, setSpecialTab] = useState(false);
  const [extraTab, setExtraTab] = useState(false);
  const [availableTab, setAvailableTab] = useState(false);
  const [locationTab, setLocationTab] = useState(false);
  const [bookingTab, setBookingTab] = useState(false);
  const [policyTab, setPolicyTab] = useState(false);

  const [imageModal, setImageModal] = useState(false);

  const [active, setActive] = useState<any[]>([]);

  useEffect(() => {
    if (value?.state) {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v2/private-ground/${value?.state}`,
      }).then((res) => {
        const data = res.data.data;
        setPreviewData(data);
        return data;
      });
    } else {
      alert("잘못된 접근 방법입니다");
      navigate("/", { replace: true });
    }
  }, [value?.state]);
  const allPhotoView = () => {
    setImageModal((imageModal) => !imageModal);
  };

  const {
    title,
    typeInfo,
    hostInfo,
    state,
    city,
    address,
    advanceDate,
    zipCode,
    images,
    currentImage,
    locationThemes,
    facilities,
    destinationInfo,
    confirmMethod,
    description,
    policyInfo,
    activities,
    restricts,
    specialFacility,
    extraPolicy,
    extraOption,
    direction,
    indicator,
    nearbyFacilities,
    touristSpots,
    bookingTime,
    unitCount,
    accessibleVehicle,
    spaceSize,
    priceInfo,
  } = previewData as any;

  const create = changeDate(hostInfo?.createdAt).substr(0, 7);

  useEffect(() => {
    if (images && currentImage) {
      setNewImageList([currentImage]);
      setNewImageList((pre: any) => [...pre, ...images]);
    }
  }, [currentImage, images]);

  const minimumPrice = () => {
    if (priceInfo) {
      let arr = Object.values(priceInfo);
      const toMinimumPrice = arr.map((i) => Number(i));
      const noZero = toMinimumPrice.filter((e) => e !== 0);
      const value = Math.min.apply(null, noZero);
      const Comma = inputPriceComma(String(value));
      return Comma;
    }
  };

  useEffect(() => {
    if (accessibleVehicle === "BACKPACKER") {
      setAccessibleVehicle("Only Backpacker");
    } else if (accessibleVehicle === "AV1") {
      setAccessibleVehicle("Up to compact / light size");
    } else if (accessibleVehicle === "AV2") {
      setAccessibleVehicle("Up to mid / large size");
    } else if (accessibleVehicle === "AV3") {
      setAccessibleVehicle("Up to compact RV (3 pax)");
    } else if (accessibleVehicle === "AV4") {
      setAccessibleVehicle("Up to mid / large RV (5 pax)");
    } else if (accessibleVehicle === "AV5") {
      setAccessibleVehicle("Small/mid Caravan or Trailer(3 pax)");
    } else if (accessibleVehicle === "AV6")
      setAccessibleVehicle("Large Caravan or Trailer (5 pax)");
  }, [accessibleVehicle]);

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <div
      {...props}
      className={currentSlide === 0 ? " slick-disabled" : ""}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <Icons
        className={`cursor-pointer w-8 h-8 absolute z-100 top-[50%] right-[25px]`}
        iconName="slider-arrow-right"
      />
    </div>
  );
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
    <div
      {...props}
      className={currentSlide === 0 ? " slick-disabled" : ""}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <Icons
        className={`cursor-pointer w-8 h-8 absolute z-100 top-[50%] left-[25px]`}
        iconName="slider-arrow-left"
      />
    </div>
  );

  const elementRef = useRef<HTMLImageElement>(null);

  const setting = {
    centerMode: true,
    infinite: true,
    appendDots: (dots: any) => (
      <div
        style={{
          width: "86.5%",
          position: "absolute",
          bottom: "-19px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    slidesToShow: 1,
    swipeToSlide: false,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
  };

  const specialTabToggle = () => {
    setSpecialTab((specialTab) => !specialTab);
  };
  const extraTabToggle = () => {
    setExtraTab((extraTab) => !extraTab);
  };
  const availableTabToggle = () => {
    setAvailableTab((availableTab) => !availableTab);
  };
  const locationTabToggle = () => {
    setLocationTab((locationTab) => !locationTab);
  };
  const bookingTabToggle = () => {
    setBookingTab((bookingTab) => !bookingTab);
  };
  const policyTabToggle = () => {
    setPolicyTab((policyTab) => !policyTab);
  };

  const touristInfo = (title: string, desc: string, imgUrl: string) => {
    setTouristDetail({ title: title, desc: desc, imgUrl: imgUrl });
    setTouristModal(true);
  };

  return (
    <div>
      <MoblieHeader center={title} left />
      <SideMenu
        setIsOpenTab={setSpecialTab}
        isOpenTab={specialTab}
        tabTitle="Product-details.Special Facility"
      >
        {specialFacility ? (
          <div>
            <div>{specialFacility}</div>
          </div>
        ) : (
          <div>{t(`Common.N/A`)}</div>
        )}
      </SideMenu>

      <SideMenu
        setIsOpenTab={setExtraTab}
        isOpenTab={extraTab}
        tabTitle="Product-details.Extra Options"
      >
        {extraOption &&
        !!extraOption[0]?.title &&
        !!extraOption[0]?.description ? (
          extraOption.map(
            (
              { title, description, maxOfNumber, price, paymentMethod }: any,
              key: number
            ) => {
              return (
                <div key={key} className="w-full">
                  <div className="flex justify-between items-center">
                    <div>{title}</div>
                    <div>{inputPriceComma(price)}</div>
                  </div>
                  <div className="my-2">
                    {paymentMethod === "ON_SITE_PAYMENT" ? (
                      <div className="flex items-center">
                        <img
                          className="w-6 h-6"
                          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/on-site-payment-only.svg"
                          alt=""
                        />
                        <div className="text-sm ml-2">
                          {t("Extra-payment-method.On-Site Payment")}
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <img
                          className="w-6 h-6"
                          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-in-extra-option.svg"
                          alt=""
                        />
                        <div className="text-sm ml-2 text-main-green">
                          {t("Extra-payment-method.Available in Extra Option")}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>{description}</div>

                  <hr className="my-4 last:hidden" />
                </div>
              );
            }
          )
        ) : (
          <div>{t(`Common.N/A`)}</div>
        )}
      </SideMenu>
      <SideMenu
        setIsOpenTab={setAvailableTab}
        isOpenTab={availableTab}
        tabTitle="Product-details.Available Ground"
      >
        Available Ground
      </SideMenu>
      <SideMenu
        setIsOpenTab={setLocationTab}
        isOpenTab={locationTab}
        tabTitle="Product-details.Location"
      >
        <div className="mb-4 w-full h-64">
          <GoogleMap
            mapLocation={{
              lat: Number(`${indicator && indicator.latitude}`),
              lng: Number(`${indicator && indicator.longitude}`),
            }}
          />
        </div>
        <div className="font-bold mb-2">{t("Product-details.Location")}</div>
        <div className="text-sm flex items-center">
          <img
            className="w-6 h-6 mr-2"
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/real-address.svg"
            alt=""
          />
          <div>
            {zipCode}&nbsp;
            {destinationInfo && destinationInfo.country}&nbsp;
            {state}&nbsp;
            {city}&nbsp;
            {address}
          </div>
        </div>
        <hr className="my-4 text-input-border-color" />
        <div className="font-bold mb-2">
          {t("Product-details.How To Get There")}
        </div>
        <div className="text-sm">
          {direction ? direction : <div>{t(`Common.N/A`)}</div>}
        </div>
        <hr className="my-4 text-input-border-color" />
        <div className="w-full">
          <div className="font-bold mb-2">
            {t("Product-details.Nearby Facilities")}
          </div>
          {nearbyFacilities?.length !== 0 && nearbyFacilities ? (
            nearbyFacilities.map((item: any, i: number) => {
              if (item !== null) {
                return (
                  <div
                    key={i}
                    className="flex items-center mb-4 last:mb-0 justify-between w-full"
                  >
                    <div className="flex items-center">
                      <img
                        className="mr-2 w-5 h-5"
                        alt=""
                        src={
                          item.facility === "EV_CHARGING_STATION"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/ev-charge-station.svg"
                            : item.facility === "CONVENIENCE_STORE"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/convenience-store.svg"
                            : item.facility === "GAS_STATION"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/gas-station.svg"
                            : item.facility === "SUPERMARKET"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/supermarket.svg"
                            : item.facility === "TOILET_24HR"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/24hr-toilet.svg"
                            : ""
                        }
                      />
                      <div>
                        {t(`Nearby-facility.${changeFacility(item.facility)}`)}
                      </div>
                    </div>
                    <div className="flex text-sm items-center max-w-[200px]">
                      <div>{item.name}</div>
                      <div> ({item.distance}Km)</div>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <div>{t(`Common.N/A`)}</div>
          )}
          <hr className="my-4 text-input-border-color" />
        </div>
        <div>
          <div className="font-bold mb-2">
            {t("Product-details.Recommending Tourist Spot")}
          </div>
          {touristSpots &&
          !!touristSpots[0]?.title &&
          !!touristSpots[0]?.description &&
          !!touristSpots[0]?.photoUrl ? (
            touristSpots.map(
              ({ title, description, photoUrl }: any, key: number) => {
                return (
                  <div
                    key={key}
                    className="w-64 h-[161px] shadow-card rounded-lg relative"
                  >
                    <div
                      onClick={() => {
                        touristInfo(title, description, photoUrl);
                      }}
                      className="opacity-0 hover:opacity-50 flex justify-center w-full h-32 cursor-pointer items-center hover:bg-black rounded-t-lg absolute"
                    >
                      <Icons iconName="view-detail" className="w-8 h-8" />
                    </div>
                    <img
                      className="w-full h-32 rounded-t-lg"
                      src={photoUrl}
                      alt=""
                    />
                    <div className="w-full h-[33px] rounded-b-lg bg-pure-white flex justify-center items-center text-sm ">
                      {title}
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <div>{t(`Common.N/A`)}</div>
          )}
        </div>
      </SideMenu>
      <SideMenu
        setIsOpenTab={setBookingTab}
        isOpenTab={bookingTab}
        tabTitle="Product-details.Policy Details"
      >
        <div className="font-bold mb-2">
          {t("Product-details.Confirmation")}
        </div>
        <div>
          <div>
            {t(
              "Confirmation-data.You will receive booking confirmation-hours",
              { confirm: "24" }
            )}
          </div>
          <div>
            {t(
              "Confirmation-data.In the event that you do not receive an email from us"
            )}
          </div>
          <hr className="my-4 text-input-border-color" />
        </div>
        <div className="font-bold mb-2">
          {t("Product-details.Advance Notice")}
        </div>
        <div>
          {advanceDate
            ? t("Advance-notice-data.Booking is not available today")
            : t("Advance-notice-data.Booking is available today")}
        </div>
        <hr className="my-4 text-input-border-color" />
        <div className="font-bold mb-2">
          {t("Product-details.Additional Rule / Policy")}
        </div>
        <div>{extraPolicy ? extraPolicy : <div>{t(`Common.N/A`)}</div>}</div>
      </SideMenu>
      <SideMenu
        setIsOpenTab={setPolicyTab}
        isOpenTab={policyTab}
        tabTitle="Product-details.Cancellation & Refund Policy"
      >
        <div className="font-bold mb-4 flex">
          <div className="text-pure-red">
            {t(
              policyInfo &&
                `Cancellation-policy-data.${changecase(policyInfo.name)}`
            )}{" "}
          </div>
          <div>{t("Product-details.Policy on Cancellation")}</div>
        </div>
        <div>
          {policyInfo?.firstRange !== null
            ? t("Cancellation-policy-data.{{days}}100% refund of the payment", {
                days: policyInfo?.firstRange,
              }) + "\n"
            : ""}
          {policyInfo?.secondRange !== null
            ? t("Cancellation-policy-data.{{days}}70% refund of the payment", {
                days: policyInfo?.secondRange,
              }) + "\n"
            : ""}
          {policyInfo?.thirdRange !== null
            ? t("Cancellation-policy-data.{{days}}50% refund of the payment", {
                days: policyInfo?.thirdRange,
              }) + "\n"
            : ""}
          {policyInfo?.fourthRange !== null
            ? t("Cancellation-policy-data.{{days}}30% refund of the payment", {
                days: policyInfo?.fourthRange,
              }) + "\n"
            : ""}
          {policyInfo?.fifthRange !== null
            ? t("Cancellation-policy-data.{{days}}0% refund of the payment", {
                days: policyInfo?.fifthRange,
              })
            : ""}
        </div>
      </SideMenu>
      {imageModal ? (
        <FullSizeModal
          isOpen={() => {
            setImageModal((imageModal) => !imageModal);
          }}
          imgList={newImageList}
        />
      ) : (
        ""
      )}
      {touristModal === true ? (
        <FullSizeModal
          tourist
          TouristTitle={touristDetail?.title}
          TouristDesc={touristDetail?.desc}
          isOpen={() => {
            setTouristModal((touristModal) => !touristModal);
          }}
          imgList={touristDetail?.imgUrl}
        />
      ) : (
        ""
      )}
      <div className="web:hidden phone:block">
        <div
          onClick={() => {
            setImageModal(true);
          }}
          className="bg-center cursor-pointer bg-cover w-full h-52"
          style={{
            backgroundImage: `url(${newImageList.length !== 0 &&
              newImageList[0].url})`,
          }}
        />
        <div className="p-4 text-left w-full">
          <div className="flex flex-col">
            <div className="text-xl font-bold">{title}</div>
            <div className="flex my-2 items-baseline">
              <div className="text-xs text-input-text">from</div>
              <div className="font-bold ml-1">{minimumPrice()}</div>
            </div>
            <div className="flex justify-between">
              <div className="flex items-baseline">
                <img
                  className="w-4 h-4"
                  src={
                    "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/rating-star-o.svg"
                  }
                  alt=""
                />
                <div className="font-bold mx-1">4.3</div>
                <div className="text-xs text-input-text">237 Reviews</div>
              </div>
              <div className="flex">
                <img
                  className="w-6 h-6"
                  src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/wishlist-black.svg`}
                  alt=""
                />
                <img
                  className="w-6 h-6 ml-4"
                  src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/share.svg`}
                  alt=""
                />
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div className="text-sm">
            <div className="flex mb-4 items-center">
              <img
                src={typeInfo?.iconUrl}
                className="w-6 h-6"
                alt={typeInfo?.displayName}
              />
              <div className="ml-2">
                {t(typeInfo && `Private-Type-data.${typeInfo.enName}`)}
              </div>
            </div>
            {locationThemes &&
              locationThemes?.map((item: any, key: any) => {
                const data = item.themeInfo;
                return (
                  <div key={key} className="flex items-center mb-4">
                    <img
                      src={data?.iconUrl}
                      className="w-6 h-6"
                      alt={data?.displayName}
                    />
                    <div className="ml-2">
                      {t(`Theme-data.${data?.enName}`)}
                    </div>
                  </div>
                );
              })}
            <div className="flex mb-4 items-center">
              <img
                className="w-6 h-6"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/cancellation-policy.svg"
                alt=""
              />
              <div className="ml-2">
                {t(
                  policyInfo &&
                    `Cancellation-policy-data.${changecase(policyInfo?.name)}`
                )}{" "}
                {t(`Product-details.Refund Policy`)}
              </div>
            </div>
            <div className="flex mb-4 items-center">
              <img
                className="w-6 h-6"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                alt=""
              />
              <div className="ml-2">
                {t(
                  confirmMethod &&
                    `Confirmation-data.${changecase(confirmMethod)}`
                )}
              </div>
            </div>
            <div className="flex items-center">
              <img
                className="w-6 h-6"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                alt=""
              />
              <div className="ml-2">
                {t(
                  advanceDate
                    ? "Advance-notice-data.Available Today"
                    : "Advance-notice-data.Unavailable Today"
                )}
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div>{t("Product-details.Description")}</div>
            <div className="text-sm mt-2">{description}</div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div className="text-xl font-bold">
              {t("Product-details.Hosted By") +
                " " +
                `${hostInfo && hostInfo.hostName}`}
            </div>
            <div className="flex mt-4">
              <img
                className="w-12 h-12"
                src={
                  hostInfo && hostInfo.imageUrl !== ""
                    ? hostInfo?.imageUrl
                    : `https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg`
                }
                alt=""
              />
              <div className="ml-4 text-sm">
                <div className="mb-2">{t("Product-details.Host Verified")}</div>
                <div className="mb-2">
                  {t(`Product-details.Joined in {{Joined_date}}`, {
                    Joined_date: create,
                  })}
                </div>
                <div className="mb-2">
                  {t("Product-details.Response Rate")}: {hostInfo?.resRate}%
                </div>
                <div>
                  {t("Product-details.Language")}:{" "}
                  {hostInfo?.languageCode[0]
                    ? t(`Language.${hostInfo?.languageCode[0]}`)
                    : ""}{" "}
                  {hostInfo?.languageCode[1]
                    ? t(`Language.${hostInfo?.languageCode[1]}`)
                    : ""}{" "}
                  {hostInfo?.languageCode[2]
                    ? t(`Language.${hostInfo?.languageCode[2]}`)
                    : ""}{" "}
                </div>
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div className="text-xl font-bold">
              {t("Product-details.Ground Details")}
            </div>
            <div className="mt-4">
              <div>{t(`Product-details.Facilty`)}</div>
              <div
                onClick={() => {
                  !active.includes(0)
                    ? setActive((active) => [...active, 0])
                    : setActive(active.filter((x) => x !== 0));
                }}
                className={`flex justify-between items-center cursor-pointer pb-2 border-border-light border-b`}
              >
                <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                  {facilities && facilities.length !== 0 ? (
                    facilities.map((item: any, key: number) => {
                      const data = item.facilityList;
                      return (
                        <img
                          key={key}
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-6 h-6 mr-4 mt-2 last:mr-0"
                        />
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(0) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(0) ? (
                <div className="mt-2">
                  {facilities && facilities.length !== 0 ? (
                    facilities.map((item: any, key: number) => {
                      const data = item.facilityList;
                      return (
                        <div
                          key={key}
                          className="mb-4 last:mb-0 flex items-center"
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6"
                          />
                          <div className="ml-2">
                            {t(`Facility-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="my-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div>
            <div className="mt-4">
              <div>{t(`Product-details.Activity`)}</div>
              <div
                onClick={() => {
                  !active.includes(1)
                    ? setActive((active) => [...active, 1])
                    : setActive(active.filter((x) => x !== 1));
                }}
                className={`flex justify-between items-center cursor-pointer pb-2 border-border-light border-b`}
              >
                <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                  {activities && activities.length !== 0 ? (
                    activities.map((item: any, key: number) => {
                      const data = item.activity;
                      return (
                        <img
                          key={key}
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-6 h-6 mr-4 mt-2 last:mr-0"
                        />
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(1) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(1) ? (
                <div className="mt-2">
                  {activities && activities.length !== 0 ? (
                    activities.map((item: any, key: number) => {
                      const data = item.activity;
                      return (
                        <div
                          key={key}
                          className="mb-4 last:mb-0 flex items-center"
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6"
                          />
                          <div className="ml-2">
                            {t(`Activity-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="my-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div>
            <div className="mt-4">
              <div>{t(`Product-details.Allowed Details`)}</div>
              <div
                onClick={() => {
                  !active.includes(2)
                    ? setActive((active) => [...active, 2])
                    : setActive(active.filter((x) => x !== 2));
                }}
                className={`flex justify-between items-center cursor-pointer pb-2 border-border-light border-b`}
              >
                <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                  {restricts && restricts.length !== 0 ? (
                    restricts.map((item: any, key: number) => {
                      const data = item.allowedList;
                      if (data.allowed === true) {
                        return (
                          <img
                            key={key}
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6 mr-4 mt-2 last:mr-0"
                          />
                        );
                      }
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(2) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(2) ? (
                <div className="mt-2">
                  {restricts && restricts.length !== 0 ? (
                    restricts.map((item: any, key: number) => {
                      const data = item.allowedList;
                      if (data.allowed === true) {
                        return (
                          <div
                            key={key}
                            className="mb-4 last:mb-0 flex items-center"
                          >
                            <img
                              src={data.iconUrl}
                              alt={data.enName}
                              className="w-6 h-6"
                            />
                            <div className="ml-2">
                              {t(`Allowed-data.${data.enName}`)}
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="my-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div>
            <div className="mt-4">
              <div>{t(`Product-details.Prohibited Details`)}</div>
              <div
                onClick={() => {
                  !active.includes(3)
                    ? setActive((active) => [...active, 3])
                    : setActive(active.filter((x) => x !== 3));
                }}
                className={`flex justify-between items-center cursor-pointer pb-2 border-border-light border-b`}
              >
                <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                  {restricts && restricts.length !== 0 ? (
                    restricts.map((item: any, key: number) => {
                      const data = item.allowedList;
                      if (data.allowed === false) {
                        return (
                          <img
                            key={key}
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6 mr-4 mt-2 last:mr-0"
                          />
                        );
                      }
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(3) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(3) ? (
                <div className="mt-2">
                  {restricts && restricts.length !== 0 ? (
                    restricts.map((item: any, key: number) => {
                      const data = item.allowedList;
                      if (data.allowed === false) {
                        return (
                          <div
                            key={key}
                            className="mb-4 last:mb-0 flex items-center"
                          >
                            <img
                              src={data.iconUrl}
                              alt={data.enName}
                              className="w-6 h-6"
                            />
                            <div className="ml-2">
                              {t(`Prohibited-data.${data.enName}`)}
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="mt-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
              <div
                onClick={() => specialTabToggle()}
                className="flex items-center cursor-pointer justify-between border-b pb-4 mb-4 border-border-light mt-4"
              >
                <div>{t("Product-details.Special Facility")}</div>
                <img
                  className="w-3 h-3"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                  alt=""
                />
              </div>
              <div
                onClick={() => extraTabToggle()}
                className="flex items-center cursor-pointer justify-between border-b pb-4 border-border-light"
              >
                <div>{t("Product-details.Extra Options")}</div>
                <img
                  className="w-3 h-3"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="text-xl font-bold mb-4">
              {t("Product-details.Available Ground")}
            </div>
            <img
              className="w-66 h-40 rounded-t-lg bg-center bg-cover"
              src={newImageList.length !== 0 ? newImageList[0]?.url : ""}
              alt=""
            />
            <div className="w-66 rounded-b-lg shadow-ms p-2">
              <div className="flex justify-between items-center">
                <div className="w-[191px] whitespace-nowrap font-bold text-main-green text-tiny overflow-hidden text-ellipsis">
                  {title}
                </div>
                <div
                  onClick={() => availableTabToggle()}
                  className="text-link cursor-pointer text-xs"
                >
                  {t("Product-details.Details")}
                  {" >"}
                </div>
              </div>
              <div className="flex mt-2 justify-between items-center">
                <div className="flex items-center">
                  <img
                    src={typeInfo?.iconUrl}
                    className="w-6 h-6"
                    alt={typeInfo?.displayName}
                  />
                  <div className="ml-2 text-sm">
                    {t(typeInfo && `Private-Type-data.${typeInfo.enName}`)}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-sm font-bold">{minimumPrice()}</div>
                  <div className="text-xs text-input-text">
                    {t("Product-details./ night")}
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div className="text-xl font-bold mb-4">
              {t("Product-details.Location")}
            </div>
          </div>
          <div className="my-8 w-full h-64">
            <GoogleMap
              mapLocation={{
                lat: Number(`${indicator && indicator.latitude}`),
                lng: Number(`${indicator && indicator.longitude}`),
              }}
            />
          </div>
          <div
            onClick={() => locationTabToggle()}
            className="cursor-pointer border-b pb-4 mb-4 border-border-light"
          >
            <div className="flex items-center justify-between">
              <div>{t("Product-details.Location")}</div>
              <img
                className="w-3 h-3"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                alt=""
              />
            </div>
            <div className="mt-4">
              {t(
                "Product-details.Information provided after booking confirmed"
              )}
            </div>
          </div>

          <div>
            <div className="text-xl font-bold mb-4">
              {t("Product-details.Policy")}
            </div>
            <div>{t(`Product-details.Check In / Out`)}</div>
            <div>
              <div className="flex justify-between text-sm my-2">
                <div>{t("Product-details.Check in")}</div>
                <div>{bookingTime && bookingTime.checkIn}</div>
              </div>
              <div className="flex justify-between text-sm">
                <div>{t("Product-details.Check out")}</div>
                <div>{bookingTime && bookingTime.checkOut}</div>
              </div>
              <hr className="my-4 text-border-light" />
            </div>
            <div
              onClick={() => bookingTabToggle()}
              className="flex items-center cursor-pointer justify-between border-b pb-4 mb-4 border-border-light"
            >
              <div>{t(`Product-details.Policy Details`)}</div>
              <img
                className="w-3 h-3"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                alt=""
              />
            </div>
            <div
              onClick={() => policyTabToggle()}
              className="cursor-pointer border-b pb-4 mb-4 border-border-light"
            >
              <div className="flex items-center justify-between">
                <div>{t(`Product-details.Cancellation & Refund Policy`)}</div>
                <img
                  className="w-3 h-3"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                  alt=""
                />
              </div>
              <div className="mt-4 flex">
                <div className="text-red">
                  {t(
                    policyInfo &&
                      `Cancellation-policy-data.${changecase(policyInfo.name)}`
                  )}{" "}
                </div>
                <div>{t("Product-details.Policy on Cancellation")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 웹 */}
      <div className="web:block phone:hidden text-left mx-auto lg:max-w-1216 p-8">
        <div className="grid grid-cols-2 w-full gap-1">
          <div
            className="w-full h-[500px] bg-center bg-cover block"
            style={{
              backgroundImage: `url(${newImageList.length !== 0 &&
                newImageList[0].url})`,
            }}
          />
          <div className="grid relative grid-cols-2 gap-1 grid-rows-2">
            {newImageList?.map((item: any, idx: number) => {
              if (idx === 0 || idx > 4) {
                return "";
              } else {
                return (
                  <div
                    key={idx}
                    className="bg-center bg-cover"
                    style={{
                      backgroundImage: `url(${item.url})`,
                    }}
                  />
                );
              }
            })}
            <div
              className="absolute z-10 px-4 py-2 font-bold bg-gainsboro bottom-0 right-8 mb-4 cursor-pointer shadow-ms rounded-lg"
              onClick={() => {
                allPhotoView();
              }}
            >
              {/* View {newImageList?.length} Photos */}
              {t("Product-details.View photos")}
            </div>
          </div>
        </div>
        <div className="flex pt-8">
          <div className="max-w-[800px]">
            <div className="text-sm">
              Home &nbsp; {">"} &nbsp;
              {destinationInfo && destinationInfo.country} &nbsp; {">"} &nbsp;
              {destinationInfo && destinationInfo.state} &nbsp; {">"} &nbsp;
              {title}
            </div>

            <div className="py-8">
              <div className="font-bold text-3.5xl mb-8">{title}</div>
              <div className="flex flex-wrap justify-start">
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src={typeInfo?.iconUrl}
                    className="w-5 h-5"
                    alt={typeInfo?.displayName}
                  />
                  <div className="ml-2">
                    {t(typeInfo && `Private-Type-data.${typeInfo?.enName}`)}
                  </div>
                </div>
                {locationThemes &&
                  locationThemes?.map((item: any, key: any) => {
                    const data = item.themeInfo;
                    return (
                      <div key={key} className="flex items-center mr-8 mb-8">
                        <img
                          src={data?.iconUrl}
                          className="w-5 h-5"
                          alt={data?.displayName}
                        />
                        <div className="ml-2">
                          {t(`Theme-data.${data?.enName}`)}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="flex flex-wrap justify-start">
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/cancellation-policy.svg"
                    className="mr-2 w-5 h-5"
                    alt=""
                  />
                  <p>
                    {t(
                      policyInfo &&
                        `Cancellation-policy-data.${changecase(
                          policyInfo?.name
                        )}`
                    )}{" "}
                    {t(`Product-details.Refund Policy`)}
                  </p>
                </div>
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                    className="mr-2 w-5 h-5"
                    alt=""
                  />
                  <p>
                    {t(
                      confirmMethod &&
                        `Confirmation-data.${changecase(confirmMethod)}`
                    )}
                  </p>
                </div>
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                    className="mr-2 w-5 h-5"
                    alt=""
                  />
                  <p>
                    {t(
                      advanceDate
                        ? "Advance-notice-data.Available Today"
                        : "Advance-notice-data.Unavailable Today"
                    )}
                  </p>
                </div>
              </div>
              <div className="text-base">{description}</div>
            </div>

            <DetailTheme
              theme={
                t("Product-details.Hosted By") +
                " " +
                `${hostInfo && hostInfo.hostName}`
              }
            >
              <DetailInfo imgUrl={hostInfo && hostInfo.profile}>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t("Product-details.Host Verified")}
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t(`Product-details.Joined in {{Joined_date}}`, {
                    Joined_date: create,
                  })}
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t("Product-details.Response Rate")} : {hostInfo?.resRate}%
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px]">
                  {t("Product-details.Language")}:{" "}
                  {hostInfo?.languageCode[0]
                    ? t(`Language.${hostInfo?.languageCode[0]}`)
                    : ""}{" "}
                  {hostInfo?.languageCode[1]
                    ? t(`Language.${hostInfo?.languageCode[1]}`)
                    : ""}{" "}
                  {hostInfo?.languageCode[2]
                    ? t(`Language.${hostInfo?.languageCode[2]}`)
                    : ""}{" "}
                </div>
              </DetailInfo>
            </DetailTheme>

            <DetailTheme kor theme="Product-details.Ground Details">
              <DetailInfo title="Product-details.Facilty">
                {facilities && facilities.length !== 0 ? (
                  facilities.map((item: any, key: number) => {
                    const data = item.facilityList;
                    return (
                      <div
                        key={key}
                        className="flex items-center w-[191px] mr-[9px] mb-4 "
                      >
                        <img
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-5 h-5"
                        />
                        <div className="ml-2">
                          {t(`Facility-data.${data.enName}`)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Activity">
                {activities && activities.length !== 0 ? (
                  activities.map((item: any, key: number) => {
                    const data = item.activity;
                    return (
                      <div
                        key={key}
                        className="flex items-center w-[191px] mr-[9px] mb-4 "
                      >
                        <img
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-5 h-5"
                        />
                        <div className="ml-2">
                          {t(`Activity-data.${data.enName}`)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Allowed Details">
                {restricts && restricts.length !== 0 ? (
                  restricts.map((item: any, key: number) => {
                    const data = item.allowedList;
                    if (data.allowed === true) {
                      return (
                        <div
                          key={key}
                          className="flex items-center w-[191px] mr-[9px] mb-4 "
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-5 h-5"
                          />
                          <div className="ml-2">
                            {t(`Allowed-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Prohibited Details">
                {restricts && restricts.length !== 0 ? (
                  restricts.map((item: any, key: number) => {
                    const data = item.allowedList;
                    if (data.allowed === false) {
                      return (
                        <div
                          key={key}
                          className="flex items-center w-[191px] mr-[9px] mb-4 "
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-5 h-5"
                          />
                          <div className="ml-2">
                            {t(`Prohibited-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Special Facility">
                {specialFacility ? (
                  specialFacility
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo
                title="Product-details.Extra Options"
                className="flex-col"
              >
                {extraOption && !!extraOption[0]?.title ? (
                  extraOption.map(
                    (
                      {
                        title,
                        description,
                        maxOfNumber,
                        price,
                        paymentMethod,
                      }: any,
                      key: number,
                      item: any
                    ) => {
                      return (
                        <div key={key} className="last:mb-0 mb-4">
                          <div className="flex">
                            {paymentMethod === "On-site Payment" ? (
                              <Icons
                                className="mr-2 mb-2 w-5 h-5"
                                iconName="on-site-payment-only"
                              />
                            ) : (
                              <Icons
                                className="mr-2 mb-2 w-5 h-5"
                                iconName="available-in-extra-option"
                              />
                            )}
                            <div className="mr-2">{title}</div>
                            <div>{price}</div>
                          </div>
                          <div>{description}</div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
            </DetailTheme>

            <DetailTheme kor theme="Product-details.Available Ground">
              <div className="w-[full] h-40 mb-8 shadow-card rounded-lg flex  text-left">
                <img
                  className="w-66 bg-[#eee] rounded-l-lg"
                  src={newImageList.length !== 0 ? newImageList[0]?.url : ""}
                  alt=""
                />
                <div className="w-[380px] p-2 ">
                  <div className="text-xl font-bold text-ellipsis whitespace-nowrap overflow-hidden">
                    {title}
                  </div>

                  <div className="flex mt-6 h-5 items-center">
                    <img
                      src={typeInfo?.iconUrl}
                      className="w-5 h-5"
                      alt={typeInfo?.displayName}
                    />
                    <div className="ml-2">
                      {t(typeInfo && `Private-Type-data.${typeInfo.enName}`)}
                    </div>
                  </div>
                  <div className="flex my-4 h-5 items-center">
                    <img
                      className="w-5 h-5 mr-2"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-ground.svg"
                      alt=""
                    />
                    <div className="mr-4">X {unitCount}</div>
                    <img
                      className="w-5 h-5 mr-2"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                      alt=""
                    />
                    <div className="w-[250px] whitespace-nowrap text-ellipsis overflow-hidden">
                      {t(
                        accessibleVehicle &&
                          `Parking-accessible-data.${accessibleVehicle}`
                      )}
                    </div>
                  </div>
                  <div className="flex h-5 items-center">
                    <img
                      className="w-5 h-5 mr-2"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                      alt=""
                    />
                    <div>{spaceSize} m²</div>
                  </div>
                </div>
                <div className="w-36 h-40 border-l border-input-text rounded-r-lg p-2 flex flex-col justify-between">
                  <div className="text-xs">
                    <span className="text-xl font-bold">{minimumPrice()}</span>{" "}
                    {t("Product-details./ night")}
                  </div>
                  <Button
                    onClick={() => {
                      setIsOpen((isOpen) => !isOpen);
                    }}
                    className="w-32 py-2 bg-light-green text-pure-white rounded-lg text-center"
                    btnText={t(
                      isOpen
                        ? "Product-details.Cancel"
                        : "Product-details.Select"
                    )}
                  />
                </div>
              </div>
              {isOpen ? (
                <div className="w-full shadow-card py-4 px-2">
                  <DetailInfo title="Product-details.Ground Type">
                    <img
                      src={typeInfo?.iconUrl}
                      className="w-5 h-5"
                      alt={typeInfo?.displayName}
                    />
                    <div className="ml-2">
                      {t(typeInfo && `Private-Type-data.${typeInfo?.enName}`)}
                    </div>
                  </DetailInfo>
                  <DetailInfo title="Ground Details" noFlex>
                    <div className="flex items-center">
                      <img
                        className="w-5 h-5 mr-2"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-ground.svg"
                        alt=""
                      />
                      <div>
                        {t("Product-details.Number of Unit")} : {unitCount}{" "}
                        {t("Product-details.Ground Unit")}
                      </div>
                    </div>
                    <div className="flex items-center my-[20px]">
                      <img
                        className="w-5 h-5 mr-2"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                        alt=""
                      />
                      <div>
                        {t("Parking-accessible-data.Accessible Vehicle")} :{" "}
                        {t(`Parking-accessible-data.${accessibleVehicle}`)}
                      </div>
                    </div>
                    <div className="flex">
                      <img
                        className="w-5 h-5 mr-2"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                        alt=""
                      />
                      <div>
                        {t("Product-details.Ground Size")} : {spaceSize} m²
                      </div>
                    </div>
                  </DetailInfo>
                  <DetailInfo
                    nomargin
                    className="mb-0"
                    title="Product-details.Price"
                  >
                    From {minimumPrice()} {t("Product-details./ night")}
                  </DetailInfo>
                </div>
              ) : (
                ""
              )}
            </DetailTheme>

            <DetailTheme theme="Product-details.Location">
              <div className="my-8 h-[400px]">
                <GoogleMap
                  mapLocation={{
                    lat: Number(`${indicator && indicator.latitude}`),
                    lng: Number(`${indicator && indicator.longitude}`),
                  }}
                />
              </div>
              <DetailInfo
                title="Product-details.Address"
                className="items-center"
                withIcon
              >
                <Icons iconName="destination" className="mr-2 w-6 h-6" />
                {zipCode}&nbsp;
                {destinationInfo && destinationInfo.country}&nbsp;
                {state}&nbsp;
                {city}&nbsp;
                {address}
              </DetailInfo>
              <DetailInfo withIcon title="Product-details.How To Get There">
                {direction ? direction : <div>{t(`Common.N/A`)}</div>}
              </DetailInfo>
              <DetailInfo
                noFlex
                withIcon
                title="Product-details.Nearby Facilities"
              >
                {nearbyFacilities?.length !== 0 ? (
                  nearbyFacilities &&
                  nearbyFacilities.map((item: any, i: number) => {
                    if (item !== null) {
                      return (
                        <div
                          key={i}
                          className="flex items-center mb-4 last:mb-0"
                        >
                          <div className="w-[224px] flex">
                            <img
                              className="mr-2 w-5 h-5"
                              alt=""
                              src={
                                item.facility === "EV_CHARGING_STATION"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/ev-charge-station.svg"
                                  : item.facility === "CONVENIENCE_STORE"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/convenience-store.svg"
                                  : item.facility === "GAS_STATION"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/gas-station.svg"
                                  : item.facility === "SUPERMARKET"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/supermarket.svg"
                                  : item.facility === "TOILET_24HR"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/24hr-toilet.svg"
                                  : ""
                              }
                            />
                            <div>
                              {t(
                                `Nearby-facility.${changeFacility(
                                  item.facility
                                )}`
                              )}
                            </div>
                          </div>
                          <div>{item.name}</div>
                          <div> ({item.distance}Km)</div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>

              <DetailInfo
                withIcon
                title={`Product-details.Recommending Tourist Spot`}
              >
                {touristSpots &&
                !!touristSpots[0]?.title &&
                !!touristSpots[0]?.description &&
                !!touristSpots[0]?.photoUrl ? (
                  touristSpots.map(
                    ({ title, description, photoUrl }: any, key: number) => {
                      return (
                        <div
                          key={key}
                          className="w-50 h-[161px] shadow-card rounded-lg relative"
                        >
                          <div
                            onClick={() => {
                              touristInfo(title, description, photoUrl);
                            }}
                            className="opacity-0 hover:opacity-50 flex justify-center  w-full h-32 cursor-pointer items-center hover:bg-black rounded-t-lg absolute"
                          >
                            <img
                              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/view-detail.svg"
                              alt=""
                              className="w-8 h-8"
                            />
                          </div>
                          <img
                            className="h-32 w-full rounded-t-lg"
                            src={photoUrl}
                            alt=""
                          />
                          <div className="whitespace-nowrap text-ellipsis w-full overflow-hidden h-[33px] leading-[33px] rounded-b-lg px-2 text-sm my-auto">
                            {title}
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
            </DetailTheme>
            <DetailTheme kor theme="Product-details.Policy">
              <DetailInfo withIcon title="Product-details.Check In / Out">
                <div className="mr-44">
                  {t("Product-details.Check in")} :{" "}
                  {bookingTime && bookingTime.checkIn}
                </div>
                <div>
                  {t("Product-details.Check out")} :{" "}
                  {bookingTime && bookingTime.checkOut}
                </div>
              </DetailInfo>
              <DetailInfo withIcon title="Product-details.Confirmation">
                <div>
                  {t(
                    "Confirmation-data.You will receive booking confirmation-hours",
                    { confirm: "24" }
                  )}
                </div>
                <div>
                  {t(
                    "Confirmation-data.In the event that you do not receive an email from us"
                  )}
                </div>
              </DetailInfo>
              <DetailInfo withIcon title="Product-details.Advance Notice">
                {advanceDate
                  ? t("Advance-notice-data.Booking is not available today")
                  : t("Advance-notice-data.Booking is available today")}
              </DetailInfo>
              <DetailInfo
                withIcon
                title={"Product-details.Additional Rule / Policy"}
              >
                {extraPolicy ? extraPolicy : <div>{t(`Common.N/A`)}</div>}
              </DetailInfo>
              <DetailInfo
                noFlex
                withIcon
                title={"Product-details.Cancellation & Refund Policy"}
              >
                <div className="font-bold mb-4 flex">
                  <div className="text-pure-red">
                    {t(
                      policyInfo &&
                        `Cancellation-policy-data.${changecase(
                          policyInfo.name
                        )}`
                    )}{" "}
                  </div>
                  <div>{t("Product-details.Policy on Cancellation")}</div>
                </div>
                <div>
                  {policyInfo?.firstRange !== null
                    ? t(
                        "Cancellation-policy-data.{{days}}100% refund of the payment",
                        { days: policyInfo?.firstRange }
                      ) + "\n"
                    : ""}
                  {policyInfo?.secondRange !== null
                    ? t(
                        "Cancellation-policy-data.{{days}}70% refund of the payment",
                        { days: policyInfo?.secondRange }
                      ) + "\n"
                    : ""}
                  {policyInfo?.thirdRange !== null
                    ? t(
                        "Cancellation-policy-data.{{days}}50% refund of the payment",
                        { days: policyInfo?.thirdRange }
                      ) + "\n"
                    : ""}
                  {policyInfo?.fourthRange !== null
                    ? t(
                        "Cancellation-policy-data.{{days}}30% refund of the payment",
                        { days: policyInfo?.fourthRange }
                      ) + "\n"
                    : ""}
                  {policyInfo?.fifthRange !== null
                    ? t(
                        "Cancellation-policy-data.{{days}}0% refund of the payment",
                        { days: policyInfo?.fifthRange }
                      )
                    : ""}
                </div>
              </DetailInfo>
            </DetailTheme>

            {/* <hr />
          <div className="py-8">
            <div className="flex">
              <div className="font-bold text-2xl mb-8 w-[200px]">Reviews</div>
              <div className="h-9 items-center flex">
                <Icon src="star" />
                <div className="text-xl font-bold ml-1 mr-2">4.3</div>
                <div className="text-input-text">20 Reviews </div>
              </div>
              <div className="h-9 items-center flex ml-auto">
                <Button
                  isIcon
                  className="text-sm px-4 py-2 rounded-lg "
                  btnText={"See All Reviews"}
                />
              </div>
            </div>

            <div className="flex">
              <div className="w-[200px]">Review Details</div>
              <div className="w-[600px]">
                <div className="flex">
                  <div className="flex justify-start flex-wrap ">
                    <div className="w-[300px] flex mb-4">
                      <div className="w-28 mr-[67px]">Accuracy</div>
                      <div className="flex items-center font-bold">
                        <Icon src="star" className="mr-1 w-[14px] h-[14px]" />
                        <div>4.3</div>
                      </div>
                    </div>
                    <div className="w-[300px] flex mb-4">
                      <div className="w-28 mr-[67px]">Cleanliness</div>
                      <div className="flex items-center font-bold">
                        <Icon src="star" className="mr-1 w-[14px] h-[14px]" />
                        <div>4.5</div>
                      </div>
                    </div>
                    <div className="w-[300px] flex">
                      <div className="w-28 mr-[67px]">Cleanliness</div>
                      <div className="flex items-center font-bold">
                        <Icon src="star" className="mr-1 w-[14px] h-[14px]" />
                        <div>4.5</div>
                      </div>
                    </div>
                    <div className="w-[300px] flex">
                      <div className="w-28 mr-[67px]">Cleanliness</div>
                      <div className="flex items-center font-bold">
                        <Icon src="star" className="mr-1 w-[14px] h-[14px]" />
                        <div>4.5</div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-4 text-[#9b9b9b]" />
            <div className="flex">
              <div className="w-12 h-12 bg-main-green"></div>
              <div className="flex flex-col justify-between ml-1 mr-4">
                <div>Stevenson</div>
                <div>
                  <div className="flex text-sm items-center w-32 justify-between">
                    <div className="flex items-center">
                      <Icon src="star" className="mr-1 w-3 h-3" />
                      <div>4.5</div>
                    </div>
                    <div className="text-xs">Nov 12, 2020</div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="w-[600px] leading-4 text-tiny">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat… Read More
              </div>
            </div>
            <hr className="my-4 text-[#9b9b9b]" />
          </div>

          <hr />

          <div className="py-8">
            <div className="font-bold text-2xl mb-8">
              Nearby Recommendations
            </div>
          </div> */}
          </div>

          <div className="max-w-[320px] h-full w-80 ml-8 bg-[#eee]"></div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
