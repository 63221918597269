import React, { useEffect, useState } from "react";

import KakaoMap from "components/Map/KakaoMap";

import AcWrap from "elements/AcWrap";
import HostInput from "elements/HostInput";

import { useTranslation } from "react-i18next";
import Button from "elements/Button";

interface geneProps {
  title?: string;
  description?: string;
  address?: string;
  country?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  extraAddress?: string;
  latitude?: number;
  longitude?: number;
  directions?: string;
  destinationInfo?: any;
  touristSpots?: any;
}

const EditAcGeneralInfo = ({ acGeneralInfoData }: geneProps | any) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("KR");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [extraAddress, setExtraAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [directions, setDirecitions] = useState("");
  const [destinationId, setDestinationId] = useState(0);
  const [touristSpots, setTouristSpots] = useState<any>([]);

  useEffect(() => {
    const data = acGeneralInfoData;
    setTitle(data?.title);
    setDescription(data?.description);
    setAddress(data?.address);
    setState(data?.state);
    setCity(data?.city);
    setZipCode(data?.zipCode);
    setExtraAddress(data?.extraAddress);
    setLatitude(data?.latitude);
    setLongitude(data?.longitude);
    setDirecitions(data?.directions);
    setDestinationId(data?.destinationInfo?.id);
    setTouristSpots(data?.touristSpots);
  }, [acGeneralInfoData.title, acGeneralInfoData.description, acGeneralInfoData.address, acGeneralInfoData.state, acGeneralInfoData.city, acGeneralInfoData.zipCode, acGeneralInfoData.extraAddress, acGeneralInfoData.latitude, acGeneralInfoData.longitude, acGeneralInfoData.directions, acGeneralInfoData.destinationInfo, acGeneralInfoData.touristSpots, acGeneralInfoData]);

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          inputValue={title}
          title="Add-accommodation.Title*"
          placeholder="Add-accommodation.Name of Accommodation"
          input
          help
          toolTitle="Tooltip-add-accommodation.Gen info title-tooltip title"
          toolSubTitle={"Tooltip-add-accommodation.Gen info title-tooltip desc"}
          inputMaxLength={50}
        />
        <AcWrap
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          inputValue={description}
          title="Add-accommodation.Description*"
          placeholder="Add-accommodation.Describe the accommodation for traveler"
          textArea
          help
          toolTitle="숙박 장소에 대해 자랑해주세요."
          toolSubTitle={
            "넓은 잔디 마당,  멋진 풍경,  우리 로컬의 분위기 등 게스트가 호스트님의 숙박 장소에 꼭 방문해보고 싶은 충동을 느낄 수 있도록 매력적으로 표현해주세요."
          }
          toolEx="(비속어/욕설 입력금지, 개인정보 노출 주의)"
          inputMaxLength={500}
        />
        <AcWrap
          title="Add-accommodation.Accommodation Address*"
          help
          toolTitle="공유하실 숙박 장소의 위치를 알려주세요."
          toolSubTitle="주소 검색 시 꼭 ‘도로명 주소로 입력해주세요~!!  주소 노출은 걱정하지 마세요. 예약이 확정된 게스트에게만 정확한 전체 주소가 제공됩니다."
          toolEx="Ex)강남대로  327"
        >
          <HostInput
            input
            size="XL"
            inputValue={"KR"}
            formTitle={t("Add-accommodation.Country / Region")}
            disabled
          />
          <HostInput
            input
            size="XL"
            disabled
            inputValue={`${zipCode} ${state} ${city} ${address}`}
            formTitle={t("Add-accommodation.Address")}
          />
          <AcWrap
            tooltipHidden
            input
            disabled
            inputValue={extraAddress}
            placeholder="상세주소를 입력해주세요"
            miniTitle="Add-accommodation.Rest Address (optional)"
            inputMaxLength={50}
          />
        </AcWrap>
        <AcWrap
          title="Add-accommodation.Map View"
          toolTitle="잠깐! 한번 더 확인해주세요."
          toolSubTitle="입력한 주소가 정확한지 한번 더 체크해주세요. 지금 설정된 주소는 이후 호스트가 직접 수정 할 수 없으며, 주소 변경을 원하는 경우 '머스트 아웃도어' 관리자에게 요청이 필요합니다."
        >
          <KakaoMap
            AddInfo={{ lat: Number(latitude), lng: Number(longitude) }}
          />
        </AcWrap>
        <AcWrap
          textArea
          onChange={(e) => {
            setDirecitions(e.target.value);
          }}
          inputValue={directions}
          placeholder="Add-accommodation.Please describe how to get there"
          title="Add-accommodation.How To Get There"
          help
          toolTitle="호스트님의 매력적인 숙박 장소, 어디에 숨어 있나요?"
          toolSubTitle="유명 관광지가 아닌 경우 대부분의 게스트가 초행길일 가능성이 큽니다. 게스트가
          방문 시 길을 헤매지 않도록 오는 길의 주요 포인트 지점이나 주변 특징 에 대해
          설명해주세요."
          row={5}
          inputMaxLength={500}
        />
        <AcWrap
          title="Add-accommodation.Recommending Tourist Spot"
          help
          // addItem
          // addOnClick={() => {
          //   setTourist((pre: any) => [pre, <TouristSpot />]);
          // }}
          toolTitle="더 많은 여행자들을 유입시킬 수 있어요."
          toolSubTitle="유명 관광지,  뷰 포인트,  지역 맛집 등 게스트가 방문 시 함께 즐길 수 있는
          지역명소들이 있다면 추천해주세요.  이러한 정보들은 모객 및 방문만족도에
          긍정적인 영향과 재방률을 높일 수 있습니다."
        >
          <div className="mb-8">
            <hr className="text-input-text" />
            <div className="flex web:flex-row phone:flex-col items-center mt-8">
              <input
                type="file"
                className="hidden"
                // onChange={(e) => {
                //   handleImage(e);
                //   e.target.value = "";
                // }}
                accept=".jpg, .jpeg, .png, .pdf"
                // ref={imaegRef}
              />
              <div
                // onClick={() => handleClick()}
                className="web:mr-8 phone:mr-0 phone:w-full web:w-48"
              >
                <div className="text-sm">
                  {t("Add-accommodation.Featured Image")}
                </div>
                {touristSpots && touristSpots[0]?.photoUrl ? (
                  <img
                    className="web:w-48 phone:w-full h-[141px] rounded-lg"
                    src={touristSpots[0]?.photoUrl}
                    alt=""
                  />
                ) : (
                  <div className="w-full cursor-pointer h-[141px] border justify-center border-input-border-color mt-2 rounded-lg flex flex-col items-center">
                    <img
                      className="w-[60px] h-[60px]"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
                      alt=""
                    />
                    <div className="mt-2 text-sm">Feature Image Upload</div>
                  </div>
                )}
              </div>
              <div className="grow web:max-w-[416px] w-full text-sm web:mt-0 phone:mt-4">
                <div>
                  <div>{t("Add-accommodation.Name of Tourist Spot")}</div>
                  <input
                    defaultValue={
                      touristSpots && touristSpots[0]?.title !== ""
                        ? touristSpots[0]?.title
                        : ""
                    }
                    onChange={(e) => {
                      setTouristSpots({
                        ...touristSpots,
                        title: e.target.value,
                      });
                    }}
                    name="title"
                    className="touristInput h-[33px]"
                  />
                </div>
                <div className="mt-4">
                  <div>
                    {t("Add-accommodation.Description of Tourist Spot")}
                  </div>
                  <textarea
                    onChange={(e) => {
                      setTouristSpots({
                        ...touristSpots,
                        description: e.target.value,
                      });
                    }}
                    defaultValue={
                      touristSpots && touristSpots[0]?.description !== ""
                        ? touristSpots[0]?.description
                        : ""
                    }
                    name="description"
                    rows={2}
                    className="touristInput h-[67px]"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* {tourist?.map((item: any, idx: number) => {
            return ( */}
          {/* <TouristSpot
            preData={touristSpot}
            createTouristSpot={setTouristSpot}
          /> */}
          {/*
          })} */}
        </AcWrap>
        <div className="text-right">
          <Button
            // onClick={() => handlePageData()}
            btnText={"Add-accommodation.Save"}
            className="inline-block bg-tan rounded-lg py-3 px-4 text-pure-white"
          />
        </div>
      </div>
    </div>
  );
};

export default EditAcGeneralInfo;
