import React from "react";

interface IconsProps {
  onClick?: () => void;
  onMousedown?: (e: any) => void;
  onMouseup?: (e: any) => void;

  common?: boolean;
  logo?: boolean;

  iconName?: string;
  className?: string;
  extension?: string;
}
const Icons = ({
  onClick,
  iconName,
  className,
  extension,
  common,
  logo,
  onMousedown,
  onMouseup,
}: IconsProps) => {
  const IconUrl = `https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/${
    common ? "common" : logo ? "Logo" : "common"
  }/${iconName}.${extension ? extension : "svg"}`;
  return (
    <img
      onClick={onClick}
      onMouseUp={onMouseup}
      onMouseDown={onMousedown}
      className={className ? className : "w-5 h-5"}
      src={IconUrl}
      alt={iconName}
    />
  );
};

export default Icons;
