import React, { useEffect, useState } from "react";


import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ACMENU, PGMENU, TRAVELERMENU } from "components/constants";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import Icons from "elements/Icons";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

interface hostAccountProps {
  onClickCalendar?: any;
  traveler?: boolean;
  isApproved?: boolean;
}

const HostAccount = ({
  traveler,
  onClickCalendar,
  isApproved,
}: hostAccountProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [calendarStatus, setCalendarStatus] = useState("");

  const name = localStorage.getItem("name");
  const email = localStorage.getItem("email");
  const created = localStorage.getItem("joined");
  const hostId = localStorage.getItem("hostNum");

  const currentType = localStorage.getItem("currentType");

  useEffect(() => {
    if (currentType === "PG") {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v2/private-ground/status/${hostId}`,
      }).then((res) => {
        const data = res.data.data;
        setCalendarStatus(data.status);
        return data;
      });
    } else if (currentType === "AC") {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v2/accommodation/status/${hostId}`,
      }).then((res) => {
        const data = res.data.data;
        setCalendarStatus(data.status);
        return data;
      });
    }
  }, [currentType, hostId]);

  const createdToNum = Number(created);

  const createdDate = new Date(createdToNum);

  const year = createdDate.getFullYear();
  const month = createdDate.getMonth() + 1;

  const isPreview: any = useSelector<RootState>((state) => state.private);
  const { id, status } = isPreview.previewData;

  const profileImg: any = localStorage.getItem("profile");

  const spaceListData: any = useSelector<RootState>(
    (state) => state?.spaces
  );

  useEffect(() => {
    if (onClickCalendar) {
      onClickCalendar();
    }
  }, [onClickCalendar]);

  return (
    <div className="web:block phone:hidden max-w-[256px] mr-8">
      <div className="w-[256px] h-[250px] rounded-lg text-sm text-pure-white bg-light-green text-center pt-8">
        <label className="inline-block">
          {profileImg ? (
            <img
              src={profileImg}
              className="w-24 h-24 mx-auto rounded-full"
              alt=""
            />
          ) : (
            <Icons
              className="fill-pure-white w-24 h-24 mx-auto"
              iconName="my-account-avatar"
            />
          )}
        </label>
        <p className="text-base font-bold my-4">{name}</p>
        <p className="mb-2 text-sm">{email}</p>
        <p className="">{`Joined ${month}월 ${year}`}</p>
      </div>
      {traveler ? (
        <div className="max-w-[256px] mt-4 text-center">
          <div className="border border-input-border-color rounded-lg flex flex-col justify-between bg-pure-white text-base text-left">
            {TRAVELERMENU.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={`py-4 px-4 text-lg items-center`}
                >
                  <div
                    onClick={() => {
                      navigate(`${item.navigate}`);
                    }}
                    className="cursor-pointer hover:text-light-green inline-block"
                  >
                    {t(`Menu.${item.title}`)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : traveler === undefined && currentType === "PG" ? (
        <div className="max-w-[256px] mt-4 text-center">
          <div className="border border-input-border-color rounded-lg flex flex-col justify-between bg-pure-white text-base text-left">
            {PGMENU?.map((item, idx) => {
              const title = item.title;
              const url = item.url;
              const subContent = item.subContent;
              return (
                <div key={idx}>
                  <div
                    className={`${
                      subContent
                        ? "pb-2 cursor-default inline-block"
                        : ""
                    } p-4 text-lg`}
                  >
                    <div
                    className={`${
                      subContent
                        ? ""
                        : "hover:text-light-green cursor-pointer inline-block"
                    }`}
                      onClick={() => {
                        navigate(`${url}`, { state: "PG" });
                      }}
                    >
                      {t(`Menu.${title}`)}
                    </div>
                  </div>
                  {subContent
                    ? subContent?.map((subItem, subIdx) => {
                        const subTitle = subItem.title;
                        const subUrl = subItem.url;
                        return (
                          <div
                            onClick={() => {
                              if (
                                subTitle === "Calendar Manager" &&
                                calendarStatus === "APPROVED"
                              ) {
                                navigate(`${subUrl}`, {
                                  state: { type: "PG" },
                                });
                              } else if (
                                subTitle === "Calendar Manager" &&
                                calendarStatus === "PENDING"
                              ) {
                                alert("승인 대기중입니다.");
                              } else {
                                navigate(`${subUrl}`, { state: "PG" });
                              }
                            }}
                            className="text-input-text inline-block cursor-pointer pl-6 p-4 py-2 hover:text-light-green"
                            key={subIdx}
                          >
                            {t(`Menu.${subTitle}`)}
                          </div>
                        );
                      })
                    : ""}
                </div>
              );
            })}
          </div>
        </div>
      ) : traveler === undefined && currentType === "AC" ? (
        <div className="max-w-[256px] mt-4 text-center">
          <div className="border border-input-border-color rounded-lg flex flex-col justify-between bg-pure-white text-base text-left">
            {ACMENU?.map((item, idx) => {
              const title = item.title;
              const url = item.url;
              const subContent = item.subContent;
              return (
                <div key={idx}>
                  <div
                    className={`${
                      subContent
                        ? "pb-2 cursor-default"
                        : "hover:text-light-green cursor-pointer"
                    } p-4 text-lg`}
                  >
                    <div
                      onClick={() => {
                        navigate(`${url}`, { state: "AC" });
                      }}
                    >
                      {t(`Menu.${title}`)}
                    </div>
                  </div>
                  {subContent
                    ? subContent?.map((subItem, subIdx) => {
                        const subTitle = subItem.title;
                        const subUrl = subItem.url;
                        return (
                          <div
                            onClick={() => {
                              if (
                                subTitle === "Calendar Manager" &&
                                calendarStatus === "APPROVED"
                              ) {
                                navigate(`${subUrl}`, {
                                  state: { type: "AC", spaceListData },
                                });
                              } else if (
                                subTitle === "Calendar Manager" &&
                                calendarStatus === "PENDING"
                              ) {
                                alert("승인 대기중입니다.");
                              } else {
                                navigate(`${subUrl}`, { state: "AC" });
                              }
                            }}
                            className="text-input-text cursor-pointer pl-6 p-4 py-2 hover:text-light-green"
                            key={subIdx}
                          >
                            {t(`Menu.${subTitle}`)}
                          </div>
                        );
                      })
                    : ""}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default HostAccount;
