import Modal from "elements/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

declare global {
  interface Window {
    IMP: any;
  }
}

interface importPayProps {
  className?: string;
  preChcekData?: any;
  requestPageData?: any;
  pg?: string;
  pay_method?: string;
  escrow?: boolean;
  merchant_uid?: string;
  name?: string;
  amount?: number;
  custom_data?: any;
  tax_free?: number;
  currency?: string;
  language?: string;
  buyer_name?: string;
  buyer_tel?: string;
  buyer_email?: string;
  buyer_addr?: string;
  buyer_postcode?: string;
  notice_url?: string | string[];
}

const ImportPay = ({
  amount,
  name,
  buyer_email,
  buyer_name,
  buyer_tel,
  preChcekData,
  requestPageData,
  className,
}: importPayProps) => {
  const { t } = useTranslation();
  const naviagate = useNavigate();
  const totalMemberId = localStorage.getItem("m");

  const booking = () => {
    const { IMP } = window;
    IMP.init("imp53385122");
    const totalMemberId = localStorage.getItem("m");
    const {
      checkIn,
      checkOut,
      currencyInfo,
      hasCar,
      hostInfo,
      price,
      totalPrice,
      unitAmount,
      plateNumber,
      currentImage,
      pgId,
      title,
    } = preChcekData;
    const { extraOption } = requestPageData;

    const extraValue = extraOption && [
      {
        amount: extraOption[0]?.amount,
        id: extraOption[0]?.id,
        price: extraOption[0]?.price,
      },
    ];

    const extraPrice =
      extraOption && Number(extraOption[0]?.price) * extraOption[0]?.amount;

    const merchant_uid = "" + new Date().getTime() + pgId;
    IMP.request_pay(
      {
        pg: "nice", // PG사
        pay_method: "card", // 결제수단
        // 에약건 + Time + TMI
        m_redirect_url: `${process.env.REACT_APP_COMPLETE_BOOK}`,
        merchant_uid: merchant_uid, // 주문번호
        amount: amount, // 결제금액
        name: name, // 주문명
        buyer_email: buyer_email,
        buyer_name: buyer_name,
        buyer_tel: buyer_tel,
        buyer_addr: "",
        buyer_postcode: "",
      },
      async function(rsp: any) {
        // callback
        if (rsp.success) {
          try {
            const data = await requestToMustOutdoor({
              method: "POST",
              url: `/api/v1/payment/get/token`,
            })
              .then((res) => {
                requestToMustOutdoor({
                  method: "POST",
                  url: "/api/v1/booking/new",
                  data: {
                    applyNum: rsp.apply_num,
                    buyerInfo: {
                      address: rsp.buyer_addr,
                      email: rsp.buyer_email,
                      mobile: rsp.buyer_tel,
                      name: rsp.buyer_name,
                      postcode: rsp.buyer_postcode,
                    },
                    cancelReason: rsp?.error_msg ? rsp?.error_msg : "",
                    cancelledAt: 0,
                    cardName: rsp.card_name,
                    cardNum: rsp.card_number,
                    cardQuota: rsp.card_quota,
                    checkIn: checkIn,
                    checkOut: checkOut,
                    currency: rsp.currency,
                    currencyId: currencyInfo?.id,
                    currentImage: currentImage,
                    escrow: false,
                    extraOption:
                      extraOption[0]?.id !== undefined &&
                      extraOption[0]?.amount !== undefined &&
                      extraOption[0]?.price !== undefined
                        ? extraValue
                        : [],
                    failReason: rsp?.error_msg ? rsp?.error_msg : "",
                    hasCar: hasCar,
                    hostId: hostInfo?.id,
                    iamportUid: rsp?.imp_uid,
                    // merchantUid: "예약번호+epoch time+totalMemberId값",
                    merchantUid: rsp?.merchant_uid,
                    method: rsp.pay_method,
                    name: rsp.name,
                    note: "머스트아웃도어 개별 메모",
                    optionPrice:
                      extraOption[0]?.id !== undefined &&
                      extraOption[0]?.amount !== undefined &&
                      extraOption[0]?.price !== undefined
                        ? extraPrice
                        : "0",
                    paidAt: rsp.paid_at,
                    pgId: pgId,
                    pgProvider: rsp?.pg_provider,
                    pgTid: rsp?.pg_tid,
                    pgTitle: title,
                    plateNumber: plateNumber,
                    price: price,
                    receiptUrl: rsp?.receipt_url,
                    refundPrice: 0,
                    status: rsp?.status,
                    succeed: rsp?.success,
                    token: res.data.data,
                    totalMemberId: Number(totalMemberId),
                    totalPrice: Number(totalPrice),
                    totalServerPrice: totalPrice,
                    unitAmount: unitAmount,
                  },
                })
                  .then((res) => {
                    naviagate("/member/booking", { replace: true });
                    return res.data.data;
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                return res.data.data;
              })
              .catch((err) => {
                console.log(err, "아임포트 결제 요청 토큰 발급 오류");
              });
          } catch {}
        } else {
          console.log(rsp, "에러");
        }
      }
    );
  };
  return (
    <div>
      {/* <Modal headerWrap headerTitle='asd'>asd</Modal> */}
      <div
        className={`${className}`}
        onClick={() => {
          booking();
        }}
      >
        {t("Booking-request.CHECKOUT")}
      </div>
    </div>
  );
};

export default ImportPay;
