import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Card from "elements/Card";
import MainCardLayout from "pages/Main/mainCardLayout";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { inputPriceComma } from "utils/commaDash";

import MainCustomSlider from "components/mainCustomSlider";
import { useQueries } from "react-query";
import { isMember } from "lib/isMember";

const MustPick = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const memberId = localStorage.getItem("m");

  const getCart = async (memberId: string | null) => {
    const res = await requestToMustOutdoor.get(
      `/api/v1/cart/private-ground/${memberId}`
    );
    return res.data.data;
  };
  const getMustPick = async () => {
    const res = await requestToMustOutdoor.get("/guest/main/must-pick");
    return res.data.data;
  };

  const result = useQueries([
    { queryKey: ["getMustPick"], queryFn: getMustPick, staleTime: Infinity },
    {
      queryKey: ["getCart"],
      queryFn: () => getCart(memberId),
      staleTime: Infinity,
      enabled: isMember(),
    },
  ]);

  const { data: wishListData } = result[1];
  const { data: mustPickData } = result[0];

  const wishListIds = wishListData?.map((item: any) => {
    const id = item.id;
    return id;
  });

  return (
    <MainCardLayout>
      <div className="web:text-[32px] phone:text-xl w-full font-bold mb-4">
        {t("Main.must pick title")}
      </div>
      <div className="pb-10 phone:text-base web:text-2xl">
        {t("Main.must pick desc")}
      </div>
      <div className="text-center">
        <MainCustomSlider>
          {mustPickData?.map((item: any, i: number) => {
            const typeInfo = item.typeInfo;
            const id = item.id;
            const price = item.priceInfo;
            let arr = Object?.values(price);
            const toNum = arr.map((i) => Number(i));
            const noZero = toNum.filter(
              (e: number | any) => e !== 0 && e !== ""
            );
            const priceValue = Math.min.apply(null, noZero);
            const iconInfo = item?.locationThemes;
            return (
              <div key={i} className="text-center">
                <Card
                  memberId={Number(memberId)}
                  listingId={Number(id)}
                  wish={wishListIds?.includes(id) ? true : false}
                  onClick={() => {
                    navigate(`details/${item.id}`, { state: item.id });
                  }}
                  country={item.country}
                  price={inputPriceComma(String(priceValue))}
                  image={item.image.url}
                  locationThemes={iconInfo}
                  state={t(`Kor-state.${item.state}`)}
                  title={item.title}
                  type={typeInfo}
                />
              </div>
            );
          })}
        </MainCustomSlider>
      </div>
    </MainCardLayout>
  );
};

export default MustPick;
