import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
    generalInfo: {
        title: string;
        description: string;
        typeId: number;
        unitCount: number;
        totalGuestAmount: number;
        bedAmount: number;
        minStay: number;
        spaceSize: string;
        availableExtraGuest: number;
        infantExtraCharge: string;
        childrenExtraCharge: string;
        adultExtraCharge: string;
        parkingType: string;
        accessibleVehicle: string;
        carAmount: number;
        extraCarAmount: number;
        extraCarCharge: string;
    },
    spaceGallery: {
        currentImage: any,
        imageList: [],
    },
    spaceAmenities: {
        amenityIds: any,
        specialAmenity: string
    },
    pricing: {
        monday: string,
        tuesday: string,
        wednesday: string,
        thursday: string,
        friday: string,
        saturday: string,
        sunday: string,
    }

}

const initialState:InitialState = {
    generalInfo: {
        title: "",
        description: "",
        typeId: 0,
        unitCount: 0,
        totalGuestAmount: 0,
        bedAmount: 0,
        minStay: 0,
        spaceSize: "",
        availableExtraGuest: 0,
        infantExtraCharge: "",
        childrenExtraCharge: "",
        adultExtraCharge: "",
        parkingType: "",
        accessibleVehicle: "",
        carAmount: 0,
        extraCarAmount: 0,
        extraCarCharge: "",
    },
    spaceGallery: {
        currentImage: {},
        imageList: []
    },
    spaceAmenities: {
        amenityIds: [],
        specialAmenity: ""
    },
    pricing: {
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
        sunday: "",
    }
}

const spaceSlice = createSlice({
    name: "SpaceData",
    initialState,
    reducers: {
        spGeneralInfo: (state, action:PayloadAction<any>) => {
            state.generalInfo = action.payload
        },
        spGallery: (state, action:PayloadAction<any>) => {
            state.spaceGallery = action.payload
        },
        spAmenities: (state, action:PayloadAction<any>) => {
            state.spaceAmenities = action.payload
        },
        spPricing: (state, action:PayloadAction<any>) => {
            state.pricing = action.payload
        }
    }
})

export default spaceSlice;
export const {spGeneralInfo, spGallery, spAmenities, spPricing} = spaceSlice.actions;