import React from "react";

import { reasons } from "./constants";

import { useTranslation } from "react-i18next";

import Icons from "elements/Icons";

const ReasonThree = () => {
  const reasons = [
    {
      iconSrc: `about-1`,
      title: "Outdoor Life Search",
      summary: "From types to destination",
    },
    {
      iconSrc: `about-2`,
      title: "Simple Step Booking",
      summary: "Search, Pick, and Reserve",
    },
    {
      iconSrc: `about-3`,
      title: "Get a Unique Experience",
      summary: "No more mundane journey",
    },
  ];

  const { t } = useTranslation();
  return (
    <div className="bg-black bg-opacity-50 relative">
      <div
        className="bg-5070  bg-cover bg-no-repeat absolute z-m-10 top-0 bottom-0 right-0 left-0"
        style={{ backgroundImage: `url("https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/Main-our-service.jpg")` }}
      />
      <div className="mx-auto lg:max-w-1216 flex justify-around phone:py-8 phone:px-4 web:px-8">
        <div className="phone:flex phone:flex-col web:hidden justify-between text-pure-white">
          <div className="flex items-center">
            <Icons className="w-[54px] h-[54px] mr-5" iconName="about-1" />
            <div className="flex-1">
              <div className="font-bold">{t("Main.Outdoor Life Search")}</div>
              <div className="break-words text-sm">
                {t("Main.From types to destination").replace(/\n/g, "")}
              </div>
            </div>
          </div>
          <div className="flex items-center mt-12">
            <Icons className="w-[54px] h-[54px] mr-5" iconName="about-2" />
            <div className="flex-1">
              <div className="font-bold">{t("Main.Simple Step Booking")}</div>
              <div className="break-words text-sm">
                {t("Main.Search, Pick, and Reserve").replace(/\n/g, "")}
              </div>
            </div>
          </div>
          <div className="flex items-center mt-12">
            <Icons className="w-[54px] h-[54px] mr-5" iconName="about-3" />
            <div className="flex-1">
              <div className="font-bold">
                {t("Main.Get a Unique Experience")}
              </div>
              <div className="break-words text-sm">
                {t("Main.No more mundane journey").replace(/\n/g, "")}
              </div>
            </div>
          </div>
        </div>
        {reasons.map(({ iconSrc, title, summary }) => (
          <React.Fragment key={title}>
            <div className="flex flex-col items-center phone:hidden web:block">
              <Icons iconName={iconSrc} className="w-full h-24 mb-5" />
              <div className="text-2xl font-bold text-pure-white text-center">
                {t(`Main.${title}`)}
              </div>
              <div className="text-pure-white text-sm text-center mt-4">
                {t(`Main.${summary}`)}
              </div>
            </div>
            <div className="w-full flex flex-wrap content-center mb-4 last:mb-0 phone:hidden">
              <div className="flex">
                <Icons iconName={iconSrc} className="w-12 h-12" />
              </div>
              <div className="text-box pl-3">
                <div className="text-2xl font-bold text-pure-white text-center">
                  {t(`Main.${title}`)}
                </div>
                <div className="text-pure-white text-sm text-center mt-4">
                  {t(`Main.${summary}`)}
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ReasonThree;
