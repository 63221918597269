import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="web:my-20 web:px-48 phone:my-10 phone:px-2">
      {/* 이용약관 */}
      <div className="border-2 border-light-grey web:p-10 phone:p-2">
        <span className="text-3xl font-bold">MUST OUTDOOR 이용약관</span>
        <div className="h-1 py-5 border-t border-black-grey bg-pure-white w-full mt-0.5" />
        <div className="max-h-[1000px] box-border border-1 border-light-grey overflow-y-auto overflow-x-hidden">
          <div>
            <h3>제1조 목적</h3>
            <p>
              이 약관은 주식회사 머스트 아웃도어(이하 “회사”라고 합니다)가 “MUST
              OUTDOOR” 인터넷 서비스 사이트(https://www.mustoutdoor.com이하
              “사이트”라고 합니다)을 통하여 제공하는 서비스 및 기타 정보
              서비스(이하 “서비스”라고 합니다)와 관련하여 “회사”와 “이용자”간의
              권리 · 의무 및 책임사항을 규정함을 목적으로 합니다.
            </p>
          </div>
          <div>
            <h3>제 2조 용어의 정리</h3>
            <ol>
              <li>
                “회사”란 ㈜머스트 아웃도어가 재화 또는 용역을 이용자에게
                제공하기 위하여 컴퓨터, 휴대폰 등 정보통신 설비를 이용하여
                재화를 거래할 수 있도록 설정한 법인 또는 가상의 영업장을 말하며,
                아울러 인터넷 사이트를 운영하는 사업자의 의미로도 사용합니다.
              </li>
              <li>
                “사이트”란 함은 회사가 제작 및 운영하는 인터넷 서비스 사이트
                www.mustoutdoor.com을 말합니다.
              </li>
              <li>
                “이용자”라 함은 사이트에 접속하여 이 약관을 승인하고 “회사”와
                서비스 “이용계약”을 체결한 서비스 “이용자”을 말합니다.
                “이용자”는 다음 각 호와 같이 구분하며 서비스 이용목적에 따라
                "게스트", “호스트"를 통칭하여 "이용자”로 칭합니다. 게스트 –
                “게스트”라 함은 회사가 운영하는 사이트에 개인정보를 제공하여
                “이용자”로 등록한 자로서, 지속적으로 “회사”의 서비스를
                제공받으며 이용할 수 있는 자를 말합니다. 호스트 – “호스트”라
                함은 “회사”가 운영하는 사이트에 개인정보, 회사정보 등을 제공하여
                “이용자”로 등록한 자로서 “회사”가 제공하는 서비스의
                마켓플레이스에 상품을 등록하고 게스트에게 제공할 수 있는 자를
                말합니다.
              </li>
              <li>
                “상품”이라 함은 “호스트”가 “회사” 사이트를 통하여 "게스트”에게
                판매하는 상품을 말합니다.
              </li>
              <li>
                "아이디(ID)”라 함은 이용자의 식별과 서비스 이용을 위하여
                이용자가 설정하고 회사가 승인하여 등록된 이메일 주소를 말합니다.
              </li>
              <li>
                "비밀번호(password)”라 함은 이용자의 동일성 확인과 이용자의 권익
                및 비밀보호를 위하여 이용자 스스로가 설정하여 사이트에 등록한
                문자와 숫자의 조합을 말합니다.
              </li>
              <li>
                “예약”이라 함은 “회사”의 사이트를 통하여 상품을 구매하는 행위를
                말합니다.
              </li>
              <li>
                “프로필”이라 함은 “이용자”가 본 서비스를 이용하여 만든 특정 개별
                프로필을 말합니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제 3조 약관 등의 명시와 설명 및 개정</h3>
            <ol>
              <li>
                “회사”는 이 약관의 내용을 회사의 상호, 소재지, 대표자의 성명,
                사업자등록번호, 개인정보관리 책임자 등을 “이용자”가 쉽게 확인할
                수 있도록 “사이트” 초기 서비스 화면에 게시합니다. 다만, 약관의
                구체적 내용은 “이용자”가 연결화면을 통해 볼 수 있습니다.
              </li>
              <li>
                “회사”는 『전자상거래등에서의소비자보호에관한법률』, 『약관의
                규제 등에 관한 법률』, 『전자거래 기본법』, 『전자서명법』,
                『정보통신망 이용촉진 등에 관한 법률』, 『소비자기본법』 등
                관련법령을 위해하지 않는 범위에서 이 약관을 개정할 수 있습니다.
              </li>
              <li>
                "회사"가 이 약관을 개정할 경우에는 적용일자 및 개정사유를
                명시하여 현행 약관과 함께 “사이트”의 초기화면에 그 적용일자 7일
                이전부터 적용일자 전일까지 공지합니다. 다만, "이용자"에게
                불리하게 이 약관의 내용을 변경하는 경우에는 최소한 30일 이상의
                사전 유예기간을 두고 공지합니다. 이 경우 "회사"는 개정 전 내용과
                개정 후 내용을 명확하게 비교하여 "이용자"가 알기 쉽도록
                표시합니다. 단, "이용자"의 연락처 미기재, 변경 후 미 수정 등으로
                인하여 개별 통지가 어려운 경우에 한하여 서비스 내의 공지사항
                게시판에 공지를 함으로써 개별 통지한 것으로 간주됩니다.
              </li>
              <li>
                제 3항에 따라 공지된 적용일자 이후에 “이용자”가 명시적으로
                거부의사를 표명하지 않을 경우에는 개정된 약관에 동의하는 것으로
                본다.
              </li>
              <li>
                “회사”가 이 약관을 개정할 경우에는 그 개정약관은 그 적용일자
                이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에
                대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미
                계약을 체결한 “이용자”가 개정약관 조항의 적용을 받기를 원하는
                뜻을 제3항에 의한 개정약관의 공지기간 내에 “회사”에 송신하여
                “회사”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
              </li>
              <li>
                이 약관에 명시하지 않은 사항은 『전자상거래 등에서의 소비자
                보호에 관한 법률』 등 관련 법령의 규정과 일반 상관례에 의합니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제4조 서비스의 내용 및 변경</h3>
            <ol>
              <li>“회사”는 다음과 같은 업무를 수행합니다.</li>
              <ol>가. 이용자의 프로필 생성 및 기업 정보 등록 서비스</ol>
              <ol>나. 호스트의 상품 등록 및 관리 활동과 관련된 제반 서비스</ol>
              <ol>다. 게스트의 상품 검색 활동과 관련된 제반 서비스</ol>
              <ol>라. 상품에 대한 정보 및 구매계약의 체결</ol>
              <ol>마. 이용자간의 교류와 소통에 관련된 서비스</ol>
              <li>
                “회사”는 제 1항의 서비스 외 다른 서비스를 추가할 수 있으며,
                추가된 서비스에 대해서도 본 약관이 동일하게 적용됩니다.
              </li>
              <li>
                “회사”는 서비스의 기술적 사양의 변경 등의 경우에는 장차 체결되는
                계약에 의해 제공할 서비스의 내용을 변경할 수 있습니다. 이
                경우에는 변경된 서비스의 내용 및 제공일자를 명시하여 현재의
                서비스의 내용을 게시한 곳에 신속하게 공지합니다.
              </li>
              <li>
                “회사”가 제공하기로 “이용자”와 계약을 체결한 서비스의 내용을
                기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를
                “이용자”에게 통지 가능한 주소(이메일 주소포함)로 즉시
                통지합니다. 전항의 경우 “회사”는 이로 인하여 “이용자”가 입은
                손해를 배상합니다. 다만, “이용자”가 고의 또는 과실이 없음을
                입증하는 경우에는 그러하지 아니합니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제 5조 서비스 이용시간</h3>
            <ol>
              <li>
                “회사”는 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를
                제공합니다. 다만, “회사”는 서비스의 종류나 성질에 따라 제공하는
                서비스 중 일부에 대해서는 별도로 이용시간을 정할 수 있으며, 이
                경우 “회사”는 그 이용시간을 사전에 “이용자”에게 공지 또는
                통지하여야 합니다.
              </li>
              <li>
                “회사”는 서비스의 갱신을 위한 시스템 작업시간, 장애 해결을 위한
                보수작업 시간, 서버 장애 등이 발생한 경우 일시적으로 서비스를
                중단 할 수 있으며 계획된 작업의 경우 공지란에 서비스 중단 시간과
                작업 내용을 알려야 합니다. 다만, “회사”가 사전에 통지할 수 없는
                부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제 6조 서비스의 중단</h3>
            <ol>
              <li>
                “회사”는 컴퓨터 등 정보통신설비의 보수점검ㆍ교체 및 고장, 통신의
                두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로
                중단할 수 있습니다.
              </li>
              <li>
                “회사”는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로
                인하여 “이용자”또는 제3자가 입은 손해에 대하여 배상합니다. 단,
                “회사”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지
                않습니다.
              </li>
              <li>
                사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로
                서비스를 제공할 수 없게 되는 경우에는 “회사”는 제8조에 정한
                방법으로 “이용자”에게 통지하고 당초 “사이트”에서 제시한 조건에
                따라 소비자에게 보상합니다. 다만, “회사”가 보상기준 등을
                고지하지 아니한 경우에는 “사이트”에서 통용되는 통화가치에
                상응하는 현물 또는 현금으로 “이용자”에게 지급합니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제7조 정보의 제공 및 광고의 게재</h3>
            <ol>
              <li>
                “회사”는 “이용자”에게 서비스 이용에 필요하다고 인정되거나 서비스
                개선 및 이용자 대상의 서비스 소개 등의 목적으로 하는 각종 정보에
                대해서 전자 우편이나 서신 우편을 이용한 방법으로 제공할 수
                있습니다.
              </li>
              <li>
                “회사”는 제공하는 서비스와 관련되는 정보 또는 광고를 서비스
                화면, 홈페이지 등에 게재할 수 있으며, 광고가 게재된 전자우편을
                수신한 “이용자”는 수신거부를 “회사”에게 할 수 있습니다.
              </li>
              <li>
                본 서비스의 “이용자”는 서비스 이용 시 노출되는 광고게재에 대해
                동의하는 것으로 간주합니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제8조 이용자 가입</h3>
            <ol>
              <li>
                “회사”는 “회사”의 서비스를 이용하고자 하는 자가 본 약관과
                개인정보처리방침을 읽고 이에 대하여 “동의” 또는 “확인” 버튼을
                누르는 경우 “이용자”로 가입됨과 동시에 “회사”와의 사이에서
                사이트 이용 계약이 성립되고 이를 서비스 이용에 대한 신청으로
                간주합니다.
              </li>
              <li>
                제1항 신청에 있어 “회사”는 “이용자”의 종류에 따라 전문기관을
                통한 실명확인 및 본인인증을 요청할 수 있습니다. “이용자”는 본인
                인증에 필요한 이름, 생년월일, 연락처 등을 제공하여야 합니다.
              </li>
              <li>
                구글 등 외부서비스와의 연동을 통해 이용계약을 신청할 경우, 본
                약관과 개인정보처리방침, 서비스 제공을 위해 “회사”가 “이용자”의
                외부 서비스 계정 정보 접근 및 활용에 “동의” 또는 “확인” 버튼을
                누르면 “회사”가 웹 상의 안내 및 전자메일로 “이용자”에게
                통지함으로써 이용계약이 성립됩니다.
              </li>
              <li>
                “이용자”는 가입시 회사에 제공한 정보에 변경이 있는 경우, 즉시
                전자우편 또는 전화 등 기타의 방법으로 회사에 대하여 그
                변경사항을 알려야 합니다.
              </li>
              <li>
                “이용계약”의 성립시기는 “회사”의 승낙이 “이용자”에게 도달한
                시점으로 합니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제9조 이용자 탈퇴 및 자격 상실 등</h3>
            <ol>
              <li>
                이용자는 “회사”에 언제든지 탈퇴를 요청할 수 있으며 “회사”는 즉시
                이용자 탈퇴를 처리합니다.
              </li>
              <li>
                이용자가 다음 각 호의 사유에 해당하는 경우, “회사”는 이용자
                자격을 제한 및 정지 시킬 수 있습니다.
              </li>
              <ol>가. 가입 신청 시에 허위 내용을 등록한 경우</ol>
              <ol>
                나. “회사” 서비스를 이용하여 구입한 재화 등의 대금, 기타 서비스
                이용에 관련하여 이용자가 부담하는 채무를 기일에 지급하지 않는
                경우
              </ol>
              <ol>
                다. 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등
                전자상거래 질서를 위협하는 경우
              </ol>
              <ol>
                라. 서비스를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에
                반하는 행위를 하는 경우
              </ol>
              <li>
                “회사”가 “이용자” 자격을 제한.정지 시킨 후, 동일한 행위가 2회
                이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우
                “회사”는 “이용자”자격을 상실 시킬 수 있습니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제10조 이용자에 대한 통지</h3>
            <ol>
              <li>
                “회사”가 “이용자”에 대한 통지를 하는 경우, “이용자”가 가입신청
                시 “회사”에 제출한 전자우편주소나 SMS등으로 할 수 있습니다.
              </li>
              <li>
                “회사”는 불특정다수 “이용자”에 대한 통지의 경우 1주일 이상
                사이트의 공지사항(고객센터)에 게시함으로써 개별 통지에 갈음할 수
                있습니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제11조 예약요청 및 계약성립</h3>
            <ol>
              <li>
                “게스트”는 사이트 상에서 다음 또는 이와 유사한 방법에 의하여
                예약요청을 진행하며, “회사”는 게스트가 예약요청을 함에 있어서
                다음의 각 내용을 알기 쉽게 제공하여야 합니다.
              </li>
              <ol>가. 상품, 재화 등의 검색 및 선택</ol>
              <ol>나. 상품 사용에 관한 예약 정보의 입력</ol>
              <ol>
                다. 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호)등의
                입력
              </ol>
              <ol>
                라. 각 상품별 약관내용, 청약철회권이 제한되는 서비스, 기타비용
                부담과 관련한 내용에 대한 확인
              </ol>
              <ol>
                마. 이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시
              </ol>
              <ol>
                바. 재화 등의 예약요청 및 이에 관한 확인 또는 “회사”의 확인에
                대한 동의
              </ol>
              <ol>사. 결제방법의 선택</ol>
              <li>
                “게스트”의 예약요청 후 “호스트”가 예약확정을 하는 경우
                “게스트”에게 예약확정 통지가 전달되며, 이 통지가 도달한 시점에
                구매계약이 성립합니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제12호 대금지급 방법</h3>
            <ol>
              <li>
                사이트에서 구매한 상품 등에 대한 대금지급방법은 다음 각 호의
                하나로 할 수 있습니다. 다만 “회사”의 정책에 따라 일부
                대급지급방법의 사용이 제한될 수 있습니다.
              </li>
              <ol>가. 신용카드, 직불카드 등의 각종 카드결제</ol>
              <ol>나. 인터넷뱅킹을 활용한 실시간 계좌이체</ol>
              <ol>다. 온라인 무통장입금</ol>
              <ol>라. 기타 회사가 추가 지정하는 결제 수단(할인쿠폰, 적립금)</ol>
              <li>
                “이용자”가 구매대금의 결제와 관련하여 입력한 정보 및 그 정보와
                관련하여 발생한 책임과 불이익은 전적으로 “이용자”이 부담합니다.
              </li>
              <li>
                이 조에 따른 세부절차는 『정보통신망 이용촉진 및 정보보호 등에
                관한 법률』, 『개인정보보호법』 및 『저작권법』이 규정한 범위
                내에서 회사가 정한 게시중단요청서비스에 따릅니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제13조 상품의 이용</h3>
            <ol>
              <li>
                “이용자”는 개별조건상에 명시된 유효기간 내에 한해서만 업체의
                서비스를 제공받을 수 있습니다. “이용자”가 유효기간 이내에
                서비스를 이용하지 못한 경우 “회사”의 환불정책에 따라 특정 비율의
                금액만 환불 받을 수 있습니다.
              </li>
              <li>
                “이용자”는 당사 홈페이지에 명시된 내용 및 개별
                주의사항(이용조건)을 확인하여야 할 책임이 있으며, 명시된
                이용조건에 따라서만 구매한 상품을 이용할 수 있습니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제14조 상품 등의 공급</h3>
            <ol>
              <li>
                “회사”는 “이용자”가 상품 등의 공급절차 및 진행사항, 상품 등에
                대한 구매 결제내역을 확인할 수 있도록 관련 정보를 제공하며, 상품
                등의 구매 취소방법 및 절차를 안내합니다.
              </li>
              <li>
                “회사”는 “이용자”가 구매한 상품 등에 대해 이용방법, 이용기간
                등을 명시합니다.
              </li>
              <li>
                “회사”와 고객 간에 상품의 인도시기 및 쿠폰의 제공 시기에 관하여
                별도의 약정이 있는 경우에는 이 약관에 우선합니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제15조 환급</h3>
            <p>
              “회사는 “이용자”가 구매 신청한 상품 등을 품절 등의 사유로 인도
              또는 제공할 수 없을 때에는 지체없이 그 사유를 “이용자”에게
              통지하여야 하며, 사전에 상품 등의 대금을 받은 경우에는 대금을 받은
              날로부터 영업일 7일 이내에 환급하거나 필요한 조치를 취합니다.
            </p>
          </div>
          <div>
            <h3>제16조 청약철회 등</h3>
            <ol>
              <li>
                "회사"와 재화 등의 구매에 관한 계약을 체결한 “이용자”는
                수신확인의 통지를 받은 날부터 7 일 이내에는 청약의 철회를 할 수
                있습니다. 다만, 여행계약 등의 중개 서비스의 경우 별도의 약관에
                따릅니다.
              </li>
              <li>
                “이용자”는 재화 등을 배송 받은 경우 다음 각 호의 경우에는
                청약철회 및 교환을 할 수 없습니다.
              </li>
              <ol>
                가. “이용자”에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된
                경우
              </ol>
              <ol>
                나. “이용자”의 사용 또는 일부 소비에 의하여 재화 등의 가치가
                현저히 감소한 경우
              </ol>
              <ol>
                다. 용역 또는 「문화산업진흥 기본법」 제2조 제5호의 디지털
                콘텐츠의 제공이 개시된 경우. (다만, 가분적 용역 또는 가분적
                디지털 콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한
                부분에 대하여는 사전에 청약철회 제한에 관해 고지하지 않은 한
                청약철회 등을 할 수 있습니다.)
              </ol>
              <li>
                “이용자”은 제 1 항 및 제 2 항의 규정에 불구하고 재화 등의 내용이
                광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화
                등을 공급받은 날부터 3 월 이내, 그 사실을 안 날 또는 알 수
                있었던 날부터 30 일 이내에 청약철회 등을 할 수 있습니다
              </li>
            </ol>
          </div>
          <div>
            <h3>제17조 청약철회 등의 효과</h3>
            <ol>
              <li>
                "회사"는 “이용자”으로부터 재화 등을 반환 받은 경우 3 영업일
                이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우
                "회사"가 “이용자”에게 재화 등의 환급을 지연한 때에는 그
                지연기간에 대하여 전자상거래 등에서의 소비자보호에 관한 법률
                시행령이 정하는 지연이자율을 곱하여 산정한 지연이자를
                지급합니다.{" "}
              </li>
              <li>
                “이용자”은 위 대금을 환급함에 있어서 이용자가 신용카드 또는
                전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체
                없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의
                청구를 정지 또는 취소하도록 요청합니다.
              </li>
              <li>
                청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은
                “이용자”가 부담합니다.
              </li>
              <li>
                “이용자”가 재화 등을 제공받을 때 발송비를 부담한 경우에 "회사"는
                청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록
                명확하게 표시합니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제18조 개인정보 수집.보호</h3>
            <ol>
              <li>
                “회사”는 “이용자”에게 서비스 제공을 위하여 필요한 범위에서
                최소한의 개인정보를 수집합니다.{" "}
              </li>
              <li>
                “회사”는 이용자의 이용자 가입 시 구매계약이행에 필요한 정보를
                미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여
                구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정
                개인정보를 수집하는 경우에는 그러하지 아니합니다.
              </li>
              <li>
                “회사”는 이용자의 개인정보를 수집·이용하는 때에는 당해
                이용자에게 그 목적을 고지하고 동의를 받습니다.{" "}
              </li>
              <li>
                “회사”는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며,
                새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는
                이용·제공 단계에서 당해 “이용자”에게 그 목적을 고지하고 동의를
                받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로
                합니다.
              </li>
              <li>
                “회사”는 제2항과 제3항에 의해 “이용자”의 동의를 받아야 하는
                경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타
                연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공
                관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등
                「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이
                규정한 사항을 미리 명시하거나 고지해야 하며 “이용자”는 언제든지
                이 동의를 철회할 수 있습니다.{" "}
              </li>
              <li>
                “이용자”는 언제든지 “회사”가 가지고 있는 자신의 개인정보에 대해
                열람 및 오류정정을 요구할 수 있으며 “회사”는 이에 대해 지체 없이
                필요한 조치를 취할 의무를 집니다. “이용자”가 오류의 정정을
                요구한 경우에는 “회사”는 그 오류를 정정할 때까지 당해 개인정보를
                이용하지 않습니다.
              </li>
              <li>
                “회사”는 개인정보 보호를 위하여 “이용자”의 개인정보를 취급하는
                자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한
                이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조
                등으로 인한 “이용자”의 손해에 대하여 모든 책임을 집니다.
              </li>
              <li>
                “회사” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의
                수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체
                없이 파기합니다.
              </li>
              <li>
                “회사”는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한
                것으로 설정해 두지 않습니다. 또한 개인정보의 수집·이용·제공에
                관한 이용자의 동의거절 시 제한되는 서비스를 구체적으로 명시하고,
                필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의
                동의 거절을 이유로 이용자가입 등 서비스 제공을 제한하거나
                거절하지 않습니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제19조 회사의 의무</h3>
            <ol>
              <li>
                “회사”는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를
                하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로
                재화․용역을 제공하는데 최선을 다하여야 합니다.
              </li>
              <li>
                “회사”는 “이용자”이 안전하게 인터넷 서비스를 이용할 수 있도록
                이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을
                갖추어야 합니다.
              </li>
              <li>
                “회사”가 상품이나 용역에 대하여 「표시․광고의 공정화에 관한
                법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 “이용자”가
                손해를 입은 때에는 이를 배상할 책임을 집니다.
              </li>
              <li>
                “회사”는 “이용자”가 원하지 않는 영리목적의 광고성 전자우편을
                발송하지 않습니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제20조 이용자의 ID 및 비밀번호에 대한 의무</h3>
            <ol>
              <li>
                ID와 비밀번호에 관한 관리책임은 “이용자”에게 있으며, 이를 소홀히
                하여 발생한 모든 민∙형사상의 책임은 “이용자” 자신에게 있습니다.
              </li>
              <li>
                “이용자”는 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는
                안됩니다.
              </li>
              <li>
                “이용자”이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고
                있음을 인지한 경우에는 즉시 “회사”에 통보하고 “회사”의 조치가
                있는 경우에는 그에 따라야 합니다.
              </li>
              <li>
                “이용자”이 제3항에 따른 통지를 하지 않거나 “회사”의 조치에
                응하지 아니하여 발생하는 모든 불이익에 대한 책임은 “이용자”에게
                있습니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제21조 이용자의 의무 </h3>
            <p>
              “이용자” 는 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는
              안됩니다.
            </p>
            <ol>
              <ol>가. 신청 또는 변경시 허위 내용의 등록</ol>
              <ol>나. 타인의 정보 도용</ol>
              <ol>다. “회사”가 사이트에 게시한 정보의 허가 받지않은 변경</ol>
              <ol>
                라. “회사”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신
                또는 게시
              </ol>
              <ol>
                마. “회사” 및 기타 제3자의 저작권 등 지적재산권에 대한 침해
              </ol>
              <ol>
                바. “회사” 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는
                행위
              </ol>
              <ol>
                사. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에
                반하는 정보를 몰에 공개 또는 게시하는 행위
              </ol>
              <ol>
                아. “회사”의 동의없이 영리를 목적으로 서비스를 사용하는 행위
              </ol>
            </ol>
          </div>
          <div>
            <h3>제22조 이용자의 게시물</h3>
            <ol>
              <li>
                “이용자”가 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한
                “이용자”에게 있으며, “회사”는 “이용자”가 게시하거나 등록하는
                서비스의 내용물이 다음 각 항에 해당한다고 판단되는 경우에
                사전통지 없이 삭제할 수 있으며, 이에 대하여 회사는 어떠한 책임도
                지지 않습니다.
              </li>
              <ol>
                가. 다른 “이용자” 또는 제3자를 비방하거나 중상모략으로 명예를
                손상시키는 내용인 경우
              </ol>
              <ol>나. 공서양속에 위반되는 내용일 경우</ol>
              <ol>다. 범죄적 행위에 결부된다고 인정되는 경우</ol>
              <ol>
                라. “회사”의 저작권, 제3자의 저작권 등 기타 권리를 침해하는
                내용인 경우
              </ol>
              <ol>
                마. “이용자”가 사이트와 게시판에 음란물을 게재하거나
                음란사이트를 링크하는 경우
              </ol>
              <ol>
                바. “회사”로부터 사전 승인 받지 아니한 상업광고, 판촉내용을
                게시하는 경우
              </ol>
              <ol>사. 해당 상품과 관련 없는 내용인 경우</ol>
              <ol>아. 기타 관련법령에 위반된다고 판단되는 경우</ol>
              <li>
                이용계약이 해지(“이용자”탈퇴)된 이후 “이용자”가 작성하였던
                게시물 및 댓글 등은 삭제되지 않으며, 이용계약의
                종료(“이용자”탈퇴)로 인하여 “이용자”정보가 삭제되어 작성자
                본인을 확인할 수 없어 게시물 편집 및 삭제가 원칙적으로
                불가합니다. “이용자”가 작성한 게시물의 삭제를 원할 경우에는
                이용계약의 해지 이전에 게시물을 모두 삭제하여야 합니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제23조 저작권의 귀속 및 이용제한</h3>
            <ol>
              <li>
                “회사”가 작성한 저작물에 대한 저작권 기타 지적재산권은 “회사”에
                귀속합니다.
              </li>
              <li>
                “이용자”는 서비스를 이용함으로써 얻은 정보 중 “회사”에게
                지적재산권이 귀속된 정보를 “회사”의 사전 승낙 없이 복제, 송신,
                출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나
                제3자에게 이용하게 하여서는 안됩니다.
              </li>
              <li>
                “이용자”가 “프로필”에 등록한 내용 및 “기업 정보”에 대해서는
                “이용자”가 저작권을 갖습니다.
              </li>
              <li>
                “회사”는 “이용자”의 콘텐츠를 “회사”가 제공하는 서비스에 노출할
                수 있으며, 이와 관련된 프로모션 등에도 노출할 수 있습니다. 해당
                노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집할 수
                있습니다.
              </li>
              <li>
                “회사”는 저작권법 규정을 준수하며, “이용자”는 언제든지
                고객센터를 통해 해당 게시물에 대해 삭제, 비공개 등의 조치를 취할
                수 있습니다.
              </li>
              <li>
                “회사”가 “이용자”에 대해 서비스를 제공하는 것은 본 이용약관에
                정한 “서비스” 목적 하에서 “회사”가 허용한 방식으로 서비스에 대한
                이용권한을 부여하는 것이며, “이용자”는 서비스를 소유하거나
                서비스에 관한 저작권을 보유하게 되는 것이 아닙니다.
              </li>
              <li>
                “회사”는 “회사”가 정한 이용조건에 따라 “이용자”에게 계정 및 내용
                등을 이용할 수 있는 이용권만을 부여하며, “이용자”은 해당 권리를
                제 3자에게 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제24조 게시물의 관리</h3>
            <ol>
              <li>
                “이용자”의 게시물이 『정보통신망 이용촉진 및 정보보호 등에 관한
                법률』, 『개인정보보호법』 및 『저작권법』등 관련 법령에
                위반되는 내용을 포함하는 경우, 권리자는 관련법령이 정한 절차에
                따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며,
                “회사”는 관련법령에 따라 조치를 취하여야 합니다.
              </li>
              <li>
                “회사”는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가
                인정될 만한 사유가 있거나 기타회사 정책 및 관련법에 위반되는
                경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수
                있습니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제25조 회사의 면책</h3>
            <ol>
              <li>
                “회사”는 본 약관의 약관과 규칙을 벗어난 거래로 발생한 일체의
                사고 및 손실 등에 대해서 책임을 지지 않습니다.
              </li>
              <li>
                “회사”는 천재지변, 불가항력 기타 “회사”의 합리적인 통제범위를
                벗어난 사유로 인하여 서비스를 제공할 수 없는 경우에는 그에 대한
                책임을 부담하지 않습니다.
              </li>
              <li>
                “회사”는 “이용자”의 귀책사유로 인하여 서비스를 제공할 수 없는
                경우에는 그에 대한 책임을 부담하지 않습니다.
              </li>
              <li>
                “회사”는 “이용자” 또는 이용자가 서비스를 이용함으로써 기대되는
                수익을 얻지 못하거나 서비스를 통해 얻은 자료를 이용하여 발생한
                손해에 대해서 책임을 부담하지 않습니다.
              </li>
              <li>
                “이용자”가 서비스에 게재한 정보, 자료, 사실 등의 내용에 관한
                신뢰도 또는 정확성에 대하여는 해당 “이용자”가 책임을 부담하며,
                “회사”는 내용의 부정확 또는 허위로 인해 “이용자” 또는 제3자에게
                발생한 손해에 대해서는 아무런 책임을 부담하지 않습니다.
              </li>
              <li>
                “회사”는 서비스 이용과 관련하여 “이용자”의 고의 또는 과실로
                인하여 “이용자” 또는 제3자에게 발생한 손해에 대하여는 아무런
                책임을 부담하지 않습니다.
              </li>
            </ol>
          </div>
          <div>
            <h3>제26조 분쟁해결</h3>
            <ol>
              <li>
                “회사”는 “이용자”으로부터 제출되는 불만사항 및 의견은 우선적으로
                그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는
                “이용자”에게 그 사유와 처리일정을 즉시 통보합니다.{" "}
              </li>
              <li>이 약관은 대한민국의 법령에 따라 해석됩니다.</li>
            </ol>
          </div>
        </div>
      </div>
      {/* 마케팅 정보 활용 동의 */}
      <div className="mt-10 border-2 border-light-grey p-10">
        <span className="text-3xl font-bold">
          MUST OUTDOOR 마케팅 정보 활용 동의
        </span>
        <div className="h-1 py-5 border-t border-black-grey bg-pure-white w-full mt-0.5" />
        <div className="max-h-[1000px] box-border border-1 border-light-grey overflow-y-auto overflow-x-hidden">
          <div>
            <p>
              주식회사 머스트 아웃도어(이하 “회사”)는 이용자 맞춤형 서비스 및
              상품추천, 각종 이벤트, 경품행사, 혜택 등의 광고성 정보를
              전자우편이나 서신우편, 문자(SMS 또는 카카오 알림톡), 푸시 전화
              등을 통해 이용자에게 제공합니다.{" "}
            </p>
            <p>
              이용자는 마케팅 수신 동의를 거부할 수 있으며, 동의 후라도 이용자의
              의사에 따라 이메일 요청(support@mustoutdoor.com)을 통해 동의를
              철회 할 수 있습니다. 개인정보 보호법 관련 법규에 의거 선택 정보
              사항에 대해서는 동의 거부 또는 철회를 하더라도 서비스 이용에
              제한을 받지 않습니다. 단 할인, 이벤트 및 이용자 맞춤형 상품 추천
              등의 마케팅 정보 안내서비스가 제한됩니다.
            </p>
          </div>
          <div className="mt-5 w-full border-2 border-light-grey h-full flex text-center">
            <div className="w-full">
              <div className="border-light-grey border-2">수집 항목</div>
              <div className="border-light-grey border-2 h-[52px]">
                연락처(휴대전화번호), 이메일, 사업자등록 번호, 기타 서비스 이용
                기록
              </div>
            </div>
            <div className="w-full">
              <div className="border-light-grey border-2">수집 목적</div>
              <div className="border-light-grey border-2 h-[52px]">
                광고성 정보 전달, 제휴 비즈니스 관련 서비스 제공 및 각종 마케팅
                활동
              </div>
            </div>
            <div className="w-full">
              <div className="border-light-grey border-2">이용기간</div>
              <div className="border-light-grey border-2 h-[52px]">
                이용 동의 철회 및 회원 탈퇴 시
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 취소 및 환불 정책 */}
      <div className="mt-10 border-2 border-light-grey p-10">
        <span className="text-3xl font-bold">
          MUST OUTDOOR 취소 및 환불 정책
        </span>
        <div className="h-1 py-5 border-t border-black-grey bg-pure-white w-full mt-0.5" />
        <div className="max-h-[1000px] box-border border-1 border-light-grey overflow-y-auto overflow-x-hidden">
          <div className="mb-5">
            <ol>
              <li>
                취소요청 접수기준은 머스트 아웃도어 플랫폼 운영시간 내 ‘예약취소
                및 환불요청’이 접수된 시간 또는 플랫폼 내의 ‘1:1 문의하기’에
                기록된 시간 혹은 고객센터 유선상 취소요청이 접수된 시간을
                기준으로 합니다.
              </li>
              <li>
                예약 확정 후 일정 변경과 관련된 예약 취소에 관하여는 본 취소환불
                정책에 따른 수수료가 부과됩니다.
              </li>
              <li>
                게스트의 예약 요청 후 호스트의 예약 확정하기 전까지는 결제가
                진행되지 않으며 24시간내 예약 확정을 진행하지 않을 경우,
                게스트의 예약 요청은 자동취소 됩니다. 따라서 게스트는 호스트의
                예약 확정 전까지는 별도의 수수료 없이 예약 요청의 취소가
                가능합니다. 다만, 게스트가 여행 확정 후 24시간 이내에 예약
                취소를 요청하더라도, 여행일이 24시간 이내인 경우 전액환불
                대상에서 제외됩니다.
              </li>
              <li>
                각 상품의 설명 내용에서 취소환불 정책에 대한 별도 규정이 있을
                경우, 각 상품의 별도 기재 내용이 본 취소환불 정책보다 우선
                적용됩니다.
              </li>
              <li>
                게스트가 예약 변경을 원하는 경우, 기존의 예약을 취소하고 다시
                예약을 진행 해야하며, 예약 변경으로 인해 수수료를 비롯한
                추가금액이 발생할 수 있습니다.
              </li>
            </ol>
          </div>
          가장 엄격한 정책
          <div className="mb-5 w-full border-2 border-light-grey h-full flex text-center">
            <div className="w-full">
              <div className="border-light-grey border-2">
                예약 확정 후 방문일 기준
              </div>
              <div className="border-light-grey border-2">
                20일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
                19일 ~ 15일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
                14일 ~ 8일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
                7일 ~ 방문 당일 취소 시
              </div>
            </div>
            <div className="w-full">
              <div className="border-light-grey border-2">환불 액</div>
              <div className="border-light-grey border-2">전액환불</div>
              <div className="border-light-grey border-2">
                결제금액의 70%환불
              </div>
              <div className="border-light-grey border-2">
                결제금액의 50% 환불
              </div>
              <div className="border-light-grey border-2">환불없음</div>
            </div>
          </div>
          엄격한 정책
          <div className="mb-5 w-full border-2 border-light-grey h-full flex text-center">
            <div className="w-full">
              <div className="border-light-grey border-2">
                예약 확정 후 방문일 기준
              </div>
              <div className="border-light-grey border-2">
              15일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              14일 ~ 10일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              9일 ~ 5일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              4일 ~ 2일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              하루 전 ~ 당일 취소 시
              </div>
            </div>
            <div className="w-full">
              <div className="border-light-grey border-2">환불 액</div>
              <div className="border-light-grey border-2">전액환불</div>
              <div className="border-light-grey border-2">
                결제금액의 70%환불
              </div>
              <div className="border-light-grey border-2">
                결제금액의 50% 환불
              </div>
              <div className="border-light-grey border-2">
                결제금액의 30% 환불
              </div>
              <div className="border-light-grey border-2">환불없음</div>
            </div>
          </div>
          기본 정책
          <div className="mb-5 w-full border-2 border-light-grey h-full flex text-center">
            <div className="w-full">
              <div className="border-light-grey border-2">
                예약 확정 후 방문일 기준
              </div>
              <div className="border-light-grey border-2">
              10일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              9일 ~ 7일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              7일 ~ 5일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              4일 ~ 2일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              하루 전 ~ 당일 취소 시
              </div>
            </div>
            <div className="w-full">
              <div className="border-light-grey border-2">환불 액</div>
              <div className="border-light-grey border-2">전액환불</div>
              <div className="border-light-grey border-2">
                결제금액의 70%환불
              </div>
              <div className="border-light-grey border-2">
                결제금액의 50% 환불
              </div>
              <div className="border-light-grey border-2">
                결제금액의 30% 환불
              </div>
              <div className="border-light-grey border-2">환불없음</div>
            </div>
          </div>
          유연한 정책
          <div className="mb-5 w-full border-2 border-light-grey h-full flex text-center">
            <div className="w-full">
              <div className="border-light-grey border-2">
                예약 확정 후 방문일 기준
              </div>
              <div className="border-light-grey border-2">
              7일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              6일 ~ 5일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              4일 ~ 3일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              2일 ~ 1일 전까지 취소 시
              </div>
              <div className="border-light-grey border-2">
              당일 취소 시
              </div>
            </div>
            <div className="w-full">
              <div className="border-light-grey border-2">환불 액</div>
              <div className="border-light-grey border-2">전액환불</div>
              <div className="border-light-grey border-2">
                결제금액의 70%환불
              </div>
              <div className="border-light-grey border-2">
                결제금액의 50% 환불
              </div>
              <div className="border-light-grey border-2">
                결제금액의 30% 환불
              </div>
              <div className="border-light-grey border-2">환불없음</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
