import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "elements/Button";
import HostInput from "elements/HostInput";
import HostWrap from "./hostWrap";
import NewModal from "elements/newModal";
import Icons from "elements/Icons";
import FullSizeModal from "elements/fullSizeModal";

import { certify } from "pages/HostInfo/constants";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

interface hostInfoPageProps {
  hostInfoPageData?: [] | any;
}

interface InputItem {
  id: number;
  languageCode: string;
}

const HostInformation = ({ hostInfoPageData }: hostInfoPageProps) => {
  const { t } = useTranslation();
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const [imgFile, setImgFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [bizNumber, setBizNumber] = useState("");
  const [bizFileUrl, setBizFileUrl] = useState("");
  const [hostName, setHostName] = useState("");
  const [hostCode, setHostCode] = useState("");
  const [bizCode, setBizCode] = useState("");
  const [hostNumber, setHostNumber] = useState("");
  const [bizPhoneNumber, setBizPhoneNumber] = useState("");
  const [selected, setSelected] = useState("");
  const [bisType, setBisType] = useState<any>();

  const [hostFile, setHostFile] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassWord, setNewPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");

  const [code, setCode] = useState();
  const [countryCode, setCountryCode] = useState<any>();
  const [language, setLanguage] = useState<any>();
  const [languageName, setLanguageName] = useState();

  const _email = localStorage.getItem("email");
  const _name = localStorage.getItem("name");
  const id = localStorage.getItem("m");
  const hostId = localStorage.getItem("hostNum");

  const nextID = useRef<number>(1);
  const [inputItem, setInputItem] = useState<InputItem[]>([
    { id: 0, languageCode: "" },
  ]);

  const languages = inputItem?.map(({ languageCode }, idx: number) => {
    const value = languageCode?.substring(0, 2);
    const obj = value;
    return obj;
  });

  const hostData = {
    imageUrl: imgFile,
    languageCode: languages,
    pgInfo: {
      bizNumber: bizNumber,
      bizType: bisType,
      bizUri: bizFileUrl,
      countryCode: bizCode?.replace(/[[a-zA-Z\s\+]/g, ""),
      groundUri: fileUrl,
      telephone: bizPhoneNumber?.replaceAll(/[^0-9]/g, ""),
    },
    mobile: hostNumber?.replaceAll(/[^0-9]/g, ""),
    mobileCountryCode: hostCode?.replace(/[[a-zA-Z\s\+]/g, ""),
    name: hostName,
  };

  const changeHostInfo = () => {
    requestToMustOutdoor({
      method: "PATCH",
      url: `/api/v1/host/update/info/${hostId}`,
      data: hostData,
    })
      .then((res) => {
        // window.location.reload();
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HandleFile = async (e: any) => {
    let file = e.target.files[0];
    const data = {
      multipartFiles: file,
    };

    const response = await requestToMustOutdoor({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}guest/upload/file`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
    const imgUrl = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${response.data}`;
    setImgFile(imgUrl);
    return response;
  };

  const HandleGroundFile = async (e: any) => {
    let file = e.target.files[0];
    const data = {
      multipartFiles: file,
    };

    const response = await requestToMustOutdoor({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}guest/upload/file`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
    const imgName = response.data.data[0];
    const fileUrl = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${imgName}`;
    setFileUrl(fileUrl);
    return response;
  };

  const HandleBizFile = async (e: any) => {
    let file = e.target.files[0];
    const data = {
      multipartFiles: file,
    };

    const response = await requestToMustOutdoor({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}guest/upload/file`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
    const imgName = response.data.data[0];
    const fileUrl = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${imgName}`;
    setBizFileUrl(fileUrl);
    return response;
  };

  const handleLanguage = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (idx > inputItem.length) return;

    const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(inputItem));

    inputItemCopy[idx].languageCode = e.target.value;
    setInputItem(inputItemCopy);
  };

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}guest/main/common/code`,
    })
      .then((res) => {
        const currencyData = res.data.data.currency;
        const countryData = res.data.data.countryCodeMap;
        const language = res.data.data.language;

        const countryCurrency = currencyData.map((item: any) => {
          return item.code;
        });
        const languageData = language.map((item: any) => {
          return `${item.name} ${item.originName}`;
        });
        const languageName = language.map((item: any) => {
          return item.name;
        });
        setCountryCode(countryData);
        setCode(countryCurrency);
        setLanguage(languageData);
        setLanguageName(languageName);
        return res.data.data;
      })
      .catch((err) => {});
  }, []);

  const handleCertify = (e: any) => {
    setSelected(e.target.value);
    if (selected === "Ownership Certificate of Private Property(Ground)") {
      setBisType(true);
    } else {
      setBisType(false);
    }
  };

  const changePassword = () => {
    const passwordChangeData = {
      checkNewPw: reEnterPassword,
      newPw: newPassWord,
      presentPw: currentPassword,
      totalMemberId: id,
    };
    requestToMustOutdoor({
      method: "POST",
      url: `/api/v1/host/change/pw`,
      data: passwordChangeData,
    })
      .then((res) => {
        alert("비밀번호 변경이 완료 되었습니다.");
        setPasswordChangeModal(false);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    ImageUrl,
    Name,
    MobileCode,
    Mobile,
    Language,
    // Currency,
    BizType,
    BizCode,
    BizNum,
    BizUrl,
  } = hostInfoPageData;

  useEffect(() => {
    setImgFile(ImageUrl);
    setHostName(Name);
    setBizNumber(BizNum);
    countryCode?.map((item: string) => {
      const optionCode = item.replace(/[[a-zA-Z\s]/g, "");
      if (optionCode === MobileCode) {
        setHostCode(item);
      }
    });
    countryCode?.map((item: string) => {
      const optionCode = item.replace(/[[a-zA-Z\s]/g, "");
      if (optionCode === "+" + BizCode) {
        setBizCode(item);
      }
    });
    language?.map((item: any) => {
      const optionLang = item.substring(0, 2);
      if (optionLang.includes(Language)) {
        setInputItem([{ id: 0, languageCode: item }]);
      }
    });

    setBizPhoneNumber(BizNum);
    setBisType(BizType);

    if (BizType === true) {
      setBisType(BizType);
      setSelected("Ownership Certificate as Business Registration*");
    } else {
      setBisType(BizType);
      setSelected("Ownership Certificate of Private Property(Ground)*");
    }
    setBizFileUrl(BizUrl);
  }, [
    BizCode,
    BizNum,
    BizType,
    BizUrl,
    ImageUrl,
    Language,
    MobileCode,
    Name,
    countryCode,
    language,
  ]);

  return (
    <div className="phone:px-4 web:px-0">
      {hostFile ? (
        <FullSizeModal
          tourist
          imgList={fileUrl ? fileUrl : bizFileUrl ? bizFileUrl : BizUrl}
          isOpen={() => {
            setHostFile((hostFile) => !hostFile);
          }}
        />
      ) : (
        ""
      )}
      {passwordChangeModal ? (
        <NewModal
          title={t("AS-Host-info.Change Password")}
          onClick={() => changePassword()}
          setModalOpen={() => {
            setPasswordChangeModal(!passwordChangeModal);
          }}
          cancelButton
          buttonText={t("AS-Host-info.Change Password")}
          buttonColor="bg-light-green text-pure-white"
        >
          <div className="font-bold mb-8">
            <div className="text-left mx-auto">
              <div className="mt-8">
                <div>{t("AS-Host-info.Current Password")}</div>
                <input
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                  }}
                  placeholder="Enter Current Password"
                  className="text-sm pl-4 py-2 rounded-lg shadow-ms w-full mt-2"
                />
              </div>
              <div className="my-8">
                <div>{t("AS-Host-info.New Password")}</div>
                <input
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  placeholder="Enter Password"
                  className="text-sm pl-4 py-2 shadow-ms rounded-lg w-full mt-2"
                />
              </div>
              <div className="mb-8">
                <div>{t("Account.Re-enter password")}</div>
                <input
                  onChange={(e) => {
                    setReEnterPassword(e.target.value);
                  }}
                  placeholder="Enter Password"
                  className="text-sm pl-4 py-2 shadow-ms rounded-lg w-full mt-2"
                />
              </div>
            </div>
          </div>
        </NewModal>
      ) : (
        ""
      )}
      <div className="p-2 border flex border-input-text bg-[#eee] rounded-lg my-8">
        <Icons iconName="warning-yellow" className="w-8 h-6 mr-2" />
        <div className="text-sm">
          {t("AS-Host-info.We MUST OUTDOOR will not share host information")}
        </div>
      </div>
      <div className="web:w-160 phone:w-full">
        <div className="font-bold text-[19px] web:pb-12 phone:pb-4">
          {t(`AS-Host-info.Host Information Details`)}
        </div>
        <div className="flex">
          <input
            type={"file"}
            id="hostImage"
            onChange={(e) => {
              HandleFile(e);
              e.target.value = "";
            }}
            className="hidden"
          />
          <label htmlFor="hostImage">
            {ImageUrl !== "" ? (
              <div className="rounded-lg">
                <img
                  src={`${ImageUrl}`}
                  className="web:w-32 web:h-32 phone:w-16 phone:h-16 rounded-full"
                  alt=""
                />
              </div>
            ) : (
              <div className="cursor-pointer relative web:w-32 web:h-32 phone:w-16 phone:h-16 bg-light-green items-center flex rounded-lg">
                <Icons
                  className="fill-pure-white h-24 w-24 mx-auto"
                  iconName={"my-account-avatar"}
                />
                <Icons
                  className="fill-link web:right-[16px] w-6 h-6 web:bottom-[16px] phone:right-0 phone:bottom-0 absolute"
                  iconName={"edit-background"}
                />
              </div>
            )}
          </label>
          <div className="ml-4 flex flex-col justify-around">
            <p className="web:text-2xl phone:text-base">{_name}</p>
            <p className="web:text-xl phone:text-xs">{_email}</p>
          </div>
        </div>

        <div>
          <HostWrap host title={"AS-Host-info.Name of Host*"}>
            <HostInput
              input
              size="XL"
              inputValue={hostName}
              onChange={(e: any) => {
                setHostName(e.target.value);
              }}
              placeholder={"AS-Host-info.Name of Host"}
            />
          </HostWrap>
        </div>

        <div>
          <HostWrap host title={"AS-Host-info.Cellphone Number*"}>
            <HostInput
              onChange={(e) => {
                setHostCode(e.target.value);
              }}
              selectTrans="Country-code"
              selected={hostCode}
              options={countryCode}
              select
              size="S"
              placeholder="AS-Host-info.Select"
            />
            <HostInput
              input
              inputOnButton
              inputValue={hostNumber ? hostNumber : Mobile}
              inputBtnClass="web:right-[32px] top-[16px] phone:right-0"
              inputOnButtonText={t("AS-Host-info.Send")}
              onChange={(e) => {
                setHostNumber(e.target.value);
              }}
              size="L"
            />
          </HostWrap>
          <div className="mt-4">
            <HostInput
              // onChange={(e) => {
              //   setMobileVerify(e.target.value);
              // }}
              // inputButtonOnclick={() => {
              //   mobileVerifyCode();
              // }}
              placeholder={t("AS-Host-info.Input Verification Code")}
              nomargin
              input
              type="number"
              inputMaxLength={6}
              inputLengthPosition="right-1 bottom-[-25px]"
              size="XL"
              inputOnButton
              inputOnButtonText={t("AS-Host-info.Verify Code")}
              inputBtnClass="right-0"
              className=""
              // isError={codeError}
            >
              {/* {codeTrue} */}
            </HostInput>
          </div>
        </div>

        <div className="flex">
          <HostWrap
            host
            // addBtn
            // onClick={() => {
            //   onAddDetailDiv();
            // }}
            addBtnTitle={"Language"}
            title={"AS-Host-info.Preferred Language"}
          >
            {inputItem.map((item, idx) => {
              return (
                <HostInput
                  key={idx}
                  selected={inputItem[idx].languageCode}
                  onChange={(e) => {
                    handleLanguage(e, idx);
                  }}
                  options={language}
                  select
                  size="S"
                  placeholder="AS-Host-info.Select"
                />
              );
            })}
          </HostWrap>
        </div>
        {/* <div>
          <HostWrap host title={"Preferred Currency"}>
            <HostInput
              select
              onChange={(e) => {
                setHostCurrency(e.target.value);
              }}
              selected={hostCurrency}
              options={code}
              size="S"
              placeholder="Select"
            />
          </HostWrap>
        </div> */}

        <HostWrap host title="AS-Host-info.Change Password">
          <div className="inline-block">
            <Button
              onClick={() => {
                setPasswordChangeModal(
                  (passwordChangeModal) => !passwordChangeModal
                );
              }}
              className="mt-4 px-4 py-3  rounded-lg bg-light-green text-pure-white"
              btnText={t("AS-Host-info.Change Password")}
              isIcon
              Icon="lock"
              src="password"
            />
          </div>
        </HostWrap>

        <hr className="my-12 text-input-text" />

        <div className="font-bold text-[19px]">
          {t(`AS-Host-info.Business details`)}
        </div>

        {/* <HostWrap title={"Name of Business*"}>
          <HostInput
            input
            size="XL"
            onChange={(e: any) => {
              setHostName(e.target.value);
            }}
            placeholder={"사업장 이름을 입력해주세요."}
          />
        </HostWrap> */}
        <HostWrap host title={"AS-Host-info.Telephone Number"}>
          <HostInput
            onChange={(e) => {
              setBizCode(e.target.value);
            }}
            selectTrans="Country-code"
            selected={bizCode}
            options={countryCode}
            select
            size="S"
            placeholder="AS-Host-info.Select"
          />
          <HostInput
            input
            inputValue={bizPhoneNumber}
            onChange={(e) => {
              setBizPhoneNumber(e.target.value);
            }}
            size="L"
          />
        </HostWrap>
        <HostWrap host column title="AS-Host-info.Ownership Certificate*">
          <HostInput
            select
            size="XL"
            selectTrans="AS-Host-info"
            selected={selected}
            onChange={handleCertify}
            options={certify}
            placeholder="AS-Host-info.Select"
          />
          {selected === "" ? (
            <div></div>
          ) : selected ===
              "Ownership Certificate of Private Property(Ground)*" ||
            selected === "개인 호스트" ? (
            <>
              <HostInput
                upload
                onChange={(e: any) => {
                  HandleGroundFile(e);
                }}
                formTitle={t("AS-Host-info.Lease or Rental Contract of a land")}
                size="XL"
              />
              <div
                onClick={() => {
                  setHostFile(true);
                }}
                className="my-4 text-sm cursor-pointer w-[142px] rounded-lg bg-light-green text-pure-white text-cetner px-4 py-2"
              >
                업로드 된 파일 확인
              </div>
            </>
          ) : (
            <>
              <HostInput
                input
                size="XL"
                inputValue={bizNumber}
                onChange={(e) => {
                  setBizNumber(e.target.value);
                }}
                formTitle="AS-Host-info.Business Registration Number*"
              />
              {BizUrl ? (
                <div>
                  <HostInput
                    upload
                    onChange={(e: any) => {
                      HandleBizFile(e);
                    }}
                    size="XL"
                    formTitle="AS-Host-info.Business Registration Certificate*"
                  />
                  {/* <div
                    onClick={() => {
                      handleInputClick();
                    }}
                    className="text-sm cursor-pointer"
                  >
                    새로운 파일 업로드
                    <input
                      ref={fileUploadRef}
                      accept=".jpg, .jpeg, .png, .pdf"
                      className="hidden"
                      onChange={(e: any) => {
                        HandleBizFile(e);
                      }}
                      type="file"
                    ></input>
                  </div> */}

                  <div
                    onClick={() => {
                      setHostFile(true);
                    }}
                    className="my-4 text-sm cursor-pointer w-[142px] rounded-lg bg-light-green text-pure-white text-cetner px-4 py-2"
                  >
                    업로드 된 파일 확인
                  </div>
                </div>
              ) : (
                <HostInput
                  upload
                  onChange={(e: any) => {
                    HandleBizFile(e);
                  }}
                  size="XL"
                  formTitle="AS-Host-info.Business Registration Certificate*"
                />
              )}
            </>
          )}
        </HostWrap>
        <div className="inline-block mt-4">
          <Button
            onClick={() => {
              changeHostInfo();
            }}
            className="px-4 py-3 rounded-lg bg-tan text-pure-white"
            isIcon
            src={"save"}
            iconClassName="fill-pure-white"
            btnText={t("AS-Host-info.Save Host Information")}
          />
        </div>

        {/* <HostWrap title={"Telephone Number*"}>
            <HostInput
              input
              size="XL"
              formTitle="Business Registration Number*"
            />
          </HostWrap>
          <HostInput
            upload
            size="XL"
            formTitle="Business Registration Certifycate*"
          /> */}

        {/* <div>
          <HostWrap title={"Tourism Business Operator*"}>
            <HostInput input size="XL" formTitle="Tourism Business Number*" />
          </HostWrap>
          <HostInput
            upload
            size="XL"
            formTitle="Tourism Business Operator Certifycate*"
          />
        </div> */}
      </div>
    </div>
  );
};

export default HostInformation;
