import React from "react";


import { useTranslation } from "react-i18next";
import Icons from "elements/Icons";

interface HostHeaderPorps {
  title: string;
  subTitle?: string;
}

const HostHeader = ({ title, subTitle }: HostHeaderPorps) => {
  const {t} = useTranslation()
  return (
    <div className="phone:hidden web:block">
      <div className="w-full py-7 pr-4">
        <div className="flex w-full items-center">
          <div>
            <Icons className="w-12 h-12" iconName={"host-account"} />
          </div>
          <p className="text-3.5xl ml-6 mr-[auto]">{t(`${title}`)}</p>
          <p className="text-left text-xs w-105">
            {t(`AS-Host-info.We MUST OUTDOOR will not share host information`)}
          </p>
        </div>
      </div>
      <hr />
      <p className="mt-12 text-[27px] font-bold text-left">{t(`${subTitle}`)}</p>
    </div>
  );
};

export default HostHeader;
