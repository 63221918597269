import React, { useEffect, useState } from "react";
import axios from "axios";

const GoogleTranslate = ({ text, language, setTranslateValue }: any) => {
  const [loading, setLoading] = useState(true);

  const translate = async () => {
    const availableLanguages = [
      { language: "영어", code: "en", i18n: "e"},
      { language: "일본어", code: "ja", i18n: "k" },
      { language: "한국어", code: "ko", i18n: "k"},
      // { language: "아프리칸스어", code: "af"},
      // { language: "알바니아어", code: "sq"},
      // { language: "암하라어", code: "am" },
      // { language: "아랍어", code: "ar" },
      // { language: "아르메니아어", code: "hy" },
      // { language: "아삼어", code: "as" },
      // { language: "아이마라어", code: "ay" },
      // { language: "아제르바이잔어", code: "az" },
      // { language: "밤바라어", code: "bm" },
      // { language: "바스크어", code: "eu" },
      // { language: "벨라루스어", code: "be" },
      // { language: "벵골어", code: "bn" },
      // { language: "보지푸리어", code: "bho" },
      // { language: "보스니아어", code: "bs" },
      // { language: "불가리아어", code: "bg" },
      // { language: "카탈루냐어", code: "ca" },
      // { language: "세부아노어", code: "ceb" },
      // { language: "중국어(간체)", code: "zh-CN" },
      // { language: "중국어(번체)", code: "zh-TW" },
      // { language: "코르시카어", code: "co" },
      // { language: "크로아티아어", code: "hr" },
      // { language: "체코어", code: "cs" },
      // { language: "덴마크어", code: "da" },
      // { language: "디베히어", code: "dv" },
      // { language: "도그리어", code: "doi" },
      // { language: "네덜란드어", code: "nl" },
      // { language: "에스페란토", code: "eo" },
      // { language: "에스토니아어", code: "et" },
      // { language: "에웨어", code: "ee" },
      // { language: "필리핀어(타갈로그어)", code: "fil" },
      // { language: "핀란드어", code: "fi" },
      // { language: "프랑스어", code: "fr" },
      // { language: "프리지아어", code: "fy" },
      // { language: "갈리시아어", code: "gl" },
      // { language: "조지아어", code: "ka" },
      // { language: "독일어", code: "de" },
      // { language: "그리스어", code: "el" },
      // { language: "과라니어", code: "gn" },
      // { language: "구자라트어", code: "gu" },
      // { language: "아이티 크리올어", code: "ht" },
      // { language: "하우사어", code: "ha" },
      // { language: "하와이어", code: "haw" },
      // { language: "히브리어", code: "he" },
      // { language: "힌디어", code: "hi" },
      // { language: "몽어", code: "hmn" },
      // { language: "헝가리어", code: "hu" },
      // { language: "아이슬란드어", code: "is" },
      // { language: "이보어", code: "ig" },
      // { language: "일로카노어", code: "ilo" },
      // { language: "인도네시아어", code: "id" },
      // { language: "아일랜드", code: "ga" },
      // { language: "이탈리아어", code: "it" },
      // { language: "자바어", code: "jv" },
      // { language: "칸나다어", code: "kn" },
      // { language: "카자흐어", code: "kk" },
      // { language: "크메르어", code: "km" },
      // { language: "키냐르완다어", code: "rw" },
      // { language: "콘칸어", code: "gom" },
      // { language: "크리오어", code: "kri" },
      // { language: "쿠르드어", code: "ku" },
      // { language: "쿠르드어(소라니어)", code: "ckb" },
      // { language: "쿠르드어", code: "ky" },
      // { language: "라오어", code: "lo" },
      // { language: "라틴어", code: "la" },
      // { language: "라트비아어", code: "lv" },
      // { language: "링갈라어", code: "ln" },
      // { language: "리투아니아어", code: "lt" },
      // { language: "루간다어", code: "lg" },
      // { language: "룩셈부르크어", code: "lb" },
      // { language: "마케도니아어", code: "mk" },
      // { language: "마이틸리어", code: "mai" },
      // { language: "말라가시어", code: "mg" },
      // { language: "말레이어", code: "ms" },
      // { language: "말라얄람어", code: "ml" },
      // { language: "몰타어", code: "mt" },
      // { language: "마오리어", code: "mi" },
      // { language: "마라티어", code: "mr" },
      // { language: "메이테이어(마니푸르어)", code: "mni-Mtei" },
      // { language: "미조어", code: "lus" },
      // { language: "몽골어", code: "mn" },
      // { language: "미얀마어(버마어)", code: "my" },
      // { language: "네팔어", code: "ne" },
      // { language: "노르웨이어", code: "no" },
      // { language: "니안자어(치츄어)", code: "ny" },
      // { language: "오리야어", code: "or" },
      // { language: "오로모어", code: "om" },
      // { language: "파슈토어", code: "ps" },
      // { language: "페르시아어", code: "fa" },
      // { language: "폴란드어", code: "pl" },
      // { language: "포르투갈어(포르투갈, 브라질)", code: "pt" },
      // { language: "펀자브어", code: "pa" },
      // { language: "케추아어", code: "qu" },
      // { language: "루마니아어", code: "ro" },
      // { language: "러시아어", code: "ru" },
      // { language: "사모아어", code: "sm" },
      // { language: "산스크리트어", code: "sa" },
      // { language: "스코틀랜드 게일어", code: "gd" },
      // { language: "북소토어", code: "nso" },
      // { language: "세르비아어", code: "sr" },
      // { language: "세소토어", code: "st" },
      // { language: "쇼나어", code: "sn" },
      // { language: "신디어", code: "sd" },
      // { language: "스리랑카어(싱할라어)", code: "si" },
      // { language: "슬로바키아어", code: "sk" },
      // { language: "슬로베니아어", code: "sl" },
      // { language: "소말리어", code: "so" },
      // { language: "스페인어", code: "es" },
      // { language: "순다어", code: "su" },
      // { language: "스와힐리어", code: "sw" },
      // { language: "스웨덴어", code: "sv" },
      // { language: "타갈로그어(필리핀어)", code: "tl" },
      // { language: "타지크어", code: "tg" },
      // { language: "타밀어", code: "ta" },
      // { language: "타타르어", code: "tt" },
      // { language: "텔루구어", code: "te" },
      // { language: "태국어", code: "th" },
      // { language: "티그리냐어", code: "ti" },
      // { language: "총가어", code: "ts" },
      // { language: "튀르키예어", code: "tr" },
      // { language: "투르크멘어", code: "tk" },
      // { language: "트위어(아칸어)", code: "ak" },
      // { language: "우크라이나어", code: "uk" },
      // { language: "우르두어", code: "ur" },
      // { language: "위구르어", code: "ug" },
      // { language: "우즈베크", code: "uz" },
      // { language: "베트남어", code: "vi" },
      // { language: "웨일즈어", code: "cy" },
      // { language: "코사어", code: "xh" },
      // { language: "이디시어", code: "yi" },
      // { language: "요루바어", code: "yo" },
      // { language: "줄루어", code: "zu" },
    ];

    if (text !== undefined && text) {
      await axios({
        url: "https://translation.googleapis.com/language/translate/v2",
        method: "POST",
        params: {
          q: text.join(""),
          target: language,
          key: process.env.REACT_APP_GOOGLE_TRANSLATE_KEY,
        },
      })
        .then((res) => {
          const translatedText = res.data.data.translations[0].translatedText;
          const textSpit = translatedText.split("_");
          setLoading(false);
          setTranslateValue(textSpit);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    translate();
  }, [loading]);

  return <div></div>;
};

export default GoogleTranslate;
