import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { userInfo } from "../slice/userSlice";

// default 서버 주소
// 서버와 쿠키 공유
requestToMustOutdoor.defaults.withCredentials = true

// 회원가입
export const signupUser = createAsyncThunk(
    'user/signup',
    async (data: userInfo, { rejectWithValue }) => {
        try {
            const res = await requestToMustOutdoor({
                method: "POST",
                url: '/auth/sign-up',
                data
            })
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);

// 로그인
export const loginUser = createAsyncThunk(
    'user/login',
    async (data: userInfo, { rejectWithValue }) => {
        try {
            const res = await requestToMustOutdoor({
                method: "POST",
                url: '/auth/log-in',
                data
            });
            return res.data.data;
        }
        catch (err: any) {
            return rejectWithValue(err);
        }
    }
)

// 이메일 중복 체크
export const emailcheck = createAsyncThunk(
    'user/emailcheck',
    async (data: userInfo, { rejectWithValue }) => {
        try {
            const res = await requestToMustOutdoor({
                method: "POST",
                url: `/auth/check/duplication/${data.email}`,
                data
            });
            return res.data.data
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
)
// 이메일 인증 코드
export const emailCodecheck = createAsyncThunk(
    'user/emailcode',
    async (data: userInfo, { rejectWithValue }) => {
        try {
            const res = await requestToMustOutdoor({
                method: "POST",
                url: '/auth/verify/account',
                data: { code: data.code }
            });
            //console.logres.data, '이메일 인증코드 OK')
            return res.data.data;
        } catch (err: any) {
            //console.logerr, '이메일 인증코드 실패');
            return rejectWithValue(err);
        }
    }
)
