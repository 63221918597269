import React, { useState, useRef } from "react";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
interface ImageUploadProps {
  className?: string;
  children?: any;
  imageListProps?: any;
  imageList?: any;
  multiUpload?: boolean;
  imageLength?: number | any;
  currentImage?: boolean;
  images?: boolean;
}

const ImageUpload = ({
  className,
  children,
  imageListProps,
  imageList,
  multiUpload,
  imageLength,
  currentImage,
  images,
}: ImageUploadProps) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const handleInputClick = () => {
    fileUploadRef.current?.click();
  };

  const handleImage = (e: any) => {
    let fileList = e.target.files;
    let file = e.target.files[0];
    let fileSize = file?.size;
    console.log(fileList)

    const formData = new FormData();

    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].size > 1024 ** 2 * 5) {
        return alert(`사이즈가 5mb 보다 큰 파일이 있습니다.`);
      } else {
        formData.append("multipartFiles", fileList[i]);
      }
    }

    if (imageLength > 18) {
      return alert("최대 업로드 갯수는 20개 입니다.");
    } else {
      requestToMustOutdoor({
        method: "POST",
        url: "/guest/upload/file",
        headers: { "Content-Type": "multipart/form-data", accept: "*/*" },
        data: formData,
      })
        .then((res) => {
          const data = res.data.data;
          const imgName = data[0];
          const imgUrl_ = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${imgName}`;
          if (images) {
            data?.map((item: string) => {
              const imgUrl_ = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${item}`;
              imageListProps((pre: any) => [
                ...pre,
                {
                  fileName: item,
                  url: imgUrl_,
                  profile: false,
                },
              ]);
            });
          } else if (currentImage) {
            imageListProps({
              fileName: imgName,
              url: imgUrl_,
              profile: true,
            });
          }

          return data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <input
        ref={fileUploadRef}
        onChange={(e) => handleImage(e)}
        type="file"
        accept=".jpg, .jpeg, .png, .pdf"
        className="hidden"
        multiple={multiUpload}
      />
      <div
        onClick={() => {
          handleInputClick();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ImageUpload;
