import React, { useEffect, useState } from "react";

import GoogleMap from "components/GoogleMap";


import DetailInfo from "elements/DetailInfo";
import DetailTheme from "elements/DetailTheme";

import { changeFacility } from "utils/changeCase";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import FullSizeModal from "elements/fullSizeModal";
import SideMenu from "elements/sideMenu";
import { inputPriceComma } from "utils/commaDash";
import MoblieHeader from "components/moblieHeader";
import Icons from "elements/Icons";

interface extraOptionItem {
  amount?: number;
  description?: string;
  maxOfNumber?: number;
  paymentMethod?: string;
  price?: string;
  title?: string;
}

const FreeDetails = () => {
  const { t } = useTranslation();

  const [specialTab, setSpecialTab] = useState(false);
  const [extraTab, setExtraTab] = useState(false);
  const [availableTab, setAvailableTab] = useState(false);
  const [locationTab, setLocationTab] = useState(false);
  const [bookingTab, setBookingTab] = useState(false);
  const [policyTab, setPolicyTab] = useState(false);
  const [allPhotoModal, setAllPhotoModal] = useState(false);
  const [freePrivate, setFreePrivate] = useState<any>([]);

  const [touristModal, setTouristModal] = useState(false);
  const [touristDetail, setTouristDetail] = useState<any>([]);

  const [active, setActive] = useState<any[]>([]);

  const [extraPayment, setExtraPayment] = useState<any[] | any>();

  const params = useParams();
  const _id = params.id;

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/guest/public-ground/${_id}`,
    })
      .then((res) => {
        setFreePrivate(res.data.data);
        return res.data.data;
      })
      .catch((err) => {});
  }, [_id]);

  const touristInfo = (title: string, desc: string, imgUrl: string) => {
    setTouristDetail({ title: title, desc: desc, imgUrl: imgUrl });
    setTouristModal(true);
  };

  const {
    title,
    typeInfo,
    facilities,
    imageList,
    locationThemes,
    countryCode,
    description,
    address,
    direction,
    activities,
    restricts,
    specialFacility,
    extraOption,
    policyInfo,
    state,
    city,
    extraPolicy,
    indicator,
    nearbyFacilities,
    touristSpots,
    unitCount,
    accessibleVehicle,
    priceInfo,
  } = freePrivate;

  const isundefined = extraPayment?.includes(undefined);

  useEffect(() => {
    if (extraPayment !== undefined && isundefined) {
      const result = extraPayment.filter(function(x: any) {
        return x !== undefined;
      });
      setExtraPayment(result);
    }
  }, [extraPayment, isundefined]);

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <div
      {...props}
      className={currentSlide === 0 ? " slick-disabled" : ""}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <Icons
        className={`cursor-pointer w-8 h-8 absolute z-100 top-[50%] right-[25px]`}
        iconName="slider-arrow-right"
      />
    </div>
  );
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
    <div
      {...props}
      className={currentSlide === 0 ? " slick-disabled" : ""}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <Icons
        className={`cursor-pointer w-8 h-8 absolute z-100 top-[50%] left-[25px]`}
        iconName="slider-arrow-left"
      />
    </div>
  );

  const allPhotoView = () => {
    setAllPhotoModal((allPhotoModal) => !allPhotoModal);
  };

  const setting = {
    centerMode: true,
    infinite: true,
    appendDots: (dots: any) => (
      <div
        style={{
          width: "86.5%",
          position: "absolute",
          bottom: "-19px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    slidesToShow: 1,
    swipeToSlide: false,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
  };

  const specialTabToggle = () => {
    setSpecialTab((specialTab) => !specialTab);
  };
  const extraTabToggle = () => {
    setExtraTab((extraTab) => !extraTab);
  };
  const availableTabToggle = () => {
    setAvailableTab((availableTab) => !availableTab);
  };
  const locationTabToggle = () => {
    setLocationTab((locationTab) => !locationTab);
  };
  const bookingTabToggle = () => {
    setBookingTab((bookingTab) => !bookingTab);
  };
  const policyTabToggle = () => {
    setPolicyTab((policyTab) => !policyTab);
  };

  return (
    <div>
      <MoblieHeader center={title} left />
      {touristModal === true ? (
        <FullSizeModal
          tourist
          TouristTitle={touristDetail?.title}
          TouristDesc={touristDetail?.desc}
          isOpen={() => {
            setTouristModal((touristModal) => !touristModal);
          }}
          imgList={touristDetail?.imgUrl}
        />
      ) : (
        ""
      )}
      <SideMenu
        setIsOpenTab={setSpecialTab}
        isOpenTab={specialTab}
        tabTitle="Product-details.Special Facility"
      >
        {specialFacility ? (
          <div>
            <div>{specialFacility}</div>
            {/* <hr className="my-4" /> */}
          </div>
        ) : (
          <div>{t(`Common.N/A`)}</div>
        )}
      </SideMenu>
      <SideMenu
        setIsOpenTab={setExtraTab}
        isOpenTab={extraTab}
        tabTitle="Product-details.Extra Options"
      >
        {extraOption && !!extraOption[0]?.title ? (
          extraOption.map(
            (
              { title, description, maxOfNumber, price, paymentMethod }: any,
              key: number
            ) => {
              return (
                <div key={key} className="w-full">
                  <div className="flex justify-between items-center">
                    <div>{title}</div>
                    <div>{inputPriceComma(price)}</div>
                  </div>
                  <div className="my-2">
                    {paymentMethod === "ON_SITE_PAYMENT" ? (
                      <div className="flex items-center">
                        <img
                          className="w-6 h-6"
                          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/on-site-payment-only.svg"
                          alt=""
                        />
                        <div className="text-sm ml-2">On-Site Payment Only</div>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <img
                          className="w-6 h-6"
                          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-in-extra-option.svg"
                          alt=""
                        />
                        <div className="text-sm ml-2 text-main-green">
                          Available in Extra Option{" "}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>{description}</div>

                  <hr className="my-4 last:hidden" />
                </div>
              );
            }
          )
        ) : (
          <div>{t(`Common.N/A`)}</div>
        )}
      </SideMenu>
      <SideMenu
        setIsOpenTab={setLocationTab}
        isOpenTab={locationTab}
        tabTitle="Product-details.Location"
      >
        <div className="mb-4 w-full h-64">
          <GoogleMap
            mapLocation={{
              lat: Number(`${indicator && indicator.latitude}`),
              lng: Number(`${indicator && indicator.longitude}`),
            }}
          />
        </div>
        <div className="font-bold mb-2">{t("Product-details.Location")}</div>
        <div className="text-sm flex items-center">
          <img
            className="w-6 h-6 mr-2"
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/real-address.svg"
            alt=""
          />
          <div>{state + " " + city + " " + address}</div>
        </div>
        <hr className="my-4 text-input-border-color" />
        <div className="font-bold mb-2">
          {t("Product-details.How To Get There")}
        </div>
        <div className="text-sm">
          {direction ? direction : <div>{t(`Common.N/A`)}</div>}
        </div>
        <hr className="my-4 text-input-border-color" />
        <div className="w-full">
          <div className="font-bold mb-2">
            {t("Product-details.Nearby Facilities")}
          </div>
          {nearbyFacilities?.length !== 0 ? (
            nearbyFacilities &&
            nearbyFacilities.map((item: any, i: number) => {
              if (item !== null) {
                return (
                  <div
                    key={i}
                    className="flex items-center mb-4 last:mb-0 justify-between w-full"
                  >
                    <div className="flex items-center">
                      <img
                        className="mr-2 w-5 h-5"
                        alt=""
                        src={
                          item.facility === "EV_CHARGING_STATION"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/ev-charge-station.svg"
                            : item.facility === "CONVENIENCE_STORE"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/convenience-store.svg"
                            : item.facility === "GAS_STATION"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/gas-station.svg"
                            : item.facility === "SUPERMARKET"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/supermarket.svg"
                            : item.facility === "TOILET_24HR"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/24hr-toilet.svg"
                            : ""
                        }
                      />
                      <div>
                        {t(`Nearby-facility.${changeFacility(item.facility)}`)}
                      </div>
                    </div>
                    <div className="flex text-sm items-center max-w-[200px]">
                      <div>{item.name}</div>
                      <div> ({item.distance}Km)</div>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <div>{t(`Common.N/A`)}</div>
          )}
          <hr className="my-4 text-input-border-color" />
        </div>
        <div>
          <div className="font-bold mb-2">
            {t("Product-details.Recommending Tourist spot")}
          </div>
          <div className="flex web:flex-row phone:flex-col">
            {touristSpots &&
            !!touristSpots[0]?.title &&
            !!touristSpots[0]?.description &&
            !!touristSpots[0]?.photoUrl ? (
              touristSpots.map(
                ({ title, description, photoUrl }: any, key: number) => {
                  return (
                    <div
                      key={key}
                      className="w-64 h-[161px] shadow-card rounded-lg relative"
                    >
                      <div
                        onClick={() => {
                          touristInfo(title, description, photoUrl);
                        }}
                        className="opacity-0 hover:opacity-50 flex justify-center w-full h-32 cursor-pointer items-center hover:bg-black rounded-t-lg absolute"
                      >
                        <Icons iconName="view-detail" className="w-8 h-8" />
                      </div>
                      <img
                        className="w-full h-32 rounded-t-lg"
                        src={photoUrl}
                        alt=""
                      />
                      <div className="w-full h-[33px] rounded-b-lg bg-pure-white flex justify-center items-center text-sm ">
                        {title}
                      </div>
                    </div>
                  );
                }
              )
            ) : (
              <div>{t(`Common.N/A`)}</div>
            )}
          </div>
        </div>
      </SideMenu>

      {allPhotoModal ? (
        <FullSizeModal
          isOpen={() => {
            setAllPhotoModal(!allPhotoModal);
          }}
          imgList={imageList}
        />
      ) : (
        ""
      )}

      {/* 모바일 */}
      <div className="web:hidden phone:block">
        <div
          onClick={() => {
            allPhotoView();
          }}
          className="bg-center bg-cover w-full h-52"
          style={{
            backgroundImage: `url(${imageList && imageList[0].url})`,
          }}
        />
        <div className="p-4 text-left w-full">
          <div className="flex flex-col">
            <div className="text-xl font-bold">{title}</div>
            <div className="flex justify-between">
              <div className="flex items-baseline">
                <img
                  className="w-4 h-4"
                  src={
                    "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/rating-star-o.svg"
                  }
                  alt=""
                />
                <div className="font-bold mx-1">4.3</div>
                <div className="text-xs text-input-text">237 Reviews</div>
              </div>
              <div className="flex">
                <img
                  className="w-6 h-6"
                  src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/wishlist-black.svg`}
                  alt=""
                />
                <img
                  className="w-6 h-6 ml-4"
                  src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/share.svg`}
                  alt=""
                />
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div className="text-sm">
            <div className="flex mb-4 items-center">
              <img
                src={typeInfo?.iconUrl}
                className="w-6 h-6"
                alt={typeInfo?.displayName}
              />
              <div className="ml-2">
                {t(typeInfo && `Private-Type-data.${typeInfo.enName}`)}
              </div>
            </div>
            {locationThemes &&
              locationThemes?.map((item: any, key: any) => {
                const data = item.themeInfo;
                return (
                  <div key={key} className="flex items-center mb-4">
                    <img
                      src={data?.iconUrl}
                      className="w-6 h-6"
                      alt={data?.displayName}
                    />
                    <div className="ml-2">
                      {t(`Theme-data.${data?.enName}`)}
                    </div>
                  </div>
                );
              })}
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div>{t("Product-details.Description")}</div>
            <div className="text-sm mt-2">{description}</div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div className="text-xl font-bold">
              {t("Product-details.Ground Details")}
            </div>
            <div
              onClick={() => {
                !active.includes(0)
                  ? setActive((active) => [...active, 0])
                  : setActive(active.filter((x) => x !== 0));
              }}
              className="mt-4 cursor-pointer"
            >
              <div>{t(`Product-details.Facilty`)}</div>
              <div
                className={`flex justify-between items-center pb-2 border-border-light border-b`}
              >
                <div
                  className={`flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap`}
                >
                  {facilities && facilities.length !== 0 ? (
                    facilities.map((item: any, key: number) => {
                      const data = item.facilityList;
                      return (
                        <div key={key} className="mr-4 mt-2 last:mr-0">
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6"
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(0) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(0) ? (
                <div className="mt-2 h-full transition-all">
                  {facilities && facilities.length !== 0 ? (
                    facilities.map((item: any, key: number) => {
                      const data = item.facilityList;
                      return (
                        <div
                          key={key}
                          className="mb-4 last:mb-0 flex items-center"
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6"
                          />
                          <div className="ml-2">
                            {t(`Facility-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="my-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div>
            <div
              onClick={() => {
                !active.includes(1)
                  ? setActive((active) => [...active, 1])
                  : setActive(active.filter((x) => x !== 1));
              }}
              className="mt-4 cursor-pointer"
            >
              <div>{t(`Product-details.Activity`)}</div>
              <div
                className={`flex justify-between items-center pb-2 border-border-light border-b`}
              >
                <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                  {activities && activities.length !== 0 ? (
                    activities.map((item: any, key: number) => {
                      const data = item.activityList;
                      return (
                        <div key={key} className="mr-4 mt-2 last:mr-0">
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6"
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(1) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(1) ? (
                <div className="mt-2">
                  {activities && activities.length !== 0 ? (
                    activities.map((item: any, key: number) => {
                      const data = item.activityList;
                      return (
                        <div
                          key={key}
                          className="mb-4 last:mb-0 flex items-center"
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6"
                          />
                          <div className="ml-2">
                            {t(`Activity-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="my-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div>
            <div
              onClick={() => {
                !active.includes(2)
                  ? setActive((active) => [...active, 2])
                  : setActive(active.filter((x) => x !== 2));
              }}
              className="cursor-pointer mt-4"
            >
              <div>{t(`Product-details.Allowed Details`)}</div>
              <div
                className={`flex justify-between items-center pb-2 border-border-light border-b`}
              >
                <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                  {restricts && restricts.length !== 0 ? (
                    restricts.map((item: any, key: number) => {
                      const data = item.allowedList;
                      if (data.allowed === true) {
                        return (
                          <div key={key} className="mr-4 mt-2 last:mr-0">
                            <img
                              src={data.iconUrl}
                              alt={data.enName}
                              className="w-6 h-6"
                            />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(2) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(2) ? (
                <div className="mt-2">
                  {restricts && restricts.length !== 0 ? (
                    restricts.map((item: any, key: number) => {
                      const data = item.allowedList;
                      if (data.allowed === true) {
                        return (
                          <div
                            key={key}
                            className="mb-4 last:mb-0 flex items-center"
                          >
                            <img
                              src={data.iconUrl}
                              alt={data.enName}
                              className="w-6 h-6"
                            />
                            <div className="ml-2">
                              {t(`Allowed-data.${data.enName}`)}
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="my-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div>
            <div
              onClick={() => {
                !active.includes(3)
                  ? setActive((active) => [...active, 3])
                  : setActive(active.filter((x) => x !== 3));
              }}
              className="mt-4 cursor-pointer"
            >
              <div>{t(`Product-details.Prohibited Details`)}</div>
              <div
                className={`flex justify-between items-center pb-2 border-border-light border-b`}
              >
                <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                  {restricts && restricts.length !== 0 ? (
                    restricts.map((item: any, key: number) => {
                      const data = item.allowedList;
                      if (data.allowed === false) {
                        return (
                          <div key={key} className="mr-4 mt-2 last:mr-0">
                            <img
                              src={data.iconUrl}
                              alt={data.enName}
                              className="w-6 h-6"
                            />
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(3) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(3) ? (
                <div className="mt-2">
                  {restricts && restricts.length !== 0 ? (
                    restricts.map((item: any, key: number) => {
                      const data = item.allowedList;
                      if (data.allowed === false) {
                        return (
                          <div
                            key={key}
                            className="mb-4 last:mb-0 flex items-center"
                          >
                            <img
                              src={data.iconUrl}
                              alt={data.enName}
                              className="w-6 h-6"
                            />
                            <div className="ml-2">
                              {t(`Prohibited-data.${data.enName}`)}
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="my-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              onClick={() => specialTabToggle()}
              className="flex items-center cursor-pointer justify-between border-b pb-4 mb-4 border-border-light mt-4"
            >
              <div>{t("Product-details.Special Facility")}</div>
              <img
                className="w-3 h-3"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                alt=""
              />
            </div>
            <div
              onClick={() => extraTabToggle()}
              className="flex items-center cursor-pointer justify-between border-b pb-4 border-border-light"
            >
              <div>{t("Product-details.Extra Options")}</div>
              <img
                className="w-3 h-3"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                alt=""
              />
            </div>
          </div>

          <div className="mt-4">
            <div className="text-xl font-bold mb-4">
              {t("Product-details.Available Ground")}
            </div>
            <img
              className="w-66 h-40 rounded-t-lg bg-center bg-cover"
              src={imageList && imageList[0].url}
              alt=""
            />
            <div className="w-66 rounded-b-lg shadow-ms p-2">
              <div className="flex justify-between items-center">
                <div className="w-[191px] whitespace-nowrap font-bold text-main-green text-tiny overflow-hidden text-ellipsis">
                  {title}
                </div>
              </div>
              <div className="flex mt-2 justify-between items-center">
                <div className="flex items-center">
                  <img
                    src={typeInfo?.iconUrl}
                    className="w-6 h-6"
                    alt={typeInfo?.displayName}
                  />
                  <div className="ml-2 text-sm">
                    {t(typeInfo && `Private-Type-data.${typeInfo.enName}`)}
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div className="text-xl font-bold mb-4">
              {t("Product-details.Location")}
            </div>
          </div>
          <div className="my-8 w-full h-64">
            <GoogleMap
              mapLocation={{
                lat: Number(`${indicator && indicator.latitude}`),
                lng: Number(`${indicator && indicator.longitude}`),
              }}
            />
          </div>
          <div
            onClick={() => locationTabToggle()}
            className="cursor-pointer border-b pb-4 mb-4 border-border-light"
          >
            <div className="flex items-center justify-between">
              <div>{t("Product-details.Location")}</div>
              <img
                className="w-3 h-3"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                alt=""
              />
            </div>
            <div className="mt-4 flex">
              <img
                className="w-6 h-6 mr-2"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/real-address.svg"
                alt=""
              />
              <div>{state + " " + city + " " + address}</div>
            </div>
          </div>
        </div>
      </div>

      {/* 웹 */}
      <div className="web:block phone:hidden text-left mx-auto lg:max-w-1216 p-8">
        <div className="grid grid-cols-2 w-full gap-1">
          <div
            className="w-full h-[500px] bg-center bg-cover block"
            style={{
              backgroundImage: `url(${imageList && imageList[0].url})`,
            }}
          />
          <div className="grid relative grid-cols-2 gap-1 grid-rows-2">
            {imageList?.map((item: any, idx: number) => {
              if (idx === 0 || idx > 4) {
                return "";
              } else {
                return (
                  <div
                    key={idx}
                    className="bg-center bg-cover"
                    style={{
                      backgroundImage: `url(${item?.url})`,
                    }}
                  />
                );
              }
            })}
            <div
              className="absolute z-10 px-4 py-2 font-bold bg-gainsboro bottom-0 right-8 mb-4 cursor-pointer shadow-ms rounded-lg"
              onClick={() => {
                allPhotoView();
              }}
            >
              {/* View {imageList?.length} Photos */}
              {t("Product-details.View photos")}
            </div>
          </div>
        </div>
        <div className="flex pt-8">
          <div className="max-w-[800px]">
            <div className="text-sm">
              Home &nbsp; {">"} &nbsp;
              {countryCode} &nbsp; {">"} &nbsp;
              {t(`Kor-state.${state}`)} &nbsp; {">"} &nbsp;
              {title}
            </div>
            <div className="py-8">
              <div className="font-bold text-3.5xl mb-8">{title}</div>
              <div className="flex flex-wrap justify-start">
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src={typeInfo?.iconUrl}
                    className="w-5 h-5"
                    alt={typeInfo?.enName}
                  />
                  <div className="ml-2">
                    {t(typeInfo && `Private-Type-data.${typeInfo?.enName}`)}
                  </div>
                </div>
                {locationThemes &&
                  locationThemes.map((item: any, key: any) => {
                    const data = item.themeInfo;
                    return (
                      <div key={key} className="flex items-center mr-8 mb-8">
                        <img
                          src={data?.iconUrl}
                          className="w-5 h-5"
                          alt={data?.enName}
                        />
                        <div className="ml-2">
                          {t(`Theme-data.${data.enName}`)}
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* <div className="flex flex-wrap justify-start">
              <div className="flex items-center mr-8 mb-8">
                <Icon src="cancellationPolicy" className="mr-2" />
                <p>
                  {t(`host.Refund Policy`)} -{" "}
                  {t(policyInfo && `policy.${changecase(policyInfo.name)}`)}
                </p>
              </div>
              <div className="flex items-center mr-8 mb-8">
                <Icon src="bookingAvailable" className="mr-2" />
                <p>
                  {t(`host.Confirm`)} -{" "}
                  {t(confirmMethod && `policy.${changecase(confirmMethod)}`)}
                </p>
              </div>
              <div className="flex items-center mr-8 mb-8">
                <Icon src="bookingAvailable" className="mr-2" />
                <p>
                  {t(`host.Booking`)} -{" "}
                  {t(
                    advanceDate
                      ? "policy.Available Today"
                      : "policy.Unavailable Today"
                  )}
                </p>
              </div>
            </div> */}

              <div className="text-base">
                {description}
                {/* <span className="text-link cursor-pointer">Read More</span> */}
              </div>
            </div>

            <DetailTheme kor theme="Product-details.Ground Details">
              <DetailInfo title="Product-details.Facilty">
                {facilities && facilities.length !== 0 ? (
                  facilities.map((item: any, key: number) => {
                    const data = item.facilityList;
                    return (
                      <div
                        key={key}
                        className="flex items-center w-[191px] mr-[9px] mb-4 "
                      >
                        <img
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-5 h-5"
                        />
                        <div className="ml-2">
                          {t(`Facility-data.${data.enName}`)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>

              <DetailInfo title="Product-details.Activity">
                {activities && activities.length !== 0 ? (
                  activities.map((item: any, key: number) => {
                    const data = item.activityList;
                    return (
                      <div
                        key={key}
                        className="flex items-center w-[191px] mr-[9px] mb-4 "
                      >
                        <img
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-5 h-5"
                        />
                        <div className="ml-2">
                          {t(`Activity-data.${data.enName}`)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Allowed Details">
                {restricts && restricts.length !== 0 ? (
                  restricts.map((item: any, key: number) => {
                    const data = item.allowedList;
                    if (data.allowed === true) {
                      return (
                        <div
                          key={key}
                          className="flex items-center w-[191px] mr-[9px] mb-4 "
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-5 h-5"
                          />
                          <div className="ml-2">
                            {t(`Allowed-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Prohibited Details">
                {restricts && restricts.length !== 0 ? (
                  restricts.map((item: any, key: number) => {
                    const data = item.allowedList;
                    if (data.allowed === false) {
                      return (
                        <div
                          key={key}
                          className="flex items-center w-[191px] mr-[9px] mb-4 "
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="mr-2 w-5 h-5"
                          />
                          <div className="ml-2">
                            {t(`Prohibited-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Special Facility" withIcon>
                {specialFacility ? (
                  specialFacility
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
            </DetailTheme>

            <DetailTheme kor theme="Product-details.Location">
              <div className="my-8 h-[400px]">
                <GoogleMap
                  mapLocation={{
                    lat: Number(`${indicator && indicator?.latitude}`),
                    lng: Number(`${indicator && indicator?.longitude}`),
                  }}
                />
              </div>
              <DetailInfo title="Product-details.Address" withIcon>
                <Icons iconName="real-address" className="mr-2 w-5 h-5" />
                <div>{state + " " + city + " " + address}</div>
              </DetailInfo>
              <DetailInfo withIcon title="Add-accommodation.How To Get There">
                {direction}
              </DetailInfo>
              <DetailInfo
                noFlex
                withIcon
                title="Product-details.Nearby Facilities"
              >
                {nearbyFacilities &&
                  nearbyFacilities.map((item: any, i: number) => {
                    if (item !== null) {
                      return (
                        <div
                          key={i}
                          className="flex items-center mb-4 last:mb-0"
                        >
                          <div className="w-[224px] flex">
                            <img
                              className="mr-2 w-5 h-5"
                              alt=""
                              src={
                                item.facility === "EV_CHARGING_STATION"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/ev-charge-station.svg"
                                  : item.facility === "CONVENIENCE_STORE"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/convenience-store.svg"
                                  : item.facility === "GAS_STATION"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/gas-station.svg"
                                  : item.facility === "SUPERMARKET"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/supermarket.svg"
                                  : item.facility === "TOILET_24HR"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/24hr-toilet.svg"
                                  : ""
                              }
                            />
                            <div>
                              {t(
                                `Nearby-facility.${changeFacility(
                                  item.facility
                                )}`
                              )}
                            </div>
                          </div>
                          <div>{item.name}</div>
                          <div> ({item.distance}Km)</div>
                        </div>
                      );
                    }
                  })}
              </DetailInfo>

              <DetailInfo
                withIcon
                title={`Add-private-ground.Recommending Tourist Spot`}
              >
                {touristSpots &&
                !!touristSpots[0]?.title &&
                !!touristSpots[0]?.description &&
                !!touristSpots[0]?.photoUrl ? (
                  touristSpots.map(
                    ({ title, description, photoUrl }: any, key: number) => {
                      return (
                        <div
                          key={key}
                          className="w-50 h-[161px] shadow-card rounded-lg"
                        >
                          <div
                            onClick={() => {
                              touristInfo(title, description, photoUrl);
                            }}
                            className="opacity-0 hover:opacity-50 flex justify-center w-50 h-32 cursor-pointer items-center hover:bg-black rounded-t-lg absolute"
                          >
                            <Icons iconName="view-detail" className="w-8 h-8" />
                          </div>
                          <img
                            className="w-50 h-32 rounded-t-lg"
                            src={photoUrl}
                            alt=""
                          />
                          <div className="w-50 h-[33px] rounded-b-lg bg-pure-white  flex justify-center items-center text-sm ">
                            {title}
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
            </DetailTheme>
            <DetailTheme kor theme="Product-details.Policy">
              <DetailInfo
                withIcon
                title={"Product-details.Additional Rule / Policy"}
              >
                {extraPolicy}
              </DetailInfo>
            </DetailTheme>

            {/* <hr />
          <div className="py-8">
            <div className="flex">
              <div className="font-bold text-2xl mb-8 w-[200px]">Reviews</div>
              <div className="h-9 items-center flex">
                <Icon src="star" />
                <div className="text-xl font-bold ml-1 mr-2">4.3</div>
                <div className="text-input-text">20 Reviews </div>
              </div>
              <div className="h-9 items-center flex ml-auto">
                <Button
                  isIcon
                  className="text-sm px-4 py-2 rounded-lg "
                  btnText={"See All Reviews"}
                />
              </div>
            </div>

            <div className="flex">
              <div className="w-[200px]">Review Details</div>
              <div className="w-[600px]">
                <div className="flex">
                  <div className="flex justify-start flex-wrap ">
                    <div className="w-[300px] flex mb-4">
                      <div className="w-28 mr-[67px]">Accuracy</div>
                      <div className="flex items-center font-bold">
                        <Icon src="star" className="mr-1 w-[14px] h-[14px]" />
                        <div>4.3</div>
                      </div>
                    </div>
                    <div className="w-[300px] flex mb-4">
                      <div className="w-28 mr-[67px]">Cleanliness</div>
                      <div className="flex items-center font-bold">
                        <Icon src="star" className="mr-1 w-[14px] h-[14px]" />
                        <div>4.5</div>
                      </div>
                    </div>
                    <div className="w-[300px] flex">
                      <div className="w-28 mr-[67px]">Cleanliness</div>
                      <div className="flex items-center font-bold">
                        <Icon src="star" className="mr-1 w-[14px] h-[14px]" />
                        <div>4.5</div>
                      </div>
                    </div>
                    <div className="w-[300px] flex">
                      <div className="w-28 mr-[67px]">Cleanliness</div>
                      <div className="flex items-center font-bold">
                        <Icon src="star" className="mr-1 w-[14px] h-[14px]" />
                        <div>4.5</div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-4 text-[#9b9b9b]" />
            <div className="flex">
              <div className="w-12 h-12 bg-main-green"></div>
              <div className="flex flex-col justify-between ml-1 mr-4">
                <div>Stevenson</div>
                <div>
                  <div className="flex text-sm items-center w-32 justify-between">
                    <div className="flex items-center">
                      <Icon src="star" className="mr-1 w-3 h-3" />
                      <div>4.5</div>
                    </div>
                    <div className="text-xs">Nov 12, 2020</div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="w-[600px] leading-4 text-tiny">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat… Read More
              </div>
            </div>
            <hr className="my-4 text-[#9b9b9b]" />
          </div>

          <hr />

          <div className="py-8">
            <div className="font-bold text-2xl mb-8">
              Nearby Recommendations
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeDetails;
