import axios, { AxiosInstance } from 'axios';
import { removeCookie } from './cookie';
import { useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';

    export const requestToMustOutdoor: AxiosInstance = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}`, 
        withCredentials: true,
    })

    requestToMustOutdoor.interceptors.request.use(
        async (config:any) => {
        const token = localStorage.getItem("refresh");
        if(token) {
            config.headers.Authorization = token !== null ? "bearer " + token : ''
        }
        return config;
      }, 
      (error) => {
        console.log(error)
        Promise.reject(error);
    },
      );

      requestToMustOutdoor.interceptors.response.use(
        (response) => {
            return response;
        },
        async function (error) {
            const history = createBrowserHistory();
            if(error.response.data.code === "UA08") {
                alert("세션이 만료 되었습니다 다시 로그인 해주세요 !")
                localStorage.removeItem("hostNum")
                localStorage.removeItem("refresh")
                localStorage.removeItem("role")
                localStorage.removeItem("profile")
                localStorage.removeItem("isLogin")
                localStorage.removeItem("email")
                localStorage.removeItem("STATUS")
                localStorage.removeItem("name")
                localStorage.removeItem("country")
                localStorage.removeItem("hostPhone")
                localStorage.removeItem("joined")
                localStorage.removeItem("hC")
                localStorage.removeItem("type")
                localStorage.removeItem("m")
                localStorage.removeItem("hostName")
                localStorage.removeItem("mC")
                localStorage.removeItem("phone")
                localStorage.removeItem("general")
                localStorage.removeItem("location")
                localStorage.removeItem("gallery")
                localStorage.removeItem("policy")
                localStorage.removeItem("pricing")
                localStorage.removeItem("detail")
                localStorage.removeItem("CC")
                localStorage.removeItem("MV")
                localStorage.removeItem("itemNum")
                localStorage.removeItem("hostCountry")
                localStorage.removeItem("bizType")
                localStorage.removeItem("AC")
                localStorage.removeItem("AT")
                localStorage.removeItem("PG")
                localStorage.removeItem("itemStatus")
                localStorage.removeItem("currentType")
                sessionStorage.removeItem("nextPath");
                removeCookie("refreshToken")
                history.replace("/");
                window.location.reload();
            }
            return Promise.reject(error);
        },
    );

      
