//Kakao.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setCookie } from "utils/cookie";
import { useAppDispatch } from "redux/store";
import { hostInfo } from "redux/action/host";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import MoblieHeader from "components/moblieHeader";

const SocialAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let params = new URL(document.URL).searchParams;
  let code = params.get("code");

  const [error, setError] = useState(false);

  const handleEmailSetting = () => {
    window.open(
      "https://accounts.kakao.com/weblogin/account/info?app_type=web",
      "_blank",
      "noopener, noreferrer"
    );
  };

  useEffect(() => {
    requestToMustOutdoor({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}auth/kakao`,
      data: {
        code: code,
      },
    })
      .then((res) => {
        const value = res?.data.data;
        const { accessToken } = value;
        const { refreshToken } = value;
        const { id } = value;
        const { type } = value;

        const { email } = value;
        const { name } = value;
        const { role } = value;

        const { mobile } = value;
        const { mobileCountryCode } = value;
        const { mobileVerified } = value;

        const { hostName } = value;

        localStorage.setItem("STATUS", "MEMBER");
        localStorage.setItem("type", type);
        localStorage.setItem("email", email);
        localStorage.setItem("name", name);
        localStorage.setItem("role", role);
        localStorage.setItem("m", id);
        localStorage.setItem("isLogin", accessToken);
        if (mobile !== null || mobile !== undefined || mobile !== "undefined") {
          localStorage.setItem("phone", mobile);
        }
        if (mobileCountryCode !== null) {
          localStorage.setItem("CC", mobileCountryCode);
        }
        if (mobileVerified !== null) {
          localStorage.setItem("MV", mobileVerified);
        }
        if (hostName !== null) {
          localStorage.setItem("hostName", hostName);
        }
        if (refreshToken !== null || refreshToken !== undefined) {
          localStorage.setItem("refresh", refreshToken);
        }
        setCookie("refreshToken", refreshToken);

        const nextPage = sessionStorage.getItem("nextPath");

        if (nextPage) {
          navigate(`${nextPage}`, { replace: true });
        } else {
          navigate("/", { replace: true });
        }

        dispatch(hostInfo());
        return res.data.data;
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 403") {
          window.alert("다른방법으로 가입 되어있습니다. 로그인 해주세요");
          navigate("/log-in", { replace: true });
        } else if (err.code === "UA09") {
          alert(err.message);
          setError(true);
          // navigate("/sign-up", { replace: true });
          // window.open(
          //   "https://accounts.kakao.com/weblogin/account/info?app_type=web",
          //   "_blank",
          //   "noopener, noreferrer"
          // );
        }
      });
  }, []);

  return (
    <div className="w-full">
      <MoblieHeader center="Kakao Login" left />
      {error ? (
        <div
          className="cursor-pointer mx-4 my-4 font-bold p-4 bg-light-green text-pure-white rounded-lg inline-block"
          onClick={() => {
            handleEmailSetting();
          }}
        >
          카카오 이메일 설정 하기
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SocialAuth;
