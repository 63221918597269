import React, { useState } from "react";

import AuthUtil from "components/Auth/AuthUtil";
import HeadLogo from "components/Auth/authLogo";
import Button from "elements/Button";
import Input from "elements/Input";
import { useTranslation } from "react-i18next";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [pw,setPw] = useState("")
  const [newPw,setNewPw] = useState("")

  const email = localStorage.getItem("email");
  const changePw = () => {
    requestToMustOutdoor({
      method:"POST",
      url:"/auth/password",
      data:{
        checkPw: pw,
        email: email,
        newPw: newPw
      }
    })
    .then((res) => {
      alert('비밀번호가 변경 되었습니다. 다시 로그인 해주세요.')
      navigate('/', { replace: true})
      return res.data.data
    })
  }
  return (
    <div>
      <HeadLogo>
        <AuthUtil
          AuthIcon={"change-password"}
          title={"Reset your password"}
          content={`Password should have at least 8 characters long`}
        />
        <div className="flex flex-col">
          <Input
            isIcon
            inputIcon={"password"}
            placeholder={t("Account.New password")}
            onChange={(e) => {setPw(e.target.value)}}
            type={"password"}
          />
          <Input
            isIcon
            onChange={(e) => {setNewPw(e.target.value)}}
            inputIcon={"password"}
            placeholder={t("Account.Confirm New password")}
            type={"password"}
          />
        </div>
        <div className="text-base text-pure-white">
          <Button
            onClick={() => {changePw()}}
            className="inline-block mx-auto mt-2 mb-4 bg-light-green text-pure-white text-xs rounded-lg py-2 w-[320px]"
            btnText={t("Account.Save new password")}
          />
        </div>
      </HeadLogo>
    </div>
  );
};

export default ResetPassword;
