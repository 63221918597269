import React from "react";

import Button from "elements/Button";
import Card from "elements/Card";
import Icons from "elements/Icons";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { inputPriceComma } from "utils/commaDash";

interface wistListProps {
  wishList?: [];
}

const MemberWishList = ({ wishList }: wistListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const memberId = localStorage.getItem("m");

  const wishListIds = wishList?.map((item: any) => {
    const id = item.id;
    return id;
  });

  return (
    <div>
      {wishList?.length === 0 ? (
        <div className="mt-12 px-8 text-center max-w-[560px] mx-auto">
          <div className="flex flex-col items-center">
            <Icons iconName="no-wish-list" className="w-32 h-32" />
            <div className="mt-8 mb-4 font-bold">
              {t("Wish-list.Create and share your Wish List")}
            </div>
            <div className="text-sm mb-4">
              {t("Wish-list.Turning on the lamp")}
            </div>
            <Button
              onClick={() => {
                navigate("/");
              }}
              className="px-4 py-3 text-sm bg-light-green rounded-lg text-pure-white"
              btnText={t("Wish-list.Start Exploring")}
            />
          </div>
        </div>
      ) : (
        <div className="phone:px-4 web:px-0">
          <div className="flex web:flex-row phone:flex-col phone:items-center my-8 text-2xl justify-between">
            <div>{t("Wish-list.Private Ground")}</div>
            <div>{wishList?.length} / 30 WishLists</div>
          </div>
          <div className="web:h-[640px] phone:h-full web:w-full shadow-ms rounded-lg overflow-y-scroll">
            <div className="flex flex-wrap items-center justify-start phone:gap-4 web:gap-[18px] phone:px-2 web:px-0">
              {wishList?.map((item: any, i: number) => {
                const typeInfo = item.typeInfo;
                const id = item.id;
                const price = item.priceInfo;
                let arr = Object?.values(price);
                const toNum = arr.map((i) => Number(i));
                const noZero = toNum.filter((e) => e !== 0);
                const priceValue = Math.min.apply(null, noZero);
                const iconInfo = item?.locationThemes;
                return (
                  <div key={i} className="text-center">
                    <Card
                      noMarginBotton
                      memberId={Number(memberId)}
                      listingId={Number(id)}
                      wish={wishListIds?.includes(id) ? true : false}
                      onClick={() => {
                        navigate(`/details/${item.id}`, { state: item.id });
                      }}
                      country={item.country}
                      price={inputPriceComma(String(priceValue))}
                      image={item.image.url}
                      locationThemes={iconInfo}
                      state={t(`Kor-state.${item.state}`)}
                      title={item.title}
                      type={typeInfo}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberWishList;
