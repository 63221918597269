import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="web:my-20 web:px-48 phone:my-10 phone:px-2">
      <div className="border-2 border-light-grey web:p-10 phone:p-2">
        <span className="text-3xl font-bold">
          MUST OUTDOOR 개인정보 처리방침
        </span>
        <div className="h-1 py-5 border-t border-black-grey bg-pure-white w-full mt-0.5" />
        <div className="max-h-[1000px] box-border border-1 border-light-grey overflow-y-auto overflow-x-hidden">
          <div>
            <p className="text-xs">
              ‘(주)머스트 아웃도어’(이하 "회사"라 함)는 통신비밀보호법,
              전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등
              정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호
              규정을 준수하며, 회사는 개인이 `동의’ 버튼을 클릭하면 개인정보
              수집 및 이용 등 아래 개인정보 취급방침에 대해 동의한 것으로
              봅니다.
            </p>
          </div>
          <div>
            <h3>1. 개인정보 수집 및 이용목적</h3>
            <p>
              회사는 다음 각 호의 목적으로 회원의 개인정보를 수집 및 이용합니다.
            </p>
            <ol>
              <li>
                “회사”란 ㈜머스트 아웃도어가 재화 또는 용역을 이용자에게
                제공하기 위하여 컴퓨터, 휴대폰 등 정보통신 설비를 이용하여
                재화를 거래할 수 있도록 설정한 법인 또는 가상의 영업장을 말하며,
                아울러 인터넷 사이트를 운영하는 사업자의 의미로도 사용합니다.
              </li>
              <ol>
                가. 회원 가입 및 관리: 회원 가입의사 확인, 회원제 서비스 제공에
                따른 본인 식별 및 인증, 회원자격 유지·관리, 제한적 본인확인제
                시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지 및 통지 등
                목적
              </ol>
              <ol>
                나. 서비스의 제공: 콘텐츠 제공, 서비스(비식별화한 통계 데이터
                판매 서비스 포함)의 제공, 계약서 또는 청구서의 발송, 맞춤서비스
                제공, 본인인증, 연령인증, 요금결제, 정산, 채권추심 등의 목적
              </ol>
              <ol>
                다. 고충 처리: 민원인의 신원 확인, 민원사항 확인, 사실조사를
                위한 연락·통지, 처리결과 통보 등의 목적
              </ol>
              <ol>
                라. 마케팅 및 광고 활용: 신규 서비스(또는 상품) 개발 및 맞춤
                서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 맞춤형
                광고성 정보 제공 등의 목적
              </ol>
              <ol>
                마. 서비스의 개선: 서비스의 유효성 확인, 접속빈도 파악 또는
                회원의 서비스 이용에 대한 통계 등의 목적
              </ol>
            </ol>
          </div>
          <div>
            <h3>2. 수집하는 개인정보 항목 및 수집방법</h3>
            <p>회원(이용자 정보)</p>
            <table className="border-2 w-full text-xs">
              <thead>
                <tr>
                  <th className="border-light-grey">유형</th>
                  <th>수집 및 이용 항목</th>
                  <th>수집 및 이용 목적</th>
                  <th>보유기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>이용자식별(회원가입)</td>
                  <td>
                    [게스트] <br />
                    필수 : 이메일, 비밀번호, 이름
                    <br />
                    선택 : 성별, 생년월일, 주소, 연락처
                    <br />
                    [호스트]
                    <br />
                    필수 : 이름, 이메일, 비밀번호, 사업타입, 사업명
                  </td>
                  <td>
                    이용자의 식별 및 본인 확인
                    <br />
                    부정이용 확인·방지
                    <br />
                    회원 서비스 제공
                    <br />
                    서비스 이용에 대한 공지 및 상담
                    <br />
                    호스트 적격확인
                  </td>
                  <td>
                    회원탈퇴시까지
                    <br />
                    (단, 관계법령에 따름)
                  </td>
                </tr>
                <tr>
                  <td>주문/결제</td>
                  <td>
                    [결제수단 선택에 따른 수집]
                    <br />
                    · 신용카드 : 카드사명, 카드번호(일부) 등<br />
                    · 무통장입금 : 은행명, 입금자명
                    <br />
                    · 세금계산서 발급 정보 : 전자세금계산서 발급용 이메일,
                    <br /> 사업자등록번호, 사업자등록증 사본
                    <br />
                    (이름, 주소, 휴대전화, 이메일)
                  </td>
                  <td>
                    주문 상품의 결제 및 환불
                    <br />
                    계약사항 및 주문내역 안내 등 고지
                    <br />
                    부정이용 확인·방지 관리
                  </td>
                  <td>
                    회원탈퇴시까지
                    <br />
                    (단, 관계법령에 따름)
                  </td>
                </tr>
                <tr>
                  <td>환불/정산</td>
                  <td>예금주 성명, 은행명, 계좌번호, 주민등록번호</td>
                  <td>
                    주문 상품의 결제 취소 및 환불
                    <br />
                    호스트 예약완료 상품 매출 정산
                  </td>
                  <td>
                    회원탈퇴시까지
                    <br />
                    (단, 관계법령에 따름)
                  </td>
                </tr>
                <tr>
                  <td>서비스 이용기록</td>
                  <td>
                    IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용
                    기록
                  </td>
                  <td>
                    서비스 이용 기록 통계 분석 등 품질 관/소비자 보호를 위한
                    법적 의무 준수/부정 이용확인. <br />
                    방지관리/회원간 푼쟁 조정/상품(경품) 발송을 위한 발송정보
                    확인
                  </td>
                  <td>
                    회원탈퇴시까지
                    <br />
                    (단, 관계법령에 따름)
                    <br />
                    서비스 이용기록은 3개월 보관(통신비밀보호법)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <h3>3. 개인정보 처리 및 수집기간</h3>
          <p>
            회사는 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유
            및 이용기간 또는 법령에 따른 개인정보 보유 및 이용기간 내에서
            개인정보를 처리, 보유합니다. 고객의 개인정보는 회원탈퇴 등 수집 및
            이용목적이 달성되거나 동의철회 요청이 있는 경우 지체없이 파기됩니다.
            단, 「전자상거래 등에서의 소비자보호에 관한 법률」 등 관련법령의
            규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을
            이유로 일정기간 보유하여야 할 필요가 있을 경우에는 그 기간 동안
            보유합니다.
          </p>
          <ol>
            <ol>
              가. 「전자상거래 등에서의 소비자보호에 관한 법률」 제6조
              <br />
              계약 또는 청약 철회 등에 관한 기록 : 5년
              <br />
              대금결재 및 재화 등의 공급에 관한 기록 : 5년
              <br />
              소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
            </ol>
            <ol>
              나. 「통신비밀보호법」 제15조
              <br />
              방문(로그)에 관한 기록: 1년
            </ol>
            <ol>다. 기타 관련 법령 등</ol>
          </ol>
          <p>회사의 개인정보 파기 방법은 다음과 같습니다</p>
          <ol>
            <ol>
              가. 파기 절차
              <ol>
                (1) 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의
                DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 일정 기간 저장된
                후 파기됩니다.
              </ol>
              <ol>
                (2) 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의
                다른 목적으로 이용되지 않습니다.
              </ol>
            </ol>
            <ol>
              나. 파기 방법
              <ol>
                (1) 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는
                기술적 방법을 사용하여 삭제합니다.
              </ol>
              <ol>
                (2) 종이에 출력된 개인정보가 있는 경우 분쇄기로 분쇄하거나
                소각을 통하여 파기합니다.
              </ol>
              <p>
                회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제29조
                제2항에 따라 휴면 회원(최근 12개월 동안 서비스를 이용하지 아니한
                회원)에 대해 회원자격 상실에 대한 안내문을 통지하고, 안내문에서
                정한 기한 내에 답변이 없을 경우 회원자격을 상실시킬 수 있습니다.
                이 경우, 휴면 회원의 개인정보는 다른 회원의 개인정보와 분리하여
                별도로 저장·관리 되며, 분리 저장된 개인정보는 법정보관기간 경과
                후 파기하고 있습니다. 파기되지 않은 개인 정보 중 해당 이용자의
                요청이 있는 경우 고객 정보는 서비스 이용을 재개하는 시점에 다시
                제공됩니다.
              </p>
            </ol>
          </ol>
        </div>
        <div>
          <h3>4. 개인정보 제 3자제공</h3>
          <ol>
            <ol>
              가. 회사는 회원의 개인정보를 제2조에 고지한 ‘개인정보의 수집 및
              이용목적” 범위 내에서 사용하며, 회원의 사전 동의 없이는 동 범위를
              초과하여 이용하거나 원칙적으로 회원의 개인정보를 외부에 공개하거나
              제3자에게 제공하지 않습니다. 다만, 다음 각 호의 경우는 예외로
              합니다.
              <ol>(1) 회원이 사전에 동의한 경우</ol>
              <ol>(2) 서비스 제공에 따른 요금정산을 위해 필요한 경우</ol>
              <ol>
                (3) 통계작성 목적으로 특정 개인을 알아볼 수 없는 형태로
                개인정보를 가공하여 제공하는 경우
              </ol>
              <ol>
                (4) 기타 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진
                절차와 방법에 따라 수사기관의 요구가 있는 경우
              </ol>
            </ol>
          </ol>
          <ol>
            <ol>
              나. 회사가 제공하는 서비스를 통하여 방문신청 혹은 결제가 이루어진
              경우 문의 및 상담 등 거래 당사자간 원활한 의사소통 및 서비스
              운영을 위하여 관련된 정보를 필요한 범위 내에서 거래 당사자에게
              제공합니다. 회사는 제공하는 서비스를 이용하는 호스트 및/또는
              게스트의 개인정보를 각 게스트 및 /또는 호스트에게 아래와 같이
              제공합니다. 회원은 개인정보 제3자 제공 동의를 거부할 권리가
              있으며, 제3자 제공 동의 여부와 관계없이 회원에 가입할 수 있습니다.
              단, 개인정보 제3자 제공 동의 거부 시, 아래 이용목적에 명시된
              서비스를 받으실 수 없습니다.
              <p>
                ▶ 호스트(회사가 제공하는 서비스 내 상품을 등록하고 게스트에게
                제공하는 회원)가 정보를 제공받는 경우
              </p>
              <ol>제공받는 자 : 호스트</ol>
              <ol>
                제공하는 항목 : 이름, 성별, 나이, 연락처 등 개별 서비스별로
                필요하여 게스트 회원이 입력한 정보
              </ol>
              <ol>
                제공받는 자의 이용목적 : 본인 확인, 서비스 준비 및 이행,
                민원처리 등 서비스 이행을 위해 필요한 업무
              </ol>
              <ol>개인정보 보유 및 이용 기간: 회원탈퇴시</ol>
              <p>
                ▶ 게스트(회사가 제공하는 서비스 내에서 호스트가 제공하는 상품을
                예약하는 회원)가 정보를 제공받는 경우
              </p>
              <ol>제공받는 자 : 게스트</ol>
              <ol>
                제공하는 항목 : 이름, 연락처, 주소 등 개별 서비스별로 필요하여
                호스트 회원이 입력한 정보
              </ol>
              <ol>
                제공받는 자의 이용목적 : 본인 확인, 이용문의를 위해 필요한 업무
              </ol>
              <ol>개인정보 보유 및 이용 기간: 회원탈퇴시</ol>
            </ol>
          </ol>
        </div>
        <div>
          <h3>5. 개인정보 취급 위탁</h3>
          <p>
            회사는 서비스 향상 및 원활한 개인정보 업무처리를 위하여 개인정보
            처리업무 중 일부를 외부전문기관에 위탁할 수 있으며, 관계 법령에 따라
            위탁계약 시 개인정보가 안전하게 관리될 수 있도록 합니다.
            <br />
            [취급위탁현황]
            <br />
            업체명 : ㈜아임포트
            <br />
            제공항목 : 이름, 전화번호, 카드명, 카드번호
            <br />
            제공받는 자의 이용목적 : 결제처리
            <br />
            보유 및 이용기간 : 회원탈퇴, 서비스 종료 혹은 위탁계약 종료 시.
            <br />
            회사는 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무
            목적 및 범위, 위탁업무 수행목적 외 개인정보 처리금지, 재위탁 제한,
            수탁자에 대한 관리, 감독, 안정성 확보 조치 및 책임에 관한 사항을
            문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를
            감독합니다.
          </p>
        </div>
        <div>
          <h3>6. 개인정보 파기절차 및 방법</h3>
          <p>
            회원의 개인정보는 제1조 개인정보 수집 및 이용목적 달성 또는 사업의
            종료하는 경우 등 개인정보가 불필요하게 되었을 때 지체없이
            파기합니다. 개인정보 파기절차 및 방법은 다음과 같습니다.
            <br />
            ▶ 전자적 파일 : 개인정보 기록을 재생할 수 없는 방법으로 영구적 삭제
            <br />▶ 종이 문서 : 파쇄 또는 소각하여 파기
          </p>
        </div>
        <div>
          <h3>7. 고객과 법정대리인의 권리 및 그 행사방법</h3>
          <p>회원은 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.</p>
          <ol>
            <ol>
              가. 회원은 주식회사 MUST OUTDOOR에 대해 언제든지 다음 각 호의
              개인정보 보호 관련 권리를 행사할 수 있으며, 고객이 만 14세 미만의
              아동인 경우 그 아동의 법정대리인은 아동의 개인정보에 대한 열람,
              정정, 삭제 및 처리정지를 요청할 수 있습니다
              <p>
                ▶ 개인정보 열람요구
                <br />
                ▶ 오류 등이 있을 경우 정정 요구
                <br />
                ▶ 삭제요구
                <br />▶ 처리정지 요구
              </p>
            </ol>
            <ol>
              나. 개인정보 열람 및 정정을 하고자 할 경우에는 홈페이지 『내
              프로필』 항목을 클릭하여 직접 열람 또는 정정하거나, 고객센터로
              서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
              또한, 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해
              고객께서 동의하신 내용을 고객께서는 언제든지 철회하실 수 있습니다.
              동의 철회를 하고 개인정보를 파기하는 등의 조치를 취한 경우에는 그
              사실을 고객께 지체 없이 통지하도록 하겠습니다.
            </ol>
          </ol>
        </div>
        <div>
          <h3>8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h3>
          <p>
            회사는 개인화된 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를
            저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는
            웹사이트를 운영하는데 이용되는 서버가 회원의 브라우저에게 보내는
            아주 작은 텍스트 파일로 이용자 단말기(PC, 태블릿 PC, 스마트폰 등)의
            하드디스크에 저장됩니다.
          </p>
          <ol>
            <ol>
              가. 쿠키의 사용 목적
              <p>
                회원들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 방문 및
                이용형태, 인기 검색어, 이용자 규모, 보안접속 여부 등을 파악하여
                회원에게 최적화된 정보 제공을 위하여 사용합니다.
              </p>
            </ol>
            <ol>
              나. 쿠키의 설치/운영 및 거부
              <p>
                회원은 쿠키 설치에 대한 선택권을 가지고 있으며, 이를 거부할 수
                있습니다. 다만, 쿠키의 자장을 거부할 경우에는 맞춤형 서비스와
                같은 일부 서비스 이용에 어려움이 있을 수 있습니다.
                <br />
                ▶ 쿠키 설치 허용 여부를 지정하는 방법(Internet Exploer의 경우)
                <br />
                웹브라우저 상단의 도구 - 인터넷 옵션 - 개인정보 메뉴의 옵션
                설정을 통해 쿠키 저장 거부
              </p>
            </ol>
          </ol>
        </div>
        <div>
          <h3>9. 개인정보 보호를 위한 기술적/관리적 조치</h3>
          <p>
            회사는 개인화된 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를
            저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는
            웹사이트를 운영하는데 이용되는 서버가 회원의 브라우저에게 보내는
            아주 작은 텍스트 파일로 이용자 단말기(PC, 태블릿 PC, 스마트폰 등)의
            하드디스크에 저장됩니다.
          </p>
          <ol>
            <ol>
              가. 암호화 보관 : 회사 회원 아이디(ID)의 비밀번호는 암호화되어
              저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및
              변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
            </ol>
            <ol>
              나. 취급직원의 최소화 및 교육 : 회사는 개인정보에 대한 접근권한을
              개인정보관리책임자 등 개인정보관리업무를 수행하는 자, 기타 업무상
              개인정보의 취급이 불가피한 자로 제한하며, 개인정보를 취급하는
              직원에 대한 수시 교육을 통하여 개인정보취급방침의 준수를 항상
              강조하고 있습니다.
            </ol>
            <ol>
              다. 안전한 관리 : 침입차단시스템, 위협 탐지 시스템을 이용하여
              해킹이나 바이러스 등으로부터 개인정보의 유출과 훼손을 막기 위해
              노력하고 있습니다. 최신 백신 프로그램을 이용하여 회원들의
              개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있으며,
              암호화 통신 등을 통해 네트워크상에서 개인정보를 안전하게
              송수신하고 있습니다
            </ol>
          </ol>
          <p>
            회원 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등
            개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지
            않습니다. 따라서, 회원의 아이디와 비밀번호는 반드시 본인만
            사용하시고, 비밀번호를 자주 바꿔주시는 것이 좋으며, 비밀번호는
            영문자, 숫자를 혼합하여 타인이 유추하기 어려운 번호를 사용하는 것이
            좋습니다.
          </p>
        </div>
        <div>
          <h3>10. 개인정보 보호 관리부서</h3>
          <p>
            회원은 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련
            민원을 다음 담당부서에 신고하실 수 있습니다. 회사는 회원들의
            신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
            <br />
            개인정보관리 책임부서
            <br />
            이메일 : privacy@mustoutdoor.com
          </p>
          <p>
            기타 개인정보 침해 신고나 상담이 필요하신 경우에는 아래 기관에 문의
            가능합니다.
            <br />
            ▶ 개인정보침해 신고센터 : (국번없이) 118 / (웹사이트:
            privacy.kisa.or.kr)
            <br />
            ▶ 대검찰청 사이버범죄수사단 : (국번없이) 1301 / (웹사이트:
            www.spo.go.kr)
            <br />▶ 경찰청 사이버안전국 : (국번없이) 182 / (웹사이트:
            cyberbureau.police.go.kr)
          </p>
        </div>
        <div>
          <h3>11. 개인정보 처리방침 변경 및 고지</h3>
          <ol>
            <ol>
              가. 회사는 현행 개인정보처리방침에 대한 내용 추가, 삭제 및 수정이
              있을 시에는 개정 최소 7일 전부터 회사가 관리·운영하는
              전자게시판(애플리케이션 내 게시판 등을 포함합니다)의 '공지사항'을
              통해 변경이유 및 그 내용을 고지합니다. 다만, 개인정보의 수집 및
              활용 등에 있어 회원 권리의 중요한 변경이 있을 경우에는 최소 30일
              전에 고지합니다.
            </ol>
            <ol>
              나. 회사는 회원이 동의한 범위를 넘어 회원의 개인정보를 이용하거나
              제3자에게 취급위탁하기 위해 회원의 추가적인 동의를 얻고자 하는
              때에는 미리 회원에게 개별적으로 서면, 메일, 전화 등으로 해당사항을
              고지합니다.
            </ol>
            <ol>
              다. 회사는 개인정보의 수집, 보관, 처리, 이용, 제공, 관리, 파기
              등을 제3자에게 위탁하는 경우에는 서비스약관, 개인정보처리방침 등을
              통하여 그 사실을 회원에게 고지합니다.
            </ol>
          </ol>
        </div>
        <div>
          <p>
            이 개인정보 처리방침의 공고 및 시행일자는 다음과 같습니다.
            <br />
            • 공고일자 : 2022년 5월 4일
            <br />• 시행일자 : 2022년 7월 4일
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
