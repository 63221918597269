/* global kakao */

import Button from "elements/Button";
import React, { useEffect, useState } from "react";

declare global {
  interface Window {
    kakao: any;
  }
}

const KakaoMap = ({ AddInfo }: any) => {
  const { kakao }: any = window;
  useEffect(() => {
    const Lat = AddInfo?.lat !== 0 ? AddInfo?.lat : 37.496486063;
    const Lng = AddInfo?.lng !== 0 ? AddInfo?.lng : 127.028361548;
    window.kakao.maps.load(() => {
        const mapContainer = document.getElementById("map");
        const mapOption = {
          center: new window.kakao.maps.LatLng(Lat, Lng), // 지도의 중심좌표
          level: 4, // 지도의 확대 레벨
        };
        const map = new window.kakao.maps.Map(mapContainer, mapOption);
        map.relayout();
        let markerPosition = new window.kakao.maps.LatLng(
          AddInfo?.lat,
          AddInfo?.lng
        );

        let marker = new window.kakao.maps.Marker({
          position: markerPosition,
        });
        marker.setMap(map);
        map.setZoomable(false);
    });
  }, [AddInfo?.lat, AddInfo?.lng]);

  return (
    <div className="my-4 web:w-160 web:h-[500px] phone:min-w-[136px] max-w-[991px] phone:w-full phone:h-64">
      <div id="map" className="w-full h-full" />
    </div>
  );
};

export default KakaoMap;
