import React from "react";
import { useTranslation } from "react-i18next";

const HowItWorks = () => {
  const { t } = useTranslation()
  const travelerTab = [
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/how-search.svg",
      title: "Search Outdoor Life",
      subTitle:
        "Search for the outdoor life you want based on",
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/how-booking.svg",
      title: "Booking Request",
      subTitle:
        "Request booking to the host easily and simply",
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/how-enjoy.svg",
      title: "Special Experience",
      subTitle:
        "Explorer a more special experience of ordinary daily life",
    },
  ];

  const hostTab = [
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/how-hosting-start.svg",
      title: "Start Hosting",
      subTitle:
        "Anyone can become hosting for free",
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/how-accept.svg",
      title: "Accept Booking",
      subTitle:
        "First, check traveler's booking request",
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/how-extra-income.svg",
      title: "Special Experience",
      subTitle:
        "Generate extra profits from unused outdoor space",
    },
  ];

  const outDoorTab = [
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/images/motorhome-g718b3d4a6_1920.jpg",
      title: "Private Ground",
      subTitle:
        "Hosts share unused outdoor space on private property"
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/images/istockphoto-1197182041-612x612.jpeg",
      title: "Outdoor Accommodation",
      subTitle: "It has four categories: Auto Camping, Glamping, Caravan, and Eco-friendly accommodation"
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/images/surf-gdc30a66c8_640.jpg",
      title: "Outdoor Activity",
      subTitle:
        "It is divided into five categories: Water, Aviation, Mountain, Ground, and Winter Activities"
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/images/mountains-gc299e5094_1920.jpg",
      title: "Local Theme",
      subTitle:
        "You can easily find the type of visit you want through local themes",
    },
  ];
  const outDoorLifeTab = [
      {
        icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/destinations.png",
        title: "Discovery & Explore",
        subTitle:
          "Find and explore new local areas",
      },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/tourism.png",
      title: "Local Experience",
      subTitle:
        "When visiting an outdoor space or private ground, experience local life",
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/adventure-sports.png",
      title: "Fully Enjoy",
      subTitle:
        "Book activities you can enjoy",
    }
  ];

  let girl =
    "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/images/girl-6746028_1920.jpg";
  let fields =
    "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/images/fields-g7a47edfc7_1920.jpg";
  let landscape =
    "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/images/landscape-g708fa5667_1920.jpg";

  return (
    <div className="w-full">
      <div
        style={{ backgroundImage: `url(${girl})` }}
        className={`w-full web:h-[640px] phone:h-52 bg-center bg-cover text-pure-white`}
      >
        <div className="flex flex-col web:px-32 phone:px-8 font-bold justify-center h-full items-end">
          <div className="web:text-5xl phone:text-xl`">{t("How-it-works.How It Works")}</div>
          <div className="web:text-2xl phone:text-sm web:mt-8 phone:mt-2`">
          {t("How-it-works.Explore Outdoor Life with MUST OUTDOOR")}
          </div>
        </div>
      </div>
      <div className="py-16">
        <div className="text-center text-2xl font-bold mb-16">
          {t("How-it-works.For Traveler")}
        </div>
        <div className="flex web:flex-row phone:flex-col justify-center">
          {travelerTab.map((item, idx) => {
            return (
              <div
                key={idx}
                className="items-center phone:mx-auto web:mx-0 text-center web:mr-7 phone:mr-0 phone:mb-8 phone:last:mb-0 web:last:mr-0 web:w-[365px] phone:w-full"
              >
                <img className="w-16 h-16 mx-auto" src={item.icon} alt="" />
                <div className="my-4 text-xl font-bold">{t(`How-it-works.${item.title}`)}</div>
                <div>
                {t(`How-it-works.${item.subTitle}`)}
                </div>
              </div>
            );
          })}
        </div>
        <div className="text-center text-2xl font-bold my-16">
          {t(`How-it-works.For Host`)}
        </div>
        <div className="flex web:flex-row phone:flex-col justify-center">
          {hostTab.map((item, idx) => {
            return (
              <div
                key={idx}
                className="items-center phone:mx-auto web:mx-0 text-center web:mr-7 phone:mr-0 phone:mb-8 phone:last:mb-0 web:last:mr-0 web:mb-0 web:w-[365px] phone:w-full"
              >
                <img className="w-16 h-16 mx-auto" src={item.icon} alt="" />
                <div className="my-4 text-xl font-bold">{t(`How-it-works.${item.title}`)}</div>
                {t(`How-it-works.${item.subTitle}`)}
                <div></div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${fields})` }}
        className="w-full web:h-80 phone:h-28 bg-cover bg-center"
      />
      <div className="py-16 text-center">
        <div className="text-2xl font-bold">
        {t(`How-it-works.Outdoor Life that MUST OUTDOOR provides`)}
        </div>
        <div className="inline-block mx-auto items-center mt-16">
            {outDoorTab.map((item, idx) => {
                return (
                    <div key={idx} className="flex web:flex-row phone:flex-col mb-12">
                        <img src={item.icon} className="w-64 h-48 rounded-lg phone:mx-auto web:mx-0" alt="" />
                        <div className="web:ml-8 phone:ml-0 phone:mt-4 flex flex-col justify-around phone:items-center web:items-start text-left">
                            <div className="font-bold text-xl web:mb-0 phone:mb-4">
                            {t(`How-it-works.${item.title}`)}
                            </div>
                            <div className="phone:w-[328px] web:w-full">
                            {t(`How-it-works.${item.subTitle}`)}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${landscape})` }}
        className="w-full web:h-80 phone:h-28 bg-cover bg-center"
      />
      <div className="py-16 text-center">
        <div className="text-2xl font-bold">
        MUST OUTDOOR와 함께 아웃도어 라이프를 더욱 즐겁게
        </div>
        <div className="mx-auto mt-16 flex items-center web:flex-row phone:flex-col justify-center">
            {outDoorLifeTab.map((item, idx) => {
                return (
                    <div key={idx} className="w-80 h-96 py-8 font-bold rounded-2xl shadow-selectCard web:mr-12 web:mb-0 phone:mb-9 last:mb-0 last:mr-0">
                        <div className="text-2xl">
                        {t(`How-it-works.${item.title}`)}
                        </div>
                        <img src={item.icon} className="h-32 w-32 mx-auto my-8" alt="" />
                        <div>
                        {t(`How-it-works.${item.subTitle}`)}
                        </div>
                    </div>
                            
                )
            })}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
