import React from 'react';
import ComingSoon from 'elements/comingSoon';

const hostTransactionHistory = () => {
    return (
        <div className="phone:px-4 web:px-0">
            <ComingSoon/>
        </div>
    );
};

export default hostTransactionHistory;