import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import MoblieHeader from "components/moblieHeader";

import GeneralInfo from "./generalInfo";
import LocationSetting from "./locationSetting";
import GroundGallery from "./groundGallery";
import GroundDetails from "./groundDetail";
import Pricing from "./pricing";
import Policy from "./policy";

import { useNavigate } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useDidMountEffect } from "hooks/useDidMountEffect";

import NewModal from "elements/newModal";

const AddPrivateGround = () =>  {
  const { t } = useTranslation();
  const [registModal, setRegistModal] = useState(false);
  const [blankModal, setBlankModal] = useState(false);
  const navigate = useNavigate();
  const [tabNum, setTabNum] = useState(0);

  const [generalData, setGeneralData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [gallleryData, setGalleryData] = useState<any>([]);
  const [detailsData, setDetailsData] = useState([]);
  const [priceData, setPriceData] = useState<any>([]);
  const [policyData, setPolicyData] = useState([]);

  const hostNumber = localStorage.getItem("hostNum");
  const hostId = Number(hostNumber);

  const extraOption =
    priceData?.extraOption !== undefined ? priceData?.extraOption : [];
  const priceInfo = priceData?.priceInfo;

  const data_ = {
    ...generalData,
    ...locationData,
    currentImage: gallleryData?.currentImage,
    images: gallleryData?.images,
    ...detailsData,
    // ...priceData,
    extraOption:
      extraOption[0]?.title !== "" &&
      extraOption[0]?.paymentMethod !== "" &&
      extraOption[0]?.description !== ""
        ? extraOption
          ? extraOption
          : ""
        : [],
    priceInfo,
    ...policyData,
    hostId,
  };

  console.log("data_", data_);

  const tabTitle = [
    { id: 0, title: "General Info" },
    { id: 1, title: "Location Setting" },
    { id: 2, title: "Ground Gallery" },
    { id: 3, title: "Ground Details" },
    { id: 4, title: "Operation" },
    { id: 5, title: "Policy" },
  ];

  const Submit = async () => {
    try {
      requestToMustOutdoor
        .post("/api/v2/private-ground/register", data_)
        .then((res) => {
          setRegistModal(true);
        })
        .catch((err) => {
          setBlankModal(true);
          return err;
        });
    } catch {}
  };

  useDidMountEffect(() => {
    Submit();
  }, [policyData]);

  const handleOnNext = () => {
    setTabNum(tabNum + 1);
    window.scrollTo(0, 0);
  };

  // 새로고침 막기
  useEffect(() => {
    // 새로고침 막기(조건 부여 가능)
    window.onbeforeunload = function () {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <div className="web:text-center phone:text-left web:p-8 mx-auto lg:max-w-1216 phone:max-w-[1023px]">
      <MoblieHeader left center="Add-private-ground.Add Private Ground" />
      <div className="web:flex ">
        <HostAccount />
        <HostAccountHeader
          titleIcon="outdoor-private-ground"
          title="Add-private-ground.Add Private Ground"
        >
          <div
            className="border-b h-11 flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll"
          >
            {tabTitle.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={`
                  ${
                    item.id === tabNum
                      ? "bg-light-green text-pure-white"
                      : " text-input-text"
                  } px-4 items-center flex focus:bg-light-green rounded-t-lg`}
                >
                  {t(`Add-private-ground.${item.title}`)}
                </div>
              );
            })}
          </div>
          {registModal ? (
            <NewModal
              onClick={() => {
                localStorage.setItem("currentType", "PG")
                navigate("/host/myprivate", { replace: true });
              }}
              setModalOpen={() => {
                setRegistModal(!registModal);
              }}
              imageUrl="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/register-completed-color.svg"
              title={t(
                "Add-private-ground.Completed to add Private Ground registration!"
              )}
              subTitle={
                t(
                  "Add-private-ground.Go My Private Ground and check your registered private ground."
                ) +
                "\n" +
                t(
                  "Add-private-ground.Your registered space will be screening and authorized"
                ) +
                "\n"
              }
              buttonText={t("Add-private-ground.Go to My Private Ground")}
              buttonColor={`bg-light-green text-pure-white`}
            />
          ) : (
            ""
          )}
          {blankModal ? (
            <NewModal
              onClick={() => {
                setBlankModal(false);
              }}
              setModalOpen={() => {
                setBlankModal(!blankModal);
              }}
              imageUrl="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-red.svg"
              title={t(
                "Add-private-ground.Please Fill Out All Required Information"
              )}
              buttonText={t("Add-private-ground.Continue to fill out")}
              buttonColor={`bg-light-green text-pure-white`}
            >
              <div className="mt-4">
                {t(
                  "Add-private-ground.You cannot register your Private Ground unless you complete"
                )}
              </div>
            </NewModal>
          ) : (
            ""
          )}
          {tabNum === 0 ? (
            <GeneralInfo
              privateData={data_}
              generalData={setGeneralData}
              onNext={() => {
                handleOnNext();
              }}
            />
          ) : tabNum === 1 ? (
            <LocationSetting
              locationData={setLocationData}
              onNext={() => {
                handleOnNext();
              }}
              onPre={() => {
                setTabNum(tabNum - 1);
                window.scrollTo(0, 0);
              }}
            />
          ) : tabNum === 2 ? (
            <GroundGallery
              galleryData={setGalleryData}
              onNext={() => {
                handleOnNext();
              }}
              onPre={() => {
                setTabNum(tabNum - 1);
                window.scrollTo(0, 0);
              }}
            />
          ) : tabNum === 3 ? (
            <GroundDetails
              DetailsData={setDetailsData}
              onNext={() => {
                handleOnNext();
              }}
              onPre={() => {
                setTabNum(tabNum - 1);
                window.scrollTo(0, 0);
              }}
            />
          ) : tabNum === 4 ? (
            <Pricing
              PriceData={setPriceData}
              onNext={() => {
                handleOnNext();
              }}
              onPre={() => {
                setTabNum(tabNum - 1);
                window.scrollTo(0, 0);
              }}
            />
          ) : tabNum === 5 ? (
            <Policy
              PolicyData={setPolicyData}
              onChange={(e) => {
                setPolicyData(e.target.value);
              }}
              onPre={() => {
                setTabNum(tabNum - 1);
                window.scrollTo(0, 0);
              }}
              onSubmit={() => {
                Submit();
              }}
            />
          ) : (
            ""
          )}
        </HostAccountHeader>
      </div>
    </div>
  );
};

export default AddPrivateGround;
