import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import HostAccount from "components/HostAccount/hostAccount";
import ComingSoon from "elements/comingSoon";
import MoblieHeader from "components/moblieHeader";
import { useTranslation } from "react-i18next";
import MemberWishList from "components/Member/memberWishList";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { isMember } from "lib/isMember";

const WishList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [wishList, setWishList] = useState<any>([]);

  const [tabNum, setTabNum] = useState(0);
  const tabInfo = [
    { id: 0, title: "Private Ground" },
    { id: 1, title: "Accommodation" },
    { id: 2, title: "Activity" },
  ];
  const ChangeTab = (id: number) => {
    setTabNum(id);
  };

  const memberId = localStorage.getItem("m");

  const getCart = useCallback(() => {
    if (!isMember()) {
      alert("로그인이 되어있어야 이용 가능한 서비스 입니다 :)");
      navigate("/log-in");
    } else {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v1/cart/private-ground/${memberId}`,
      }).then((res) => {
        const data = res.data.data;
        setWishList(data);
        return data;
      });
    }
  }, [memberId]);

  useEffect(() => {
    getCart();
  }, [memberId]);

  return (
    <div>
      <MoblieHeader left center="Menu.Wish List" centerTitleImage="wishlist-black" />
      <div className="text-center mx-auto web:pt-8 web:px-8 phone:px-0 phone:pt-0 pb-[100px] lg:max-w-1216">
        <div className="flex">
          <HostAccount traveler />
          <HostAccountHeader titleIcon="wishlist-black" title="Menu.Wish List">
            <div
              className="border-b w-full h-11 flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll"
            >
              {tabInfo.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      ChangeTab(item.id);
                    }}
                    className={`${
                      item.id === tabNum
                        ? "bg-light-green text-pure-white"
                        : " text-input-text"
                    } cursor-pointer px-4 items-center flex hover:bg-light-green focus:bg-light-green rounded-t-lg hover:text-pure-white`}
                  >
                    {t(`Wish-list.${item.title}`)}
                  </div>
                );
              })}
            </div>
            {tabNum === 0 ? (
              <MemberWishList wishList={wishList} />
            ) : tabNum === 1 ? (
              <div className="text-center text-sm">
                <ComingSoon />
              </div>
            ) : tabNum === 2 ? (
              <div className="text-center text-sm">
                <ComingSoon />
              </div>
            ) : (
              ""
            )}
          </HostAccountHeader>
        </div>
      </div>
    </div>
  );
};

export default WishList;
