import Button from "elements/Button";
import InquiryModal from "elements/InquiryModal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

interface MemberInquiryCardProps {
  answered?: string;
  content?: string;
  memeberName?: string;
  answer?: string;
  hostImage?: string;
  createdAt?: any;
  onClick?: () => void;
  onRespond?: any;
  respond?: boolean;
  respondInput?: boolean;
  hostId?: number;
  updatedAt?: string;
  id?: number;
  memberId?: number;
}

const MemberInquiryCard = ({
  answered,
  content,
  memeberName,
  createdAt,
  onClick,
  hostImage,
  onRespond,
  updatedAt,
  answer,
  respond,
  respondInput,
  hostId,
  id,
  memberId,
}: MemberInquiryCardProps) => {
  const { t } = useTranslation();
  const [inquiryValue, setInquiryValue] = useState("");
  const [inquiryModal, setInquiryModal] = useState(false);

  const deleteInquiry = () => {
    requestToMustOutdoor({
      method: "DELETE",
      url: `/api/v1/qna/${id}`,
    }).then((res) => {
      const data = res.data.data;
      window.location.reload();
      return data;
    });
  };

  return (
    <tbody>
      <tr>
        <td className="p-4 text-sm web:w-[600px] phone:w-[139px]">
          <div>
            <div className="flex items-center justify-between w-full">
              {answered ? (
                <td className="text-light-green font-bold text-sm">
                  {t("Message-box.Answered")}
                </td>
              ) : (
                <td className="text-tan font-bold text-sm">
                  {t("Message-box.Unanswered")}
                </td>
              )}
              <td className="text-sm">{createdAt}</td>
            </div>
            <div>
              <div className="my-2">
                {t("Message-box.Traveler inquiry from {{product_title}}", {
                  product_title: "Product_title",
                })}
              </div>
              <div className={`mb-2 font-bold`}>{memeberName}</div>
            </div>
          </div>
          <div onClick={onClick} className="cursor-pointer">
            {content}
          </div>
          {respond && answer ? (
            <div>
              <div
                onClick={() => {
                  deleteInquiry();
                }}
                className="text-pure-red text-sm my-2 inline-block"
              >
                {t("Message-box.Delete")}
              </div>
              <div className="w-full bg-border-light p-2 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>{t("Message-box.Host Response")}</div>
                  <div>{updatedAt}</div>
                </div>
                <div className="font-bold my-2">PgTitle</div>
                <div className="text-sm">{answer}</div>
              </div>
            </div>
          ) : (
            ""
          )}
        </td>
      </tr>
    </tbody>
  );
};

export default MemberInquiryCard;
