import GoogleMap from "components/GoogleMap";
import React from "react";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="relative">
        <img
          className="w-full h-80"
          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/Contact-support-service.png"
          alt=""
        />
        <div className="text-4xl -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-pure-white absolute">
          {t("ContactUs.CONTACT SUPPORT SERVICE")}
        </div>
      </div>
      <div className="flex gap-10 mx-auto justify-center pb-16">
        <div className="bg-pure-white border border-input-border-color py-8 text-center w-137 px-4">
          <div className="text-2xl font-bold mb-8">
            {t("ContactUs.Please feel free to contact us")}
          </div>
          <div className="whitespace-normal">
            {t("ContactUs.We would be happy to answer your question")}
          </div>
          <div className="text-2xl font-bold my-8">
            {t("ContactUs.Business Hour of customer service")}
          </div>
          <div>{t("ContactUs.Mon to Fri")}</div>
        </div>
        <div className="bg-pure-white border border-input-border-color p-4 w-137 relative">
          <div className="mb-8">
            <div className="font-bold text-2xl mb-2">Email</div>
            <div>support@mustoutdoor.com</div>
          </div>
          <div className="mb-8">
            <div className="font-bold text-2xl mb-2">Direction</div>
            <div>
              {
                "MUST OUTDOOR\nUnit 216(2F)\n415, Teheran-ro, Gangnam-gu, Seoul\nRepublic of Korea 06160"
              }
            </div>
          </div>
          <div className="h-[361px]"></div>
          <div className="absolute w-137 h-[375px] left-0 bottom-0">
            <GoogleMap
              mapLocation={{ lng: 127.03001913095, lat: 37.492129011067 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
