import React from "react";
import { useTranslation } from "react-i18next";


interface faqCardProps {
  onClick?: () => void;
  title?: string;
  content?: string;
  faqSize?: boolean;
  className?: string;
}

const FaqCard = ({ onClick, title, content, faqSize, className }: faqCardProps) => {
  const {t} = useTranslation();
  return (
    <div
    onClick={onClick}
      className={`${
        "w-full"
      } ${className} cursor-pointer shadow-card rounded-lg text-left p-4`}
    >
      <div className={`text-xl font-bold ${content ? "mb-4" : ""}`}>
        {t(`faq.${title}`)}
      </div>
      <div>{content}</div>
    </div>
  );
};

export default FaqCard;
