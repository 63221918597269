import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  // 기본 정보
  General: {
      title: string,
      description: string,
      typeId:number,
      unitCount: number | any,
      themeIds:any[],
      accessibleVehicle:string,
      spaceSize:string,
    }
    // 위치 설정
  Location: {
    country: string,
      state: string,
      city: string,
      zipCode: string,
      extraAddress: string,
      direction: string,
      nearbyFacilities: [],
      touristSpots: any,
      indicator: any,
      address: string,
  },
    //   공간 이미지
  Gallery: {
    currentImage: [],
    imageList: []
  },
    //   공간 상세정보
  Details: {
    facilityIds: number[],
    activityIds: number[],
    prohibitedIds: number[],
    allowedIds: number[],
        specialFacility: string,
  },
    //   가격 설정
  Pricing: {
        priceInfo: any,
        extraOption: any,
  }
    //   정책
  Policy: {
        bookingTime: any,
        confirmMethod: string,
        advanceDate: boolean,
        extraPolicy: string,
        policyId: number
    
  }
}


const initialState: InitialState = {
    General : {
        title: "",
        description: "",
        typeId: 0,
        unitCount: 0,
        themeIds: [],
        accessibleVehicle: "",
        spaceSize: "",
    },
    Location: {
        country: "",
        state: "",
        city: "",
        zipCode: "",
        extraAddress: "",
        direction: "",
        nearbyFacilities: [],
        touristSpots: [{title:"", description: "", photo:"", photoUrl:""}],
        indicator: {latitude:0, longitude:0},
        address: "",
    },
    Gallery: {
      currentImage: [],
        imageList: [],
    },
    Details:{
        facilityIds: [],
        activityIds: [],
        prohibitedIds: [],
        allowedIds: [],
        specialFacility: "",
    },
    Pricing: {
        priceInfo: {mon:'',thu:'',wed:'',tue:'',fri:'',sat:'',sun:''},
        extraOption: [{description: "", maxOfNumber: "", paymentMethod: '', price: '', title: '',amount: 0}],
    },
    Policy: {
        bookingTime: {checkIn: '', checkOut: ''},
        confirmMethod: "",
        advanceDate: false,
        extraPolicy: "",
        policyId: 0
    }
}

const privateGroundSlice = createSlice({
    name: "PrivateGroundData",
    initialState,
    reducers: {
        generalInfo: (state, action: PayloadAction<any>) => {
            state.General = action.payload
        },
        locationSetting: (state, action: PayloadAction<any>) => {
            state.Location = action.payload
        },
        groundGallery: (state, action: PayloadAction<any>) => {
            state.Gallery = action.payload
        },
        groundDetails: (state, action: PayloadAction<any>) => {
            state.Details = action.payload
        },
        pricing: (state, action: PayloadAction<any>) => {
            state.Pricing = action.payload
        },
        policy: (state, action: PayloadAction<any>) => {
            state.Policy = action.payload
        }
    }
})

export default privateGroundSlice;
export const {generalInfo, locationSetting, groundGallery, groundDetails, pricing, policy} = privateGroundSlice.actions;