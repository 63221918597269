//footer SnsLogo
export const sns = [
    {
        id: 1,
        src: 'https://www.instagram.com/must_outdoor/',
        imgUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/instagram.png"
    },
    {
        id: 2,
        src: 'https://www.youtube.com/channel/UCLl25wNuiUfses7sCE1wkHA/featured',
        imgUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/youtube.png"
    }
]
// footer 메뉴
export const menus = [
    {
        title: "About us",
        sub: [
            {
                title: "Our Service",
                url: "/about",
            },
            {
                title: "Partner With Us",
                url: "/partner",
            }
        ],
    },
    {
        title: "Support",
        sub: [
            {
                title: "How It Works",
                url: "/how-it-works",
            },
            {
                title: "FAQ",
                url: "/faq",
            },
            {
                title: "Contact Us",
                url: "/contact",
            },
        ],
    },
    {
        title: "Terms of Use",
        sub: [
            {
                title: "Terms and Conditions",
                url: "/terms",
            },
            {
                title: "Privacy Policy",
                url: "/privacy-policy",
            },
        ],
    },
];


export const PGMENU = [
    {
        title: "Private Ground Mgmt",
        url: "",
        subContent: [
            {
                title: "My Private Ground",
                url: "/host/myprivate",
            },
            {
                title: "Calendar Manager",
                url: "/host/calendar",
            },
        ],
    },
    {
        title: "Message Box",
        url: "/host/message"
    },
    {
        title: "Booking Status",
        url: "/host/book"
    },
    {
        title: "Account Setting",
        url: "/host/account"
    },
    {
        title: "Statistics",
        url: "",
    }
]


export const ACMENU = [
    {
        title: "Accommodation Mgmt",
        url: "",
        subContent: [
            {
                title: "My Accommodation",
                url: "/host/myaccommodation",
            },
            {
                title: "Calendar Manager",
                url: "/host/calendar",
            },
        ],
    },
    {
        title: "Message Box",
        url: "/host/message"
    },
    {
        title: "Booking Status",
        url: "/host/book"
    },
    {
        title: "Account Setting",
        url: "/host/account"
    },
    {
        title: "Statistics",
        url: "",
    }
]

export const TRAVELERMENU = [
    {
        title: "My Profile",
        navigate: "/member/profile",
        position: "top"
    },
    {
        title: "Wish List",
        navigate: "/member/wishlist"
    },
    {
        title: "Message Box",
        navigate: "/member/message"
    },
    {
        title: "Booking Box",
        navigate: "/member/booking",
        position: "bottom"
    },
]
export const Membertab = [
    {
        nav: "member/profile",
        title: 'My Profile'
    },
    {
        nav: "member/wishlist",
        title: 'Wish List'
    },
    {
        nav: "member/message",
        title: 'Message Box'
    },
    {
        nav: "member/booking",
        title: 'Booking Box'
    },
    {
        nav: "",
        title: 'Log out'
    },
]
export const Pgtab = [
    {
        nav: "host/myprivate",
        title: 'My Private Ground'
    },
    {
        nav: "host/message",
        title: 'Message Box'
    },
    {
        nav: "host/book",
        title: 'Booking Status'
    },
    {
        nav: "host/account",
        title: 'Account Setting'
    },
    {
        nav: "host/myprivate",
        title: 'Statistics'
    },
    {
        nav: "",
        title: 'Log out'
    },
]

export const ACtab = [
    {
        nav: "host/myaccommodation",
        title: 'My Accommodation'
    },
    {
        nav: "host/message",
        title: 'Message Box'
    },
    {
        nav: "host/book",
        title: 'Booking Status'
    },
    {
        nav: "host/account",
        title: 'Account Setting'
    },
    {
        nav: "host/myaccommodation",
        title: 'Statistics'
    },
    {
        nav: "",
        title: 'Log out'
    },
]