import React, { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface MemberPrivateRouterProps {
  children?: ReactElement;
  isHost: boolean;
}

export default function HostRouter({
  isHost,
}: MemberPrivateRouterProps): React.ReactElement | null {
  const isAuthentication: any = localStorage.getItem("STATUS");
  const currentType: string | null = localStorage.getItem("currentType");

  if (isHost) {
    return isAuthentication === "HOST" ? (
      <Navigate
        to={`${
          currentType === "AC"
            ? "/host/myaccommodation"
            : currentType === "PG"
            ? "/host/myprivate"
            : ""
        }`}
      ></Navigate>
    ) : (
      <Outlet />
    );
  } else {
    return isAuthentication === "MEMBER" || isAuthentication === null ? (
      <Outlet />
    ) : (
      <Navigate
        to={`${
          currentType === "AC"
            ? "/host/myaccommodation"
            : currentType === "PG"
            ? "/host/myprivate"
            : ""
        }`}
      ></Navigate>
    );
  }
}
