import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const {t} = useTranslation();
  return (
    <div className="w-full">
      <div className="w-full web:h-[640px] phone:h-80 bg-service_1 bg-cover bg-center text-pure-white justify-center web:text-5xl phone:text-xl font-bold flex items-center">
        <div>Go Play the Great Outdoors</div>
      </div>
      <div className="py-8 web:max-w-1216 xl:max-w-1280 px-8 mx-auto text-center">
        <div className="font-bold mb-8 web:text-[32px] phone:text-base">MUST OUTDOOR?</div>
        <div className="web:text-2xl phone:text-sm text-left">
          {t("OurService.MUST OUTDOOR aims at a platform service")}
        </div>
      </div>
      <div className="w-full h-[320px] bg-service_2 bg-cover bg-center text-pure-white justify-center web:text-5xl phone:text-xl font-bold flex items-center"></div>
      <div className="py-8 web:max-w-1216 xl:max-w-1280 px-8 mx-auto text-center">
        <div className="font-bold mb-8 web:text-[32px] phone:text-base">
          {t("OurService.GLOBAL NO1 OUTDOOR LIFE PLATFORM")}
        </div>
        <div className="font-bold mb-8 web:text-2xl phone:text-base">
          {t("OurService.It will develop global leader platform")}
        </div>
      </div>
      <div className="w-full h-[320px] bg-service_3 bg-cover bg-center text-pure-white justify-center web:text-5xl phone:text-xl font-bold flex items-center"></div>
      <div className="py-8 web:max-w-1216 xl:max-w-1280 px-8 mx-auto text-center">
        <div className="font-bold mb-8 web:text-2xl phone:text-base">
          {t('OurService.MAKE YOU REACH EVERY SINGLE OUTDOOR LIFE')}
        </div>
        <div className="web:text-2xl phone:text-sm text-left">
        {t("OurService.From surrounding activities to special outdoor")}
        </div>
      </div>
      <div className="w-full h-[320px] bg-service_4 bg-cover bg-center text-pure-white justify-center web:text-5xl phone:text-xl font-bold flex items-center"></div>
      <div className="py-8 web:max-w-1216 xl:max-w-1280 px-8 mx-auto text-center">
        <div className="mb-8">
          <div className="font-bold mb-8 web:text-2xl phone:text-base">
          {t("OurService.LEAVE BETTER, LIVE BETTER")}
          </div>
          <div className="web:text-2xl phone:text-sm text-left">
            {t('OurService.Everyone enjoys their own outdoor life')}
          </div>
        </div>
        <div className="mb-8">
          <div className="font-bold mb-8 web:text-2xl phone:text-base">BEYOND & MORE</div>
          <div className="web:text-2xl phone:text-sm text-left">
          {t('OurService.Beyond just simple Outdoor')}
          </div>
        </div>
        <div className="font-bold mb-8 web:text-2xl phone:text-base">CREATE GROWTH TOGETHER</div>
        <div className="web:text-2xl phone:text-sm text-left">
        {t('OurService.Host can promote their own business')}
        </div>
      </div>
    </div>
  );
};

export default About;
