export const changecase = (str: string) => {
    const upper = str.toLowerCase().replace(/(^|\s)\S/g, L => L.toUpperCase());
    const newUpper = upper.replace(/\_/g, ' ')
    return newUpper
}

export const changeSpace = (str: string) => {
    str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
    str = str.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    let newStr = str.replace(/\b[a-z]/g, char => char.toUpperCase());
    return newStr;
}

export const changeFacility = (str: string) => {
    const newStr = str.toLowerCase().replace(/\_/g, ' ');
    let newNewStr = newStr.replace(/\b[a-z]/g, char => char.toUpperCase());
    return newNewStr;
}