import React from "react";
import PropTypes from "prop-types";

import Button from "elements/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isHost, isMember } from "lib/isMember";

const HostingImage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const startHosting = () => {
    if (!isMember()) {
      alert("로그인이 되어있어야 이용 가능한 서비스 입니다 :)");
      navigate("/log-in", {
        replace: true,
        state: { nextPath: "/type-select" },
      });
    } else {
      navigate("/type-select");
    }
  };
  return (
    <div className="bg-CampingImg bg-cover bg-center text-pure-white w-full h-128 web:pt-16 web:pl-16 phone:p-4">
      <div className="bg-light-green phone:w-full max-w-[411px] web:w-[411px] rounded-2xl inline-block p-8">
        <div className="text-2xl text-pure-white mb-4 inline-block">
          {t("Partner-becomehost.Share your unused ground")}
        </div>
        <div className="mb-8">{t("Partner-becomehost.Any outdoor space")}</div>
        <Button
          onClick={() => {
            startHosting();
          }}
          className="bg-pure-white inline-block rounded-lg py-2.5 text-light-green px-4"
          btnText={t("Partner-select.Start Hosting")}
        />
      </div>
    </div>
  );
};

export default HostingImage;
