import React, { useCallback, useEffect, useState } from "react";

import AuthUtil from "components/Auth/AuthUtil";
import HeadLogo from "components/Auth/authLogo";
import Button from "elements/Button";
import { useNavigate } from "react-router-dom";
import CheckBox from "elements/CheckBox";
import { useTranslation } from "react-i18next";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

const SocialSuccess = () => {
  const { t } = useTranslation();

  const [checkedList, setCheckedLists] = useState<any[]>([]);
  const [agreeTerm, setAgreeTerm] = useState(false);
  const [agreePolicy, setAgreePolicy] = useState(false);
  const [marketing, setMarketing] = useState(false);

  const checkContent = [
    {
      id: 1,
      content: "I have read and agree to Terms & Conditions",
      navi: "/terms",
    },
    {
      id: 2,
      content: "I have read and agree to Privacy Policy",
      navi: "/privacy-policy",
    },
    {
      id: 3,
      content: "I agree to receive emails from MUST OUTDOOR",
    },
  ];

  const id = localStorage.getItem("m");

  useEffect(() => {
    if (checkedList.includes(3) === true) {
      setMarketing(true);
    } else {
      setMarketing(false);
    }
  }, [checkedList]);
  useEffect(() => {
    if (checkedList.includes(2) === true) {
      setAgreePolicy(true);
    } else {
      setAgreePolicy(false);
    }
  }, [checkedList]);
  useEffect(() => {
    if (checkedList.includes(1) === true) {
      setAgreeTerm(true);
    } else {
      setAgreeTerm(false);
    }
  }, [checkedList]);

  const onCheckedAll = useCallback(
    (checked: any) => {
      if (checked) {
        const checkedListArray: any = [];
        checkContent.forEach(({ id }) => {
          checkedListArray.push(id);
        });
        setCheckedLists(checkedListArray);
      } else {
        setCheckedLists([]);
      }
    },
    [checkContent]
  );

  const onCheckedElement = useCallback(
    (checked: any, id: any) => {
      if (checked) {
        setCheckedLists([...checkedList, id]);
      } else {
        setCheckedLists(checkedList.filter((el) => el !== id));
      }
    },
    [checkedList]
  );

  const navigate = useNavigate();

  const socialSignUp = () => {
    if (agreeTerm !== true) {
      alert("개인정보보호정책 체크");
      return;
    } else if (agreePolicy !== true) {
      alert("이용약관 동의 체크");
      return;
    } else {
      requestToMustOutdoor({
        method: "POST",
        url: "/auth/marketing",
        data: {
          consent: marketing,
          id: Number(id),
        },
      })
        .then((res) => {
          navigate("/log-in");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div>
      <HeadLogo>
        <AuthUtil
          className="fill-light-green"
          AuthIcon={"status-confirmed-approve"}
          title={"Signed up successfully!"}
          subTitle={"If you wish to enjoy all the best benefits"}
        />
        {/* 체크박스 */}
        <div className="text-left inline-block text-xs mt-4 text-pure-white">
          <div className="flex items-center">
            <input
              checked={
                checkedList.length === checkContent.length ? true : false
              }
              onChange={(e) => {
                onCheckedAll(e.target.checked);
              }}
              type="checkbox"
            />
            <label>{t(`Account.I accept all`)}</label>
          </div>
          <div className="flex items-center justify-center my-2">
            <div className="h-1 border-t border-black-grey bg-pure-white w-80 mt-0.5" />
          </div>
          {checkContent &&
            checkContent.map((item, idx) => {
              return (
                <CheckBox
                  key={item.id}
                  onChange={(e) => onCheckedElement(e.target.checked, item.id)}
                  checked={checkedList.includes(item.id)}
                  value={item.content}
                  content={t(`Account.${item.content}`)}
                  url={item.navi}
                />
              );
            })}
        </div>
        <div className="text-base text-pure-white">
          <Button
            onClick={() => {
              socialSignUp();
            }}
            className="inline-block mx-auto mt-4 mb-4 bg-light-green text-pure-white text-xs rounded-lg py-2 w-[320px]"
            btnText={"Continue to login"}
          />
        </div>
      </HeadLogo>
    </div>
  );
};

export default SocialSuccess;
