
export const Vehicle = [
    "Only Backpacker",
    "Up to compact / light size",
    "Up to mid / large size",
    "Up to compact RV (3 pax)",
    "Up to mid / large RV (5 pax)",
    "Small/mid Caravan or Trailer(3 pax)",
    "Large Caravan or Trailer (5 pax)",
];
export const ParkingType = [
    "Parking Onsite",
    "Parking Lot",
    "No Parking Lot"
];

export const hour = [
    "00", "01", "02",
    "03", "04", "05",
    "06", "07", "08",
    "09", "10", "11",
    "12", "13", "14",
    "15", "16", "17",
    "18", "19", "20",
    "21", "22", "23",
]

export const extraGuest = [
    1, 2, 3, 4, 5
]
export const unitNumber = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10
]
export const numberOfGuest = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11,12,13,14,15,16,17,18,19,20
]

export const Payment = [
    "Available in Extra Option",
    "On-site Payment"
]

export const Confirmation = [
    "Instant booking",
    "Confirmation Within 24 Hours"
]

export const Advanced = [
    "Booking is available today",
    "Booking is not available today"
]

export const CancellPolicy = [
    'Flexible',
    'Moderate',
    'Strict',
    'Super Strict'
]
export const pgType = [
    {
        category: "",
        enName: "farmBarn",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-type/farm-barn.svg",
        fileName: "farm-barn"
    },
    {
        category: "",
        enName: "farmland",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-type/farmland.svg",
        fileName: "farmland"
    },
    {
        category: "",
        enName: "frontYard",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-type/front-yard.svg",
        fileName: "front-yard"
    },
    {
        category: "",
        enName: "openGround",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-type/open-ground.svg",
        fileName: "open-ground"
    },
    {
        category: "",
        enName: "orchard",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-type/orchard.svg",
        fileName: "orchard"
    },
    {
        category: "",
        enName: "park",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-type/park.svg",
        fileName: "park"
    },
    {
        category: "",
        enName: "privateGround",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-type/private-ground.svg",
        fileName: "private-ground"
    },
    {
        category: "",
        enName: "quaysideSeawall",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-type/quayside-seawall.svg",
        fileName: "quayside-seawall"
    },
    {
        category: "",
        enName: "restStop",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-type/rest-stop.svg",
        fileName: "rest-stop"
    },
    {
        category: "",
        enName: "winery",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-type/winery.svg",
        fileName: "winery"
    },
]

export const acSpaceGlampingType = [
    {
        category: "glamping-types",
        enName: "yurtGer",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/yurt-ger.svg",
        fileName: "yurt-ger"
    },
    {
        category: "glamping-types",
        enName: "yacht",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/yacht.svg",
        fileName: "yacht"
    },
    {
        category: "glamping-types",
        enName: "tipiTepee",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/tipi-tepee.svg",
        fileName: "tipi-tepee"
    },
    {
        category: "glamping-types",
        enName: "safariTent",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/safari-tent.svg",
        fileName: "safari-tent"
    },
    {
        category: "glamping-types",
        enName: "rooftopTent",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/rooftop-tent.svg",
        fileName: "rooftop-tent"
    },
    {
        category: "glamping-types",
        enName: "glampingType",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/glamping-type.svg",
        fileName: "glamping-type"
    },
    {
        category: "glamping-types",
        enName: "domeHouse",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/dome-house.svg",
        fileName: "dome-house"
    },
    {
        category: "glamping-types",
        enName: "cubeTent",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/cube-tent.svg",
        fileName: "cube-tent"
    },
    {
        category: "glamping-types",
        enName: "cabanaTent",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/cabana-tent.svg",
        fileName: "cabana-tent"
    },
    {
        category: "glamping-types",
        enName: "bubbleTent",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/bubble-tent.svg",
        fileName: "bubble-tent"
    },
    {
        category: "glamping-types",
        enName: "boatHouse",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/boat-house.svg",
        fileName: "boat-house"
    },
    {
        category: "glamping-types",
        enName: "bellTent",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/bell-tent.svg",
        fileName: "bell-tent"
    },
    {
        category: "glamping-types",
        enName: "barnfarmHouse",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/barnfarm-house.svg",
        fileName: "barnfarm-house"
    }
]

export const acSpaceEcoFriendlyTypes = [
    {
        category: "eco-friendly-types",
        enName: "villa",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/villa.svg",
        fileName: "villa"
    },
    {
        category: "eco-friendly-types",
        enName: "treeHouse",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/tree-house.svg",
        fileName: "tree-house"
    },
    {
        category: "eco-friendly-types",
        enName: "tinyHouse",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/tiny-house.svg",
        fileName: "tiny-house"
    },
    {
        category: "eco-friendly-types",
        enName: "lodge",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/lodge.svg",
        fileName: "lodge"
    },
    {
        category: "eco-friendly-types",
        enName: "igloo",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/igloo.svg",
        fileName: "igloo"
    },
    {
        category: "eco-friendly-types",
        enName: "hutCottage",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/hut-cottage.svg",
        fileName: "hut-cottage"
    },
    {
        category: "eco-friendly-types",
        enName: "floatingHouse",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/floating-house.svg",
        fileName: "floating-house"
    },
    {
        category: "eco-friendly-types",
        enName: "ecoFriendlyType",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/eco-friendly-type.svg",
        fileName: "eco-friendly-type"
    },
    {
        category: "eco-friendly-types",
        enName: "cubePods",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/cube-pods.svg",
        fileName: "cube-pods"
    },
    {
        category: "eco-friendly-types",
        enName: "cabin",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/cabin.svg",
        fileName: "cabin"
    },
    {
        category: "eco-friendly-types",
        enName: "bungalow",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/bungalow.svg",
        fileName: "bungalow"
    },
]

export const acSpaceCaravanTypes = [
    {
        category: "caravan-types",
        enName: "wagonHut",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/wagon-hut.svg",
        fileName: "wagon-hut"
    },
    {
        category: "caravan-types",
        enName: "train",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/train.svg",
        fileName: "train"
    },
    {
        category: "caravan-types",
        enName: "scamp",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/scamp.svg",
        fileName: "scamp"
    },
    {
        category: "caravan-types",
        enName: "miniVan",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/mini-van.svg",
        fileName: "mini-van"
    },
    {
        category: "caravan-types",
        enName: "caravanType",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/caravan-type.svg",
        fileName: "caravan-type"
    },
    {
        category: "caravan-types",
        enName: "caravanPark",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/caravan-park.svg",
        fileName: "caravan-park"
    },
    {
        category: "caravan-types",
        enName: "airstreamTrailer",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/airstream-trailer.svg",
        fileName: "airstream-trailer"
    },
]

export const acSpaceCampgroundTypes = [
    {
        category: "campground-types",
        enName: "sand",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/sand.svg",
        fileName: "sand"
    },
    {
        category: "campground-types",
        enName: "rvTrailer",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/rv-trailer.svg",
        fileName: "rv-trailer"
    },
    {
        category: "campground-types",
        enName: "rock",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/rock.svg",
        fileName: "rock"
    },
    {
        category: "campground-types",
        enName: "gravel",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/gravel.svg",
        fileName: "gravel"
    },
    {
        category: "campground-types",
        enName: "grass",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/grass.svg",
        fileName: "grass"
    },
    {
        category: "campground-types",
        enName: "farmping",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/farmping.svg",
        fileName: "farmping"
    },
    {
        category: "campground-types",
        enName: "deck",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/deck.svg",
        fileName: "deck"
    },
]

export const acSpaceAmenity = [
    {
        category: "space-amenity",
        enName: "wifi",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/wifi.svg",
        fileName: "wifi"
    },
    {
        category: "space-amenity",
        enName: "washingMachine",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/washing-machine.svg",
        fileName: "washing-machine"
    },
    {
        category: "space-amenity",
        enName: "tv",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/tv.svg",
        fileName: "tv"
    },
    {
        category: "space-amenity",
        enName: "towel",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/towel.svg",
        fileName: "towel"
    },
    {
        category: "space-amenity",
        enName: "terrace",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/terrace.svg",
        fileName: "terrace"
    },
    {
        category: "space-amenity",
        enName: "table",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/table.svg",
        fileName: "table"
    },
    {
        category: "space-amenity",
        enName: "stove",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/stove.svg",
        fileName: "stove"
    },
    {
        category: "space-amenity",
        enName: "spa",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/spa.svg",
        fileName: "spa"
    },
    {
        category: "space-amenity",
        enName: "sleepingBag",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/sleeping-bag.svg",
        fileName: "sleeping-bag"
    },
    {
        category: "space-amenity",
        enName: "sink",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/sink.svg",
        fileName: "sink"
    },
    {
        category: "space-amenity",
        enName: "riceCooker",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/rice-cooker.svg",
        fileName: "rice-cooker"
    },
    {
        category: "space-amenity",
        enName: "refrigerator",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/refrigerator.svg",
        fileName: "refrigerator"
    },
    {
        category: "space-amenity",
        enName: "privateSwimmingPool",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/private-swimming-pool.svg",
        fileName: "private-swimming-pool"
    },
    {
        category: "space-amenity",
        enName: "noSmoking",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/no-smoking.svg",
        fileName: "no-smoking"
    },
    {
        category: "space-amenity",
        enName: "microwave",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/microwave.svg",
        fileName: "microwave"
    },
    {
        category: "space-amenity",
        enName: "hygiene",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/hygiene.svg",
        fileName: "hygiene"
    },
    {
        category: "space-amenity",
        enName: "heatingFloorPad",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/heating-floor-pad.svg",
        fileName: "heating-floor-pad"
    },
    {
        category: "space-amenity",
        enName: "heater",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/heater.svg",
        fileName: "heater"
    },
    {
        category: "space-amenity",
        enName: "hairDryer",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/hair-dryer.svg",
        fileName: "hair-dryer"
    },
    {
        category: "space-amenity",
        enName: "firstAidKit",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/first-aid-kit.svg",
        fileName: "first-aid-kit"
    },
    {
        category: "space-amenity",
        enName: "fireplace",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/fireplace.svg",
        fileName: "fireplace"
    },
    {
        category: "space-amenity",
        enName: "firePit",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/fire-pit.svg",
        fileName: "fire-pit"
    },
    {
        category: "space-amenity",
        enName: "fan",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/fan.svg",
        fileName: "fan"
    },
    {
        category: "space-amenity",
        enName: "electricity",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/electricity.svg",
        fileName: "electricity"
    },
    {
        category: "space-amenity",
        enName: "couch",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/couch.svg",
        fileName: "couch"
    },
    {
        category: "space-amenity",
        enName: "cookUtensil",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/cook-utensil.svg",
        fileName: "cook-utensil"
    },
    {
        category: "space-amenity",
        enName: "coffeeMaker",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/coffee-maker.svg",
        fileName: "coffee-maker"
    },
    {
        category: "space-amenity",
        enName: "bed",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/bed.svg",
        fileName: "bed"
    },
    {
        category: "space-amenity",
        enName: "bbqGrill",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/bbq-grill.svg",
        fileName: "bbq-grill"
    },
    {
        category: "space-amenity",
        enName: "bathtub",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/bathtub.svg",
        fileName: "bathtub"
    },
    {
        category: "space-amenity",
        enName: "bathroom",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/bathroom.svg",
        fileName: "bathroom"
    },
    {
        category: "space-amenity",
        enName: "airConditioner",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/air-conditioner.svg",
        fileName: "air-conditioner"
    },
    {
        category: "space-amenity",
        enName: "accessKey",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-amenity/access-key.svg",
        fileName: "access-key"
    }
]

export const atWinterSeasonalActivityTypes = [
    {
        category: "winter-seasonal-activity",
        enName: "winterSeasonalActivityType",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/winter-seasonal-activity/winter-seasonal-activity-type.svg",
        fileName: "winter-seasonal-activity-type"
    },
    {
        category: "winter-seasonal-activity",
        enName: "sledRiding",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/winter-seasonal-activity/sled-riding.svg",
        fileName: "sled-riding"
    },
    {
        category: "winter-seasonal-activity",
        enName: "skiSnowboard",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/winter-seasonal-activity/ski-snowboard.svg",
        fileName: "ski-snowboard"
    },
    {
        category: "winter-seasonal-activity",
        enName: "iceSkating",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/winter-seasonal-activity/ice-skating.svg",
        fileName: "ice-skating"
    },
    {
        category: "winter-seasonal-activity",
        enName: "iceFishing",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/winter-seasonal-activity/ice-fishing.svg",
        fileName: "ice-fishing"
    },
    {
        category: "winter-seasonal-activity",
        enName: "festival",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/winter-seasonal-activity/festival.svg",
        fileName: "festival"
    },
    {
        category: "winter-seasonal-activity",
        enName: "dogSledding",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/winter-seasonal-activity/dog-sledding.svg",
        fileName: "dog-sledding"
    },
]

export const atWaterActivityTypes = [
    {
        category: "water-activity",
        enName: "yachtTour",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/yacht-tour.svg",
        fileName: "yacht-tour"
    },
    {
        category: "water-activity",
        enName: "windsurf",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/windsurf.svg",
        fileName: "windsurf"
    },
    {
        category: "water-activity",
        enName: "whaleWatching",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/whale-watching.svg",
        fileName: "whale-watching"
    },
    {
        category: "water-activity",
        enName: "waterSki",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/water-ski.svg",
        fileName: "water-ski"
    },
    {
        category: "water-activity",
        enName: "waterPark",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/water-park.svg",
        fileName: "water-park"
    },
    {
        category: "water-activity",
        enName: "waterActivityType",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/water-activity-type.svg",
        fileName: "water-activity-type"
    },
    {
        category: "water-activity",
        enName: "wakeBoard",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/wake-board.svg",
        fileName: "wake-board"
    },
    {
        category: "water-activity",
        enName: "swimming",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/swimming.svg",
        fileName: "swimming"
    },
    {
        category: "water-activity",
        enName: "surfing",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/surfing.svg",
        fileName: "surfing"
    },
    {
        category: "water-activity",
        enName: "snorkeling",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/snorkeling.svg",
        fileName: "snorkeling"
    },
    {
        category: "water-activity",
        enName: "scubaDiving",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/scuba-diving.svg",
        fileName: "scuba-diving"
    },
    {
        category: "water-activity",
        enName: "sailing",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/sailing.svg",
        fileName: "sailing"
    },
    {
        category: "water-activity",
        enName: "rafting",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/rafting.svg",
        fileName: "rafting"
    },
    {
        category: "water-activity",
        enName: "parasailing",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/parasailing.svg",
        fileName: "parasailing"
    },
    {
        category: "water-activity",
        enName: "paddleBoarding",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/paddle-boarding.svg",
        fileName: "paddle-boarding"
    },
    {
        category: "water-activity",
        enName: "motorBoat",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/motor-boat.svg",
        fileName: "motor-boat"
    },
    {
        category: "water-activity",
        enName: "kitesurf",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/kitesurf.svg",
        fileName: "kitesurf"
    },
    {
        category: "water-activity",
        enName: "jetSurf",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/jet-surf.svg",
        fileName: "jet-surf"
    },
    {
        category: "water-activity",
        enName: "jetSki",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/jet-ski.svg",
        fileName: "jet-ski"
    },
    {
        category: "water-activity",
        enName: "flyboard",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/flyboard.svg",
        fileName: "flyboard"
    },
    {
        category: "water-activity",
        enName: "flyFishBananaBoat",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/fly-fish-banana-boat.svg",
        fileName: "fly-fish-banana-boat"
    },
    {
        category: "water-activity",
        enName: "fishing",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/.svg",
        fileName: "fishing"
    },
    {
        category: "water-activity",
        enName: "cruise",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/cruise.svg",
        fileName: "cruise"
    },
    {
        category: "water-activity",
        enName: "canoeKayak",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/water-activity/canoe-kayak.svg",
        fileName: "canoe-kayak"
    },
]
export const atSkyActivityTypes = [
    {
        category: "sky-activity",
        enName: "skydiving",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/sky-activity/skydiving.svg",
        fileName: "skydiving"
    },
    {
        category: "sky-activity",
        enName: "skyActivityType",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/sky-activity/sky-activity-type.svg",
        fileName: "sky-activity-type"
    },
    {
        category: "sky-activity",
        enName: "paragliding",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/sky-activity/paragliding.svg",
        fileName: "paragliding"
    },
    {
        category: "sky-activity",
        enName: "parachute",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/sky-activity/parachute.svg",
        fileName: "parachute"
    },
    {
        category: "sky-activity",
        enName: "hotAirballon",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/sky-activity/hot-airballon.svg",
        fileName: "hot-airballon"
    },
    {
        category: "sky-activity",
        enName: "hellicopterTour",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/sky-activity/hellicopter-tour.svg",
        fileName: "hellicopter-tour"
    },
    {
        category: "sky-activity",
        enName: "hangGliding",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/sky-activity/hang-gliding.svg",
        fileName: "hang-gliding"
    },
    {
        category: "sky-activity",
        enName: "gliderFlight",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/sky-activity/glider-flight.svg",
        fileName: "glider-flight"
    },
    {
        category: "sky-activity",
        enName: "airplaneTour",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/sky-activity/airplane-tour.svg",
        fileName: "airplane-tour"
    }
]
export const atMountainActivityTypes = [
    {
        category: "mountain-activity",
        enName: "zipLine",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/mountain-activity/zip-line.svg",
        fileName: "zip-line"
    },
    {
        category: "mountain-activity",
        enName: "trekking",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/mountain-activity/trekking.svg",
        fileName: "trekking"
    },
    {
        category: "mountain-activity",
        enName: "rappelAbseiling",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/mountain-activity/rappel-abseiling.svg",
        fileName: "rappel-abseiling"
    },
    {
        category: "mountain-activity",
        enName: "mountainBike",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/mountain-activity/mountain-bike.svg",
        fileName: "mountain-bike"
    },
    {
        category: "mountain-activity",
        enName: "mountainActivityType",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/mountain-activity/mountain-activity-type.svg",
        fileName: "mountain-activity-type"
    },
    {
        category: "mountain-activity",
        enName: "hiking",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/mountain-activity/hiking.svg",
        fileName: "hiking"
    },
    {
        category: "mountain-activity",
        enName: "climbing",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/mountain-activity/climbing.svg",
        fileName: "climbing"
    },
    {
        category: "mountain-activity",
        enName: "caving",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/mountain-activity/caving.svg",
        fileName: "caving"
    },
    {
        category: "mountain-activity",
        enName: "canyoning",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/mountain-activity/canyoning.svg",
        fileName: "canyoning"
    },
    {
        category: "mountain-activity",
        enName: "bouldering",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/mountain-activity/bouldering.svg",
        fileName: "bouldering"
    },

]
export const atGroundActivityTypes = [
    {
        category: "ground-activity",
        enName: "zorbing",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/zorbing.svg",
        fileName: "zorbing"
    },
    {
        category: "ground-activity",
        enName: "trails",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/trails.svg",
        fileName: "trails"
    },
    {
        category: "ground-activity",
        enName: "touristSpotsVisit",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/tourist-spots-visit.svg",
        fileName: "tourist-spots-visit"
    },
    {
        category: "ground-activity",
        enName: "sleddingHill",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/sledding-hill.svg",
        fileName: "sledding-hill"
    },
    {
        category: "ground-activity",
        enName: "sandBoarding",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/sand-boarding.svg",
        fileName: "sand-boarding"
    },
    {
        category: "ground-activity",
        enName: "safariTour",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/safari-tour.svg",
        fileName: "safari-tour"
    },
    {
        category: "ground-activity",
        enName: "ropeSwing",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/rope-swing.svg",
        fileName: "rope-swing"
    },
    {
        category: "ground-activity",
        enName: "railBike",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/rail-bike.svg",
        fileName: "rail-bike"
    },
    {
        category: "ground-activity",
        enName: "paintball",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/paintball.svg",
        fileName: "paintball"
    },
    {
        category: "ground-activity",
        enName: "offRoading",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/off-roading.svg",
        fileName: "off-roading"
    },
    {
        category: "ground-activity",
        enName: "luge",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/luge.svg",
        fileName: "luge"
    },
    {
        category: "ground-activity",
        enName: "kartRacing",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/kart-racing.svg",
        fileName: "kart-racing"
    },
    {
        category: "ground-activity",
        enName: "horsebackRiding",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/horseback-riding.svg",
        fileName: "horseback-riding"
    },
    {
        category: "ground-activity",
        enName: "groundActivityType",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/ground-activity-type.svg",
        fileName: "ground-activity-type"
    },
    {
        category: "ground-activity",
        enName: "golf",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/golf.svg",
        fileName: "golf"
    },
    {
        category: "ground-activity",
        enName: "elephantRiding",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/elephant-riding.svg",
        fileName: "elephant-riding"
    },
    {
        category: "ground-activity",
        enName: "cycling",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/cycling.svg",
        fileName: "cycling"
    },
    {
        category: "ground-activity",
        enName: "clayTargetShooting",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/clay-target-shooting.svg",
        fileName: "clay-target-shooting"
    },
    {
        category: "ground-activity",
        enName: "camelRiding",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/camel-riding.svg",
        fileName: "camel-riding"
    },
    {
        category: "ground-activity",
        enName: "bungeeJump",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/bungee-jump.svg",
        fileName: "bungee-jump"
    },
    {
        category: "ground-activity",
        enName: "atv",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/atv.svg",
        fileName: "atv"
    },
    {
        category: "ground-activity",
        enName: "archeryRange",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-types/ground-activity/archery-range.svg",
        fileName: "archery-range"
    },

]


export const atFacility = [
    {
        category: "at-facility",
        enName: "withAccommodation",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/with-accommodation.svg",
        fileName: "with-accommodation"
    },
    {
        category: "at-facility",
        enName: "wifi",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/wifi.svg",
        fileName: "wifi"
    },
    {
        category: "at-facility",
        enName: "terraceTable",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/terrace-table.svg",
        fileName: "terrace-table"
    },
    {
        category: "at-facility",
        enName: "sunbed",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/sunbed.svg",
        fileName: "sunbed"
    },
    {
        category: "at-facility",
        enName: "smokingArea",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/smoking-area.svg",
        fileName: "smoking-area"
    },
    {
        category: "at-facility",
        enName: "shop",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/shop.svg",
        fileName: "shop"
    },
    {
        category: "at-facility",
        enName: "restaurant",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/restaurant.svg",
        fileName: "restaurant"
    },
    {
        category: "at-facility",
        enName: "publicToilet",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/public-toilet.svg",
        fileName: "public-toilet"
    },
    {
        category: "at-facility",
        enName: "publicSink",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/public-sink.svg",
        fileName: "public-sink"
    },
    {
        category: "at-facility",
        enName: "publicShower",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/public-shower.svg",
        fileName: "public-shower"
    },
    {
        category: "at-facility",
        enName: "pickupService",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/pickup-service.svg",
        fileName: "pickup-service"
    },
    {
        category: "at-facility",
        enName: "noPetAllowed",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/no-pet-allowed.svg",
        fileName: "no-pet-allowed"
    },
    {
        category: "at-facility",
        enName: "lounge",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/lounge.svg",
        fileName: "lounge"
    },
    {
        category: "at-facility",
        enName: "lockerRoom",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/locker-room.svg",
        fileName: "locker-room"
    },
    {
        category: "at-facility",
        enName: "firstAidKit",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/first-aid-kit.svg",
        fileName: "first-aid-kit"
    },
    {
        category: "at-facility",
        enName: "equipmentRental",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/equipment-rental.svg",
        fileName: "equipment-rental"
    },
    {
        category: "at-facility",
        enName: "carParking",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/car-parking.svg",
        fileName: "car-parking"
    },
    {
        category: "at-facility",
        enName: "cafe",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/cafe.svg",
        fileName: "cafe"
    },
    {
        category: "at-facility",
        enName: "bar",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/activity/at-facility/bar.svg",
        fileName: "bar"
    },
]


export const pgProhibitedDetails = [
    {
        enName: "noUrinate",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-prohibited-details/no-urinate.svg",
        fileName: "no-urinate"
    },
    {
        enName: "noSmoking",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-prohibited-details/no-smoking.svg",
        fileName: "no-smoking"
    },
    {
        enName: "noOutdoorCooking",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-prohibited-details/no-outdoor-cooking.svg",
        fileName: "no-outdoor-cooking"
    },
    {
        enName: "noGrillUse",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-prohibited-details/no-grill-use.svg",
        fileName: "no-grill-use"
    },
    {
        enName: "noDumpTrash",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-prohibited-details/no-dump-trash.svg",
        fileName: "no-dump-trash"
    },
    {
        enName: "noCampfire",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-prohibited-details/no-campfire.svg",
        fileName: "no-campfire"
    }
]


export const pgNearbyFacility = [
    {
        enName: "supermarket",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/supermarket.svg",
        fileName: "supermarket"
    },
    {
        enName: "gasStation",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/gas-station.svg",
        fileName: "gas-station"
    },
    {
        enName: "evChargeStation",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/ev-charge-station.svg",
        fileName: "ev-charge-station"
    },
    {
        enName: "convenienceStore",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/convenience-store.svg",
        fileName: "convenience-store"
    },
    {
        enName: "24hrToilet",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/24hr-toilet.svg",
        fileName: "24hr-toilet"
    },
]

export const pgAllowedDetails = [
    {
        enName: "tentPitchingSmall",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-allowed-details/tent-pitching-small.svg",
        fileName: "tent-pitching-small"
    },
    {
        enName: "tentPitchingLarge",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-allowed-details/tent-pitching-large.svg",
        fileName: "tent-pitching-large"
    },
    {
        enName: "tarpAwning",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-allowed-details/tarp-awning.svg",
        fileName: "tarp-awning"
    },
    {
        enName: "rooftopTarpAwning",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-allowed-details/rooftop-tarp-awning.svg",
        fileName: "rooftop-tarp-awning"
    },
    {
        enName: "rooftopPitching",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-allowed-details/rooftop-pitching.svg",
        fileName: "rooftop-pitching"
    },
    {
        enName: "outdoorTable",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-allowed-details/outdoor-table.svg",
        fileName: "outdoor-table"
    },
    {
        enName: "outdoorCooking",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-allowed-details/outdoor-cooking.svg",
        fileName: "outdoor-cooking"
    },
]

export const theme = [
    {
        enName: "beachFront",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/beach-front.svg",
        fileName: "beach-front"
    },
    {
        enName: "countryside",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/countryside.svg",
        fileName: "countryside"
    },
    {
        enName: "coupleFriendly",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/couple-friendly.svg",
        fileName: "couple-friendly"
    },
    {
        enName: "desert",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/desert.svg",
        fileName: "desert"
    },
    {
        enName: "eventParty",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/event-party.svg",
        fileName: "event-party"
    },
    {
        enName: "familyFriendly",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/family-friendly.svg",
        fileName: "family-friendly"
    },
    {
        enName: "forest",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/forest.svg",
        fileName: "forest"
    },
    {
        enName: "hillSide",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/hill-side.svg",
        fileName: "hill-side"
    },
    {
        enName: "hotSpring",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/hot-spring.svg",
        fileName: "hot-spring"
    },
    {
        enName: "island",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/island.svg",
        fileName: "island"
    },
    {
        enName: "lakeView",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/lake-view.svg",
        fileName: "lake-view"
    },
    {
        enName: "mountain",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/mountain.svg",
        fileName: "mountain"
    },
    {
        enName: "petAllowed",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/pet-allowed.svg",
        fileName: "pet-allowed"
    },
    {
        enName: "riverView",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/river-view.svg",
        fileName: "river-view"
    },
    {
        enName: "streamSide",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/stream-side.svg",
        fileName: "stream-side"
    },
    {
        enName: "theme",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/theme.svg",
        fileName: "theme"
    },
    {
        enName: "urbanCity",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/urban-city.svg",
        fileName: "urban-city"
    },
    {
        enName: "valley",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/theme/valley.svg",
        fileName: "valley"
    },
]

export const facility = [
    {
        enName: "bicycleStorage",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/bicycle-storage.svg",
        fileName: "bicycle-storage"
    },
    {
        enName: "cafe",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/cafe.svg",
        fileName: "cafe"
    },
    {
        enName: "campfireZone",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/campfire-zone.svg",
        fileName: "campfire-zone"
    },
    {
        enName: "carParking",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/car-parking.svg",
        fileName: "car-parking"
    },
    {
        enName: "dustbin",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/dustbin.svg",
        fileName: "dustbin"
    },
    {
        enName: "evChargingStation",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/ev-charging-station.svg",
        fileName: "ev-charging-station"
    },
    {
        enName: "garden",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/garden.svg",
        fileName: "garden"
    },
    {
        enName: "groupBbqZone",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/group-bbq-zone.svg",
        fileName: "group-bbq-zone"
    },
    {
        enName: "handicapped",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/handicapped.svg",
        fileName: "handicapped"
    },
    {
        enName: "hotSpring",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/hot-spring.svg",
        fileName: "hot-spring"
    },
    {
        enName: "laundaryFacility",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/laundary-facility.svg",
        fileName: "laundary-facility"
    },
    {
        enName: "noPetAllowed",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/no-pet-allowed.svg",
        fileName: "no-pet-allowed"
    },
    {
        enName: "picnicTable",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/picnic-table.svg",
        fileName: "picnic-table"
    },
    {
        enName: "playground",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/playground.svg",
        fileName: "playground"
    },
    {
        enName: "pool",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/pool.svg",
        fileName: "pool"
    },
    {
        enName: "powerPlug",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/power-plug.svg",
        fileName: "power-plug"
    },
    {
        enName: "publicShower",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/public-shower.svg",
        fileName: "public-shower"
    },
    {
        enName: "publicSink",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/public-sink.svg",
        fileName: "public-sink"
    },
    {
        enName: "publicToilet",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/public-toilet.svg",
        fileName: "public-toilet"
    },
    {
        enName: "sanitaryDumpStation",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/sanitary-dump-station.svg",
        fileName: "sanitary-dump-station"
    },
    {
        enName: "securityCamera",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/security-camera.svg",
        fileName: "security-camera"
    },
    {
        enName: "shop",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/shop.svg",
        fileName: "shop"
    },
    {
        enName: "smokingArea",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/smoking-area.svg",
        fileName: "smoking-area"
    },
    {
        enName: "vantagePoint",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/vantage-point.svg",
        fileName: "vantage-point"
    },
    {
        enName: "vendingMachine",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/vending-machine.svg",
        fileName: "vending-machine"
    },
    {
        enName: "waterPlayground",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/water-playground.svg",
        fileName: "water-playground"
    },
    {
        enName: "wifi",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/facility/wifi.svg",
        fileName: "wifi"
    },
]

export const activity = [
    {
        enName: "ballGame",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/ball-game.svg",
        fileName: "ball-game"
    },
    {
        enName: "bicycle",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/bicycle.svg",
        fileName: "bicycle"
    },
    {
        enName: "boat",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/boat.svg",
        fileName: "boat"
    },
    {
        enName: "campfire",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/campfire.svg",
        fileName: "campfire"
    },
    {
        enName: "concertPerformance",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/concert-performance.svg",
        fileName: "concert-performance"
    },
    {
        enName: "culturalVisit",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/cultural-visit.svg",
        fileName: "cultural-visit"
    },
    {
        enName: "eventParty.",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/event-party..svg",
        fileName: "event-party."
    },
    {
        enName: "farmingExperience",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/farming-experience.svg",
        fileName: "farming-experience"
    },
    {
        enName: "fieldTrip",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/field-trip.svg",
        fileName: "field-trip"
    },
    {
        enName: "fishingExperience",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/fishing-experience.svg",
        fileName: "fishing-experience"
    },
    {
        enName: "fleaMarket",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/flea-market.svg",
        fileName: "flea-market"
    },
    {
        enName: "hikingTrekkingCourse",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/hiking-trekking-course.svg",
        fileName: "hiking-trekking-course"
    },
    {
        enName: "movieWatching",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/movie-watching.svg",
        fileName: "movie-watching"
    },
    {
        enName: "shopping",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/shopping.svg",
        fileName: "shopping"
    },
    {
        enName: "tastingFoodDrink",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/tasting-food-drink.svg",
        fileName: "tasting-food-drink"
    },
    {
        enName: "tidelandExperience",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/tideland-experience.svg",
        fileName: "tideland-experience"
    },
    {
        enName: "trampoline",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/trampoline.svg",
        fileName: "trampoline"
    },
    {
        enName: "waterLeisureSports",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/water-leisure-sports.svg",
        fileName: "water-leisure-sports"
    },
    {
        enName: "wildlifeWatching",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/wildlife-watching.svg",
        fileName: "wildlife-watching"
    },
    {
        enName: "winterLeisureSports",
        iconUrl: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/icons/activity/winter-leisure-sports.svg",
        fileName: "winter-leisure-sports"
    }
]

export const _privateGround = [
    {
        id: 0,
        src: 'park',
        title: 'Park'
    },
    {
        id: 1,
        src: 'farmland',
        title: 'Farmland'
    },
    {
        id: 2,
        src: 'farmBarn',
        title: 'Farm / Barn'
    },
    {
        id: 3,
        src: 'orchard',
        title: 'Orchard'
    },
    {
        id: 4,
        src: 'winery',
        title: 'Winery'
    },
    {
        id: 5,
        src: 'quaysideSeawall',
        title: 'Quayside / Seawall'
    },
    {
        id: 6,
        src: 'frontYard',
        title: 'Front Yard'
    },
    {
        id: 7,
        src: 'openGround',
        title: 'Open Ground'
    },
    {
        id: 8,
        src: 'restStop',
        title: 'Rest Stop'
    }
]

export const _Location = [
    {
        id: 9,
        src: 'forest',
        title: 'Forest',

    },
    {
        id: 10,
        src: 'mountain',
        title: 'Mountain',
    },
    {
        id: 11,
        src: 'hillSide',
        title: 'Hill Side',
    },
    {
        id: 12,
        src: 'riverView',
        title: 'River View',
    },
    {
        id: 13,
        src: 'lakeView',
        title: 'Lake View',
    },
    {
        id: 14,
        src: 'streamSide',
        title: 'Stream Side',
    },
    {
        id: 15,
        src: 'beachFront',
        title: 'Beach Front',
    },
    {
        id: 16,
        src: 'valley',
        title: 'Valley',
    },
    {
        id: 17,
        src: 'countrySide',
        title: 'Country Side',
    },
    {
        id: 18,
        src: 'island',
        title: 'Island',
    },
    {
        id: 19,
        src: 'urbanCity',
        title: 'Urban City',
    },
    {
        id: 20,
        src: 'desert',
        title: 'Desert',
    },
    {
        id: 21,
        src: 'themeEventParty',
        title: 'Event & Party',
    },
    {
        id: 22,
        src: 'familyFriendly',
        title: 'Family Friendly',
    },
    {
        id: 23,
        src: 'coupleFriendly',
        title: 'Couple Friendly',
    },
    {
        id: 24,
        src: 'themeHotSpring',
        title: 'Hot Spring',
    },
    {
        id: 25,
        src: 'petAllowed',
        title: 'Pet Allowed',
    }
]

export const _Facility = [
    {
        id: 26,
        src: 'campfireZone',
        title: 'Campfire Zone'        
    },
    {
        id: 27,
        src: 'groupBbqZone',
        title: 'Group BBQ Zone'
    },
    {
        id: 28,
        src: 'picnicTable',
        title: 'Picnic Table'
    },
    {
        id: 29,
        src: 'pool',
        title: 'Pool'
    },
    {
        id: 30,
        src: 'playground',
        title: 'Playground'
    },
    {
        id: 31,
        src: 'waterPlayground',
        title: 'Water Playground'
    },
    {
        id: 32,
        src: 'cafe',
        title: 'Cafe'
    },
    {
        id: 33,
        src: 'shop',
        title: 'Shops'
    },
    {
        id: 34,
        src: 'vendingMachine',
        title: 'Vending Machine'
    },
    {
        id: 35,
        src: 'publicToilet',
        title: 'Public Toilet'
    },
    {
        id: 36,
        src: 'publicShower',
        title: 'Public Shower'
    },
    {
        id: 37,
        src: 'publicToiletublicSink',
        title: 'Public Sink'
    },
    {
        id: 38,
        src: 'laundaryFacility',
        title: 'Laundary Facility'
    },
    {
        id: 39,
        src: 'carParking',
        title: 'Car Parking'
    },
    {
        id: 40,
        src: 'bicycleStorage',
        title: 'Bicycle Storage'
    },
    {
        id: 41,
        src: 'powerPlug',
        title: 'Power Plug'
    },
    {
        id: 42,
        src: 'evChargingStation',
        title: 'EV Charge'
    },
    {
        id: 43,
        src: 'wifi',
        title: 'Wifi'
    },
    {
        id: 44,
        src: 'accommodationSmokingArea',
        title: 'Smoking Area'
    },
    {
        id: 45,
        src: 'accommodationNoPetAllowed',
        title: 'No Pet Allowed'
    },
    {
        id: 46,
        src: 'handicapped',
        title: 'Handicapped'
    },
    {
        id: 47,
        src: 'dustbin',
        title: 'Dustbin'
    },
    {
        id: 48,
        src: 'sanitaryDumpStation',
        title: 'Sanitary Dump Station'
    },
    {
        id: 49,
        src: 'securityCamera',
        title: 'Security Camera'
    },
    {
        id: 50,
        src: 'hotSpring',
        title: 'Hot Spring / Onsen'
    },
    {
        id: 51,
        src: 'garden',
        title: 'Garden'
    },
    {
        id: 52,
        src: 'vantagePoint',
        title: 'Vantage Point'
    }
]

export const _Activities = [

    {
        id: 53,
        src: 'campfire',
        title: 'Campfire'
    },
    {
        id: 54,
        src: 'eventParty',
        title: 'Event / Party'
    },
    {
        id: 55,
        src: 'concertPerformance',
        title: 'Concert / Performance'
    },
    {
        id: 56,
        src: 'hikingTrekkingCourse',
        title: 'Hiking / Trekking'
    },
    {
        id: 57,
        src: 'wildlifeWatching',
        title: 'Wildlife Watching'
    },
    {
        id: 58,
        src: 'fleaMarket',
        title: 'Flea Market'
    },
    {
        id: 59,
        src: 'farmingExperience',
        title: 'Farming Experience'
    },
    {
        id: 60,
        src: 'fishingExperience',
        title: 'Fishing Experience'
    },
    {
        id: 61,
        src: 'tidelandExperience',
        title: 'Tideland Experience'
    },
    {
        id: 62, 
        src: 'ballGame',
        title: 'Ball Games'
    },
    {
        id: 63,
        src: 'trampoline',
        title: 'Trampoline'
    },
    {
        id: 64,
        src: 'bicycle',
        title: 'Bicycle'
    },
    {
        id: 65,
        src: 'boat',
        title: 'Boat'
    },
    {
        id: 66,
        src: 'waterLeisure',
        title: 'Water Leisure'
    },
    {
        id: 67,
        src: 'winterLeisureSports',
        title: 'Winter Leisure'
    },
    {
        id: 68,
        src: 'movieWatching',
        title: 'Movie Watching'
    },
    {
        id: 69,
        src: 'culturalVisit',
        title: 'Cultural Visit'
    },
    {
        id: 70,
        src: 'tastingFoodDrink',
        title: 'Tasting Food/Drink'
    },
    {
        id: 71,
        src: 'shopping',
        title: 'Shopping'
    },
    {
        id: 72,
        src: 'fieldTrip',
        title: 'Field Trip'
    }
]

export const _AllowedDetails = [
    {
        id: 73,
        src: 'rooftopPitching',
        title: 'Rooftop Pitching'
    },
    {
        id: 74,
        src: 'tentPitchingSmall',
        title: 'Tent Pitching small'
    },
    {
        id: 75,
        src: 'picnicTable',
        title: 'Picnic Table'
    },
    {
        id: 76,
        src: 'tarpAwning',
        title: 'Tarp / Awning Pitching'
    },
    {
        id: 77,
        src: 'tentPitchingLarge',
        title: 'Tent Pitching large'
    },
    {
        id: 78,
        src: 'outdoorCooking',
        title: 'Outdoor Cooking'
    }
]

export const _ProhibitedDetails = [
    {
        id: 79,
        src: 'noSmoking',
        title: 'No Smoking'
    },
    {
        id: 80,
        src: 'noUrinate',
        title: 'No Urinate'
    },
    {
        id: 81,
        src: 'noCampfire',
        title: 'No Campfire'
    },
    {
        id: 82,
        src: 'noOutdoorCooking',
        title: 'No Outdoor Cooking'
    },
    {
        id: 83,
        src: 'noDumpTrash',
        title: 'No Dump Trash'
    },
    {
        id: 84,
        src: 'noGrillUse',
        title: 'No Grill Use'
    }
]