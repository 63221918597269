import React, { useState, useTransition } from "react";

import AuthUtil from "components/Auth/AuthUtil";
import HeadLogo from "components/Auth/authLogo";
import Button from "elements/Button";
import Input from "elements/Input";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ForgetPassword = () => {
  const {t} = useTranslation()
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [currentCode, setCurrentCode] = useState("");
  const [isError, setIsError] = useState(false);

  const resetPassword = () => {
    requestToMustOutdoor({
      method:"POST",
      url:`/auth/find/password?email=${email}`,
    })
    .then((res) => {
      setIsError(false);
      localStorage.setItem("email", email);
      navigate('/log-in/auth-pass')
      return res.data.data
    })
    .catch((err) => {
      setCurrentCode(t("Account.Please enter a valid email format"))
      setIsError(true);
      //console.logerr)
      return err
    })
  }
  return (
    <div>
      <HeadLogo>
        <AuthUtil
          AuthIcon={"forgot-password"}
          title={t("Forgot your password?")}
          content={'Please enter the email address associated with your'}
        />
        <Input
          isError={isError}
          isIcon
          onChange={(e) => {setEmail(e.target.value)}}
          inputIcon={"email-address"}
          placeholder={t("Account.Email Address")}
          type={""}
        >{currentCode}</Input>
        <div className="text-base text-pure-white">
          <Button
            onClick={() => {
              resetPassword();
            }}
            className="inline-block mx-auto mt-2 mb-4 bg-light-green text-pure-white text-xs rounded-lg py-2 w-[320px]"
            btnText={t("Account.Send reset password code")}
          />
        </div>
      </HeadLogo>
    </div>
  );
};

export default ForgetPassword;
