import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
    spaces:[]
}

const initialState:InitialState = {
    spaces:[]
}

const spaceListSlice = createSlice({
    name: "spaces",
    initialState,
    reducers:{
        spaces: (state, action:PayloadAction<any>) => {
            state.spaces = action.payload;
        }
    }
})

export default spaceListSlice;
export const {spaces} = spaceListSlice.actions;