import React from "react";



import { useTranslation } from "react-i18next";
import Button from "elements/Button";
import { useNavigate } from "react-router-dom";

const PartnerWithUs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="web:px-28 phone:px-4 py-8 bg-black bg-opacity-50 relative">
      <div
        className="bg-cover bg-center absolute top-0 bottom-0 right-0 left-0 z-m-10"
        style={{ backgroundImage: `url("https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/Partner-with-us-mainpage.jpg")` }}
      />
      <div className="text-center flex flex-col items-center">
        <div className="web:text-2xl web:pb-8 phone:text-base phone:pb-4 font-bold text-pure-white drop-shadow">
          {t("Main.Partner with us")}
        </div>
        <img
          alt="partnerWithUs"
          src={`${process.env.REACT_APP_S3_URL}host-account-mainpage.svg`}
          className="m-auto web:mb-8 web:w-24 web:h-19 phone:mb-4 phone:w-16 phone:h-16"
        />
        <div className="web:text-2xl phone:text-base font-bold text-pure-white mb-4">
          {t("Main.Meet travlers")}
        </div>
        <div className="web:text-base phone:text-sm web:mb-8 phone:mb-4 text-pure-white">
          {t("Main.Light up your outdoor accommodation")}
        </div>
        <Button
          onClick={() => {
            navigate("/partner");
          }}
          btnText={t("Main.Learn More")}
          className="w-30 h-[2.6875rem] cursor-pointe bg-light-green flex justify-center items-center text-pure-white font-bold rounded-lg"
        />
      </div>
    </div>
  );
};

export default PartnerWithUs;
