import React, { useEffect } from "react";
import Icons from "./Icons";

interface ToastAlertProps {
  text: string;
  type: string;
  iconName?: string;
  setToastState?: any;
}

const ToastAlert = ({
  text,
  type,
  iconName,
  setToastState,
}: ToastAlertProps) => {
  useEffect(() => {
    let timer = setTimeout(() => {
      setToastState(false);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [setToastState]);

  const setBgColor = () => {
    switch (type) {
      case "Success":
        return "bg-light-green-op";
      case "Fail":
        return "bg-red";
    }
  };

  const bgColor = setBgColor();

  return (
    <div className={`toast-container animate-fadeUp ${bgColor}`}>
      <div className="flex justify-between">
        <div className="flex items-center my-[6px] flex-1 py-[6px]">
          {iconName ? (
            <Icons iconName={`${iconName}`} className="w-4 h-4 mr-2"></Icons>
          ) : (
            ""
          )}
          <div className="text-pure-white">{text ? text : ""}</div>
        </div>
        <Icons
          onClick={() => setToastState(false)}
          iconName="menu-close"
          className="w-4 h-4"
        ></Icons>
      </div>
    </div>
  );
};

export default ToastAlert;
