import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface sideMenuProps {
  onClick?: () => void;
  left?: boolean;

  isOpenTab?: boolean | any;
  setIsOpenTab?: any;
  children?: any;
  tabTitle?: string;
  noPadding?: boolean;
}

const SideMenu = ({
  isOpenTab,
  setIsOpenTab,
  onClick,
  left,
  children,
  tabTitle,
  noPadding,
}: sideMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>();

  const openToggle = () => {
    setIsOpenTab(false);
  };
  useEffect(() => {
    setIsOpen(isOpenTab);
  }, [isOpenTab]);

  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isOpen]);

  return (
    <div
      className={`transition-all h-full overflow-y-scroll w-full z-[10000] web:hidden phone:block bg-pure-white fixed top-0 ${
        isOpen ? " translate-x-0" : "translate-x-[1024px]"
      }`}
    >
      <div className="flex justify-between items-center px-4 shadow-miniCard py-2">
        {left ? <img onClick={onClick} className="cursor-pointer w-3 h-3" src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-left.svg" alt="" /> : <div/>}
        <div className="">{t(`${tabTitle}`)}</div>
        <img
          onClick={() => openToggle()}
          src={`${process.env.REACT_APP_S3_URL}menu-close.svg`}
          className="inline-block cursor-pointer"
          alt="menu-close"
        />
      </div>
      <div className={`${noPadding ? "" : "p-4"} `}>{children}</div>
    </div>
  );
};

export default SideMenu;
