import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icons from "elements/Icons";

type Props = {
    children: any
  };

const AuthLogo:React.FC<Props> = ({children}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div
      className="lg:max-w-full w-[full] h-screen min-h-[800px] block bottom-0 left-0 pt-8 text-center bg-center bg-cover bg-no-repeat"
      style={{ backgroundImage: `url("https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/Login.jpg")` }}
    >
      <div>
          <Icons
          onClick={() => {navigate("/")}}
            className="w-63 h-12 cursor-pointer text-center inline-block"
            iconName="must-logo-white"
            logo
            extension="png"
          />
        <p className="text-xs text-pure-white">
          Adventure awaits you! Go Play The Great Outdoors!
        </p>
      </div>
      {children}
    </div>
  );
};
export default AuthLogo;
