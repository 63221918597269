import React from "react";

interface messageNotiCardProps {
  image?: string;
  sender?: string;
  title?: string;
  createdAt?: string;
  description?: string;
  newMessage?: boolean;
}

const MessageNotiCard = ({
  image,
  sender,
  title,
  createdAt,
  description,
  newMessage,
}: messageNotiCardProps) => {
  return (
    <div className="w-full web:py-4 web:px-0 phone:p-4">
      <div className="flex justify-between items-start mb-2">
        <div className="flex items-center">
          <img
            className="w-12 h-12 mr-2"
            src={
              image
                ? image
                : "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/Logo/must-small-logo-color.png"
            }
            alt=""
          />
          <div>
            <div className="text-sm">MUST OUTDOOR</div>
            <div className="font-bold">Welcome to MUST OUTDOOR!</div>
          </div>
        </div>
        <div className="flex items-center">
          {newMessage ? (
            <div className="text-sm font-bold text-gold mr-4">New</div>
          ) : (
            ""
          )}
          <div className="text-xs">3:29 pm Jan 07, 2023</div>
        </div>
      </div>
      <div className="text-sm">Hello! first_name last_name</div>
    </div>
  );
};

export default MessageNotiCard;
