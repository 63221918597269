import React, { useEffect, useRef, useState } from "react";


import Button from "./Button";
import { t } from "i18next";
import { time } from "console";
import { useTranslation } from "react-i18next";
import Icons from "./Icons";

interface InputProps {
  onClick?: () => void;
  onChange?: (e: any) => void;

  timeCounter?: boolean;
  className?: string;
  inputIcon: any;
  placeholder: string;
  type?: string;
  title?: string;
  children?: any;

  password?: boolean;

  isIcon?: boolean;
  isBtn?: boolean;
  onKeyPress?: any;

  png?: boolean;

  nameReq?: boolean;
  emailReq?: boolean;
  passwordReq?: boolean;
  pwCheckReq?: boolean;
  isError?: boolean;
}

const Input = ({
  children,
  onClick,
  className,
  onChange,
  timeCounter,
  inputIcon,
  placeholder,
  type,
  // input 왼쪽에 아이콘이 있을때 사용
  isIcon,
  // 버튼이 있을 때 사용
  isBtn,

  title,

  password,
  png,

  nameReq,
  emailReq,
  passwordReq,
  onKeyPress,
  pwCheckReq,
  isError,
}: InputProps) => {
  const { t } = useTranslation();

  const [passwordView, setPasswordView] = useState(false);

  return (
    <div className="inline-block">
      <div className={`inline-block justify-center items-center relative mt-2`}>
        {isIcon ? (
          <img
            src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/${inputIcon}.${
              png ? "png" : "svg"
            }`}
            className="w-4 h-4 absolute bottom-2 left-4"
            alt=""
          />
        ) : (
          ""
        )}
        <div className="flex relative">
          <input
            onKeyPress={onKeyPress}
            onChange={onChange}
            name="name"
            type={passwordView ? "text" : type}
            placeholder={t(`${placeholder}`)}
            className={`${isIcon ? "pl-12" : "pl-4"} ${
              isError ? "border-2 border-red" : ""
            } ${isBtn ? "w-[264px] rounded-l-lg" : "w-80 rounded-lg"}
          bg-pure-black bg-opacity-50 text-pure-white shadow-ms h-8 text-sm
          `}
          />
          {password ? (
            <div>
              <Icons
                iconName={passwordView ? "show" : "hide"}
                onMouseup={(e) => setPasswordView(false)}
                onMousedown={(e) => {
                  setPasswordView(true);
                }}
                className="web:block phone:hidden absolute right-2 top-[6px] w-5 h-5 cursor-pointer"
              />
              <Icons
                iconName={passwordView ? "show" : "hide"}
                onClick={() => setPasswordView((passwordView) => !passwordView)}
                className="phone:block web:hidden absolute right-2 top-[6px] w-5 h-5 cursor-pointer"
              />
            </div>
          ) : (
            ""
          )}
          {isBtn ? (
            <Button
              onClick={onClick}
              className="text-pure-white inline-block text-sm rounded-lg bg-light-green py-[5.5px] px-1 min-w-[56.45px]"
              btnText={t("Account.Check")}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        className={`${className} ${
          isError ? "text-pure-red" : "text-light-green"
        } mt-2 font-bold flex text-xs text-pure-white`}
      >
        {children}
      </div>
    </div>
  );
};

export default Input;
