import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "redux/store";

import AcWrap from "elements/AcWrap";
import Button from "elements/Button";
import Modal from "elements/Modal";
import CheckBoxDefault from "elements/CheckBoxDefault";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { inputMaxLength } from "utils/inputMaxLength";

import {
  extraGuest,
  numberOfGuest,
  ParkingType,
  unitNumber,
  Vehicle,
} from "../constants";
import { spGeneralInfo } from "redux/slice/spaceSlice";
import { useTranslation } from "react-i18next";
import { inputPriceFormat } from "utils/commaDash";

interface GeneProps {
  onNext?: any;
  geneData?: any;
}

const AcGeneralInfo = ({ onNext, geneData }: GeneProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [typeSelect, setTypeSelect] = useState(false);
  const [types, setTypes] = useState([]);

  const [type, setType] = useState<number>(0);

  const preData = useAppSelector((state) => state.space.generalInfo);

  const page = preData;

  const handleType = (checked: string, item: number, e: any) => {
    // const checkboxes:any = document.getElementsByName('types')
    // for (let i = 0; i < checkboxes.length; i++) {
    //   if (checkboxes[i] !== e.target) {
    //     checkboxes[i].checked = false
    //   }
    // }
    if (checked && !type) {
      const result = Number(item);
      setType(result);
    } else if (!checked) {
      setType(0);
    }
    if (type && checked) {
      alert("1개만 선택 가능합니다");
      e.target.checked = false;
    }
  };

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/space/type`,
    })
      .then((res) => {
        const data = res.data.data;
        setTypes(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setSpaceGeneData({ ...spaceGeneData, typeId: type });
  }, [type]);

  const [spaceGeneData, setSpaceGeneData] = useState({
    title: "",
    description: "",
    typeId: 0,
    unitCount: 0,
    totalGuestAmount: 0,
    bedAmount: 0,
    minStay: 0,
    spaceSize: "",
    availableExtraGuest: 0,
    infantExtraCharge: "",
    childrenExtraCharge: "",
    adultExtraCharge: "",
    parkingType: "",
    accessibleVehicle: "",
    carAmount: 0,
    extraCarAmount: 0,
    extraCarCharge: "",
  });

  useEffect(() => {
    const data = spaceGeneData?.accessibleVehicle;
    if (data === "Only Backpacker") {
      setSpaceGeneData({
        ...spaceGeneData,
        accessibleVehicle: "BACKPACKER",
      });
    } else if (spaceGeneData?.parkingType === "No Parking Lot") {
      setSpaceGeneData({
        ...spaceGeneData,
        accessibleVehicle: "BACKPACKER",
        carAmount: 0,
        extraCarAmount: 0,
        extraCarCharge: "0",
      });
    } else if (data === "Up to compact / light size") {
      setSpaceGeneData({
        ...spaceGeneData,
        accessibleVehicle: "AV1",
      });
    } else if (data === "Up to mid / large size") {
      setSpaceGeneData({
        ...spaceGeneData,
        accessibleVehicle: "AV2",
      });
    } else if (data === "Up to compact RV (3 pax)") {
      setSpaceGeneData({
        ...spaceGeneData,
        accessibleVehicle: "AV3",
      });
    } else if (data === "Up to mid / large RV (5 pax)") {
      setSpaceGeneData({
        ...spaceGeneData,
        accessibleVehicle: "AV4",
      });
    } else if (data === "Small/mid Caravan or Trailer(3 pax)") {
      setSpaceGeneData({
        ...spaceGeneData,
        accessibleVehicle: "AV5",
      });
    } else if (data === "Large Caravan or Trailer (5 pax)") {
      setSpaceGeneData({
        ...spaceGeneData,
        accessibleVehicle: "AV6",
      });
    }
  }, [spaceGeneData?.accessibleVehicle, spaceGeneData?.parkingType]);

  const nextPage = () => {
    geneData(spaceGeneData);
    dispatch(spGeneralInfo(spaceGeneData));
    onNext();
  };

  useEffect(() => {
    setSpaceGeneData({
      ...spaceGeneData,
      title: page?.title,
      description: page?.description,
      unitCount: page?.unitCount,
      totalGuestAmount: page?.totalGuestAmount,
      bedAmount: page?.bedAmount,
      minStay: page?.minStay,
      spaceSize: String(Math.floor(Number(page?.spaceSize))),
      availableExtraGuest: page?.availableExtraGuest,
      infantExtraCharge: page?.infantExtraCharge,
      childrenExtraCharge: page?.childrenExtraCharge,
      adultExtraCharge: page?.adultExtraCharge,
      parkingType: page?.parkingType,
      carAmount: page?.carAmount,
      extraCarAmount: page?.extraCarAmount,
      extraCarCharge: page?.extraCarCharge,
    });
    setType(page?.typeId);
  }, [
    page?.adultExtraCharge,
    page?.availableExtraGuest,
    page?.bedAmount,
    page?.carAmount,
    page?.childrenExtraCharge,
    page?.description,
    page?.extraCarAmount,
    page?.extraCarCharge,
    page?.infantExtraCharge,
    page?.minStay,
    page?.parkingType,
    page?.spaceSize,
    page?.title,
    page?.totalGuestAmount,
    page?.typeId,
    page?.unitCount,
  ]);

  return (
    <div className="py-12 flex">
      {typeSelect ? (
        <Modal typesWidth="web:w-160 web:h-[650px] overflow-y-scroll overflow-hidden">
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold">
              {t(`Add-space.Space Type*`)}
            </div>
            <img
              onClick={() => {
                setTypeSelect(false);
              }}
              className="w-4 h-4 cursor-pointer"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/menu-close.svg"
              alt=""
            />
          </div>
          <div className="mt-8">
            <div>
              <div className="flex items-center">
                <img
                  className="w-4 h-4 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/glamping-type.svg"
                  alt=""
                />
                <div className="text-sm font-bold">
                  {t(`Space-type-data.Glamping Types`)}
                </div>
              </div>
              <div
                className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
              >
                {types?.map((item: any, idx: number) => {
                  const iconUrl = item.iconUrl;
                  const enName = item.enName;
                  const id = item.id;
                  const category = item.category;
                  if (category === "Glamping Types") {
                    return (
                      <CheckBoxDefault
                        onChange={(e: any) => {
                          handleType(e.target.checked, e.target.id, e);
                        }}
                        defaultChecked={type === id ? true : false}
                        typesModal="mr-0"
                        key={idx}
                        name="types"
                        id={id}
                        imageUrl={iconUrl}
                        title={`Space-type-data.${enName}`}
                        value={enName}
                      />
                    );
                  }
                })}
              </div>
              <hr className="mb-8 mt-4 text-input-border-color" />
            </div>
            <div>
              <div className="flex items-center">
                <img
                  className="w-4 h-4 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/caravan-type.svg"
                  alt=""
                />
                <div className="text-sm font-bold">
                  {t(`Space-type-data.Caravan Types`)}
                </div>
              </div>
              <div
                className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
              >
                {types?.map((item: any, idx: number) => {
                  const iconUrl = item.iconUrl;
                  const enName = item.enName;
                  const id = item.id;
                  const category = item.category;
                  if (category === "Caravan Types") {
                    return (
                      <CheckBoxDefault
                        onChange={(e: any) => {
                          handleType(e.target.checked, e.target.id, e);
                        }}
                        defaultChecked={type === id ? true : false}
                        typesModal="mr-0"
                        key={idx}
                        name="types"
                        id={id}
                        imageUrl={iconUrl}
                        title={`Space-type-data.${enName}`}
                        value={enName}
                      />
                    );
                  }
                })}
              </div>
              <hr className="mb-8 mt-4 text-input-border-color" />
            </div>
            <div>
              <div className="flex items-center">
                <img
                  className="w-4 h-4 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/campground-type.svg"
                  alt=""
                />
                <div className="text-sm font-bold">
                  {t(`Space-type-data.Campground Types`)}
                </div>
              </div>
              <div
                className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
              >
                {types?.map((item: any, idx: number) => {
                  const iconUrl = item.iconUrl;
                  const enName = item.enName;
                  const id = item.id;
                  const category = item.category;
                  if (category === "Campground Types") {
                    return (
                      <CheckBoxDefault
                        onChange={(e: any) => {
                          handleType(e.target.checked, e.target.id, e);
                        }}
                        defaultChecked={type === id ? true : false}
                        typesModal="mr-0"
                        key={idx}
                        name="types"
                        id={id}
                        imageUrl={iconUrl}
                        title={`Space-type-data.${enName}`}
                        value={enName}
                      />
                    );
                  }
                })}
              </div>
              <hr className="mb-8 mt-4 text-input-border-color" />
            </div>
            <div>
              <div className="flex items-center">
                <img
                  className="w-4 h-4 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/eco-friendly-type.svg"
                  alt=""
                />
                <div className="text-sm font-bold">
                  {t(`Space-type-data.Eco Friendly Types`)}
                </div>
              </div>
              <div
                className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
              >
                {types?.map((item: any, idx: number) => {
                  const iconUrl = item.iconUrl;
                  const enName = item.enName;
                  const id = item.id;
                  const category = item.category;
                  if (category === "Eco Friendly Types") {
                    return (
                      <CheckBoxDefault
                        onChange={(e: any) => {
                          handleType(e.target.checked, e.target.id, e);
                        }}
                        defaultChecked={type === id ? true : false}
                        typesModal="mr-0"
                        key={idx}
                        id={id}
                        name="types"
                        imageUrl={iconUrl}
                        title={`Space-type-data.${enName}`}
                        value={enName}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setTypeSelect(false);
            }}
            className="mt-4 text-right cursor-pointer text-sm hover:text-light-green"
          >
            {t("Space-type-data.Apply")}
          </div>
        </Modal>
      ) : (
        ""
      )}
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          inputValue={spaceGeneData?.title}
          title={t("Add-space.Title*")}
          onChange={(e) => {
            setSpaceGeneData({ ...spaceGeneData, title: e.target.value });
          }}
          help
          placeholder={"Add-space.Name of Space"}
          input
          inputMaxLength={50}
          toolTitle="공간의 이름을 입력해주세요."
          toolSubTitle="해당 방 또는 캠프 사이트 특색에 맞춰 이름을 만들어 주세요."
          toolEx="Ex) 럭셔리 사파리 텐트, 계곡옆 데크 사이트 A, 트리하우스 등"
        ></AcWrap>
        <AcWrap
          inputValue={spaceGeneData?.description}
          onChange={(e) => {
            setSpaceGeneData({ ...spaceGeneData, description: e.target.value });
          }}
          title="Add-space.Description*"
          textArea
          inputMaxLength={500}
          placeholder="Add-space.Describe the space for traveler"
          row={5}
          toolTitle="공간에 대해 설명해주세요."
          toolSubTitle="게스트들이 해당 공간에 대해 알기 쉽도록 특색과 매력적인 부분들을 
          설명해주세요."
        />
        <AcWrap
          help
          toolTitle="공간 타입 선택과 유닛수를 설정해주세요."
          toolSubTitle="먼저, 해당 공간에 가장 적절한 타입을 1개 선택해주세요. 그리고 운영 가능한 
        유닛수를 설정하세요. (하루에 예약이 가능한 수량)"
          title="Add-space.Space Type*"
        >
          <div className="mb-4">
            <div className="text-sm">{t("Add-space.Selected space type")}</div>
            <div
              onClick={() => {
                setTypeSelect(true);
              }}
              className="acSubInput cursor-pointer"
            >
              {types && type !== 0 && types
                ? types?.map((item: any, idx) => {
                    const id = item.id;
                    const category = item.category;
                    const iconUrl = item.iconUrl;
                    const enName = item.enName;
                    if (type === id) {
                      return (
                        <div key={idx} className="flex text-sm items-center">
                          <img
                            className="w-5 h-5 mr-2"
                            src={`${
                              category === "Glamping Types"
                                ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/glamping-type.svg"
                                : category === "Caravan Types"
                                ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/caravan-type.svg"
                                : category === "Campground Types"
                                ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/campground-type.svg"
                                : category === "Eco Friendly Types"
                                ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/eco-friendly-type.svg"
                                : ""
                            }`}
                            alt={enName}
                          />
                          <div>{t(`Space-type-data.${category}`)}</div>
                          <div className="mx-4">|</div>
                          <img
                            className="w-5 h-5 mr-2"
                            src={iconUrl}
                            alt={enName}
                          />
                          <div>{t(`Space-type-data.${enName}`)}</div>
                        </div>
                      );
                    }
                  })
                : t("Add-space.Select Space Type")}
            </div>
          </div>
          <div>
            <div className="text-sm">{t("Add-space.Number of Unit")}</div>
            <select
              value={spaceGeneData?.unitCount}
              onChange={(e) => {
                setSpaceGeneData({
                  ...spaceGeneData,
                  unitCount: Number(e.target.value),
                });
              }}
              className="acSubInput"
            >
              <option value="">{t(`Add-space.Select`)}</option>
              {unitNumber?.map((item, idx) => {
                return (
                  <option key={idx} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </AcWrap>
        <AcWrap
          help
          toolTitle="공간 및 운영에 관한 정보입니다."
          toolSubTitle="수용 가능한 기준 인원, 침대 개수, 최소 숙박 일수, 공간 사이즈를 설정해주세요."
          title="Add-space.Space Details*"
        >
          <div className="flex web:flex-row phone:flex-col justify-between mb-4">
            <div className="web:mb-0 phone:mb-2">
              <div className="text-sm">
                {t(`Add-space.Number of Guest* (Occupancy)`)}
              </div>
              <select
                value={spaceGeneData?.totalGuestAmount}
                onChange={(e) => {
                  setSpaceGeneData({
                    ...spaceGeneData,
                    totalGuestAmount: Number(e.target.value),
                  });
                }}
                className="acSubMinInput"
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {numberOfGuest?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <div className="text-sm">{t(`Add-space.Number of Beds*`)}</div>
              <select
                value={spaceGeneData?.bedAmount}
                onChange={(e) => {
                  setSpaceGeneData({
                    ...spaceGeneData,
                    bedAmount: Number(e.target.value),
                  });
                }}
                className="acSubMinInput"
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {unitNumber?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="flex web:flex-row phone:flex-col justify-between">
            <div className="web:mb-0 phone:mb-2">
              <div className="text-sm">{t(`Add-space.Minimum Stay(day)*`)}</div>
              <select
                value={spaceGeneData?.minStay}
                onChange={(e) => {
                  setSpaceGeneData({
                    ...spaceGeneData,
                    minStay: Number(e.target.value),
                  });
                }}
                className="acSubMinInput"
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {unitNumber?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <div className="text-sm">{t(`Add-space.Space Size`)}</div>
              <div className="relative">
              <input
                defaultValue={spaceGeneData?.spaceSize}
                onWheel={(e: any) => e.target.blur()}
                onChange={(e) => {
                  const currentSpace = Number(e.target.value);
                  setSpaceGeneData({
                    ...spaceGeneData,
                    spaceSize: String(currentSpace.toFixed(2)),
                  });
                }}
                placeholder={t("Add-space.Input space size")}
                type="number"
                onInput={(e) => {
                  inputMaxLength(e, 3);
                }}
                className="acSubMinInput"
                />
                <div className="absolute top-[14.5px] right-2 text-sm">㎡</div>
                </div>
            </div>
          </div>
        </AcWrap>
        <AcWrap
          help
          toolTitle="추가 인원 정책을 설정해주세요."
          toolSubTitle="추가 가능 여부 및 인원, 그리고 연령에 맞춰 가격을 설정해주세요. 가격 입력 
        부분을 공란으로 두실 경우 무료로 설정됩니다."
          title="Add-space.Extra Guests"
        >
          <div className="mb-4">
            <div className="text-sm">
              {t(`Add-space.Available Extra Guests`)}
            </div>
            <select
              value={spaceGeneData?.availableExtraGuest}
              onChange={(e) => {
                setSpaceGeneData({
                  ...spaceGeneData,
                  availableExtraGuest: Number(e.target.value),
                });
              }}
              className="acSubInput mt-2"
            >
              <option value="0">{t(`Add-space.None`)}</option>
              {extraGuest?.map((item, idx) => {
                return (
                  <option key={idx} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="flex web:flex-row phone:flex-col justify-between mb-4">
            <div className="web:mb-0 phone:mb-2">
              <div className="text-sm">{t(`Add-space.Guest Title`)}</div>
              <div className="acSubMinInput">
                {t(`Add-space.Adults (Age 13+)`)}
              </div>
            </div>
            <div>
              <div className="text-sm">
                {t(`Add-space.Extra Price / Guest`)}
              </div>
              <div className="relative">
                <input
                  value={spaceGeneData?.adultExtraCharge}
                  onWheel={(e: any) => e.target.blur()}
                  onChange={(e) => {
                    setSpaceGeneData({
                      ...spaceGeneData,
                      adultExtraCharge: inputPriceFormat(e.target.value),
                    });
                  }}
                  placeholder={t("Add-space.Input price")}
                  className="acSubMinInput pl-6"
                />
                <div className="absolute top-[16px] text-sm left-[8px]">₩</div>
              </div>
            </div>
          </div>
          <div className="flex web:flex-row phone:flex-col justify-between mb-4">
            <div className="acSubMinInput">
              {t(`Add-space.Children (Ages 2–12)`)}
            </div>
            <div className="relative">
              <input
                value={spaceGeneData?.childrenExtraCharge}
                onWheel={(e: any) => e.target.blur()}
                onChange={(e) => {
                  setSpaceGeneData({
                    ...spaceGeneData,
                    childrenExtraCharge: inputPriceFormat(e.target.value),
                  });
                }}
                placeholder={t("Add-space.Input price")}
                className="acSubMinInput pl-6"
              />
              <div className="absolute top-[16px] text-sm left-[8px]">₩</div>
            </div>
          </div>
          <div className="flex web:flex-row phone:flex-col justify-between">
            <div className="acSubMinInput">
              {t(`Add-space.Infant (Under 2)`)}
            </div>
            <div className="relative">
              <input
                value={spaceGeneData?.infantExtraCharge}
                onWheel={(e: any) => e.target.blur()}
                onChange={(e) => {
                  setSpaceGeneData({
                    ...spaceGeneData,
                    infantExtraCharge: inputPriceFormat(e.target.value),
                  });
                }}
                placeholder={t("Add-space.Input price")}
                className="acSubMinInput pl-6"
              />
              <div className="absolute top-[16px] text-sm left-[8px]">₩</div>
            </div>
          </div>
        </AcWrap>
        <AcWrap
          help
          toolTitle="해당 공간의 주차 기준을 설정해주세요."
          toolSubTitle="먼저, 주차 타입, 접근 가능 차량의 크기, 그리고 주차 가능 대수를 선택해주세요.
        만약 해당 공간(사이트)의 추가 주차가 가능하다면,  추가 가능 대수와 가격을
        설정해주세요."
          title="Add-space.Parking Details*"
        >
          <div className="flex web:flex-row phone:flex-col justify-between mb-4">
            <div className="web:mb-0 phone:mb-2">
              <div className="text-sm">{t(`Add-space.Parking Type*`)}</div>
              <select
                value={spaceGeneData?.parkingType}
                onChange={(e) => {
                  setSpaceGeneData({
                    ...spaceGeneData,
                    parkingType: e.target.value,
                  });
                }}
                className="acSubMinInput"
                name=""
                id=""
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {ParkingType?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {t(`Parking-accessible-data.${item}`)}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <div className="text-sm">{t(`Add-space.Accessible Vehicle`)}</div>
              <select
                disabled={
                  spaceGeneData.parkingType === "No Parking Lot" ? true : false
                }
                onChange={(e) => {
                  setSpaceGeneData({
                    ...spaceGeneData,
                    accessibleVehicle: e.target.value,
                  });
                }}
                className="acSubMinInput"
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {Vehicle?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {t(`Parking-accessible-data.${item}`)}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="flex web:flex-row phone:flex-col justify-between mb-4">
            <div className="web:mb-0 phone:mb-2">
              <div className="text-sm">{t(`Add-space.Number of Car*`)}</div>
              <select
                disabled={
                  spaceGeneData.parkingType === "No Parking Lot" ? true : false
                }
                value={spaceGeneData?.carAmount}
                onChange={(e) => {
                  setSpaceGeneData({
                    ...spaceGeneData,
                    carAmount: Number(e.target.value),
                  });
                }}
                className="acSubMinInput"
                name=""
                id=""
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {extraGuest?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {item} {t(`Parking-accessible-data.Car`)}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <div className="text-sm">
                {t(`Add-space.Available Extra Car*`)}
              </div>
              <select
                disabled={
                  spaceGeneData.parkingType === "No Parking Lot" ? true : false
                }
                value={spaceGeneData?.extraCarAmount}
                onChange={(e) => {
                  setSpaceGeneData({
                    ...spaceGeneData,
                    extraCarAmount: Number(e.target.value),
                  });
                }}
                className="acSubMinInput"
                name=""
                id=""
              >
                <option value="">{t(`Add-space.Select`)}</option>
                {extraGuest?.map((item, idx) => {
                  return (
                    <option key={idx} value={item}>
                      {item} {t(`Parking-accessible-data.Extra Car`)}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div>
            <div className="text-sm">
              {t(`Add-space.Extra Price / per car*`)}
            </div>
            <div className="relative">
              <input
                disabled={
                  spaceGeneData.parkingType === "No Parking Lot" ? true : false
                }
                value={spaceGeneData?.extraCarCharge}
                onWheel={(e: any) => e.target.blur()}
                onChange={(e) => {
                  setSpaceGeneData({
                    ...spaceGeneData,
                    extraCarCharge: inputPriceFormat(e.target.value),
                  });
                }}
                placeholder={t("Add-space.Input price")}
                className="acSubMinInput pl-5"
              />
              <div className="absolute top-[16px] text-sm left-[8px]">₩</div>
            </div>
          </div>
        </AcWrap>
        <div className="mt-12 mb-8 justify-end flex">
          <Button
            onClick={() => nextPage()}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText="Add-space.Continue"
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default AcGeneralInfo;
