export const emailreg = (email: string) => {
    let emailReg =
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
      
    return emailReg.test(email);
  };
export const passwordreg = (pw: string) => {
    let pwReg =
      /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;

    return pwReg.test(pw);
};
export const phoneCheck = (mobile: any) => {
    let phone_Reg =
      /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;

    return phone_Reg.test(mobile);
  };