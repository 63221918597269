import React, { useEffect, useState } from "react";

import HostWrap from "components/Host/hostWrap";
import Button from "elements/Button";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import AddButton from "elements/AddButton";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "redux/store";
import { groundGallery } from "../../../redux/slice/addPrivateSlice";
import Icons from "elements/Icons";

interface GroundGalleryProps {
  onPre?: any;
  onNext?: any;
  galleryData: any;
}

const GroundGallery = ({ onNext, onPre, galleryData }: GroundGalleryProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentData = useAppSelector((state) => state.privateGround.Gallery);

  const [imgUrl, setImgUrl] = useState<any>([]);
  const [currentImage, setCurrentImage] = useState<any>({});

  const page = currentData;

  useEffect(() => {
    const preImage = page?.imageList;
    if (preImage?.length > 0) {
      setImgUrl([...preImage]);
    }
    const preCurrentImage = page?.currentImage;
    if (preCurrentImage?.length > 0) {
      setCurrentImage({ ...preCurrentImage });
    }
  }, [page?.currentImage, page?.imageList]);

  const HandleFeature = (e: any) => {
    let files = e.target.files[0];
    const fileSize = files.size;
    const data = {
      multipartFiles: files,
    };
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 1024 ** 2 * 5) {
        return alert(`사이즈가 5mb 보다 큰 파일이 있습니다.`);
      }
    }
    if (imgUrl?.length > 20) {
      return alert("최대 업로드 갯수는 20개 입니다.");
    } else {
      requestToMustOutdoor
        .post("/guest/upload/file", data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        })
        .then((res) => {
          const result = res.data.data;
          const imgName = result[0];
          const imgUrl_ = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${imgName}`;
          setCurrentImage({
            fileName: imgName,
            url: imgUrl_,
            profile: true,
          });
          return res.data.data;
        })
        .catch((err) => {});
    }
  };

  const HandleFile = (e: any) => {
    let fileList = e.target.files;
    let files = e.target.files[0];
    const fileSize = files.size;

    const formData = new FormData();

    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].size > 1024 ** 2 * 5) {
        return alert(`사이즈가 5mb 보다 큰 파일이 있습니다.`);
      } else {
        formData.append("multipartFiles", fileList[i]);
      }
    }

    // 1024 ** 2 * 5 = 5MB;
    if (imgUrl?.length > 20) {
      return alert("최대 업로드 갯수는 20개 입니다.");
    } else {
      requestToMustOutdoor({
        method: "POST",
        url: "/guest/upload/file",
        headers: { "Content-Type": "multipart/form-data", accept: "*/*" },
        data: formData,
      })
        .then((res) => {
          const result = res.data.data;
          result?.map((item: string) => {
            const imgUrl_ = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${item}`;
            setImgUrl((pre: any) => [
              ...pre,
              {
                fileName: item,
                url: imgUrl_,
                profile: false,
              },
            ]);
          });
          return res.data.data;
        })
        .catch((err) => {});
    }
  };
  const data = {
    currentImage,
    images: imgUrl,
  };

  console.log(data);

  const handleGalleryData = () => {
    if (!currentImage?.fileName && !currentImage?.url) {
      return alert("대표 이미지를 등록해주세요.");
    } else if (imgUrl?.length < 4) {
      return alert("공간이미지를 최소 4장 이상 등록해주시기 바랍니다.");
    }
    onNext();
    dispatch(groundGallery(data));
    galleryData(data);
    // }
  };
  const Prev = () => {
    onPre();
  };
  const featureInput = React.useRef<any>(null);
  const featureClick = (e: any) => {
    featureInput.current.click();
  };

  const hiddenInput = React.useRef<any>(null);
  const handleClick = (e: any) => {
    hiddenInput.current.click();
  };

  return (
    <div className="web:flex phone:block">
      <div className="web:w-160 phone:mx-4">
        <div className="text-left min-h-[28rem]">
          <HostWrap tooltipHidden title="Add-private-ground.Main Gallery">
            <input
              ref={featureInput}
              type="file"
              onChange={(e) => {
                HandleFeature(e);
                e.target.value = "";
              }}
              accept=".jpg, .jpeg, .png, .pdf"
              className="hidden"
              id="images"
            />
            {currentImage?.fileName ? (
              <div className="relative mt-4">
                <img
                  className="phone:h-[192px] web:w-52  web:h-[128px] phone:w-full phone:mb-4 web:mb-0 rounded-lg border border-input-border-color"
                  src={`${currentImage?.url}`}
                  alt="대표이미지"
                ></img>
                <div className="absolute top-2 left-2 text-gold">대표</div>
                <img
                  onClick={() => {
                    setCurrentImage({});
                  }}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                  className="absolute w-6 h-6 cursor-pointer top-2 right-2"
                  alt=""
                />
              </div>
            ) : (
              <div className="mt-4">
                <div
                  onClick={(e) => featureClick(e)}
                  className="cursor-pointer web:w-52 web:h-[128px] phone:h-48 phone:w-full rounded-lg border border-input-border-color mb-2 flex flex-col justify-center items-center"
                >
                  <Icons iconName="gallery" className="w-12 h-12" />
                  <AddButton title="Add-private-ground.Add Gallery" />
                </div>
              </div>
            )}
          </HostWrap>
          <HostWrap
            help
            column
            toolTitle={
              "Tooltip-add-private-ground.Ground gallery-tooltip title"
            }
            toolSubTitle={
              "Tooltip-add-private-ground.Ground gallery-tooltip desc"
            }
            title="Add-private-ground.Ground Gallery"
            host
          >
            <div>
              <input
                ref={hiddenInput}
                type="file"
                multiple
                onChange={(e) => {
                  HandleFile(e);
                  // e.target.value = ""
                }}
                accept=".jpg, .jpeg, .png, .pdf"
                className="hidden"
                id="images"
              />
              <div
                className={`${
                  imgUrl?.length > 0
                    ? "web:grid web:grid-cols-3 web:gap-2 mt-4"
                    : ""
                }`}
              >
                {imgUrl?.length > 0 ? (
                  imgUrl?.map((item: any, idx: number) => {
                    if (idx === 0) {
                      return (
                        <div key={idx} className="relative">
                          <img
                            className="phone:h-[192px] web:h-[128px] phone:w-full phone:mb-4 web:mb-0 rounded-lg border border-input-border-color"
                            src={`${item.url}`}
                            alt="대표이미지"
                          ></img>
                          <img
                            onClick={() => {
                              const image = imgUrl;
                              const newImage = image.filter(
                                (x: any) => x.fileName !== item.fileName
                              );
                              setImgUrl(newImage);
                            }}
                            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                            className="absolute w-6 h-6 cursor-pointer top-2 right-2"
                            alt=""
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={idx} className="relative">
                          <img
                            draggable={true}
                            key={idx}
                            alt=""
                            className="w-52 web:h-[128px] phone:w-full phone:h-48 rounded-lg border border-input-border-color mr-2 mb-2 [&:nth-child(3n)]:mr-0"
                            src={`${item.url}`}
                          />
                          <img
                            onClick={() => {
                              const image = imgUrl;
                              const newImage = image.filter(
                                (x: any) => x.fileName !== item.fileName
                              );
                              setImgUrl(newImage);
                            }}
                            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                            className="absolute w-6 h-6 cursor-pointer top-2 right-2"
                            alt=""
                          />
                        </div>
                      );
                    }
                  })
                ) : (
                  <div
                    onClick={(e) => handleClick(e)}
                    className="web:min-h-[382px] web:w-160 phone:w-full phone:h-48 rounded-lg border border-input-border-color flex flex-col justify-center items-center"
                  >
                    <Icons
                      iconName="gallery"
                      className="web:w-32 web:h-32 phone:w-16 phone:h-16"
                    />
                    <AddButton
                      title={"Add-private-ground.Add Gallery"}
                      className="my-4"
                    />
                    <div className="phone:text-sm phone:hidden text-center">
                      Upload the attractive features of your private ground that
                      guests might love.
                    </div>
                  </div>
                )}
                {imgUrl?.length > 0 && imgUrl?.length < 20 ? (
                  <div
                    onClick={(e) => handleClick(e)}
                    className="cursor-pointer web:w-52 web:h-[128px] phone:h-48 phone:w-full rounded-lg border border-input-border-color mb-2 flex flex-col justify-center items-center"
                  >
                    <Icons iconName="gallery" className="w-12 h-12" />
                    <AddButton title="Add-private-ground.Add Gallery" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </HostWrap>
        </div>
        <div className="mt-12 mb-8 justify-between flex">
          <Button
            onClick={() => {
              Prev();
            }}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-private-ground.Previous")}
          />
          <Button
            onClick={() => {
              handleGalleryData();
            }}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-private-ground.Continue")}
          />
        </div>
      </div>
      <div className="w-full ml-4"></div>
    </div>
  );
};

export default GroundGallery;
