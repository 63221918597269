import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";


// default 서버 주소
// 서버와 쿠키 공유
requestToMustOutdoor.defaults.withCredentials = true

export const privateData = createAsyncThunk('private/data', async (id: string) => {
    try {
        const res = await requestToMustOutdoor({
            method: "GET",
            url: `/guest/private-ground/${id}`
        });
        return res.data.data
    } catch (err) {
        return err
    }
});

export const previewData = createAsyncThunk('preview/data', async (id: string) => {
    try {
        const res = await requestToMustOutdoor({
            method: "GET",
            url: `/api/v2/private-ground/${id}`,
        })
        return res.data.data;
    } catch (err) {
        return err
    }
});