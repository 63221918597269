
import Icons from "elements/Icons";
import Modal from "elements/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import BookingCard from "./bookingCard";

const MemberBookingHistory = () => {
  const {t} = useTranslation();
  const [booking, setBooking] = useState<any>();
  const [clickData, setClickData] = useState<any>();
  const [reviewModal, setReviewModal] = useState(false);
  const [reviewStar, setReviewStar] = useState([false, false, false, false, false])
  const id = localStorage.getItem("m");
  const arr = [0, 1, 2, 3 ,4];

  const handleStarClick = (index:number) => {
    let clickStates = [...reviewStar];
    for (let i = 0; i < 5; i++) {
    clickStates[i] = i <= index ? true : false;
    }
    setReviewStar(clickStates);
  };

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v1/booking/fetch/${id}`,
    })
      .then((res) => {
        const value = res.data.data;

        const complete = value.map((item: any) => {
          if (item.status === "COMPLETED") {
            return item;
          }
        });
        const noUnde = complete.filter(function (x: any) {
          return x !== undefined;
        });
        setBooking(noUnde);
        return res.data.data;
      })
      .catch((err) => {
        //console.logerr);
      });
  }, []);

  const reviewHandle = (
    hostName: string,
    pg_title: string,
    reservation_id: number
  ) => {
    const clickData = {
      hostName: hostName,
      pg_title: pg_title,
      id: reservation_id,
    };
    if(clickData){
      setClickData(clickData);
      setReviewModal(true);
    }
  };


  return (
    <div>
      <div className="web:text-2xl phone:text-base phone:px-4 web:px-0 mb-8">
        {t("Booking-box-status.You have {{number}} completed outdoor life.", {number: booking && booking.length ? booking.length : "0" })}
      </div>
      {reviewModal ? (
        <Modal
          headerWrap
          setModalOpen={() => setReviewModal((reviewModal) => !reviewModal)}
          headerTextColor="text-black"
          headerBgColor="bg-yellow"
          headerTitle="리뷰쓰기 준비중"
        >
          <div className="font-bold">
            {t("Rate-review.Rate & Describe your experience", {host: clickData?.hostName})}
          </div>
          <div className="mt-4 mb-8 text-sm">
            {t("Rate-review.Now take a minute to share your experience with a quick review for next travelers.")}
          </div>
          <div className="font-bold">
            How was your stay at {clickData?.hostName} {clickData?.pg_title}?
          </div>
          <div className="mt-4 mb-8">
            <div>{t("Rate-review.Accuracy")}</div>
            <div className="text-sm text-input-text my-2">
            {t("Rate-review.Was it accurate information described in details?")}
            </div>
            <div className="flex">
            {arr.map((el, idx) => {
              return (
                <Icons onClick={() => {handleStarClick(el)}} className={`cursor-pointer mr-1 last:mr-0`} key={idx} iconName={`${reviewStar[el] ? "star" : "rating-star-e"}`}/>
                )
              })}
              </div>
          </div>
          <div className="mt-4 mb-8">
            <div>Cleanliness</div>
            <div className="text-sm text-input-text my-2">
              Was it accurate information described in details?
            </div>
            <div>리뷰 별</div>
          </div>
          <div className="mt-4 mb-8">
            <div>Communication</div>
            <div className="text-sm text-input-text my-2">
              Was it accurate information described in details?
            </div>
            <div>리뷰 별</div>
          </div>
          <div className="mt-4 mb-8">
            <div>Value</div>
            <div className="text-sm text-input-text my-2">
              Was it accurate information described in details?
            </div>
            <div>리뷰 별</div>
          </div>
        </Modal>
      ) : (
        ""
      )}
      <div className="flex web:justify-start phone:justify-center flex-wrap">
        {booking &&
          booking.map(
            (
              {
                id,
                pgId,
                hostName,
                pgTitle,
                currentImage,
                status,
                checkIn,
                checkOut,
                resoCode,
              }: any,
              idx: number
            ) => {
              if (status === "COMPLETED") {
                return (
                  <BookingCard
                    key={idx}
                    reviewClick={() => {
                      reviewHandle(hostName, pgTitle, id);
                    }}
                    id={id}
                    pgId={pgId}
                    title={pgTitle}
                    checkIn={checkIn}
                    checkOut={checkOut}
                    bookingStatus={status}
                    host={hostName}
                    imageUrl={currentImage}
                    bookingCode={resoCode}
                  />
                );
              }
            }
          )}
      </div>
    </div>
  );
};

export default MemberBookingHistory;
