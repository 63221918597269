import { useTranslation } from 'react-i18next';

export function useLanguage() {
  const { i18n } = useTranslation();

  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return {
    onChangeLanguage,
  };
}
