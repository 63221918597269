import MessageNotiCard from "pages/Host/messageNotiCard";
import React from "react";

const HostNotification = () => {
  return (
    <div>
      <MessageNotiCard />
    </div>
  );
};

export default HostNotification;
