import React, { useEffect, useState } from "react";

import HostWrap from "components/Host/hostWrap";
import HostInput from "elements/HostInput";
import Button from "elements/Button";

import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";
import { useTranslation } from "react-i18next";

import CheckBoxDefault from "elements/CheckBoxDefault";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

import { useAppDispatch, useAppSelector } from "redux/store";

import { groundDetails } from "../../../redux/slice/addPrivateSlice";

interface GroundProps {
  onPre?: any;
  onNext?: any;
  DetailsData: any;
}

const GroundDetails = ({ onNext, onPre, DetailsData }: GroundProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentData = useAppSelector((state) => state.privateGround.Details);

  const [facilityList, setFacilityList] = useState<any>([]);
  const [activityList, setActivityList] = useState<any>([]);
  const [allowedList, setAllowedList] = useState<any>([]);
  const [prohibitList, setProhibitList] = useState<any>([]);
  const [specialFacility, setSpecialFacility] = useState("");

  const [FacilityIcon, setFacilityIcons] = useState<any>([]);
  const [ActivityIcon, setActivityIcons] = useState<any>([]);
  const [AllowedIcons, setAllowedIcons] = useState<any>([]);
  const [ProhibitedIcons, setProhibitedIcons] = useState<any>([]);

  // const pageData: any = localStorage.getItem("detail");
  // const detailData: any = JSON.parse(pageData);

  const page = currentData;

  useEffect(() => {
    if (page) {
      setFacilityList([...page?.facilityIds]);
      setActivityList([...page?.activityIds]);
      setAllowedList([...page?.allowedIds]);
      setProhibitList([...page?.prohibitedIds]);
      setSpecialFacility(page?.specialFacility);
    }
  }, [page]);

  const groundDetail = {
    facilityIds: facilityList ? facilityList : page?.facilityIds,
    activityIds: activityList ? activityList : page?.activityIds,
    allowedIds: allowedList ? allowedList : page?.allowedIds,
    prohibitedIds: prohibitList ? prohibitList : page?.prohibitedIds,
    specialFacility: specialFacility
      ? TextareaReplaceNewLine(specialFacility)
      : TextareaReplaceNewLine(page?.specialFacility),
  };

  const Facility = (checked: string, item: number) => {
    if (checked) {
      const result = Number(item);
      setFacilityList([...facilityList, result]);
    } else if (!checked) {
      const result = Number(item);
      setFacilityList(facilityList?.filter((el: any) => el !== result));
    }
  };

  const Activities = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setActivityList([...activityList, result]);
    } else if (!checked) {
      const result = Number(item);
      setActivityList(activityList?.filter((el: any) => el !== result));
    }
  };

  const AllowedDetails = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setAllowedList([...allowedList, result]);
    } else if (!checked) {
      const result = Number(item);
      setAllowedList(allowedList?.filter((el: any) => el !== result));
    }
  };

  const ProhibitedDetails = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setProhibitList([...prohibitList, result]);
    } else if (!checked) {
      const result = Number(item);
      setProhibitList(prohibitList?.filter((el: any) => el !== result));
    }
  };

  const handleDetailData = () => {
    dispatch(groundDetails(groundDetail));
    onNext();
    DetailsData(groundDetail);
  };

  const Prev = () => {
    onPre();
  };

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/private-ground/fetch/icons`,
    })
      .then((res) => {
        const data = res.data.data;
        setFacilityIcons(data.facilities);
        setActivityIcons(data.activities);
        data.restricts.map((item: any) => {
          if (item.allowed === true) {
            setAllowedIcons((pre: any) => [...pre, item]);
          } else {
            setProhibitedIcons((pre: any) => [...pre, item]);
          }
        });
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="web:flex phone:block">
      <div className="web:w-160 web:mx-0 phone:mx-4">
        <div className="text-left">
          <HostWrap
            help
            host
            title="Add-private-ground.Facility"
            toolTitle="Tooltip-add-private-ground.Ground detail facility-tooltip title"
            toolSubTitle="Tooltip-add-private-ground.Ground detail facility-tooltip desc"
          >
            <div
              className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
            >
              {FacilityIcon?.map((item: any, idx: number) => {
                const iconUrl = item.iconUrl;
                const enName = item.enName;
                const id = item.id;
                return (
                  <CheckBoxDefault
                    key={idx}
                    id={id}
                    imageUrl={iconUrl}
                    defaultChecked={
                      page?.facilityIds?.includes(id) === true ? true : false
                    }
                    title={`Facility-data.${enName}`}
                    value={enName}
                    onChange={(e: any) => {
                      Facility(e.target.checked, e.target.id);
                    }}
                  />
                );
              })}
            </div>
          </HostWrap>
          <HostWrap
            host
            help
            title="Add-private-ground.Activities"
            toolTitle="Tooltip-add-private-ground.Ground detail activity-tooltip title"
            toolSubTitle="Tooltip-add-private-ground.Ground detail activity-tooltip desc"
          >
            <div
              className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
            >
              {ActivityIcon?.map((item: any, idx: number) => {
                const iconUrl = item.iconUrl;
                const enName = item.enName;
                const id = item.id;
                return (
                  <CheckBoxDefault
                    key={idx}
                    id={id}
                    imageUrl={iconUrl}
                    defaultChecked={
                      page?.activityIds?.includes(id) === true ? true : false
                    }
                    value={enName}
                    title={`Activity-data.${enName}`}
                    onChange={(e: any) =>
                      Activities(e.target.checked, e.target.id)
                    }
                  />
                );
              })}
            </div>
          </HostWrap>
          <HostWrap
            help
            host
            title="Add-private-ground.Allowed Details"
            toolTitle="Tooltip-add-private-ground.Ground detail allowed-tooltip title"
            toolSubTitle={
              "Tooltip-add-private-ground.Ground detail allowed-tooltip desc"
            }
          >
            <div
              className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
            >
              {AllowedIcons?.map((item: any, idx: number) => {
                const iconUrl = item.iconUrl;
                const enName = item.enName;
                const id = item.id;
                return (
                  <CheckBoxDefault
                    key={idx}
                    id={id}
                    imageUrl={iconUrl}
                    defaultChecked={
                      page?.allowedIds?.includes(id) === true ? true : false
                    }
                    value={enName}
                    title={`Allowed-data.${enName}`}
                    onChange={(e: any) =>
                      AllowedDetails(e.target.checked, e.target.id)
                    }
                  />
                );
              })}
            </div>
          </HostWrap>
          <HostWrap
            help
            host
            title="Add-private-ground.Prohibited Details"
            toolTitle="Tooltip-add-private-ground.Ground detail prohibited-tooltip title"
            toolSubTitle="Tooltip-add-private-ground.Ground detail prohibited-tooltip desc"
          >
            <div
              className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
            >
              {ProhibitedIcons?.map((item: any, idx: number) => {
                const iconUrl = item.iconUrl;
                const enName = item.enName;
                const id = item.id;
                return (
                  <CheckBoxDefault
                    key={idx}
                    id={id}
                    imageUrl={iconUrl}
                    defaultChecked={
                      page?.prohibitedIds?.includes(id) === true ? true : false
                    }
                    value={enName}
                    title={`Prohibited-data.${enName}`}
                    onChange={(e: any) =>
                      ProhibitedDetails(e.target.checked, e.target.id)
                    }
                  />
                );
              })}
            </div>
          </HostWrap>
          <HostWrap
            help
            toolTitle="Tooltip-add-private-ground.Ground detail special-tooltip title"
            toolSubTitle="Tooltip-add-private-ground.Ground detail special-tooltip desc"
            toolEx="Tooltip-add-private-ground.Ground detail special-tooltip ex"
            host
            title={"Add-private-ground.Special Facility"}
          >
            <HostInput
              inputLengthPosition="right-0"
              inputMaxLength={500}
              onChange={(e: any) => setSpecialFacility(e.target.value)}
              inputValue={specialFacility}
              textarea
              size="XL"
              rows={8}
              placeholder="Add-private-ground.Please describe the special facility for travelers."
            />
          </HostWrap>
        </div>
        <div className="mt-12 mb-8 justify-between flex">
          <Button
            onClick={() => {
              Prev();
            }}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-private-ground.Previous")}
          ></Button>
          <Button
            onClick={() => {
              handleDetailData();
            }}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-private-ground.Continue")}
          ></Button>
        </div>
      </div>
      <div className="w-full ml-4"></div>
    </div>
  );
};

export default GroundDetails;
