import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import HostInformation from "components/Host/hostInformation";
import HostPayoutDetails from "components/Host/hostPayoutDetails";
import HostPayoutSetting from "components/Host/hostPayoutSetting";
import HostTransactionHistory from "components/Host/hostTransactionHistory";

import { RootState, useAppDispatch } from "redux/store";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useTranslation } from "react-i18next";
import MoblieHeader from "components/moblieHeader";

const AccountSetting = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const pageData = useLocation();
  const [tabNum, setTabNum] = useState(0);
  const [hostData, setHostData] = useState<any>([]);

  useEffect(() => {
    if (tabNum === undefined) {
      setTabNum(0);
    }
  }, [tabNum]);

  const tabInfo = [
    { id: 0, title: "Host Information" },
    { id: 1, title: "Payout Setting" },
    { id: 2, title: "Payout Details" },
    { id: 3, title: "Transaction History" },
  ];
  const ChangeTab = (id: number) => {
    setTabNum(id);
  };

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `api/v1/host/search/${localStorage.getItem("m")}`,
    })
      .then((res) => {
        setHostData(res.data.data);
        return res.data.data;
      })
      .catch((err) => {});
  }, []);

  const { imageUrl, name, totalMember, languageCode, pgInfo } = hostData;

  const hostInfo = {
    ImageUrl: imageUrl,
    Name: name,
    MobileCode: totalMember?.mobileCountryCode,
    Mobile: totalMember?.mobile,
    Language: languageCode,
    BizType: pgInfo?.bizType,
    BizCode: pgInfo?.countryCode,
    BizNum: pgInfo?.telephone,
    BizUrl: pgInfo?.groundUri ? pgInfo?.groundUri : pgInfo?.bizUri,
  };

  useEffect(() => {
    const prePageTabNum: any = pageData?.state;
    if (prePageTabNum) {
      setTabNum(prePageTabNum?.id);
    } else {
      setTabNum(0);
    }
  }, [pageData.state]);

  return (
    <div>
      <MoblieHeader left center="Menu.Account Setting" centerTitleImage="account-setting" />
      <div className="text-center mx-auto web:pt-8 web:px-8 phone:px-0 phone:pt-0 pb-[100px] lg:max-w-1216">
        <div className="flex">
          <HostAccount />
          <HostAccountHeader
            titleIcon="account-setting"
            title="Menu.Account Setting"
          >
            <div
              className="border-b w-full flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll"
            >
              {tabInfo.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      ChangeTab(item.id);
                    }}
                    className={`${
                      item.id === tabNum
                        ? "bg-light-green text-pure-white"
                        : " text-input-text"
                    } cursor-pointer px-4 py-3 items-center hover:bg-light-green focus:bg-light-green rounded-t-lg hover:text-pure-white`}
                  >
                    {t(`Account-Setting-tab.${item.title}`)}
                  </div>
                );
              })}
            </div>
            {tabNum === 0 ? (
              <HostInformation hostInfoPageData={hostInfo} />
            ) : tabNum === 1 ? (
              <HostPayoutSetting bizType={pgInfo?.bizType} />
            ) : tabNum === 2 ? (
              <HostPayoutDetails />
            ) : tabNum === 3 ? (
              <HostTransactionHistory />
            ) : (
              ""
            )}
          </HostAccountHeader>
        </div>
      </div>
    </div>
  );
};

export default AccountSetting;
