import React, { useEffect, useState, useRef } from "react";
import Geocode from "react-geocode";
import { useTranslation } from "react-i18next";

import HostInput from "elements/HostInput";


import HostWrap from "components/Host/hostWrap";
import GoogleMap from "components/GoogleMap";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";
import Button from "elements/Button";
import Icons from "elements/Icons";

interface editLocaData {
  countryCode?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  preAddress?: string;
  extraAddress?: string;
  direction?: string;
  nearbyFacilities: any;
  touristSpots: any;
  indicator: any;
  id?: number;
}

interface InputItem {
  id: number;
  title: string;
  description?: string;
  photo?: string;
  photoUrl?: string;
}

const EditLocationSetting = ({ id, locaEditData }: editLocaData | any) => {
  const { t } = useTranslation();
  const hostNum = localStorage.getItem("hostNum");
  const nextID = useRef<number>(1);
  const [inputItem, setInputItem] = useState<InputItem[]>([
    { id: 0, title: "", description: "", photo: "", photoUrl: "" },
  ]);

  const [zipCode, setZipCode] = useState("");
  const [restAddr, setRestAddr] = useState("");

  const [howToArea, setHowToArea] = useState("");

  const [store, setStore] = useState({
    name: "",
    disabled: true,
    checked: true,
    facility: "CONVENIENCE_STORE",
    distance: "",
  });
  const [market, setMarket] = useState({
    name: "",
    disabled: true,
    checked: true,
    facility: "SUPERMARKET",
    distance: "",
  });
  const [toilet, setToilet] = useState({
    name: "",
    checked: true,
    disabled: true,
    facility: "TOILET_24HR",
    distance: "",
  });
  const [gas, setGas] = useState({
    name: "",
    checked: true,
    disabled: true,
    facility: "GAS_STATION",
    distance: "",
  });
  const [EV, setEV] = useState({
    name: "",
    disabled: true,
    checked: true,
    facility: "EV_CHARGING_STATION",
    distance: "",
  });

  Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_MAP_KEY}`);
  Geocode.setLanguage("KO");
  Geocode.setRegion("KO");

  const {
    preCountryCode,
    preState,
    preCity,
    preZipCode,
    preExtraAddress,
    preDirection,
    preNearbyFacilityInfo,
    preTouristSpot,
    preAddress,
    preIndicator,
  } = locaEditData;

  const preTourist = preTouristSpot !== undefined ? preTouristSpot : "";

  const touristSpots = inputItem?.map(
    ({ description, title, photo, photoUrl }) => {
      const Obj = {
        description:
          description !== "" ? description : preTourist[0]?.description,
        title: title !== "" ? title : preTourist[0]?.title,
        photo: photo !== "" ? photo : preTourist[0]?.photo,
        photoUrl: photoUrl !== "" ? photoUrl : preTourist[0]?.photoUrl,
      };
      return Obj;
    }
  );

  const nearbyFacilityValue = [
    store.name
      ? { name: store.name, distance: store.distance, facility: store.facility }
      : null,
    market.name
      ? {
          name: market.name,
          distance: market.distance,
          facility: market.facility,
        }
      : null,
    toilet.name
      ? {
          name: toilet.name,
          distance: toilet.distance,
          facility: toilet.facility,
        }
      : null,
    gas.name
      ? { name: gas.name, distance: gas.distance, facility: gas.facility }
      : null,
    EV.name
      ? { name: EV.name, distance: EV.distance, facility: EV.facility }
      : null,
  ];

  const noNull = nearbyFacilityValue.filter((data) => data !== null);

  const newNearby = noNull?.map((item: any) => {
    const obj = {
      name: item.name,
      facility: item.facility,
      distance: item.distance,
    };
    return obj;
  });

  const currentData = {
    nearbyFacilities: [...newNearby],
    state: preState,
    city: preCity,
    zipCode: zipCode,
    address: preAddress,
    destinationId: 49,
    extraAddress: restAddr,
    direction: TextareaReplaceNewLine(howToArea),
    preIndicator: preIndicator,
    country: "KR",
    touristSpots,
  };

  const addInput = () => {
    const input = {
      id: nextID.current,
      title: "",
      description: "",
      photoUrl: "",
      photo: "",
    };
    if (nextID.current === 3) {
      return alert("최대 3개까지 가능힙나다.");
    }
    setInputItem((pre) => [...pre, input]);
    nextID.current += 1;
  };

  const deleteInput = (idx: number) => {
    setInputItem(inputItem.filter((item) => item.id !== idx));
    nextID.current -= 1;
  };
  const hiddenInput = React.useRef<any>(null);
  const handleClick = (e: any) => {
    hiddenInput.current.click();
  };

  const handleFile = async (e: any, idx: number) => {
    let files = e.target.files[0];
    const fileSize = files?.size;
    const data = {
      multipartFiles: files,
    };
    if (fileSize < 1024 ** 2 * 5) {
      const response = await requestToMustOutdoor({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}guest/upload/file`,
        data,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      });
      const photo = `${response.data.data}`;
      const imgUrl = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${photo}`;
      const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(inputItem));

      inputItemCopy[hiddenInput.current.id].photo = photo;
      inputItemCopy[hiddenInput.current.id].photoUrl = imgUrl;
      setInputItem(inputItemCopy);

      return response;
    } else {
      alert("파일 사이즈는 5mb 보다 작아야합니다.");
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (idx > inputItem.length) return;

    const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(inputItem));
    inputItemCopy[idx].title = e.target.value;
    setInputItem(inputItemCopy);
  };

  const handleDesc = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    if (idx > inputItem.length) return;

    const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(inputItem));
    inputItemCopy[idx].description = e.target.value;
    setInputItem(inputItemCopy);
  };

  useEffect(() => {
    setHowToArea(preDirection);
    setZipCode(preZipCode);
    setRestAddr(preExtraAddress);
    const nearby = preNearbyFacilityInfo;
    nearby?.map((item: any, idx: number) => {
      const facil = item.facility;
      const name = item.name;
      const distance = item.distance;
      if (store.facility === facil) {
        setStore({
          ...store,
          name: name,
          distance: distance,
          checked: !item.name,
        });
      } else if (market.facility === facil) {
        setMarket({
          ...market,
          name: name,
          distance: distance,
          checked: !item.name,
        });
      } else if (EV.facility === facil) {
        setEV({ ...EV, name: name, distance: distance, checked: !item.name });
      } else if (gas.facility === facil) {
        setGas({ ...gas, name: name, distance: distance, checked: !item.name });
      } else if (toilet.facility === facil) {
        setToilet({
          ...toilet,
          name: name,
          distance: distance,
          checked: !item.name,
        });
      }
    });
  }, [
    preAddress,
    preDirection,
    preExtraAddress,
    preNearbyFacilityInfo,
    preState,
    preZipCode,
  ]);

  const modiLoca = () => {
    requestToMustOutdoor({
      method: "PATCH",
      url: `/api/v2/private-ground/${id}`,
      data: { ...currentData, hostId: hostNum },
    })
      .then((res) => {
        window.location.reload();
        return res;
      })
      .catch((err) => {});
  };

  return (
    <div>
      {/* 웹 화면 */}
      <div className="web:flex phone:block">
        <div className="web:w-160 web:mx-0 phone:mx-4">
          <div className="text-left">
            <HostWrap
              help
              host
              toolTitle="공유하실 야외 공간의 위치를 알려주세요."
              toolSubTitle="주소 검색 시 꼭 ‘도로명 주소로 입력해주세요~!!  주소 노출은 걱정하지 마세요. 예약이 확정된 게스트에게만 정확한 전체 주소가 제공됩니다."
              toolEx="Ex)강남대로  327"
              title="Add-private-ground.Private Ground Address*"
              column
            >
              <HostInput
                input
                size="XL"
                inputValue={preCountryCode}
                formTitle={t("Add-private-ground.Country / Region")}
                disabled
              />
              {/* <div className="web:flex phone:block">
                <HostInput
                  input
                  type="text"
                  disabled
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                  inputValue={preState}
                  formTitle={t("State")}
                  size="M"
                />
                <HostInput
                  input
                  disabled
                  inputValue={preCity}
                  formTitle={t("City")}
                  size="M"
                />
              </div> */}
              <HostInput
                input
                size="XL"
                disabled
                inputValue={preAddress}
                formTitle={t("Add-private-ground.Address")}
              />
              <div className="web:flex phone:block">
                <HostInput
                  onChange={(e) => setRestAddr(e.target.value)}
                  input
                  inputMaxLength={50}
                  disabled
                  inputValue={preExtraAddress}
                  inputLengthPosition="right-0"
                  formTitle={t("Add-private-ground.Rest Address (optional)")}
                  size="XL"
                />
                {/* <HostInput
                  input
                  disabled
                  inputValue={preZipCode}
                  formTitle={t("Zip Code")}
                  size="M"
                /> */}
              </div>
            </HostWrap>
            <div>
              <HostWrap
                toolTitle="잠깐! 한번 더 확인해주세요."
                toolSubTitle="입력한 주소가 정확한지 한번 더 체크해주세요. 지금 설정된 주소는 이후 호스트가 직접 수정 할 수 없으며, 주소 변경을 원하는 경우 '머스트 아웃도어' 관리자에게 요청이 필요합니다."
                help
                host
                title="Add-private-ground.Map View"
              />
              <div className="my-4 h-[400px]">
                <GoogleMap
                  mapLocation={{
                    lat: Number(`${preIndicator?.latitude}`),
                    lng: Number(`${preIndicator?.longitude}`),
                  }}
                />
              </div>
            </div>
            <div>
              <HostWrap
                toolTitle="호스트님의 매력적인 야외 공간, 어디에 숨어 있나요?"
                toolSubTitle="유명 관광지가 아닌 경우 대부분의 게스트가 초행길일 가능성이 큽니다. 게스트가 방문 시 길을 헤매지 않도록 오는 길의 주요 포인트 지점이나 주변 특징 에 대해 설명해주세요."
                help
                host
                title="Add-private-ground.How To Get There"
              >
                <HostInput
                  textarea
                  inputMaxLength={300}
                  inputValue={howToArea}
                  inputLengthPosition="right-0"
                  onChange={(e: any) => {
                    setHowToArea(e.target.value);
                  }}
                  placeholder="Provide guests with directions to your place, sugguestions on transportation, and any other details that may help them arrive easily and on time."
                  rows={5}
                  size="XL"
                />
              </HostWrap>
            </div>
            <div>
              <HostWrap
                toolTitle="동네 슈퍼나 주변에 편의점이 있나요?"
                toolSubTitle="게스트가 야외 공간 이용 시 생리현상,  음식,  연료 등과 같이 기본적인 것을 해결하거나 미리 준비 가능하도록 주변 시설 정보를 제공해주세요. (반경 10km 내 권장)"
                help
                host
                title="Add-private-ground.Nearby Facility"
              >
                <div>
                  <HostInput
                    nearby
                    checkIcon={"convenienceStore"}
                    disabled={store.checked}
                    inputValue={store.name}
                    defaultChecked={store?.disabled}
                    kmValue={store.distance}
                    onChange={(e) => {
                      setStore({
                        ...store,
                        disabled: e.target.checked,
                        checked: !e.target.checked,
                      });
                    }}
                    distanceOnChange={(e: any) => {
                      setStore({ ...store, distance: e.target.value });
                    }}
                    inputOnChange={(e: any) => {
                      setStore({ ...store, name: e.target.value });
                    }}
                    CheckContent={t("Nearby-facility.Convenience Store")}
                    nomargin
                    size="M"
                  />
                  <HostInput
                    nearby
                    inputValue={market.name}
                    kmValue={market.distance}
                    defaultChecked={market.disabled}
                    disabled={market?.checked}
                    onChange={(e) => {
                      setMarket({
                        ...market,
                        disabled: !e.target.checked,
                        checked: !e.target.checked,
                      });
                    }}
                    distanceOnChange={(e: any) => {
                      setMarket({ ...market, distance: e.target.value });
                    }}
                    inputOnChange={(e: any) => {
                      setMarket({ ...market, name: e.target.value });
                    }}
                    checkIcon={"supermarket"}
                    CheckContent={t("Nearby-facility.Supermarket")}
                    nomargin
                    size="M"
                  />
                  <HostInput
                    nearby
                    inputValue={toilet.name}
                    kmValue={toilet.distance}
                    disabled={toilet.checked}
                    defaultChecked={toilet?.disabled}
                    onChange={(e) => {
                      setToilet({
                        ...toilet,
                        disabled: e.target.checked,
                        checked: !e.target.checked,
                      });
                    }}
                    inputOnChange={(e: any) => {
                      setToilet({
                        ...toilet,
                        name: e.target.value,
                      });
                    }}
                    distanceOnChange={(e: any) => {
                      setToilet({ ...toilet, distance: e.target.value });
                    }}
                    checkIcon={"twentyFourSevenToilet"}
                    CheckContent={t("Nearby-facility.24hr Toilet")}
                    nomargin
                    size="M"
                  />
                  <HostInput
                    nearby
                    inputValue={gas.name}
                    kmValue={gas.distance}
                    disabled={gas.checked}
                    defaultChecked={gas?.disabled}
                    onChange={(e) => {
                      setGas({
                        ...gas,
                        disabled: e.target.checked,
                        checked: !e.target.checked,
                      });
                    }}
                    inputOnChange={(e: any) => {
                      setGas({ ...gas, name: e.target.value });
                    }}
                    distanceOnChange={(e: any) => {
                      setGas({ ...gas, distance: e.target.value });
                    }}
                    checkIcon={"gasStation"}
                    CheckContent={t("Nearby-facility.Gas Station")}
                    nomargin
                    size="M"
                  />
                  <HostInput
                    nearby
                    inputValue={EV.name}
                    defaultChecked={EV?.disabled}
                    kmValue={EV.distance}
                    disabled={EV.checked}
                    onChange={(e) => {
                      setEV({
                        ...EV,
                        disabled: e.target.checked,
                        checked: !e.target.checked,
                      });
                    }}
                    inputOnChange={(e: any) => {
                      setEV({ ...EV, name: e.target.value });
                    }}
                    distanceOnChange={(e: any) => {
                      setEV({ ...EV, distance: e.target.value });
                    }}
                    checkIcon={"evChargingStation"}
                    CheckContent={t("Nearby-facility.EV Charging Station")}
                    nomargin
                    size="M"
                  />
                </div>
              </HostWrap>
            </div>
            <div>
              <HostWrap
                column
                toolTitle="호스팅을 하면서 지역 활성화에도 기여할 수 있어요."
                toolSubTitle="유명 관광지,  뷰 포인트,  지역 맛집 등 게스트가 방문 시 함께 즐길 수 있는
                  지역명소들이 있다면 추천해주세요.  이러한 정보들은 모객 및 방문만족도에
                  긍정적인 영향과 재방률을 높일 수 있습니다."
                help
                host
                title="Add-private-ground.Recommending Tourist Spot"
                // addBtn={inputItem[0].photoUrl === "" ? false : true}
                // addBtnTitle={"More"}
                onClick={() => {
                  addInput();
                }}
              >
                {inputItem?.map((item, i) => {
                  return (
                    <div key={i}>
                      <hr className="mt-4 border-input-text" />
                      {i > 0 ? (
                        <div
                          className="flex justify-end cursor-pointer"
                          onClick={() => {
                            deleteInput(item.id);
                          }}
                        >
                          X
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="pt-2 pb-4 web:flex items-center web:justify-between web:flex-row phone:flex-col">
                        <div>
                          <input
                            id={`${i}`}
                            accept=".jpg, .jpeg, .png, .pdf"
                            type={"file"}
                            className="hidden"
                            ref={hiddenInput}
                            onChange={(e) => {
                              handleFile(e, i);
                            }}
                          />
                          {inputItem[0]?.photoUrl || preTourist[0]?.photoUrl ? (
                            <img
                              onClick={(e) => {
                                handleClick(e);
                              }}
                              src={`${
                                inputItem[i].photoUrl
                                  ? inputItem[i].photoUrl
                                  : preTourist[0]?.photoUrl
                              }`}
                              className="web:w-48 phone:w-full phone:mb-2 h-44 rounded-lg"
                              alt=""
                            />
                          ) : (
                            <div
                              onClick={(e) => {
                                handleClick(e);
                              }}
                            >
                              <div className="text-sm mb-2">
                                {t("Add-private-ground..Featured Image")}
                              </div>
                              <div className="web:w-48 phone:w-full phone:mb-2 h-[141px] rounded-lg border-input-border-color border text-sm justify-center items-center flex flex-col">
                                <Icons
                                  className="w-[60px] h-[60px] mb-2"
                                  iconName="gallery"
                                />
                                <div>Feature Image Upload</div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="phone:block web:inline-block">
                            <HostInput
                              onChange={(e) => {
                                handleChange(e, i);
                              }}
                              inputLengthPosition="right-0"
                              inputMaxLength={50}
                              nomargin
                              notitlemargin
                              inputValue={
                                inputItem[0]?.title !== ""
                                  ? inputItem[0]?.title
                                  : preTourist[0]?.title
                              }
                              input
                              formTitle={t("Add-private-ground.Name of Tourist Spot")}
                              size="L"
                              placeholder="Add-private-ground.Name of Tourist Spot"
                            />
                          </div>
                          <HostInput
                            inputLengthPosition="right-0"
                            inputMaxLength={200}
                            onChange={(e) => {
                              handleDesc(e, i);
                            }}
                            nomargin
                            inputValue={
                              inputItem[0]?.description !== ""
                                ? inputItem[0]?.description
                                : preTourist[0]?.description
                            }
                            textarea
                            rows={2}
                            formTitle={t("Add-private-ground.Description")}
                            size="L"
                            placeholder="Description of Tourist Spot"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </HostWrap>
            </div>
          </div>
          <div className="mt-12 mb-8 justify-end flex">
            <Button
              onClick={() => {
                if (window.confirm("정말 수정하시겠어요?")) {
                  modiLoca();
                }
              }}
              btnText={"Add-private-ground.SAVE"}
              className="px-4 py-3 rounded-lg border border-input-border-color text-pure-white bg-tan"
            ></Button>
          </div>
        </div>
        <div className="ml-4 w-full"></div>
      </div>
    </div>
  );
};

export default EditLocationSetting;
