import React, { useState, useEffect, useCallback } from "react";

import GoogleMap from "components/GoogleMap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useScroll } from "hooks/useScroll";

import DetailInfo from "elements/DetailInfo";
import DetailTheme from "elements/DetailTheme";
import Button from "elements/Button";
import FullSizeModal from "elements/fullSizeModal";
import Icons from "elements/Icons";
import InquiryModal from "elements/InquiryModal";

import { isLogin } from "utils/roleStatus";
import { changeDate } from "utils/changeDate";
import { changecase } from "utils/changeCase";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";

import DayPicker from "components/DayPicker";
import SideMenu from "elements/sideMenu";
import MoblieHeader from "components/moblieHeader";
import MainCustomSlider from "components/mainCustomSlider";
import Slider from "react-slick";
import { isMember } from "lib/isMember";

const AcDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const _id = params.id;

  const [detailData, setDetailData] = useState([]);
  const [spaceDetail, setSpaceDetail] = useState<any>([]);
  const { element, onMoveElement } = useScroll();
  const [selectedSpace, setSelectedSpace] = useState<any>([]);

  const [touristModal, setTouristModal] = useState(false);
  const [allPhotoModal, setAllPhotoModal] = useState(false);
  const [inquiryModal, setInquiryModal] = useState(false);

  const [inquiryValue, setInquiryValue] = useState("");

  const [newImageList, setNewImageList] = useState<any[]>([]);
  const [touristDetail, setTouristDetail] = useState<any>([]);

  const [extraOption, setExtraOption] = useState<any>([]);

  const [carAmount, setCarAmount] = useState({ max: 0, current: 0 });

  const [age, setAge] = useState<any>([]);
  const [guestAmount, setGuestAmount] = useState<any>(0);

  const [spaceImageList, setSpaceImageList] = useState<any[]>([]);
  const [spaceImageListModal, setSpaceImageListModal] = useState(false);
  const [spaceDetailModal, setSpaceDetailModal] = useState(false);

  const [active, setActive] = useState<any[]>([]);

  const [availableSpaceDetail, setAvailableSpaceDetail] = useState<any>({});

  //   캘린더
  const [dayPrice, setDayPrice] = useState("");
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const [specialFacTab, setSpecialFacTab] = useState(false);
  const [extraTab, setExtraTab] = useState(false);
  const [locationTab, setLocationTab] = useState(false);
  const [policyTab, setPolicyTab] = useState(false);

  const memberNum = localStorage.getItem("m");

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/guest/accommodation/${_id}`,
    }).then((res) => {
      const data = res.data.data;
      setDetailData(data);
      return data;
    });
  }, [_id]);

  const selectSpace = (idx: string | number, spaceId: number) => {
    const Index = Number(idx);
    !spaceDetail.includes(Index)
      ? setSpaceDetail([Index])
      : setSpaceDetail(spaceDetail.filter((x: any) => x !== Index));
    if (!spaceDetail.includes(Index)) {
      requestToMustOutdoor({
        method: "GET",
        url: `/guest/space/${spaceId}`,
      }).then((res) => {
        const data = res.data.data;
        const adultExtraCharge = data.adultExtraCharge;
        const childrenExtraCharge = data.childrenExtraCharge;
        const infantExtraCharge = data.infantExtraCharge;
        const availableExtraGuest = Number(data.availableExtraGuest);
        const totalGuestAmount = Number(data.totalGuestAmount);
        setGuestAmount(availableExtraGuest + totalGuestAmount);
        setAge([
          {
            title: "Adults (Age 13+)",
            type: "adult",
            price: adultExtraCharge,
            current: 1,
          },
          {
            title: "Children (Ages 2–12)",
            type: "children",
            price: childrenExtraCharge,
            current: 0,
          },
          {
            title: "Infant (Under 2)",
            type: "infant",
            price: infantExtraCharge,
            current: 0,
          },
        ]);

        const carAmount = Number(data.carAmount);
        const extraCarAmount = Number(data.extraCarAmount);
        setCarAmount({ max: carAmount + extraCarAmount, current: 0 });
        setSelectedSpace(data);
        return data;
      });
    } else {
      setSelectedSpace([]);
    }
  };

  const {
    title,
    spaces,
    hostInfo,
    state,
    city,
    id,
    address,
    advanceDate,
    zipCode,
    imageList,
    currentImage,
    locationThemes,
    facilities,
    destinationInfo,
    confirmMethod,
    description,
    policyInfo,
    activities,
    extraAddress,
    specialFacility,
    extraPolicy,
    extraOptions,
    directions,
    latitude,
    longitude,
    advanced,
    touristSpots,
    checkIn,
    checkOut,
    unitCount,
    accessibleVehicle,
    spaceSize,
    priceInfo,
  } = detailData as any;

  const create = changeDate(hostInfo?.createdAt).substr(0, 7);

  const { calendars } = selectedSpace as any;

  useEffect(() => {
    if (imageList && currentImage) {
      setNewImageList([currentImage]);
      setNewImageList((pre: any) => [...pre, ...imageList]);
    }
  }, [currentImage, imageList]);

  const typeCategory = spaces?.map((item: any) => {
    const typeInfo = item.typeInfo;
    const category = typeInfo?.category;
    return category;
  });

  const category = typeCategory?.filter(
    (x: string, i: string) => typeCategory?.indexOf(x) === i
  );

  const priceList = spaces?.map((item: any) => {
    const mon = Number(item.monday);
    const tue = Number(item.tuesday);
    const wed = Number(item.wednesday);
    const thu = Number(item.thursday);
    const fri = Number(item.friday);
    const sat = Number(item.saturday);
    const sun = Number(item.sunday);

    const price = [mon, tue, wed, thu, fri, sat, sun];
    const noZero = price?.filter((x) => x !== 0);
    const minPrice = Math.min.apply(null, noZero as number[]);
    return minPrice;
  });

  const minimun = Math.min.apply(null, priceList as number[]);

  const value = age?.map((item: any) => {
    return item.current;
  });

  const sum =
    value.length !== 0 ? value?.reduce((a: number, b: number) => a + b) : [];

  const currentMinus = useCallback(() => {
    setGuestAmount(guestAmount - 1);
  }, [guestAmount]);

  const handleAgeAmountPlus = (type: string, idx: number) => {
    setAge(
      age.map((item: any) => {
        const ageType = item.type;
        const current = item.current;
        return ageType === type
          ? { ...item, current: guestAmount > current ? current + 1 : current }
          : item;
      })
    );
  };

  const handleAgeAmountMinus = (type: string, idx: number) => {
    setGuestAmount(guestAmount - 1);
    setAge(
      age.map((item: any, idx: number) => {
        const ageType = item.type;
        const current = item.current;
        if (ageType !== "adult") {
          return ageType === type
            ? { ...item, current: 0 < current ? current - 1 : current }
            : item;
        } else {
          return ageType === "adult"
            ? { ...item, current: 1 < current ? current - 1 : current }
            : item;
        }
      })
    );
  };

  const touristInfo = (title: string, desc: string, imgUrl: string) => {
    setTouristDetail({ title: title, desc: desc, imgUrl: imgUrl });
    setTouristModal(true);
  };

  const allPhotoView = () => {
    setAllPhotoModal((allPhotoModal) => !allPhotoModal);
  };

  const extraValue = extraOptions?.map((item: any, idx: number) => {
    const id = item.id;
    const title = item.title;
    const price = item.price;
    const amount = item.amount;
    const paymentMethod = item.paymentMethod;
    const obj = {
      id: id,
      title: title,
      price: price,
      amount: amount,
      paymentMethod: paymentMethod,
    };
    return obj;
  });

  useEffect(() => {
    setExtraOption(extraValue);
  }, [extraOptions]);

  const extraPlus = (idx: number, item: any) => {
    const maxNum = extraOptions[idx].maxOfNumber;
    if (extraOption) {
      setExtraOption(
        extraOption?.map((i: any) => {
          return i && i.title === item.title
            ? {
                ...i,
                amount: maxNum > item.amount ? item.amount + 1 : item.amount,
              }
            : i;
        })
      );
    }
  };
  const extraMinus = (idx: number, item: any) => {
    if (extraOption) {
      setExtraOption(
        extraOption?.map((i: any) => {
          return i && i.title === item.title
            ? { ...i, amount: 0 < item.amount ? item.amount - 1 : item.amount }
            : i;
        })
      );
    }
  };

  const carPlus = () => {
    const max = carAmount?.max;
    const current = carAmount?.current;
    setCarAmount({
      current: max > current ? current + 1 : current,
      max: max,
    });
  };
  const carMinus = () => {
    const max = carAmount?.max;
    const current = carAmount?.current;
    setCarAmount({
      current: 0 < current ? current - 1 : current,
      max: max,
    });
  };
  const selectedData = {
    startDate: startDate,
    endDate: endDate,
    extraGuest: age,
    selectedDatePrice: dayPrice,
    carAmount: carAmount?.current,
    extraOption: extraOption,
  };

  const requstData = {
    selectedSpace: selectedSpace,
    selectedData: selectedData,
    id: _id,
    detailData: detailData,
  };

  const bookingRequest = () => {
    navigate(`/ac/book/${_id}`, { state: requstData });
  };

  const handleInquiry = () => {
    if (isLogin() === true) {
      requestToMustOutdoor({
        method: "POST",
        url: `/api/v1/qna/accommodation`,
        data: {
          content: TextareaReplaceNewLine(inquiryValue),
          hostId: Number(hostInfo?.id),
          memberId: Number(memberNum),
          acId: id,
          atId: 0,
          pgId: 0,
        },
      }).then((res) => {
        const data = res.data.data;
        navigate("/member/message");
        return data;
      });
    } else {
      alert("로그인이 필요한 기능입니다.");
    }
  };

  const handleSpaceDetail = (item: any) => {
    setAvailableSpaceDetail(item);
    setSpaceDetailModal(true);
  };

  const AvailableSpaceMinPrice = [
    Number(availableSpaceDetail.monday),
    Number(availableSpaceDetail.tuesday),
    Number(availableSpaceDetail.wednesday),
    Number(availableSpaceDetail.thursday),
    Number(availableSpaceDetail.friday),
    Number(availableSpaceDetail.saturday),
    Number(availableSpaceDetail.sunday),
  ];

  const noZero = AvailableSpaceMinPrice?.filter((x) => x !== 0);
  const minPrice = Math.min.apply(null, noZero as number[]);
  return (
    <div>
      <SideMenu
        noPadding
        tabTitle="Product-details.Available Space"
        setIsOpenTab={setSpaceDetailModal}
        isOpenTab={spaceDetailModal}
      >
        <Slider {...{ arrows: false }}>
          {availableSpaceDetail?.imageList?.map((item: any, idx: number) => {
            return (
              <img
                key={idx}
                src={item?.url}
                alt=""
                className="w-full h-52 bg-center bg-cover"
              />
            );
          })}
        </Slider>
        <div className="p-4">
          <div className="text-xl text-main-green">
            {availableSpaceDetail?.title}
          </div>

          <hr className="my-4 text-border-light" />

          <div>
            <div>{t("Product-details.Description")}</div>
            <div className="mt-2">{availableSpaceDetail?.description}</div>
          </div>

          <hr className="my-4 text-border-light" />

          <div>
            <div>{t("Product-details.Space Type")}</div>
            <div className="flex items-center mt-2">
              <img
                className="w-5 h-5 mr-2"
                src={availableSpaceDetail?.typeInfo?.iconUrl}
                alt=""
              />
              <div>
                {t(`Space-type-data.${availableSpaceDetail?.typeInfo?.enName}`)}
              </div>
            </div>
          </div>

          <hr className="my-4 text-border-light" />

          <div>
            <div>{t("Product-details.Space Details")}</div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <Icons
                  iconName="guest-occupancy"
                  className="mr-2 w-5 h-5"
                ></Icons>
                <div>{t("Product-details.Number of Guest")}</div>
              </div>
              <div>
                {availableSpaceDetail?.totalGuestAmount}{" "}
                {t("Product-details.Guests")}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <Icons iconName="bed" className="mr-2 w-5 h-5"></Icons>
                <div>{t("Product-details.Number of Bed")}</div>
              </div>
              <div>
                {availableSpaceDetail?.bedAmount} {t("Product-details.Bed")}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <Icons iconName="min-stay" className="mr-2 w-5 h-5"></Icons>
                <div>{t("Product-details.Minimum Stay")}</div>
              </div>
              <div>
                {availableSpaceDetail?.minStay} {t("Product-details.Day")}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <Icons iconName="space-size" className="mr-2 w-5 h-5"></Icons>
                <div>{t("Product-details.Space Size")}</div>
              </div>
              <div>{availableSpaceDetail?.spaceSize} ㎡</div>
            </div>
          </div>

          <hr className="my-4 text-border-light" />

          <div className="flex items-center justify-between mt-2">
            <div>{t("Product-details.Price")}</div>
            <div>
              From {minPrice} {t("Product-details./ night")}
            </div>
          </div>

          <hr className="my-4 text-border-light" />

          <div>
            <div>{t("Product-details.Extra Guests")}</div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <div>{t("Product-details.Available Extra Guests")}</div>
              </div>
              <div>
                {availableSpaceDetail?.availableExtraGuest}{" "}
                {t("Product-details.Guests")}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div>{t("Product-details.Adults (Age 13+)")}</div>
              <div>
                {availableSpaceDetail?.adultExtraCharge === ""
                  ? "Free"
                  : availableSpaceDetail?.adultExtraCharge}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div>{t("Product-details.Children (Ages 2–12)")}</div>
              <div>
                {availableSpaceDetail?.childrenExtraCharge === ""
                  ? "Free"
                  : availableSpaceDetail?.childrenExtraCharge}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <div>{t("Product-details.Infant (Under 2)")}</div>
              </div>
              {availableSpaceDetail?.infantExtraCharge === ""
                ? "Free"
                : availableSpaceDetail?.infantExtraCharge}
            </div>
          </div>

          <hr className="my-4 text-border-light" />

          <div>
            <div>{t("Product-details.Parking Details")}</div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <div>{t("Product-details.Parking Type")}</div>
              </div>
              <div>
                {t(
                  `Parking-accessible-data.${availableSpaceDetail?.parkingType}`
                )}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div>{t("Product-details.Accessible Vehicle")}</div>
              <div>
                {t(
                  `Parking-accessible-data.${availableSpaceDetail?.accessibleVehicle}`
                )}
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <div>{t("Product-details.Number of Car")}</div>
              </div>
              {availableSpaceDetail?.carAmount}{" "}
              {t(`Parking-accessible-data.Cars`)}
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <div>{t("Product-details.Available Extra Car")}</div>
              </div>
              {availableSpaceDetail?.extraCarAmount}{" "}
              {t(`Parking-accessible-data.Cars`)}
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center">
                <div>{t("Product-details.Extra Price / per car")}</div>
              </div>
              {availableSpaceDetail?.extraCarCharge}
            </div>
          </div>
          <hr className="my-4 text-border-light" />

          <div>
            <div>{t("Product-details.Amenities")}</div>
            <div
              onClick={() => {
                !active.includes(2)
                  ? setActive((active) => [...active, 2])
                  : setActive(active.filter((x) => x !== 2));
              }}
              className="flex justify-between items-center"
            >
              <div className="flex noScroll mt-2 items-center w-full overflow-x-scroll pr-4 whitespace-nowrap ">
                {availableSpaceDetail?.amenityList?.map(
                  (item: any, idx: number) => {
                    const amenityList = item?.amenityList;
                    return (
                      <img
                        key={idx}
                        className="w-6 h-6 mr-4 last:mr-0"
                        src={amenityList?.iconUrl}
                        alt=""
                      />
                    );
                  }
                )}
              </div>
              <img
                className={`${
                  active.includes(2) ? "rotate-180" : ""
                } w-3 transition-transform`}
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                alt=""
              />
            </div>
            {active.includes(2) ? (
              <div className="mt-2 h-full transition-all">
                <hr className="my-4 text-border-light" />
                {availableSpaceDetail?.amenityList?.map(
                  (item: any, idx: number) => {
                    const amenityList = item?.amenityList;
                    return (
                      <div
                        key={idx}
                        className="mb-4 last:mb-0 flex items-center"
                      >
                        <img
                          className="w-6 h-6"
                          src={amenityList?.iconUrl}
                          alt=""
                        />
                        <div className="ml-1">
                          {t(`Amenities-data.${amenityList?.enName}`)}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <hr className="my-4 text-border-light" />

          <div>
            <div>{t("Product-details.Special Amenities")}</div>
            <div className="mt-2 text-sm">
              {availableSpaceDetail?.specialAmenity}
            </div>
          </div>
        </div>

        <div></div>
      </SideMenu>
      <SideMenu
        tabTitle="Product-details.Policy Details"
        setIsOpenTab={setPolicyTab}
        isOpenTab={policyTab}
      >
        <div>
          <span className="text-red">
            {t(
              policyInfo &&
                `Cancellation-policy-data.${changecase(policyInfo.name)}`
            )}
          </span>{" "}
          {t("Product-details.Policy on Cancellation")}
        </div>
        <div className="mt-2">
          {policyInfo?.firstRange !== null
            ? t("Cancellation-policy-data.{{days}}100% refund of the payment", {
                days: policyInfo?.firstRange,
              }) + "\n"
            : ""}
          {policyInfo?.secondRange !== null
            ? t("Cancellation-policy-data.{{days}}70% refund of the payment", {
                days: policyInfo?.secondRange,
              }) + "\n"
            : ""}
          {policyInfo?.thirdRange !== null
            ? t("Cancellation-policy-data.{{days}}50% refund of the payment", {
                days: policyInfo?.thirdRange,
              }) + "\n"
            : ""}
          {policyInfo?.fourthRange !== null
            ? t("Cancellation-policy-data.{{days}}30% refund of the payment", {
                days: policyInfo?.fourthRange,
              }) + "\n"
            : ""}
          {policyInfo?.fifthRange !== null
            ? t("Cancellation-policy-data.{{days}}0% refund of the payment", {
                days: policyInfo?.fifthRange,
              })
            : ""}
        </div>
      </SideMenu>
      <SideMenu
        tabTitle="Product-details.Location"
        setIsOpenTab={setLocationTab}
        isOpenTab={locationTab}
      >
        <div>
          <div className="w-full h-64 my-4">
            <GoogleMap
              mapLocation={{
                lat: Number(`${latitude && latitude}`),
                lng: Number(`${longitude && longitude}`),
              }}
            />
          </div>
          <div className="mt-4">
            <div>{t("Product-details.Location")}</div>
            <div className="text-sm mt-2">
              {t(
                "Product-details.Information provided after booking confirmed"
              )}
            </div>
          </div>
          <hr className="my-4 text-border-light" />
        </div>
        <div>
          <div>
            <div>{t("Product-details.Recommending Tourist Spot")}</div>
            <div className="w-50 h-[161px] shadow-card rounded-lg relative">
              <div
                onClick={() => {
                  touristInfo(
                    touristSpots[0]?.title,
                    touristSpots[0]?.description,
                    touristSpots[0]?.photoUrl
                  );
                }}
                className="opacity-0 hover:opacity-50 flex justify-center  w-full h-32 cursor-pointer items-center hover:bg-black rounded-t-lg absolute"
              >
                <img
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/view-detail.svg"
                  alt=""
                  className="w-8 h-8"
                />
              </div>
              <img
                className="h-32 w-full rounded-t-lg"
                src={touristSpots && touristSpots[0]?.photoUrl}
                alt=""
              />
              <div className="whitespace-nowrap text-ellipsis w-full overflow-hidden h-[33px] leading-[33px] rounded-b-lg px-2 text-sm my-auto">
                {touristSpots && touristSpots[0]?.title}
              </div>
            </div>
          </div>
        </div>
      </SideMenu>
      <SideMenu
        tabTitle="Product-details.Special Facility"
        setIsOpenTab={setSpecialFacTab}
        isOpenTab={specialFacTab}
      >
        {specialFacility ? specialFacility : <div>{t(`Common.N/A`)}</div>}
      </SideMenu>
      <SideMenu
        tabTitle="Product-details.Extra Options"
        setIsOpenTab={setExtraTab}
        isOpenTab={extraTab}
      >
        {extraOptions && !!extraOptions[0]?.title ? (
          extraOptions.map(
            (
              { title, description, maxOfNumber, price, paymentMethod }: any,
              key: number
            ) => {
              return (
                <div key={key} className="last:mb-0 mb-4">
                  <div className="flex justify-between items-center">
                    <div className="mr-2">{title}</div>
                    <div>{price}</div>
                  </div>
                  <div className="flex my-2">
                    {paymentMethod === "On-site Payment" ? (
                      <>
                        <Icons
                          className="mr-2 w-5 h-5"
                          iconName="on-site-payment-only"
                        />
                        <div>{t("Extra-payment-method.On-site Payment")}</div>
                      </>
                    ) : (
                      <>
                        <Icons
                          className="mr-2 w-5 h-5"
                          iconName="available-in-extra-option"
                        />
                        <div>
                          {t("Extra-payment-method.Available in Extra Option")}
                        </div>
                      </>
                    )}
                  </div>
                  <div>{description}</div>
                </div>
              );
            }
          )
        ) : (
          <div>{t(`Common.N/A`)}</div>
        )}
      </SideMenu>
      {inquiryModal ? (
        <InquiryModal>
          <div className="bg-pure-white web:h-full phone:h-[600px] phone:overflow-y-scroll rounded-lg p-8">
            <div className="flex justify-between items-center">
              <div className="text-2xl font-bold">
                {t(`Message-box.1:1 Inquiry`, { nsSeparator: false })}
              </div>
              <img
                onClick={() => {
                  setInquiryModal(false);
                }}
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/menu-close.svg"
                alt=""
                className="w-5 h-5 mr-2 cursor-pointer"
              />
            </div>
            <textarea
              onChange={(e) => {
                setInquiryValue(e.target.value);
              }}
              className="acInput mt-8 mb-4"
              rows={8}
              placeholder={t("Message-box.Please enter your inquiry.")}
            />
            <div className="text-sm">
              {t(
                "Message-box.You can find answers to your inquiries in the 1:1 inquiry in the message box."
              )}
            </div>
            <div className="my-8 text-center">
              <Button
                onClick={() => {
                  handleInquiry();
                }}
                btnText={"Message-box.Submit"}
                className="py-3 px-14 inline-block text-pure-white rounded-lg bg-light-green"
              />
            </div>
            <div className="border border-input-border-color bg-[#f8f8f8] flex flex-col justify-between p-4 web:h-[200px] phone:h-[400px] text-sm">
              <div className="text-base font-bold flex items-center">
                <img
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                  alt=""
                  className="w-5 h-5 mr-2"
                />
                <div>{t("Message-box.Notice on writing 1:1 Inquiry")}</div>
              </div>
              <div>
                {t(
                  "Message-box.1:1 inquiry allows travellers to contact their hosts"
                )}
              </div>
              <div>{t("Message-box.To protect your personal information")}</div>
              <div>
                {t("Message-box.We cannot help you in the event of damage")}
              </div>
              <div>{t("Message-box.If you pay through MUST OUTDOOR")}</div>
            </div>
          </div>
        </InquiryModal>
      ) : (
        ""
      )}
      {spaceImageListModal ? (
        <FullSizeModal
          imgList={spaceImageList}
          isOpen={() => {
            setSpaceImageListModal(
              (spaceImageListModal) => !spaceImageListModal
            );
          }}
        ></FullSizeModal>
      ) : (
        ""
      )}
      {touristModal ? (
        <FullSizeModal
          tourist
          TouristTitle={touristDetail?.title}
          TouristDesc={touristDetail?.desc}
          isOpen={() => {
            setTouristModal((touristModal) => !touristModal);
          }}
          imgList={touristDetail?.imgUrl}
        />
      ) : (
        ""
      )}
      {allPhotoModal && newImageList ? (
        <FullSizeModal
          isOpen={() => {
            setAllPhotoModal(!allPhotoModal);
          }}
          imgList={newImageList}
        />
      ) : (
        ""
      )}

      {/* 모바일 */}
      <MoblieHeader center={title} left />
      <div className="web:hidden phone:block">
        <div
          onClick={() => {
            allPhotoView();
          }}
          className="w-full h-64 relative"
        >
          <img
            className="object-cover w-full h-full"
            src={newImageList[0]?.url}
            alt=""
          />
          <div className="absolute bottom-4 right-4 z-10 px-4 py-2 font-bold bg-[rgba(0,0,0,.4)] text-pure-white shadow-ms rounded-lg">
            {t("Product-details.View photos")}
          </div>
        </div>
        <div className="pt-4 px-4 pb-[72px] text-left w-full">
          <div className="flex flex-col">
            <div className="text-xl font-bold">{title}</div>
            <div className="flex my-2 items-baseline">
              <div className="text-xs text-input-text">from</div>
              <div className="font-bold ml-1">{minimun}</div>
            </div>
            <div className="flex justify-between">
              <div className="flex items-baseline">
                <img
                  className="w-4 h-4"
                  src={
                    "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/rating-star-e.svg"
                  }
                  alt=""
                />
                <div className="font-bold mx-1">NEW</div>
                <div className="text-xs text-input-text">0 Reviews</div>
              </div>
              <div className="flex">
                <img
                  className="w-6 h-6"
                  src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/wishlist-black.svg`}
                  alt=""
                />
                <img
                  className="w-6 h-6 ml-4"
                  src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/share.svg`}
                  alt=""
                />
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            {category?.map((item: any, idx: number) => {
              return (
                <div className="flex items-center mb-4 text-sm" key={idx}>
                  <img
                    src={
                      item === "Glamping Types"
                        ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/glamping-type.svg"
                        : item === "Caravan Types"
                        ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/caravan-type.svg"
                        : item === "Campground Types"
                        ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/campground-type.svg"
                        : item === "Eco Friendly Types"
                        ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/eco-friendly-type.svg"
                        : ""
                    }
                    className="w-6 h-6 mr-2"
                    alt=""
                  />
                  <div>{t(`Space-type-data.${item}`)}</div>
                </div>
              );
            })}
            <div className="text-sm flex items-center mb-4">
              <img
                className="w-6 h-6 mr-2"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/cancellation-policy.svg"
                alt=""
              />
              <div>
                {t(
                  policyInfo &&
                    `Cancellation-policy-data.${changecase(policyInfo?.name)}`
                )}{" "}
                {t(`Product-details.Refund Policy`)}
              </div>
            </div>
            <div className="text-sm flex items-center mb-4">
              <img
                className="w-6 h-6 mr-2"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                alt=""
              />
              <div>
                {advanceDate
                  ? t("Advance-notice-data.Booking is not available today")
                  : t("Advance-notice-data.Booking is available today")}
              </div>
            </div>
            <div className="text-sm flex items-center">
              <img
                className="w-6 h-6 mr-2"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/confirmation.svg"
                alt=""
              />
              <div>
                {t(
                  confirmMethod &&
                    `Confirmation-data.${changecase(confirmMethod)}`
                )}
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div>{t("Product-details.Description")}</div>
            <div className="text-sm mt-2">{description}</div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div className="flex items-center justify-between">
              <div className="text-xl font-bold">
                {t("Product-details.Hosted By")}{" "}
                {hostInfo && hostInfo?.hostName}{" "}
              </div>
              <div
                onClick={() => {
                  setInquiryModal(true);
                }}
                className="text-sm text-link cursor-pointer"
              >
                {t("Message-box.1:1 Inquiry")} {">"}
              </div>
            </div>
            <div className="flex items-start mt-4 text-sm">
              <img
                className="w-12 h-12 mr-4"
                src={
                  hostInfo?.imageUrl
                    ? hostInfo.imageUrl
                    : "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg"
                }
                alt=""
              />
              <div className="flex-1">
                <div className="mb-2">{t("Product-details.Host Verified")}</div>
                <div className="mb-2">
                  {t(`Product-details.Joined in {{Joined_date}}`, {
                    Joined_date: create,
                  })}
                </div>
                <div className="mb-2">
                  {t("Product-details.Response Rate")}: {hostInfo?.resRate}%
                </div>
                <div>
                  {t("Product-details.Language")}: {hostInfo?.languageCode}
                </div>
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div>{t("Product-details.Accommodation Details")}</div>
            <div
              onClick={() => {
                !active.includes(0)
                  ? setActive((active) => [...active, 0])
                  : setActive(active.filter((x) => x !== 0));
              }}
              className="mt-4 cursor-pointer"
            >
              <div>{t(`Product-details.Facilty`)}</div>
              <div
                className={`flex justify-between items-center pb-2 border-border-light border-b`}
              >
                <div
                  className={`flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap`}
                >
                  {facilities && facilities.length !== 0 ? (
                    facilities.map((item: any, key: number) => {
                      const data = item.facilityList;
                      return (
                        <img
                          key={key}
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-6 h-6 mr-4 mt-2 last:mr-0"
                        />
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(0) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(0) ? (
                <div className="mt-2 h-full transition-all">
                  {facilities && facilities.length !== 0 ? (
                    facilities.map((item: any, key: number) => {
                      const data = item.facilityList;
                      return (
                        <div
                          key={key}
                          className="mb-4 last:mb-0 flex items-center"
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className={`w-6 h-6`}
                          />
                          <div className="ml-1">
                            {t(`Facility-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="my-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <div
                onClick={() => {
                  !active.includes(1)
                    ? setActive((active) => [...active, 1])
                    : setActive(active.filter((x) => x !== 1));
                }}
                className="mt-4 cursor-pointer"
              >
                <div>{t(`Product-details.Activity`)}</div>
                <div
                  className={`flex justify-between items-center pb-2 border-border-light border-b`}
                >
                  <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                    {activities && activities.length !== 0 ? (
                      activities.map((item: any, key: number) => {
                        const data = item.activityList;
                        return (
                          <img
                            key={key}
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6 mr-4 mt-2 last:mr-0"
                          />
                        );
                      })
                    ) : (
                      <div>{t(`Common.N/A`)}</div>
                    )}
                  </div>
                  <img
                    className={`${
                      active.includes(1) ? "rotate-180" : ""
                    } w-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                    alt=""
                  />
                </div>
                {active.includes(1) ? (
                  <div
                    className={`mt-2 ${
                      active.includes(1) ? "block max-h-full" : "hidden max-h-0"
                    } transition-all`}
                  >
                    {activities && activities.length !== 0 ? (
                      activities.map((item: any, key: number) => {
                        const data = item.activityList;
                        return (
                          <div
                            key={key}
                            className="mb-4 last:mb-0 flex items-center"
                          >
                            <img
                              src={data.iconUrl}
                              alt={data.enName}
                              className="w-6 h-6"
                            />
                            <div className="ml-1">
                              {t(`Activity-data.${data.enName}`)}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>{t(`Common.N/A`)}</div>
                    )}
                    <hr className="my-4 text-border-light" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div>
              <div
                onClick={() => {
                  setSpecialFacTab(true);
                }}
                className="cursor-pointer mt-4"
              >
                <div className="flex items-center justify-between">
                  <div>{t(`Product-details.Special Facility`)}</div>
                  <img
                    className={`w-3 h-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                    alt=""
                  />
                </div>
                <hr className="my-4 text-border-light" />
              </div>
            </div>
            <div>
              <div
                onClick={() => {
                  setExtraTab(true);
                }}
                className="cursor-pointer mt-4"
              >
                <div className="flex items-center justify-between">
                  <div>{t(`Product-details.Extra Options`)}</div>
                  <img
                    className={`w-3 h-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                    alt=""
                  />
                </div>
                <hr className="my-4 text-border-light" />
              </div>
            </div>

            <div>
              <div className="font-bold text-xl">
                {t("Product-details.Available Space")}
              </div>
              <div className="mt-2">
                <MainCustomSlider>
                  {spaces?.map((item: any, idx: number) => {
                    const imageList = item.imageList;
                    const title = item.title;

                    const typeInfo = item.typeInfo;

                    const mon = Number(item.monday);
                    const tue = Number(item.tuesday);
                    const wed = Number(item.wednesday);
                    const thu = Number(item.thursday);
                    const fri = Number(item.friday);
                    const sat = Number(item.saturday);
                    const sun = Number(item.sunday);

                    const price = [mon, tue, wed, thu, fri, sat, sun];
                    const noZero = price?.filter((x) => x !== 0);
                    const minPrice = Math.min.apply(null, noZero as number[]);

                    return (
                      <div
                        key={idx}
                        className="border w-66 max-w-[276px] border-input-border-color mb-4"
                      >
                        <img
                          className="w-full h-40 rounded-t-lg bg-center bg-cover"
                          src={imageList && imageList[0].url}
                          alt=""
                        />
                        <div className="w-full rounded-b-lg shadow-ms p-2">
                          <div className="flex justify-between items-center mb-2">
                            <div className="text-sm text-main-green">
                              {title}
                            </div>
                            <div
                              onClick={() => {
                                handleSpaceDetail(item);
                              }}
                              className="text-xs text-link cursor-pointer"
                            >
                              {t("Booking-box-status.Details")} {">"}
                            </div>
                          </div>
                          <div className="flex justify-between items-center text-sm">
                            <div className="flex items-center">
                              <img
                                src={typeInfo?.iconUrl}
                                className="w-5 h-5 mr-2"
                                alt=""
                              />
                              <div>
                                {t(`Space-type-data.${typeInfo?.enName}`)}
                              </div>
                            </div>
                            <div className="flex items-center">
                              <div>{minPrice}</div>
                              <div className="text-xs text-input-text">
                                {t(`Product-details./ night`)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </MainCustomSlider>
              </div>
              <hr className="my-4 text-border-light" />
            </div>

            <div>
              <div className="font-bold text-xl">
                {t("Product-details.Location")}
              </div>
              <div className="w-full h-64 my-4">
                <GoogleMap
                  mapLocation={{
                    lat: Number(`${latitude && latitude}`),
                    lng: Number(`${longitude && longitude}`),
                  }}
                />
              </div>
              <div
                onClick={() => {
                  setLocationTab(true);
                }}
                className="flex items-center justify-between cursor-pointer"
              >
                {t("Product-details.Location")}
                <img
                  className={`w-3 h-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                  alt=""
                />
              </div>
              <div className="mt-4">
                {t(
                  "Product-details.Information provided after booking confirmed"
                )}
              </div>

              <hr className="my-4 text-border-light" />
            </div>

            <div>
              <div className="font-bold text-xl">
                {t("Product-details.Policy")}
              </div>
              <div className="text-sm mt-2">
                <div>{t("Product-details.Check In / Out")}</div>
                <div className="my-2 flex items-center justify-between">
                  <div>{t("Product-details.Check in")}</div>
                  <div>{checkIn}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>{t("Product-details.Check out")}</div>
                  <div>{checkOut}</div>
                </div>
              </div>
              <hr className="my-4 text-border-light" />
            </div>
            <div>
              <div
                onClick={() => {
                  setPolicyTab(true);
                }}
                className="flex items-center justify-between cursor-pointer"
              >
                {t("Product-details.Policy Details")}
                <img
                  className={`w-3 h-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                  alt=""
                />
              </div>
              <div className="mt-2">
                <span className="text-red">
                  {t(
                    policyInfo &&
                      `Cancellation-policy-data.${changecase(policyInfo.name)}`
                  )}
                </span>
                {t("Product-details.Policy on Cancellation")}
              </div>
              <hr className="my-4 text-border-light" />
            </div>
          </div>
        </div>
      </div>

      {/* 웹 */}
      <div className="web:block phone:hidden text-left mx-auto lg:max-w-1216 p-8">
        <div className="grid grid-cols-2 w-full gap-1">
          <div
            className="w-full h-[500px] bg-center bg-cover block"
            style={{
              backgroundImage: `url(${currentImage && currentImage.url})`,
            }}
          />
          <div className="grid relative grid-cols-2 gap-1 grid-rows-2">
            {imageList?.map((item: any, idx: number) => {
              if (idx > 4) {
                return "";
              } else {
                return (
                  <div
                    key={idx}
                    className="bg-center bg-cover"
                    style={{
                      backgroundImage: `url(${item.url})`,
                    }}
                  />
                );
              }
            })}
            <div
              className="absolute z-10 px-4 py-2 font-bold bg-gainsboro bottom-0 right-8 mb-4 cursor-pointer shadow-ms rounded-lg"
              onClick={() => {
                allPhotoView();
              }}
            >
              {/* View {imageList?.length + 1} Photos */}
              {t("Product-details.View photos")}
            </div>
          </div>
        </div>

        <div className="flex pt-8">
          <div className="max-w-[800px]">
            <div className="text-sm">
              Home &nbsp; {">"} &nbsp;
              {destinationInfo && destinationInfo.country} &nbsp; {">"} &nbsp;
              {destinationInfo && destinationInfo.state} &nbsp; {">"} &nbsp;
              {title}
            </div>
            <div className="py-8">
              <div className="font-bold text-3.5xl mb-8">{title}</div>
              <div className="flex flex-wrap justify-start">
                <div className="flex items-center mr-8 mb-8">
                  {category?.map((item: any, idx: number) => {
                    return (
                      <div
                        className="flex items-center mr-8 last:mr-0"
                        key={idx}
                      >
                        <img
                          src={
                            item === "Glamping Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/glamping-type.svg"
                              : item === "Caravan Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/caravan-type.svg"
                              : item === "Campground Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/campground-type.svg"
                              : item === "Eco Friendly Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/eco-friendly-type.svg"
                              : ""
                          }
                          className="w-5 h-5 mr-2"
                          alt=""
                        />
                        <div>{t(`Space-type-data.${item}`)}</div>
                      </div>
                    );
                  })}
                </div>
                {locationThemes &&
                  locationThemes?.map((item: any, key: any) => {
                    const data = item.themeInfo;
                    return (
                      <div key={key} className="flex items-center mr-8 mb-8">
                        <img
                          src={data?.iconUrl}
                          className="w-5 h-5"
                          alt={data?.displayName}
                        />
                        <div className="ml-2">
                          {t(`Theme-data.${data?.enName}`)}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="flex flex-wrap justify-start">
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/cancellation-policy.svg"
                    className="mr-2 w-5 h-5"
                    alt=""
                  />
                  <p>
                    {t(
                      policyInfo &&
                        `Cancellation-policy-data.${changecase(
                          policyInfo?.name
                        )}`
                    )}{" "}
                    {t(`Product-details.Refund Policy`)}
                  </p>
                </div>
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                    className="mr-2 w-5 h-5"
                    alt=""
                  />
                  <p>
                    {t(
                      confirmMethod &&
                        `Confirmation-data.${changecase(confirmMethod)}`
                    )}
                  </p>
                </div>
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                    className="mr-2 w-5 h-5"
                    alt=""
                  />
                  <p>
                    {t(
                      advanceDate
                        ? "Advance-notice-data.Available Today"
                        : "Advance-notice-data.Unavailable Today"
                    )}
                  </p>
                </div>
              </div>
              <div className="text-base">{description}</div>
            </div>
            <DetailTheme
              needPosition="relative"
              theme={
                t("Product-details.Hosted By") +
                " " +
                `${hostInfo && hostInfo?.hostName}`
              }
            >
              <div
                onClick={() => {
                  if (!isMember()) {
                    alert("로그인이 되어있어야 가능합니다");
                    navigate("/log-in", {
                      replace: true,
                      state: { nextPath: `/ac/details/${id}` },
                    });
                  } else {
                    // setInquiryModal(true);
                    alert("좀 더 빠른 답변을 위해 지금은 채널톡으로 문의해주세요")
                  }
                }}
                className="absolute -top-[69.3px] right-0 px-4 py-2 text-sm border border-input-border-color rounded-lg cursor-pointer flex items-center"
              >
                <img
                  className="w-4 h-4 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/message.svg"
                  alt=""
                />
                <div>{t("Message-box.1:1 Inquiry")}</div>
              </div>
              <DetailInfo imgUrl={hostInfo && hostInfo.imageUrl}>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t("Product-details.Host Verified")}
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t(`Product-details.Joined in {{Joined_date}}`, {
                    Joined_date: create,
                  })}
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t("Product-details.Response Rate")}: {hostInfo?.resRate}%
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px]">
                  {t("Product-details.Language")}: {hostInfo?.languageCode}
                </div>
              </DetailInfo>
            </DetailTheme>

            <DetailTheme kor theme="Product-details.Ground Details">
              <DetailInfo title="Product-details.Facilty">
                {facilities && facilities.length !== 0 ? (
                  facilities.map((item: any, key: number) => {
                    const data = item.facilityList;
                    return (
                      <div
                        key={key}
                        className="flex items-center w-[191px] mr-[9px] mb-4 "
                      >
                        <img
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-5 h-5"
                        />
                        <div className="ml-2">
                          {t(`Facility-data.${data.enName}`)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Activity">
                {activities && activities.length !== 0 ? (
                  activities.map((item: any, key: number) => {
                    const data = item.activityList;
                    return (
                      <div
                        key={key}
                        className="flex items-center w-[191px] mr-[9px] mb-4 "
                      >
                        <img
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-5 h-5"
                        />
                        <div className="ml-2">
                          {t(`Activity-data.${data.enName}`)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Special Facility">
                {specialFacility ? (
                  specialFacility
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo
                title="Product-details.Extra Options"
                className="flex-col"
              >
                {extraOptions && !!extraOptions[0]?.title ? (
                  extraOptions.map(
                    (
                      {
                        title,
                        description,
                        maxOfNumber,
                        price,
                        paymentMethod,
                      }: any,
                      key: number,
                      item: any
                    ) => {
                      return (
                        <div key={key} className="last:mb-0 mb-4">
                          <div className="flex">
                            {paymentMethod === "On-site Payment" ? (
                              <Icons
                                className="mr-2 mb-2 w-5 h-5"
                                iconName="on-site-payment-only"
                              />
                            ) : (
                              <Icons
                                className="mr-2 mb-2 w-5 h-5"
                                iconName="available-in-extra-option"
                              />
                            )}
                            <div className="mr-2">{title}</div>
                            <div>{price}</div>
                          </div>
                          <div>{description}</div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
            </DetailTheme>
            <DetailTheme
              kor
              theme="Product-details.Available Space"
              needPosition="relative"
              _ref={element}
            >
              {spaces?.map((item: any, idx: number) => {
                if (item.status !== "PENDING") {
                  const imageList = item.imageList;
                  const image = item.imageList[0];
                  const title = item.title;
                  const availableExtraGuest = item.availableExtraGuest;
                  const bedAmount = item.bedAmount;
                  const minStay = item.minStay;
                  const spaceSize = item.spaceSize;
                  const typeInfo = item.typeInfo;

                  const mon = Number(item.monday);
                  const tue = Number(item.tuesday);
                  const wed = Number(item.wednesday);
                  const thu = Number(item.thursday);
                  const fri = Number(item.friday);
                  const sat = Number(item.saturday);
                  const sun = Number(item.sunday);

                  const price = [mon, tue, wed, thu, fri, sat, sun];
                  const noZero = price?.filter((x) => x !== 0);
                  const minPrice = Math.min.apply(null, noZero as number[]);
                  return (
                    <div key={idx}>
                      <div className="w-full h-40 flex border border-input-border-color rounded-lg mb-2">
                        <img
                          onClick={() => {
                            setSpaceImageList(imageList);
                            setSpaceImageListModal(true);
                          }}
                          src={image?.url}
                          alt=""
                          className="w-66 cursor-pointer rounded-l-lg h-full"
                        />
                        <div className="flex-1 p-2 h-40">
                          <div className="text-xl font-bold text-main-green">
                            {title}
                          </div>
                          <div className="flex flex-col h-[112px] justify-end">
                            <div className="flex items-center">
                              <img
                                className="w-5 h-5 mr-2"
                                src={typeInfo?.iconUrl}
                                alt=""
                              />
                              <div>
                                {t(`Space-type-data.${typeInfo?.enName}`)}
                              </div>
                            </div>
                            <div>
                              <div className="flex items-center mt-4">
                                <div className="flex items-center mr-4">
                                  <img
                                    className="w-5 h-5 mr-2"
                                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/guest-occupancy.svg"
                                    alt=""
                                  />
                                  <div>X {availableExtraGuest}</div>
                                </div>
                                <div className="flex items-center mr-4">
                                  <img
                                    className="w-5 h-5 mr-2"
                                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/bed.svg"
                                    alt=""
                                  />
                                  <div>X {bedAmount}</div>
                                </div>
                                <div className="flex items-center mr-4">
                                  <img
                                    className="w-5 h-5 mr-2"
                                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/min-stay.svg"
                                    alt=""
                                  />
                                  <div>X {minStay}</div>
                                </div>
                                <div className="flex items-center mr-4">
                                  <img
                                    className="w-5 h-5 mr-2"
                                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                                    alt=""
                                  />
                                  <div>{spaceSize}m²</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-l border-input-border-color w-36 p-2 h-40 flex flex-col justify-between">
                          <div className="text-xl font-bold">
                            {minPrice ? minPrice : 0}{" "}
                            <span className="text-xs">
                              {t(`Product-details./ night`)}
                            </span>
                          </div>
                          <Button
                            onClick={() => {
                              selectSpace(idx, item.id);
                            }}
                            btnText={
                              spaceDetail.includes(Number(idx))
                                ? "Product-details.Cancel"
                                : "Product-details.Select"
                            }
                            className="rounded-lg font-bold bg-light-green text-pure-white py-2"
                          />
                        </div>
                      </div>
                      {spaceDetail.includes(Number(idx)) ? (
                        <div className="mb-2 px-2 py-4 border rounded-lg border-input-border-color">
                          <div className="flex mb-8">
                            <div className="w-48">
                              {t("Product-details.Description")}
                            </div>
                            <div className="max-w-[592px] break-words">
                              {spaces[idx]?.description}
                            </div>
                          </div>
                          <div className="flex mb-8">
                            <div className="w-48">
                              {t("Product-details.Space Type")}
                            </div>
                            <div className="w-[592px] flex-1 flex items-center">
                              <div className="flex-1 flex items-center">
                                <img
                                  src={spaces[idx]?.typeInfo?.iconUrl}
                                  className="w-5 h-5 mr-2"
                                  alt=""
                                />
                                <div>
                                  {t(
                                    `Space-type-data.${spaces[idx]?.typeInfo?.enName}`
                                  )}
                                </div>
                              </div>
                              <div className="flex-1">
                                Unit : {spaces[idx]?.unitCount} Units
                              </div>
                            </div>
                          </div>
                          <div className="flex mb-8">
                            <div className="w-48">
                              {t("Product-details.Space Details")}
                            </div>
                            <div className="w-[592px]">
                              <div className="flex-1 flex items-center mb-5">
                                <div className="flex-1">
                                  {t("Product-details.Number of Guest")}:{" "}
                                  {spaces[idx]?.totalGuestAmount}
                                  {t("Product-details.Guests")}
                                </div>
                                <div className="flex-1">
                                  {t("Product-details.Number of Bed")}:{" "}
                                  {spaces[idx]?.bedAmount}{" "}
                                  {t("Product-details.Bed")}
                                </div>
                              </div>
                              <div className="flex-1 flex items-center">
                                <div className="flex-1">
                                  {t("Product-details.Minimum Stay")} :{" "}
                                  {spaces[idx]?.minStay}{" "}
                                  {t("Product-details.Day")}
                                </div>
                                <div className="flex-1">
                                  {t("Product-details.Space Size")} :
                                  {spaces[idx]?.spaceSize} m²
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex mb-8">
                            <div className="w-48">
                              {t("Product-details.Extra Guests")}
                            </div>
                            <div className="max-w-[592px] w-full flex-col">
                              <div className="mb-4">
                                {t("Product-details.Available Extra Guests")}:{" "}
                                {spaces[idx]?.availableExtraGuest}{" "}
                                {t("Product-details.Guests")}
                              </div>
                              <div className="flex items-center">
                                <div className="flex-1">
                                  {t("Product-details.Adults (Age 13+)")}:{" "}
                                  <span className="text-sm">
                                    {spaces[idx]?.adultExtraCharge}
                                  </span>
                                </div>
                                <div className="flex-1">
                                  {t("Product-details.Adults (Age 13+)")}:{" "}
                                  <span className="text-sm">
                                    {spaces[idx]?.childrenExtraCharge}
                                  </span>
                                </div>
                                <div className="flex-1">
                                  {t("Product-details.Infant (Under 2)")}:{" "}
                                  <span className="text-sm">
                                    {spaces[idx]?.infantExtraCharge}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex mb-8">
                            <div className="w-48">
                              {t("Product-details.Space Details")}
                            </div>
                            <div className="w-[592px]">
                              <div className="flex-1 flex items-center mb-5">
                                <div className="flex-1">
                                  {t("Product-details.Parking Type")}
                                  <br />
                                  <span className="text-sm">
                                    {t(
                                      `Parking-accessible-data.${spaces[idx]?.parkingType}`
                                    )}
                                  </span>
                                </div>
                                <div className="flex-1">
                                  {t("Product-details.Accessible Vehicle")}
                                  <br />
                                  <span className="text-sm">
                                    {t(
                                      `Parking-accessible-data.${spaces[idx]?.accessibleVehicle}`
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="flex-1 flex items-center">
                                <div className="flex-1">
                                  {t("Product-details.Number of Car")}:{" "}
                                  {spaces[idx]?.carAmount}{" "}
                                  {t("Parking-accessible-data.Car")}
                                </div>
                                <div className="flex-1">
                                  {t("Product-details.Available Extra Car")}:{" "}
                                  {spaces[idx]?.extraCarAmount}
                                  {t("Parking-accessible-data.Car")}
                                </div>
                                <div className="flex-1">
                                  {t("Product-details.Extra Price / per car")}:
                                  {spaces[idx]?.extraCarCharge}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex mb-4">
                            <div className="w-48">
                              {t("Product-details.Amenities")}
                            </div>
                            <div className="w-[592px]">
                              <div className="flex flex-wrap items-center mb-4">
                                {spaces[idx]?.amenityList.map(
                                  (item: any, idx: number) => {
                                    const amenityList = item?.amenityList;
                                    return (
                                      <div
                                        key={idx}
                                        className="flex mb-4 w-[196.33px] items-center"
                                      >
                                        <img
                                          src={amenityList.iconUrl}
                                          className="w-5 h-5 mr-2"
                                          alt=""
                                        />
                                        <div>
                                          {t(
                                            `Amenities-data.${amenityList?.enName}`
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="flex mb-8">
                            <div className="w-48">
                              {t("Product-details.Special Amenities")}
                            </div>
                            <div className="max-w-[592px] break-words">
                              {spaces[idx]?.specialAmenity}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {spaceDetail.includes(Number(idx)) ? (
                        <div
                          className={`absolute top-0 w-[320px] -right-[352px] rounded-lg`}
                        >
                          <div className="w-full rounded-t-lg px-2 py-2 text-pure-white bg-light-green">
                            {t("Product-details.Booking Details")}
                          </div>
                          <div className="border rounded-b-lg border-input-border-color">
                            <div className="p-4">
                              <div className="font-bold  text-base">
                                {t("Product-details.Selected Ground")}
                              </div>
                              <div className="text-main-green">{title}</div>
                            </div>
                            <div className="p-4 bg-pure-white border-y border-input-border-color">
                              <div className="mb-2 text-base font-bold">
                                {t("Product-details.Dates")}
                              </div>
                              <div className="text-sm justify-between items-center">
                                {calendars ? (
                                  <DayPicker
                                    datePrice={setDayPrice}
                                    calendars={calendars}
                                    from_={(date: any) => {
                                      setStartDate(date);
                                    }}
                                    to_={(date: any) => {
                                      setEndDate(date);
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="p-4 font-bold">
                              <div className="mb-2">
                                {t("Product-details.Guest")}
                              </div>
                              {age?.map((ageItem: any, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    className="flex mb-4 last:mb-0 items-center justify-between"
                                  >
                                    <div className="text-sm">
                                      {t(`Booking-request.${ageItem?.title}`)}
                                    </div>
                                    <div className="flex w-[74px] justify-between items-center">
                                      <img
                                        onClick={() => {
                                          handleAgeAmountMinus(
                                            ageItem?.type,
                                            index
                                          );
                                        }}
                                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/minus-circle.svg"
                                        className="w-4 h-4"
                                        alt=""
                                      />
                                      {ageItem?.current}
                                      <img
                                        onClick={() => {
                                          handleAgeAmountPlus(
                                            ageItem?.type,
                                            index
                                          );
                                        }}
                                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/plus-circle.svg"
                                        className="w-4 h-4"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                              {/* {ageAmount?.map((ageItem, index) => {
                                if (ageItem.type) {
                                  return (
                                    <div
                                      key={index}
                                      className="flex mb-4 last:mb-0 items-center justify-between"
                                    >
                                      <div className="text-sm">
                                        {ageItem.title}
                                      </div>
                                      <div className="flex items-center">
                                        <img
                                          onClick={() => {
                                            handleAgeAmountMinus(ageItem.type);
                                          }}
                                          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/minus-circle.svg"
                                          className="w-4 h-4"
                                          alt=""
                                        />
                                        <div className="mx-4">
                                          {ageItem.amount}
                                        </div>
                                        <img
                                          onClick={() => {
                                            handleAgeAmountPlus(ageItem.type);
                                          }}
                                          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/plus-circle.svg"
                                          className="w-4 h-4"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              })} */}
                            </div>
                            <div className="p-4 font-bold border-y border-input-border-color">
                              <div className="mb-2">
                                {t("Product-details.Parking")}
                              </div>
                              <div className="flex items-center justify-between"></div>
                              <div className="flex mb-4 last:mb-0 items-center justify-between">
                                <div className="text-sm">
                                  {t("Booking-request.Total number of car")}
                                </div>
                                <div className="flex w-[74px] justify-between items-center">
                                  <img
                                    onClick={() => {
                                      carMinus();
                                    }}
                                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/minus-circle.svg"
                                    className="w-4 h-4"
                                    alt=""
                                  />
                                  {carAmount && carAmount.current}
                                  <img
                                    onClick={() => {
                                      carPlus();
                                    }}
                                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/plus-circle.svg"
                                    className="w-4 h-4"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>

                            {extraOption.length !== 0 &&
                            extraOption[0]?.title !== "" &&
                            extraOption[0]?.description !== "" ? (
                              <div className="p-4 font-bold border-input-border-color">
                                <div className="mb-2">
                                  {t("Product-details.Extra Option(s)")}
                                </div>
                                {extraOption &&
                                  extraOption.map((item: any, idx: number) => {
                                    if (item === undefined) {
                                      <></>;
                                    } else {
                                      return (
                                        <div
                                          key={idx}
                                          className="flex mb-4 last:mb-0 items-center justify-between"
                                        >
                                          <div className="text-sm">
                                            <div>{item && item.title}</div>
                                            <div>{item && item.price}</div>
                                          </div>
                                          <div className="flex w-[74px] justify-between items-center">
                                            <img
                                              onClick={() => {
                                                extraMinus(idx, item);
                                              }}
                                              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/minus-circle.svg"
                                              className="w-4 h-4"
                                              alt=""
                                            />
                                            {item && item.amount}
                                            <img
                                              onClick={() => {
                                                extraPlus(idx, item);
                                              }}
                                              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/plus-circle.svg"
                                              className="w-4 h-4"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            ) : (
                              <div />
                            )}
                            <div className="p-4 font-bold border-t border-input-border-color">
                              <div className="flex items-center justify-between">
                                <div>{t("Booking-request.Total Amount")}</div>
                                <div>$120</div>
                              </div>
                              <Button
                                onClick={() => {
                                  bookingRequest();
                                }}
                                btnText={"Booking-request.Booking Request"}
                                className="mt-4 rounded-lg bg-light-green text-pure-white py-2"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                }
              })}
            </DetailTheme>
            <DetailTheme theme="Product-details.Location">
              <div className="my-8 h-[400px]">
                <GoogleMap
                  mapLocation={{
                    lat: Number(`${latitude && latitude}`),
                    lng: Number(`${longitude && longitude}`),
                  }}
                />
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Address")}
                </div>
                <div>
                  {t(
                    "Product-details.Information provided after booking confirmed"
                  )}
                </div>
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.How To Get There")}
                </div>
                <div>
                  {directions ? directions : <div>{t(`Common.N/A`)}</div>}
                </div>
              </div>
              {touristSpots?.length !== 0 ? (
                <div className="flex mb-8">
                  <div className="w-[113px] mr-[87px] whitespace-normal">
                    {t("Product-details.Recommending Tourist Spot")}
                  </div>
                  <div className="w-50 h-[161px] shadow-card rounded-lg relative">
                    <div
                      onClick={() => {
                        touristInfo(
                          touristSpots[0]?.title,
                          touristSpots[0]?.description,
                          touristSpots[0]?.photoUrl
                        );
                      }}
                      className="opacity-0 hover:opacity-50 flex justify-center  w-full h-32 cursor-pointer items-center hover:bg-black rounded-t-lg absolute"
                    >
                      <img
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/view-detail.svg"
                        alt=""
                        className="w-8 h-8"
                      />
                    </div>
                    <img
                      className="h-32 w-full rounded-t-lg"
                      src={touristSpots && touristSpots[0]?.photoUrl}
                      alt=""
                    />
                    <div className="whitespace-nowrap text-ellipsis w-full overflow-hidden h-[33px] leading-[33px] rounded-b-lg px-2 text-sm my-auto">
                      {touristSpots && touristSpots[0]?.title}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </DetailTheme>
            <DetailTheme theme="Product-details.Policy">
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Check In / Out")}
                </div>
                <div className="flex-1">
                  {t("Product-details.Check in")} : {checkIn}
                </div>
                <div className="flex-1">
                  {t("Product-details.Check out")} : {checkOut}
                </div>
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Confirmation")}
                </div>
                <div className="w-[600px]">
                  <div className="mb-4">
                    {t(
                      "Confirmation-data.You will receive booking confirmation-hours",
                      { confirm: "24" }
                    )}
                  </div>
                  <div>
                    {t(
                      "Confirmation-data.In the event that you do not receive an email from us"
                    )}
                  </div>
                </div>
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Advance Notice")}
                </div>
                <div>
                  {!advanced
                    ? t("Advance-notice-data.Booking is not available today")
                    : t("Advance-notice-data.Booking is available today")}
                </div>
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Additional Rule / Policy")}
                </div>
                <div>
                  {extraPolicy ? extraPolicy : <div>{t(`Common.N/A`)}</div>}
                </div>
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Policy Details")}
                </div>
                <div>
                  <div className="font-bold mb-4 flex">
                    <div className="text-pure-red">
                      {t(
                        policyInfo &&
                          `Cancellation-policy-data.${changecase(
                            policyInfo.name
                          )}`
                      )}{" "}
                    </div>
                    <div>{t("Product-details.Policy on Cancellation")}</div>
                  </div>
                  <div>
                    {policyInfo?.firstRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}100% refund of the payment",
                          { days: policyInfo?.firstRange }
                        ) + "\n"
                      : ""}
                    {policyInfo?.secondRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}70% refund of the payment",
                          { days: policyInfo?.secondRange }
                        ) + "\n"
                      : ""}
                    {policyInfo?.thirdRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}50% refund of the payment",
                          { days: policyInfo?.thirdRange }
                        ) + "\n"
                      : ""}
                    {policyInfo?.fourthRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}30% refund of the payment",
                          { days: policyInfo?.fourthRange }
                        ) + "\n"
                      : ""}
                    {policyInfo?.fifthRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}0% refund of the payment",
                          { days: policyInfo?.fifthRange }
                        )
                      : ""}
                  </div>
                </div>
              </div>
            </DetailTheme>
          </div>
          <div className="flex flex-col ml-8">
            <div className="w-80 h-52 rounded-lg p-4 flex flex-col justify-between border border-input-text ">
              <div className="flex justify-between">
                <Icons iconName="best-tag" className="h-11 w-11" />
                <div className="flex items-end">
                  <div className="text-input-text text-base mr-1">From</div>
                  <div className="text-2xl font-bold">
                    {minimun ? minimun : 0}
                  </div>
                </div>
              </div>
              <Button
                onClick={onMoveElement}
                className="bg-light-green w-full py-2 text-center rounded-lg text-pure-white"
                btnText={t("Product-details.Go Booking")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcDetails;
