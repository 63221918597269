import React from "react";
import { useTranslation } from "react-i18next";

const AboutMust = () => {
  const { t } = useTranslation();

  const hosting = [
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/host-process-creat-listing.svg",
      title: "1. Creat listings",
      subTitle:
        "It is easy to create listings",
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/host-process-confirm-booking.svg",
      title: "2. Booking management",
      subTitle:
        "Please check the booking request",
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/host-process-check-in.svg",
      title: "3. Check-in & welcome",
      subTitle:
        "Please be prepared to welcome",
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/host-process-payout.svg",
      title: "4. Payouts",
      subTitle:
        "The payout of host’s sales",
    },
  ];

  return (
    <div className="w-full py-12 text-center phone:max-w-[1024px] web:px-32 phone:px-4 mx-auto">
      <div className="text-2xl font-bold">{t("Partner-becomehost.Hosting your place")} </div>
      <div className="text-xl my-8">
        {t("Partner-becomehost.Anyone can easily start")}
      </div>
      <div className="text-xl mb-12">
        {t("Partner-becomehost.You can generate extra profits")}
      </div>
      <div className="phone:grid-cols-1 grid phone:justify-items-center">
      {hosting.map((item, idx) => {
        return (
          <div
          key={idx}
          className="rounded-2xl shadow-selectCard mb-8 last:mb-0 phone:max-w-[328px] web:min-w-[768px]"
          >
            <div className="flex web:flex-row phone:flex-col phone:items-center w-full web:py-6 web:px-8 phone:p-4 mx-auto">
              <img
                src={item.icon}
                className="web:w-24 web:h-24 phone:w-16 phone:h-16 my-6 phone:mx-auto web:mx-0"
                alt=""
                />
              <div className="flex phone:w-[296px] web:w-full flex-col web:ml-8 phone:ml-0 justify-around text-left">
                <div className="font-bold text-xl mb-5">{t(`Partner-becomehost.${item.title}`)}</div>
                <div className="leading-8">{t(`Partner-becomehost.${item.subTitle}`)}</div>
              </div>
            </div>
          </div>
        );
      })}
      </div>
    </div>
  );
};

export default AboutMust;
