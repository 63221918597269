import Toggle from "components/toggle";
import Button from "elements/Button";
import Icons from "elements/Icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { changecase, changeFacility } from "utils/changeCase";
import { inputPriceComma } from "utils/commaDash";

interface ACprops {
  id?: number;
  currentImage?: string;
  title?: string;
  typeInfo?: any;
  availableExtraGuest?: string;
  bedAmount?: string;
  minStay?: string;
  spaceSize?: string;
  adult?: string;
  children?: string;
  infant?: string;
  guestAmount?: string;
  status?: string;
  price?: [
    mon: number,
    tue: number,
    wed: number,
    thu: number,
    fri: number,
    sat: number,
    sun: number
  ];
}

const SpaceCard = ({
  id,
  currentImage,
  title,
  typeInfo,
  availableExtraGuest,
  bedAmount,
  minStay,
  spaceSize,
  adult,
  children,
  infant,
  guestAmount,
  status,
  price,
}: ACprops) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modi, setModi] = useState(false);

  const noZero = price?.filter((x) => x !== 0);
  const minPrice = Math.min.apply(null, noZero as number[]);

  return (
    <div className="w-full web:h-[160px] phone:h-[293px] mt-4 rounded-lg shadow-btn flex web:flex-row phone:flex-col">
      <div className="web:w-66 phone:w-full">
        <img
          className="w-full web:h-full phone:h-[184px] bg-cetner bg-no-repeat web:rounded-l-lg phone:rounded-t-lg phone:rounded-none"
          src={currentImage}
          alt=""
        />
      </div>
      <div className="web:hidden phone:flex flex-col flex-1 justify-between p-2">
        <div className="flex justify-between items-center">
        <div className="font-bold text-main-green">{title}</div>
          <div className="text-link text-xs">Details {">"}</div>
        </div>
        <div className="flex justify-between items-center">
        <div className="flex items-center">
              <img className="w-5 h-5 mr-2" src={typeInfo?.iconUrl} alt="" />
              <div>{t(`Space-type-data.${typeInfo?.enName}`)}</div>
            </div>
            <div className="text-sm font-bold">
            {minPrice ? minPrice : "0"}{" "}
            <span className="text-xs">{t(`Product-details./ night`)}</span>
          </div>
        </div>
      </div>
      <div className="flex justify-between flex-1 web:flex phone:hidden">
        <div className="p-2 flex w-full max-w-[356px] flex-col justify-between">
          <div className="text-xl font-bold text-main-green">{title}</div>
          <div>
            <div className="flex items-center">
              <img className="w-5 h-5 mr-2" src={typeInfo?.iconUrl} alt="" />
              <div>{t(`Space-type-data.${typeInfo?.enName}`)}</div>
            </div>
            <div className="flex items-center mt-4">
              <div className="flex items-center mr-4">
                <img
                  className="w-5 h-5 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/guest-occupancy.svg"
                  alt=""
                />
                <div>X {availableExtraGuest}</div>
              </div>
              <div className="flex items-center mr-4">
                <img
                  className="w-5 h-5 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/bed.svg"
                  alt=""
                />
                <div>X {bedAmount}</div>
              </div>
              <div className="flex items-center mr-4">
                <img
                  className="w-5 h-5 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/min-stay.svg"
                  alt=""
                />
                <div>X {minStay}</div>
              </div>
              <div className="flex items-center mr-4 text-xs">
                <img
                  className="w-5 h-5 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                  alt=""
                />
                <div>{spaceSize}m²</div>
              </div>
            </div>
          </div>
          <div/>
        </div>
        <div className="p-2 border-l h-[160px] border-input-border-color min-w-[144px]">
          <div className="text-xl font-bold">
            {minPrice ? inputPriceComma(String(minPrice)) : "0"}{" "}
            <span className="text-xs">{t(`Product-details./ night`)}</span>
          </div>
          <div className="mt-4 flex flex-col h-[92px] justify-between mb-2 text-sm">
            <div className="flex justify-between items-center">
              <div className="max-w-[100px] overflow-hidden">{t('Add-space.Extra Guests')}</div>
              <div className="max-w-[61px] overflow-hidden text-ellipsis whitespace-nowrap">
                X {guestAmount}
              </div>
            </div>
            <div className="flex justify-between items-center">
            <div className="max-w-[100px] overflow-hidden">{t('My-accommodation.Adults')}</div>
              <div className="max-w-[61px] overflow-hidden text-ellipsis whitespace-nowrap">
                {adult ? adult : "free"}
              </div>
            </div>
            <div className="flex justify-between items-center">
            <div>{t('My-accommodation.Children')}</div>
              <div className="max-w-[61px] overflow-hidden text-ellipsis whitespace-nowrap">
                {children ? children : "free"}
              </div>
            </div>
            <div className="flex justify-between items-center">
            <div>{t('My-accommodation.Infant')}</div>
              <div className="max-w-[61px] overflow-hidden text-ellipsis whitespace-nowrap">
                {infant ? infant : "free"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-xs">
        <div className="phone:hidden web:block">

        <div className="h-10 w-22 px-2 py-3 border border-input-border-color rounded-tr-lg">
        {t(
          `My-accommodation.Approved`
          )}
        </div>
        <div onClick={() => {
          // if(status === "PENDING") {
          //   return alert("해당 숙박은 허가지되지 않은 숙박이므로 해당 기능을 사용할 수 없습니다.")
          // } else {
            navigate(`/host/edit/space/${id}`, {state:id})
          // }
          }} className="h-10 w-22 px-2 py-3 border border-input-border-color hover:text-light-green cursor-pointer">
        {t(`My-accommodation.Edit`)}
        </div>
        <div className="h-10 w-22 px-2 py-3 border border-input-border-color">
          {/* <Toggle onClick={toggleHandler} isActive={active} /> */}
          <div className="h-[14px]"></div>
        </div>
        <div className="h-10 w-22 px-2 py-3 border border-input-border-color rounded-br-lg hover:text-pure-red cursor-pointer">
        {t(`My-accommodation.Delete`)}
        </div>
      </div>
          </div>
          <div className="relative phone:flex items-center px-2 justify-between web:hidden text-main-green py-2 border-t border-input-border-color text-center">
            <div/>
            <div>
          {t(
            `My-accommodation.Approved`
            )}
            </div>
            <Icons onClick={() => {
          setModi((modi) => !modi)
        }} iconName="more-dots" className="w-5 h-5"/>
        {modi ? (
          <div className="absolute rounded-lg bg-pure-white web:hidden phone:block right-3 top-7 border border-input-border-color text-center">
            <div
            onClick={() => {
              // if(status === "PENDING") {
          //   return alert("해당 숙박은 허가지되지 않은 숙박이므로 해당 기능을 사용할 수 없습니다.")
          // } else {
            navigate(`/host/edit/space/${id}`, {state:id})
          // }
            }}
            className="px-4 py-1 cursor-pointer hover:text-light-green"
          >
            {t("My-accommodation.Edit")}
          </div>
          </div>

        )
        : ""}
          </div>
    </div>
  );
};

export default SpaceCard;
