import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import HostBookingListCard from "./hostBookingListCard";
import Pagination from 'react-js-pagination'

const HostBookingCancelled = () => {
  const { t } = useTranslation();
  const [cancelledData, setCancelledData] = useState([]);

  const [currentPost, setCurrentPost] = useState<any>(cancelledData) // 게시판 목록에 보여줄 게시글
  const [page, setPage] = useState<number>(1) // 현재 페이지 번호

  const postPerPage = 10 // 페이지 당 게시글 개수
  const indexOfLastPost = page * postPerPage
  const indexOfFirstPost = indexOfLastPost - postPerPage

  const boardLength = cancelledData.length

  useEffect(() => {
    setCurrentPost(cancelledData.slice(indexOfFirstPost, indexOfLastPost))
  }, [cancelledData, page])

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const hostId = localStorage.getItem("hostNum");
  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/booking/fetch/${hostId}`,
    })
      .then((res) => {
        const data = res.data.data;

        const cancelled = data.map((item: any) => {
          if (
            item.status === "CANCELLED_BY_ADMIN" ||
            item.status === "CANCELLED_BY_HOST" ||
            item.status === "CANCELLED_BY_TRAVELER" ||
            item.status === "DECLINED" ||
            item.status === "REQUEST_CANCELLED" ||
            item.status === "FAILED"
          ) {
            return item;
          }
        });

        const noUnde = cancelled.filter(function(x: any) {
          return x !== undefined;
        });

        setCancelledData(noUnde);
        return res.data.data;
      })
      .catch((err) => {
        //console.logerr)
      });
  }, []);

  return (
    <div className="w-full phone:overflow-x-scroll phone:whitespace-nowrap phone:noScroll">
      <table>
        <thead className="border-b border-input-border-color">
          <tr>
            <th className="p-4">{t("Booking-box-status.Status")}</th>
            <th className="p-4">{t("Booking-box-status.Traveler")}</th>
            <th className="p-4">{t("Booking-box-status.Listing")}</th>
            <th className="p-4">{t("Booking-box-status.Check-in")}</th>
            <th className="p-4">{t("Booking-box-status.Check-out")}</th>
            <th className="p-4">{t("Booking-box-status.Booking #")}</th>
            <th className="p-4 text-center">
              {t("Booking-box-status.Details")}
            </th>
          </tr>
        </thead>
        {currentPost?.map(({
              status,
              resoCode,
              pgTitle,
              travelerName,
              checkOut,
              checkIn,
              id,
              pgId,
              unitAmount,
              extraOptions,
            }:any,
            i:number
          ) => {
            return (
              <HostBookingListCard
                key={i}
                itemNum={id}
                pgId={pgId}
                status={status}
                traveler={travelerName}
                extraOptions={extraOptions}
                unitAmount={unitAmount}
                checkIn={checkIn}
                checkOut={checkOut}
                listing={pgTitle}
                bookingNum={resoCode}
              />
            );
          }
        )}
      </table>
      {cancelledData?.length === 0 ? (
        <div className="m-4">
          {t("Booking-box-status.You have {{number}} cancelled booking.", {
            number: cancelledData.length,
          })}
        </div>
      ) : (
        ""
      )}
      <div>
         Total : {boardLength}
      </div>
      {boardLength !== 0 ? (
      <Pagination
        activePage={page}
        itemsCountPerPage={postPerPage}
        totalItemsCount={cancelledData?.length}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={handlePageChange}/>
      ) : "" }
    </div>
  );
};

export default HostBookingCancelled;
