import Button from "elements/Button";
import React from "react";
import DaumPostcodeEmbed, { useDaumPostcodePopup } from "react-daum-postcode";
import { useTranslation } from "react-i18next";

const DaumAddressSearch = ({ setGoogleMapInfo }: any) => {
  const { t } = useTranslation();
  const CURRENT_URL =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(CURRENT_URL);
  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setGoogleMapInfo(data);
  };
  const handleClick = () => {
    open({ onComplete: handleComplete });
  };
  return (
    <div>
      <Button
        onClick={handleClick}
        btnText={t("Add-private-ground.Search Address")}
        className="mb-1 text-center text-pure-white rounded-lg bg-light-green"
        btnSize="small"
      />
    </div>
  );
};

export default DaumAddressSearch;
