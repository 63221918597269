import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "react-day-picker/dist/style.css";
import moment from "moment";
import { addMonths } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";

import Button from "elements/Button";
import Card from "elements/Card";

import GoogleMapMultiMarker from "components/GoogleMapMultiMarker";
import MainCustomSlider from "components/mainCustomSlider";
import CheckBoxDefault from "elements/CheckBoxDefault";
import SideMenu from "elements/sideMenu";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { inputPriceComma } from "utils/commaDash";
import { changeDate } from "utils/changeDate";
import { isLogin } from "utils/roleStatus";

const TypeViewAll = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { state }: any = useLocation();
  const memberId = localStorage.getItem("m");

  const [wishList, setWishList] = useState([]);

  const [cardList, setCardList] = useState<any>([]);
  const [filterCardList, setFilterCardList] = useState<any>([]);
  const [locationList, setLocationList] = useState<any>([]);

  const [selectDataTab, setSelectDateTab] = useState(false);
  const [filterTab, setFilterTab] = useState(false);

  const [isClick, setIsClick] = useState(false);
  const [moustEnter, setMoustEnter] = useState(false);

  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  const [range, setRange] = useState<DateRange | any>();

  const [dateFilter, setDateFilter] = useState(false);
  const [iconFilter, setIconFilter] = useState(false);
  const [instantBookingFilter, setInstantBookingFilter] = useState(false);

  const [types, setTypes] = useState([]);
  const [themes, setThemes] = useState([]);

  const [selectedtypes, setSelectedTypes] = useState<any>([]);
  const [selectedthemes, setSelectedThemes] = useState<any>([]);

  const [filterData, setFilterData] = useState<{
    checkIn: string;
    checkOut: string;
    destinationId: string;
    isInstantBook: boolean;
    themeIds: any;
    typeIds: any;
  }>({
    checkIn: "",
    checkOut: "",
    destinationId: id as string,
    isInstantBook: false,
    themeIds: [],
    typeIds: [],
  });

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/guest/theme/list-up/private-ground/${id}`,
    }).then((res) => {
      const data = res.data.data;
      setThemes(data);
      return data;
    });
    requestToMustOutdoor({
      method: "GET",
      url: `/guest/type/list-up/private-ground/${id}`,
    }).then((res) => {
      const data = res.data.data;
      setTypes(data);
      return data;
    });
  }, []);

  const getCart = useCallback(() => {
    if (isLogin()) {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v1/cart/private-ground/${memberId}`,
      }).then((res) => {
        const data = res.data.data;
        setWishList(data);
        return data;
      });
    }
  }, [memberId]);

  useEffect(() => {
    getCart();
  }, [memberId]);

  const wishListIds = wishList?.map((item: any) => {
    const id = item.id;
    return id;
  });

  const startChangeDate =
    range?.from !== undefined ? changeDate(range?.from) : "";
  const endChangeDate = range?.to !== undefined ? changeDate(range?.to) : "";

  // useEffect(() => {
  //   setFilterData({
  //     ...filterData,
  //     checkIn: startChangeDate,
  //     checkOut: endChangeDate,
  //   });
  // }, [endChangeDate, startChangeDate]);

  useEffect(() => {
    setCenter({
      lat: locationList[0]?.lat,
      lng: locationList[0]?.lng,
    });
  }, [locationList]);

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/guest/private-ground/fetch/destination/${id}`,
    }).then((res) => {
      const value = res.data.data;
      setCardList(value);
      value.map((item: any) => {
        const location = item.indicator;
        const id = item.id;
        const title = item.title;
        const lat = Number(location.latitude);
        const lng = Number(location.longitude);
        const locationList = { lat: lat, lng: lng, id: id, title: title };
        setLocationList((pre: any) => [...pre, locationList]);
      });
      return res.data.data;
    });
  }, [id]);

  useEffect(() => {
    const channelTalk: any = document.getElementById("ch-plugin");
    setTimeout(() => {
      const value = channelTalk;
      const result = value?.style ? value?.style : "";
      if (result) {
        value.style.cssText = "display:none !important";
      }
    }, 1000);
  }, [cardList]);

  const yesterday = moment().subtract(1, "day");
  const disabledDays = [
    (day: any) => day > addMonths(new Date(), 5),
    (day: any) => day < yesterday,
  ];

  const css = `
  * {
      --rdp-accent-color: #538a40 !important;
      --rdp-outline-selected: #538a40
  }
  .rdp-day_today { 
  color: #538a40;
  font-size: 130%; 
  }
  .rdp-months {
    position: relative;
    border: 1px solid #D9D9D9;
  }

  .rdp-caption_start {
    padding: 36px 16px 48px 16px; 
  }
  .rdp-caption_end {
    padding: 36px 16px 48px 16px; 
  }
  `;
  const phoneCss = `
  * {
      --rdp-accent-color: #538a40 !important;
      --rdp-outline-selected: #538a40
  }
  .rdp-day_today { 
  color: #538a40;
  font-size: 130%; 
  }
  .rdp-table {
    margin: 0px auto;
  }
  
  .rdp-months {
    position: relative;
    border: 1px solid #D9D9D9;
    flex-direction : column;
  }
  
  .rdp-caption_start {
    padding: 8px; 
  }
  .rdp-caption_end {
    padding: 8px; 
  }
  `;

  const setFilter = (type: string) => {
    if (
      type === "Date" &&
      filterData.checkIn !== "" &&
      filterData.checkOut === ""
    ) {
      alert("체크아웃 날짜를 선택해주세요.");
    } else {
      requestToMustOutdoor({
        method: "GET",
        url: `/guest/private-ground/search`,
        params: filterData,
      }).then((res) => {
        const data = res.data.data;
        setFilterCardList(data);
        return data;
      });
    }
  };

  // const handleDate = () => {
  //   if (filterData.checkIn !== "" && filterData.checkOut !== "") {
  //     setDateFilter(false);
  //     requestToMustOutdoor({
  //       method: "GET",
  //       url: `/guest/private-ground/search`,
  //       params: {
  //         checkIn: startChangeDate ? startChangeDate : "",
  //         checkOut: endChangeDate ? endChangeDate : "",
  //         destinationId: id,
  //         isInstantBook: instantBookingFilter,
  //         themeIds: selectedthemes.join(","),
  //         typeIds: selectedtypes.join(","),
  //       },
  //     }).then((res) => {
  //       const data = res.data.data;
  //       setFilterCardList(data);
  //       return data;
  //     });
  //   } else if (filterData.checkIn !== "" && filterData.checkOut === "") {

  //   } else if (filterData.checkIn === "" && filterData.checkOut === "") {
  //     setDateFilter(false);
  //     requestToMustOutdoor({
  //       method: "GET",
  //       url: `/guest/private-ground/search`,
  //       params: {
  //         checkIn: startChangeDate ? startChangeDate : "",
  //         checkOut: endChangeDate ? endChangeDate : "",
  //         destinationId: id,
  //         isInstantBook: instantBookingFilter,
  //         themeIds: [],
  //         typeIds: [],
  //       },
  //     }).then((res) => {
  //       const data = res.data.data;
  //       setFilterCardList(data);
  //       return data;
  //     });
  //   }
  // };
  // const handleIcon = () => {
  //   setIconFilter(false);
  //   requestToMustOutdoor({
  //     method: "GET",
  //     url: `/guest/private-ground/search`,
  //     params: {
  //       checkIn: startChangeDate ? startChangeDate : "",
  //       checkOut: endChangeDate ? endChangeDate : "",
  //       destinationId: id,
  //       isInstantBook: instantBookingFilter,
  //       themeIds: selectedthemes.join(","),
  //       typeIds: selectedtypes.join(","),
  //     },
  //   }).then((res) => {
  //     const data = res.data.data;
  //     setFilterCardList(data);
  //     return data;
  //   });
  // };

  const handleType = (checked: string, item: number) => {
    if (checked) {
      const result = Number(item);
      setSelectedTypes([...selectedtypes, result]);
    } else if (!checked) {
      const result = Number(item);
      setSelectedTypes(selectedtypes?.filter((el: any) => el !== result));
    }
  };
  const handleTheme = (checked: string, item: number) => {
    if (checked) {
      const result = Number(item);
      setSelectedThemes([...selectedthemes, result]);
    } else if (!checked) {
      const result = Number(item);
      setSelectedThemes(selectedthemes?.filter((el: any) => el !== result));
    }
  };

  // useEffect(() => {
  //   setFilterData({
  //     ...filterData,
  //     themeIds: [...selectedthemes],
  //     typeIds: [...selectedtypes],
  //   });
  // }, [selectedthemes, selectedtypes]);

  useEffect(() => {
    setFilterData({
      ...filterData,
      checkIn: startChangeDate,
      checkOut: endChangeDate,
      destinationId: `${id}`,
      isInstantBook: instantBookingFilter,
      themeIds: selectedthemes.join(","),
      typeIds: selectedtypes.join(","),
    });
  }, [
    startChangeDate,
    endChangeDate,
    id,
    instantBookingFilter,
    selectedthemes,
    selectedtypes,
  ]);

  return (
    <div>
      <div className="w-full bg-input-text h-[60px] px-16 web:flex phone:hidden py-2 justify-between">
        <div className="flex items-center relative">
          <Button
            onClick={() => {
              setIconFilter(false);
              setDateFilter((dateFilter) => !dateFilter);
            }}
            className={`${
              filterData?.checkIn !== "" && filterData?.checkOut
                ? "bg-light-green text-pure-white"
                : "bg-pure-white "
            } px-6 py-3 rounded-lg border border-main-green`}
            btnText={`${
              filterData?.checkIn !== "" && filterData?.checkOut !== ""
                ? startChangeDate + " ~ " + endChangeDate
                : t("Search-result.Select Date")
            }`}
            isImg={`${
              filterData?.checkIn !== "" && filterData?.checkOut
                ? "select-date-calendar-white"
                : "select-date-calendar"
            }`}
          />
          {dateFilter ? (
            <div className="absolute top-[33px] -left-[17px]">
              <style>{css}</style>
              <DayPicker
                today={new Date()}
                onSelect={setRange}
                selected={range}
                disabled={disabledDays}
                mode="range"
                numberOfMonths={2}
                pagedNavigation
              />
              <div
                onClick={() => {
                  setFilter("Date");
                  setDateFilter(false);
                }}
                className="z-[9999] absolute bottom-[32px] right-[33px] text-light-green cursor-pointer"
              >
                {t("Search-result.Apply")}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="relative">
            <Button
              onClick={() => {
                setDateFilter(false);
                setIconFilter((iconFilter) => !iconFilter);
              }}
              className={`${
                filterData?.typeIds.length !== 0 ||
                filterData?.themeIds.length !== 0
                  ? "bg-light-green text-pure-white"
                  : "bg-pure-white "
              } mx-4 px-6 py-3 rounded-lg border border-main-green`}
              btnText={"Search-result.Filter"}
              isImg={`${
                filterData?.typeIds.length !== 0 ||
                filterData?.themeIds.length !== 0
                  ? "filter-white"
                  : "filter"
              }`}
            />
            {iconFilter ? (
              <div className="absolute top-[52px] w-[834px]  -left-[160px] border border-input-border-color rounded-lg bg-pure-white p-8 z-[9999]">
                <div className="font-bold">
                  {t("Private-Type-data.Private Ground Type")}
                </div>
                <div className="py-4">
                  <div>
                    <div>{t("Private-Type-data.Private Ground Type")}</div>
                    <div
                      className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
                    >
                      {types?.map((item: any, idx: number) => {
                        const iconUrl = item.iconUrl;
                        const enName = item.enName;
                        const id = item.id;
                        return (
                          <CheckBoxDefault
                            onChange={(e: any) => {
                              handleType(e.target.checked, e.target.id);
                            }}
                            defaultChecked={
                              filterData?.typeIds?.includes(id) ? true : false
                            }
                            typesModal="mr-0"
                            key={idx}
                            id={id}
                            imageUrl={iconUrl}
                            title={`Private-Type-data.${enName}`}
                            value={enName}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <hr className="mb-4 bg-input-text" />
                  <div>
                    <div>{t("Theme-data.Location Theme")}</div>
                    <div
                      className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
                    >
                      {themes?.map((item: any, idx: number) => {
                        const iconUrl = item.iconUrl;
                        const enName = item.enName;
                        const id = item.id;
                        return (
                          <CheckBoxDefault
                            onChange={(e: any) => {
                              handleTheme(e.target.checked, e.target.id);
                            }}
                            defaultChecked={
                              filterData?.themeIds?.includes(id) ? true : false
                            }
                            typesModal="mr-0"
                            key={idx}
                            id={id}
                            imageUrl={iconUrl}
                            title={`Theme-data.${enName}`}
                            value={enName}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center">
                  <div
                    className="text-light-green cursor-pointer"
                    onClick={() => {
                      setFilter("Icon");
                      setIconFilter(false);
                    }}
                  >
                    {t("Search-result.Apply")}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <Button
            onClick={() => {
              setInstantBookingFilter(
                (instantBookingFilter) => !instantBookingFilter
              );
              setFilter("InstantBook");
              // requestToMustOutdoor({
              //   method: "GET",
              //   url: `/guest/private-ground/search`,
              //   params: {
              //     checkIn: startChangeDate ? startChangeDate : "",
              //     checkOut: endChangeDate ? endChangeDate : "",
              //     destinationId: id,
              //     isInstantBook: instantBookingFilter,
              //     themeIds: selectedthemes.join(","),
              //     typeIds: selectedtypes.join(","),
              //   },
              // }).then((res) => {
              //   const data = res.data.data;
              //   console.log("instant", data);
              //   setFilterCardList(data);
              //   return data;
              // });
            }}
            className={`${
              instantBookingFilter
                ? "bg-light-green text-pure-white"
                : "bg-pure-white"
            } px-6 py-3 rounded-lg border border-main-green`}
            btnText={"Confirmation-data.Instant booking"}
            isImg={`${
              instantBookingFilter ? "confirmation-white" : "booking-available"
            }`}
          />
        </div>
        <div className="flex relative">
          <Button
            onClick={() => {
              setIsClick((isClick) => !isClick);
            }}
            className={`px-6 py-3 ${
              isClick === true
                ? "bg-light-green text-pure-white"
                : "bg-pure-white"
            } rounded-lg border border-light-green`}
            btnText={"Search-result.Map View"}
            isImg={`${isClick ? "map-view-white" : "map-view"}`}
          />
        </div>
      </div>
      {isClick ? (
        <div className="w-full flex">
          <div className="w-[680px]">
            <GoogleMapMultiMarker
              setMouseEnter={moustEnter}
              setPosition={center}
              mapLocation={locationList}
            />
          </div>
          <div className="w-[600px] p-4 mx-auto">
            <div className="mb-4 text-xl font-bold">
              {t("Search-result.Private Ground in {{location}}", {
                location: state,
                number:
                  filterCardList.length !== 0
                    ? filterCardList.length
                    : cardList?.length,
              })}
            </div>
            {/* 웹 map view */}
            {filterCardList?.length !== 0 ? (
              <div className="flex justify-start items-center gap-4 flex-wrap">
                {filterCardList?.map((item: any, idx: number) => {
                  const price = item.priceInfo;
                  let arr = Object.values(price);
                  const toNum = arr.map((i) => Number(i));
                  const noZero = toNum.filter((e) => e !== 0);
                  const listingId = item.id;
                  const priceValue = Math.min.apply(null, noZero);
                  const location = {
                    lat: Number(item.indicator.latitude),
                    lng: Number(item.indicator.longitude),
                  };
                  return (
                    <div key={idx}>
                      <Card
                        memberId={Number(memberId)}
                        listingId={Number(listingId)}
                        wish={wishListIds?.includes(listingId) ? true : false}
                        onMoustEnter={() => {
                          setMoustEnter(true);
                          setCenter({ ...location });
                        }}
                        onClick={() => {
                          navigate(`/details/${item.id}`, { state: item.id });
                        }}
                        country={item.country}
                        price={inputPriceComma(String(priceValue))}
                        image={item.image.url}
                        locationThemes={item.locationThemes}
                        state={t(`Kor-state.${item.state}`)}
                        title={item.title}
                        type={item.typeInfo}
                      />
                    </div>
                  );
                })}
              </div>
            ) : filterCardList?.length === 0 && cardList?.length !== 0 ? (
              <div className="flex justify-start items-center gap-4 flex-wrap">
                {cardList?.map((item: any, idx: number) => {
                  const price = item.priceInfo;
                  let arr = Object.values(price);
                  const toNum = arr.map((i) => Number(i));
                  const noZero = toNum.filter((e) => e !== 0);
                  const listingId = item.id;
                  const priceValue = Math.min.apply(null, noZero);
                  const location = {
                    lat: Number(item.indicator.latitude),
                    lng: Number(item.indicator.longitude),
                  };
                  return (
                    <div key={idx}>
                      <Card
                        memberId={Number(memberId)}
                        listingId={Number(listingId)}
                        wish={wishListIds?.includes(listingId) ? true : false}
                        onMoustEnter={() => {
                          setMoustEnter(true);
                          setCenter({ ...location });
                        }}
                        onClick={() => {
                          navigate(`/details/${item.id}`, { state: item.id });
                        }}
                        country={item.country}
                        price={inputPriceComma(String(priceValue))}
                        image={item.image.url}
                        locationThemes={item.locationThemes}
                        state={t(`Kor-state.${item.state}`)}
                        title={item.title}
                        type={item.typeInfo}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {/* {filterCardList.length === 0 && cardList.length < 3 ? (
              <div className="flex flex-wrap justify-start items-center">
                {cardList.map((item: any, i: number) => {
                  const price = item.priceInfo;
                  const listingId = item.id;
                  let arr = Object.values(price);
                  const toNum = arr.map((i) => Number(i));
                  const noZero = toNum.filter((e) => e !== 0);
                  const priceValue = Math.min.apply(null, noZero);
                  const location = {
                    lat: Number(item.indicator.latitude),
                    lng: Number(item.indicator.longitude),
                  };
                  return (
                    <div
                      key={i}
                      className="text-center mr-4 [&:nth-child(2n)]:mr-0"
                    >
                      <Card
                        memberId={Number(memberId)}
                        listingId={Number(listingId)}
                        wish={wishListIds?.includes(listingId) ? true : false}
                        onMoustEnter={() => {
                          setMoustEnter(true);
                          setCenter({ ...location });
                        }}
                        onClick={() => {
                          navigate(`/details/${item.id}`, { state: item.id });
                        }}
                        country={item.country}
                        price={inputPriceComma(String(priceValue))}
                        image={item.image.url}
                        locationThemes={item.locationThemes}
                        state={t(`Kor-state.${item.state}`)}
                        title={item.title}
                        type={item.typeInfo}
                      />
                      ㄱ
                    </div>
                  );
                })}
              </div>
            ) : filterCardList.lenght < 3 ? (
              <div className="flex flex-wrap justify-start items-center">
                {filterCardList.map((item: any, i: number) => {
                  const price = item.priceInfo;
                  let arr = Object.values(price);
                  const toNum = arr.map((i) => Number(i));
                  const listingId = item.id;
                  const noZero = toNum.filter((e) => e !== 0);
                  const priceValue = Math.min.apply(null, noZero);
                  const location = {
                    lat: Number(item.indicator.latitude),
                    lng: Number(item.indicator.longitude),
                  };
                  return (
                    <div
                      key={i}
                      className="text-center mr-4 [&:nth-child(2n)]:mr-0"
                    >
                      <Card
                        memberId={Number(memberId)}
                        listingId={Number(listingId)}
                        wish={wishListIds?.includes(listingId) ? true : false}
                        onMoustEnter={() => {
                          setMoustEnter(true);
                          setCenter({ ...location });
                        }}
                        onClick={() => {
                          navigate(`/details/${item.id}`, { state: item.id });
                        }}
                        country={item.country}
                        price={inputPriceComma(String(priceValue))}
                        image={item.image.url}
                        locationThemes={item.locationThemes}
                        state={t(`Kor-state.${item.state}`)}
                        title={item.title}
                        type={item.typeInfo}
                      />
                      ㄴ
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )} */}
          </div>
        </div>
      ) : (
        <div>
          {selectDataTab ? (
            <SideMenu
              isOpenTab={selectDataTab}
              setIsOpenTab={setSelectDateTab}
              tabTitle="Select Date"
            >
              <div>
                <style>{phoneCss}</style>
                <DayPicker
                  today={new Date()}
                  onSelect={setRange}
                  selected={range}
                  disabled={disabledDays}
                  mode="range"
                  numberOfMonths={2}
                  pagedNavigation
                />
                <div
                  onClick={() => {
                    setFilter("Date");
                    setSelectDateTab(false);
                  }}
                  className="z-[9999] absolute right-[33px] text-light-green cursor-pointer"
                >
                  {t("Search-result.Apply")}
                </div>
              </div>
            </SideMenu>
          ) : (
            ""
          )}
          {filterTab ? (
            <SideMenu
              isOpenTab={filterTab}
              setIsOpenTab={setFilterTab}
              tabTitle="Filter"
            >
              <div className="border border-input-border-color rounded-lg bg-pure-white p-8 z-[9999]">
                <div className="font-bold">
                  {t("Private-Type-data.Private Ground Type")}
                </div>
                <div className="py-4">
                  <div>
                    <div>{t("Private-Type-data.Private Ground Type")}</div>
                    <div
                      className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
                    >
                      {types?.map((item: any, idx: number) => {
                        const iconUrl = item.iconUrl;
                        const enName = item.enName;
                        const id = item.id;
                        return (
                          <CheckBoxDefault
                            onChange={(e: any) => {
                              handleType(e.target.checked, e.target.id);
                            }}
                            defaultChecked={
                              filterData?.typeIds?.includes(id) ? true : false
                            }
                            typesModal="mr-0"
                            key={idx}
                            id={id}
                            imageUrl={iconUrl}
                            title={`Private-Type-data.${enName}`}
                            value={enName}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <hr className="mb-4 bg-input-text" />
                  <div>
                    <div>{t("Theme-data.Location Theme")}</div>
                    <div
                      className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
                    >
                      {themes?.map((item: any, idx: number) => {
                        const iconUrl = item.iconUrl;
                        const enName = item.enName;
                        const id = item.id;
                        return (
                          <CheckBoxDefault
                            onChange={(e: any) => {
                              handleTheme(e.target.checked, e.target.id);
                            }}
                            defaultChecked={
                              filterData?.themeIds?.includes(id) ? true : false
                            }
                            typesModal="mr-0"
                            key={idx}
                            id={id}
                            imageUrl={iconUrl}
                            title={`Theme-data.${enName}`}
                            value={enName}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center">
                  <div
                    className="text-light-green cursor-pointer"
                    onClick={() => {
                      setFilter("Icon");
                      setFilterTab(false);
                    }}
                  >
                    {t("Search-result.Apply")}
                  </div>
                </div>
              </div>
            </SideMenu>
          ) : (
            ""
          )}
          <div className="web:hidden relative phone:block h-96 ">
            <GoogleMapMultiMarker
              setMouseEnter={moustEnter}
              setPosition={center}
              mapLocation={locationList}
            />
            <div className="absolute flex bottom-4 w-full justify-center">
              <div
                onClick={() => {
                  setSelectDateTab(true);
                  setFilterTab(false);
                }}
                className={`${
                  filterData?.checkIn !== "" && filterData?.checkOut
                    ? "bg-light-green text-pure-white text-sm py-[10.5px] px-2"
                    : "bg-pure-white border-input-border-color border p-2"
                } cursor-pointer rounded-lg mr-2`}
              >
                {`${
                  filterData?.checkIn !== "" && filterData?.checkOut !== ""
                    ? startChangeDate + " ~ " + endChangeDate
                    : t("Search-result.Select Date")
                }`}
              </div>
              <div
                onClick={() => {
                  setSelectDateTab(false);
                  setFilterTab(true);
                }}
                className={`${
                  filterData?.typeIds.length !== 0 ||
                  filterData?.themeIds.length !== 0
                    ? "bg-light-green text-pure-white"
                    : "bg-pure-white "
                } cursor-pointer p-2 rounded-lg border-input-border-color border`}
              >
                {t("Search-result.Filter")}
              </div>
            </div>
          </div>
          <div className="web:max-w-1216 xl:max-w-1280 px-4 py-2 mx-auto">
            <div className="mb-4 flex justify-between">
              <div className="text-2xl font-bold ">
                {t("Search-result.Private Ground in {{location}}", {
                  number:
                    filterCardList.length !== 0
                      ? filterCardList.length
                      : cardList?.length,
                  location: state,
                })}
              </div>
              <select className="web:block phone:hidden" onChange={(e) => {}}>
                <option defaultChecked disabled>
                  Filter
                </option>
                <option value="Newest">{t("Search-result.Newest")}</option>
                <option value="MUST Pick’s">
                  {t("Search-result.MUST Pick's")}
                </option>
                <option value="Low to High">
                  {t("Search-result.Price Low to High")}
                </option>
                <option value="High to Low">
                  {t("Search-result.Prive High to Low")}
                </option>
                <option value="Hot Deal">{t("Search-result.Hot Deal")}</option>
              </select>
            </div>
            {/* 웹 전체 view */}
            {filterCardList?.length > 3 ? (
              <MainCustomSlider>
                {filterCardList?.map((item: any, idx: number) => {
                  const price = item.priceInfo;
                  let arr = Object.values(price);
                  const toNum = arr.map((i) => Number(i));
                  const noZero = toNum.filter((e) => e !== 0);
                  const listingId = item.id;
                  const priceValue = Math.min.apply(null, noZero);
                  const location = {
                    lat: Number(item.indicator.latitude),
                    lng: Number(item.indicator.longitude),
                  };
                  return (
                    <div key={idx}>
                      <Card
                        memberId={Number(memberId)}
                        listingId={Number(listingId)}
                        wish={wishListIds?.includes(listingId) ? true : false}
                        onMoustEnter={() => {
                          setMoustEnter(true);
                          setCenter({ ...location });
                        }}
                        onClick={() => {
                          navigate(`/details/${item.id}`, { state: item.id });
                        }}
                        country={item.country}
                        price={inputPriceComma(String(priceValue))}
                        image={item.image.url}
                        locationThemes={item.locationThemes}
                        state={t(`Kor-state.${item.state}`)}
                        title={item.title}
                        type={item.typeInfo}
                      />
                    </div>
                  );
                })}
              </MainCustomSlider>
            ) : filterCardList?.length !== 0 && filterCardList?.length <= 3 ? (
              <div className="flex justify-start items-center gap-4">
                {filterCardList?.map((item: any, idx: number) => {
                  const price = item.priceInfo;
                  let arr = Object.values(price);
                  const toNum = arr.map((i) => Number(i));
                  const noZero = toNum.filter((e) => e !== 0);
                  const listingId = item.id;
                  const priceValue = Math.min.apply(null, noZero);
                  const location = {
                    lat: Number(item.indicator.latitude),
                    lng: Number(item.indicator.longitude),
                  };
                  return (
                    <div key={idx}>
                      <Card
                        memberId={Number(memberId)}
                        listingId={Number(listingId)}
                        wish={wishListIds?.includes(listingId) ? true : false}
                        onMoustEnter={() => {
                          setMoustEnter(true);
                          setCenter({ ...location });
                        }}
                        onClick={() => {
                          navigate(`/details/${item.id}`, { state: item.id });
                        }}
                        country={item.country}
                        price={inputPriceComma(String(priceValue))}
                        image={item.image.url}
                        locationThemes={item.locationThemes}
                        state={t(`Kor-state.${item.state}`)}
                        title={item.title}
                        type={item.typeInfo}
                      />
                    </div>
                  );
                })}
              </div>
            ) : filterCardList?.length === 0 && cardList?.length > 3 ? (
              <MainCustomSlider>
                {cardList?.map((item: any, idx: number) => {
                  const price = item.priceInfo;
                  let arr = Object.values(price);
                  const toNum = arr.map((i) => Number(i));
                  const noZero = toNum.filter((e) => e !== 0);
                  const listingId = item.id;
                  const priceValue = Math.min.apply(null, noZero);
                  const location = {
                    lat: Number(item.indicator.latitude),
                    lng: Number(item.indicator.longitude),
                  };
                  return (
                    <div key={idx}>
                      <Card
                        memberId={Number(memberId)}
                        listingId={Number(listingId)}
                        wish={wishListIds?.includes(listingId) ? true : false}
                        onMoustEnter={() => {
                          setMoustEnter(true);
                          setCenter({ ...location });
                        }}
                        onClick={() => {
                          navigate(`/details/${item.id}`, { state: item.id });
                        }}
                        country={item.country}
                        price={inputPriceComma(String(priceValue))}
                        image={item.image.url}
                        locationThemes={item.locationThemes}
                        state={t(`Kor-state.${item.state}`)}
                        title={item.title}
                        type={item.typeInfo}
                      />
                    </div>
                  );
                })}
              </MainCustomSlider>
            ) : filterCardList?.length === 0 && cardList?.length <= 3 ? (
              <div className="flex justify-start items-center gap-4">
                {cardList?.map((item: any, idx: number) => {
                  const price = item.priceInfo;
                  let arr = Object.values(price);
                  const toNum = arr.map((i) => Number(i));
                  const noZero = toNum.filter((e) => e !== 0);
                  const listingId = item.id;
                  const priceValue = Math.min.apply(null, noZero);
                  const location = {
                    lat: Number(item.indicator.latitude),
                    lng: Number(item.indicator.longitude),
                  };
                  return (
                    <div key={idx}>
                      <Card
                        memberId={Number(memberId)}
                        listingId={Number(listingId)}
                        wish={wishListIds?.includes(listingId) ? true : false}
                        onMoustEnter={() => {
                          setMoustEnter(true);
                          setCenter({ ...location });
                        }}
                        onClick={() => {
                          navigate(`/details/${item.id}`, { state: item.id });
                        }}
                        country={item.country}
                        price={inputPriceComma(String(priceValue))}
                        image={item.image.url}
                        locationThemes={item.locationThemes}
                        state={t(`Kor-state.${item.state}`)}
                        title={item.title}
                        type={item.typeInfo}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {/* {filterCardList.length === 0 && cardList.length > 3 ? (
              <MainCustomSlider>
                {cardList.map((item: any, i: number) => {
                  const price = item.priceInfo;
                  let arr = Object.values(price);
                  const toNum = arr.map((i) => Number(i));
                  const listingId = item.id;
                  const noZero = toNum.filter((e) => e !== 0);
                  const priceValue = Math.min.apply(null, noZero);
                  const location = {
                    lat: Number(item.indicator.latitude),
                    lng: Number(item.indicator.longitude),
                  };
                  return (
                    <div key={i} className="text-center">
                      <Card
                        memberId={Number(memberId)}
                        listingId={Number(listingId)}
                        wish={wishListIds?.includes(listingId) ? true : false}
                        onMoustEnter={() => {
                          setMoustEnter(true);
                          setCenter({ ...location });
                        }}
                        onClick={() => {
                          navigate(`/details/${item.id}`, { state: item.id });
                        }}
                        country={item.country}
                        price={inputPriceComma(String(priceValue))}
                        image={item.image.url}
                        locationThemes={item.locationThemes}
                        state={t(`Kor-state.${item.state}`)}
                        title={item.title}
                        type={item.typeInfo}
                      />
                        ㅂㅂ
                    </div>
                  );
                })}
              </MainCustomSlider>
            ) : filterCardList.lenght > 3 ? (
              <MainCustomSlider>
                {filterCardList.map((item: any, i: number) => {
                  const price = item.priceInfo;
                  let arr = Object.values(price);
                  const toNum = arr.map((i) => Number(i));
                  const noZero = toNum.filter((e) => e !== 0);
                  const listingId = item.id;
                  const priceValue = Math.min.apply(null, noZero);
                  const location = {
                    lat: Number(item.indicator.latitude),
                    lng: Number(item.indicator.longitude),
                  };
                  return (
                    <div key={i} className="text-center">
                      <Card
                        memberId={Number(memberId)}
                        listingId={Number(listingId)}
                        wish={wishListIds?.includes(listingId) ? true : false}
                        onMoustEnter={() => {
                          setMoustEnter(true);
                          setCenter({ ...location });
                        }}
                        onClick={() => {
                          navigate(`/details/${item.id}`, { state: item.id });
                        }}
                        country={item.country}
                        price={inputPriceComma(String(priceValue))}
                        image={item.image.url}
                        locationThemes={item.locationThemes}
                        state={t(`Kor-state.${item.state}`)}
                        title={item.title}
                        type={item.typeInfo}
                      />
                      ㄹ
                    </div>
                  );
                })}
              </MainCustomSlider>
            ) : (
              ""
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default TypeViewAll;
