import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import jwtDecode from "jwt-decode";

import Main from "pages/Main/Main";
import Layout from "pages/Main/Layout";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./index.css";

import Notfound from "pages/NotFound/Notfound";
import Terms from "pages/Info/TermsAndConditions";
import PrivacyPolicy from "pages/Info/PrivacyPolicy";
import Faq from "pages/Info/Faq";
import About from "pages/Info/About";
import HowItWork from "pages/Info/HowItWorks";
import Contact from "pages/Info/Contact";
import BecomeHost from "pages/BecomeHost/becomeHost";

import Login from "pages/Auth/Login";
import Signup from "pages/Auth/Signup";
import EmailConfirm from "pages/Auth/EmailConfirm";
import EmailExist from "pages/Auth/EmailExist";
import KakaoAuth from "pages/Auth/KakaoAuth";
import ForgetPassword from "pages/Auth/ForgetPassword";
import SignupSuccess from "pages/Auth/SignupSuccess";
import SocialSuccess from "pages/Auth/SocialSuccess";
import ResetPassword from "pages/Auth/ResetPassword";
import EmailVerifyFailed from "pages/Auth/EmailVerifyFailed";

import SelectType from "pages/HostInfo/SelectType";
import HostInfo from "pages/HostInfo/hostInfo";
import BusinessInfo from "pages/HostInfo/businessInfo";

import MyPrivateGround from "pages/Register/PrivateGround/myPrivateGround";
import AddPrivateGround from "pages/Register/PrivateGround/addPrivateGround";
import Details from "pages/Product/Details";
import Preview from "pages/Product/Preview";
import BookingRequest from "pages/Booking/bookingRequest";

import MyProfile from "pages/Member/myProfile";
import WishList from "pages/Member/wishList";
import MessageBox from "pages/Member/messageBox";
import BookingBox from "pages/Member/bookingBox";
import BookingVerification from "pages/Booking/bookingVerification";

import HostMessageBox from "pages/Host/messageBox";
import BookingStatus from "pages/Host/bookingStatus";
import BookingPayment from "pages/Booking/BookingPayment";
import CalendarManager from "pages/Host/calendarManager";
import AccountSetting from "pages/Host/accountSetting";
import MemberDetailView from "components/Member/memberDetailView";
import HostDetailView from "components/Host/hostDetailView";
import FaqDetail from "pages/Info/FaqDetail";
import FreeDetails from "pages/Product/FreeDetails";
import LocationSearchResult from "pages/LocationSearch/LocationSearchResult";
import TypeViewAll from "pages/LocationSearch/TypeViewAll";
import EditPrivateGround from "pages/Edit/editPrivateGround";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import Loading from "elements/Loading";
import ForgetPasswordVerfication from "pages/Auth/ForgetPasswordVerfication";
import MyAccommodation from "pages/Register/Accommodation/myAccommodation";
import AddAccommodation from "pages/Register/Accommodation/addAccommodation";
import AddSpace from "pages/Register/Accommodation/addSpace";
import AcPreview from "pages/Product/AcPreview";
import AcDetails from "pages/Product/AcDetails";
import AcBookingRequest from "pages/Booking/AcBookingRequest";
import BookingComplete from "payment/bookingComplete";
import EditAccommodation from "pages/Edit/editAccommodation";
import EditSpace from "pages/Edit/editSpace";
import HostRouter from "route/HostRouter";
import NaverAuth from "pages/Auth/NaverAuth";

function App() {
  
  const [loading, setLoading] = useState(false);

  const [isLogin, setIsLogin] = useState<boolean>(
    !!localStorage.getItem("isLogin")
  );
  const [isHost, setIsHost] = useState<string | null>(
    localStorage.getItem("STATUS")
  );

  // const token: {exp:number} = jwtDecode(String(localStorage.getItem("isLogin")));

  // const exp = token && token?.exp
  
    
  useEffect(() => {
    requestToMustOutdoor.interceptors.request.use(
      function(config) {
        setLoading(true);
        return config;
      },
      function(err: any) {
        console.log(err)
        setLoading(true);
        return Promise.reject(err);
      }
    );
    requestToMustOutdoor.interceptors.response.use(
      function(res) {
        setLoading(false);
        return res;
      },
      function(error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  const currentType = localStorage.getItem("STATUS");

  return (
    <>
      {loading ? <Loading /> : ""}
      <Routes>
        <Route element={<Layout />}>
          {/* 모두 접속 가능한 페이지 */}
          <Route path="/*" element={<Notfound />} />

          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="faq" element={<Faq />} />
          <Route path="faq/detail" element={<FaqDetail />} />
          <Route path="how-it-works" element={<HowItWork />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="partner" element={<BecomeHost />} />

          {/* 멤버만 가능 */}
          <Route path="host-info" element={<HostInfo />} />
          <Route path="business-info" element={<BusinessInfo />} />
          <Route path="type-select" element={<SelectType />} />
          <Route path="search/*" element={<LocationSearchResult />}>
            <Route path=":id" element={<LocationSearchResult />} />
          </Route>

          {/* 여행자만 가능 */}

          {/* 호스트만 가능 */}

          {/* 언제나 갈 수있는 페이지 */}
          <Route
            element={<HostRouter isHost={isHost === "HOST" ? true : false} />}
          >
            <Route path="/" element={<Main />} />
          </Route>

          <Route path="search/Type/*" element={<TypeViewAll />}>
            <Route path=":id" element={<TypeViewAll />} />
          </Route>

          <Route path="/log-in" element={<Login />} />
          {/* 비밀번호 찾기 */}
          <Route path="/log-in/forgot-pass" element={<ForgetPassword />} />

          {/* 비밀번호 찾기 다음 인증 화면 */}
          <Route
            path="/log-in/auth-pass"
            element={<ForgetPasswordVerfication />}
          />

          {/* 비밀번호 찾기 다음 다음 리셋 화면 */}
          <Route path="/log-in/reset-pass" element={<ResetPassword />} />
          {/* 로그인 이메일 존재 */}
          <Route path="/log-in/email-exist" element={<EmailExist />} />
          {/* 소셜로그인 */}
          <Route path="/kakao/auth/callback" element={<KakaoAuth />} />
          <Route path="/naver/auth/callback" element={<NaverAuth />} />

          <Route path="/sign-up" element={<Signup />} />
          {/* 회원가입 성공 */}
          <Route path="/sign-up/success" element={<SignupSuccess />} />
          {/* 소셜 회원가입 성공 */}
          <Route path="/sign-up/social-success" element={<SocialSuccess />} />
          {/* 회원가입 이메일 체크 */}
          <Route path="/sign-up/authentication" element={<EmailConfirm />} />
          {/* 회원가입 이메일 인증 시간초과 */}
          <Route path="/sign-up/email-failed" element={<EmailVerifyFailed />} />

          <Route path="details/*" element={<Details />}>
            <Route path=":id" element={<Details />} />
          </Route>

          <Route path="free/details/*" element={<FreeDetails />}>
            <Route path=":id" element={<FreeDetails />} />
          </Route>

          <Route path="book/*" element={<BookingRequest />}>
            <Route path=":id" element={<BookingRequest />} />
          </Route>
          <Route path="ac/book/*" element={<AcBookingRequest />}>
            <Route path=":id" element={<AcBookingRequest />} />
          </Route>
          <Route path="verification/*" element={<BookingVerification />}>
            <Route path=":id" element={<BookingVerification />} />
          </Route>
          <Route path="payment/*" element={<BookingPayment />}>
            <Route path=":id" element={<BookingPayment />} />
          </Route>

          <Route path="member/profile" element={<MyProfile />} />
          <Route path="member/wishlist" element={<WishList />} />
          <Route path="member/message" element={<MessageBox />} />
          <Route path="member/booking" element={<BookingBox />} />
          <Route path="member/:id" element={<MemberDetailView />} />

          <Route path="host/myaccommodation" element={<MyAccommodation />} />
          <Route path="host/add-accommodation" element={<AddAccommodation />} />

          <Route path="host/add-space" element={<AddSpace />} />

          <Route path="ac/preview/*" element={<AcPreview />}>
            <Route path=":id" element={<AcPreview />} />
          </Route>
          <Route path="ac/details/*" element={<AcDetails />}>
            <Route path=":id" element={<AcDetails />} />
          </Route>

          <Route path="host/myprivate" element={<MyPrivateGround />} />
          <Route path="host/edit/private" element={<EditPrivateGround />} />
          <Route path="host/add-private" element={<AddPrivateGround />} />
          <Route path="host/book" element={<BookingStatus />} />
          <Route path="host/calendar" element={<CalendarManager />} />
          <Route path="host/message" element={<HostMessageBox />} />
          <Route path="host/account" element={<AccountSetting />} />
          <Route path="host/:id" element={<HostDetailView />} />

          <Route
            path="host/edit/accommodation"
            element={<EditAccommodation />}
          />
          <Route path="host/edit/space/*" element={<EditSpace />}>
            <Route path=":id" element={<EditSpace />} />
          </Route>

          {/* 미리보기 페이지 */}
          <Route path="preview/*" element={<Preview />}>
            <Route path=":id" element={<Preview />} />
          </Route>

          <Route path="host/book" element={<MyProfile />} />

          <Route path="complete/book" element={<BookingComplete />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
