import axios from "axios";
import MoblieHeader from "components/moblieHeader";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { hostInfo } from "redux/action/host";
import { useAppDispatch } from "redux/store";
import { setCookie } from "utils/cookie";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

declare global {
  interface Window {
    naver: any;
  }
}
const NaverAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchParams = new URLSearchParams(window.location.search);

  const code = searchParams.get("code");
  const state = searchParams.get("state");

  useEffect(() => {
    requestToMustOutdoor({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}auth/naver`,
      data: {
        authorizedCode: code,
        state: state,
      },
    })
      .then((res) => {
        const value = res?.data.data;
        console.log(value);
        const { accessToken } = value;
        const { refreshToken } = value;
        const { id } = value;
        const { type } = value;

        const { email } = value;
        const { name } = value;
        const { role } = value;

        const { mobile } = value;
        const { mobileCountryCode } = value;
        const { mobileVerified } = value;

        const { hostName } = value;

        localStorage.setItem("STATUS", "MEMBER");
        localStorage.setItem("type", type);
        localStorage.setItem("email", email);
        localStorage.setItem("name", name);
        localStorage.setItem("role", role);
        localStorage.setItem("m", id);
        localStorage.setItem("isLogin", accessToken);
        if (mobile !== null || mobile !== undefined) {
          localStorage.setItem("phone", mobile);
        }
        if (mobileCountryCode !== null) {
          localStorage.setItem("CC", mobileCountryCode);
        }
        if (mobileVerified !== null) {
          localStorage.setItem("MV", mobileVerified);
        }
        if (hostName !== null) {
          localStorage.setItem("hostName", hostName);
        }
        if (refreshToken !== null || refreshToken !== undefined) {
          localStorage.setItem("refresh", refreshToken);
        }
        setCookie("refreshToken", refreshToken);

        const nextPage = sessionStorage.getItem("nextPath");

        if (nextPage) {
          navigate(`${nextPage}`, { replace: true });
        } else {
          navigate("/", { replace: true });
        }

        dispatch(hostInfo());
        return value;
      })
      .catch((err) => {
        if (err.response.data.code === "FB10") {
          window.alert("다른방법으로 가입 되어있습니다. 로그인 해주세요");
          navigate("/log-in", { replace: true });
        }
      });
  }, []);
  return (
    <div className="w-full">
      <MoblieHeader center="Naver Login" left />
    </div>
  );
};

export default NaverAuth;
