import React from "react";

import AuthUtil from "components/Auth/AuthUtil";
import HeadLogo from "components/Auth/authLogo";
import Button from "elements/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SignupSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <HeadLogo>
        <AuthUtil
          AuthIcon={"status-confirmed-approve"}
          title={"Signed up successfully!"}
          subTitle={"Verification of your email is successfully confirmed"}
          and
          content={`Your MUST OUTDOOR account is now activated`}
        />
        <div className="text-base text-pure-white">
          <Button
            onClick={() => {
              navigate("/log-in");
            }}
            className="inline-block mx-auto mt-4 mb-4 bg-light-green text-pure-white text-xs rounded-lg py-2 w-[320px]"
            btnText={t("Account.Continue to Login")}
          />
        </div>
      </HeadLogo>
    </div>
  );
};

export default SignupSuccess;
