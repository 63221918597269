import Toggle from "components/toggle";
import Button from "elements/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { changecase, changeFacility, changeSpace } from "utils/changeCase";

interface ACprops {
  currentImage?: string;
  title?: string;
  locationThemes?: [];
  categoryList?: any;
  policyName?: string;
  confirmMethod?: string;
  advanced?: boolean;
  status?: string;
}

const AccommodationCard = ({
  currentImage,
  title,
  locationThemes,
  categoryList,
  policyName,
  confirmMethod,
  advanced,
  status,
}: ACprops) => {
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>(false);
  const navigate = useNavigate();

  const toggleHandler = () => {
    if (active) {
      alert("상품이 더이상 노출되지 않습니다.");
      setActive(false);
    } else {
      alert("이제부터 상품이 여행자에게 노출됩니다.");
      setActive(true);
    }
  };

  return (
    <div className="w-full web:h-[160px] web:min-h-0 mt-4 web:rounded-lg phone:rounded-b-lg shadow-btn flex web:flex-row phone:flex-col">
      <div className="web:w-66 phone:w-full web:h-full phone:h-[192px] border-r border-input-border-color">
        {currentImage ? (
          <img
            className="w-full h-full bg-cetner bg-no-repeat bg-cover web:rounded-l-lg web:rounded-r-none phone:rounded-t-lg web:border-none phone:border-b border-input-border-color"
            src={currentImage}
            alt=""
          />
        ) : (
          <img
            className="mx-auto my-8 w-24 h-24"
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
            alt=""
          />
        )}
      </div>
      {currentImage && title ? (
        <div className="flex web:flex-row phone:flex-col h-full web:flex-1 phone:flex-none justify-between">
          <div className="p-2 flex flex-col justify-between">
            <div className="text-xl font-bold text-main-green">{title}</div>
            <div className="web:mt-0 phone:mt-4">
              <div className="flex web:flex-row phone:flex-col phone:items-start web:items-center">
                {categoryList.length !== 0 ? (
                  categoryList?.map((item: any, idx: number) => {
                    return (
                      <div
                        className="flex items-center mr-4 last:mr-0"
                        key={idx}
                      >
                        <img
                          src={
                            item === "Glamping Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/glamping-type.svg"
                              : item === "Caravan Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/caravan-type.svg"
                              : item === "Campground Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/campground-type.svg"
                              : item === "Eco Friendly Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/eco-friendly-type.svg"
                              : ""
                          }
                          className="w-5 h-5 mr-2"
                          alt=""
                        />
                        <div>{t(`Space-type-data.${item}`)}</div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex items-center">
                    <img
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/plus-circle-1427.svg"
                      className="mr-2 w-5 h-5"
                      alt=""
                    />
                    <div>{t("My-accommodation.No Registered Space")}</div>
                  </div>
                )}
              </div>
              <div className="flex web:my-4 phone:mb-4 web:flex-row phone:flex-col">
                {locationThemes?.map((item: any, key) => {
                  const info = item?.themeInfo;
                  return (
                    <div
                      key={key}
                      className="flex items-center mr-4 phone:mt-4 web:mt-0"
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={info?.iconUrl}
                        alt={info?.enName}
                      />
                      <div>{t(`Theme-data.${info?.enName}`)}</div>
                    </div>
                  );
                })}
              </div>
              <div className="flex phone:items-start web:items-center web:flex-row phone:flex-col">
                <div className="flex items-center mr-4 web:mb-0 phone:mb-4">
                  <img
                    className="w-5 h-5 mr-2"
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/cancellation-policy.svg"
                    alt=""
                  />
                  <div>
                    {policyName &&
                      t(`Cancellation-policy-data.${changecase(policyName)}`)}
                  </div>
                </div>
                <div className="flex items-center mr-4 web:mb-0 phone:mb-4">
                  <img
                    className="w-5 h-5 mr-2"
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/confirmation.svg"
                    alt=""
                  />
                  <div>
                    {confirmMethod &&
                      t(`Confirmation-data.${changecase(confirmMethod)}`)}
                  </div>
                </div>
                <div className="flex items-center">
                  <img
                    className="w-5 h-5 mr-2"
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                    alt=""
                  />
                  <div>
                    {t(
                      advanced
                        ? "Advance-notice-data.Available Today"
                        : "Advance-notice-data.Unavailable Today"
                    )}
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div className="web:text-xs phone:text-sm border-input-border-color phone:border-t web:border-none text-center">
            <div className="phone:hidden web:block">
              <div className="h-10 w-22 px-2 py-3 border border-input-border-color rounded-tr-lg">
                {t(
                  `My-accommodation.${
                    status === "APPROVED" ? "Approved" : "Pending"
                  }`
                )}
              </div>
              <div
                onClick={() => {
                  navigate("/host/edit/accommodation");
                }}
                className="h-10 w-22 px-2 py-3 border border-input-border-color hover:text-light-green cursor-pointer"
              >
                {t(`My-accommodation.Edit`)}
              </div>
              <div className="h-10 cursor-pointer w-full flex items-center px-2 py-3 border border-input-border-color">
                {/* <Toggle onClick={toggleHandler} isActive={active} /> */}
                <div className="h-[14px]"></div>
              </div>
              <div className="h-10 w-22 px-2 py-3 border border-input-border-color rounded-br-lg hover:text-pure-red cursor-pointer">
                {t(`My-accommodation.Delete`)}
              </div>
            </div>
            <div className="web:hidden phone:block py-3">
              {t(
                `My-accommodation.${
                  status === "APPROVED" ? "Approved" : "Pending"
                }`
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-1 text-center py-[22px]">
          <div style={{ lineHeight: "normal" }}>
            {t("My-accommodation.You haven’t registered an accommodation yet!")}
          </div>
          <Button
            onClick={() => {
              navigate("/host/add-accommodation");
            }}
            btnText={"Add-accommodation.Continue"}
            className="text-sm inline-block mt-4 py-3 px-4 rounded-lg text-pure-white bg-light-green"
          />
        </div>
      )}
    </div>
  );
};

export default AccommodationCard;
