import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Cookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import { signupUser, emailcheck, emailCodecheck, loginUser } from "redux/action/userInfo";
import { getCookie, removeCookie, setCookie } from "utils/cookie";
import moment from "moment";
export interface userInfo {
    name?: string;
    email?: string;
    password?: string;
    checkPassword?: string;
    checkEmail?: boolean;
    marketingConsent?: boolean;
    code?: any;
}

const initialState = {
    userInfo: <userInfo>{
        name: '',
        email: '',
        checkEmail: false,
        password: '',
        checkPassword: '',
        marketingConsent: true,
        code: ''
    },
    isLoggedIn: false,
    isEmailCheck: false,
    isEmailConfirm: false,
}


const userSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        logout: () => {
            localStorage.removeItem("hostNum")
            localStorage.removeItem("refresh")
            localStorage.removeItem("role")
            localStorage.removeItem("profile")
            localStorage.removeItem("isLogin")
            localStorage.removeItem("email")
            localStorage.removeItem("STATUS")
            localStorage.removeItem("name")
            localStorage.removeItem("country")
            localStorage.removeItem("hostPhone")
            localStorage.removeItem("joined")
            localStorage.removeItem("hC")
            localStorage.removeItem("type")
            localStorage.removeItem("m")
            localStorage.removeItem("hostName")
            localStorage.removeItem("mC")
            localStorage.removeItem("phone")
            localStorage.removeItem("general")
            localStorage.removeItem("location")
            localStorage.removeItem("gallery")
            localStorage.removeItem("policy")
            localStorage.removeItem("pricing")
            localStorage.removeItem("detail")
            localStorage.removeItem("CC")
            localStorage.removeItem("MV")
            localStorage.removeItem("itemNum")
            localStorage.removeItem("hostCountry")
            localStorage.removeItem("bizType")
            localStorage.removeItem("AC")
            localStorage.removeItem("AT")
            localStorage.removeItem("PG")
            localStorage.removeItem("itemStatus")
            localStorage.removeItem("currentType")
            sessionStorage.removeItem("nextPath");
            removeCookie("refreshToken")
        }
    },
    extraReducers: (builder) => {
        // pending - 로딩과 비슷한 맥락으로 요청 중으로 생각하면 된다.
        // fulfilled - 요청에 대한 응답 성공
        // rejected - 요청에 대한 응답 실패
        // builder
        builder
            // 회원가입
            .addCase(signupUser.pending, (state, action) => {
                if (state.userInfo.checkEmail === false) {
                    return
                }
            })
            .addCase(signupUser.fulfilled, (state, action) => {
                state.userInfo = action.payload;
                const { email } = action.payload;
                localStorage.setItem("email", email)
                state.isEmailCheck = true;
            })
            .addCase(signupUser.rejected, (state, action) => {
                //console.logstate)
                //console.logaction)
            })
            // 로그인
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                const { accessToken } = action.payload
                const { refreshToken } = action.payload
                const { id } = action.payload;

                const { email } = action.payload
                const { name } = action.payload;
                const { role } = action.payload;

                const { totalMobile } = action.payload
                const { mobile } = action.payload
                const { mobileCountryCode } = action.payload
                const { mobileVerified } = action.payload
                localStorage.setItem("STATUS", "MEMBER");
                localStorage.setItem("email", email)
                localStorage.setItem("name", name)
                localStorage.setItem("role", role)
                localStorage.setItem("m", id)
                localStorage.setItem("isLogin", accessToken)
                if (mobile !== null || mobile !== undefined) {
                    localStorage.setItem("phone", mobile)
                }
                if (mobileCountryCode !== null) {
                    localStorage.setItem("CC", mobileCountryCode)
                }
                if (mobileVerified !== null) {
                    localStorage.setItem("MV", mobileVerified)
                }
                if (refreshToken !== null || refreshToken !== undefined) {
                    localStorage.setItem("refresh", refreshToken);
                }
                setCookie("refreshToken", refreshToken)
            })
            .addCase(emailCodecheck.fulfilled, (state, action) => {
                state.isLoggedIn = true;
                state.isEmailConfirm = true;
                //console.logstate)
                //console.logaction)
            })
            .addCase(emailCodecheck.rejected, (state, action) => {
                //console.logstate)
                //console.logaction)
            })
        //이메일 체크
        // .addCase(emailcheck.fulfilled, (state, action) => {
        //     state.userInfo.checkEmail = true
        // })
    }
});

export const { logout } = userSlice.actions

// export const { } = userSlice.actions;

export default userSlice.reducer
