import React, { useEffect } from 'react';

const Loading = () => {

    useEffect(() => {
        document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
        return () => {
          const scrollY = document.body.style.top;
          document.body.style.cssText = "";
          window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
      }, []);
    return (
        <div className='z-[10002] w-full h-full fixed left-0 top-0 bg-pure-black bg-opacity-40' >
            <img style={{transform: "translate(-50%, -50%)"}} className='fixed top-2/4 left-2/4 w-[100px]' src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/spinner/Ellipsis-2s-200px.gif" alt="" />
        </div>
    );
};

export default Loading;