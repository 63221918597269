import React from "react";

import { useTranslation } from "react-i18next";
import Icons from "elements/Icons";
import Button from "elements/Button";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="mx-auto text-center">
      <div className="font-bold web:text-[128px] phone:text-6xl">404</div>
      <div className="font-bold web:text-6xl phone:text-3xl">
        {t("Main.not Found")}
      </div>
      <div className="web:text-3.5xl phone:text-base">
        {t("Main.Sorry, the Page")}
      </div>
      <Button
        onClick={() => {
          navigate("/", { replace: true });
        }}
        className="inline-block mt-4 rounded-lg bg-light-green text-pure-white web:py-4 web:px-8 phone:py-2 phone:px-4 shadow-ms"
        btnText={"Main.Back to home"}
      />
      <div className="web:text-[40px] phone:text-xl px-8 my-8 mx-auto text-left">
        {t("Main.Oops wrong")}
      </div>
      <Icons className="m-auto w-108 h-80" iconName="404-error" />
    </div>
  );
}

export default NotFound;
