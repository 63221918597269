import Icons from "elements/Icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface moblieHeaderProps {
  left?: boolean;
  onClick?: () => void;
  center?: string;
  centerTitleImage?: string;
  right?: string;
  rightImage?: string;
  className?: string;
}

const MoblieHeader = ({
  left,
  center,
  centerTitleImage,
  right,
  onClick,
  className,
  rightImage,
}: moblieHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="web:hidden phone:flex w-full relative shadow-ms items-center bg-pure-white z-[9999] justify-between px-4 py-3">
      {left ? (
        <img
          onClick={() => navigate(-1)}
          className="w-3 h-3 cursor-pointer"
          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-left.svg"
          alt=""
        />
      ) : (
        <div />
      )}
      <div className={`${centerTitleImage ? "flex items-center" : ""}`}>
      {centerTitleImage ? (
       <Icons iconName={centerTitleImage} />
      ): ""}
      {center ? (
        <div className={`${centerTitleImage ? "ml-2" : ""} font-bold max-w-[150px] text-center w-full overflow-hidden whitespace-nowrap text-ellipsis`}>
          {t(`${center}`)}
        </div>
      ) : (
        <div />
        )}
        </div>
      {right ? (
        <div
          className={`${className} text-sm cursor-pointer`}
          onClick={onClick}
        >
          {t(`${right}`)}
        </div>
      ) : rightImage ? (
        <img
          onClick={onClick}
          className="w-4 h-4 cursor-pointer"
          src={rightImage}
          alt=""
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default MoblieHeader;
