import React, { useState } from "react";

import AcWrap from "elements/AcWrap";
import ImageUpload from "elements/ImageUpload";
import { t } from "i18next";
import Button from "elements/Button";
import { useTranslation } from "react-i18next";

interface GalleryProps {
  onPre?: any;
  onNext?: any;
  galleryData?: any;
}

const EditSpaceGallery = ({ onPre, onNext, galleryData }: GalleryProps) => {
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = useState<any>();
  const [imageList, setImageList] = useState<any[]>([]);

  const handlePageData = () => {
    // galleryData(data);
    // dispatch(spGallery(data));
    onNext();
  };

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          help
          toolTitle="공간/사이트의 대표 이미지를 올려주세요."
          toolSubTitle="상세 페이지 내 공간 리스트에서 노출되는 메인 이미지 입니다. 공간을 가장 잘
        표현할 수 있는 이미지 1장을 등록해주세요."
          title="대표이미지"
          need
        >
          {currentImage?.fileName ? (
            <div className="cursor-pointer text-center web:w-52 text-sm phone:h-48 web:h-32 phone:w-full rounded-lg border-input-border-color border relative">
              <img
                className="w-full h-full bg-center bg-no-repeat object-cover bg-cover rounded-lg"
                src={currentImage?.url}
                alt={`${currentImage?.profile}`}
              />
              <img
                onClick={() => {
                  setCurrentImage({});
                }}
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                className="absolute z-100 w-6 h-6 cursor-pointer top-2 right-2"
                alt=""
              />
            </div>
          ) : (
            <ImageUpload
              currentImage
              imageLength={currentImage?.length}
              imageListProps={setCurrentImage}
            >
              <div className="cursor-pointer text-center web:w-52 text-sm web:h-32 phone:h-48 phone:w-full rounded-lg border-input-border-color border flex justify-center items-center flex-col">
                <img
                  className="h-16 w-16"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
                  alt=""
                />
                <div className="text-link web:mt-2 phone:mt-2 phone:mb-0">
                  {t(`Add-space.Add Gallery`)}
                </div>
                <div className="web:hidden phone:block">
                  {t(
                    `Add-accommodation.Upload the attractive features of your accommodation that traveler might love.`
                  )}
                </div>
              </div>
            </ImageUpload>
          )}
        </AcWrap>
        <AcWrap
          help
          toolTitle="이 공간의 다양한 사진을 올려주세요."
          toolSubTitle="이 공간의 매력을 잘 알 수 있도록 다양한 사진을 업로드해주세요.  자세하고
        상세한 사진들은 더 많은 게스트들의 최종 예약을 유도합니다."
          title="공간이미지"
          need
        >
          {imageList.length !== 0 ? (
            <div className="flex web:flex-row phone:flex-col justify-start flex-wrap">
              {imageList.map((item, idx) => {
                const url = item.url;
                return (
                  <div
                    className="web:w-52 phone:w-full phone:h-48 web:h-32 rounded-lg mr-2 web:[&:nth-child(3n)]:mr-0 mb-2 relative"
                    key={idx}
                  >
                    <img
                      className="w-full h-full bg-center bg-cover bg-no-repeat rounded-lg"
                      src={url}
                      alt=""
                    />
                    <img
                      onClick={() => {
                        const image = imageList;
                        const newImage = image.filter(
                          (x: any) => x.fileName !== item.fileName
                        );
                        setImageList(newImage);
                      }}
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                      className="absolute w-6 h-6 cursor-pointer top-2 right-2"
                      alt=""
                    />
                  </div>
                );
              })}
              {imageList?.length < 19 ? (
                <ImageUpload
                  imageLength={imageList?.length}
                  imageList={imageList}
                  images
                  multiUpload
                  imageListProps={setImageList}
                >
                  <div className="cursor-pointer text-center web:w-52 text-sm web:h-32 phone:h-48 phone:w-full rounded-lg border-input-border-color border flex justify-center items-center flex-col">
                    <img
                      className="h-16 w-16"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
                      alt=""
                    />
                    <div className="text-link web:mt-2 phone:mt-2 phone:mb-0">
                      {t(`Add-space.Add Gallery`)}
                    </div>
                    <div className="web:hidden phone:block">
                      {t(
                        `Add-accommodation.Upload the attractive features of your accommodation that traveler might love.`
                      )}
                    </div>
                  </div>
                </ImageUpload>
              ) : (
                ""
              )}
            </div>
          ) : (
            <ImageUpload
              imageLength={imageList?.length}
              imageList={imageList}
              images
              multiUpload
              imageListProps={setImageList}
            >
              <div className="cursor-pointer w-full web:min-w-[640px] phone:min-w-[320px] web:h-[382px] text-center phone:h-48 rounded-lg border-input-border-color border flex justify-center items-center flex-col">
                <img
                  className="phone:h-16 phone:w-16 web:w-32 web:h-32"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
                  alt=""
                />
                <div className="text-link my-4">
                  {t(`Add-space.Add Gallery`)}
                </div>
                <div className="text-sm">
                  {t(
                    `Add-accommodation.Upload the attractive features of your accommodation that traveler might love.`
                  )}
                </div>
              </div>
            </ImageUpload>
          )}
        </AcWrap>
        <div className="flex justify-between items-center">
          <Button
            onClick={() => onPre()}
            btnText={"Add-space.Previous"}
            className="inline-block bg-light-green rounded-lg py-3 px-4 text-pure-white"
          />
          <Button
            onClick={() => handlePageData()}
            btnText={"Add-space.Continue"}
            className="inline-block bg-light-green rounded-lg py-3 px-4 text-pure-white"
          />
        </div>
      </div>
    </div>
  );
};

export default EditSpaceGallery;
