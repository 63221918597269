import React from "react";
import { useTranslation } from "react-i18next";
import Icons from "./Icons";

interface AddButtonProps {
  title: string;
  className?: string;
  onClick?: () => void;
}

const AddButton = ({ title, className, onClick }: AddButtonProps) => {
  const {t} = useTranslation();
  return (
    <div onClick={onClick} className={` ${className} text-sm text-link inline-block cursor-pointer`}>
      <div className="flex items-center">
        <Icons iconName="add" className="mr-2 w-4 web:fill-link phone:fill-main-green" />
        <div className="block">{t(`${title}`)}</div>
      </div>
    </div>
  );
};

export default AddButton;
