import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import HostWrap from "components/Host/hostWrap";
import HostInput from "elements/HostInput";
import Button from "elements/Button";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";

import { Advanced, Confirmation, hour } from "../constants";

import { useAppDispatch, useAppSelector } from "redux/store";

import { policy } from "../../../redux/slice/addPrivateSlice";
import { changecase } from "utils/changeCase";

interface PolicyProps {
  onPre?: any;
  PolicyData: any;
  onChange?: (e: any) => void;
  onSubmit?(): any;
}

const Policy = ({ onPre, PolicyData, onSubmit }: PolicyProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const currentData = useAppSelector((state) => state.privateGround.Policy);

  const [policyValue, setPolicyValue] = useState<any>([]);
  const [policyName, setPolicyName] = useState("");
  const [policyEn, setPolicyEn] = useState("");
  const [policyId, setPolicyId] = useState(0);
  const [extraPolicy, setExtraPolicy] = useState("");

  const [advanceData, setAdvanceData] = useState<boolean>();
  const [advanceValue, setAdvanceValue] = useState("");

  const [rule, setRule] = useState([]);

  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");

  const [confirmValue, setConfinmValue] = useState("");
  const [confirmValueSetting, setConfinmSetting] = useState("");

  const [preConfirmValue, setPreConfinmValue] = useState("");
  const [preAdvanceData, setPreAdvanceData] = useState("");
  const [policyTitle, setPolicyTitle] = useState("");

  const page = currentData;

  const data = {
    bookingTime: {
      checkIn: `${startHour}:00`,
      checkOut: `${endHour}:00`,
    },
    advanceDate: advanceData,
    confirmMethod: confirmValueSetting,
    policyId: policyId,
    extraPolicy: TextareaReplaceNewLine(extraPolicy),
  };

  useEffect(() => {
    if (confirmValue || preConfirmValue === "Confirmation Within 24 Hours") {
      setConfinmSetting("WITHIN_24_HOURS");
    } else if (confirmValue || preConfirmValue === "Instant booking") {
      setConfinmSetting("INSTANT_BOOK");
    }
  }, [confirmValue, preConfirmValue]);

  useEffect(() => {
    if (advanceValue === "Booking is available today") {
      setAdvanceData(true);
    } else if (advanceValue === "Booking is not available today") {
      setAdvanceData(false);
    }
  }, [advanceValue]);

  useEffect(() => {
    requestToMustOutdoor
      .get("api/v2/policy/PG")
      .then((res) => {
        const data = res.data.data;
        setRule(data);
        const policyOptions = data.map((item: any) => {
          return t(`Cancellation-policy-data.${changecase(item.name)}`);
        });
        setPolicyValue(policyOptions);
        return res;
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const poliy = page?.policyId;
    if (poliy && poliy === 34) {
      setPolicyTitle("super strict");
    } else if (poliy === 35) {
      setPolicyTitle("moderate");
    } else if (poliy === 36) {
      setPolicyTitle("flexible");
    } else if (poliy === 37) {
      setPolicyTitle("strict");
    }
  }, [page?.policyId]);

  useEffect(() => {
    if (
      policyName === "Super Strict" ||
      policyName === "가장 엄격한" ||
      policyTitle === "super strict" ||
      policyTitle === "가장 엄격한"
    ) {
      setPolicyId(34);
      setPolicyEn("super strict");
    } else if (
      policyName === "Moderate" ||
      policyName === "일반" ||
      policyTitle === "moderate" ||
      policyTitle === "일반"
    ) {
      setPolicyId(35);
      setPolicyEn("moderate");
    } else if (
      policyName === "Flexible" ||
      policyName === "유연한" ||
      policyTitle === "flexible" ||
      policyTitle === "유연한"
    ) {
      setPolicyId(36);
      setPolicyEn("flexible");
    } else if (
      policyName === "Strict" ||
      policyName === "엄격한" ||
      policyTitle === "strict" ||
      policyTitle === "엄격한"
    ) {
      setPolicyId(37);
      setPolicyEn("strict");
    }
  }, [policyName, policyTitle]);

  const Submit = () => {
    if (startHour === "" && endHour === "") {
      return alert("체크인/아웃 시간을 체크해주세요.");
    } else if (confirmValue === "") {
      return alert("예약 확정을 체크해주세요.");
    } else if (advanceValue === "") {
      return alert("예약 준비기간 체크해주세요.");
    } else if (policyName === "" && policyTitle === "") {
      return alert("취소/환불정책을 체크해주세요.");
    }
    dispatch(policy(data));
    PolicyData(data);
  };
  const Prev = () => {
    onPre();
  };

  useEffect(() => {
    if (page?.confirmMethod === "WITHIN_24_HOURS") {
      setPreConfinmValue("Confirmation Within 24 Hours");
    } else if (page?.confirmMethod === "INSTANT_BOOK") {
      setPreConfinmValue("Instant booking");
    }
  }, [page?.confirmMethod]);

  useEffect(() => {
    if (page?.advanceDate === true) {
      setPreAdvanceData("Booking is available today");
    } else if (page?.advanceDate === false) {
      setPreAdvanceData("Booking is not available today");
    }
  }, [page?.advanceDate]);

  return (
    <div className="web:flex phone:block">
      <div className="web:w-160 web:mx-0 phone:mx-4">
        <div className="text-left">
          <HostWrap
            help
            toolTitle="Tooltip-add-private-ground.Policy check in out-tooltip title"
            toolSubTitle="Tooltip-add-private-ground.Policy check in out-tooltip desc"
            host
            title="Add-private-ground.Check In / Out*"
          >
            <HostInput
              timeselect
              selected={
                startHour ? startHour : page?.bookingTime.checkIn.slice(0, 2)
              }
              placeholder="Hr"
              onChange={(e) => {
                setStartHour(e.target.value);
              }}
              options={hour}
              formTitle={t("Add-private-ground.Check in")}
            />
            <HostInput
              timeselect
              selected={
                endHour ? endHour : page?.bookingTime.checkOut.slice(0, 2)
              }
              placeholder="Hr"
              onChange={(e) => {
                setEndHour(e.target.value);
              }}
              options={hour}
              nomargin
              formTitle={t("Add-private-ground.Check out")}
            />
          </HostWrap>
          <HostWrap
            help
            toolTitle="Tooltip-add-private-ground.Policy confirmation-tooltip title"
            toolSubTitle={
              "Tooltip-add-private-ground.Policy confirmation-tooltip desc"
            }
            host
            title={t("Add-private-ground.Confirmation*")}
          >
            <HostInput
              select
              size="XL"
              selected={confirmValue}
              onChange={(e) => {
                setConfinmValue(e.target.value);
              }}
              selectTrans="Confirmation-data"
              options={Confirmation}
              placeholder="Add-private-ground.Select"
            />
          </HostWrap>
          <HostWrap
            help
            toolTitle="Tooltip-add-private-ground.Policy advance-tooltip title"
            toolSubTitle={
              "Tooltip-add-private-ground.Policy advance-tooltip desc"
            }
            host
            title="Add-private-ground.Advance Notice*"
          >
            <HostInput
              select
              size="XL"
              selected={advanceValue}
              selectTrans="Advance-notice-data"
              onChange={(e) => {
                setAdvanceValue(e.target.value);
              }}
              options={Advanced}
              placeholder="Add-private-ground.Select"
            />
          </HostWrap>
          <HostWrap
            help
            toolTitle="Tooltip-add-private-ground.Policy additional-tooltip title"
            toolSubTitle="Tooltip-add-private-ground.Policy additional-tooltip desc"
            host
            title="Add-private-ground.Additional Rule / Policy"
          >
            <HostInput
              size="XL"
              onChange={(e) => {
                setExtraPolicy(e.target.value);
              }}
              inputValue={extraPolicy ? extraPolicy : page?.extraPolicy}
              textarea
              inputLengthPosition="right-0"
              inputMaxLength={500}
              placeholder={
                "Please describe additional rule / policy on your Private Ground \n• e.g. No Smoking in the area of private ground. Smoking is available in designated smoking area"
              }
              rows={4}
            />
          </HostWrap>
          <HostWrap
            help
            toolTitle="Tooltip-add-private-ground.Policy cancel refund-tooltip title"
            toolSubTitle="Tooltip-add-private-ground.Policy cancel refund-tooltip desc"
            host
            title="Add-private-ground.Cancellation & Refund Policy*"
            column
          >
            <div className="mt-4">
              {t(
                "Add-private-ground.MUST OUTDOOR will enforce following cancellation policy"
              )}
            </div>
            <HostInput
              options={policyValue}
              onChange={(e) => {
                setPolicyName(e.target.value);
              }}
              selected={policyName ? policyName : policyTitle}
              select
              size="XL"
              placeholder={`Add-private-ground.Select Cancellation Policy`}
            />
            <div className="font-bold text-sm mt-4 mb-2">
              {policyName ? t(`${policyName}`) : ""}{" "}
              {t("Add-private-ground.Cancellation Policy")}
            </div>
            <div>
              {rule.map((item: any, idx) => {
                if (policyEn === item.name) {
                  return (
                    <div key={idx}>
                      {item?.firstRange !== null ? (
                        <div>
                          {t(
                            "Cancellation-policy-data.{{days}}100% refund of the payment",
                            { days: item?.firstRange }
                          )}
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      {item?.secondRange !== null ? (
                        <div>
                          {t(
                            "Cancellation-policy-data.{{days}}70% refund of the payment",
                            { days: item?.secondRange }
                          )}
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      {item?.thirdRange !== null ? (
                        <div>
                          {t(
                            "Cancellation-policy-data.{{days}}50% refund of the payment",
                            { days: item?.thirdRange }
                          )}
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      {item?.fourthRange !== null ? (
                        <div>
                          {t(
                            "Cancellation-policy-data.{{days}}30% refund of the payment",
                            { days: item?.fourthRange }
                          )}
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      {item?.fifthRange !== null ? (
                        <div>
                          {t(
                            "Cancellation-policy-data.{{days}}0% refund of the payment",
                            { days: item?.fifthRange }
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </HostWrap>
        </div>
        <div className="mt-12 mb-8 justify-between flex">
          <Button
            onClick={() => {
              Prev();
            }}
            btnText={t("Add-private-ground.Previous")}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
          />
          <Button
            isIcon
            src={"register-completed"}
            onClick={() => Submit()}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-private-ground.Register")}
          />
        </div>
      </div>
      <div className="w-full ml-4"></div>
    </div>
  );
};

export default Policy;
