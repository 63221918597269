import React from 'react';
import { useTranslation } from 'react-i18next';

interface bookingWrapProps {
    className?: string;
    children?: any;

    size?: "S" | "M" | "L";
    cancel?: boolean;
    pending?: boolean;
    title: string
    _ref?:any;
}

const BookingWrap = ({className, _ref, size, title, children, cancel, pending} : bookingWrapProps) => {
    const {t} = useTranslation();
    const smallWrap = size === "S";
    const mediumWrap = size === "M";

    return (
        <div ref={_ref} className={`${className} ${smallWrap ? "w-80" : ""} ${mediumWrap ? " w-[800px]" : ""}`}>
            <div className={`px-4 py-3 ${pending ? 'bg-tan' : "bg-light-green"} ${cancel ? "bg-pure-red" : "bg-light-green"}  rounded-t-lg text-pure-white`}>{t(`${title}`)}</div>
            <div className='border-x border-b border-input-text rounded-b-lg p-4'>
            {children}
            </div>
        </div>
    );
};

export default BookingWrap;