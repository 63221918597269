import React from "react";
import { useTranslation } from "react-i18next";
import Icons from "./Icons";

interface DetailInfoProps {
  children?: React.ReactNode;
  className?: string;
  noFlex?: boolean;

  title?: string;
  imgUrl?: string;

  w276?: boolean;
  nomargin?: boolean;
  withIcon?: boolean;
  onlyIconImg?: string;

  onlyDesc?: boolean;
  iconWithDescCol?: boolean;
  iconWithDescRow?: boolean;
  iconWithDistance?: boolean;
  RecoSpot?: boolean;
}

const DetailInfo = ({
  className,
  children,
  noFlex,
  title,
  imgUrl,

  w276,
  withIcon,
  nomargin,
  onlyIconImg,

  onlyDesc,
  iconWithDescCol,
  iconWithDescRow,
  iconWithDistance,
}: DetailInfoProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flex ${nomargin ? "mb-0" : ""} ${withIcon ? "mb-8" : "mb-4"}`}
    >
      {title ? (
        <div
          className={`${
            w276 ? "min-w-[256px]" : "w-[119px] mr-[81px] whitespace-normal"
          } font-bold`}
        >
          {t(`${title}`)}
        </div>
      ) : imgUrl ? (
        <div className="w-[200px]">
          <img className="w-24 h-24 rounded-lg" src={imgUrl} alt="" />
        </div>
      ) : (
        <div className="w-[200px]">
          <Icons
            className="w-24 h-24 rounded-lg"
            iconName={"my-account-avatar"}
          />
        </div>
      )}
      <div className="flex-1">
        <div
          className={`${className} ${
            noFlex ? "" : "flex"
          } flex-wrap justify-start align-middle`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default DetailInfo;
