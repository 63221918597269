import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { changeDataGetAll, changeDate } from "utils/changeDate";
import HostInquiryCard from "./hostInquiryCard";

interface InquiryProps {
  inquiryList?: any;
}

const HostInquiry = ({ inquiryList }: InquiryProps) => {
  const { t } = useTranslation();
  const [respond, setRespond] = useState<any>([]);
  const [respondInput, setRespondInput] = useState<any>([]);
  return (
    <div>
      <table>
        <thead className="border-b border-input-border-color">
          <tr>
            <th className="p-4 w-full flex items-center justify-between">
              <div>{t(`Message-box.Status`)}</div>
              <div>{t(`Message-box.Inquiry`)}</div>
              <div />
            </th>
            {/* <th className="p-4">{t(`Message-box.Inquiry`)}</th> */}
          </tr>
        </thead>
        {inquiryList ? (
          inquiryList?.map((item: any, idx: number) => {
            const answered = item.answered;
            const content = item.content;
            const travelerName = item.travelerName;
            const createdAt = changeDataGetAll(item.createdAt);
            const idxNum = Number(idx);
            const id = item.id;
            const hostId = item.hostId;
            const memberId = item.memberId;
            const answer = item.answer;
            const deleted = item.deleted;
            const hostName = item.hostName;
            if (deleted === false) {
              return (
                <HostInquiryCard
                  answer={answer}
                  id={id}
                  memberId={memberId}
                  hostId={hostId}
                  hostName={hostName}
                  onRespond={() => {
                    !respondInput.includes(idxNum)
                      ? setRespondInput([idxNum])
                      : setRespondInput(
                          respondInput.filter((x: number) => x !== idxNum)
                        );
                  }}
                  answered={answered}
                  onClick={() => {
                    !respond.includes(idxNum)
                      ? setRespond([idxNum])
                      : setRespond(respond.filter((x: number) => x !== idxNum));
                  }}
                  createdAt={createdAt}
                  memeberName={travelerName}
                  content={content}
                  key={idx}
                  respond={respond.includes(idxNum)}
                  respondInput={respondInput.includes(idxNum)}
                />
              );
            }
          })
        ) : (
          <tr>
            <td>문의내역이 없습니다.</td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default HostInquiry;
