import React from "react";
import { useTranslation } from "react-i18next";

interface calendarInputProps {
  className?: string;

  children?: any;
  onChange?: (e: any) => void;
  availableClick?: () => void;
  unavailableClick?: () => void;

  availableDisabled?: boolean;
  unavailableDisabled?: boolean;

  title?: string;
  subTitle?: string;
  titleBlack?: boolean;
  iconFlex?: boolean;
  iconSrc?: string | any;

  input?: boolean;
  inputValue?: any;
  disabled?: boolean;
  buttons?: boolean;
  select?: boolean;
  selected?: any;
  options?: any[];
  textarea?: boolean;

  placeholder?: string;

  png?: boolean;

  noMargin?: boolean;
}

const CalendarInput = ({
  className,
  children,
  availableClick,
  unavailableClick,
  onChange,

  availableDisabled,
  unavailableDisabled,

  title,
  subTitle,
  titleBlack,
  iconFlex,
  iconSrc,
  input,
  inputValue,
  disabled,
  buttons,
  select,
  selected,
  options,
  textarea,
  placeholder,

  png,

  noMargin,
}: calendarInputProps) => {
  const { t } = useTranslation();
  return (
    <div className={`text-left ${noMargin ? "" : "mb-8"}`}>
      <div
        className={`text-sm ${titleBlack ? "" : "text-input-text"} ${
          children ? "flex items-baseline" : ""
        }`}
      >
        {children}
        <div>{t(`${title ? title : ""}`)}</div>
      </div>
      <div></div>
      <div className={`${iconFlex ? "flex mt-2" : ""}`}>
        {iconFlex ? (
          <>
            <img
              className="w-5 h-5 mr-2"
              src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/${iconSrc}.${
                png ? "png" : "svg"
              }`}
              alt=""
            />
            <div>{subTitle}</div>
          </>
        ) : (
          ""
        )}
        {input ? (
          <input
            disabled={disabled}
            defaultValue={inputValue}
            onChange={onChange}
            className={`${className} web:w-[328px] h-[37px] phone:w-full shadow-ms rounded-lg p-2 mt-2`}
          ></input>
        ) : select ? (
          <select
            disabled={disabled}
            value={selected}
            onChange={onChange}
            className={`${className} web:w-[328px] h-[37px] phone:w-full shadow-ms rounded-lg p-2 mt-2`}
          >
            {placeholder ? <option value="">{placeholder}</option> : ""}
            {options?.map((value) => {
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
        ) : textarea ? (
          <textarea
            placeholder={t(`${placeholder}`)}
            className={`${className} web:w-[328px] phone:w-full shadow-ms text-sm rounded-lg p-2 mt-2`}
          ></textarea>
        ) : buttons ? (
          <div className="flex justify-between text-center mt-2">
            <select
              value={selected}
              onChange={onChange}
              className="web:w-[328px] h-[37px] phone:w-full shadow-ms rounded-lg p-2 mt-2"
            >
              <option value="">{t(`${placeholder}`)}</option>
              <option value={"AVAILABLED"}>
                {t("Calendar-manager.Available")}
              </option>
              <option value={"UNAVAILABLED"}>
                {t("Calendar-manager.Unavailable")}
              </option>
            </select>

            {/* <button
              onClick={availableClick}
              disabled={availableDisabled}
              className="w-[156px] py-2 shadow-btn text-sm rounded-lg"
            >
              {t("calendar.Available")}
            </button>
            <button
              onClick={unavailableClick}
              disabled={unavailableDisabled}
              className="w-[156px] py-2 shadow-btn text-sm rounded-lg"
            >
              {t("calendar.Unavailable")}
            </button> */}
          </div>
        ) : (
          ""
        )}
      </div>
      <div></div>
    </div>
  );
};

export default CalendarInput;
