import React from "react";

import HostingImg from "components/BecomeHost/hostingImage";
import AboutMust from "components/BecomeHost/aboutMust";
import DescriptionImg from "components/BecomeHost/descriptionImg";
import HostBenefit from "components/BecomeHost/hostBenefit";
import HostProcess from "components/BecomeHost/hostProcess";
import StartHost from "components/BecomeHost/startHost";

const BecomeHost = () => {
  return (
    <>
      <HostingImg />
      <AboutMust />
      <DescriptionImg />
      <HostBenefit />
      {/* <HostProcess /> */}
      <StartHost />
    </>
  );
};

export default BecomeHost;
