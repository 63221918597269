
import Icons from "elements/Icons";
import React from "react";
import Slider from "react-slick";

interface MainCustomSliderProps {
    children?: any;
    cardPosition?: boolean;
    customSlideToShow?: number;
}

const MainCustomSlider = ({children, customSlideToShow, cardPosition}:MainCustomSliderProps) => {
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
        <div
          {...props}
          className={currentSlide === 0 ? " slick-disabled" : ""}
          aria-hidden="true"
          aria-disabled={currentSlide === 0 ? true : false}
          type="button"
        >
          <Icons
            className={`cursor-pointer w-8 h-8 absolute z-100 top-[42%] right-[-12px]`}
            iconName="slider-arrow-right"
          />
        </div>
      );
      const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
        <div
          {...props}
          className={currentSlide === 0 ? " slick-disabled" : ""}
          aria-hidden="true"
          aria-disabled={currentSlide === 0 ? true : false}
          type="button"
        >
          <Icons
            className={`cursor-pointer w-8 h-8 absolute z-100 top-[42%] left-[-12px]`}
            iconName="slider-arrow-left"
          />
        </div>
      );
    
    const setting = {
        dots: false,
        infinite: true,
        slidesToShow: customSlideToShow ? customSlideToShow : 4,
        draggable : false,
        slidesToScroll: 1,
        nextArrow: <SlickArrowRight />,
        prevArrow: <SlickArrowLeft />,
        responsive: [
          {
            breakpoint: 1190,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 895,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows:false,
              centerMode:true,
              draggable : true,
            },
          },
          {
            breakpoint: 711,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
                arrows:false,
                centerMode:true,
                centerPadding: "10%",
                draggable : true,
              },
            },
          {
            breakpoint: 385,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
                arrows:false,
                centerPadding:"6%",
                centerMode:true
              },
            },
          {
            breakpoint: 355,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
                arrows:false,
                centerPadding:"4%",
                centerMode:true
              },
            },
        ]
    }
  return (
    <>
      <Slider {...setting}>{children}</Slider>
    </>
  );
};

export default MainCustomSlider;
