import React, { useEffect, useState } from "react";

import AcWrap from "elements/AcWrap";
import ImageUpload from "elements/ImageUpload";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import Button from "elements/Button";

const EditAcGallery = ({ acGalleryData }: any) => {
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState<any>({});
  const [imageList, setImageList] = useState<any[]>([]);

  useEffect(() => {
    const current = acGalleryData.currentImage;
    const list = acGalleryData.imageList;
    if (Object.keys(current).length !== 0) {
      setCurrentImage({ ...current });
    }
    if (list?.length > 0) {
      setImageList([...list]);
    }
  }, [acGalleryData.currentImage, acGalleryData.imageList]);

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          help
          toolTitle="이제 멋진 사진을 공개할 차례입니다."
          toolSubTitle="첫번째 사진은 대표 메인 사진으로 설정됩니다. 게스트들에게 가장 많이 그리고 가장 먼저 노출되는 사진이며, 게스트들의 클릭을 유도할 수 있는 중요한 요소입니다."
          title="대표이미지"
          need
        >
          {currentImage?.fileName ? (
            <div className="cursor-pointer text-center web:w-52 text-sm phone:h-48 web:h-32 phone:w-full rounded-lg border-input-border-color border relative">
              <img
                className="w-full h-full bg-center bg-no-repeat object-cover bg-cover rounded-lg"
                src={currentImage?.url}
                alt={`${currentImage?.profile}`}
              />
              <img
                onClick={() => {
                  setCurrentImage({});
                }}
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                className="absolute z-100 w-6 h-6 cursor-pointer top-2 right-2"
                alt=""
              />
            </div>
          ) : (
            <ImageUpload
              currentImage
              imageLength={currentImage?.length}
              imageListProps={setCurrentImage}
            >
              <div className="cursor-pointer text-center web:w-52 text-sm web:h-32 phone:h-48 phone:w-full rounded-lg border-input-border-color border flex justify-center items-center flex-col">
                <img
                  className="h-16 w-16"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
                  alt=""
                />
                <div className="text-link web:mt-2 phone:mt-2 phone:mb-0">
                  {t("Add-accommodation.Add Gallery")}
                </div>
                <div className="web:hidden phone:block">
                  {t(
                    "Add-accommodation.Upload the attractive features of your accommodation that traveler might love."
                  )}
                </div>
              </div>
            </ImageUpload>
          )}
        </AcWrap>
        <AcWrap
          help
          toolTitle={"장소 이미지 (최소 5장 이상)"}
          toolSubTitle={
            "- 숙박 장소의 전체적인 분위기와 외부 풍경들의 매력적인 포인트를 놓치지 말고 하나하나 담아주세요.\n-전문가 사진이 아니어도 괜찮아요. 깔끔하게 촬영한 사진이면 충분히 매력을 뽐낼수 있어요~!!"
          }
          toolEx="주의) 과도한 필터 및 보정은 역효과를 가져올 수 있습니다."
          title="공간이미지"
          need
        >
          {imageList.length !== 0 ? (
            <div className="flex web:flex-row phone:flex-col justify-start flex-wrap">
              {imageList.map((item, idx) => {
                const url = item.url;
                return (
                  <div
                    className="web:w-52 phone:w-full phone:h-48 web:h-32 rounded-lg mr-2 web:[&:nth-child(3n)]:mr-0 mb-2 relative"
                    key={idx}
                  >
                    <img
                      className="w-full h-full bg-center bg-no-repeat object-cover rounded-lg bg-cover"
                      src={url}
                      alt=""
                    />
                    <img
                      onClick={() => {
                        const image = imageList;
                        const newImage = image.filter(
                          (x: any) => x.fileName !== item.fileName
                        );
                        setImageList(newImage);
                      }}
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                      className="absolute w-6 h-6 cursor-pointer top-2 right-2"
                      alt=""
                    />
                  </div>
                );
              })}
              {imageList?.length < 19 ? (
                <ImageUpload
                  imageLength={imageList?.length}
                  imageList={imageList}
                  images
                  imageListProps={setImageList}
                >
                  <div className="cursor-pointer text-center web:w-52 text-sm web:h-32 phone:h-48 phone:w-full rounded-lg border-input-border-color border flex justify-center items-center flex-col">
                    <img
                      className="h-16 w-16"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
                      alt=""
                    />
                    <div className="text-link web:mt-2 phone:mt-2 phone:mb-0">
                      {t("Add-accommodation.Add Gallery")}
                    </div>
                    <div className="web:hidden phone:block">
                      {t(
                        "Add-accommodation.Upload the attractive features of your accommodation that traveler might love."
                      )}
                    </div>
                  </div>
                </ImageUpload>
              ) : (
                ""
              )}
            </div>
          ) : (
            <ImageUpload
              imageLength={imageList?.length}
              imageList={imageList}
              images
              imageListProps={setImageList}
            >
              <div className="cursor-pointer w-full web:min-w-[640px] phone:min-w-[320px] web:h-[382px] text-center phone:h-48 rounded-lg border-input-border-color border flex justify-center items-center flex-col">
                <img
                  className="phone:h-16 phone:w-16 web:w-32 web:h-32"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
                  alt=""
                />
                <div className="text-link my-4">
                  {" "}
                  {t("Add-accommodation.Add Gallery")}
                </div>
                <div className="text-sm">
                  {t(
                    "Add-accommodation.Upload the attractive features of your accommodation that traveler might love."
                  )}
                </div>
              </div>
            </ImageUpload>
          )}
        </AcWrap>
        <div className="flex justify-end items-center">
          <Button
            btnText={"Add-accommodation.Save"}
            className="inline-block bg-tan rounded-lg py-3 px-4 text-pure-white"
          />
        </div>
      </div>
    </div>
  );
};

export default EditAcGallery;
