import React, { useCallback, useEffect, useState } from "react";
import EditSpaceGeneralInfo from "./editSpaceGeneralInfo";
import EditSpaceAmenities from "./editSpaceAmenities";
import EditSpaceGallery from "./editSpaceGallery";
import EditSpaceOperation from "./editSpaceOperation";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import MoblieHeader from "components/moblieHeader";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const EditSpace = () => {
  const { t } = useTranslation();
  const { state: stateId } = useLocation();

  const [spaceData, setSpaceData] = useState([]);
  const [tabNum, setTabNum] = useState(0);

  const fetctSpace = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/space/${stateId}`,
    }).then((res) => {
      const data = res.data.data;
      setSpaceData(data);
      return data;
    });
  }, []);

  const handleOnNext = () => {
    setTabNum(tabNum + 1);
    window.scrollTo(0, 0);
  };
  const handleOnPrev = () => {
    setTabNum(tabNum - 1);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetctSpace();
  }, []);

  const editTab = [
    { id: 0, title: "General Info" },
    { id: 1, title: "Space Gallery" },
    { id: 2, title: "Space Amenities" },
    { id: 3, title: "Operation" },
  ];

  const changeTab = (id: number) => {
    setTabNum(id);
  };

  // 새로고침 막기
  useEffect(() => {
    // 새로고침 막기(조건 부여 가능)
    window.onbeforeunload = function() {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <div className="web:text-center phone:text-left web:p-8 mx-auto lg:max-w-1216">
      <MoblieHeader left center="My-accommodation.Edit Space" />
      <div className="web:flex">
        <HostAccount />
        <HostAccountHeader
          titleIcon="outdoor-accommodation"
          title="My-accommodation.Edit Space"
        >
          <div
            className="border-b h-11 flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll"
          >
            {editTab.map((item, idx) => {
              return (
                <div
                  onClick={() => {
                    changeTab(item?.id);
                  }}
                  key={idx}
                  className={`
                  cursor-pointer
                  ${
                    item.id === tabNum
                      ? "bg-light-green text-pure-white"
                      : " text-input-text"
                  } px-4 items-center flex focus:bg-light-green rounded-t-lg`}
                >
                  {t(`Add-space.${item.title}`)}
                </div>
              );
            })}
          </div>
          {tabNum === 0 ? (
            <EditSpaceGeneralInfo
              onNext={() => {
                handleOnNext();
              }}
            />
          ) : tabNum === 1 ? (
            <EditSpaceGallery
              onNext={() => {
                handleOnNext();
              }}
              onPre={handleOnPrev}
            />
          ) : tabNum === 2 ? (
            <EditSpaceAmenities
              onNext={() => {
                handleOnNext();
              }}
              onPre={handleOnPrev}
            />
          ) : tabNum === 3 ? (
            <EditSpaceOperation
              onNext={() => {
                handleOnNext();
              }}
              onPre={handleOnPrev}
            />
          ) : (
            ""
          )}
        </HostAccountHeader>
      </div>
    </div>
  );
};

export default EditSpace;
