import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

requestToMustOutdoor.defaults.withCredentials = true

export const hostInfo = createAsyncThunk(
    'host/hostinfo',
    async () => {
        try {
            const member_id = localStorage.getItem("m")
            const res = await requestToMustOutdoor({
                method: "GET",
                url: `/api/v1/host/search/${member_id}`
            });
            const value = res.data.data;
            const { createdAt, hasAt, hasAc, hasPg, country, id, imageUrl, languageCode, atInfo, acInfo, pgInfo } = value
            
            if (value.id !== null || value.id !== undefined) {
                localStorage.setItem("hostNum", id)
            }
            if (imageUrl !== null) {
                localStorage.setItem("profile", imageUrl)
            }
            const itemStatus:{hasAc:boolean, hasAt:boolean, hasPg:boolean, atInfo:{} | null, acInfo:{} | null, pgInfo:{} | null} = {hasAc, hasAt, hasPg, atInfo, acInfo, pgInfo}
            localStorage.setItem("itemStatus", JSON.stringify(itemStatus))
            localStorage.setItem("country", languageCode)
            localStorage.setItem("joined", createdAt)
            localStorage.setItem("hostCountry", country)
            return res.data.data
        } catch (err) {
            return err
        }
    })