import React from "react";
import { useTranslation } from "react-i18next";

const HostBenefit = () => {
  const { t } = useTranslation();

  const cardArray = [
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/free.svg",
      header: "100% free to create listings",
      impacts: "Absolutely 100% free",
      text: "Register independent store in the platform for free",
      image:
        "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/Partner-with-us-3.jpg",
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/marketing.svg",
      header: "Great advertising Impact",
      impacts: "Obtain great advertising effect",
      text: "MUST OUTDOOR is actively targeting for",
      image:
        "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/Partner-with-us-4.jpg",
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/world-guests.svg",
      header: "Attract more guests around the world",
      impacts: "Welcome and meet more outdoor travellers",
      text: "MUST OUTDOOR is aiming global service",
      image:
        "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/Partner-with-us-5.jpg",
    },
    {
      icon: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/earn-more.svg",
      header: "Increase sales and earn more",
      impacts: "Stop worrying about low utilization rate",
      text: "Through attracting more outdoor leisure and oversea travellers",
      image:
        "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/Partner-with-us-6.jpg",
    },
  ];

  return (
    <div className="w-full phone:max-w-[1024px] phone:px-4 web:px-0 mx-auto py-16">
      <div className="text-center text-2xl font-bold">
        {t("Partner-becomehost.What are the benefits of being a MUST OUTDOOR Host?")}
      </div>
      <div className="grid web:grid-cols-2 phone:grid-cols-1 phone:justify-items-center gap-8 mt-12">
        {cardArray.map((item, idx) => {
          return (
            <div className="web:w-[512px] phone:w-[328px] rounded-lg" key={idx}>
              <div
                className="w-full web:h-64 phone:h-48 bg-cover rounded-t-2xl bg-center"
                style={{ backgroundImage: `url(${item.image})` }}
              />
              <div className="w-full web:h-[304px] shadow-selectCard rounded-b-2xl p-4">
                <div className="flex items-center">
                  <img
                    src={item.icon}
                    className="web:w-16 web:h-16 phone:w-11 phone:h-11"
                    alt=""
                  />
                  <div className="ml-4 web:text-2xl phone:text-base font-bold">
                    {t(`Partner-becomehost.${item.header}`)}
                  </div>
                </div>
                <div className="my-4 font-bold">
                  {t(`Partner-becomehost.${item.impacts}`)}
                </div>
                <div className="phone:text-sm web:text-base">
                  {t(`Partner-becomehost.${item.text}`)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HostBenefit;
