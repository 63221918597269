import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Link, useNavigate } from "react-router-dom";
import Icons from "./Icons";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { isMember } from "lib/isMember";

type CardProps = {
  onClick?: () => void;

  listingId?: number;
  url?: string;
  state: string;
  image?: string;
  country: string;
  title: string;
  locationThemes?: any;
  wish?: any;
  FreePrivate?: boolean;
  memberId?: number;
  type?: any;
  price?: string;
  currency?: string;
  stars?: string;
  reviews?: string;
  className?: string;
  style?: React.CSSProperties;
  height?: number;
  onMoustEnter?: () => any;
  noMarginBotton?: boolean;
};

const Card = ({
  onClick,
  memberId,
  listingId,
  wish,
  FreePrivate,
  image,
  country,
  state,
  title,
  type,
  locationThemes,
  price,
  stars,
  reviews,
  className,
  onMoustEnter,
  noMarginBotton,
}: CardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [wishList, setWishList] = useState(false);

  useEffect(() => {
    setWishList(wish);
  }, [wish]);

  return (
    <div
      onClick={onClick}
      onMouseEnter={onMoustEnter}
      className={`${className} overflow-hidden leading-normal cursor-pointer inline-block ${
        noMarginBotton ? "" : "web:mb-8 phone:mb-4"
      } rounded-lg w-[276px] h-[261px] shadow-card`}
    >
      <div className="relative">
        <img
          className={"bg-center z-50 bg-cover rounded-t-lg h-40 w-66"}
          alt=""
          src={`${image}`}
        />
        {/* 지명 */}
        <div className="absolute flex left-0 bottom-0 p-2">
          <Icons
            iconName="destination-white"
            className="inline-block w-3 mr-2 fill-pure-white"
          />
          <span className="text-pure-white text-xs">{state}</span>
        </div>
        {!FreePrivate ? (
          <div className="absolute right-3 top-2 z-100">
            {wishList ? (
              <Icons
                onClick={() => {
                  if (!isMember()) {
                    alert("로그인이 되어있어야 이용 가능한 서비스 입니다 :)");
                    navigate("/log-in");
                  } else {
                    requestToMustOutdoor({
                      method: "PATCH",
                      url: `/api/v1/cart/like`,
                      params: { type: "private-ground" },
                      data: {
                        ilike: false,
                        listingId: listingId,
                        memberId: memberId,
                      },
                    }).then((res) => {
                      const data = res.data.data;
                      setWishList(false);
                      return data;
                    });
                  }
                }}
                iconName="wishlist-color"
                className="w-8 h-8"
              />
            ) : (
              <Icons
                onClick={() => {
                  if (!isMember()) {
                    alert("로그인이 되어있어야 이용 가능한 서비스 입니다 :)");
                    navigate("/log-in");
                  } else {
                    requestToMustOutdoor({
                      method: "PATCH",
                      url: `/api/v1/cart/like`,
                      params: { type: "private-ground" },
                      data: {
                        ilike: true,
                        listingId: listingId,
                        memberId: memberId,
                      },
                    }).then((res) => {
                      const data = res.data.data;
                      setWishList(true);
                      return data;
                    });
                  }
                }}
                className="w-8 h-8 hover:rotate-12"
                iconName="wishlist-white"
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {/* 타이틀 */}
      <div className={"content px-2 py-2"}>
        <div className="title text-left font-bold w-[260px] overflow-hidden text-ellipsis whitespace-nowrap">
          {title}
        </div>
        {/* 주요시설 Box */}
        <div className={"flex py-2"}>
          <div>
            <img src={type?.iconUrl} className="h-6 w-6 mr-4" alt="" />
          </div>
          <div className="flex">
            {locationThemes?.map((Item: any, i: number) => {
              const data = Item.themeInfo;
              return (
                <img
                  src={data.iconUrl}
                  key={i}
                  alt={data.enName}
                  className={"h-6 w-6 mr-4 last:mr-0"}
                />
              );
            })}
          </div>
        </div>
        {/* 리뷰박스 */}
        <div className="flex justify-between items-center">
          <div className="flex items-center justify-center">
            <Icons iconName="rating-star-o" />
            <span className="text-sm font-bold mx-1">{stars}</span>
            <div className="font-bold text-xs">NEW</div>
            {/* <div className="text-xs text-light-grey">
              {reviews ? reviews : "0 Reviews"}
            </div> */}
          </div>
          {FreePrivate ? (
            ""
          ) : (
            <div className="font-bold text-sm text-light-grey">
              From {price ? price : "예약마감"}
              {/* <Trans i18nKey="product.From price" values={{ price }}>
              From <Price currency={currency} value={price} />
              <div></div>
            </Trans> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
