import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Footer from "components/Footer";
import Header from "components/Header";
import NewModal from "elements/newModal";

const Layout = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(true);
  return (
    <>
      {/* <div className=' w-200 h-40 z-[9999] fixed left-2/4 text-center bg-pure-white flex items-center flex-col justify-center top-2/4 -translate-x-2/4 -translate-y-2/4'>
    {`현재 사이트 리뉴얼 중으로 서비스 이용이 불가합니다. 불편을 드려 죄송합니다.\n(예약요청 및 문의를 받지 않습니다)`}
    </div> */}
      {modal && (
        <NewModal
          imageUrl="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-red.svg"
          buttonText="닫기"
          className="whitespace-pre-wrap"
          onClick={() => {
            setModal(false);
          }}
        >
          {`현재 사이트 리뉴얼 중으로 서비스 이용이 불가합니다. 불편을 드려 죄송합니다.\n(예약요청 및 문의를 받지 않습니다)`}
        </NewModal>
      )}
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
