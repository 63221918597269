import React from "react";
import { useTranslation } from "react-i18next";

interface ButtonProps {
  onClick?: () => void;
  className?: string;
  iconClassName?: string;
  Icon?: any;
  disalbed?: boolean;
  src?: any;
  noneShadow?: boolean;
  isIcon?: boolean;
  isImg?: string;
  btnText?: any;
  btnSize?: "small" | "medium";
  png?: boolean;
}

function Button({
  onClick,
  isIcon,
  isImg,
  src,
  className,
  noneShadow,
  btnSize,
  btnText,
  iconClassName,
  png,
}: ButtonProps) {
  const { t } = useTranslation();
  const smallBtn = btnSize === "small";
  const mediumBtn = btnSize === "medium";
  return (
    <>
      <div
        onClick={onClick}
        className={`${className} ${
          isIcon || isImg ? "flex" : ""
        } items-center ${noneShadow ? "" : "shadow-btn"} cursor-pointer ${
          smallBtn
            ? "w-25 py-2 text-sm"
            : mediumBtn
            ? "w-34 py-3 text-base"
            : ""
        }`}
      >
        {isIcon ? (
          <img
            className={`${iconClassName} w-5 h-5 mr-2`}
            src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/${src}.${
              png ? "png" : "svg"
            }`}
            alt=""
          />
        ) : (
          ""
        )}
        {isImg ? (
          <img
            alt=""
            className={`${iconClassName} w-5 h-5 mr-2`}
            src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/${isImg}.svg`}
          />
        ) : (
          ""
        )}
        <div className="text-center">{t(`${btnText}`)}</div>
      </div>
    </>
  );
}

export default Button;
