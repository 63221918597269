import React, { useEffect, useRef } from "react";
import Slider from "react-slick";

interface fullSizeModalProps {
  imgList?: any[] | any;
  isOpen?: any;
  tourist?: boolean;
  TouristTitle?: string;
  TouristDesc?: string;
  selectHostingType?: boolean;
  children?: any;
  className?: string;
}

interface customSlideProps {
  children?: any;
  isMobile?: boolean;
  cardPosition?: boolean;
  isCenterMode?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
}

const FullSizeModal = (
  {
    imgList,
    isOpen,
    tourist,
    TouristTitle,
    TouristDesc,
    selectHostingType,
    children,
    className,
  }: fullSizeModalProps,
  { cardPosition, isCenterMode, slidesToShow, slidesToScroll }: customSlideProps
) => {
  useEffect(() => {
    document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <div
      {...props}
      className={currentSlide === 0 ? " slick-disabled" : ""}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <img
        className={`cursor-pointer w-8 h-8 absolute web:block phone:hidden z-100 top-[50%] right-[-12px]`}
        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/slider-arrow-right.svg"
        alt=""
      />
    </div>
  );
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
    <div
      {...props}
      className={currentSlide === 0 ? " slick-disabled" : ""}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <img
        className={`cursor-pointer w-8 h-8 absolute web:block phone:hidden z-100 top-[50%] left-[-12px]`}
        src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/slider-arrow-left.svg`}
        alt=""
      />
    </div>
  );

  const settings = {
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: false,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
  };

  return (
    <div
      id="ModalOverlay"
      className="px-2 fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,.8)] box-border z-[10001]"
    >
      <div className="z-[10000] absolute right-6 top-4">
        <img
          onClick={isOpen}
          className="cursor-pointer"
          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/menu-close-white.svg"
          alt=""
        />
      </div>
      <div
        id="Modal"
        className={`${
          className
            ? className :
          selectHostingType
            ? "web:h-full phone:h-[800px] phone:overflow-y-auto fixed w-full top-2/4 -translate-y-[50%] left-2/4 -translate-x-[50%] text-center lg:max-w-1216 web:px-8 web:py-8 web:mx-auto phone:px-4 phone:pt-16 phone:pb-16"
            : "fixed top-2/4 max-h-[810px] left-2/4 w-full web:px-8 phone:px-0 -translate-y-[50%] items-center -translate-x-[50%]"
        }`}
      >
        {tourist ? (
          <div>
            <img
              src={imgList}
              className="w-full object-contain bg-center bg-cover max-h-[700px]"
              alt=""
            />
            <div className="text-pure-white mx-auto">
              <div className="my-4 text-xl font-bold">{TouristTitle}</div>
              <div>{TouristDesc}</div>
            </div>
          </div>
        ) : selectHostingType ? (
          <div>{children}</div>
        ) : (
          <Slider {...settings}>
            {imgList?.map((item: any, idx: number) => {
              return (
                <img
                  key={idx}
                  src={item?.url}
                  className="w-full object-contain bg-center bg-cover max-h-[810px]"
                  alt=""
                />
              );
            })}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default FullSizeModal;
