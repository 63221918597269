import React, { Children, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import CheckBox from "./CheckBox";

interface HostInputProps {
  errorMessage?: string;
  isError?: boolean;

  defaultChecked?: any;
  onKeyUp?: any;

  className?: string;
  children?: any;
  messageChildren?: string;
  onClick?: (e: any) => void;
  onChange?: (e: any) => void;
  ref?: any;
  distanceOnChange?: (e: any) => void;
  inputOnChange?: (e: any) => void;

  input?: boolean;
  disabled?: boolean;
  inputValue?: any;
  inputMaxLength?: number;
  inputLengthPosition?: string;
  inputOnText?: boolean;
  inputOnButton?: boolean;
  inputOnTextPosition?: string;
  inputOnTextValue?: string;
  inputBtnClass?: string;
  inputButtonOnclick?: () => void;
  inputOnButtonText?: string;
  kmValue?: string;
  select?: boolean;
  selectTrans?: string;
  defaultValue?: string;
  textarea?: boolean;
  maxLang?: number;
  minLang?: number;
  upload?: boolean;
  image?: boolean;
  images?: boolean;

  timeselect?: boolean;
  formTitle?: string;
  nearby?: boolean;
  checkIcon?: string;
  CheckContent?: string;
  pricing?: boolean;
  editPricing?: boolean;
  priceChange?: (e: any) => void;
  calendarPricing?: boolean;

  selected?: string;
  options?: any[];

  rows?: number;

  size?: "XS" | "S" | "M" | "L" | "XL";
  type?: string;
  nomargin?: boolean;
  notitlemargin?: boolean;

  placeholder?: string;
}

const HostInput = ({
  errorMessage,
  defaultChecked,
  className,
  children,
  onChange,
  distanceOnChange,
  inputOnChange,
  onClick,
  messageChildren,

  input,
  inputMaxLength,
  inputLengthPosition,
  disabled,
  inputValue,
  inputOnText,
  inputOnButton,
  inputOnTextPosition,
  inputBtnClass,
  inputOnTextValue,
  inputButtonOnclick,
  inputOnButtonText,
  maxLang,
  minLang,
  select,
  selectTrans,
  kmValue,
  textarea,
  upload,
  image,
  images,
  timeselect,
  formTitle,
  nearby,
  checkIcon,
  CheckContent,
  pricing,
  editPricing,
  calendarPricing,
  priceChange,
  isError,

  selected,
  options,

  rows,
  nomargin,
  notitlemargin,

  size,
  type,
  placeholder,
}: HostInputProps) => {
  const { t } = useTranslation();
  const XS = size === "XS";
  const S = size === "S";
  const M = size === "M";
  const L = size === "L";
  const XL = size === "XL";

  const [textLength, setTextLength] = useState(0);
  const [checked, setChecked] = useState(false);

  const handleInputDisabled = (e: any) => {
    setChecked(e.target.checked);
  };

  const maxLen = (e: any, max: number | any) => {
    const currentTarget = e.target.value;
    setTextLength(currentTarget.length);
    if (currentTarget.length > max) {
      e.target.value = currentTarget.substr(0, max);
    }
  };

  return (
    <div>
      {formTitle ? (
        <div
          className={`text-sm ${notitlemargin ? "" : "web:mt-4 phone:mt-2"} `}
        >
          {t(`${formTitle}`)}
        </div>
      ) : (
        ""
      )}
      {input ? (
        <div>
          <div
            className={`${inputOnText || inputOnButton ? "relative flex" : ""}`}
          >
            <input
              onWheel={(e: any) => e.target.blur()}
              onInput={(e) => {
                maxLen(e, inputMaxLength);
              }}
              disabled={disabled}
              type={type}
              defaultValue={inputValue}
              maxLength={maxLang}
              minLength={minLang}
              onChange={onChange}
              onClick={onClick}
              className={`
            ${className} 
            ${formTitle ? "mt-2" : ""} 
            ${inputOnText ? "pr-6" : ""} 
            ${nomargin ? "" : "mt-4 web:mr-8 phone:mr-0"}
            ${isError ? "border border-red" : ""}
            rounded-lg phone:min-w-[136px] max-w-[991px] phone:w-full p-2 text-sm focus:outline-none h-[37px]
            ${
              XS
                ? "web:w-[136px] "
                : S
                ? "web:w-48 "
                : M
                ? "web:w-76"
                : L
                ? "web:w-[416px]"
                : XL
                ? "web:w-160"
                : ""
            }`}
              placeholder={t(`${placeholder ? placeholder : ""}`)}
            />
            {inputOnButton ? (
              <Button
                onClick={inputButtonOnclick}
                className={`${inputBtnClass} ${
                  isError ? "border border-red" : ""
                } absolute h-[37px] px-2 rounded-r-lg bg-light-green text-pure-white justify-center items-center flex`}
                btnText={t(`${inputOnButtonText}`)}
              />
            ) : (
              ""
            )}
            {inputOnText ? (
              <div
                className={`absolute text-sm text-border-black ${
                  inputOnTextPosition
                    ? `${inputOnTextPosition}`
                    : "right-10 top-[24px]"
                }`}
              >
                {inputOnTextValue}
              </div>
            ) : (
              ""
            )}
            {inputMaxLength ? (
              <div
                className={`${
                  textLength === inputMaxLength || textLength > inputMaxLength
                    ? "text-light-green"
                    : ""
                } absolute flex ${inputLengthPosition} text-sm mt-2`}
              >
                <p>
                  {textLength > inputMaxLength ? inputMaxLength : textLength}
                </p>
                /<p>{inputMaxLength}</p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${
              isError ? "text-pure-red" : "text-light-green"
            } font-bold text-xs`}
          >
            {children}
          </div>
        </div>
      ) : select ? (
        <select
          disabled={disabled}
          value={selected}
          defaultValue={inputValue}
          onChange={onChange}
          onClick={onClick}
          className={`${className} bg-center ${formTitle ? "mt-2" : ""} ${
            nomargin ? "" : "mt-4 web:mr-8 phone:mr-2"
          } rounded-lg p-2 h-[37px] text-sm phone:min-w-[136px] max-w-[991px] phone:w-full focus:outline-none ${
            XS
              ? "web:w-[136px]"
              : S
              ? "web:w-48 "
              : M
              ? "web:w-76"
              : L
              ? "web:w-[416px]"
              : XL
              ? "web:w-160"
              : ""
          }`}
        >
          <option value="">{t(`${placeholder ? placeholder : ""}`)}</option>
          {options?.map((value) => {
            return (
              <option key={value} value={value}>
                {value && !selectTrans
                  ? t(`${value}`)
                  : value && selectTrans
                  ? t(`${selectTrans}.${value}`)
                  : ""}
              </option>
            );
          })}
        </select>
      ) : textarea ? (
        <div>
          <textarea
            onInput={(e) => {
              maxLen(e, inputMaxLength);
            }}
            placeholder={t(`${placeholder ? placeholder : ""}`)}
            onChange={onChange}
            maxLength={maxLang}
            defaultValue={inputValue}
            rows={rows}
            className={`${className} ${formTitle ? "mt-2" : ""} ${
              nomargin ? "" : "web:mt-4 web:mr-8 phone:mt-2 phone:mr-0"
            } phone:border-border-light focus:outline-none rounded-lg p-2 phone:min-w-[136px] max-w-[991px] phone:w-full text-sm resize-none  ${
              XS
                ? "web:w-[136px] "
                : S
                ? "web:w-48"
                : M
                ? "web:w-76"
                : L
                ? "web:w-[416px]"
                : XL
                ? "web:w-160"
                : ""
            }`}
          />

          {inputMaxLength ? (
            <div
              className={`${
                textLength === inputMaxLength || textLength > inputMaxLength
                  ? "text-pure-red"
                  : ""
              } absolute flex ${inputLengthPosition} text-sm`}
            >
              <p>{textLength > inputMaxLength ? inputMaxLength : textLength}</p>
              /<p>{inputMaxLength}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : upload ? (
        <input
          onChange={onChange}
          name="file"
          accept=".jpg, .jpeg, .png, .pdf"
          type="file"
          className={`${className} ${formTitle ? "mt-2" : ""} ${
            nomargin ? "" : "web:mt-4 web:mr-8 phone:mt-2 phone:mr-0"
          } file:absolute text-sm file:py-1 phone:w-full file:px-4 file:top-0 file:right-[-5px] file:rounded-r-lg file:h-full file:border-0 file:text-sm file:bg-light-green file:cursor-pointer file:text-pure-white rounded-lg web:p-2 phone:border-border-light focus:outline-none phone:p-0 ${
            XS
              ? "w-[136px] "
              : S
              ? "w-48"
              : M
              ? "w-76"
              : L
              ? " w-[416px]"
              : XL
              ? " web:w-160 phone:h-[33px]"
              : ""
          }`}
          placeholder={t(`${placeholder ? placeholder : ""}`)}
        />
      ) : timeselect ? (
        <div
          className={`flex justify-between items-center  ${
            nomargin ? "" : "web:mr-8 phone:mr-0"
          }`}
        >
          <select
            onChange={onChange}
            defaultValue={inputValue}
            value={selected}
            className="web:w-[136px] phone:border-border-light focus:outline-none phone:w-[147px] h-[37px] mr-[14px] rounded-lg py-2 pl-2 text-sm bg-pure-white"
          >
            <option value="">{t(`${placeholder ? placeholder : ""}`)}</option>
            {options?.map((value) => {
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
          :
          <select
            disabled
            className={`phone:border-border-light focus:outline-none web:w-[136px] phone:w-[147px] h-[37px] ml-[14px] rounded-lg py-2 pl-2 text-sm bg-[#eee]`}
          >
            <option defaultValue={"00"}>00</option>
          </select>
        </div>
      ) : nearby ? (
        <div className={`web:flex mt-4 phone:block `}>
          <CheckBox
            className="mb-0 w-[198px]"
            onChange={onChange}
            defaultChecked={defaultChecked}
            icon
            content={CheckContent}
            checkImg={checkIcon}
          />
          <div
            className={`${className} ${inputOnText ? "relative flex" : "flex"}`}
          >
            <input
              onWheel={(e: any) => e.target.blur()}
              onChange={inputOnChange}
              disabled={disabled}
              type={type}
              onInput={(e) => {
                maxLen(e, 40);
              }}
              defaultValue={inputValue}
              maxLength={maxLang}
              minLength={minLang}
              onClick={onClick}
              className={`${
                formTitle ? "mt-2" : ""
              } phone:border-border-light focus:outline-none ${
                inputOnText ? "pr-6" : ""
              } ${
                nomargin ? "" : "web:mt-0 web:mr-8 phone:mt-2 phone:mr-0"
              }  rounded-lg p-2 text-sm phone:min-w-[136px] max-w-[991px] phone:w-full ${
                XS
                  ? "web:w-[136px] "
                  : S
                  ? "web:w-48"
                  : M
                  ? "web:w-76"
                  : L
                  ? "web:w-[416px]"
                  : XL
                  ? "web:w-160"
                  : ""
              }`}
              placeholder={t(
                "Add-private-ground.Name of facility close to your private ground"
              )}
            />
            <div className="web:flex phone:block relative top-2">
              <input
                onWheel={(e: any) => e.target.blur()}
                onInput={(e) => {
                  maxLen(e, inputMaxLength);
                }}
                onChange={distanceOnChange}
                defaultValue={kmValue}
                disabled={disabled}
                type="number"
                className="text-right inputNoBorder web:ml-10 phone:ml-2 web:w-8 phone:w-16 phone:pr-[28px] web:pr-1 web:h-[17px] web:text-sm web:border-red"
              />
              <span className="web:block absolute web:top-[-2px] web:right-[-25px] phone:right-[1px]">
                Km
              </span>
            </div>
          </div>
        </div>
      ) : pricing ? (
        <div>
          <div
            className={`flex items-center ${
              calendarPricing ? "web:w-full" : "web:w-[640px]"
            } phone:w-full text-sm mt-4 justify-between`}
          >
            {editPricing ? (
              <div>{CheckContent}</div>
            ) : (
              <CheckBox
                name={CheckContent}
                className="mb-0"
                onChange={onChange}
                content={CheckContent}
                checkImg={checkIcon}
              />
            )}
            <div className="relative">
              <input
                type={type}
                name={CheckContent}
                onWheel={(e: any) => e.target.blur()}
                disabled={disabled}
                value={inputValue}
                onChange={inputOnChange}
                className={`pl-6 ${
                  calendarPricing ? "web:w-[160px]" : "web:w-76"
                }  phone:min-w-[136px] h-[33px] text-sm px-2 rounded-lg`}
              ></input>
              <div className="absolute top-[6px] left-2">₩</div>
            </div>
          </div>
        </div>
      ) : image ? (
        <div className={className}>
          <input
            type="file"
            onChange={onChange}
            className="hidden"
            id="image"
          />
          <label htmlFor="image">{children}</label>
        </div>
      ) : images ? (
        <div className="mt-4">{children}</div>
      ) : (
        ""
      )}
      <div
        className={`${isError ? "text-pure-red" : "text-light-green"}
        font-bold flex mt-1 text-xs`}
      >
        {messageChildren}
      </div>
    </div>
  );
};

export default HostInput;
