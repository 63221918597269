import React, { useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import Icons from "./Icons";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { hostInfo } from "redux/action/host";
import { useAppDispatch } from "redux/store";

interface HostingCardProps {
  id?: number;
  bizStatus?: boolean | undefined;
  onClick?: () => void;
  closeModal?: any;
  className?: string;

  title?: string;
  cardIcon?: string;
  subTitle?: string;
  content?: string;
  timeRequired?: string;
  mobileContent?: string;
  hasItem?: string | null;
  info?: string;
  bizType: string;

  commonBtn?: string;
  hasBtn?: string;
}

const HostingTypeCard = ({
  id,
  bizStatus,
  onClick,
  closeModal,
  className,
  title,
  cardIcon,
  subTitle,
  content,
  mobileContent,
  bizType,
  info,
  timeRequired,
  hasItem,
  commonBtn,
  hasBtn,
}: HostingCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [mobileTab, setMobileTab] = useState<any>(0);

  const getHostInfo = useCallback((bizType: string) => {
    dispatch(hostInfo())
      .unwrap()
      .then((res) => {
        const { imageUrl, languageCode, totalMember, name } = res;
        navigate("/business-info", {
          state: {
            type: "update",
            bizType: [bizType],
            hasAc: bizType === "AC" ? true : false,
            hasAt: bizType === "AT" ? true : false,
            hasPg: bizType === "PG" ? true : false,
            languageCode: languageCode,
            phoneNum: totalMember?.totalMobile,
            name: name,
            countryCode: totalMember?.mobileCountryCode,
            mobile: totalMember?.mobile,
            imageUrl: imageUrl,
          },
          replace: true,
        });

        return res;
      });
  }, []);

  return (
    <div>
      <div className="web:inline-flex phone:hidden flex-row justify-between">
        <div className="w-80 inline-block bg-pure-white relative web:h-[448px] text-center web:p-8 phone:p-4 shadow-selectCard rounded-2xl hover:-translate-5 ">
          <div className="text-[22px] font-bold web:text-center web:mb-0">
            {t(`Partner-select.${title}`)}
          </div>
          <div className="items-center text-center">
            <Icons
              extension="png"
              iconName={cardIcon}
              className="w-24 h-24 mx-auto mb-6 mt-8"
            />
            <div className="m-4 font-bold">
              {t(`Partner-select.${subTitle}`)}
            </div>
            <div className="mt-4 text-sm">{t(`Partner-select.${content}`)}</div>
          </div>
        </div>
        <div className="typeHover text-left text-sm px-8 pb-8 pt-16 w-80 h-[448px] absolute opacity-0 hover:opacity-100 hover:bg-[rgba(0,0,0,0.8)] rounded-2xl text-pure-white">
          <div className="flex items-center">
            <Icons iconName="warning-yellow" className="w-4 h-4" />
            <p className="ml-2">{t("Partner-select.Preparation items")}</p>
          </div>
          <div className="flex my-4">
            <p>{t(`Host-dashboard.${info}`)}</p>
          </div>
          <div className="text-base">
            {bizStatus ? (
              <Button
                btnSize="small"
                onClick={() => {
                  localStorage.setItem("STATUS", "HOST");
                  if (bizType === "AC") {
                    localStorage.setItem("currentType", "AC");
                    navigate("/host/myaccommodation");
                    closeModal();
                  } else if (bizType === "PG") {
                    localStorage.setItem("currentType", "PG");
                    navigate("/host/myprivate");
                    closeModal();
                  }
                }}
                className="block absolute bg-light-green  bottom-8 w-[256px] rounded-2xl mx-[auto] text-center"
                // 등록된 ""가 있습니다
                btnText={t(`Host-dashboard.${hasBtn}`)}
              />
            ) : bizStatus === false ? (
              <Button
                btnSize="small"
                onClick={() => {
                  getHostInfo(bizType);
                  closeModal();
                }}
                className="block absolute bg-light-green  bottom-8 w-[256px] rounded-2xl mx-[auto] text-center"
                // 호스팅 시작하기
                btnText={t(`Partner-select.${commonBtn}`)}
              />
            ) : bizStatus === undefined ? (
              <Button
                btnSize="small"
                onClick={() => {
                  window.alert("오픈 준비중입니다.");
                }}
                className="block absolute transition-transform text-pure-white opacity-70 font-bold bg-grey bottom-8 w-[256px] rounded-2xl mx-[auto] text-center"
                // 오픈 준비중
                btnText={t("Partner-select.Coming Soon")}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="web:hidden phone:inline-block text-left mb-4">
        <div
          onClick={() => {
            mobileTab !== id ? setMobileTab(id) : setMobileTab(0);
          }}
          className="w-80 inline-block bg-pure-white relative web:h-[448px] web:p-8 phone:p-4 shadow-selectCard rounded-2xl hover:-translate-5 "
        >
          <div className="text-xl font-bold web:text-center mb-4">
            {t(`Partner-select.${title}`)}
          </div>
          <div className="flex items-center">
            <Icons extension="png" iconName={cardIcon} className="w-16 h-16" />
            <div className="ml-4 font-bold">
              {t(`Partner-select.${subTitle}`)}
            </div>
          </div>
          {mobileTab === id ? (
            <div>
              <div className="text-sm my-4">
                {t(`Partner-select.${content}`)}
              </div>
              <div className="flex items-center">
                <Icons iconName="warning-yellow" className="w-4 h-4" />
                <p className="ml-2 text-sm">
                  {t("Partner-select.Preparation items")}
                </p>
              </div>
              <div className="text-sm my-2">
                <p>{t(`Host-dashboard.${info}`)}</p>
              </div>
              <div className="text-sm mb-8">
                <p>{t(`Partner-select.${timeRequired}`)}</p>
              </div>
              {bizStatus ? (
                <Button
                  onClick={() => {
                    localStorage.setItem("STATUS", "HOST");
                    if (bizType === "AC") {
                      localStorage.setItem("currentType", "AC");
                      navigate("/host/myaccommodation");
                      closeModal();
                    } else if (bizType === "PG") {
                      localStorage.setItem("currentType", "PG");
                      navigate("/host/myprivate");
                      closeModal();
                    }
                  }}
                  className="bg-light-green rounded-2xl text-pure-white text-center py-3"
                  // 등록된 ""가 있습니다
                  btnText={t(`Host-dashboard.${hasBtn}`)}
                />
              ) : bizStatus === false ? (
                <Button
                  onClick={() => {
                    getHostInfo(bizType);
                    closeModal();
                  }}
                  className="bg-light-green rounded-2xl text-pure-white text-center py-3"
                  // 호스팅 시작하기
                  btnText={t(`Partner-select.${commonBtn}`)}
                />
              ) : bizStatus === undefined ? (
                <Button
                  onClick={() => {
                    window.alert("오픈 준비중입니다.");
                  }}
                  className="text-pure-white opacity-70 font-bold bg-grey rounded-2xl text-center py-3"
                  // 오픈 준비중
                  btnText={t("Partner-select.Coming Soon")}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default HostingTypeCard;
