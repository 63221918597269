import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "redux/store";

import AcWrap from "elements/AcWrap";
import Button from "elements/Button";
import CheckBoxDefault from "elements/CheckBoxDefault";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { spAmenities } from "redux/slice/spaceSlice";
import { useTranslation } from "react-i18next";

interface AmenityDataProps {
  onPre?: any;
  onNext?: any;
  amenityData?: any;
}

const SpaceAmenities = ({ onNext, onPre, amenityData }: AmenityDataProps) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [amenities, setAmenity] = useState<any>([]);
  const [amenityList, setAmenityList] = useState<any>([]);

  const [specialAmenity, setSpecialAmenity] = useState<any>("");

  const preData = useAppSelector((state) => state.space.spaceAmenities);

  const page = preData;

  useEffect(() => {
    setAmenityList([...amenityList, ...page?.amenityIds]);
    setSpecialAmenity(page?.specialAmenity);
  }, [page?.amenityIds, page?.specialAmenity]);

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `api/v2/amenity/fetch`,
    })
      .then((res) => {
        const data = res.data.data;
        setAmenity(data);
        return data;
      })
      .catch((err) => {});
  }, []);

  const data = {
    amenityIds: amenityList,
    specialAmenity: specialAmenity,
  };

  const Amenities = (checked: string, item: number) => {
    if (checked) {
      const result = Number(item);
      setAmenityList([...amenityList, result]);
    } else if (!checked) {
      const result = Number(item);
      setAmenityList(amenityList?.filter((el: any) => el !== result));
    }
  };

  const nextPage = () => {
    amenityData(data);
    dispatch(spAmenities(data));
    onNext();
  };

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          title="Add-space.Amenities"
          help
          toolTitle="제공하는 편의 용픔을 선택해주요."
          toolSubTitle="게스트들의 편의를 위해 갖춰진 편의 용품을 하나도 빠짐없이 선택해주세요. 현재 리스트에 없는 용품들은 다음 섹션을 통해 직접 작성할 수 있습니다."
        >
          <div
            className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
          >
            {amenities?.map((item: any, idx: number) => {
              const iconUrl = item.iconUrl;
              const enName = item.enName;
              const id = item.id;
              return (
                <CheckBoxDefault
                  key={idx}
                  id={id}
                  imageUrl={iconUrl}
                  defaultChecked={
                    amenityList?.includes(id) === true ? true : false
                  }
                  title={`Amenities-data.${enName}`}
                  value={enName}
                  onChange={(e: any) => {
                    Amenities(e.target.checked, e.target.id);
                  }}
                />
              );
            })}
          </div>
        </AcWrap>
        <AcWrap
          inputValue={specialAmenity}
          onChange={(e) => {
            setSpecialAmenity(e.target.value);
          }}
          title="Add-space.Special Amenities"
          textArea
          row={5}
          help
          toolTitle="추가 제공 용품을 직접 입력해주세요."
          toolSubTitle="이전 편의 용품 리스트에 없는 특별 제공 용품이 있다면 직접 입력해주세요."
          placeholder="Add-space.Please describe the special amenities for traveler"
        />
        <div className="flex w-full justify-between items-center">
          <Button
            onClick={() => onPre()}
            btnText={t("Add-space.Previous")}
            className="inline-block bg-light-green rounded-lg py-3 px-4 text-pure-white"
          />
          <Button
            onClick={() => nextPage()}
            btnText={t("Add-space.Continue")}
            className="inline-block bg-light-green rounded-lg py-3 px-4 text-pure-white"
          />
        </div>
      </div>
    </div>
  );
};

export default SpaceAmenities;
