import HostWrap from "components/Host/hostWrap";
import AcWrap from "elements/AcWrap";
import Button from "elements/Button";
import HostInput from "elements/HostInput";
import { t } from "i18next";
import { hour, Confirmation, Advanced } from "pages/Register/constants";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { changecase } from "utils/changeCase";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";

const EditAcPoliy = ({ acPolicyData }: any) => {
  const { t } = useTranslation();
  const [rule, setRule] = useState([]);
  const [policyValue, setPolicyValue] = useState<any>([]);
  const [policyName, setPolicyName] = useState<string>("");

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [confirm, setConfirm] = useState("");
  const [advance, setAdvance] = useState("");
  const [extraPoli, setExtraPoli] = useState("");
  const [policyId, setPolicyId] = useState(0);

  useEffect(() => {
    requestToMustOutdoor
      .get("api/v2/policy/PG")
      .then((res) => {
        const data = res.data.data;
        setRule(data);
        const policyOptions = data?.map((item: any) => {
          return changecase(item?.name);
        });
        setPolicyValue(policyOptions);
        return res;
      })
      .catch((err) => {});
  }, []);

  const {
    advanced,
    checkIn,
    checkOut,
    confirmMethod,
    extraPolicy,
    policyInfo,
  } = acPolicyData;
  const start = checkIn.split(":")[0];
  const end = checkOut.split(":")[0];

  useEffect(() => {
    setStartTime(start);
    setEndTime(end);
    if (advanced) {
      setAdvance("Booking is available today");
    } else {
      setAdvance("Booking is not available today");
    }
    if (confirmMethod === "WITHIN_24_HOURS") {
      setConfirm("Confirmation Within 24 Hours");
    } else {
      setConfirm("Instant Confirmation");
    }
    setExtraPoli(extraPolicy);
    setPolicyName(policyInfo?.name);
  }, [advanced, confirmMethod, end, extraPolicy, policyInfo?.name, start]);

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <div className="text-left">
          <HostWrap
            mainClass="mt-0"
            help
            toolTitle="1 박 기준 공유 시간을 설정해주세요."
            toolSubTitle="호스트님의 공유 장소가 항상 비어있는 땅이 아니라면 활용이 가능한 시간대를 고려하여 체크인과 체크아웃 시간을 정해주세요."
            host
            title="Add-accommodation.Check In / Out*"
          >
            <HostInput
              timeselect
              selected={startTime}
              placeholder="Hr"
              onChange={(e) => {
                setStartTime(e.target.value);
              }}
              options={hour}
              formTitle={t("Add-accommodation.Check in")}
            />
            <HostInput
              timeselect
              selected={endTime}
              placeholder="Hr"
              onChange={(e) => {
                setEndTime(e.target.value);
              }}
              options={hour}
              nomargin
              formTitle={t("Add-accommodation.Check out")}
            />
          </HostWrap>
          <AcWrap
            help
            selectTrans="Confirmation-data"
            titleClass="mt-12"
            toolTitle="예약 진행 방식을 선택해주세요."
            toolSubTitle={
              "- 즉시 예약 :  게스트의 예약 요청 시 별도의 호스트 승인없이 자동적으로 예약이 확정되어 관리가 편한 방식입니다. 또한 즉시 예약이 가능한 컨텐츠의 경우 필터링 검색에도 적용되어 더 많은 노출을 기대할 수 있습니다.\n\n- 확인 예약 : 게스트의 예약 요청에 대해 호스트가 설정한 시간 내 직접 승인 또는 거절을   진행할  수  있는   방식입니다.  호스트의   관리가   필요하며   응답률과 응답시간이 중요한 타입입니다."
            }
            placeholder="Add-accommodation.Select"
            onChange={(e) => {
              setConfirm(e.target.value);
              //   if (e.target.value === "Confirmation Within 24 Hours") {
              //     setPolicy({ ...policy, confirmMethod: "WITHIN_24_HOURS" });
              //   } else if (e.target.value === "Instant booking") {
              //     setPolicy({ ...policy, confirmMethod: "INSTANT_BOOK" });
              //   }
            }}
            select
            inputValue={confirm}
            options={Confirmation}
            title="Add-accommodation.Confirmation*"
          />
          <AcWrap
            help
            options={Advanced}
            inputValue={advance}
            selectTrans="Advance-notice-data"
            placeholder="Add-accommodation.Select"
            toolTitle="방문일 기준 사전 예약 요청이 가능한 D-day 를 설정해주세요."
            toolSubTitle={
              "현재는 ‘당일 예약 가능’ 또는 ‘당일 예약 불가능’으로 설정할 수 있습니다. (추후 기능 업그레이드 예정\n\n당일 예약 : 게스트가 방문 당일에도 예약이 가능합니다.\n\n사전 요청일 설정 : 게스트가 예약  일정을 선택하는 캘린더에 적용됩니다. Ex) 3 일 설정 – 게스트의 예정 방문일이 6 월 17 일인 경우 6 월 14 일까지는 예약 요청이 가능함"
            }
            onChange={(e) => {
              setAdvance(e.target.value);
            }}
            select
            title="Add-accommodation.Advance Notice*"
          />
          <AcWrap
            textArea
            onChange={(e) => {
              setExtraPoli(TextareaReplaceNewLine(e.target.value));
            }}
            title="Add-accommodation.Additional Rule / Policy"
            help
            inputValue={extraPoli}
            inputMaxLength={500}
            row={4}
            toolTitle="게스트가 이용 시 지켜야할 규칙을 빠짐없이 알려주세요."
            toolSubTitle="무엇보다 호스트와 게스트 안전이 우선입니다. 흡연구역이나 화기 사용 등 안전과 관련된 정책을 꼭 명시해주세요. 또한 게스트가 쓰레기를 직접 수거해 가는 것이 기본  원칙이지만  한번  더 명시해준다면  지속적인  관리에  도움이  됩니다. 그 외 매너타임이나 시설 이용과 관련하여 지켜야 할 사항이 있다면 알려주세요."
            placeholder={
              "Please describe additional rule / policy on your Private Ground \n• e.g. No Smoking in the area of private ground. Smoking is available in designated smoking area"
            }
          />
          <AcWrap
            noMarginB
            select
            inputValue={changecase(policyName)}
            options={policyValue}
            disabled
            // onChange={(e) => {
            //   setPolicyName(e.target.value);
            //   setPolicy({
            //     ...policy,
            //     policyId:
            //       e.target.value === "Flexible"
            //         ? 36
            //         : e.target.value === "Moderate"
            //         ? 35
            //         : e.target.value === "Super Strict"
            //         ? 34
            //         : e.target.value === "Strict"
            //         ? 37
            //         : 0,
            //   });
            // }}
            placeholder="Add-accommodation.Select Cancellation Policy"
            title="Add-accommodation.Cancellation & Refund Policy*"
            help
            selectTrans="Cancellation-policy-data"
            toolTitle="취소 & 환불 정책 1 개를 선택해주세요."
            toolSubTitle="유연한 취소&환불 정책부터 강력한 정책까지 4 단계로 구분되어 있습니다.  각 단계는 게스트의 취소 요청일을 기준으로 환불 범위가 다르게 적용되어 있으니 호스트님의 운영 방식에 가장 적합한 취소/환불 정책을 확인하여 선택해주세요."
            miniTitle="Booking-request.MUST OUTDOOR will enforce following cancellation policy"
          />
          <div className="font-bold text-sm mt-4 mb-2">
            {t(
              policyName
                ? `Cancellation-policy-data.${changecase(policyName)}`
                : ""
            )}{" "}
            {t("Add-accommodation.Cancellation Policy")}
          </div>
          <div>
            {rule.map((item: any, idx) => {
              if (policyName === item.name) {
                return (
                  <div key={idx}>
                    {item?.firstRange !== null ? (
                      <div>
                        {t(
                          "Cancellation-policy-data.{{days}}100% refund of the payment",
                          { days: item?.firstRange }
                        )}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.secondRange !== null ? (
                      <div>
                        {t(
                          "Cancellation-policy-data.{{days}}70% refund of the payment",
                          { days: item?.secondRange }
                        )}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.thirdRange !== null ? (
                      <div>
                        {t(
                          "Cancellation-policy-data.{{days}}50% refund of the payment",
                          { days: item?.thirdRange }
                        )}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.fourthRange !== null ? (
                      <div>
                        {t(
                          "Cancellation-policy-data.{{days}}30% refund of the payment",
                          { days: item?.fourthRange }
                        )}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.fifthRange !== null ? (
                      <div>
                        {t(
                          "Cancellation-policy-data.{{days}}0% refund of the payment",
                          { days: item?.fifthRange }
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="mt-12 mb-8 justify-end flex">
          <Button
            className={`bg-tan text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-accommodation.Save")}
          />
        </div>
      </div>
    </div>
  );
};

export default EditAcPoliy;
