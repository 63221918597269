import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
    HostInfo : {
        bizType: [],
        countryCode: string,
        currency: string,
        imageUrl: string,
        languageCode: any,
        mobile: string,
        name: string,
        phoneNum: string
    }
    BizInfo: {
        pgInfo: {
            bizNumber: string
            bizType: boolean,
            bizUri: string,
            countryCode: string,
            groundUri: string,
            telephone: string
        }
    }
}

const initialState: InitialState = {
    HostInfo: {
        bizType: [],
        countryCode: "",
        currency: "",
        imageUrl: "",
        languageCode: [],
        mobile: "",
        name: "",
        phoneNum: ""
    },
    BizInfo: {
        pgInfo: {
            bizNumber: "",
            bizType: false,
            bizUri:"",
            countryCode: "",
            groundUri: "",
            telephone: "",
        }
    }
}

const hostingSlice = createSlice({
    name: "hostingData", 
    initialState,
    reducers: {
        hostInfo: (state, action:PayloadAction<any>) => {
            state.HostInfo = action.payload;
        },
        bizInfo: (state, action:PayloadAction<any>) => {
            state.BizInfo = action.payload;
        }
    }

})

export default hostingSlice;

export const {
    hostInfo, bizInfo
} = hostingSlice.actions