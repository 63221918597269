import React, { useCallback, useEffect, useRef, useState } from "react";

import CalendarInput from "elements/calendarInput";

import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import { ko, enUS } from "date-fns/esm/locale";
import { useLocation, useNavigate } from "react-router-dom";
import { addMonths } from "date-fns";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { changeDate, returnDate } from "utils/changeDate";

import { useTranslation } from "react-i18next";
import { inputPriceComma, inputPriceFormat } from "utils/commaDash";
import SideMenu from "elements/sideMenu";
import Button from "elements/Button";
import { getEveryDiffDay } from "utils/getDiffDay";
import MoblieHeader from "components/moblieHeader";
import HostInput from "elements/HostInput";
import Icons from "elements/Icons";

const CalendarManager = () => {
  const { t } = useTranslation();
  const { state }: any = useLocation();

  const status = state?.type;

  const spaces = state?.spaceListData;

  const spaceListName = spaces?.spaces.map((item: any) => {
    return item.title;
  });

  const preSpaceName: any = localStorage.getItem("spaceName");

  const navigate = useNavigate();
  const [spaceId, setSpaceId] = useState(0);
  const [selectedSpace, setSelectedSpace] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [isSelected, setIsSelected] = useState("");

  const [betweenDay, setBetweenDay] = useState<any>([]);
  const [max, setMax] = useState<any>("");
  const [min, setMin] = useState<any>("");
  const [discountedMax, setDiscountedMax] = useState<any>("");
  const [discountedMin, setDiscountedMin] = useState<any>("");

  const [radioChecked, setRadioChecked] = useState("price");

  const [calendarTab, setCalendarTab] = useState<boolean>();

  const [price, setPrice] = useState("");
  const [discountPer, setDiscountPer] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [discountRate, setDiscountRate] = useState("");

  const [mainPrice, setMainPrice] = useState(false);

  const [bookingWindow, setBookingWindow] = useState(12);

  const [calendarData, setCalendarData] = useState<any[]>([]);

  const [unavailabled, setUnavailabled] = useState<any[] | any>([]);

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [mobileMenu, setMobileMenu] = useState(false);

  const [weekPrice, setWeekPrice] = useState([
    { title: "Sunday", checked: false, price: "" },
    { title: "Monday", checked: false, price: "" },
    { title: "Tuesday", checked: false, price: "" },
    { title: "Wednesday", checked: false, price: "" },
    { title: "Thursday", checked: false, price: "" },
    { title: "Friday", checked: false, price: "" },
    { title: "Saturday", checked: false, price: "" },
  ]);

  const onChange = (dates: [any, any]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const currentType = localStorage.getItem("currentType");

  const bookingWindowValue = [
    { name: "2 Months", value: 2 },
    { name: "3 Months", value: 3 },
    { name: "4 Months", value: 4 },
    { name: "5 Months", value: 5 },
    { name: "6 Months", value: 6 },
  ];

  const week = weekPrice?.map((item) => {
    const values = Object.values(item);
    const price = values[2];

    return price;
  });

  const priceData = {
    sun: week[0],
    mon: week[1],
    tue: week[2],
    wed: week[3],
    thu: week[4],
    fri: week[5],
    sat: week[6],
  };

  const host_id = localStorage.getItem("hostNum");

  const itemNum = localStorage.getItem("itemNum");

  // 2022-01-01
  const startChangeDate = startDate !== null ? changeDate(startDate) : "";
  const endChangeDate = endDate !== null ? changeDate(endDate) : "";

  // 2022-1-1
  const startReturnDate = startDate !== null ? returnDate(startDate) : "";
  const endReturnDate = endDate !== null ? returnDate(endDate) : "";

  useEffect(() => {
    spaces?.spaces.map((item: any) => {
      const title = item.title;
      const id = item.id;
      if (selectedSpace === title) {
        setSpaceId(id);
      } else if (preSpaceName === title) {
        setSpaceId(id);
      }
    });
  }, [preSpaceName, selectedSpace]);

  useEffect(() => {
    calendarData?.map((item: any) => {
      const itemValue = item?.date;
      const start = changeDate(startDate);
      const end = changeDate(endDate);
      const discountRate = item.discountRate;
      // 선택한 시작날과 마지막날에 discountRate가 같으면 안비워줘야하는데
      const dateValue =
        itemValue[0] +
        "-" +
        ("00" + itemValue[1])?.slice(-2) +
        "-" +
        ("00" + itemValue[2])?.slice(-2);
      if (dateValue === changeDate(startDate) && startDate && !endDate) {
        setDiscountRate(`${discountRate}`);
      } else if (startDate && endDate && start !== end) {
        setDiscountRate("");
      }
    });
  }, [endDate, startDate]);

  useEffect(() => {
    const start = startChangeDate;
    const end = endChangeDate;

    const result = getEveryDiffDay(start, end);

    if (result !== undefined) {
      setBetweenDay(result);
    } else {
      setBetweenDay([start]);
    }
  }, [endChangeDate, startChangeDate]);

  const datePrice = calendarData?.map((item) => {
    const itemValue = item?.date;
    const status = item?.status;
    const dateValue =
      itemValue[0] +
      "-" +
      ("00" + itemValue[1])?.slice(-2) +
      "-" +
      ("00" + itemValue[2])?.slice(-2);
    if (
      betweenDay !== undefined &&
      betweenDay?.includes(dateValue) &&
      status !== "UNAVAILABLE"
    ) {
      const result = item;
      return result;
    }
  }, []);

  const dateResult = datePrice?.filter((x: any) => x !== undefined);

  useEffect(() => {
    const a = dateResult?.map((item: any) => {
      return Number(item.price !== "" ? item.price : "0");
    });
    if (a?.length > 1) {
      const max = Math?.max.apply(null, a);
      const min = Math?.min.apply(null, a);
      setMax(max);
      setMin(min);
    } else if (a?.length === 1) {
      const min = a[0];
      setMin(min);
      setMax("");
    }
  }, [dateResult]);

  const discountValue = () => {
    const arr = [];

    for (let i = 0; i <= 90; i++) {
      if (i % 5 === 0 && i <= 50) {
        arr?.push(i);
      } else if (i % 10 === 0 && i >= 51) {
        arr?.push(i);
      }
    }
    return arr;
  };

  const PGCalendarValue = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/host/calendar/${host_id}`,
    })
      .then((res) => {
        const value = res?.data.data;
        setCalendarData(value);

        const unavailbleData = value?.map((item: any) => {
          const status = item?.status;
          const date = item?.date;
          if (status === "UNAVAILABLE") {
            const unADate = date[0] + "-" + date[1] + "-" + date[2];
            return unADate;
          }
        });
        const unavailabled = unavailbleData?.filter((i: string) => {
          return i !== undefined;
        });
        setUnavailabled([...unavailabled]);

        return res.data.data;
      })
      .catch((err) => {});
  }, [host_id]);

  const ACCalendarValue = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/space/calendar/${spaceId}`,
    })
      .then((res) => {
        const value = res?.data.data;
        setCalendarData(value);

        const unavailbleData = value?.map((item: any) => {
          const status = item?.status;
          const date = item?.date;
          if (status === "UNAVAILABLE") {
            const unADate = date[0] + "-" + date[1] + "-" + date[2];
            return unADate;
          }
        });
        const unavailabled = unavailbleData?.filter((i: string) => {
          return i !== undefined;
        });
        setUnavailabled([...unavailabled]);

        return res.data.data;
      })
      .catch((err) => {});
  }, [spaceId]);

  useEffect(() => {
    if (currentType === "PG") {
      PGCalendarValue();
    }
  }, [status, host_id]);
  useEffect(() => {
    if (currentType === "AC") {
      ACCalendarValue();
    }
  }, [spaceId, selectedSpace]);

  const lan = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (startDate) {
      setStart(startDate?.toDateString());
    }
    if (endDate && endDate !== null) {
      setEnd(endDate?.toDateString());
    }
  }, [endDate, startDate]);

  const closeBtn = () => {
    setStartDate(null);
    setEndDate(null);
    setMax("");
    setMin("");
  };

  useEffect(() => {
    if (startDate === endDate) {
      setEndDate(null);
    }
  }, [endDate, startDate]);

  const chooseDay = (e: React.ChangeEvent<Element>) => {
    var regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[A-Z|a-z]/g;
    const value = e.target.ariaLabel?.replace(regex, "");
    let last = value?.slice(0, -1);
    let first = last?.slice(1);
    const result = first?.replace(/ /g, "-");
    return result;
  };

  const save = () => {
    if (currentType === "PG") {
      requestToMustOutdoor({
        method: "POST",
        url: `/api/v2/host/calendar/set-up`,
        data: {
          discountRate: radioChecked === "discount" ? Number(discountPer) : 0,
          discounted: discountPer ? true : false,
          endDate: endChangeDate ? endChangeDate : startChangeDate,
          holiday: false,
          hostId: host_id,
          note: "Private Ground Calendar",
          pgId: itemNum,
          price: radioChecked === "price" ? price : "",
          startDate: startChangeDate,
          status: isSelected === "AVAILABLED" ? "AVAILABLE" : "UNAVAILABLE",
        },
      }).then((res) => {
        window.location.reload();
        return res.data.data;
      });
    } else if (currentType === "AC") {
      requestToMustOutdoor({
        method: "PATCH",
        url: `/api/v2/space/calendar/set-up`,
        data: {
          discountRate: radioChecked === "discount" ? Number(discountPer) : 0,
          discounted: discountPer ? true : false,
          discountedPrice: "",
          endDate: endChangeDate ? endChangeDate : startChangeDate,
          holiday: false,
          hostId: host_id,
          note: "Accommodation Calendar",
          price: radioChecked === "price" ? price : "",
          spaceId: spaceId,
          startDate: startChangeDate,
          status: isSelected === "AVAILABLED" ? "AVAILABLE" : "UNAVAILABLE",
        },
      }).then((res) => {
        localStorage.setItem("spaceName", selectedSpace);
        window.location.reload();
        return res.data.data;
      });
    }
  };

  useEffect(() => {
    setDiscountRate("");
    setDiscountPer("");
  }, [startDate]);

  // useEffect(() => {
  //   // 새로고침 막기(조건 부여 가능)
  //   window.onbeforeunload = function () {
  //     return true;
  //   };
  //   return () => {
  //     window.onbeforeunload = null;
  //     localStorage.removeItem("spaceName");
  //   };
  // }, []);

  useEffect(() => {
    if (startChangeDate) {
      let start = startReturnDate;
      let include = unavailabled?.includes(start);
      include === true
        ? setIsSelected("UNAVAILABLED")
        : setIsSelected("AVAILABLED");
    }
  }, [startChangeDate, startReturnDate, unavailabled]);

  useEffect(() => {
    const priceNum = Number(price);
    const discountNum = Number(discountPer);
    if (price !== "" && discountPer !== "") {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v2/host/calendar/discount/${priceNum}/${discountNum}`,
      }).then((res) => {
        return res.data.data;
      });
    } else if (min && max) {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v2/host/calendar/discount/${min}/${discountNum}`,
      }).then((res) => {
        setDiscountedMin(res.data.data);
        return res.data.data;
      });
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v2/host/calendar/discount/${max}/${discountNum}`,
      }).then((res) => {
        setDiscountedMax(res.data.data);
        return res.data.data;
      });
      setDiscountPrice(`${discountedMin + " ~ " + discountedMax}`);
    } else if (min && !max) {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v2/host/calendar/discount/${min}/${discountNum}`,
      }).then((res) => {
        setDiscountPrice(res.data.data);
        return res.data.data;
      });
    }
  }, [discountPer, discountedMax, discountedMin, max, min, price]);

  const renderDayContents = useCallback(
    (day: any, date: any) => {
      const data = calendarData;
      const dateValue = changeDate(date);
      const calendarDate = new Date(date);
      const year = calendarDate?.getFullYear();
      const month = calendarDate?.getMonth() + 1;
      const days = calendarDate?.getDate();

      const fullDate = `${year}-${month}-${days}`;

      const value = unavailabled?.map((item: any) => {
        if (item !== undefined) return item;
      });

      const a = data?.map((item) => {
        if (item.status === "AVAILABLE") {
          const date =
            item.date[0] +
            "-" +
            ("00" + item.date[1]).slice(-2) +
            "-" +
            ("00" + item.date[2]).slice(-2);
          return date;
        }
      });
      const b = a?.filter((x: any) => x !== undefined);
      const c = data?.map((item) => {
        if (item.status === "AVAILABLE") {
          const date = {
            data:
              item.date[0] +
              "-" +
              ("00" + item.date[1]).slice(-2) +
              "-" +
              ("00" + item.date[2]).slice(-2),
            availableAmount: item?.availableAmount,
            totalAmount: item?.totalAmount,
            price: item?.price,
            discountPrice: item?.discountPrice,
            discountRate: item?.discountRate,
            discounted: item?.discounted,
          };
          return date;
        }
      });
      const d = c?.filter((x: any) => x !== undefined);
      if (b?.includes(dateValue)) {
        return (
          <div className="px-2 flex h-full flex-col text-left justify-around">
            <span>{day}</span>
            {d?.map((item: any, idx) => {
              const discountPrice = item?.discountPrice;
              const discounted = item?.discounted;
              if (item?.data === dateValue) {
                return (
                  <div key={idx}>
                    <div className="font-bold text-center web:block phone:hidden ">
                      {item?.availableAmount === 0
                        ? "Booked"
                        : discounted
                        ? inputPriceComma(discountPrice)
                        : inputPriceComma(item.price)}
                    </div>
                    <div className="flex justify-end">
                      <div className="text-right text-xs">
                        {item?.availableAmount} / {item?.totalAmount}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      } else if (value.includes(fullDate)) {
        return (
          <div className="react-datepicker_custom_color h-full w-full">
            <span>{day}</span>
          </div>
        );
      } else {
        return (
          <div className="web:px-2 phone:px-0 flex h-full flex-col web:text-left phone:text-center justify-around">
            <span>{day}</span>
          </div>
        );
      }
    },
    [calendarData, start, unavailabled]
  );

  useEffect(() => {
    const channelTalk: any = document.getElementById("ch-plugin");
    setTimeout(() => {
      const value = channelTalk;
      const result = value?.style ? value?.style : "";
      if (result) {
        value.style.cssText = "display:none !important";
      }
    }, 1000);
  }, [calendarData]);

  const changePrice = (e: any) => {
    if (radioChecked !== "price") {
      setPrice("");
    } else {
      setPrice(e.target.value);
    }
  };

  const mobileTabInfo = [
    { title: "Booking Window", subTitle: "Edit" },
    { title: "Edit Operation & Regular Price", subTitle: "Edit" },
  ];

  const outSide = useRef<any>();
  useEffect(() => {
    const clickOutside = (e: any) => {
      if (
        mobileMenu &&
        outSide.current &&
        !outSide.current.contains(e.target)
      ) {
        setMobileMenu(false);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  });

  // price: 요금을 다시 세팅 할 때 input 안에 넣는 price
  // start: 선택 한 시작 날 ex) Thu Mar 09 2023
  // startDate : 선택 한 시작 날 ex) Thu Mar 09 2023 00:00:00 GMT+0900 (한국 표준시)
  // end : 선택 한 마지막 날 ex) Fri Mar 10 2023
  // endDate : 선택 한 마지막 날 ex) Fri Mar 10 2023 00:00:00 GMT+0900 (한국 표준시)

  // 시작 날과 마지막 날을 2023-03-10 형식으로 바꾸고싶으면 changeDate() 함수를 사용하면 됨

  // min: 시작 날과 마지막 날 요금 중 제일 가격이 낮은 값
  // max: 시작 날과 마지막 날 요금 중 제일 가격이 높은 값

  const changeBasePrice = () => {
    requestToMustOutdoor({
      method: "PATCH",
      url: `/api/v2/host/calendar/${itemNum}`,
      data: priceData,
    })
      .then((res) => {
        const data = res.data.data;
        window.location.reload();
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="web:max-w-1280 mx-auto">
      <MoblieHeader
        left
        centerTitleImage="calendar-manager"
        center="Calendar-manager.Calendar Manager"
        onClick={() => setMobileMenu((mobileMenu) => !mobileMenu)}
        rightImage="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/more-dots.svg"
      />
      {currentType === "AC" ? (
        <div className="web:hidden phone:px-4 py-3 flex justify-between border-b border-input-border-color">
          <div>Select Space</div>
          <select
            value={selectedSpace ? selectedSpace : preSpaceName}
            onChange={(e) => {
              setSelectedSpace(e.target.value);
            }}
          >
            {spaceId !== 0 ? "" : <option value="">Select Space</option>}
            {spaceListName?.map((value: string) => {
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
        </div>
      ) : (
        ""
      )}
      {mobileMenu ? (
        <div
          ref={outSide}
          onClick={(e) => {
            if (outSide.current === e.target) {
              setMobileMenu(false);
            }
          }}
          className="absolute z-100 right-0 web:hidden phone:block"
        >
          {mobileTabInfo.map((item, idx) => {
            return (
              <div
                key={idx}
                className="w-[224px] bg-pure-white shadow-btn flex justify-between p-2"
              >
                <div className="text-sm">
                  {t(`Calendar-manager.${item.title}`)}
                </div>
                <div className="text-xs hover:underline text-link cursor-pointer">
                  {item.subTitle}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
      <div className="w-full min-h-[80px] p-4 z-[9999] web:hidden phone:block fixed left-0 bottom-0 bg-pure-white rounded-lg shadow-selectCard">
        <div className="flex justify-between text-xs">
          <div className="flex ">
            <div>
              <div className="font-bold">
                {t("Calendar-manager.Selected Date")}
              </div>
              <div>
                {`${
                  start && startDate !== null
                    ? changeDate(start)
                    : "일정을 선택해주세요."
                } ${
                  end && endDate !== null && end !== changeDate(start)
                    ? "~ " + changeDate(end)
                    : ""
                }`}
              </div>
            </div>
          </div>
          <Button
            onClick={() => {
              const start = startChangeDate;
              const end = endChangeDate;
              if (
                (start !== "" && end === "") ||
                (start !== "" && end !== "")
              ) {
                setCalendarTab((calendarTab) => !calendarTab);
              } else {
                alert("시작일과 마지막일을 둘 다 선택해주세요.");
              }
            }}
            btnText={"Details"}
            className="p-2 rounded-lg bg-light-green text-pure-white"
          />
        </div>
      </div>
      <SideMenu
        setIsOpenTab={setCalendarTab}
        isOpenTab={calendarTab}
        tabTitle={t("Calendar-manager.Calendar Daily Details")}
      >
        <div>
          <CalendarInput
            iconFlex
            iconSrc="select-date-calendar"
            title="Calendar-manager.Selected Date"
            subTitle={`${
              start && startDate !== null
                ? changeDate(start)
                : "일정을 선택해주세요."
            } ${
              end && endDate !== null && end !== changeDate(start)
                ? "~ " + changeDate(end)
                : ""
            }`}
          />
        </div>
        <div>
          <CalendarInput
            selected={isSelected}
            placeholder="Calendar-manager.Select"
            onChange={(e) => {
              setIsSelected(e.target.value);
            }}
            title="Calendar-manager.Availability"
            buttons
          />
        </div>
        <div>
          {/* 핸드폰 */}
          <CalendarInput
            title="Calendar-manager.Price/night"
            disabled={
              radioChecked === "discount" || isSelected === "UNAVAILABLED"
            }
            onChange={(e: any) => setPrice(e.target.value)}
            inputValue={`${
              min === max
                ? inputPriceComma(min)
                : min && !max
                ? inputPriceComma(min)
                : max && !min
                ? inputPriceComma(max)
                : inputPriceComma(min) + ` ~ ` + inputPriceComma(max)
            }`}
            input
          >
            <label htmlFor="priceRadio">
              <input
                type="radio"
                checked={
                  isSelected !== "UNAVAILABLED" && radioChecked === "price"
                }
                value={"price"}
                onChange={(e) => {
                  setRadioChecked(e.target.value);
                }}
                id="priceRadio"
                className="mr-2"
              />
            </label>
          </CalendarInput>
        </div>
        <div>
          {/* 핸드폰 */}
          <CalendarInput
            onChange={(e: any) => {
              setDiscountPer(e.target.value);
            }}
            disabled={radioChecked === "price" || isSelected === "UNAVAILABLED"}
            title="Calendar-manager.Discount Rate"
            selected={discountPer ? discountPer : discountRate}
            placeholder={t("Calendar-manager.Select Rate")}
            options={discountValue()}
            select
          >
            <label htmlFor="discountRadio">
              <input
                type="radio"
                checked={
                  isSelected !== "UNAVAILABLED" && radioChecked === "discount"
                }
                value={"discount"}
                onChange={(e) => {
                  setRadioChecked(e.target.value);
                }}
                id="discountRadio"
                className="mr-2"
              />
            </label>
          </CalendarInput>
        </div>
        <div>
          <CalendarInput
            inputValue={inputPriceComma(discountPrice)}
            disabled
            title="Calendar-manager.Rate Applied Price"
            input
          />
        </div>
        <div>
          <CalendarInput
            placeholder="Calendar-manager.This calendar note is host’s private note"
            noMargin
            titleBlack
            title="Calendar-manager.Calendar note"
            textarea
          />
        </div>
        <div className="text-right">
          <button
            onClick={() => save()}
            className="inline-block mt-4 rounded-lg bg-gold p-2"
          >
            {t("Calendar-manager.Save")}
          </button>
        </div>
      </SideMenu>
      <div className="web:flex phone:hidden justify-between items-center mx-auto web:px-[35px] phone:p-4 web:py-8">
        <div
          className="web:block phone:hidden cursor-pointer hover:text-light-green"
          onClick={() => {
            navigate(-1);
          }}
        >
          {"<"}{" "}
          {currentType === "PG"
            ? t(`Menu.My Private Ground`)
            : t(`Menu.My Accommodation`)}
        </div>
        <div className="web:flex phone:hidden text-[32px] items-center">
          <Icons
            iconName="calendar-manager"
            className="mr-6 w-[42px] h-[46px]"
          />
          <div>{t(`Menu.Calendar Manager`)}</div>
        </div>
        <div className="flex web:flex-col web:w-32 phone:w-full phone:items-center phone:flex-row phone:justify-between">
          <div>{t("Calendar-manager.Booking Window")}</div>
          <select
            onChange={(e) => {
              setBookingWindow(Number(e.target.value));
            }}
            className="h-8 w-32 shadow-ms rounded-lg"
          >
            {bookingWindowValue?.map((item, idx) => {
              const value = item.value;
              const name = item.name;
              return (
                <option key={idx} value={value}>
                  {t(`Calendar-manager.${name}`)}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="web:flex phone:block relative phone:w-full">
        {currentType === "AC" && selectedSpace === "" && spaceId === 0 ? (
          <div className="web:w-[calc(100%-360px)] phone:w-full h-full calc z-100 bg-[rgba(0,0,0,.7)] box-border absolute">
            <div className="top-2/4 -translate-x-2/4 -translate-y-2/4 left-2/4 absolute text-pure-white text-xl font-bold">
              Select space
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="h-screen overflow-y-scroll pb-20">
          <ReactDatePicker
            onChangeRaw={(e: any) => {
              chooseDay(e);
            }}
            monthsShown={bookingWindow}
            renderDayContents={renderDayContents}
            disabledKeyboardNavigation
            // excludeDates={disbledDay}
            selected={startDate}
            locale={lan === "ko" ? ko : enUS}
            startDate={startDate}
            endDate={endDate}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 12)}
            selectsRange
            inline
            onChange={(e: any) => {
              onChange(e);
            }}
          ></ReactDatePicker>
        </div>
        <div className="web:block phone:hidden w-[360px] px-4 pt-4 border-t border-r border-border-light">
          {currentType === "AC" ? (
            <div className="text-center">
              <div className="font-bold mb-8">Space Calendar</div>
              <CalendarInput
                selected={selectedSpace ? selectedSpace : preSpaceName}
                placeholder={spaceId !== 0 ? "" : "Select Space"}
                onChange={(e) => {
                  setSelectedSpace(e.target.value);
                }}
                options={spaceListName}
                noMargin
                title="Selected Space"
                select
              />
              <hr className="my-4 text-input-border-color" />
            </div>
          ) : (
            ""
          )}
          {!startDate && !mainPrice ? (
            <div className="text-center">
              <div className="font-bold">
                {t("Calendar-manager.Calendar Daily Details")}
              </div>
              <Icons
                className="mx-auto mt-8 mb-4 w-16 h-16"
                iconName="calendar-daily-details"
              />
              <div className="text-left text-sm">
                {t(
                  "Calendar-manager.Check all spaces you registered on the calendar"
                )}
              </div>
              <div className="text-left justify-between text-sm w-full flex shadow-btn rounded-lg p-2 mt-12">
                <div>
                  {t("Calendar-manager.Edit Operation & Regular Price")}
                </div>
                <div
                  onClick={() => {
                    setMainPrice((mainPrice) => !mainPrice);
                  }}
                  className="text-link hover:underline cursor-pointer"
                >
                  {t("Calendar-manager.Edit")} {">"}
                </div>
              </div>
            </div>
          ) : !mainPrice ? (
            <>
              <div className="flex items-center justify-between font-bold mb-8">
                <Icons
                  onClick={() => closeBtn()}
                  iconName="menu-close"
                  className="w-4 h-4 cursor-pointer"
                />
                <div className="block">
                  {t("Calendar-manager.Calendar Daily Details")}
                </div>
                <div></div>
              </div>
              <CalendarInput
                iconFlex
                iconSrc="select-date-calendar"
                title="Calendar-manager.Selected Date"
                subTitle={`${
                  start && startDate !== null
                    ? changeDate(start)
                    : "일정을 선택해주세요."
                } ${
                  end && endDate !== null && end !== changeDate(start)
                    ? "~ " + changeDate(end)
                    : ""
                }`}
              />
              <CalendarInput
                placeholder="Calendar-manager.Select"
                selected={isSelected}
                onChange={(e) => {
                  setIsSelected(e.target.value);
                }}
                title="Calendar-manager.Availability"
                buttons
              />
              {/* 웹 */}
              <CalendarInput
                title="Calendar-manager.Price/night"
                disabled={
                  radioChecked === "discount" || isSelected === "UNAVAILABLED"
                }
                onChange={(e: any) => {
                  changePrice(e);
                }}
                inputValue={`${
                  min === max
                    ? inputPriceComma(min)
                    : max && !min
                    ? inputPriceComma(max)
                    : inputPriceComma(min) + ` ~ ` + inputPriceComma(max)
                }`}
                input
              >
                <label htmlFor="priceRadio">
                  <input
                    type="radio"
                    checked={
                      isSelected !== "UNAVAILABLED" && radioChecked === "price"
                    }
                    value={"price"}
                    onChange={(e) => {
                      setPrice("");
                      setRadioChecked(e.target.value);
                    }}
                    id="priceRadio"
                    className="mr-2"
                  />
                </label>
              </CalendarInput>

              {/* 웹 */}
              <CalendarInput
                onChange={(e: any) => {
                  setDiscountPer(e.target.value);
                }}
                title="Calendar-manager.Discount Rate"
                disabled={
                  radioChecked === "price" || isSelected === "UNAVAILABLED"
                }
                selected={discountPer ? discountPer : discountRate}
                placeholder={t("Calendar-manager.Select Rate")}
                options={discountValue()}
                select
              >
                <label htmlFor="discountRadio">
                  <input
                    type="radio"
                    checked={
                      isSelected !== "UNAVAILABLED" &&
                      radioChecked === "discount"
                    }
                    value={"discount"}
                    onChange={(e) => {
                      setDiscountPer("");
                      setRadioChecked(e.target.value);
                    }}
                    id="discountRadio"
                    className="mr-2"
                  />
                </label>
              </CalendarInput>
              <CalendarInput
                inputValue={inputPriceComma(discountPrice)}
                disabled
                title="Calendar-manager.Rate Applied Price"
                input
              />
              <CalendarInput
                placeholder={t(
                  "Calendar-manager.This calendar note is host’s private note"
                )}
                noMargin
                title="Calendar-manager.Calendar note"
                textarea
              />
              <div className="text-right">
                <button
                  onClick={() => save()}
                  className="inline-block mt-4 rounded-lg bg-gold p-2"
                >
                  {t("Calendar-manager.Save")}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-between font-bold mb-8">
                <Icons
                  onClick={() => setMainPrice((mainPrice) => !mainPrice)}
                  iconName="menu-close"
                  className="w-4 h-4 cursor-pointer"
                />
                <div className="block">
                  {t("Calendar-manager.Edit Operation & Regular Price")}
                </div>
                <div></div>
              </div>
              <div>
                {weekPrice.map((item, idx) => {
                  return (
                    <HostInput
                      key={idx}
                      pricing
                      inputValue={item.price}
                      onChange={(e) => {
                        setWeekPrice(
                          weekPrice.map((weekItem: any) =>
                            t(`Days-data.${weekItem.title}`) === e.target.name
                              ? { ...weekItem, checked: e.target.checked }
                              : weekItem
                          )
                        );
                      }}
                      inputOnChange={(e) => {
                        setWeekPrice(
                          weekPrice.map((weekItem: any) =>
                            t(`Days-data.${weekItem.title}`) === e.target.name
                              ? {
                                  ...weekItem,
                                  price: inputPriceFormat(e.target.value),
                                }
                              : weekItem
                          )
                        );
                      }}
                      disabled={!item.checked}
                      calendarPricing
                      CheckContent={t(`Days-data.${item.title}`)}
                    />
                  );
                })}
                <div className="text-right">
                  <button
                    onClick={() => {
                      changeBasePrice();
                    }}
                    className="inline-block mt-4 rounded-lg bg-gold p-2"
                  >
                    {t("Calendar-manager.Save")}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarManager;
