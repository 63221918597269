import React from "react";
import { useTranslation } from "react-i18next";

interface paypalPayProps {
  className?: string;

  pg?: string;
  pay_method?: string;
  escrow?: boolean;
  merchant_uid?: string;
  name?: string;
  amount?: number;
  currency?: string;
  buyer_email?: string;
  buyer_name?: string;
  buyer_tel?: string;
  buyer_addr?: string;
  buyer_postcode?: string;
  language?: string;
  m_redirect_url?: string;
}

const PaypalPay = ({
  className,
  pg,
  pay_method,
  escrow,
  merchant_uid,
  name,
  amount,
  currency,
  buyer_email,
  buyer_name,
  buyer_tel,
  buyer_addr,
  buyer_postcode,
  language,
  m_redirect_url,
}: paypalPayProps) => {
  const { t } = useTranslation();
  const booking = () => {
    const { IMP } = window;
    IMP.request_pay(
      {
        pg: "paypal.{API 사용자 이름}",
        pay_method: "card",
        merchant_uid: "order_no_0001", // 상점에서 관리하는 주문 번호
        name: "주문명:결제테스트",
        amount: 14.2,
        currency: "USD", // 기본값: USD(원화 KRW는 페이팔 정책으로 인해 지원하지 않음)
        buyer_email: "test@portone.io",
        buyer_name: "구매자이름",
        buyer_tel: "010-1234-5678",
        buyer_addr: "서울특별시 강남구 삼성동",
        buyer_postcode: "123-456",
        m_redirect_url: "{결제 완료 후 리디렉션 될 URL}",
      },
      function (rsp: any) {}
    );
  };
  return (
    <div>
      <div
        className={`${className}`}
        onClick={() => {
          booking();
        }}
      >
        {t("Booking-request.PayPal")} {t("Booking-request.CHECKOUT")}
      </div>
    </div>
  );
};

export default PaypalPay;
