import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import HeadLogo from "components/Auth/authLogo";

import Button from "elements/Button";
import Input from "elements/Input";
import { useAppDispatch } from "redux/store";
import { loginUser } from "redux/action/userInfo";

import { hostInfo } from "redux/action/host";
import Icons from "elements/Icons";
import NaverLoginButton from "elements/NaverLoginButton";
import { useGoogleLogin } from "@react-oauth/google";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { setCookie } from "utils/cookie";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const locationInfo: any = useLocation();
  const nextPage = locationInfo.state?.nextPath;
  const nextPageId = locationInfo.state?.nextPathId;

  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");

  const [loginMessage, setLoginMessage] = useState("");
  const [loginErr, setLoginErr] = useState(false);

  const loginData = {
    email: email,
    password: pw,
  };

  const onEnterPress = (e: any) => {
    if (e.key === "Enter") {
      login();
    }
  };

  const googleAuth = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      console.log(credentialResponse);
      const {
        access_token,
        expires_in,
        scope,
        token_type,
      } = credentialResponse;
      requestToMustOutdoor({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}auth/google/log-in`,
        data: {
          accessToken: access_token,
          expiresIn: expires_in,
          scope: scope,
          tokenType: token_type,
        },
      })
        .then((res) => {
          const value = res.data.data;
          const { type } = value;
          const { accessToken } = value;
          const { refreshToken } = value;
          const { id } = value;

          const { email } = value;
          const { name } = value;
          const { role } = value;

          const { totalMobile } = value;
          const { mobile } = value;
          const { mobileCountryCode } = value;
          const { mobileVerified } = value;

          const { hostName } = value;

          localStorage.setItem("STATUS", "MEMBER");
          localStorage.setItem("type", type);
          localStorage.setItem("email", email);
          localStorage.setItem("name", name);
          localStorage.setItem("role", role);
          localStorage.setItem("m", id);
          localStorage.setItem("isLogin", accessToken);
          if (mobile !== null || mobile !== undefined) {
            localStorage.setItem("phone", mobile);
          }
          if (mobileCountryCode !== null) {
            localStorage.setItem("CC", mobileCountryCode);
          }
          if (mobileVerified !== null) {
            localStorage.setItem("MV", mobileVerified);
          }
          if (hostName !== null) {
            localStorage.setItem("hostName", hostName);
          }
          if (refreshToken !== null || refreshToken !== undefined) {
            localStorage.setItem("refresh", refreshToken);
          }
          setCookie("refreshToken", refreshToken);
          dispatch(hostInfo());
          if (nextPage) {
            navigate(`${nextPage}`, { replace: true });
          } else if (nextPageId) {
            navigate(`/details/${nextPageId}`, { replace: true });
          } else {
            navigate("/", { replace: true });
          }
          return res.data.data;
        })
        .catch((err) => {
          const errCode = err.response.data;
          alert(errCode.message);
        });
    },
  });

  const login = async () => {
    try {
      const res = await dispatch(loginUser(loginData)).unwrap();
      dispatch(hostInfo());
      setLoginErr(false);
      if (nextPage) {
        navigate(`${nextPage}`, { replace: true });
      } else if (nextPageId) {
        navigate(`/details/${nextPageId}`, { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    } catch (err:any) {
      setLoginErr(true);
      if (err.response.data?.code === "UA06") {
        localStorage.setItem("VerifyEmail", email);
        navigate("/sign-up/authentication", { replace: true });
      }
      setLoginMessage(
        t("Account.Email address or password you entered is invalid")
      );
    }
  };

  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_DEV_REDIRECT_URI}&response_type=code`;

  return (
    <div>
      <HeadLogo>
        <div>
          <div className="inline-block mt-8">
            <a href={KAKAO_AUTH_URL}>
              <div className="bg-yellow w-80 border-0 px-4 h-8 rounded-lg justify-center relative cursor-pointer items-center shadow-ms text-sm flex">
                <Icons
                  iconName="kakao"
                  className="absolute left-4 bottom-2 w-4 h-4"
                />
                <div>{t("Account.Login with Kakao")}</div>
              </div>
            </a>
            <div
              onClick={() => {
                googleAuth();
              }}
              className="bg-pure-white mt-2 w-80 border-0 px-4 h-8 rounded-lg justify-between relative cursor-pointer items-center shadow-ms text-sm flex"
            >
              <img
                className="w-4 h-4"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/google.svg"
                alt=""
              />
              <div>{t("Account.Login with Google")}</div>
              <div />
            </div>
          </div>
          <div className="flex items-center justify-center my-4">
            <div className="h-1 border-t border-black-grey bg-pure-white w-25 mt-0.5" />
            <div className="mx-6 text-pure-white px-6">or</div>
            <div className="h-1 border-t border-black-grey bg-pure-white w-25 mt-0.5" />
          </div>
        </div>
        <div className="mb-4">
          <div>
            <Input
              isIcon
              isError={loginErr}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              inputIcon={"email-address"}
              placeholder={t("Account.Email Address")}
              type={"email"}
            />
          </div>
          <div>
            <Input
              password
              isError={loginErr}
              onKeyPress={onEnterPress}
              isIcon
              onChange={(e) => {
                setPw(e.target.value);
              }}
              type={"password"}
              placeholder={t("Account.Password")}
              inputIcon={"password"}
            >
              {loginMessage}
            </Input>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              login();
            }}
            className="bg-light-green inline-block text-pure-white text-xs rounded-lg py-2 px-36"
            btnText={t("Account.Login")}
          />
        </div>
        <div
          onClick={() => {
            navigate("forgot-pass");
          }}
          className="mt-8 text-pure-white underline underline-offset-4 cursor-pointer"
        >
          {t("Account.Forgot your password?")}
        </div>
        <div className="w-[327px] inline-block">
          <div className="text-pure-white flex justify-between mt-4">
            <div>{t("Account.Don't you have an account")}</div>
            <span
              onClick={() => {
                navigate("/sign-up");
              }}
              className="font-bold underline text-xs cursor-pointer"
            >
              {`${t("Account.Sign up")}`}
            </span>
          </div>
        </div>
      </HeadLogo>
    </div>
  );
};

export default Login;
