import Button from "elements/Button";

import React from "react";
import { useTranslation } from "react-i18next";

interface MyprivateGroundProps {
  children?: any
  title?: string
  className?: string;
  icons?: any;
  isBtn?: boolean;
  onClick?: () => void;
  isPrivate?: boolean;

  titleIcon: string;
}

const MyPrivateGround = ({onClick, className, titleIcon, children, isBtn, title, isPrivate}: MyprivateGroundProps) => {
  const {t} = useTranslation()
  return (
    <div className={`${className} w-full web:max-w-[864px] phone:max-w-none text-left`}>
      <div className="text-left my-8  web:block phone:hidden">
        <div className="flex justify-between">
          <div className="flex "> 
          <img className="w-12 h-12" src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/${titleIcon}.svg`} alt="" />
          <p className="text-[32px] ml-4">{title ? t(`${title}`) : t(`Menu.My Private Ground`)}</p>
          </div>
          {isBtn ? 
          <Button
            isIcon
            onClick={onClick}
            src="calendar-manager"
            className={`${isPrivate ? " fill-pure-white bg-light-green text-pure-white" : 'bg-[#e1e1e1] opacity-50' } py-3 px-4 ml-auto rounded-lg`}
            btnText={t("Add-private-ground.Preview")}
          ></Button> : '' }
        </div>
      </div>
      {children}
    </div>
  );
};

export default MyPrivateGround;
