import React from "react";
import { useTranslation } from "react-i18next";

interface ToolTipProps {
  toolTipClass?: string;
  children?: any;
  className?: string;
  toolTitle?: any;
  toolSubTitle?: any;
  toolEx?: any;
}

const ToolTip = ({
  children,
  toolTitle,
  toolSubTitle,
  toolEx,
  className,
  toolTipClass,
}: ToolTipProps) => {
  const {t} = useTranslation();
  return (
    <div className={`relative ${className} phone:hover:border-none`}>
      {children}
      <div
        className={`rounded-lg tooltip hidden phone:hidden absolute z-100 w-52 min-h-[128px] bg-toolTipBg p-4`}
      >
        <div className="font-bold text-sm">{t(`${toolTitle}`)}</div>
        {toolTitle ? <hr className="my-2 text-silver" /> : ""}
        <div className="text-xs">{t(`${toolSubTitle}`)}</div>
        {toolEx ? (
          <>
            <br />
            <div className="text-xs text-input-text">{t(`${toolEx}`)}</div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ToolTip;
