import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
    generalInfo: {
        title: string;
        description: string;
        address: string;
        country: "KR";
        state: string;
        city: string;
        zipCode: string;
        directions: string;
        extraAddress: string;
        latitude: number;
        longitude: number;
        destinationId: 49;
        touristSpots: any;
    },
    accommodationDetails: {
        facilityIds: any;
        activityIds: any;
        themeIds: any;
        specialFacility: string;
    },
    accommodationGallery: {
        currentImage: any,
        imageList: [],
    },
    extraOptions: {
        extraOption: []
    },
    policy: {
        checkIn: string;
        checkOut: string;
        confirmMethod: string;
        advanced: boolean;
        extraPolicy: string;
        policyId: number
    }

}

const initialState: InitialState = {
    generalInfo: {
        title: "",
        description: "",
        address: "",
        country: "KR",
        state: "",
        city: "",
        zipCode: "",
        directions: "",
        extraAddress: "",
        latitude: 0,
        longitude: 0,
        destinationId: 49,
        touristSpots: []
    },
    accommodationDetails: {
        facilityIds: [],
        activityIds: [],
        themeIds: [],
        specialFacility: ""
    },
    accommodationGallery: {
        currentImage: {},
        imageList: []
    },
    extraOptions: {
        extraOption: []
    },
    policy: {
        checkIn: "",
        checkOut: "",
        confirmMethod: "",
        advanced: false,
        extraPolicy: "",
        policyId: 0
    }
}

const accommodationSlice = createSlice({
    name: "AccommodationData",
    initialState,
    reducers: {
        generalInfo: (state, action: PayloadAction<any>) => {
            state.generalInfo = action.payload;
        },
        acDetails: (state, action: PayloadAction<any>) => {
            state.accommodationDetails = action.payload;
        },
        acGallery: (state, action: PayloadAction<any>) => {
            state.accommodationGallery = action.payload;
        },
        extraOption: (state, action: PayloadAction<any>) => {
            state.extraOptions = action.payload;
        },
        policy: (state, action: PayloadAction<any>) => {
            state.policy = action.payload;
        }
    }
})

export default accommodationSlice;
export const { generalInfo, acDetails, acGallery, extraOption, policy } = accommodationSlice.actions;