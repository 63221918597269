import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ToolTip from "./ToolTip";

interface checkBoxProps {
  onChange?: (e: any) => void;

  title?: string;
  defaultChecked?: any;
  value?: string;
  checked?: any;
  id?: any;
  imageUrl?: any;
  typesModal?: string;
  name?:string;
}

const CheckBoxDefault = ({
  defaultChecked,
  value,
  onChange,
  id,
  checked,
  title,
  imageUrl,
  typesModal,
  name
}: checkBoxProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`inputWrap w-48 flex items-center mb-4 ${
        typesModal ? typesModal : "mr-8"
      } `}
    >
      <input
        id={id}
        name={name}
        defaultChecked={defaultChecked}
        value={value}
        onChange={onChange}
        checked={checked}
        type="checkbox"
      />
      <img className="w-6 h-6 mr-1" src={imageUrl} alt="" />
      {t(`${title}`)}
    </div>
  );
};

export default CheckBoxDefault;
