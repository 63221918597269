import React, { useCallback, useEffect, useState } from "react";
import EditAcGeneralInfo from "./editAcGeneralInfo";
import EditAcDetails from "./editAcDetails";
import EditAcGallery from "./editAcGallery";
import EditAcExtraOption from "./editAcExtraOption";
import EditAcPoliy from "./editAcPoliy";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useTranslation } from "react-i18next";
import MoblieHeader from "components/moblieHeader";
import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";

const EditAccommodation = () => {
  const { t } = useTranslation();
  const [acData, setAcData] = useState<any>([]);
  const [tabNum, setTabNum] = useState(0);

  const hostId = localStorage.getItem("hostNum");

  const fetctAccommodation = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/accommodation/search/${hostId}`,
    }).then((res) => {
      const data = res.data.data;
      setAcData(data);
      return data;
    });
  }, [hostId]);

  useEffect(() => {
    fetctAccommodation();
  }, []);

  const {
    title,
    description,
    address,
    country,
    state,
    city,
    zipCode,
    extraAddress,
    latitude,
    longitude,
    directions,
    destinationInfo,
    touristSpots,

    facilities,
    activities,
    locationThemes,
    specialFacility,

    currentImage,
    imageList,

    extraOptions,

    checkIn,
    checkOut,
    confirmMethod,
    advanced,
    extraPolicy,
    policyInfo,
  } = acData;

  const acGeneralInfo = {
    title,
    description,
    address,
    country,
    state,
    city,
    zipCode,
    extraAddress,
    latitude,
    longitude,
    directions,
    destinationInfo,
    touristSpots,
  };

  const acDetail = {
    facilities,
    activities,
    locationThemes,
    specialFacility,
  };

  const acGallery = {
    currentImage,
    imageList,
  };

  const acExtraOption = extraOptions;

  const acPolicy = {
    checkIn,
    checkOut,
    confirmMethod,
    advanced,
    extraPolicy,
    policyInfo,
  };

  const editTab = [
    { id: 0, title: "General Info" },
    { id: 1, title: "Accommodation Details" },
    { id: 2, title: "Accommodation Gallery" },
    { id: 3, title: "Extra Options" },
    { id: 4, title: "Policy" },
  ];

  // 새로고침 막기
  useEffect(() => {
    // 새로고침 막기(조건 부여 가능)
    window.onbeforeunload = function() {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const changeTab = (id: number) => {
    setTabNum(id);
  };

  return (
    <div className="web:text-center phone:text-left web:p-8 mx-auto lg:max-w-1216">
      <MoblieHeader left center="My-accommodation.Edit Accommodation" />
      <div className="web:flex">
        <HostAccount />
        <HostAccountHeader
          titleIcon="outdoor-accommodation"
          title="My-accommodation.Edit Accommodation"
        >
          <div
            className="border-b h-11 flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll"
          >
            {editTab.map((item, idx) => {
              return (
                <div
                  onClick={() => {
                    changeTab(item?.id);
                  }}
                  key={idx}
                  className={`
                  cursor-pointer
                  ${
                    item.id === tabNum
                      ? "bg-light-green text-pure-white"
                      : " text-input-text"
                  } px-4 items-center flex focus:bg-light-green rounded-t-lg`}
                >
                  {t(`Add-accommodation.${item.title}`)}
                </div>
              );
            })}
          </div>
          {tabNum === 0 ? (
            <EditAcGeneralInfo acGeneralInfoData={acGeneralInfo} />
          ) : tabNum === 1 ? (
            <EditAcDetails acDetailData={acDetail} />
          ) : tabNum === 2 ? (
            <EditAcGallery acGalleryData={acGallery} />
          ) : tabNum === 3 ? (
            <EditAcExtraOption acExtraData={acExtraOption} />
          ) : tabNum === 4 ? (
            <EditAcPoliy acPolicyData={acPolicy} />
          ) : (
            ""
          )}
        </HostAccountHeader>
      </div>
    </div>
  );
};

export default EditAccommodation;
