import React, { useEffect, useState } from "react";
import { t } from "i18next";

import AcWrap from "elements/AcWrap";
import Button from "elements/Button";
import HostInput from "elements/HostInput";

import HostWrap from "components/Host/hostWrap";

import { hour, Confirmation, Advanced } from "../constants";
import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useTranslation } from "react-i18next";
import { changecase } from "utils/changeCase";

interface PolicyProps {
  onPre?: any;
  onNext?: any;
  policyData?: any;
}

const AcPolicy = ({ onPre, policyData }: PolicyProps) => {
  const { t } = useTranslation();
  const [rule, setRule] = useState([]);
  const [policyValue, setPolicyValue] = useState<any>([]);
  const [policyName, setPolicyName] = useState<string>("");

  const [policy, setPolicy] = useState({
    checkIn: "",
    checkOut: "",
    confirmMethod: "",
    advanced: true,
    extraPolicy: "",
    policyId: 0,
  });

  const handlePageData = () => {
    policyData(policy);
  };

  useEffect(() => {
    requestToMustOutdoor
      .get("api/v2/policy/PG")
      .then((res) => {
        const data = res.data.data;
        setRule(data);
        const policyOptions = data.map((item: any) => {
          return changecase(item.name);
        });
        setPolicyValue(policyOptions);
        return res;
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <div className="text-left">
          <HostWrap
            mainClass="mt-0"
            help
            toolTitle="Tooltip-add-accommodation.Policy check in out-tooltip title"
            toolSubTitle="Tooltip-add-accommodation.Policy check in out-tooltip desc"
            host
            title="Add-accommodation.Check In / Out*"
          >
            <HostInput
              timeselect
              //   selected={
              //     startHour ? startHour : page?.bookingTime.checkIn.slice(0, 2)
              //   }
              placeholder="Hr"
              onChange={(e) => {
                setPolicy({ ...policy, checkIn: `${e.target.value}:00` });
              }}
              options={hour}
              formTitle={t("Add-accommodation.Check in")}
            />
            <HostInput
              timeselect
              //   selected={
              //     endHour ? endHour : page?.bookingTime.checkOut.slice(0, 2)
              //   }
              placeholder="Hr"
              onChange={(e) => {
                setPolicy({ ...policy, checkOut: `${e.target.value}:00` });
              }}
              options={hour}
              nomargin
              formTitle={t("Add-accommodation.Check out")}
            />
          </HostWrap>
          <AcWrap
            help
            selectTrans="Confirmation-data"
            titleClass="mt-12"
            toolTitle="Tooltip-add-accommodation.Policy confirmation-tooltip title"
            toolSubTitle="Tooltip-add-accommodation.Policy confirmation-tooltip desc"
            placeholder="Add-accommodation.Select"
            onChange={(e) => {
              if (e.target.value === "Confirmation Within 24 Hours") {
                setPolicy({ ...policy, confirmMethod: "WITHIN_24_HOURS" });
              } else if (e.target.value === "Instant booking") {
                setPolicy({ ...policy, confirmMethod: "INSTANT_BOOK" });
              }
            }}
            select
            options={Confirmation}
            title="Add-accommodation.Confirmation*"
          />
          <AcWrap
            help
            options={Advanced}
            selectTrans="Advance-notice-data"
            placeholder="Add-accommodation.Select"
            toolTitle="Tooltip-add-accommodation.Policy advance-tooltip title"
            toolSubTitle="Tooltip-add-accommodation.Policy advance-tooltip desc"
            toolEx="Tooltip-add-accommodation.Policy advance-tooltip ex"
            onChange={(e) => {
              if (e.target.value === "Booking is available today") {
                setPolicy({ ...policy, advanced: true });
              } else if (e.target.value === "Booking is not available today") {
                setPolicy({ ...policy, advanced: false });
              }
            }}
            select
            title="Add-accommodation.Advance Notice*"
          />
          <AcWrap
            textArea
            onChange={(e) => {
              setPolicy({
                ...policy,
                extraPolicy: TextareaReplaceNewLine(e.target.value),
              });
            }}
            title="Add-accommodation.Additional Rule / Policy"
            help
            inputMaxLength={500}
            row={4}
            toolTitle="Tooltip-add-accommodation.Policy additional-tooltip title"
            toolSubTitle="Tooltip-add-accommodation.Policy additional-tooltip desc"
            placeholder={
              "Please describe additional rule / policy on your Private Ground \n• e.g. No Smoking in the area of private ground. Smoking is available in designated smoking area"
            }
          />
          <AcWrap
            noMarginB
            select
            options={policyValue}
            onChange={(e) => {
              setPolicyName(e.target.value);
              setPolicy({
                ...policy,
                policyId:
                  e.target.value === "Flexible"
                    ? 36
                    : e.target.value === "Moderate"
                    ? 35
                    : e.target.value === "Super Strict"
                    ? 34
                    : e.target.value === "Strict"
                    ? 37
                    : 0,
              });
            }}
            placeholder="Add-accommodation.Select Cancellation Policy"
            title="Add-accommodation.Cancellation & Refund Policy*"
            help
            selectTrans="Cancellation-policy-data"
            toolTitle="Tooltip-add-accommodation.Policy cancel refund-tooltip title"
            toolSubTitle="Tooltip-add-accommodation.Policy cancel refund-tooltip desc"
            miniTitle="Booking-request.MUST OUTDOOR will enforce following cancellation policy"
          />
          <div className="font-bold text-sm mt-4 mb-2">
            {t(policyName ? `Cancellation-policy-data.${policyName}` : "")}{" "}
            {t("Add-accommodation.Cancellation Policy")}
          </div>
          <div>
            {rule.map((item: any, idx) => {
              if (policyName === changecase(item.name)) {
                return (
                  <div key={idx}>
                    {item?.firstRange !== null ? (
                      <div>
                        {t(
                          "Cancellation-policy-data.{{days}}100% refund of the payment",
                          { days: item?.firstRange }
                        )}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.secondRange !== null ? (
                      <div>
                        {t(
                          "Cancellation-policy-data.{{days}}70% refund of the payment",
                          { days: item?.secondRange }
                        )}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.thirdRange !== null ? (
                      <div>
                        {t(
                          "Cancellation-policy-data.{{days}}50% refund of the payment",
                          { days: item?.thirdRange }
                        )}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.fourthRange !== null ? (
                      <div>
                        {t(
                          "Cancellation-policy-data.{{days}}30% refund of the payment",
                          { days: item?.fourthRange }
                        )}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.fifthRange !== null ? (
                      <div>
                        {t(
                          "Cancellation-policy-data.{{days}}0% refund of the payment",
                          { days: item?.fifthRange }
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="mt-12 mb-8 justify-between flex">
          <Button
            onClick={() => onPre()}
            btnText={t("Add-accommodation.Previous")}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
          />
          <Button
            isIcon
            src={"register-completed"}
            onClick={() => handlePageData()}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-accommodation.Register")}
          />
        </div>
      </div>
    </div>
  );
};

export default AcPolicy;
