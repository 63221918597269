import React, { useEffect, useState } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SearchBanner from "components/Main/SearchBanner";
import PopularTheme from "components/Main/PopularTheme";
import ReasonThree from "components/Main/ReasonThree";
import MustPick from "components/Main/MustPick";
import PopularDestination from "components/Main/PopularDestination";
import HotDeal from "components/Main/HotDeal";
import ParnerWithUs from "components/Main/PartnerWithUs";
import axios from "axios";
import { changeDate } from "utils/changeDate";

const Main = () => {
  // const location = useGeolocation();
  const today = changeDate(new Date());
  const currency = localStorage.getItem("currency");

  useEffect(() => {
    axios.get("https://api.ip.pe.kr/json").then((res) => {
      const value = res.data;
      const currentLocation = value?.country_code;
      return value;
    });
  }, []);

  // 엑세스 토큰 : 2시간
  // 리프레시 토큰 : 1년 6시간
  // 401 에러

  useEffect(() => {
    const channelTalk: any = document.getElementById("ch-plugin");
    setTimeout(() => {
      const value = channelTalk;
      const result = value?.style ? value?.style : "";
      if (result) {
        value.style.cssText = "display: block !important";
      }
    }, 10);
  }, []);

  return (
    <>
      {/* <GoogleTranslate/> */}
      {/* <BottomPopup/> */}
      <SearchBanner />
      <MustPick />
      <ReasonThree />
      {/* <PopularTheme /> */}
      <PopularDestination />
      {/* <HotDeal /> */}
      <ParnerWithUs />
    </>
  );
};

export default Main;
