import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { menus, sns } from "./constants";

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  if (window.location.pathname === "/log-in") return null;
  if (window.location.pathname === "/sign-up") return null;
  if (window.location.pathname === "/host/calendar") return null;
  // if (window.location.pathname.split("/")[1] === "details") return null;

  const SnsItem = sns.map((el, idx) => {
    return (
      <img
        onClick={() => window.open(`${el.src}`, "_blank")}
        alt="snsLogo"
        className="mr-4 web:w-6 web:h-6 phone:w-5 phone:h-5 cursor-pointer"
        key={idx}
        src={el.imgUrl}
      />
    );
  });

  return (
    <div>
      <div className="bg-black-grey px-16 ">
        <div className="web:flex justify-between pt-8 phone:hidden">
          <div>
            <div>
              <img
                onClick={() => {
                  navigate("/");
                }}
                alt=""
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/Logo/must-logo-white.png"
                className="cursor-pointer w-64 sm:w-64"
              />
              <p className="text-sm sm:text my-4 text-pure-white">
                {t("Footer.No.1 Glocal Outdoor Life & Travel Platform")}
              </p>
              <div className="flex">{SnsItem}</div>
            </div>
          </div>
          <div id="right_about">
            <div className="inline-block">
              <div className="flex text-pure-white">
                {menus.map(({ title, sub }) => (
                  <ul className="inline-block" key={title}>
                    <li className="title mb-5 font-bold text-base">
                      {t(`Footer.${title}`)}
                    </li>
                    {sub.map(({ title: subtitle, url }) => (
                      <li
                        className="mb-[0.5625rem] cursor-pointer text-sm w-39"
                        key={subtitle}
                      >
                        <div
                          onClick={() => {
                            navigate(url);
                          }}
                        >
                          {t(`Footer.${subtitle}`)}
                        </div>
                      </li>
                    ))}
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </div>
        <hr className="text-[#d8d8d8] my-4 web:block phone:hidden " />
        <div className="pb-4 text-pure-white text-[10px] web:flex phone:hidden justify-between">
          <div>{t("Footer.All rights reserved.")}</div>
          <div className="w-[480px]">
            <div>{t("Footer.Affiliate Partnership")}</div>
            <div>고객센터: 070-8018-5262</div>
          </div>
        </div>
      </div>

      <div className="phone:block web:hidden bg-black-grey p-4">
        <img
          onClick={() => {
            navigate("/");
          }}
          alt=""
          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/Logo/must-logo-white.png"
          className="cursor-pointer w-64 sm:w-64"
        />
        <p className="text-xs my-2 text-pure-white">
          {t("Footer.No.1 Glocal Outdoor Life & Travel Platform")}
        </p>
        <div className="flex">{SnsItem}</div>
        <div className="text-pure-white mt-4">
          <div>
            <div className="font-bold text-sm">{t("Footer.About us")}</div>
            <div className="flex text-xs mt-2">
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate("/about");
                }}
              >
                {t("Footer.Our Service")}
              </div>
              <div className="mx-2">|</div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate("/partner");
                }}
              >
                {t("Footer.Partner With Us")}
              </div>
            </div>
          </div>
          <div>
            <div className="font-bold text-sm mt-4">{t("Footer.Support")}</div>
            <div className="flex text-xs mt-2">
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate("/how-it-works");
                }}
              >
                {t("Footer.How It Works")}
              </div>
              <div className="mx-2">|</div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate("/faq");
                }}
              >
                {t("Footer.FAQ")}
              </div>
              <div className="mx-2">|</div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate("/contact");
                }}
              >
                {t("Footer.Contact Us")}
              </div>
            </div>
          </div>
          <div>
            <div className="font-bold text-sm mt-4">
              {t("Footer.Terms of Use")}
            </div>
            <div className="flex text-xs mt-2">
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate("/terms");
                }}
              >
                {t("Footer.Terms and Conditions")}
              </div>
              <div className="mx-2">|</div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                {t("Footer.Privacy Policy")}
              </div>
            </div>
          </div>
          <div className="text-center text-[10px] mt-4">
            {t("Footer.All rights reserved.")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
