import React, { useEffect, useState } from "react";

import HostWrap from "components/Host/hostWrap";
import HostInput from "elements/HostInput";
import Button from "elements/Button";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";
import { useTranslation } from "react-i18next";
import { Advanced, Confirmation, hour } from "pages/Register/constants";
import { changecase, changeFacility } from "utils/changeCase";

interface editPolicyData {
  bookingTime?: string;
  confirmMethod?: string;
  advanceDate?: boolean;
  extraPolicy?: string;
  policyInfo?: string;
  id?: number;
}

const EditPolicy = ({ id, policyEditData }: editPolicyData | any) => {
  const { t } = useTranslation();
  const hostNum = localStorage.getItem("hostNum");
  const [policyValue, setPolicyValue] = useState<any>([]);
  const [policyName, setPolicyName] = useState("");
  const [policyEn, setPolicyEn] = useState("");
  const [policyId, setPolicyId] = useState(0);
  const [extraPolicy, setExtraPolicy] = useState("");

  const [advanceData, setAdvanceData] = useState(false);
  const [advanceValue, setAdvanceValue] = useState("");

  const [rule, setRule] = useState([]);

  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");

  const [confirmValue, setConfinmValue] = useState("");
  const [confirmValueSetting, setConfinmSetting] = useState("");

  const [preConfirmValue, setPreConfinmValue] = useState("");
  const [preAdvanceData, setPreAdvanceData] = useState("");

  const currentData = {
    bookingTime: {
      checkIn: `${startHour}:00`,
      checkOut: `${endHour}:00`,
    },
    advanceDate: advanceData,
    confirmMethod: confirmValueSetting,
    // policyId: policyId,
    extraPolicy: TextareaReplaceNewLine(extraPolicy),
  };

  useEffect(() => {
    if (confirmValue === "Confirmation Within 24 Hours") {
      setConfinmSetting("WITHIN_24_HOURS");
    } else if (confirmValue === "Instant Confirmation") {
      setConfinmSetting("INSTANT_BOOK");
    }
  }, [confirmValue]);

  useEffect(() => {
    if (advanceValue === "Booking is available today") {
      setAdvanceData(true);
    } else if (advanceValue === "Booking is not available today") {
      setAdvanceData(false);
    }
  }, [advanceValue]);

  useEffect(() => {
    requestToMustOutdoor
      .get("api/v2/policy/PG")
      .then((res) => {
        const data = res.data.data;
        setRule(data);
        const policyOptions = data?.map((item: any) => {
          return item?.name;
        });
        setPolicyValue(policyOptions);
        return res;
      })
      .catch((err) => {});
  }, []);

  const {
    preBookingTime,
    preAdvanceDate,
    preConfirmMethod,
    preExtraPolicy,
    prePolicyInfo,
  } = policyEditData;

  useEffect(() => {
    setStartHour(preBookingTime?.checkIn.slice(0, 2));
    setEndHour(preBookingTime?.checkOut.slice(0, 2));
    setExtraPolicy(preExtraPolicy);
    let method = "";
    if (preConfirmMethod === "WITHIN_24_HOURS") {
      method = "Confirmation Within 24 Hours";
    }
    setConfinmValue(method);
  }, [preBookingTime, preExtraPolicy, preConfirmMethod]);

  useEffect(() => {
    if (preConfirmMethod === "WITHIN_24_HOURS") {
      setPreConfinmValue("Confirmation Within 24 Hours");
    } else if (preConfirmMethod === "INSTANT_BOOK") {
      setPreConfinmValue("Instant Confirmation");
    }
  }, [confirmValue, preConfirmMethod, preConfirmValue]);

  useEffect(() => {
    if (preAdvanceDate === true) {
      setPreAdvanceData("Booking is available today");
    } else if (preAdvanceDate === false) {
      setPreAdvanceData("Booking is not available today");
    }
  }, [preAdvanceDate]);

  const policyTitle = prePolicyInfo?.name;

  useEffect(() => {
    if (
      policyName === "super strict" ||
      policyName === "가장 엄격한" ||
      policyTitle === "super strict" ||
      policyTitle === "가장 엄격한"
    ) {
      setPolicyId(34);
      setPolicyEn("super strict");
    } else if (
      policyName === "moderate" ||
      policyName === "일반" ||
      policyTitle === "moderate" ||
      policyTitle === "일반"
    ) {
      setPolicyId(35);
      setPolicyEn("moderate");
    } else if (
      policyName === "flexible" ||
      policyName === "유연한" ||
      policyTitle === "flexible" ||
      policyTitle === "유연한"
    ) {
      setPolicyId(36);
      setPolicyEn("flexible");
    } else if (
      policyName === "strict" ||
      policyName === "엄격한" ||
      policyTitle === "strict" ||
      policyTitle === "엄격한"
    ) {
      setPolicyId(37);
      setPolicyEn("strict");
    }
  }, [policyName, policyTitle]);

  const modiPoli = () => {
    requestToMustOutdoor({
      method: "PATCH",
      url: `/api/v2/private-ground/${id}`,
      data: { ...currentData, hostId: hostNum },
    })
      .then((res) => {
        window.location.reload();
        return res;
      })
      .catch((err) => {});
  };

  return (
    <div className="web:flex phone:block">
      <div className="web:w-160 web:mx-0 phone:mx-4">
        <div className="text-left">
          <HostWrap
            toolTitle="1 박 기준 공유 시간을 설정해주세요."
            toolSubTitle="호스트님의 공유 장소가 항상 비어있는 땅이 아니라면 활용이 가능한 시간대를 고려하여 체크인과 체크아웃 시간을 정해주세요."
            host
            help
            title="Add-private-ground.Check In / Out*"
          >
            <HostInput
              timeselect
              selected={startHour}
              placeholder="Hr"
              onChange={(e) => {
                setStartHour(e.target.value);
              }}
              options={hour}
              formTitle={t("Add-private-ground.Check in")}
            />
            <HostInput
              timeselect
              selected={endHour}
              placeholder="Hr"
              onChange={(e) => {
                setEndHour(e.target.value);
              }}
              options={hour}
              nomargin
              formTitle={t("Add-private-ground.Check out")}
            />
          </HostWrap>
          <HostWrap
            toolTitle="예약 진행 방식을 선택해주세요."
            toolSubTitle={
              "- 즉시 예약 :  게스트의 예약 요청 시 별도의 호스트 승인없이 자동적으로 예약이 확정되어 관리가 편한 방식입니다. 또한 즉시 예약이 가능한 컨텐츠의 경우 필터링 검색에도 적용되어 더 많은 노출을 기대할 수 있습니다.\n\n- 확인 예약 : 게스트의 예약 요청에 대해 호스트가 설정한 시간 내 직접 승인 또는 거절을   진행할  수  있는   방식입니다.  호스트의   관리가   필요하며   응답률과 응답시간이 중요한 타입입니다."
            }
            host
            help
            title={t("Add-private-ground.Confirmation*")}
          >
            <HostInput
              select
              size="XL"
              selected={confirmValue ? confirmValue : preConfirmValue}
              onChange={(e) => {
                setConfinmValue(e.target.value);
              }}
              selectTrans="Confirmation-data"
              options={Confirmation}
              placeholder="Add-private-ground.Select"
            />
          </HostWrap>
          <HostWrap
            toolTitle="방문일 기준 사전 예약 요청이 가능한 D-day 를 설정해주세요."
            toolSubTitle={
              "현재는 ‘당일 예약 가능’ 또는 ‘당일 예약 불가능’으로 설정할 수 있습니다. (추후 기능 업그레이드 예정\n\n당일 예약 : 게스트가 방문 당일에도 예약이 가능합니다.\n\n사전 요청일 설정 : 게스트가 예약  일정을 선택하는 캘린더에 적용됩니다. Ex) 3 일 설정 – 게스트의 예정 방문일이 6 월 17 일인 경우 6 월 14 일까지는 예약 요청이 가능함"
            }
            host
            help
            title="Add-private-ground.Advance Notice*"
          >
            <HostInput
              select
              selectTrans="Advance-notice-data"
              size="XL"
              selected={advanceValue ? advanceValue : preAdvanceData}
              onChange={(e) => {
                setAdvanceValue(e.target.value);
              }}
              options={Advanced}
              placeholder="Add-private-ground.Select"
            />
          </HostWrap>
          <HostWrap
            toolTitle="게스트가 이용 시 지켜야할 규칙을 빠짐없이 알려주세요."
            toolSubTitle="무엇보다 호스트와 게스트 안전이 우선입니다. 흡연구역이나 화기 사용 등 안전과 관련된 정책을 꼭 명시해주세요. 또한 게스트가 쓰레기를 직접 수거해 가는 것이 기본  원칙이지만  한번  더 명시해준다면  지속적인  관리에  도움이  됩니다. 그 외 매너타임이나 시설 이용과 관련하여 지켜야 할 사항이 있다면 알려주세요."
            host
            help
            title={"Add-private-ground.Additional Rule / Policy"}
          >
            <HostInput
              size="XL"
              inputValue={extraPolicy}
              onChange={(e) => {
                setExtraPolicy(e.target.value);
              }}
              textarea
              inputLengthPosition="right-0"
              inputMaxLength={500}
              placeholder={
                "Please describe additional rule / policy on your Private Ground \n• e.g. No Smoking in the area of private ground. Smoking is available in designated smoking area"
              }
              rows={4}
            />
          </HostWrap>
          <HostWrap
            toolTitle="취소 & 환불 정책 1 개를 선택해주세요."
            toolSubTitle="유연한 취소&환불 정책부터 강력한 정책까지 4 단계로 구분되어 있습니다.  각 단계는 게스트의 취소 요청일을 기준으로 환불 범위가 다르게 적용되어 있으니 호스트님의 운영 방식에 가장 적합한 취소/환불 정책을 확인하여 선택해주세요."
            host
            help
            title="Add-private-ground.Cancellation & Refund Policy*"
            column
          >
            <div className="mt-4">
              {t(
                "Add-private-ground.MUST OUTDOOR will enforce following cancellation policy"
              )}
            </div>
            <HostInput
              disabled
              inputValue={
                policyTitle !== undefined
                  ? t(`Cancellation-policy-data.${changecase(policyTitle)}`) +
                    " " +
                    t("Add-private-ground.Cancellation Policy")
                  : ""
              }
              input
              size="XL"
            />

            <div className="font-bold text-sm mt-4 mb-2">
              {policyTitle
                ? t(`Cancellation-policy-data.${changecase(policyTitle)}`)
                : ""}{" "}
              {t("Add-private-ground.Cancellation Policy")}
            </div>
            <div>
              {rule?.map((item: any, idx: number) => {
                if (policyEn === item?.name) {
                  return (
                    <div key={idx}>
                      {item?.firstRange !== null ? (
                        <div>
                          {t(
                            "Cancellation-policy-data.{{days}}100% refund of the payment",
                            { days: item?.firstRange }
                          )}
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      {item?.secondRange !== null ? (
                        <div>
                          {t(
                            "Cancellation-policy-data.{{days}}70% refund of the payment",
                            { days: item?.secondRange }
                          )}
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      {item?.thirdRange !== null ? (
                        <div>
                          {t(
                            "Cancellation-policy-data.{{days}}50% refund of the payment",
                            { days: item?.thirdRange }
                          )}
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      {item?.fourthRange !== null ? (
                        <div>
                          {t(
                            "Cancellation-policy-data.{{days}}30% refund of the payment",
                            { days: item?.fourthRange }
                          )}
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      {item?.fifthRange !== null ? (
                        <div>
                          {t(
                            "Cancellation-policy-data.{{days}}0% refund of the payment",
                            { days: item?.fifthRange }
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </HostWrap>
        </div>
        <div className="mt-12 mb-8 justify-end flex">
          <Button
            btnText={"SAVE"}
            onClick={() => {
              if (window.confirm("정말 수정하시겠어요?")) {
                modiPoli();
              }
            }}
            className="px-4 py-3 rounded-lg border border-input-border-color text-pure-white bg-tan"
          ></Button>
        </div>
      </div>
      <div className="w-full ml-4"></div>
    </div>
  );
};

export default EditPolicy;
