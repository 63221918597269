import React, { useEffect, useState, useRef } from "react";

import HostWrap from "components/Host/hostWrap";
import HostInput from "elements/HostInput";

import GoogleMap from "components/GoogleMap";

import Geocode from "react-geocode";
import Button from "elements/Button";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/store";
import { locationSetting } from "../../../redux/slice/addPrivateSlice";
import GoogleMapSearch from "components/Host/GoogleMapSearch";
import KakaoMap from "components/Map/KakaoMap";
import DaumAddressSearch from "components/Map/DaumAddressSearch";
import axios from "axios";
import Icons from "elements/Icons";

interface LocationProps {
  onPre?: () => void;
  onNext?: any;
  locationData: any;
}
interface InputItem {
  id: number;
  title: string;
  description?: string;
  photo?: string;
  photoUrl?: string;
}

const LocationSetting = ({ locationData, onNext, onPre}: LocationProps) => {
  const dispatch = useAppDispatch();
  const currentData = useAppSelector((state) => state.privateGround.Location);
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [indicator, setIndicator] = useState({ latitude: 0, longitude: 0 });

  const nextID = useRef<number>(1);
  const [inputItem, setInputItem] = useState<InputItem[]>([
    { id: 0, title: "", description: "", photo: "", photoUrl: "" },
  ]);

  const [googleMapInfo, setGoogleMapInfo] = useState();
  const [addressInfo, setAddressInfo] = useState<any>({
    lng: 0,
    lat: 0,
  });

  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [streetNum, setStreetNum] = useState("");
  const [restAddr, setRestAddr] = useState("");

  const [fullAddress, setFullAddress] = useState("");

  const [howToArea, setHowToArea] = useState("");

  const [store, setStore] = useState({
    name: "",
    disabled: true,
    facility: "CONVENIENCE_STORE",
    distance: "",
  });
  const [market, setMarket] = useState({
    name: "",
    disabled: true,
    facility: "SUPERMARKET",
    distance: "",
  });
  const [toilet, setToilet] = useState({
    name: "",
    disabled: true,
    facility: "TOILET_24HR",
    distance: "",
  });
  const [gas, setGas] = useState({
    name: "",
    disabled: true,
    facility: "GAS_STATION",
    distance: "",
  });
  const [EV, setEV] = useState({
    name: "",
    disabled: true,
    facility: "EV_CHARGING_STATION",
    distance: "",
  });

  Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_MAP_KEY}`);
  Geocode.setLanguage("KO");
  Geocode.setRegion("KO");

  const page = currentData;

  const preTourist = page?.touristSpots !== undefined ? page?.touristSpots : "";

  const touristSpots = inputItem?.map(
    ({ description, title, photo, photoUrl }) => {
      const Obj = {
        description:
          description !== "" ? description : preTourist[0]?.description,
        title: title !== "" ? title : preTourist[0]?.title,
        photo: photo !== "" ? photo : preTourist[0]?.photo,
        photoUrl: photoUrl !== "" ? photoUrl : preTourist[0]?.photoUrl,
      };
      return Obj;
    }
  );

  const nearbyFacilityValue = [
    store.name
      ? { name: store.name, distance: store.distance, facility: store.facility }
      : null,
    market.name
      ? {
          name: market.name,
          distance: market.distance,
          facility: market.facility,
        }
      : null,
    toilet.name
      ? {
          name: toilet.name,
          distance: toilet.distance,
          facility: toilet.facility,
        }
      : null,
    gas.name
      ? { name: gas.name, distance: gas.distance, facility: gas.facility }
      : null,
    EV.name
      ? { name: EV.name, distance: EV.distance, facility: EV.facility }
      : null,
  ];

  const noNull = nearbyFacilityValue.filter((data) => data !== null);
  
  useEffect(() => {
    setAddress(page?.address) 
    setCity(page?.city)
    setHowToArea(page?.direction)
    setZipCode(page?.zipCode)
    setRestAddr(page?.extraAddress)
    setAddressInfo({lng: Number(page?.indicator.longitude), lat: Number(page?.indicator.latitude),})
    setState(page?.state)
    const nearby = page?.nearbyFacilities;
    nearby?.map((item: any, idx: number) => {
      const facil = item.facility;
      const name = item.name;
      const distance = item.distance;
      if (store.facility === facil) {
        setStore({ ...store, name: name, distance: distance });
      } else if (market.facility === facil) {
        setMarket({ ...market, name: name, distance: distance });
      } else if (EV.facility === facil) {
        setEV({ ...EV, name: name, distance: distance });
      } else if (gas.facility === facil) {
        setGas({ ...gas, name: name, distance: distance });
      } else if (toilet.facility === facil) {
        setToilet({ ...toilet, name: name, distance: distance });
      }
    });
    
  },[page?.address, page?.city, page?.direction, page?.extraAddress, page?.indicator, page?.nearbyFacilities, page?.state, page?.zipCode])
  
    const data = {
      nearbyFacilities: [...noNull],
      state:state,
      city: city,
      zipCode: zipCode,
      address: address,
      destinationId: 49,
      extraAddress: restAddr,
      direction:TextareaReplaceNewLine(howToArea),
      indicator:
      addressInfo?.lat !== 0 && addressInfo?.lng !== 0
          ? {latitude: Number(addressInfo?.lat), longitude: Number(addressInfo?.lng)}
          : page?.indicator,
      // country: country ? country : page?.country,
      country: "KR",
      touristSpots,
    };

  const submit = () => {
    Geocode.fromAddress(`${address}`)
      .then((res) => {
        // 경/위도
        const latitude = res?.results[0]?.geometry.location.lat;
        const longitude = res?.results[0]?.geometry.location.lng;
        const lat = String(latitude);
        const lng = String(longitude);
        setIndicator({ latitude: Number(lat), longitude: Number(lng) });
        // 주소 값 가져오기
        const result = res.results[0].address_components;
        setCountry("");
        setState("");
        setCity("");
        setStreet("");
        setStreetNum("");
        setZipCode("");
        result?.map((_item: any, i: any) => {
          if (_item.types[0] === "country") {
            setCountry(_item.short_name);
          } else if (_item.types[0] === "administrative_area_level_1") {
            setState(_item.long_name);
          } else if (_item.types[0] === "locality") {
            setCity(_item.long_name);
          } else if (_item.types[2] === "sublocality_level_1") {
            setCity(_item.long_name);
          } else if (_item.types[2] === "sublocality_level_4") {
            setStreet(_item.long_name);
          } else if (_item.types[2] === "sublocality_level_2") {
            setStreet(_item.long_name);
          } else if (_item.types[0] === "route") {
            setStreet(_item.long_name);
          } else if (_item.types[0] === "premise") {
            setStreetNum(_item.long_name);
          } else if (_item.types[0] === "street_number") {
            setStreetNum(_item.long_name);
          } else if (_item.types[0] === "postal_code") {
            setZipCode(_item.long_name);
          }
        });
        return res;
      })
      .catch((err) => {});
  };

  const handleLocationData = () => {
    if(!state && !city && !address ) {
      return( alert('주소를 입력해주세요.') )
    } 
    dispatch(locationSetting(data));
    onNext();
    locationData(data);
  };

  const addInput = () => {
    const input = {
      id: nextID.current,
      title: "",
      description: "",
      photoUrl: "",
      photo: "",
    };
    if (nextID.current === 3) {
      return alert("최대 3개까지 가능힙나다.");
    }
    setInputItem((pre) => [...pre, input]);
    nextID.current += 1;
  };

  const deleteInput = (idx: number) => {
    setInputItem(inputItem.filter((item) => item.id !== idx));
    nextID.current -= 1;
  };

  const handleFile = async (e: any, idx: number) => {
    let files = e.target.files[0];
    const fileSize = files?.size;
    const data = {
      multipartFiles: files,
    };
    if (fileSize < 1024 ** 2 * 5) {
      const response = await requestToMustOutdoor({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}guest/upload/file`,
        data,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      });
      const photo = `${response.data.data}`;
      const imgUrl = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${photo}`;
      const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(inputItem));
      inputItemCopy[idx].photo = photo;
      inputItemCopy[idx].photoUrl = imgUrl;
      setInputItem(inputItemCopy);
      return response;
    } else {
      alert("파일 사이즈는 5mb 보다 작아야합니다.");
    }
  };
  const hiddenInput = React.useRef<any>(null);
  const handleClick = (e: any) => {
    hiddenInput.current.click();
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (idx > inputItem.length) return;

    const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(inputItem));
    inputItemCopy[idx].title = e.target.value;
    setInputItem(inputItemCopy);
  };

  const handleDesc = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    if (idx > inputItem.length) return;

    const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(inputItem));
    inputItemCopy[idx].description = e.target.value;
    setInputItem(inputItemCopy);
  };

  useEffect(() => {
    const nearby = page?.nearbyFacilities;
    nearby?.map((item: any, idx: number) => {
      const facil = item.facility;
      const name = item.name;
      const distance = item.distance;
      if (store.facility === facil) {
        setStore({ ...store, name: name, distance: distance });
      } else if (market.facility === facil) {
        setMarket({ ...market, name: name, distance: distance });
      } else if (EV.facility === facil) {
        setEV({ ...EV, name: name, distance: distance });
      } else if (gas.facility === facil) {
        setGas({ ...gas, name: name, distance: distance });
      } else if (toilet.facility === facil) {
        setToilet({ ...toilet, name: name, distance: distance });
      }
    });
  }, []);

useEffect(() => {

  const data:any = googleMapInfo;
  let fullAddress = data?.address;
  let extraAddress = '';
  if(data) {
    const state = data?.sido;
    const city = data?.sigungu;
    const roadname = data?.roadname;
    const addressData = data?.roadAddressEnglish.split(",");
    const addressnum = addressData[0]
    const zipCode = data?.zonecode;
    setZipCode(zipCode)
    setState(state)
    setCity(city)
    setAddress(roadname + " " + addressnum )
  }

  if (data?.addressType === 'R') {
  if (data?.bname !== '') {
    extraAddress += data.bname;
    }
  if (data?.buildingName !== '') {
    extraAddress += extraAddress !== '' ? `, ${data?.buildingName}` : data?.buildingName;
    }
     fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    
    setFullAddress(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    const config = { headers: {Authorization : `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`}};
	  const url = 'https://dapi.kakao.com/v2/local/search/address.json?query='+fullAddress;
    axios.get(url, config).then(function(result) { // API호출
      if(result?.data !== undefined || result?.data !== null || result.data.documents.length !== 0){
        if(result?.data?.documents[0]?.x && result?.data?.documents[0]?.y) {
                  // Kakao Local API로 검색한 주소 정보 및 위도, 경도값 저장 
          setAddressInfo({
            lng: result.data.documents[0].x,
            lat: result.data.documents[0].y,
          })
        }
        }
      })
},[googleMapInfo])

  return (
    <div className="web:flex phone:block">
      <div className="web:w-160 phone:mx-4">
        <div className="text-left">
          <HostWrap
            help
            toolTitle="Tooltip-add-private-ground.Location set pg address-tooltip title"
            toolSubTitle="Tooltip-add-private-ground.Location set pg address-tooltip desc"
            host
            title="Add-private-ground.Private Ground Address*"
            column
          >
            <div className="flex items-baseline mt-4">
              <DaumAddressSearch setGoogleMapInfo={setGoogleMapInfo}/>
            </div>
            <HostInput
              input
              size="XL"
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              inputValue={page?.country ? page?.country : "KR"}
              formTitle={t("Add-private-ground.Country / Region")}
              // disabled={country || page?.country ? true : false}
              disabled={true}
            />
            
            {/* <div className="web:flex phone:block">
              <HostInput
                input
                type="text"
                // disabled={state || page?.state ? true : false}
                disabled={true}
                onChange={(e) => {
                  setState(e.target.value);
                }}
                inputValue={data?.state !== "" ? data?.state : page?.state}
                formTitle={t("State")}
                size="M"
              />
              <HostInput
                input
                // disabled={city || page?.city ? true : false}
                disabled={true}
                inputValue={city !== "" ? data?.city : page?.city}
                formTitle={t("City")}
                size="M"
              />
            </div> */}
            <HostInput
              input
              size="XL"
              disabled={true}
              inputValue={fullAddress === undefined ? page?.state + " " + page?.city + " " + page?.address : fullAddress}
              formTitle={t("Add-private-ground.Address")}
            />
            <div className="web:flex phone:block">
              <HostInput
                onChange={(e) => setRestAddr(e.target.value)}
                input
                inputValue={restAddr}
                inputMaxLength={50}
                placeholder="Add-private-ground.Input rest address"
                inputLengthPosition="right-0"
                formTitle={t("Add-private-ground.Rest Address (optional)")}
                size="XL"
              />
              {/* <HostInput
                input
                // disabled={zipCode ? true : false}
                disabled={true}
                inputValue={zipCode ? zipCode : page?.zipCode}
                formTitle={t("Zip Code")}
                size="M"
              /> */}
            </div>
          </HostWrap>
          <div>
            <HostWrap toolTitle="잠깐! 한번 더 확인해주세요."
             toolSubTitle="입력한 주소가 정확한지 한번 더 체크해주세요. 지금 설정된 주소는 이후 호스트가 직접 수정 할 수 없으며, 주소 변경을 원하는 경우 '머스트 아웃도어' 관리자에게 요청이 필요합니다." host title="Add-private-ground.Map View" />
              <KakaoMap AddInfo={addressInfo} />
              {/* <GoogleMapSearch /> */}
              {/* <GoogleMap
                mapLocation={
                  indicator?.latitude !== 0 && indicator?.longitude !== 0
                    ? { lat: indicator?.latitude, lng: indicator?.longitude }
                    : {
                        lat: Number(page?.indicator?.latitude),
                        lng: Number(page?.indicator?.longitude),
                      }
                }
              /> */}
          </div>
          <div>
            <HostWrap 
              help
              toolTitle="Tooltip-add-private-ground.Location set how to get-tooltip title"
              toolSubTitle="Tooltip-add-private-ground.Location set how to get-tooltip desc"
              host
              title="Add-private-ground.How To Get There"
            >
              <HostInput
                textarea
                inputMaxLength={300}
                inputValue={howToArea}
                inputLengthPosition="right-0"
                onChange={(e: any) => {
                  setHowToArea(e.target.value);
                }}
                placeholder="찾아오는 길을 설명해주세요"
                rows={5}
                size="XL"
              />
            </HostWrap>
          </div>
          <div>
            <HostWrap 
              help
              toolTitle="Tooltip-add-private-ground.Location set nearby-tooltip title"
              toolSubTitle="Tooltip-add-private-ground.Location set nearby-tooltip desc"
              host
              title="Add-private-ground.Nearby Facility"
            >
              <div>
                <HostInput
                  nearby
                  checkIcon={"https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/convenience-store.svg"}
                  disabled={store.disabled}
                  inputValue={store.name}
                  kmValue={store.distance}
                  onChange={(e) => {
                    setStore({ ...store, disabled: !e.target.checked });
                  }}
                  distanceOnChange={(e: any) => {
                    setStore({ ...store, distance: e.target.value });
                  }}
                  inputOnChange={(e: any) => {
                    setStore({ ...store, name: e.target.value });
                  }}
                  CheckContent={t("Nearby-facility.Convenience Store")}
                  nomargin
                  size="M"
                />
                <HostInput
                  nearby
                  inputValue={market.name}
                  kmValue={market.distance}
                  disabled={market.disabled}
                  onChange={(e) => {
                    setMarket({ ...market, disabled: !e.target.checked });
                  }}
                  distanceOnChange={(e: any) => {
                    setMarket({ ...market, distance: e.target.value });
                  }}
                  inputOnChange={(e: any) => {
                    setMarket({ ...market, name: e.target.value });
                  }}
                  checkIcon={"https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/supermarket.svg"}
                  CheckContent={t("Nearby-facility.Supermarket")}
                  nomargin
                  size="M"
                />
                <HostInput
                  nearby
                  inputValue={toilet.name}
                  kmValue={toilet.distance}
                  disabled={toilet.disabled}
                  onChange={(e) => {
                    setToilet({ ...toilet, disabled: !e.target.checked });
                  }}
                  inputOnChange={(e: any) => {
                    setToilet({
                      ...toilet,
                      name: e.target.value,
                    });
                  }}
                  distanceOnChange={(e: any) => {
                    setToilet({ ...toilet, distance: e.target.value });
                  }}
                  checkIcon={"https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/24hr-toilet.svg"}
                  CheckContent={t("Nearby-facility.24hr Toilet")}
                  nomargin
                  size="M"
                />
                <HostInput
                  nearby
                  inputValue={gas.name}
                  kmValue={gas.distance}
                  disabled={gas.disabled}
                  onChange={(e) => {
                    setGas({ ...gas, disabled: !e.target.checked });
                  }}
                  inputOnChange={(e: any) => {
                    setGas({ ...gas, name: e.target.value });
                  }}
                  distanceOnChange={(e: any) => {
                    setGas({ ...gas, distance: e.target.value });
                  }}
                  CheckContent={t("Nearby-facility.Gas Station")}
                  checkIcon={"https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/gas-station.svg"}
                  nomargin
                  size="M"
                  />
                <HostInput
                  nearby
                  inputValue={EV.name}
                  kmValue={EV.distance}
                  disabled={EV.disabled}
                  onChange={(e) => {
                    setEV({ ...EV, disabled: !e.target.checked });
                  }}
                  inputOnChange={(e: any) => {
                    setEV({ ...EV, name: e.target.value });
                  }}
                  distanceOnChange={(e: any) => {
                    setEV({ ...EV, distance: e.target.value });
                  }}
                  checkIcon={"https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/ev-charge-station.svg"}
                  CheckContent={t("Nearby-facility.EV Charging Station")}
                  nomargin
                  size="M"
                />
              </div>
            </HostWrap>
          </div>
          <div>
            <HostWrap 
              help
              column
              subClass="phone:flex phone:flex-col-reverse"
              toolTitle="Tooltip-add-private-ground.Location set tour spot-tooltip title"
              toolSubTitle="Tooltip-add-private-ground.Location set tour spot-tooltip desc"
              host
              className=""
              title="Add-private-ground.Recommending Tourist Spot"
              onClick={() => {
                addInput();
              }}
            >
              {inputItem?.map((item, i) => {
                return (
                  <div key={i}>
                    <hr className="mt-4 border-input-text" />
                    {i > 0 ? (
                      <div
                        className="flex justify-end cursor-pointer"
                        onClick={() => {
                          deleteInput(item.id);
                        }}
                      >
                        X
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="pt-2 pb-4 web:flex items-center web:justify-between web:flex-row phone:flex-col">
                      <div>
                        <input
                          accept=".jpg, .jpeg, .png, .pdf"
                          type={"file"}
                          className="hidden"
                          ref={hiddenInput}
                          onChange={(e) => {
                            handleFile(e, i);
                            e.target.value = ""
                          }}
                        />
                        {inputItem[0]?.photoUrl ||
                        page?.touristSpots[0]?.photoUrl ? (
                          <img
                            onClick={(e) => {
                              handleClick(e);
                            }}
                            src={`${
                              inputItem[0]?.photoUrl
                                ? inputItem[i]?.photoUrl
                                : page?.touristSpots[0]?.photoUrl
                            }`}
                            className="web:w-48 mb-2 phone:w-full h-44 rounded-lg object-cover bg-center bg-no-repeat bg-cover"
                            alt=""
                          />
                        ) : (
                          <div
                            onClick={(e) => {
                              handleClick(e);
                            }}
                          >
                            <div className="text-sm mb-2">
                              {t("Add-private-ground.Featured Image")}
                            </div>
                            <div className="web:w-48 phone:w-full h-[141px] rounded-lg border-input-border-color border text-sm justify-center items-center flex flex-col">
                              <Icons
                                className="w-[60px] h-[60px] mb-2"
                                iconName="gallery"
                              />
                              <div>{t(`Add-private-ground.Upload Image`)}</div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="phone:block web:inline-block">
                          <HostInput
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                            inputLengthPosition="right-0"
                            inputMaxLength={50}
                            nomargin
                            notitlemargin
                            input
                            inputValue={
                              inputItem[0].title !== ""
                                ? inputItem[0].title
                                : page?.touristSpots[0].title
                            }
                            formTitle={t("Add-private-ground.Name of Tourist Spot")}
                            size="L"
                            placeholder="Add-private-ground.Name of Tourist Spot"
                          />
                        </div>
                        <HostInput
                          inputLengthPosition="right-0"
                          inputMaxLength={200}
                          inputValue={
                            inputItem[0].description !== ""
                              ? inputItem[0].description
                              : page?.touristSpots[0].description
                          }
                          onChange={(e) => {
                            handleDesc(e, i);
                          }}
                          nomargin
                          textarea
                          rows={3}
                          formTitle={t("Add-private-ground.Description")}
                          size="L"
                          placeholder="Add-private-ground.Description of Tourist Spot"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </HostWrap>
          </div>
        </div>
        <div className="mt-12 mb-8 justify-between flex">
          <Button
            onClick={onPre}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-private-ground.Previous")}
          ></Button>
          <Button
            onClick={() => {
              handleLocationData();
            }}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-private-ground.Continue")}
          ></Button>
        </div>
      </div>
      <div className="ml-4 w-full"></div>
    </div>
  );
};

export default LocationSetting;
