import React, { useEffect } from "react";

interface InquiryModalProps {
  children?: any;
  className?: string;
}

const InquiryModal = ({ children, className }: InquiryModalProps) => {
  useEffect(() => {
    document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);
  return (
    <div
      id="ModalOverlay"
      className="px-2 fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,.8)] box-border z-[10001]"
    >
      <div
        id="Modal"
        className="fixed top-2/4 max-h-[810px] left-2/4 w-full web:px-8 phone:px-4 -translate-y-[50%] items-center -translate-x-[50%]"
      >
        <div className={className}>{children}</div>
      </div>
    </div>
  );
};

export default InquiryModal;
