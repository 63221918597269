import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import HostWrap from "components/Host/hostWrap";

import { Payment } from "../constants";

import HostInput from "elements/HostInput";
import Button from "elements/Button";
import ExtraOption from "elements/ExtraOption";

import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";
import { inputPriceFormat } from "utils/commaDash";

import { useAppDispatch, useAppSelector } from "redux/store";
import { pricing } from "../../../redux/slice/addPrivateSlice";
import AcWrap from "elements/AcWrap";

interface PricingProps {
  onPre?: any;
  onNext?: any;
  PriceData: any;
}

interface InputItem {
  title: string;
  price: string;
  amount: number;
  maxOfNumber: string;
  paymentMethod: string;
  description: string;
}

const Pricing = ({ onPre, onNext, PriceData }: PricingProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const currentData = useAppSelector((state) => state.privateGround.Pricing);

  const nextID = useRef<number>(1);
  const [extraOption, setExtraOption] = useState<InputItem[]>([
    {
      amount: 0,
      title: "",
      price: "",
      maxOfNumber: "",
      paymentMethod: "",
      description: "",
    },
  ]);

  const [extraOptionData, setExtraOptionData] = useState<{
    amount: number;
    description: string;
    maxOfNumber: number;
    paymentMethod: string;
    price: string;
    title: string;
  }>({
    amount: 0,
    description: "",
    maxOfNumber: 0,
    paymentMethod: "",
    price: "",
    title: "",
  });

  const page = currentData;

  const [mon, setMon] = useState({ price: "", disabled: true });
  const [tue, setTue] = useState({ price: "", disabled: true });
  const [wed, setWed] = useState({ price: "", disabled: true });
  const [thu, setThu] = useState({ price: "", disabled: true });
  const [fri, setFri] = useState({ price: "", disabled: true });
  const [sat, setSat] = useState({ price: "", disabled: true });
  const [sun, setSun] = useState({ price: "", disabled: true });

  const extraValue = page?.extraOption[0];

  useEffect(() => {
    setExtraOptionData({
      amount: 0,
      description: extraValue?.description ? extraValue?.description : "",
      maxOfNumber: extraValue?.maxOfNumber ? extraValue?.maxOfNumber : "",
      paymentMethod: extraValue?.paymentMethod ? extraValue?.paymentMethod : "",
      price: extraValue?.price ? extraValue?.price : "",
      title: extraValue?.title ? extraValue?.title : "",
    });
  }, [page]);

  const extraOptionResult = [
    {
      amount: 0,
      description: extraOptionData?.description,
      maxOfNumber: extraOptionData?.maxOfNumber,
      paymentMethod: extraOptionData?.paymentMethod,
      price: extraOptionData?.price,
      title: extraOptionData?.title,
    },
  ];

  useEffect(() => {
    setMon({
      ...mon,
      price: page?.priceInfo?.mon,
    });
    setTue({
      ...tue,
      price: page?.priceInfo?.tue,
    });
    setWed({
      ...wed,
      price: page?.priceInfo?.wed,
    });
    setThu({
      ...thu,
      price: page?.priceInfo?.thu,
    });
    setFri({
      ...fri,
      price: page?.priceInfo?.fri,
    });
    setSat({
      ...sat,
      price: page?.priceInfo?.sat,
    });
    setSun({
      ...sun,
      price: page?.priceInfo?.sun,
    });
  }, [page?.priceInfo]);

  // const addInput = () => {
  //   const input = {
  //     id: nextID.current,
  //     title: "",
  //     price: "",
  //     amount: 0,
  //     maxOfNumber: "",
  //     paymentMethod: "",
  //     description: "",
  //   };
  //   if (nextID.current === 10) {
  //     return alert("최대 10개까지 가능힙나다.");
  //   }
  //   setInputItem([...extraOption, input]);
  //   nextID.current += 1;
  // };
  // const deleteInput = (idx: number) => {
  //   setInputItem(extraOption.filter((item) => item.id !== idx));
  //   nextID.current -= 1;
  // };

  // const handleTitle = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
  //   if (idx > extraOption.length) return;

  //   const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(extraOption));
  //   inputItemCopy[idx].title = e.target.value;
  //   setInputItem(inputItemCopy);
  // };

  // const handlePrice = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
  //   if (idx > extraOption.length) return;

  //   const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(extraOption));
  //   const Number = e.currentTarget.value.toString();

  //   inputItemCopy[idx].price = e.target.value;
  //   setInputItem(inputItemCopy);
  // };

  // const handlemaxOfNum = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   idx: number
  // ) => {
  //   if (idx > extraOption?.length) return;

  //   const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(extraOption));
  //   inputItemCopy[idx].maxOfNumber = String(e.target.value);
  //   setInputItem(inputItemCopy);
  // };

  // const handlePayment = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   idx: number
  // ) => {
  //   if (idx > extraOption?.length) return;

  //   const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(extraOption));
  //   inputItemCopy[idx].paymentMethod = e.target.value;
  //   setInputItem(inputItemCopy);
  // };

  // const handleDesc = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
  //   if (idx > extraOption?.length) return;

  //   const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(extraOption));
  //   inputItemCopy[idx].description = e.target.value;
  //   setInputItem(inputItemCopy);
  // };

  // const extra = extraOption?.map(
  //   (
  //     { description, maxOfNumber, paymentMethod, price, title, amount },
  //     key
  //   ) => {
  //     const Obj = {
  //       description: description !== undefined
  //         ? TextareaReplaceNewLine(description)
  //         : page?.extraOption[0].description,
  //       maxOfNumber: maxOfNumber !== undefined
  //         ? maxOfNumber
  //         : page.extraOption[0].maxOfNumber,
  //       paymentMethod: paymentMethod !== undefined
  //         ? paymentMethod
  //         : page.extraOption[0].paymentMethod,
  //       price: price !== undefined ? price : inputPriceComma(page.extraOption[0].price),
  //       title: title !== undefined ? title : page.extraOption[0].title,
  //       amount: amount !== undefined ? amount : page.extraOption[0].amount,
  //     };
  //     return Obj;
  //   }
  // );
  const data = {
    extraOption: extraOptionResult,
    priceInfo: {
      mon: mon.price,
      thu: thu.price,
      wed: wed.price,
      tue: tue.price,
      fri: fri.price,
      sat: sat.price,
      sun: sun.price,
    },
  };
  const handlePriceData = () => {
    if (
      mon?.price ||
      thu?.price ||
      wed?.price ||
      tue?.price ||
      fri?.price ||
      sat?.price ||
      sun?.price ||
      page?.priceInfo.sun ||
      page?.priceInfo.mon ||
      page?.priceInfo.thu ||
      page?.priceInfo.wed ||
      page?.priceInfo.tue ||
      page?.priceInfo.fri ||
      page?.priceInfo.sat ||
      page?.priceInfo.sun
    ) {
      dispatch(pricing(data));
      onNext();
      PriceData(data);
    } else alert("요금을 설정해주세요.");
  };

  const Prev = () => {
    onPre();
  };

  return (
    <div className="web:flex phone:block">
      <div className="web:w-160 web:mx-0 phone:mx-4">
        <div className="text-left">
          <div>
            <HostWrap
              help
              column
              toolTitle="Tooltip-add-private-ground.Operation price-tooltip title"
              toolSubTitle="Tooltip-add-private-ground.Operation price-tooltip desc"
              title="Add-private-ground.Operation & Regular Price*"
              host
            >
              <HostInput
                inputValue={sun.price}
                disabled={sun.disabled}
                onChange={(e) => {
                  setSun({
                    ...sun,
                    disabled: !e.target.checked,
                  });
                }}
                inputOnChange={(e) =>
                  setSun({ ...sun, price: inputPriceFormat(e.target.value) })
                }
                pricing
                CheckContent={t("Days-data.Sunday")}
              />
              <HostInput
                inputValue={mon.price}
                onChange={(e) =>
                  setMon({
                    ...mon,
                    disabled: !e.target.checked,
                  })
                }
                inputOnChange={(e) => {
                  setMon({ ...mon, price: inputPriceFormat(e.target.value) });
                }}
                disabled={mon.disabled}
                pricing
                CheckContent={t("Days-data.Monday")}
              />
              <HostInput
                inputValue={tue.price}
                onChange={(e) =>
                  setTue({
                    ...tue,
                    disabled: !e.target.checked,
                  })
                }
                inputOnChange={(e) => {
                  setTue({ ...tue, price: inputPriceFormat(e.target.value) });
                }}
                disabled={tue.disabled}
                pricing
                CheckContent={t("Days-data.Tuesday")}
              />
              <HostInput
                inputValue={wed.price}
                onChange={(e) =>
                  setWed({
                    ...wed,
                    disabled: !e.target.checked,
                  })
                }
                inputOnChange={(e) =>
                  setWed({ ...wed, price: inputPriceFormat(e.target.value) })
                }
                disabled={wed.disabled}
                pricing
                CheckContent={t("Days-data.Wednesday")}
              />
              <HostInput
                inputValue={thu.price}
                onChange={(e) =>
                  setThu({
                    ...thu,
                    disabled: !e.target.checked,
                  })
                }
                inputOnChange={(e) => {
                  setThu({ ...thu, price: inputPriceFormat(e.target.value) });
                }}
                disabled={thu.disabled}
                pricing
                CheckContent={t("Days-data.Thursday")}
              />
              <HostInput
                inputValue={fri.price}
                onChange={(e) =>
                  setFri({ ...fri, disabled: !e.target.checked })
                }
                inputOnChange={(e) => {
                  setFri({ ...fri, price: inputPriceFormat(e.target.value) });
                }}
                disabled={fri.disabled}
                pricing
                CheckContent={t("Days-data.Friday")}
              />
              <HostInput
                inputValue={sat.price}
                onChange={(e) =>
                  setSat({ ...sat, disabled: !e.target.checked })
                }
                inputOnChange={(e) => {
                  setSat({ ...sat, price: inputPriceFormat(e.target.value) });
                }}
                disabled={sat.disabled}
                pricing
                CheckContent={t("Days-data.Saturday")}
              />
            </HostWrap>
          </div>
          <div>
            <HostWrap
              help
              toolTitle="Tooltip-add-private-ground.Operation extra option-tooltip title"
              toolSubTitle="Tooltip-add-private-ground.Operation extra option-tooltip desc"
              column
              // onClick={() => {
              //   addInput();
              // }}
              host
              title="Add-private-ground.Extra Options"
              // addBtn
              // addBtnTitle={"More"}
            >
              {/* <ExtraOption
                preData={extraOption}
                setExtraOption={setExtraOption}
              ></ExtraOption> */}
              <div>
                <hr className="text-input-text" />
                <div className="w-full mt-8">
                  <div className="flex web:flex-row phone:flex-col items-center justify-between text-sm">
                    <div className="web:w-76 phone:w-full">
                      <div>{t("Add-accommodation.Name of extra option")}</div>
                      <input
                        value={
                          extraOptionData?.title ? extraOptionData?.title : ""
                        }
                        name="title"
                        onChange={(e) => {
                          setExtraOptionData({
                            ...extraOptionData,
                            title: e.target.value,
                          });
                        }}
                        className="extraInput"
                        type="text"
                      />
                    </div>
                    <div className="phone:mt-2 web:mt-0 web:w-76 phone:w-full">
                      <div>{t("Add-private-ground.Price (Per 1 item)")}</div>
                      <input
                        value={
                          extraOptionData.price ? extraOptionData.price : ""
                        }
                        onChange={(e) => {
                          setExtraOptionData({
                            ...extraOptionData,
                            price: inputPriceFormat(e.target.value),
                          });
                        }}
                        onWheel={(e: any) => e.target.blur()}
                        name="price"
                        className="extraInput"
                      />
                    </div>
                  </div>
                  <div className="flex web:flex-row phone:flex-col items-center mt-4 justify-between text-sm">
                    <div className="web:w-76 phone:w-full">
                      <div>{t("Add-accommodation.Payment Method")}</div>
                      <select
                        value={
                          extraOptionData?.paymentMethod
                            ? extraOptionData?.paymentMethod
                            : ""
                        }
                        onChange={(e) => {
                          setExtraOptionData({
                            ...extraOptionData,
                            paymentMethod: e.target.value,
                          });
                        }}
                        name="paymentMethod"
                        className="extraInput"
                      >
                        <option value="">
                          {t("Add-accommodation.Select")}
                        </option>
                        <option value={"AVAILABLE_IN_EXTRA_OPTION"}>
                          {t("Extra-payment-method.Available in Extra Option")}
                        </option>
                        <option value={"On-site Payment"}>
                          {t("Extra-payment-method.On-site Payment")}
                        </option>
                      </select>
                    </div>
                    <div className="phone:mt-2 web:mt-0 web:w-76 phone:w-full">
                      <div>{t("Add-private-ground.Max of Number")}</div>
                      <input
                        value={
                          extraOptionData.maxOfNumber
                            ? extraOptionData.maxOfNumber
                            : ""
                        }
                        onChange={(e) => {
                          setExtraOptionData({
                            ...extraOptionData,
                            maxOfNumber: Number(e.target.value),
                          });
                        }}
                        onWheel={(e: any) => e.target.blur()}
                        name="maxOfNumber"
                        className="extraInput"
                        type="number"
                      />
                    </div>
                  </div>
                  <AcWrap
                    name="description"
                    tooltipHidden
                    inputValue={
                      extraOptionData?.description
                        ? extraOptionData?.description
                        : ""
                    }
                    onChange={(e) => {
                      setExtraOptionData({
                        ...extraOptionData,
                        description: e.target.value,
                      });
                    }}
                    placeholder="Add-accommodation.Please describe inclusion of extra option for your traveler"
                    miniTitle="Add-private-ground.Description"
                    textArea
                  />
                </div>
              </div>
            </HostWrap>
          </div>
        </div>
        <div className="mt-12 mb-8 justify-between flex">
          <Button
            onClick={() => {
              Prev();
            }}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-private-ground.Previous")}
          />
          <Button
            onClick={() => {
              handlePriceData();
            }}
            className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
            btnText={t("Add-private-ground.Continue")}
          />
        </div>
      </div>
      <div className="w-full ml-4"></div>
    </div>
  );
};

export default Pricing;
