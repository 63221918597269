import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";



import { ACtab, Membertab, Pgtab } from "./constants";

import { useAppDispatch } from "redux/store";
import { logout } from "redux/slice/userSlice";
import ToastAlert from "elements/ToastAlert";
interface HeaderAccountProps {
  onClick?: () => void;
  className?: string;
  isMember?: boolean;
  isHost?: boolean;
  isAC?: boolean;
}

const HeaderAccount = ({
  className,
  isMember,
  isHost,
  isAC,
  onClick,
}: HeaderAccountProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [logOutToastAlert, setLogOutToastAlert] = useState(false);

  return (
    <>
      {logOutToastAlert ? (
        <ToastAlert
          text={"😄 로그아웃 되었습니다."}
          type="green"
          setToastState={setLogOutToastAlert}
        />
      ) : (
        ""
      )}
      {isMember ? (
        <div className={`${className} shadow-card`}>
          {Membertab.map(({ nav, title }) => {
            return (
              <div
                onClick={() => {
                  if (title === "Log out") {
                    setLogOutToastAlert(true);
                    dispatch(logout());
                    navigate("/", { replace: true });
                  } else {
                    navigate(`/${nav}`);
                  }
                }}
                key={title}
                className={`flex text-sm items-center p-4 cursor-pointer z-100 hover:text-light-green hover:opacity-70`}
              >
                <div>{t(`Menu.${title}`)}</div>
              </div>
            );
          })}
        </div>
      ) : isHost ? (
        <>
          <div onClick={onClick} className={`${className} shadow-card`}>
            {Pgtab.map(({ nav, title }) => {
              return (
                <div
                  onClick={() => {
                    if (title === "Log out") {
                      setLogOutToastAlert(true);
                      dispatch(logout());
                      navigate("/", { replace: true });
                    } else {
                      navigate(`/${nav}`);
                    }
                  }}
                  key={title}
                  className={`flex text-sm items-center p-4 cursor-pointer z-100 hover:text-light-green hover:opacity-70`}
                >
                  <div>{t(`Menu.${title}`)}</div>
                </div>
              );
            })}
          </div>
        </>
      ) : isAC ? (
        <>
          <div onClick={onClick} className={`${className} shadow-card`}>
            {ACtab.map(({ nav, title }) => {
              return (
                <div
                  onClick={() => {
                    if (title === "Log out") {
                      setLogOutToastAlert(true);
                      dispatch(logout());
                      navigate("/", { replace: true });
                    } else {
                      navigate(`/${nav}`);
                    }
                  }}
                  key={title}
                  className={`flex text-sm items-center p-4 cursor-pointer z-100 hover:text-light-green hover:opacity-70`}
                >
                  <div>{t(`Menu.${title}`)}</div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default HeaderAccount;
