import React, { useEffect, useState } from "react";

import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import HostBookingUpcoming from "components/Host/hostBookingUpcoming";
import HostBookingCompleted from "components/Host/hostBookingCompleted";
import HostBookingCancelled from "components/Host/hostBookingCancelled";
import MoblieHeader from "components/moblieHeader";
import { useTranslation } from "react-i18next";
import { isLogin } from "utils/roleStatus";
import { useNavigate } from "react-router-dom";

const BookingStatus = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();

  useEffect(() => {
    sessionStorage.setItem("nextPath", "/host/book");
    if(!isLogin()) {
      navigate("/log-in", {state:{nextPath: "/host/book"}})
    }
  },[])

  const [tabNum, setTabNum] = useState(0);
  const tabInfo = [
    { id: 0, title: "Upcoming" },
    { id: 1, title: "Completed" },
    { id: 2, title: "Cancelled" },
  ];
  const ChangeTab = (id: number) => {
    setTabNum(id);
  };
  return (
    <div>
      <MoblieHeader left center="Menu.Booking Status" centerTitleImage="mobile-bookings" />
      <div className="text-center mx-auto web:pt-8 web:px-8 phone:px-0 phone:pt-0 pb-[100px] lg:max-w-1216">
        <div className="flex">
          <HostAccount />
          <HostAccountHeader
            titleIcon="mobile-bookings"
            title="Menu.Booking Status"
          >
            <div className="border-b w-full h-11 flex">
              {tabInfo.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      ChangeTab(item.id);
                    }}
                    className={`${
                      item.id === tabNum
                        ? "bg-light-green text-pure-white"
                        : " text-input-text"
                    } cursor-pointer px-4 items-center flex hover:bg-light-green focus:bg-light-green rounded-t-lg hover:text-pure-white`}
                  >
                    {t(`Booking-box-status.${item.title}`)}
                  </div>
                );
              })}
            </div>
            {tabNum === 0 ? (
              <HostBookingUpcoming />
            ) : tabNum === 1 ? (
              <HostBookingCompleted />
            ) : tabNum === 2 ? (
              <HostBookingCancelled />
            ) : (
              ""
            )}
          </HostAccountHeader>
        </div>
      </div>
    </div>
  );
};

export default BookingStatus;
