import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import HostWrap from "components/Host/hostWrap";

import Button from "elements/Button";
import HostInput from "elements/HostInput";

import { unitNumber, Vehicle } from "pages/Register/constants";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import CheckBoxDefault from "elements/CheckBoxDefault";
interface editGeneData {
  title?: string;
  description?: string;
  type?: [];
  unitCount?: number;
  locationThemes?: [];
  accessibleVehicle?: string;
  spaceSize?: string;
}
interface editGeneProps {
  id?: number;
  geneEditData?: editGeneData | any;
}

const EditGeneralInfo = ({ id, geneEditData }: editGeneProps) => {
  const { t } = useTranslation();

  const [types, setTypes] = useState([]);
  const [themes, setThemes] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [unitNum, setUnitNum] = useState(0);
  const [accessibleVehicle, setVehicle] = useState("");
  const [accessibleVehicleName, setVehicleName] = useState("");
  const [spaceSize, setSpaceSize] = useState("");

  const [type, setType] = useState<any>("");
  const [locationTheme, setLocationThemeList] = useState<any>([]);
  const hostNum = localStorage.getItem("hostNum");

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/private-ground/fetch/icons`,
    })
      .then((res) => {
        const data = res.data.data;
        setTypes(data.types);
        setThemes(data.themes);
      })
      .catch((err) => {});
  }, []);

  const {
    preTitle,
    preDescription,
    preType,
    preUnitCount,
    preLocationThemeList,
    preAccessibleVehicle,
    preSpaceSize,
  } = geneEditData;

  const themeValue = preLocationThemeList?.map((item: any) => {
    const value = item.themeInfo;
    return value.id;
  });

  const handleType = (checked: string, item: number, e: any) => {
    if (checked && !type) {
      const result = Number(item);
      setType(result);
    } else if (!checked) {
      setType(0);
    }
    if (type && checked) {
      alert("1개만 선택 가능합니다");
      e.target.checked = false;
    }
  };

  const LocationTheme = (checked: string, item: number, e: any) => {
    if (checked) {
      const result = Number(item);
      setLocationThemeList([...locationTheme, result]);
    } else if (!checked) {
      const result = Number(item);
      setLocationThemeList(locationTheme.filter((el: any) => el !== result));
    }
    if (locationTheme.length > 2 && checked) {
      const result = Number(item);
      alert("최대 3개까지 선택 가능합니다");
      setLocationThemeList(locationTheme.filter((el: any) => el !== result));
      e.target.checked = false;
    }
  };

  const unitCount = Number(unitNum);

  useEffect(() => {
    let AV = preAccessibleVehicle;
    if (AV === "Only Backpacker") {
      AV = "BACKPACKER";
    } else if (AV === "Up to compact / light size") {
      AV = "AV1";
    } else if (AV === "Up to mid / large size") {
      AV = "AV2";
    } else if (AV === "Up to compact RV (3 pax)") {
      AV = "AV3";
    } else if (AV === "Up to mid / large RV (5 pax)") {
      AV = "AV4";
    } else if (AV === "Small/mid Caravan or Trailer(3 pax)") {
      AV = "AV5";
    } else if (AV === "Large Caravan or Trailer (5 pax)") {
      AV = "AV6";
    }
    setTitle(preTitle);
    setDescription(preDescription);
    setType(preType?.id);
    setUnitNum(preUnitCount);
    setVehicleName(AV);
    setVehicle(preAccessibleVehicle);
    setSpaceSize(preSpaceSize);
    setLocationThemeList(themeValue);
  }, [
    preAccessibleVehicle,
    preDescription,
    preType,
    preSpaceSize,
    preLocationThemeList,
    preTitle,
    preUnitCount,
  ]);

  const handleSpaceSize = (e: any) => {
    const currnetSpaceSize = Number(e.target.value);
    setSpaceSize(currnetSpaceSize.toFixed(2));
  };
  const modiGene = () => {
    requestToMustOutdoor({
      method: "PATCH",
      url: `/api/v2/private-ground/${id}`,
      data: { ...currentData, hostId: hostNum },
    })
      .then((res) => {
        // window.location.reload();
        console.log(res)
        return res;
      })
      .catch((err) => {});
  };

  const currentData = {
    title: title,
    description: description,
    typeId: type,
    unitCount: unitCount,
    themeIds: locationTheme,
    accessibleVehicle: accessibleVehicleName,
    spaceSize: spaceSize,
    hostId: Number(hostNum),
  };

  console.log(currentData)

  const handleVehicle = (e: any) => {
    let AV = e.target.value;
    if (AV === "Only Backpacker") {
      AV = "BACKPACKER";
    } else if (AV === "Up to compact / light size") {
      AV = "AV1";
    } else if (AV === "Up to mid / large size") {
      AV = "AV2";
    } else if (AV === "Up to compact RV (3 pax)") {
      AV = "AV3";
    } else if (AV === "Up to mid / large RV (5 pax)") {
      AV = "AV4";
    } else if (AV === "Small/mid Caravan or Trailer(3 pax)") {
      AV = "AV5";
    } else if (AV === "Large Caravan or Trailer (5 pax)") {
      AV = "AV6";
    }
    setVehicleName(AV);
    setVehicle(e.target.value);
  };

  return (
    <div className="web:flex phone:block">
      <div className="web:w-160 web:mx-0 phone:mx-4">
        <div className="text-left">
          <HostWrap
            help
            host
            toolTitle="야외 공간 이름을 한문장으로 표현해주세요"
            toolSubTitle={
              "게스트들에게 가장 먼저 그리고 많이 노출되는 부분입니다. 게스트들이 클릭하지 않고는 그냥 지나칠 수 없도록 매력적인 이름을 지어 주세요."
            }
            toolEx={
              "Ex) 멋진 노을과 드넓은 논 뷰가 펼쳐지는 마당, 와이너리 투어가 있는 포도밭 정원"
            }
            title="Add-private-ground.Title*"
          >
            <HostInput
              input
              inputValue={preTitle}
              inputMaxLength={50}
              inputLengthPosition="right-0"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              size="XL"
              placeholder="Add-private-ground.Name of Private Ground"
            />
          </HostWrap>
          <HostWrap
            help
            host
            toolTitle={"나만의 야외 공간을 자랑해주세요."}
            toolEx="(비속어/욕설 입력금지, 개인정보 노출 주의)"
            toolSubTitle="넓은 잔디 마당,  멋진 풍경,  우리 로컬의 분위기 등 게스트가 호스트님의 야외 공간에 꼭 방문해보고 싶은 충동을 느낄 수 있도록 매력적으로 표현해주세요."
            title={"Add-private-ground.Description*"}
          >
            <HostInput
              inputMaxLength={500}
              inputValue={preDescription}
              textarea
              inputLengthPosition="right-0"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              size="XL"
              rows={5}
              placeholder="Add-private-ground.Describe the Private Ground for travelers"
            />
          </HostWrap>
          <HostWrap
            help
            host
            title="Add-private-ground.Private Ground Type*"
            toolTitle={"야외 공간은 어떤 타입인가요?\n(최대 1 개)"}
            toolSubTitle="야외 공간 타입 선택은 게스트들이 원하는 타입을 쉽고 빠르게 찾을 수 있는 필터링
                              검색과 연계되는 부분입니다.  호스트님이 공유하실 야외 공간의 특징을 가장 잘
                              표현하고 대표적인 키워드가 될 수 있는 타입으로 선택해주세요."
          >
            <div
              className={`flex web:flex-row phone:flex-col flex-wrap text-sm mt-4`}
            >
              {types?.map((item: any, idx) => {
                const iconUrl = item.iconUrl;
                const enName = item.enName;
                const id = item.id;
                if (preType) {
                  return (
                    <CheckBoxDefault
                      key={idx}
                      id={id}
                      imageUrl={iconUrl}
                      defaultChecked={
                        (preType?.id === id) === true ? true : false
                      }
                      onChange={(e: any) => {
                        handleType(e.target.checked, e.target.id, e);
                      }}
                      title={`Private-Type-data.${enName}`}
                      value={enName}
                    />
                  );
                }
              })}
            </div>
          </HostWrap>
          <HostWrap
            help
            host
            toolTitle="공간의 수용 가능 범위를 설정해 주세요."
            toolSubTitle={
              "유닛 수: 하루 동시에 예약을 받을 수 있는 최대 수량을 설정 할 수 있습니다.\n차량 1 대 기준, RV 주차 또는 차박이 가능한 야외 공간 크기를 가늠하여 설정해주세요.\n\n접근 가능 차량: 접근 가능한 차량을 크기, 그리고 하루 최대 예약 수량을 고려하여 설정해주세요.\n\n공간 크기: 차량  1 대 기준 사용 가능한 야외 공간의 크기를 입력해주세요."
            }
            toolEx={
              "Ex) 대형 RV 접근 가능으로 예약 수량(공간 수)을 3개로 설정하거나 소형 차량 접근 가능으로 예약 수량을 5 개로 설정할 수 있습니다."
            }
            title="Add-private-ground.Availability*"
          >
            <div className="flex web:flex-row phone:flex-col">
              <HostInput
                selected={String(unitCount)}
                formTitle={t("Add-private-ground.Number of Unit")}
                select
                onChange={(e) => setUnitNum(e.target.value)}
                options={unitNumber}
                size={"S"}
                placeholder="Add-private-ground.Select"
              />
              <HostInput
                select
                selectTrans="Parking-accessible-data"
                selected={accessibleVehicle}
                onChange={handleVehicle}
                options={Vehicle}
                formTitle={t("Add-private-ground.Accessible Vehicle")}
                size="S"
                placeholder="Add-private-ground.Select"
              />
              <HostInput
                inputOnText
                inputOnTextValue="m²"
                type="number"
                inputMaxLength={3}
                inputLengthPosition="right-0 bottom-[-27px]"
                onChange={(e) => handleSpaceSize(e)}
                inputValue={spaceSize}
                input
                nomargin
                inputOnTextPosition="right-2 top-[24px]"
                className="text-right pr-8 mt-4"
                formTitle={t("Add-private-ground.Ground Size")}
                size="S"
              />
            </div>
          </HostWrap>
          <HostWrap
            help
            host
            title="Add-private-ground.Location Theme*"
            subTitle="Add-private-ground.(Choose only upto 3 themes)"
            toolTitle={
              "야외 공간에서는 어떤 뷰를 기대할 수 있나요?\n(최대 3 개)"
            }
            toolSubTitle="논,  밭이 보이는 시골풍경,  탁 트인 해변가 등과 같은 지형 타입과 주변 풍경은
                게스트가 방문지 선택 시 아주 중요한 요소로써 필터링 검색기능과 연계되는 부분
                입니다.  공유하는 야외 공간의 주변 지형 및 풍경 등을 고려하여 가장 대표적인
                테마를 선택해주세요."
          >
            <div
              className={`flex web:flex-row phone:flex-col flex-wrap text-sm mt-4`}
            >
              {themes?.map((item: any, idx: number) => {
                const iconUrl = item.iconUrl;
                const enName = item.enName;
                const id = item.id;
                if (themeValue) {
                  return (
                    <CheckBoxDefault
                      key={idx}
                      id={id}
                      imageUrl={iconUrl}
                      defaultChecked={
                        themeValue?.includes(id) === true ? true : false
                      }
                      onChange={(e: any) => {
                        LocationTheme(e.target.checked, e.target.id, e);
                      }}
                      title={`Theme-data.${enName}`}
                      value={enName}
                    />
                  );
                }
              })}
            </div>
          </HostWrap>
          <div className="mt-12 mb-8 justify-end flex">
            <Button
              onClick={() => {
                if (window.confirm("정말 수정하시겠어요?")) {
                  modiGene();
                }
              }}
              btnText={"Add-private-ground.Save"}
              className="px-4 py-3 rounded-lg border border-input-border-color text-pure-white bg-tan"
            ></Button>
          </div>
        </div>
      </div>
      <div className="web:block w-full ml-4"></div>
    </div>
  );
};

export default EditGeneralInfo;
