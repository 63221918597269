import FaqCard from "components/Info/faqCard";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

const FaqDetail = () => {
  const { t } = useTranslation();
  const location: any = useLocation();
  const stateNum: number = location.state;
  const [tabNum, setTabNum] = useState(0);
  const [changeTab, setChangeTab] = useState(0);
  const [categoryValue, setCategoryValue] = useState<any>([]);
  const travelerTab = [
    {
      id: 1,
      title: "Booking Process",
    },
    {
      id: 4,
      title: "Payment Process",
    },
    {
      id: 2,
      title: "Service related",
    },
  ];
  const hostTab = [
    {
      id: 5,
      title: "Create Outdoor Life Items",
    },
    {
      id: 3,
      title: "Hosting Tips",
    },
    {
      id: 8,
      title: "Booking Process",
    },
    {
      id: 6,
      title: "Payout Process",
    },
  ];

  useEffect(() => {
    if (stateNum) {
      setChangeTab(stateNum);
    }
  }, [stateNum]);

  useEffect(() => {
    if (changeTab !== 0) {
      requestToMustOutdoor({
        method: "GET",
        url: `/guest/faq/category/${changeTab}`,
      }).then((res) => {
        const dataValue = res.data.data;
        setCategoryValue(dataValue);
      });
    }
  }, [changeTab]);

  return (
    <div>
      <div className="w-full h-[320px] bg-faq bg-cover text-pure-white justify-center text-5xl font-bold flex items-center">
        <div>FAQ</div>
      </div>
      <div className="py-8 web:max-w-1216 xl:max-w-1280 px-8 mx-auto">
        <div className="flex web:flex-row phone:flex-col">
          <div className="web:w-[224px] phone:w-full web:justify-start phone:justify-between web:mr-16 phone:mr-0 flex web:flex-col phone:flex-row phone:mb-4">
            <div>
              <div className="font-bold text-xl mb-4">
                {t("faq.For Traveler")}
              </div>
              {travelerTab.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      setChangeTab(item.id);
                    }}
                    className={`${
                      changeTab === item.id ? "font-bold text-main-green" : ""
                    } cursor-pointer text-input-text mb-4 last:mb-0`}
                  >
                    {t(`faq.${item.title}`)}
                  </div>
                );
              })}
            </div>
            <hr className="text-input-text my-4" />
            <div>
              <div className="font-bold text-xl mb-4">
                {t("faq.For Partner")}
              </div>
              {hostTab.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      setChangeTab(item.id);
                    }}
                    className={`${
                      changeTab === item.id ? "font-bold text-main-green" : ""
                    } cursor-pointer text-input-text mb-4 last:mb-0`}
                  >
                    {t(`faq.${item.title}`)}
                  </div>
                );
              })}
            </div>
          </div>
          <hr className="my-4 border-input-border-color" />
          <div className="w-full">
            {categoryValue?.map((item: any, idx: number) => {
              return (
                <FaqCard title={item.title} content={item.content} key={idx} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqDetail;
