export const isLogin = () => {
    return !!localStorage.getItem("isLogin");
}

export const HostMember = () => {
    const status = localStorage.getItem("STATUS");
    const role = localStorage.getItem("role");

    return status === "MEMBER" && role === "HOST"
}
export const HostHost = () => {
    const status = localStorage.getItem("STATUS");
    const role = localStorage.getItem("role");

    return status === "HOST" && role === "HOST"
}