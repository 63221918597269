import React, { useState } from "react";

import HeadLogo from "components/Auth/authLogo";
import AuthUtil from "components/Auth/AuthUtil";
import { useTranslation } from "react-i18next";
import Input from "elements/Input";
import Button from "elements/Button";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useNavigate } from "react-router-dom";

const ForgetPasswordVerfication = () => {
   const navigate = useNavigate(); 
  const { t } = useTranslation();

  const [code ,setCode] = useState("");

  const email = localStorage.getItem("email");

  const handlePasswordVerifyCode = () => {
    requestToMustOutdoor({
        method:"POST",
        url:`/auth/forgot/password?email=${email}&code=${code}`
    })
    .then((res) => {
        navigate('/log-in/reset-pass')
        return res.data.data
    })
    .catch((err) => {
        console.log(err)
    })
  }

  return (
    <div>
      <HeadLogo>
        <AuthUtil
          AuthIcon={"forgot-password"}
          title={t("Please enter the reset password code")}
          subTitle={"We sent you a reset password code in your email account"}
          email={email}
          content={
            "Please enter the code you received below to reset your passowrd"
          }
        />
        <Input
        onChange={(e) => {setCode(e.target.value)}}
          isIcon
          inputIcon={"email-address"}
          placeholder={t("Account.Reset password code")}
          type={""}
        />
        <div className="flex justify-center mt-2 text-pure-white">
          <div>{t(`Account.Still can’t find the email?`)} </div>
          <div className="underline cursor-pointer">
            {t(`Account.Resend Email`)}
          </div>
        </div>
        <div className="text-base text-pure-white">
          <Button
          onClick={() => {handlePasswordVerifyCode()}}
            className="inline-block mx-auto mt-2 mb-4 bg-light-green text-pure-white text-xs rounded-lg py-2 w-[320px]"
            btnText={t("Account.Reset Password")}
          />
        </div>
        <div className="flex justify-center text-pure-white">
          <div>{t(`Account.Need help?`)} </div>
          <div className="underline">{t(`Account.Contact us`)}</div>
        </div>
      </HeadLogo>
    </div>
  );
};

export default ForgetPasswordVerfication;
