import React, { useEffect, useState } from "react";

import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import AccommodationCard from "./accommodationCard";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useNavigate } from "react-router-dom";
import SpaceCard from "./spaceCard";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "redux/store";
import { spaces as spaceListValue } from "redux/slice/spaceListSlice";
import Button from "elements/Button";
import MoblieHeader from "components/moblieHeader";

const MyAccommodation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [acData, setAcData] = useState<any>([]);
  const hostId = localStorage.getItem("hostNum");
  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/accommodation/search/${hostId}`,
    }).then((res) => {
      const data = res.data.data;
      setAcData(data);
      return data;
    });
  }, [hostId]);

  const {
    id,
    currentImage,
    title,
    status,
    locationThemes,
    policyInfo,
    confirmMethod,
    advanced,
    spaces,
  } = acData;

  const onClickPreview = () => {
    if (acData?.length !== 0) {
      navigate(`/ac/preview/${hostId}`, { state: hostId });
    } else {
      return;
    }
  };

  const typeCategory = spaces?.map((item: any) => {
    const typeInfo = item.typeInfo;
    const category = typeInfo?.category;
    return category;
  });

  const category = typeCategory?.filter(
    (x: string, i: string) => typeCategory?.indexOf(x) === i
  );

  const handleSpaceList = () => {
    dispatch(spaceListValue(spaces));
  };

  return (
    <div>
      <MoblieHeader
        center="Menu.My Accommodation"
        centerTitleImage="outdoor-accommodation"
      />
      <div className="text-center pb-20 px-4 mx-auto lg:max-w-1216 py-8">
        <div className="flex">
          <HostAccount
            isApproved={status === "APPROVED" ? true : false}
            onClickCalendar={() => {
              handleSpaceList();
            }}
          />
          <HostAccountHeader
            className="grow w-[864px]"
            titleIcon="outdoor-accommodation"
            isPrivate={acData?.length !== 0 ? true : false}
            onClick={() => {
              onClickPreview();
            }}
            title="Menu.My Accommodation"
            isBtn
          >
            <div className="py-3 px-4 border rounded-lg web:block phone:hidden">
              <div>
                {acData !== undefined && acData.length !== 0
                  ? t("My-accommodation.You have a registered accommodation.")
                  : t(
                      "My-accommodation.You haven’t registered an accommodation yet!"
                    )}
              </div>
              {acData !== undefined && acData.length !== 0 ? (
                <div>
                  <div className="flex items-center">
                    <img
                      className="mr-2 w-5 h-5"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                      alt=""
                    />
                    <div>
                      {t(
                        "My-accommodation.Go Account Setting to add your new payout method"
                      )}{" "}
                      <span
                        className="text-link cursor-pointer ml-1"
                        onClick={() => {
                          navigate("/host/account", { state: { id: 1 } });
                        }}
                      >
                        {t(`My-accommodation.Go Payout Setting`)}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <img
                      className="mr-2 w-5 h-5"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                      alt=""
                    />
                    <div>
                      {t(
                        "My-accommodation.Go Account Setting to verify your contact"
                      )}{" "}
                      <span
                        className="text-link cursor-pointer ml-1"
                        onClick={() => {
                          navigate("/host/account", { state: { id: 0 } });
                        }}
                      >
                        {t(`My-accommodation.Go Host Information`)}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <AccommodationCard
              categoryList={category}
              currentImage={currentImage?.url}
              title={title}
              locationThemes={locationThemes}
              policyName={policyInfo?.name}
              confirmMethod={confirmMethod}
              advanced={advanced}
              status={status}
            />
            {title ? (
              <div>
                <div
                  onClick={() => {
                    navigate("/host/add-space", { state: { acId: id } });
                  }}
                  className="web:block phone:hidden cursor-pointer mt-8 w-full border border-input-border-color px-4 py-3 rounded-lg relative"
                >
                  <div>
                    {t(
                      "My-accommodation.You have {{number}} registered spaces in {{AC_title}}",
                      {
                        number: spaces?.length ? spaces?.length : 0,
                        AC_title: title ? title : "Accommodation",
                      }
                    )}
                  </div>
                  <div className="absolute right-0 top-0 px-4 py-3 rounded-lg bg-light-green text-pure-white">
                    {t("Add-space.Add Space")}
                  </div>
                </div>
                <div className="web:hidden phone:block">
                  <Button
                    onClick={() => {
                      navigate("/host/add-space", { state: { acId: id } });
                    }}
                    btnText={"Add-space.Add Space"}
                    className="rounded-lg text-pure-white bg-light-green mt-8 mb-4 py-2"
                  ></Button>
                  <div className="text-left">
                    {t(
                      "My-accommodation.You have {{number}} registered spaces in {{AC_title}}",
                      {
                        number: spaces?.length ? spaces?.length : 0,
                        AC_title: title ? title : "Accommodation",
                      }
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {spaces?.map((item: any, idx: number) => {
              const id = item.id;
              const title = item.title;
              const image = item.images[0];
              const typeInfo = item.typeInfo;
              const availableExtraGuest = item.availableExtraGuest;
              const bedAmount = item.bedAmount;
              const minStay = item.minStay;
              const spaceSize = item.spaceSize;
              const adult = item.adultExtraCharge;
              const children = item.childrenExtraCharge;
              const infant = item.infantExtraCharge;
              const guestAmount = item.totalGuestAmount;

              const mon = Number(item.monday);
              const tue = Number(item.tuesday);
              const wed = Number(item.wednesday);
              const thu = Number(item.thursday);
              const fri = Number(item.friday);
              const sat = Number(item.saturday);
              const sun = Number(item.sunday);
              return (
                <SpaceCard
                  status={status}
                  id={id}
                  adult={adult}
                  children={children}
                  infant={infant}
                  price={[mon, tue, wed, thu, fri, sat, sun]}
                  guestAmount={guestAmount}
                  currentImage={image.url}
                  spaceSize={spaceSize}
                  bedAmount={bedAmount}
                  minStay={minStay}
                  availableExtraGuest={availableExtraGuest}
                  typeInfo={typeInfo}
                  title={title}
                  key={idx}
                />
              );
            })}
          </HostAccountHeader>
        </div>
      </div>
    </div>
  );
};

export default MyAccommodation;
