import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import BookingWrap from "components/Booking/bookingWrap";

import Button from "elements/Button";
import DetailInfo from "elements/DetailInfo";

import { useTranslation } from "react-i18next";

import { inputPriceComma } from "utils/commaDash";
import { Payment } from "payment/importPayment";
import ImportPay from "payment/importPay";
import PaypalPay from "payment/paypalPay";
import Icons from "elements/Icons";

const BookingPayment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [paymentMethodCheck, setPaymentMethodCheck] = useState("");

  const localName = localStorage.getItem("name");
  const localEmail = localStorage.getItem("email");
  const localPhone = localStorage.getItem("phone");

  const { requestPageData, preChcekData }: any = location.state;

  const {
    title,
    extraOption,
    unitAmount,
    totalPrice,
    dayLength,
    totalAmount,
  } = requestPageData;

  const extraValue = extraOption[0]?.price * extraOption[0]?.amount;

  const reservation = location.state;

  // const reserve = () => {
  //   requestToMustOutdoor({
  //     method: "POST",
  //     url: "/api/v1/booking/make",
  //     data: reservation,
  //   })
  //     .then((res) => {
  //       return res.data.data;
  //     })
  //     .catch((err) => {});
  // };
  const creditCardClick = () => {
    setPaymentMethodCheck("");
    setPaymentMethodCheck("CREDIT_CARD");
  };
  const paypalClick = () => {
    setPaymentMethodCheck("");
    setPaymentMethodCheck("PAYPAL");
  };

  const payment = () => {
    if (paymentMethodCheck === "CREDIT_CARD") {
      Payment({
        requestPageData: requestPageData,
        preChcekData: preChcekData,
        name: title,
        buyer_email: localEmail ? localEmail : "",
        buyer_name: localName ? localName : "",
        amount: Number(totalPrice),
      });
    }
  };

  return (
    <div className="lg:max-w-1216 xl:max-w-7xl px-8 mx-auto pb-16">
      <div className="flex text-3.5xl items-center my-9">
        <Icons iconName="mobile-bookings" className="w-12 h-12 mr-6" />
        Booking Request
      </div>
      <div className="flex">
        <div className="w-[800px]">
          <BookingWrap
            size="M"
            title="Booking-request.Payment"
            className="mb-[67px]"
          >
            <DetailInfo withIcon w276 title="Booking-request.Total Amount">
              <div className="w-full">
                <div className="flex items-center">
                  <div className="font-bold">{inputPriceComma(totalPrice)}￦</div>
                </div>
              </div>
            </DetailInfo>
            <DetailInfo withIcon w276 title="Booking-request.Payment Amount">
              <div className="w-full">
                <div className="flex items-center">
                  <div className="font-bold text-light-green">
                    {inputPriceComma(totalPrice)}￦
                  </div>
                </div>
              </div>
            </DetailInfo>
            <DetailInfo
              withIcon
              w276
              nomargin
              title="Booking-request.Payment Method"
            >
              <div>
                <div className="flex items-center mb-8">
                  <Button
                    onClick={() => creditCardClick()}
                    btnText={"Booking-request.Credit Card"}
                    isIcon
                    src={`${
                      paymentMethodCheck === "CREDIT_CARD"
                        ? "credit-card-color"
                        : "credit-card"
                    }`}
                    className={`${
                      paymentMethodCheck === "CREDIT_CARD"
                        ? ""
                        : "bg-border-light text-border-black"
                    } py-3 font-bold px-[22px] justify-center min-w-[155px] mr-4 rounded-lg`}
                  />

                  <div className="text-xs">
                    {t(
                      "Booking-request.All card information is fully encrypted, secure, and protected."
                    )}
                  </div>
                </div>
                <div className="flex items-center mb-8">
                  <Button
                    onClick={() => {
                      paypalClick();
                    }}
                    btnText={"Booking-request.PayPal"}
                    isIcon
                    src={`${
                      paymentMethodCheck === "PAYPAL"
                        ? "paypal-color"
                        : "paypal-black"
                    }`}
                    className={`${
                      paymentMethodCheck === "PAYPAL"
                        ? ""
                        : "bg-border-light text-border-black"
                    } py-3 justify-center font-bold px-[22px] min-w-[155px] mr-4 rounded-lg`}
                  />

                  <div className="text-xs">
                    {t(
                      "Booking-request.Pay easily, fast, and secure with PayPal"
                    )}
                  </div>
                </div>
                <span
                  onClick={() => navigate("/terms")}
                  className="cursor-pointer text-xs text-link underline"
                >
                  {t("Footer.Terms and Conditions")}
                </span>
              </div>
            </DetailInfo>
          </BookingWrap>
        </div>
        <div className="w-80 ml-8">
          <BookingWrap
            size="S"
            title="Booking-request.Payment Details"
            className="mb-8"
          >
            <div>
              <div className="font-bold mb-4">
                {t("Booking-request.Ground Charge")}
              </div>
              <div className="flex mb-1 justify-between text-sm">
                <div className="max-w-[200px]">{title}</div>
                <div>
                  {inputPriceComma(
                    extraOption.length !== 0
                      ? totalAmount - extraValue
                      : totalAmount
                  )}￦
                </div>
              </div>
              <div className="text-xs text-input-text">
                {unitAmount} ground unit x {dayLength} night
              </div>
              <hr className="text-input-text my-4" />
            </div>
            {extraOption.length !== 0 ? (
              <div>
                <div className="font-bold mb-4">
                  {t("Booking-request.Extra Option(s)")}
                </div>
                {extraOption?.map((item: any, key: number) => {
                  return (
                    <div key={key}>
                      <div className="flex mb-1 justify-between text-sm">
                        <div className="max-w-[200px]">{item.name}</div>
                        <div>
                          {inputPriceComma(String(item.price * item.amount))}￦
                        </div>
                      </div>
                      <div className="text-xs mb-4 text-input-text">
                        {inputPriceComma(item.price)} x {item.amount}￦
                      </div>
                    </div>
                  );
                })}
                <hr className="text-input-text my-4" />
              </div>
            ) : (
              ""
            )}
            <div className="flex justify-between font-bold">
              <div>{t("Booking-request.Total Amount")}</div>
              <div>{inputPriceComma(totalPrice)}￦</div>
            </div>
          </BookingWrap>
          {paymentMethodCheck === "PAYPAL" ? (
            <>
              <PaypalPay className="bg-light-green rounded-lg mt-2 text-center text-pure-white py-3 w-full" />
            </>
          ) : paymentMethodCheck === "CREDIT_CARD" ? (
            <>
              <ImportPay
                className="bg-light-green rounded-lg mt-2 text-center text-pure-white py-3 w-full"
                requestPageData={requestPageData}
                // preChcekData={{
                //   currentImage: preChcekData?.currentImage,
                //   pgId: requestPageData.pgId,
                //   title: title,
                //   checkIn: preChcekData?.checkIn,
                //   checkOut: preChcekData?.checkOut,
                //   currencyInfo: preChcekData.currencyInfo,
                //   hasCar: preChcekData?.hasCar,
                //   hostInfo: preChcekData?.hostInfo,
                //   price: preChcekData?.price,
                //   totalPrice: preChcekData?.totalPrice,
                //   unitAmount: preChcekData.unitAmount,
                //   plateNumber: preChcekData.plateNumber,
                // }}
                // requestPageData: requestPageData,
                // preChcekData: preChcekData,
                // name: title,
                // buyer_email: localEmail ? localEmail : "",
                // buyer_name: localName ? localName : "",
                // amount: Number(totalPrice),
                preChcekData={preChcekData}
                name={title}
                buyer_tel={localPhone ? localPhone : ""}
                buyer_email={localEmail ? localEmail : ""}
                buyer_name={localName ? localName : ""}
                amount={Number(totalPrice)}
              />
            </>
          ) : (
            ""
          )}
          {/* <Button
            onClick={() => {
              payment();
            }}
            className="w-full font-bold rounded-lg bg-light-green text-pure-white text-center py-3"
            btnText={"Booking-request.CHECKOUT"}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default BookingPayment;
