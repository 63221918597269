import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ToolTip from "./ToolTip";

interface acWrapProps {
  onChange?: (e: any) => void;
  addOnClick?: () => void;
  name?: string;
  selectTrans?: string;
  noMarginB?: boolean;
  disabled?: boolean;

  tooltipHidden?: boolean;
  toolTitle?: string;
  toolSubTitle?: string;
  toolEx?: string;
  help?: boolean;

  title?: string;
  titleClass?: string;
  miniTitle?: string;
  need?: boolean;
  children?: any;
  className?: string;
  tooltip?: boolean;

  input?: boolean;
  textArea?: boolean;
  select?: boolean;
  options?: any[];
  inputValue?: any;
  inputMaxLength?: number;

  maxLength?: number;
  row?: number;
  placeholder?: string;

  addItem?: boolean;
}

const AcWrap = ({
  onChange,
  addOnClick,
  name,
  selectTrans,
  noMarginB,
  disabled,

  tooltipHidden,
  toolTitle,
  toolSubTitle,
  toolEx,
  help,

  input,
  textArea,
  select,
  options,
  inputValue,
  inputMaxLength,
  maxLength,
  row,
  placeholder,

  addItem,

  title,
  titleClass,
  miniTitle,
  need,
  children,
  className,
}: acWrapProps) => {
  const { t } = useTranslation();
  const [textLength, setTextLength] = useState(0);

  const maxLen = (e: any, max: number | any) => {
    const currentTarget = e.target.value;
    setTextLength(currentTarget.length);
    if (currentTarget.length > max) {
      e.target.value = currentTarget.substr(0, max);
    }
  };
  const [helpWrap, setHelpWrap] = useState(false);

  const outSide = useRef<any>();

  useEffect(() => {
    const clickOutside = (e: any) => {
      if (helpWrap && outSide.current && !outSide.current.contains(e.target)) {
        setHelpWrap(false);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  });
  return (
    <div className={`w-full ${titleClass} ${noMarginB ? "" : "mb-12"} `}>
      <ToolTip
        className={tooltipHidden ? "tooltipHidden" : "tooltipContent"}
        toolTitle={toolTitle}
        toolEx={toolEx}
        toolSubTitle={toolSubTitle}
      >
        <div className="flex items-center">
          <div
            className={`${
              addItem ? "flex items-center w-full justify-between" : ""
            }`}
          >
            <div className="font-bold">
              {t(`${title ? title : ""}`)}
              {need ? "*" : ""}
            </div>
            {miniTitle ? (
              <div className="mt-4 mb-2 text-sm">
                {t(`${miniTitle}`)}
                {need && miniTitle ? "*" : ""}
              </div>
            ) : (
              ""
            )}
            {addItem ? (
              <div
                onClick={addOnClick}
                className="flex items-center text-link text-sm cursor-pointer"
              >
                <img
                  className="w-4 h-4 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/plus-link.svg"
                  alt=""
                />
                <div>Add More</div>
              </div>
            ) : (
              ""
            )}
          </div>
          {help && toolTitle ? (
            <img
              onClick={() => setHelpWrap((helpWrap) => !helpWrap)}
              alt=""
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/question.svg"
              className="phone:block web:hidden ml-2 w-4 cursor-pointer h-4"
            />
          ) : (
            ""
          )}
          {helpWrap ? (
            <div
              className="w-full h-4 absolute phone:block web:hidden"
              ref={outSide}
              onClick={(e) => {
                if (outSide.current === e.target) {
                  setHelpWrap(false);
                }
              }}
            >
              <div className="transition-all absolute w-52 right-0 rounded-lg min-h-[160px] top-0 p-2 bg-toolTipBg z-[1000]">
                <div className="font-bold">
                  {t(`${toolTitle ? toolTitle : ""}`)}
                </div>
                {toolTitle ? <hr className="my-2 text-silver" /> : ""}
                <div className="my-2 text-sm">
                  {t(`${toolSubTitle ? toolSubTitle : ""}`)}
                </div>
                <div className="text-xs text-input-text">
                  {t(`${toolEx ? toolEx : ""}`)}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className={`${className ? className : ""} ${
            miniTitle ? "" : "mt-4"
          } relative`}
        >
          {input ? (
            <input
              name={name}
              onInput={(e) => {
                maxLen(e, inputMaxLength);
              }}
              disabled={disabled}
              defaultValue={inputValue}
              maxLength={maxLength}
              onChange={onChange}
              onWheel={(e: any) => e.target.blur()}
              placeholder={t(`${placeholder ? placeholder : ""}`)}
              className="acInput acHeight"
            ></input>
          ) : textArea ? (
            <textarea
              disabled={disabled}
              name={name}
              onWheel={(e: any) => e.target.blur()}
              onInput={(e) => {
                maxLen(e, inputMaxLength);
              }}
              defaultValue={inputValue}
              onChange={onChange}
              placeholder={t(`${placeholder ? placeholder : ""}`)}
              rows={row}
              className="acInput"
            ></textarea>
          ) : select ? (
            <select
              disabled={disabled}
              name={name}
              value={inputValue}
              onChange={onChange}
              className="acInput acHeight"
            >
              <option value="">{t(`${placeholder ? placeholder : ""}`)}</option>
              {options?.map((value) => {
                return (
                  <option key={value} value={value}>
                    {value && !selectTrans
                      ? t(`${value}`)
                      : value && selectTrans
                      ? t(`${selectTrans}.${value}`)
                      : ""}
                  </option>
                );
              })}
            </select>
          ) : (
            children
          )}
          {inputMaxLength ? (
            <div
              className={`absolute text-xs -top-[26px] right-0 flex ${
                textLength === inputMaxLength || textLength > inputMaxLength
                  ? "text-light-green"
                  : ""
              }`}
            >
              <p>{textLength > inputMaxLength ? inputMaxLength : textLength}</p>
              /<p>{inputMaxLength}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </ToolTip>
    </div>
  );
};

export default AcWrap;
