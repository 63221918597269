import React, { useEffect, useState } from "react";

import { useAppDispatch } from "redux/store";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import EditLocationSetting from "./editLocationSetting";
import EditGeneralInfo from "./editGeneralInfo";
import EditGroundGallery from "./editGroundGallery";
import EditGroundDetail from "./editGroundDetail";
import EditPricing from "./editPricing";
import EditPolicy from "./editPolicy";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import MoblieHeader from "components/moblieHeader";

const EditPrivateGround = () => {
  const { t } = useTranslation();
  const preTabNum = Number(sessionStorage.getItem("editTab"));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const hostNum = localStorage.getItem("hostNum");
  const [tabNum, setTabNum] = useState(preTabNum ? preTabNum : 0);
  const [value, setValue] = useState<any>([]);

  sessionStorage.setItem("editTab", String(tabNum));

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/private-ground/${hostNum}`,
    })
      .then((res) => {
        setValue(res.data.data);
        return res.data.data;
      })
      .catch((err) => {});
  }, [hostNum]);

  const {
    // 기본 정보

    title,
    description,
    typeInfo,
    unitCount,
    locationThemes,
    accessibleVehicle,
    spaceSize,

    // 위치 설정
    countryCode,
    state,
    city,
    zipCode,
    extraAddress,
    direction,
    nearbyFacilities,
    touristSpots,
    indicator,
    address,

    // 공간 이미지
    currentImage,
    images,

    // 공간 상세정보
    facilities,
    activities,
    restricts,
    specialFacility,

    // 가격 설정
    priceInfo,
    extraOptions,

    // 정책
    bookingTime,
    confirmMethod,
    advanceDate,
    extraPolicy,
    policyInfo,

    // 필요없는
    calendars,
    createdAt,
    deleted,
    destinationInfo,
    featured,
    hostInfo,
    hotDeal,
    id,
    mustPick,
    note,
    status,
    updatedAt,
    views,
  } = value;

  const editTab = [
    { id: 0, title: "General Info" },
    { id: 1, title: "Location Setting" },
    { id: 2, title: "Ground Gallery" },
    { id: 3, title: "Ground Details" },
    { id: 4, title: "Operation" },
    { id: 5, title: "Policy" },
  ];

  const ChangeTab = (id: number) => {
    setTabNum(id);
  };

console.log(value)

  const general = {
    preTitle: title,
    preDescription: description,
    preType: typeInfo,
    preUnitCount: unitCount,
    preLocationThemeList: locationThemes,
    preAccessibleVehicle: accessibleVehicle,
    preSpaceSize: spaceSize,
  };

  const location = {
    preCountryCode: countryCode,
    preState: state,
    preCity: city,
    preZipCode: zipCode,
    preExtraAddress: extraAddress,
    preDirection: direction,
    preNearbyFacilityInfo: nearbyFacilities,
    preTouristSpot: touristSpots,
    preAddress: address,
    preIndicator: indicator,
  };

  const gallery = {
    preImageDtoList: images,
    preCurrentImage: currentImage,
  };

  const allow = restricts?.map((item: any) => {
    const value = item?.allowedList;
    if (value.allowed === true) return item;
  });
  const prohibit = restricts?.map((item: any) => {
    const value = item?.allowedList;
    if (value.allowed === false) return item;
  });
  const ground = {
    preFacilities: facilities,
    preActivities: activities,
    preProhibitedList: prohibit?.filter((x: any) => x !== undefined),
    preAllowedList: allow?.filter((x: any) => x !== undefined),
    preSpecialFacility: specialFacility,
  };

  const price = {
    prePriceInfo: priceInfo,
    preExtraOption: extraOptions,
  };

  const policy = {
    preBookingTime: bookingTime,
    preConfirmMethod: confirmMethod,
    preAdvanceDate: advanceDate,
    preExtraPolicy: extraPolicy,
    prePolicyInfo: policyInfo,
  };

  return (
    <div className="web:text-center phone:text-left web:p-8 mx-auto lg:max-w-1216 phone:max-w-[1023px]">
      <MoblieHeader left center="Add-private-ground.Edit Private Ground" />
      <div className="web:flex">
        <HostAccount />
        <HostAccountHeader
          titleIcon="outdoor-private-ground" 
          isPrivate={value.name !== "AxiosError" ? true : false}
          title="Add-private-ground.Edit Private Ground"
        >
          <div
            className="border-b h-11 flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll"
          >
            {editTab.map((item, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => {
                    ChangeTab(item.id);
                  }}
                  className={`${
                    item.id === tabNum
                      ? "bg-light-green text-pure-white"
                      : " text-input-text"
                  } cursor-pointer px-4 items-center flex hover:bg-light-green focus:bg-light-green rounded-t-lg hover:text-pure-white`}
                >
                  {t(`Add-private-ground.${item.title}`)}
                </div>
              );
            })}
          </div>
          {tabNum === 0 ? (
            <EditGeneralInfo id={id} geneEditData={general} />
          ) : tabNum === 1 ? (
            <EditLocationSetting id={id} locaEditData={location} />
          ) : tabNum === 2 ? (
            <EditGroundGallery id={id} galleryEditData={gallery} />
          ) : tabNum === 3 ? (
            <EditGroundDetail id={id} groundEditData={ground} />
          ) : tabNum === 4 ? (
            <EditPricing id={id} pricingEditData={price} />
          ) : tabNum === 5 ? (
            <EditPolicy id={id} policyEditData={policy} />
          ) : (
            ""
          )}
        </HostAccountHeader>
      </div>
    </div>
  );
};

export default EditPrivateGround;
