import React, { useCallback, useEffect, useRef, useState } from "react";
import { privateData } from "redux/action/private";
import { RootState, useAppDispatch } from "redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useScroll } from "hooks/useScroll";

import GoogleMap from "components/GoogleMap";
import DayPicker from "components/DayPicker";
import MoblieHeader from "components/moblieHeader";

import { changecase, changeFacility } from "utils/changeCase";
import { isMember } from "lib/isMember";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { inputPriceComma } from "utils/commaDash";
import { getEveryDiffDay } from "utils/getDiffDay";

import Button from "elements/Button";
import DetailInfo from "elements/DetailInfo";
import DetailTheme from "elements/DetailTheme";
import SideMenu from "elements/sideMenu";
import FullSizeModal from "elements/fullSizeModal";
import HostInput from "elements/HostInput";
import InquiryModal from "elements/InquiryModal";

import { Payment } from "payment/importPayment";
import ImportPay from "payment/importPay";
import { isLogin } from "utils/roleStatus";
import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";
import Icons from "elements/Icons";
import { changeDate } from "utils/changeDate";
import GoogleTranslate from "utils/googleTranslate";
import { useQueries, useQuery } from "react-query";
import axios from "axios";

interface extraOptionItem {
  amount?: number;
  description?: string;
  maxOfNumber?: number;
  paymentMethod?: string;
  price?: string;
  title?: string;
}

interface detailsProps {
  specialTabOpen?: boolean;
  extraTabOpen?: boolean;
  availableTabOpen?: boolean;
  locationTabOpen?: boolean;
  bookingTabOpen?: boolean;
  policyTabOpen?: boolean;
  datePrice?: any;
}

const Details = ({
  specialTabOpen,
  extraTabOpen,
  availableTabOpen,
  locationTabOpen,
  bookingTabOpen,
  policyTabOpen,
  datePrice,
}: detailsProps) => {
  const { t } = useTranslation();
  const [allPhotoModal, setAllPhotoModal] = useState(false);
  const [touristModal, setTouristModal] = useState(false);
  const [inquiryModal, setInquiryModal] = useState(false);
  const [touristDetail, setTouristDetail] = useState<any>([]);
  const [hasCar, setHasCar] = useState(true);

  const [googleTranslate, setGoogleTranslate] = useState<any>();

  const [newImageList, setNewImageList] = useState<any[]>([]);

  const [payType, setPayType] = useState("");

  const [reservationTab, setReservationTab] = useState(false);
  const [specialTab, setSpecialTab] = useState(false);
  const [extraTab, setExtraTab] = useState(false);
  const [availableTab, setAvailableTab] = useState(false);
  const [locationTab, setLocationTab] = useState(false);
  const [bookingTab, setBookingTab] = useState(false);
  const [policyTab, setPolicyTab] = useState(false);

  const [extra, setExtra] = useState<extraOptionItem[]>([]);

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [totalPrice, setTotalPrice] = useState("");
  const [dayPrice, setDayPrice] = useState("");
  const [groundUnitNumber, setGroundUnitNumber] = useState(1);
  const [extraPayment, setExtraPayment] = useState<any[] | any>();

  const [active, setActive] = useState<any[]>([5]);

  const [preBookingData, setPreBookingData] = useState<any>({});

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState([]);
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [plateNum, setPlateNum] = useState("");
  const [preValue, setPreValue] = useState<any>("");
  const [inquiryValue, setInquiryValue] = useState("");

  const { element, onMoveElement } = useScroll();
  const [isOpen, setIsOpen] = useState(false);

  const [current, setCurrent] = useState(0);
  const [style, setStyle] = useState({
    transform: `translate(-${current}00%)`,
  });

  const [isPhone, setIsPhone] = useState(false);
  const [phoneCode, setPhoneCode] = useState("");

  const [verifyErr, setVerfyErr] = useState(false);
  const [verifyMessage, setVerfyMessage] = useState("");

  const [translate, setTranslate] = useState(false);

  const SideTab = useRef<HTMLDivElement | any>(null);

  const [Language, setLanguage] = useState<string>("");

  const [vehicle, setVehicle] = useState(false);

  const localName: any = localStorage.getItem("name");
  const localEmail: any = localStorage.getItem("email");
  const localPhone: any = localStorage.getItem("phone");
  const localCountyCode: any = localStorage.getItem("CC");

  useEffect(() => {
    if (localName) {
      setName(localName);
    }
    if (localEmail) {
      setEmail(localEmail);
    }
    if (localPhone || localPhone !== undefined || localPhone !== "undefined") {
      setPhone(localPhone);
    }
    const preValue = countryCode?.map((item: any) => {
      if (item.replace(/[[a-zA-Z\s]/g, "") === localCountyCode) return item;
    });
    const noUnde = preValue?.filter((preValue: any) => preValue !== undefined);
    setPreValue(noUnde);
  }, [countryCode, localCountyCode, localEmail, localName, localPhone]);

  const navigate = useNavigate();
  const params = useParams();
  const _id = params.id;
  const dispatch = useAppDispatch();
  const Data: any = useSelector<RootState>((state) => state.private);
  useEffect(() => {
    dispatch(privateData(_id ? _id : ""));
  }, [_id, dispatch]);

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}guest/main/common/code`,
    }).then((res) => {
      const country = res.data.data.countryCodeMap;
      setCountryCode(country);
    });
  }, []);

  const memberNum = localStorage.getItem("m");

  const getItem = async (id: string | undefined | number) => {
    const res = await requestToMustOutdoor.get(`/guest/private-ground/${id}`);
    return res.data.data;
  };

  const {
    isLoading,
    isError,
    data: detailData,
  } = useQuery(["getPgItem"], () => {
    return getItem(_id);
  });

  const {
    title,
    typeInfo,
    id,
    hostInfo,
    images,
    currentImage,
    locationThemes,
    destinationInfo,
    confirmMethod,
    facilities,
    description,
    policyInfo,
    activities,
    restricts,
    specialFacility,
    extraOptions,
    advanceDate,
    extraPolicy,
    indicator,
    nearbyFacilities,
    touristSpots,
    bookingTime,
    unitCount,
    accessibleVehicle,
    spaceSize,
    calendars,
    priceInfo,
  } = !isLoading && detailData;

  const tourTitle = touristSpots && touristSpots[0]?.title;
  const tourDesc = touristSpots && touristSpots[0]?.description;
  const extraTitle = extraOptions && extraOptions[0]?.title;
  const extraDesc = extraOptions && extraOptions[0]?.description;

  const translateValue = [
    `${title}_`,
    `${description}_`,
    `${tourTitle}_`,
    `${tourDesc}_`,
    `${specialFacility}_`,
    `${extraTitle}_`,
    `${extraDesc}_`,
    `${extraPolicy}`,
  ];

  const currentLanguage = [
    { language: "영어", code: "en", i18n: "e" },
    { language: "일본어", code: "ja", i18n: "j" },
    { language: "한국어", code: "ko", i18n: "k" },
  ];
  const currentLan = localStorage.getItem("i18nextLng");

  const lan = currentLanguage.map((item: any) => {
    const i18n = item.i18n;
    const code = item.code;
    if (i18n === currentLan) {
      return code;
    }
  });
  useEffect(() => {
    setLanguage(lan.filter((x) => x !== undefined)[0]);
  }, [currentLan, lan]);

  const googleTrans = async (text: string) => {
    const res = await axios({
      url: "https://translation.googleapis.com/language/translate/v2",
      method: "POST",
      params: {
        q: text,
        target: Language,
        key: process.env.REACT_APP_GOOGLE_TRANSLATE_KEY,
      },
    });
    return res.data.data.translations[0].translatedText;
  };

  const result = useQueries([
    {
      queryKey: ["getTranslate", "Title"],
      queryFn: () => googleTrans(title),
      enabled: !!title,
    },
    {
      queryKey: ["getTranslate", "Desc"],
      queryFn: () => googleTrans(description),
      enabled: !!description,
    },
    {
      queryKey: ["getTranslate", "TouristTitle"],
      queryFn: () => googleTrans(tourTitle),
      enabled: !!tourTitle,
    },
    {
      queryKey: ["getTranslate", "TouristDesc"],
      queryFn: () => googleTrans(tourDesc),
      enabled: !!tourDesc,
    },
    {
      queryKey: ["getTranslate", "specialFacility"],
      queryFn: () => googleTrans(specialFacility),
      enabled: !!specialFacility,
    },
    {
      queryKey: ["getTranslate", "extraOptionTitle"],
      queryFn: () => googleTrans(extraTitle),
      enabled: !!extraTitle,
    },
    {
      queryKey: ["getTranslate", "extraOptionDesc"],
      queryFn: () => googleTrans(extraDesc),
      enabled: !!extraDesc,
    },
    {
      queryKey: ["getTranslate", "extraPolicy"],
      queryFn: () => googleTrans(extraPolicy),
      enabled: !!extraPolicy,
    },
  ]);
  const { status: titleStauts, data: translateTitle } = result[0];
  const { status: descStatus, data: translateDesc } = result[1];
  const { status: TouristTitleStatus, data: translateTourTitle } = result[2];
  const { status: TouristDescStatus, data: translateTourDesc } = result[3];
  const { status: specialFacilityStatus, data: translateSpecialFacility } =
    result[4];
  const { status: extraOptionTitleStatus, data: translateExtraTitle } =
    result[5];
  const { status: extraOptionDescStatus, data: translateExtraDesc } = result[6];
  const { status: extraPolicyStatus, data: translateExtraPolicy } = result[7];

  // const translateTitle = googleTranslate && googleTranslate[0];
  // const translateDesc = googleTranslate && googleTranslate[1];
  // const translateTourTitle = googleTranslate && googleTranslate[2];
  // const translateTourDesc = googleTranslate && googleTranslate[3];
  // const translateSpecialFacility = googleTranslate && googleTranslate[4];
  // const translateExtraTitle = googleTranslate && googleTranslate[5];
  // const translateExtraDesc = googleTranslate && googleTranslate[6];
  // const translateExtraPolicy = googleTranslate && googleTranslate[7];

  const unitNum = Number(unitCount);

  const isundefined = extraPayment?.includes(undefined);

  const newValue = extraOptions?.map(
    ({ id, title, price, amount, paymentMethod }: any) => {
      if (paymentMethod === "Available In Extra Option") {
        const obj = {
          id: id,
          name: title,
          price,
          amount: 0,
        };
        return obj;
      }
    }
  );

  const verification = () => {
    const countryValue = country.split(" ").reverse()[0];
    requestToMustOutdoor({
      method: "POST",
      url: `/api/v1/host/verify/code`,
      data: { verifyCode: phoneCode },
    })
      .then((res) => {
        setIsPhone(true);
        setVerfyErr(false);
        localStorage.setItem("phone", phone);
        localStorage.setItem("CC", countryValue);
        setVerfyMessage("맞는 인증코드");
        return res.data.data;
      })
      .catch((err) => {
        setVerfyErr(true);
        setVerfyMessage("틀린 인증코드");
      });
  };

  useEffect(() => {
    if (images && currentImage) {
      setNewImageList([currentImage]);
      setNewImageList((pre: any) => [...pre, ...images]);
    }
  }, [currentImage, images]);

  useEffect(() => {
    setExtra(extraOptions);
  }, [extraOptions]);

  const booking = () => {
    alert("현재 사이트 리뉴얼 중으로 서비스 이용이 불가합니다. 불편을 드려 죄송합니다.\n(예약요청 및 문의를 받지 않습니다)")
    // if (startDate === "" || startDate === "NaN-aN-aN") {
    //   alert("체크인 날짜 선택");
    // } else if (endDate === "" || endDate === "NaN-aN-aN") {
    //   alert("체크아웃 날짜 선택");
    // } else if (startDate === endDate) {
    //   alert("1박 이상 가능합니다");
    // } else if (groundUnitNumber === 0) {
    //   alert("groundUnit 선택");
    // } else if (!isMember()) {
    //   alert("로그인후 예약 가능합니다.");
    //   navigate(`/log-in`, { replace: true, state: { nextPathId: _id } });
    // } else if (isMember()) {
    //   navigate(`/book/${_id}`, { state: data });
    // }
  };

  const handleInquiry = () => {
    if (isLogin() === true) {
      requestToMustOutdoor({
        method: "POST",
        url: `/api/v1/qna/private-ground`,
        data: {
          content: TextareaReplaceNewLine(inquiryValue),
          hostId: Number(hostInfo?.id),
          memberId: Number(memberNum),
          acId: 0,
          atId: 0,
          pgId: id,
        },
      }).then((res) => {
        const data = res.data.data;
        navigate("/member/message");
        return data;
      });
    } else {
      alert("로그인이 필요한 기능입니다.");
    }
  };

  const calendarPriceInfo = calendars?.map((item: any) => {
    return Number(item.price);
  });

  const minimumPrice = () => {
    if (calendarPriceInfo) {
      const noZero = calendarPriceInfo.filter(
        (e: number | any) => e !== 0 && e !== ""
      );
      if (noZero.length === 0) {
        return "예약마감";
      } else {
        const value = Math.min.apply(null, noZero);
        const Comma = inputPriceComma(String(value));
        return Comma;
      }
    }
  };

  const handlePlusClickOption = (idx: number, item: any) => {
    const MON: any = extra[idx].maxOfNumber;
    if (extraPayment) {
      setExtraPayment(
        extraPayment.map((i: any) => {
          return i && i.name === item.name
            ? {
                ...i,
                amount: MON > item.amount ? item.amount + 1 : item.amount,
              }
            : i;
        })
      );
    }
  };

  const handleMinusClickOption = (idx: number, item: any) => {
    if (extraPayment) {
      setExtraPayment(
        extraPayment.map((i: any) => {
          return i.name === item.name
            ? { ...i, amount: 0 < item.amount ? item.amount - 1 : item.amount }
            : i;
        })
      );
    }
  };

  const handleOpen = () => {
    if (extraPayment === undefined) {
      setExtraPayment(newValue);
    }
    setIsOpen((isOpen) => !isOpen);
  };

  console.log("extraPayment", extraPayment);
  useEffect(() => {
    if (extraPayment !== undefined && isundefined) {
      const result = extraPayment.filter(function (x: any) {
        return x !== undefined;
      });
      setExtraPayment(result);
    }
  }, [extraPayment, isundefined]);

  useEffect(() => {
    if (extraPayment !== undefined) {
      const datePrice = Number(dayPrice);
      const result = datePrice * groundUnitNumber;
      if (
        (!extraPayment[0]?.name && !extraPayment[0]?.price) ||
        extraPayment.length === 0
      ) {
        const stringResult = String(result);
        setTotalPrice(stringResult);
      } else {
        const price = extraPayment[0]?.price;
        const amount = extraPayment[0]?.amount;

        const extreValue = Number(price) * amount;
        const extraResult = extreValue + result;
        setTotalPrice(String(extraResult));
      }
    }
  }, [dayPrice, endDate, extraPayment, groundUnitNumber]);

  const touristInfo = (title: string, desc: string, imgUrl: string) => {
    setTouristDetail({ title: title, desc: desc, imgUrl: imgUrl });
    setTouristModal(true);
  };

  const create = changeDate(hostInfo?.createdAt).substr(0, 7);

  const allPhotoView = () => {
    setAllPhotoModal((allPhotoModal) => !allPhotoModal);
  };
  
  const reservationToggle = () => {
    alert("현재 사이트 리뉴얼 중으로 서비스 이용이 불가합니다. 불편을 드려 죄송합니다.\n(예약요청 및 문의를 받지 않습니다)")
    // if (isLogin() === false) {
    //   alert("로그인후 예약 가능합니다.");
    //   navigate(`/log-in`, { replace: true, state: { nextPathId: _id } });
    // } else if (extraPayment === undefined) {
    //   setExtraPayment(newValue);
    // }
    // setReservationTab((reservationTab) => !reservationTab);
  };

  const specialTabToggle = () => {
    setSpecialTab((specialTab) => !specialTab);
  };
  const extraTabToggle = () => {
    setExtraTab((extraTab) => !extraTab);
  };
  const availableTabToggle = () => {
    setAvailableTab((availableTab) => !availableTab);
  };
  const locationTabToggle = () => {
    setLocationTab((locationTab) => !locationTab);
  };
  const bookingTabToggle = () => {
    setBookingTab((bookingTab) => !bookingTab);
  };
  const policyTabToggle = () => {
    setPolicyTab((policyTab) => !policyTab);
  };

  useEffect(() => {
    const channelTalk: any = document.getElementById("ch-plugin");
    setTimeout(() => {
      const value = channelTalk;
      const result = value?.style ? value?.style : "";
      if (result) {
        value.style.cssText = "display:none !important";
      }
    }, 100);
  }, [Data?.detailList]);

  useEffect(() => {
    setStyle({ transform: `translateX(-${current}00%)` });
  }, [current]);

  const data = {
    _id: _id,
    checkIn: startDate,
    checkOut: endDate,
    totalPrice: totalPrice,
    dayPrice: dayPrice,
    extraOptions: extraPayment || [],
    totalMemberId: memberNum,
    unitAmount: groundUnitNumber,
    hasCar: hasCar,
  };

  const extraValue = extraPayment && [
    {
      amount: extraPayment[0]?.amount,
      id: extraPayment[0]?.id,
      price: extraPayment[0]?.price,
    },
  ];
  const extraPrice =
    extraPayment && Number(extraPayment[0]?.price) * extraPayment[0]?.amount;
  const privateGroundPrice = Number(totalPrice) - extraPrice;

  const checkData = {
    checkIn: startDate,
    checkOut: endDate,
    currencyId: 2,
    currentImage: currentImage?.url,
    extraOption:
      extraPayment &&
      extraPayment?.length !== 0 &&
      extraPayment[0]?.amount !== 0
        ? extraValue
        : [],
    hasCar: plateNum ? true : false,
    hostId: hostInfo?.id,
    optionPrice: extraPayment?.length !== 0 ? String(extraPrice) : "0",
    pgId: Number(_id),
    pgTitle: title,
    plateNumber: plateNum,
    price: String(extraPayment?.length !== 0 ? privateGroundPrice : totalPrice),
    totalMemberId: Number(memberNum),
    totalServerPrice: totalPrice,
    unitAmount: groundUnitNumber,
  };

  const phoneVerify = () => {
    const countryValue = country.split(" ").reverse()[0];
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v1/host/verify/sms",
      data: {
        mobile: phone,
        mobileCountryCode: countryValue,
        totalMemberId: Number(memberNum),
      },
    })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return err;
      });
  };

  const handlePage = (i: number) => {
    alert("현재 사이트 리뉴얼 중으로 서비스 이용이 불가합니다. 불편을 드려 죄송합니다.\n(예약요청 및 문의를 받지 않습니다)")
    // if (current === 0) {
    //   if (startDate === "" || startDate === "NaN-aN-aN") {
    //     alert("체크인 날짜 선택");
    //   } else if (endDate === "" || endDate === "NaN-aN-aN") {
    //     alert("체크아웃 날짜 선택");
    //   } else if (startDate === endDate) {
    //     alert("1박 이상 가능합니다");
    //   } else if (groundUnitNumber === 0) {
    //     alert("groundUnit 선택");
    //   } else if (!isMember()) {
    //     alert("로그인후 예약 가능합니다.");
    //     navigate(`/log-in`, { replace: true, state: { nextPathId: _id } });
    //   } else {
    //     setCurrent(current + 1);
    //   }
    // } else if (current === 1) {
    //   requestToMustOutdoor({
    //     method: "POST",
    //     url: "/api/v1/booking/pre-booking",
    //     data: { ...checkData },
    //   })
    //     .then((res) => {
    //       const value = res.data.data;
    //       setCurrent(current + 2);
    //       setPreBookingData(value);
    //       // JSON.stringify
    //       const {
    //         checkIn,
    //         checkOut,
    //         currencyInfo,
    //         currentImage,
    //         hasCar,
    //         hostInfo,
    //         pgId,
    //         plateNumber,
    //         price,
    //         title,
    //         totalPrice,
    //         unitAmount,
    //         optionInfo,
    //       } = value;

    //       localStorage.setItem(
    //         "book",
    //         JSON.stringify({
    //           checkIn: checkIn,
    //           checkOut: checkOut,
    //           currencyId: currencyInfo?.id,
    //           currentImage: currentImage,
    //           hasCar: hasCar,
    //           hostId: hostInfo?.id,
    //           pgId: pgId,
    //           plateNumber: plateNumber,
    //           price: price,
    //           pgTitle: title,
    //           totalPrice: totalPrice,
    //           unitAmount: unitAmount,
    //           optionInfo: optionInfo,
    //         })
    //       );
    //       return res.data.data;
    //     })
    //     .catch((err) => {
    //       const errData = err.response.data;
    //       if (
    //         errData.message === "휴대폰 인증 필요한 사용자입니다." &&
    //         errData.status === 403
    //       ) {
    //         alert(errData.message);
    //         setCurrent(current + 1);
    //       }
    //     });
    // } else if (current === 2) {
    //   return isPhone ? setCurrent(i) : alert("핸드폰 인증을 진행해주세요");
    // } else {
    //   setCurrent(current + i);
    // }
    // setActive([5]);
    // if (SideTab) {
    //   SideTab?.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //   });
    // }
  };

  const night: any = getEveryDiffDay(startDate, endDate);

  const creditCardClick = () => {
    setPayType("");
    setPayType("CREDIT_CARD");
  };
  const paypalClick = () => {
    setPayType("");
    setPayType("PAYPAL");
  };

  const { currencyInfo, unitAmount, plateNumber } = preBookingData;

  const payment = () => {
    if (payType === "CREDIT_CARD") {
      Payment({
        requestPageData: { extraOption: extraValue },
        preChcekData: {
          currentImage: currentImage?.url,
          pgId: Number(_id),
          title: title,
          checkIn: preBookingData?.checkIn,
          checkOut: preBookingData?.checkOut,
          currencyInfo: currencyInfo,
          hasCar: preBookingData?.hasCar,
          hostInfo: preBookingData?.hostInfo,
          price: preBookingData?.price,
          totalPrice: preBookingData?.totalPrice,
          unitAmount: unitAmount,
          plateNumber: plateNumber,
        },
        name: title,
        buyer_email: localEmail ? localEmail : "",
        buyer_name: localName ? localName : "",
        amount: Number(totalPrice),
      });
    }
  };

  const vehicles = [
    { title: "Only Backpacker" },
    { title: "Up to compact / light size" },
    { title: "Up to mid / large size" },
    { title: "Up to compact RV (3 pax)" },
    { title: "Up to mid / large RV (5 pax)" },
    { title: "Small/mid Caravan or Trailer(3 pax)" },
    { title: "Large Caravan or Trailer (5 pax)" },
  ];

  const x = (Math.random() * 0.0095).toFixed(6);
  const y = (Math.random() * 0.00085).toFixed(6);

  return (
    <div>
      <GoogleTranslate
        text={translateValue}
        language="en"
        setTranslateValue={setGoogleTranslate}
      />
      {vehicle ? (
        <FullSizeModal
          selectHostingType
          className="web:h-full phone:h-[800px] phone:overflow-y-auto fixed w-full top-2/4 -translate-y-[25%] left-2/4 -translate-x-[50%] text-center lg:max-w-1216 web:px-8 web:py-8 web:mx-auto phone:px-4 phone:pt-16 phone:pb-16"
          isOpen={() => {
            setVehicle(false);
          }}
        >
          <div className="px-2 py-2 text-sm text-pure-white bg-light-green rounded-t-lg">
            {t("Parking-accessible-data.Parking-accessible-tooltip-title")}
          </div>
          <div className="px-2 py-4 border-x rounded-b-lg border-input-text bg-pure-white">
            <ul>
              {vehicles?.map((item, idx) => {
                const title = item.title;
                return (
                  <li
                    className={`${
                      accessibleVehicle === title ? "text-light-green" : ""
                    }
                            ${
                              title === "Only Backpacker"
                                ? "text-main-green"
                                : ""
                            }
                            `}
                    key={idx}
                  >
                    {t(`Parking-accessible-data.${title}`)}
                  </li>
                );
              })}
            </ul>
          </div>
        </FullSizeModal>
      ) : (
        ""
      )}
      {inquiryModal ? (
        <InquiryModal>
          <div className="bg-pure-white web:h-full phone:h-[600px] phone:overflow-y-scroll rounded-lg p-8">
            <div className="flex justify-between items-center">
              <div className="text-2xl font-bold">
                {t(`Message-box.1:1 Inquiry`, { nsSeparator: false })}
              </div>
              <img
                onClick={() => {
                  setInquiryModal(false);
                }}
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/menu-close.svg"
                alt=""
                className="w-5 h-5 mr-2 cursor-pointer"
              />
            </div>
            <textarea
              onChange={(e) => {
                setInquiryValue(e.target.value);
              }}
              className="acInput mt-8 mb-4"
              rows={8}
              placeholder={t("Message-box.Please enter your inquiry.")}
            />
            <div className="text-sm">
              {t(
                "Message-box.You can find answers to your inquiries in the 1:1 inquiry in the message box."
              )}
            </div>
            <div className="my-8 text-center">
              <Button
                onClick={() => {
                  handleInquiry();
                }}
                btnText={"Message-box.Submit"}
                className="py-3 px-14 inline-block text-pure-white rounded-lg bg-light-green"
              />
            </div>
            <div className="border border-input-border-color bg-[#f8f8f8] flex flex-col justify-between p-4 web:h-[200px] phone:h-[400px] text-sm">
              <div className="text-base font-bold flex items-center">
                <img
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                  alt=""
                  className="w-5 h-5 mr-2"
                />
                <div>{t("Message-box.Notice on writing 1:1 Inquiry")}</div>
              </div>
              <div>
                {t(
                  "Message-box.1:1 inquiry allows travellers to contact their hosts"
                )}
              </div>
              <div>{t("Message-box.To protect your personal information")}</div>
              <div>
                {t("Message-box.We cannot help you in the event of damage")}
              </div>
              <div>{t("Message-box.If you pay through MUST OUTDOOR")}</div>
            </div>
          </div>
        </InquiryModal>
      ) : (
        ""
      )}
      <MoblieHeader center={title} left />
      {/* fixed footer */}
      <div className="w-full shadow-card p-4 fixed bottom-0 phone:flex web:hidden bg-pure-white z-10 justify-between">
        <div>
          <div className="flex items-baseline">
            <div className="text-xs text-input-text mr-2">
              {minimumPrice() === "예약마감" ? "" : "From"}
            </div>
            <div>{minimumPrice()}</div>
          </div>
          <div className="flex items-baseline">
            <img
              className="w-4 h-4"
              src={
                "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/rating-star-e.svg"
              }
              alt=""
            />
            <div className="font-bold mx-1">NEW</div>
            {/* <div className="text-xs text-input-text">0 Reviews</div> */}
          </div>
        </div>
        <Button
          onClick={() => reservationToggle()}
          className="bg-light-green text-center py-3 text-pure-white px-4 rounded-lg cursor-pointer"
          btnText={t("Product-details.Go Booking")}
        />
      </div>

      {/* 모달 / 사이드 메뉴 */}
      <SideMenu
        onClick={() => {
          if (current === 3) {
            setCurrent(current - 2);
          } else {
            setCurrent(current - 1);
          }
        }}
        left={current !== 0}
        noPadding
        setIsOpenTab={setReservationTab}
        isOpenTab={reservationTab}
        tabTitle="Booking-request.Booking Request"
      >
        <div className="w-full transition-all overflow-hidden">
          <div ref={SideTab}></div>
          <div className="flex items-start transition-all" style={style}>
            <div className="w-full flex-none">
              <div className="p-4 border-b border-border-light font-bold">
                <div>{t("Product-details.Selected Ground")}</div>
                <div className="mt-2 text-main-green">{title}</div>
              </div>
              <div className="p-4 border-b border-border-light font-bold">
                <div>{t("Product-details.Dates")}</div>
                <div className="flex justify-center">
                  {calendars ? (
                    <DayPicker
                      advanceDate={advanceDate}
                      datePrice={setDayPrice}
                      calendars={calendars}
                      from_={(date: any) => {
                        setStartDate(date);
                      }}
                      to_={(date: any) => {
                        setEndDate(date);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="p-4 border-b border-border-light font-bold">
                <div>{t("Product-details.Ground Unit")}</div>
                <div className="flex items-center justify-between">
                  <div className="w-[166px]">
                    {t("Product-details.Number of Ground Unit")}
                  </div>
                  <Icons
                    onClick={() => {
                      setGroundUnitNumber(
                        groundUnitNumber > 1
                          ? groundUnitNumber - 1
                          : groundUnitNumber
                      );
                    }}
                    iconName="minus-circle"
                  />
                  {groundUnitNumber}
                  <Icons
                    onClick={() => {
                      setGroundUnitNumber(
                        unitNum <= groundUnitNumber
                          ? groundUnitNumber
                          : groundUnitNumber + 1
                      );
                    }}
                    iconName="plus-circle"
                  />
                </div>
              </div>
              {extraPayment &&
              extraPayment?.length !== 0 &&
              extraPayment[0]?.name !== "" &&
              extraPayment[0]?.price !== "" ? (
                <div className="p-4 border-b border-border-light font-bold">
                  <div>{t("Booking-request.Extra Option(s)")}</div>
                  {extraPayment &&
                    extraPayment.map((item: any, idx: number) => {
                      if (item === undefined) {
                        <></>;
                      } else {
                        return (
                          <div
                            key={idx}
                            className="flex mb-4 last:mb-0 items-center justify-between"
                          >
                            <div className="text-sm w-[166px]">
                              <div>{item && item.name}</div>
                              <div>{item && item.price}</div>
                            </div>
                            <img
                              onClick={() => {
                                handleMinusClickOption(idx, item);
                              }}
                              className="w-4 h-4"
                              alt=""
                              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/minus-circle.svg"
                            />
                            {item && item.amount}
                            <img
                              onClick={() => {
                                handlePlusClickOption(idx, item);
                              }}
                              className="w-4 h-4"
                              alt=""
                              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/plus-circle.svg"
                            />
                          </div>
                        );
                      }
                    })}
                </div>
              ) : (
                ""
              )}
              <div className="flex justify-between items-center p-4 font-bold">
                <div>{t("Product-details.Total")}</div>
                <div>
                  {totalPrice === "NaN" ? "0" : inputPriceComma(totalPrice)}￦
                </div>
              </div>
              <div className="w-full p-4 phone:block web:hidden text-center bg-pure-white mt-20">
                <Button
                  onClick={() => {
                    handlePage(1);
                  }}
                  className="bg-light-green text-center py-3 text-pure-white px-4 rounded-lg cursor-pointer"
                  btnText={t("Product-details.Booking Request")}
                />
              </div>
            </div>

            <div className="w-full flex-none p-4">
              <div className="flex justify-between items-center mb-4">
                <div className="font-bold text-main-green">
                  {t("Booking-request.Booking Details")}
                </div>
                <div
                  className="flex items-center"
                  onClick={() => {
                    !active.includes(4)
                      ? setActive((active) => [...active, 4])
                      : setActive(active.filter((x) => x !== 4));
                  }}
                >
                  <div className="mr-2 text-sm">
                    {t("Booking-box-status.Details")}
                  </div>
                  <img
                    className={`${
                      active.includes(4) ? "rotate-180" : ""
                    } w-3 h-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
              <hr className="mb-4 text-border-light" />
              {active.includes(4) ? (
                <div>
                  <img
                    src={currentImage ? currentImage?.url : ""}
                    className="rounded-lg w-full max-h-[192px]"
                    alt=""
                  />
                  <div className="font-bold text-main-green mt-2">{title}</div>
                  <div className="pt-4">
                    <div className="flex items-center mb-2">
                      <img
                        alt=""
                        src={typeInfo?.iconUrl}
                        className="w-5 h-5 mr-2"
                      />
                      <div>{t(`Private-Type-data.${typeInfo?.enName}`)}</div>
                    </div>
                    <div className="flex items-center mb-2">
                      <img
                        alt=""
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-ground.svg"
                        className="w-5 h-5 mr-2"
                      />
                      <div className="mr-4">X {unitCount}</div>
                    </div>
                    <div className="flex items-center mb-2">
                      <img
                        alt=""
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                        className="w-5 h-5 mr-2"
                      />
                      <div>
                        {t(`Parking-accessible-data.${accessibleVehicle}`)}
                      </div>
                    </div>
                    <div className="flex items-center">
                      <img
                        alt=""
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                        className="w-5 h-5 mr-2"
                      />
                      <div>{spaceSize} m²</div>
                    </div>
                  </div>
                  <hr className="text-border-light my-4" />
                  <div>
                    <div className="font-bold mb-2">
                      {t("Booking-request.Schedule")}
                    </div>
                    <div className="flex justify-between items-center text-sm">
                      <div>{t("Booking-request.Check in")}</div>
                      <div className="font-bold">{startDate}</div>
                    </div>
                    <div className="flex justify-between items-center text-sm">
                      <div>{t("Booking-request.Check out")}</div>
                      <div className="font-bold">{endDate}</div>
                    </div>
                  </div>
                  <hr className="text-border-light my-4" />
                  <div>
                    <div className="font-bold mb-2">
                      {t("Booking-request.Ground Unit")}
                    </div>
                    <div className="flex justify-between items-center text-sm">
                      <div className="max-w-[150px]">{title}</div>
                      <div className="font-bold">X {groundUnitNumber}</div>
                    </div>
                  </div>
                  {extraPayment.length !== 0 &&
                  extraPayment[0]?.amount !== 0 ? (
                    <>
                      <hr className="text-border-light my-4" />
                      {extraPayment?.map((item: any, idx: number) => {
                        return (
                          <div key={idx}>
                            <div className="font-bold mb-2">
                              Extra Option(s)
                            </div>
                            <div className="flex justify-between items-center text-sm">
                              <div>{item?.name}</div>
                              <div className="font-bold">X {item?.amount}</div>
                            </div>
                            <hr className="text-border-light my-4" />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="flex justify-between items-center mb-4">
                <div className="font-bold text-main-green">
                  {t("Booking-box-status.Booking Request By (Traveler)")}
                </div>
                <div
                  className="flex items-center"
                  onClick={() => {
                    !active.includes(5)
                      ? setActive((active) => [...active, 5])
                      : setActive(active.filter((x) => x !== 5));
                  }}
                >
                  <div className="mr-2 text-sm">
                    {t("Booking-box-status.Details")}
                  </div>
                  <img
                    className={`${
                      active.includes(5) ? "rotate-180" : ""
                    } w-3 h-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
              <hr className="text-border-light my-4" />
              {active?.includes(5) ? (
                <div>
                  <HostInput
                    input
                    formTitle="Booking-request.Name*"
                    inputValue={name}
                    onChange={(e: any) => {
                      setName(e.target.value);
                    }}
                    disabled={localName ? true : false}
                    nomargin
                  />
                  <HostInput
                    input
                    formTitle="Booking-request.Email Address*"
                    inputValue={email}
                    disabled={localEmail ? true : false}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    nomargin
                  />
                  <HostInput
                    formTitle="Booking-request.Contact Number*"
                    select
                    selectTrans="Country-code"
                    placeholder="AS-Host-info.Select Country Code"
                    onChange={(e: any) => {
                      setCountry(e.target.value);
                    }}
                    options={countryCode}
                    nomargin
                  />
                  <HostInput
                    placeholder="Booking-request.Contact Number"
                    input
                    type="text"
                    inputValue={phone === "undefined" ? "" : phone}
                    onChange={(e: any) => {
                      setPhone(e.target.value);
                    }}
                    nomargin
                  />
                  <HostInput
                    formTitle="Booking-request.Plate Number"
                    input
                    inputValue={plateNum}
                    onChange={(e: any) => {
                      setPlateNum(e.target.value);
                    }}
                    nomargin
                  />
                </div>
              ) : (
                ""
              )}
              <div
                className="flex justify-between items-center mt-4"
                onClick={() => {
                  !active.includes(6)
                    ? setActive((active) => [...active, 6])
                    : setActive(active.filter((x) => x !== 6));
                }}
              >
                <div className="font-bold text-main-green">
                  {t("Booking-request.Payment Details")}
                </div>
                <div className="flex items-center">
                  <div className="mr-2">{inputPriceComma(totalPrice)}￦</div>
                  <img
                    className={`${
                      active.includes(6) ? "rotate-180" : ""
                    } w-3 h-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
              <hr className="text-border-light my-4" />
              {active.includes(6) ? (
                <div>
                  <div className="mt-4 font-bold">
                    {t("Booking-request.Ground Charge")}
                  </div>
                  <div className="flex justify-between items-center text-sm mt-2">
                    <div className="max-w-[150px]">
                      <div>{title}</div>
                    </div>
                    <div>
                      {inputPriceComma(
                        String(Number(dayPrice) * (night?.length - 1))
                      )}
                      ￦
                    </div>
                  </div>
                  <div className="text-xs text-input-text mt-1">
                    {groundUnitNumber} {t("Booking-box-status.ground unit")} x{" "}
                    {night && night?.length - 1}{" "}
                    {t("Product-details./ 1 night per vehicle")}
                  </div>
                  <hr className="text-border-light my-4" />
                  {extraPayment?.length ? (
                    <div className="mb-4">
                      <div className="font-bold mb-4">
                        {t("Booking-request.Extra Option(s)")}
                      </div>
                      {extraPayment?.map((item: any, key: number) => {
                        return (
                          <div className="mb-2" key={key}>
                            <div>
                              <div className="flex justify-between items-center text-sm">
                                <div>{item.name}</div>
                                <div>
                                  {inputPriceComma(
                                    String(item.price * item.amount)
                                  )}
                                  ￦
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <hr className="text-border-light my-4" />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex justify-between items-center font-bold">
                    <div>{t("Booking-request.Total Amount")}</div>
                    <div>{inputPriceComma(totalPrice)}￦</div>
                  </div>
                  <hr className="text-border-light my-4" />
                </div>
              ) : (
                ""
              )}
              <div className="flex justify-between my-4">
                <div className="font-bold text-main-green">
                  {t("Booking-request.Policy")}
                </div>
                <div
                  className="flex items-center"
                  onClick={() => {
                    !active.includes(7)
                      ? setActive((active) => [...active, 7])
                      : setActive(active.filter((x) => x !== 7));
                  }}
                >
                  <div className="mr-2 text-sm">
                    {t("Booking-box-status.Details")}
                  </div>
                  <img
                    className={`${
                      active.includes(7) ? "rotate-180" : ""
                    } w-3 h-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
              {active.includes(7) ? (
                <div>
                  <div className="w-full mb-4 border-input-text border bg-[#eeeeee] rounded-lg p-2">
                    <div className="flex font-bold items-start mb-2">
                      <img
                        className="mr-2 w-5 h-5"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                        alt=""
                      />
                      <div className="text-sm font-bold">
                        {t("Booking-request.Notice")}
                      </div>
                    </div>
                    <div className="text-xs">
                      {t(
                        "Booking-request.MUST OUTDOOR will enforce following cancellation policy"
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="font-bold mb-2">
                      {t("Booking-request.Check In / Out")}
                    </div>
                    <div className="flex text-sm justify-between items-center">
                      <div>{t("Booking-request.Check in")}</div>
                      <div>{bookingTime?.checkIn}</div>
                    </div>
                    <div className="flex text-sm justify-between items-center">
                      <div>{t("Booking-request.Check out")}</div>
                      <div>{bookingTime?.checkOut}</div>
                    </div>
                  </div>
                  <hr className="text-border-light my-4" />
                  <div>
                    <div className="font-bold mb-2">
                      {t("Booking-request.Confirmation")}
                    </div>
                    <div className="text-sm">
                      <div>
                        {t(
                          "Confirmation-data.You will receive booking confirmation-hours",
                          { confirm: "24" }
                        )}
                      </div>
                      <div>
                        {t(
                          "Confirmation-data.In the event that you do not receive an email from us"
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className="text-border-light my-4" />
                  <div className="mt-2">
                    <div className="font-bold mb-2">
                      {t("Booking-request.Advance Notice")}
                    </div>
                    <div className="text-sm">
                      {" "}
                      {advanceDate
                        ? t(
                            "Advance-notice-data.Booking is not available today"
                          )
                        : t("Advance-notice-data.Booking is available today")}
                    </div>
                  </div>
                  <hr className="text-border-light my-4" />
                  <div className="mt-2">
                    <div className="font-bold mb-2">
                      {" "}
                      {t("Booking-request.Additional Rule / Policy")}
                    </div>
                    <div className="text-sm">
                      {translate ? translateExtraPolicy : extraPolicy}
                    </div>
                  </div>
                  <hr className="text-border-light my-4" />
                  <div>{t("Product-details.Cancellation & Refund Policy")}</div>
                  <div className="font-bold flex mt-2">
                    <div className="text-pure-red">
                      {t(
                        policyInfo &&
                          `Cancellation-policy-data.${changecase(
                            policyInfo?.name
                          )}`
                      )}{" "}
                    </div>
                    <div>{t("Product-details.Policy on Cancellation")}</div>
                  </div>
                  <div className="text-sm">
                    <div className="leading-[24px]">
                      {policyInfo?.firstRange !== null
                        ? t(
                            "Cancellation-policy-data.{{days}}100% refund of the payment",
                            { days: policyInfo?.firstRange }
                          ) + "\n"
                        : ""}
                      {policyInfo?.secondRange !== null
                        ? t(
                            "Cancellation-policy-data.{{days}}70% refund of the payment",
                            { days: policyInfo?.secondRange }
                          ) + "\n"
                        : ""}
                      {policyInfo?.thirdRange !== null
                        ? t(
                            "Cancellation-policy-data.{{days}}50% refund of the payment",
                            { days: policyInfo?.thirdRange }
                          ) + "\n"
                        : ""}
                      {policyInfo?.fourthRange !== null
                        ? t(
                            "Cancellation-policy-data.{{days}}30% refund of the payment",
                            { days: policyInfo?.fourthRange }
                          ) + "\n"
                        : ""}
                      {policyInfo?.fifthRange !== null
                        ? t(
                            "Cancellation-policy-data.{{days}}0% refund of the payment",
                            { days: policyInfo?.fifthRange }
                          )
                        : ""}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="w-full phone:block web:hidden text-center bg-pure-white mt-6">
                <Button
                  onClick={() => {
                    handlePage(1);
                  }}
                  className="bg-light-green text-center py-3 text-pure-white px-4 rounded-lg cursor-pointer"
                  btnText={t("Booking-request.NEXT")}
                />
              </div>
            </div>
            <div className="w-full flex-none">
              <div className="p-4 font-bold">
                <div>
                  <div>{t("Booking-request.Contact Number")}</div>
                  <div className="flex justify-between items-center mt-4 mb-2">
                    <div>
                      {country} {phone}
                    </div>
                    <div
                      onClick={() => {
                        phoneVerify();
                      }}
                      className="text-link text-sm cursor-pointer"
                    >
                      코드 전송 {">"}
                    </div>
                  </div>
                  <div className="text-xs">
                    To verify your phone number, a verification code has been
                    sent to your text message.
                  </div>
                  <hr className="text-border-light my-4" />
                </div>
                <div>
                  <div className="mb-4">
                    {t("Booking-request.Verification Code")}
                  </div>
                  <HostInput
                    isError={verifyErr}
                    messageChildren={verifyMessage}
                    inputButtonOnclick={() => {
                      verification();
                    }}
                    onChange={(e: any) => {
                      setPhoneCode(e.target.value);
                    }}
                    inputOnButton
                    inputOnButtonText={"휴대폰 인증"}
                    placeholder="Booking-request.Input Verification Code"
                    input
                    nomargin
                    inputBtnClass="right-0"
                  />
                  <hr className="text-border-light my-4" />
                </div>
                <div className="w-full phone:block web:hidden text-center bg-pure-white mt-6">
                  <Button
                    onClick={() => {
                      handlePage(1);
                    }}
                    className="bg-light-green text-center py-3 text-pure-white px-4 rounded-lg cursor-pointer"
                    btnText={t("Booking-request.NEXT")}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex-none">
              <div className="p-4 font-bold">
                <div className="flex justify-between items-center">
                  <div>{t("Booking-request.Payment Amount")}</div>
                  <div className="text-main-green">
                    {inputPriceComma(totalPrice)}￦
                  </div>
                </div>
                <hr className="text-border-light my-4" />
                <div>
                  <div>{t("Booking-request.Payment Method")}</div>
                  <div className="flex justify-between items-center my-2">
                    <Button
                      onClick={() => creditCardClick()}
                      btnText={"Booking-request.Credit Card"}
                      isIcon
                      src={`${
                        payType === "CREDIT_CARD"
                          ? "credit-card-color"
                          : "credit-card"
                      }`}
                      className={`${
                        payType === "CREDIT_CARD"
                          ? ""
                          : "bg-border-light text-border-black"
                      } py-3 font-bold px-[22px] mr-4 rounded-lg`}
                    />
                    <Button
                      onClick={() => {
                        paypalClick();
                      }}
                      btnText={"Booking-request.PayPal"}
                      isIcon
                      src={`${
                        payType === "PAYPAL" ? "paypal-color" : "paypal-black"
                      }`}
                      className={`${
                        payType === "PAYPAL"
                          ? ""
                          : "bg-border-light text-border-black"
                      } py-3 justify-center font-bold px-[22px] w-[152.23px] mr-4 rounded-lg`}
                    />
                  </div>
                  <div className="text-xs text-center">
                    {t(
                      "Booking-request.All card information is fully encrypted, secure, and protected."
                    )}
                  </div>
                </div>
                <hr className="text-border-light my-4" />
                <div
                  className="flex justify-between items-center mt-4"
                  onClick={() => {
                    !active.includes(8)
                      ? setActive((active) => [...active, 8])
                      : setActive(active.filter((x) => x !== 8));
                  }}
                >
                  <div className="font-bold text-main-green">
                    {t("Booking-request.Payment Details")}
                  </div>
                  <div className="flex items-center">
                    <div className="mr-2">{inputPriceComma(totalPrice)}￦</div>
                    <img
                      className={`${
                        active.includes(8) ? "rotate-180" : ""
                      } w-3 h-3 transition-transform`}
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                      alt=""
                    />
                  </div>
                </div>
                {active.includes(8) ? (
                  <div>
                    <div className="mt-4 font-bold">
                      {t("Booking-request.Ground Charge")}
                    </div>
                    <div className="flex justify-between items-center text-sm mt-2">
                      <div className="max-w-[150px]">
                        <div>{title}</div>
                      </div>
                      <div>
                        {inputPriceComma(
                          String(Number(dayPrice) * (night?.length - 1))
                        )}
                        ￦
                      </div>
                    </div>
                    <div className="text-xs text-input-text mt-1">
                      {groundUnitNumber} {t("Booking-request.ground unit")} x{" "}
                      {night && night?.length - 1}{" "}
                      {t("Product-details./ 1 night per vehicle")}
                    </div>
                    <hr className="text-border-light my-4" />

                    {extraPayment?.length ? (
                      <div className="mb-4">
                        <div className="font-bold mb-4">
                          {t("Booking-request.Extra Option(s)")}
                        </div>
                        {extraPayment?.map((item: any, key: number) => {
                          return (
                            <div className="mb-2" key={key}>
                              <div>
                                <div className="flex justify-between items-center text-sm">
                                  <div>{item.name}</div>
                                  <div>
                                    {inputPriceComma(
                                      String(item.price * item.amount)
                                    )}
                                    ￦
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <hr className="text-border-light my-4" />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="flex justify-between items-center font-bold">
                      <div>{t("Booking-request.Total Amount")}</div>
                      <div>{inputPriceComma(totalPrice)}￦</div>
                    </div>
                    <hr className="text-border-light my-4" />
                  </div>
                ) : (
                  ""
                )}
                <div className="w-full phone:block web:hidden text-center bg-pure-white mt-6">
                  {/* <Button className="bg-light-green rounded-lg mt-2 text-pure-white py-3 w-full" onClick={() => {payment()}} btnText={"Check out"}></Button> */}
                  <ImportPay
                    className="bg-light-green rounded-lg mt-2 text-pure-white py-3 w-full"
                    requestPageData={{ extraOption: extraValue }}
                    preChcekData={{
                      currentImage: currentImage?.url,
                      pgId: Number(_id),
                      title: title,
                      checkIn: preBookingData?.checkIn,
                      checkOut: preBookingData?.checkOut,
                      currencyInfo: currencyInfo,
                      hasCar: preBookingData?.hasCar,
                      hostInfo: preBookingData?.hostInfo,
                      price: preBookingData?.price,
                      totalPrice: preBookingData?.totalPrice,
                      unitAmount: unitAmount,
                      plateNumber: plateNumber,
                    }}
                    name={title}
                    buyer_email={localEmail ? localEmail : ""}
                    buyer_name={localName ? localName : ""}
                    amount={Number(totalPrice)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SideMenu>

      <SideMenu
        setIsOpenTab={setSpecialTab}
        isOpenTab={specialTab}
        tabTitle="Product-details.Special Facility"
      >
        {specialFacility ? (
          <div>
            <div>{translate ? translateSpecialFacility : specialFacility}</div>
          </div>
        ) : (
          <div>{t(`Common.N/A`)}</div>
        )}
      </SideMenu>

      <SideMenu
        setIsOpenTab={setSpecialTab}
        isOpenTab={specialTab}
        tabTitle="Product-details.Special Facility"
      >
        {specialFacility ? (
          <div>
            <div>{translate ? translateSpecialFacility : specialFacility}</div>
          </div>
        ) : (
          <div>{t(`Common.N/A`)}</div>
        )}
      </SideMenu>

      <SideMenu
        setIsOpenTab={setExtraTab}
        isOpenTab={extraTab}
        tabTitle="Product-details.Extra Options"
      >
        {extraOptions && !!extraOptions[0]?.title ? (
          extraOptions.map(
            (
              { title, description, maxOfNumber, price, paymentMethod }: any,
              key: number
            ) => {
              return (
                <div key={key} className="w-full">
                  <div className="flex justify-between items-center">
                    <div>{translate ? translateExtraTitle : title}</div>
                    <div>{inputPriceComma(price)}￦</div>
                  </div>
                  <div className="my-2">
                    {paymentMethod === "ON_SITE_PAYMENT" ? (
                      <div className="flex items-center">
                        <img
                          className="w-6 h-6"
                          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/on-site-payment-only.svg"
                          alt=""
                        />
                        <div className="text-sm ml-2">
                          {t("Extra-payment-method.On-Site Payment")}
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <img
                          className="w-6 h-6"
                          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-in-extra-option.svg"
                          alt=""
                        />
                        <div className="text-sm ml-2 text-main-green">
                          {t("Extra-payment-method.Available in Extra Option")}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>{translate ? translateExtraDesc : description}</div>
                  <hr className="my-4 last:hidden" />
                </div>
              );
            }
          )
        ) : (
          <div>{t(`Common.N/A`)}</div>
        )}
      </SideMenu>
      <SideMenu
        noPadding
        setIsOpenTab={setAvailableTab}
        isOpenTab={availableTab}
        tabTitle="Product-details.Available Ground"
      >
        <div
          className="w-full min-h-[208px] bg-cover bg-center"
          style={{
            backgroundImage: `url(${newImageList && newImageList[0]?.url})`,
          }}
        />
        <div className="p-4">
          <div className="text-xl font-bold">
            {translate ? translateTitle : title}
          </div>
          <hr className="my-4 text-input-border-color" />
          <div>
            <div>{t("Product-details.Ground Type")}</div>
            <div className="flex mt-2 items-center">
              <img
                src={typeInfo?.iconUrl}
                className="w-6 h-6"
                alt={typeInfo?.displayName}
              />
              <div className="ml-2">
                {t(typeInfo && `Private-Type-data.${typeInfo.enName}`)}
              </div>
            </div>
            <hr className="my-4 text-input-border-color" />
          </div>
          <div>
            <div>{t("Product-details.Ground Details")}</div>
            <div className="flex justify-between items-center mt-2">
              <div className="flex items-center">
                <img
                  className="w-6 h-6 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-ground.svg"
                  alt=""
                />
                <div>{t("Product-details.Number of Unit")}</div>
              </div>
              <div className="max-w-[130px] text-sm">
                {unitCount} {t("Product-details.Ground Unit")}
              </div>
            </div>
            <div className="flex justify-between items-center my-2">
              <div className="flex items-center">
                <img
                  className="w-6 h-6 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                  alt=""
                />
                <div>{t("Product-details.Accessible Vehicle")}</div>
              </div>
              <div className="max-w-[130px] text-sm">
                {t(`Parking-accessible-data.${accessibleVehicle}`)}
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <img
                  className="w-6 h-6 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                  alt=""
                />
                <div>{t("Product-details.Ground Size")}</div>
              </div>
              <div className="max-w-[130px] text-sm">{spaceSize} m²</div>
            </div>
            <hr className="my-4 text-input-border-color" />
          </div>
          <div className="flex justify-between items-center">
            <div>{t("Product-details.Price")}</div>
            <div className="max-w-[160px] text-sm">
              From {minimumPrice()} {t("Product-details./ 1 night per vehicle")}
            </div>
          </div>
        </div>
      </SideMenu>
      <SideMenu
        setIsOpenTab={setLocationTab}
        isOpenTab={locationTab}
        tabTitle="Product-details.Location"
      >
        <div className="mb-4 w-full h-64">
          <GoogleMap
            mapLocation={{
              lat: Number(`${indicator && indicator.latitude - Number(x)}`),
              lng: Number(`${indicator && indicator.longitude + Number(y)}`),
            }}
          />
        </div>
        <div className="font-bold mb-2">{t("Product-details.Location")}</div>
        <div className="text-sm flex items-center">
          <img
            className="w-6 h-6 mr-2"
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/real-address.svg"
            alt=""
          />
          <div>
            {t("Product-details.Information provided after booking confirmed")}
          </div>
        </div>
        <hr className="my-4 text-input-border-color" />
        <div className="w-full">
          <div className="font-bold mb-2">
            {t("Product-details.Nearby Facilities")}
          </div>
          {nearbyFacilities?.length !== 0 ? (
            nearbyFacilities &&
            nearbyFacilities.map((item: any, i: number) => {
              if (item !== null) {
                return (
                  <div
                    key={i}
                    className="flex items-center mb-4 last:mb-0 justify-between w-full"
                  >
                    <div className="flex items-center">
                      <img
                        className="mr-2 w-5 h-5"
                        alt=""
                        src={
                          item.facility === "EV_CHARGING_STATION"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/ev-charge-station.svg"
                            : item.facility === "CONVENIENCE_STORE"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/convenience-store.svg"
                            : item.facility === "GAS_STATION"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/gas-station.svg"
                            : item.facility === "SUPERMARKET"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/supermarket.svg"
                            : item.facility === "TOILET_24HR"
                            ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/24hr-toilet.svg"
                            : ""
                        }
                      />
                      <div>
                        {t(`Nearby-facility.${changeFacility(item.facility)}`)}
                      </div>
                    </div>
                    <div className="flex text-sm items-center max-w-[200px]">
                      <div>{item.name}</div>
                      <div> ({item.distance}Km)</div>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <div>{t(`Common.N/A`)}</div>
          )}
          <hr className="my-4 text-input-border-color" />
        </div>
        <div>
          <div className="font-bold mb-2">
            {t("Product-details.Recommending Tourist Spot")}
          </div>
          {touristSpots &&
          !!touristSpots[0]?.title &&
          !!touristSpots[0]?.description &&
          !!touristSpots[0]?.photoUrl ? (
            touristSpots.map(
              ({ title, description, photoUrl }: any, key: number) => {
                return (
                  <div
                    key={key}
                    className="w-64 h-[161px] shadow-card rounded-lg relative"
                  >
                    <div
                      onClick={() => {
                        touristInfo(
                          translate ? translateTourTitle : title,
                          translate ? translateTourDesc : description,
                          photoUrl
                        );
                      }}
                      className="opacity-0 hover:opacity-50 flex justify-center w-full h-32 cursor-pointer items-center hover:bg-black rounded-t-lg absolute"
                    >
                      <Icons iconName="view-detail" className="w-8 h-8" />
                    </div>
                    <img
                      className="w-full h-32 rounded-t-lg"
                      src={photoUrl}
                      alt=""
                    />
                    <div className="w-full h-[33px] rounded-b-lg bg-pure-white flex justify-center items-center text-sm ">
                      {translate ? translateTourTitle : title}
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <div>{t(`Common.N/A`)}</div>
          )}
        </div>
      </SideMenu>
      <SideMenu
        setIsOpenTab={setBookingTab}
        isOpenTab={bookingTab}
        tabTitle="Product-details.Policy Details"
      >
        <div className="font-bold mb-2">
          {t("Product-details.Confirmation")}
        </div>
        <div>
          <div>
            {t(
              "Confirmation-data.You will receive booking confirmation-hours",
              { confirm: "24" }
            )}
          </div>
          <div>
            {t(
              "Confirmation-data.In the event that you do not receive an email from us"
            )}
          </div>
          <hr className="my-4 text-input-border-color" />
        </div>
        <div className="font-bold mb-2">
          {t("Product-details.Advance Notice")}
        </div>
        <div>
          {!advanceDate
            ? t("Advance-notice-data.Booking is not available today")
            : t("Advance-notice-data.Booking is available today")}
        </div>
        <hr className="my-4 text-input-border-color" />
        <div className="font-bold mb-2">
          {t("Product-details.Additional Rule / Policy")}
        </div>
        <div className="break-all">
          {translate ? (
            translateExtraPolicy
          ) : extraPolicy ? (
            extraPolicy
          ) : (
            <div>{t(`Common.N/A`)}</div>
          )}
        </div>
      </SideMenu>
      <SideMenu
        setIsOpenTab={setPolicyTab}
        isOpenTab={policyTab}
        tabTitle="Product-details.Cancellation & Refund Policy"
      >
        <div className="font-bold mb-4 flex">
          <div className="text-pure-red">
            {t(
              policyInfo &&
                `Cancellation-policy-data.${changecase(policyInfo.name)}`
            )}{" "}
          </div>
          <div>{t("Product-details.Policy on Cancellation")}</div>
        </div>
        <div>
          {policyInfo?.firstRange !== null
            ? t("Cancellation-policy-data.{{days}}100% refund of the payment", {
                days: policyInfo?.firstRange,
              }) + "\n"
            : ""}
          {policyInfo?.secondRange !== null
            ? t("Cancellation-policy-data.{{days}}70% refund of the payment", {
                days: policyInfo?.secondRange,
              }) + "\n"
            : ""}
          {policyInfo?.thirdRange !== null
            ? t("Cancellation-policy-data.{{days}}50% refund of the payment", {
                days: policyInfo?.thirdRange,
              }) + "\n"
            : ""}
          {policyInfo?.fourthRange !== null
            ? t("Cancellation-policy-data.{{days}}30% refund of the payment", {
                days: policyInfo?.fourthRange,
              }) + "\n"
            : ""}
          {policyInfo?.fifthRange !== null
            ? t("Cancellation-policy-data.{{days}}0% refund of the payment", {
                days: policyInfo?.fifthRange,
              })
            : ""}
        </div>
      </SideMenu>
      {allPhotoModal && newImageList ? (
        <FullSizeModal
          isOpen={() => {
            setAllPhotoModal(!allPhotoModal);
          }}
          imgList={newImageList}
        />
      ) : (
        ""
      )}

      {touristModal === true ? (
        <FullSizeModal
          tourist
          TouristTitle={touristDetail?.title}
          TouristDesc={touristDetail?.desc}
          isOpen={() => {
            setTouristModal((touristModal) => !touristModal);
          }}
          imgList={touristDetail?.imgUrl}
        />
      ) : (
        ""
      )}

      {/* 모바일 */}
      <div className="web:hidden phone:block">
        <div
          onClick={() => {
            allPhotoView();
          }}
          className="w-full h-64 relative"
        >
          <img
            className="object-cover w-full h-full"
            src={newImageList[0]?.url}
            alt=""
          />
          <div className="absolute bottom-4 right-4 z-10 px-4 py-2 font-bold bg-[rgba(0,0,0,.4)] text-pure-white shadow-ms rounded-lg">
            {t("Product-details.View photos")}
          </div>
        </div>
        <div className="pt-4 px-4 pb-[72px] text-left w-full">
          <div className="flex flex-col">
            <div className="text-xl font-bold">
              {translate ? translateTitle : title}
            </div>
            <div className="flex my-2 items-baseline">
              <div className="text-xs text-input-text">from</div>
              <div className="font-bold ml-1">{minimumPrice()}</div>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <img
                  className="w-4 h-4"
                  src={
                    "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/rating-star-o.svg"
                    // "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/rating-star-e.svg"
                  }
                  alt=""
                />
                <div className="font-bold mx-1">NEW</div>
                {/* <div className="text-xs text-input-text">0 Reviews</div> */}
              </div>
              <div className="flex">
                <img
                  className="w-6 h-6"
                  src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/wishlist-black.svg`}
                  alt=""
                />
                <img
                  className="w-6 h-6 ml-4"
                  src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/share.svg`}
                  alt=""
                />
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div className="text-sm">
            <div className="flex mb-4 items-center">
              <img
                src={typeInfo?.iconUrl}
                className="w-6 h-6"
                alt={typeInfo?.displayName}
              />
              <div className="ml-2">
                {t(typeInfo && `Private-Type-data.${typeInfo.enName}`)}
              </div>
            </div>
            {locationThemes &&
              locationThemes?.map((item: any, key: any) => {
                const data = item.themeInfo;
                return (
                  <div key={key} className="flex items-center mb-4">
                    <img
                      src={data?.iconUrl}
                      className="w-6 h-6"
                      alt={data?.displayName}
                    />
                    <div className="ml-2">
                      {t(`Theme-data.${data?.enName}`)}
                    </div>
                  </div>
                );
              })}

            <div className="flex justify-between mb-4 items-center">
              <div className="flex items-center">
                <img
                  className="w-6 h-6"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                  alt=""
                />
                <div className="hover:text-light-green ml-2">
                  {t(
                    accessibleVehicle &&
                      `Parking-accessible-data.${accessibleVehicle}`
                  )}
                </div>
              </div>
              <Icons
                onClick={() => {
                  setVehicle((vehicle) => !vehicle);
                }}
                iconName="question"
              ></Icons>
            </div>
            <div className="flex mb-4 items-center">
              <img
                className="w-6 h-6"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/cancellation-policy.svg"
                alt=""
              />
              <div className="ml-2">
                {t(
                  policyInfo &&
                    `Cancellation-policy-data.${changecase(policyInfo?.name)}`
                )}{" "}
                {t(`Booking-request.Policy on Cancellation`)}
              </div>
            </div>
            <div className="flex mb-4 items-center">
              <img
                className="w-6 h-6"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                alt=""
              />
              <div className="ml-2">
                {t(
                  confirmMethod &&
                    `Confirmation-data.${changecase(confirmMethod)}`
                )}
              </div>
            </div>
            <div className="flex items-center">
              <img
                className="w-6 h-6"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                alt=""
              />
              <div className="ml-2">
                {t(
                  advanceDate
                    ? "Advance-notice-data.Available Today"
                    : "Advance-notice-data.Unavailable Today"
                )}
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div className="flex justify-between items-center">
              <div>{t("Product-details.Description")}</div>
              <div
                onClick={() => {
                  setTranslate((translate) => !translate);
                }}
                className="underline text-link inline-block font-bold hover:text-light-green cursor-pointer"
              >
                {translate ? "Show original language" : "Translate"}
              </div>
            </div>
            <div className="text-sm mt-2">
              {translate ? translateDesc : description}
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div className="flex justify-between items-center">
              <div className="text-xl font-bold">
                {t("Product-details.Hosted By") +
                  " " +
                  `${hostInfo && hostInfo.hostName}`}
              </div>
              <div
                onClick={() => {
                  if (!isMember()) {
                    alert("로그인이 되어있어야 가능합니다");
                    navigate("/log-in", {
                      replace: true,
                      state: { nextPath: `/details/${id}` },
                    });
                  } else {
                    // setInquiryModal(true);
                    alert(
                      "좀 더 빠른 답변을 위해 지금은 채널톡으로 문의해주세요"
                    );
                  }
                }}
                className="px-4 py-2 text-sm border border-input-border-color rounded-lg cursor-pointer flex items-center"
              >
                <img
                  className="w-4 h-4 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/message.svg"
                  alt=""
                />
                <div>{t("Message-box.1:1 Inquiry")}</div>
              </div>
            </div>
            <div className="flex mt-4">
              <img
                className="w-12 h-12"
                src={
                  hostInfo && hostInfo.imageUrl !== ""
                    ? hostInfo?.imageUrl
                    : `https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg`
                }
                alt=""
              />
              <div className="ml-4 text-sm">
                <div className="mb-2">{t("Product-details.Host Verified")}</div>
                <div className="mb-2">
                  {t(`Product-details.Joined in {{Joined_date}}`, {
                    Joined_date: create,
                  })}
                </div>
                <div className="mb-2">
                  {t("Product-details.Response Rate")}: {hostInfo?.resRate}%
                </div>
                <div>
                  {t("Product-details.Language")}:{" "}
                  {hostInfo?.languageCode[0]
                    ? t(`Language.${hostInfo?.languageCode[0]}`)
                    : ""}{" "}
                  {hostInfo?.languageCode[1]
                    ? t(`Language.${hostInfo?.languageCode[1]}`)
                    : ""}{" "}
                  {hostInfo?.languageCode[2]
                    ? t(`Language.${hostInfo?.languageCode[2]}`)
                    : ""}{" "}
                </div>
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div className="text-xl font-bold">
              {t("Product-details.Ground Details")}
            </div>
            <div
              onClick={() => {
                !active.includes(0)
                  ? setActive((active) => [...active, 0])
                  : setActive(active.filter((x) => x !== 0));
              }}
              className="mt-4 cursor-pointer"
            >
              <div>{t(`Product-details.Facilty`)}</div>
              <div
                className={`flex justify-between items-center pb-2 border-border-light border-b`}
              >
                <div
                  className={`flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap`}
                >
                  {facilities && facilities.length !== 0 ? (
                    facilities.map((item: any, key: number) => {
                      const data = item.facilityList;
                      return (
                        <img
                          key={key}
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-6 h-6 mr-4 mt-2 last:mr-0"
                        />
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(0) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(0) ? (
                <div className="mt-2 h-full transition-all">
                  {facilities && facilities.length !== 0 ? (
                    facilities.map((item: any, key: number) => {
                      const data = item.facilityList;
                      return (
                        <div
                          key={key}
                          className="mb-4 last:mb-0 flex items-center"
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className={`w-6 h-6`}
                          />
                          <div className="ml-1">
                            {t(`Facility-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="my-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div>
            <div
              onClick={() => {
                !active.includes(1)
                  ? setActive((active) => [...active, 1])
                  : setActive(active.filter((x) => x !== 1));
              }}
              className="mt-4 cursor-pointer"
            >
              <div>{t(`Product-details.Activity`)}</div>
              <div
                className={`flex justify-between items-center pb-2 border-border-light border-b`}
              >
                <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                  {activities && activities.length !== 0 ? (
                    activities.map((item: any, key: number) => {
                      const data = item.activity;
                      return (
                        <img
                          key={key}
                          src={data?.iconUrl}
                          alt={data?.enName}
                          className="w-6 h-6 mr-4 mt-2 last:mr-0"
                        />
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(1) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(1) ? (
                <div
                  className={`mt-2 ${
                    active.includes(1) ? "block max-h-full" : "hidden max-h-0"
                  } transition-all`}
                >
                  {activities && activities.length !== 0 ? (
                    activities.map((item: any, key: number) => {
                      const data = item.activity;
                      return (
                        <div
                          key={key}
                          className="mb-4 last:mb-0 flex items-center"
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6"
                          />
                          <div className="ml-1">
                            {t(`Activity-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="my-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div>
            <div
              onClick={() => {
                !active.includes(2)
                  ? setActive((active) => [...active, 2])
                  : setActive(active.filter((x) => x !== 2));
              }}
              className="cursor-pointer mt-4"
            >
              <div>{t(`Product-details.Allowed Details`)}</div>
              <div
                className={`flex justify-between items-center pb-2 border-border-light border-b`}
              >
                <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                  {restricts && restricts.length !== 0 ? (
                    restricts.map((item: any, key: number) => {
                      const data = item.allowedList;
                      if (data.allowed === true) {
                        return (
                          <img
                            key={key}
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6 mr-4 mt-2 last:mr-0"
                          />
                        );
                      }
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(2) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
            </div>
            {active.includes(2) ? (
              <div className="mt-2">
                {restricts && restricts.length !== 0 ? (
                  restricts.map((item: any, key: number) => {
                    const data = item.allowedList;
                    if (data.allowed === true) {
                      return (
                        <div
                          key={key}
                          className="mb-4 last:mb-0 flex items-center"
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6"
                          />
                          <div className="ml-1">
                            {t(`Allowed-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
                <hr className="my-4 text-border-light" />
              </div>
            ) : (
              ""
            )}
          </div>

          <div>
            <div
              onClick={() => {
                !active.includes(3)
                  ? setActive((active) => [...active, 3])
                  : setActive(active.filter((x) => x !== 3));
              }}
              className="mt-4 cursor-pointer"
            >
              <div>{t(`Product-details.Prohibited Details`)}</div>
              <div
                className={`flex justify-between items-center pb-2 border-border-light border-b`}
              >
                <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                  {restricts && restricts.length !== 0 ? (
                    restricts.map((item: any, key: number) => {
                      const data = item.allowedList;
                      if (data.allowed === false) {
                        return (
                          <img
                            key={key}
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6 mr-4 mt-2 last:mr-0"
                          />
                        );
                      }
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(3) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(3) ? (
                <div className="mt-2">
                  {restricts && restricts.length !== 0 ? (
                    restricts.map((item: any, key: number) => {
                      const data = item.allowedList;
                      if (data.allowed === false) {
                        return (
                          <div
                            key={key}
                            className="mb-4 last:mb-0 flex items-center"
                          >
                            <img
                              src={data.iconUrl}
                              alt={data.enName}
                              className="w-6 h-6"
                            />
                            <div className="ml-1">
                              {t(`Prohibited-data.${data.enName}`)}
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="mt-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              onClick={() => specialTabToggle()}
              className="flex items-center cursor-pointer justify-between border-b pb-4 mb-4 border-border-light mt-4"
            >
              <div>{t("Product-details.Special Facility")}</div>
              <img
                className="w-3 h-3"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                alt=""
              />
            </div>
            <div
              onClick={() => extraTabToggle()}
              className="flex items-center cursor-pointer justify-between border-b pb-4 border-border-light"
            >
              <div>{t("Product-details.Extra Options")}</div>
              <img
                className="w-3 h-3"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                alt=""
              />
            </div>
          </div>

          <div className="mt-4">
            <div className="text-xl font-bold mb-4">
              {t("Product-details.Available Ground")}
            </div>
            <img
              className="w-66 h-40 rounded-t-lg bg-center bg-cover"
              src={newImageList.length !== 0 ? newImageList[0]?.url : ""}
              alt=""
            />
            <div className="w-66 rounded-b-lg shadow-ms p-2">
              <div className="flex justify-between items-center">
                <div className="w-[191px] whitespace-nowrap font-bold text-main-green text-tiny overflow-hidden text-ellipsis">
                  {translate ? translateTitle : title}
                </div>
                <div
                  onClick={() => availableTabToggle()}
                  className="text-link cursor-pointer text-xs"
                >
                  {t("Booking-box-status.Details")} {">"}
                </div>
              </div>
              <div className="flex mt-2 justify-between items-center">
                <div className="flex items-center">
                  <img
                    src={typeInfo?.iconUrl}
                    className="w-6 h-6"
                    alt={typeInfo?.displayName}
                  />
                  <div className="ml-2 text-sm">
                    {t(typeInfo && `Private-Type-data.${typeInfo.enName}`)}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="text-sm font-bold">{minimumPrice()}</div>
                  <div className="text-xs text-input-text">
                    {t("Product-details./ 1 night per vehicle")}
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div className="text-xl font-bold mb-4">
              {t("Product-details.Location")}
            </div>
          </div>
          <div className="my-8 w-full h-64">
            <GoogleMap
              mapLocation={{
                lat: Number(`${indicator && indicator.latitude - Number(x)}`),
                lng: Number(`${indicator && indicator.longitude + Number(y)}`),
              }}
            />
          </div>
          <div
            onClick={() => locationTabToggle()}
            className="cursor-pointer border-b pb-4 mb-4 border-border-light"
          >
            <div className="flex items-center justify-between">
              <div>{t("Product-details.Location")}</div>
              <img
                className="w-3 h-3"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                alt=""
              />
            </div>
            <div className="mt-4">
              {t(
                "Product-details.Information provided after booking confirmed"
              )}
            </div>
          </div>

          <div>
            <div className="text-xl font-bold mb-4">
              {t("Product-details.Policy")}
            </div>
            <div>{t(`Product-details.Check In / Out`)}</div>
            <div>
              <div className="flex justify-between items-center text-sm my-2">
                <div>{t("Product-details.Check in")}</div>
                <div>{bookingTime && bookingTime.checkIn}</div>
              </div>
              <div className="flex justify-between items-center text-sm">
                <div>{t("Product-details.Check out")}</div>
                <div>{bookingTime && bookingTime.checkOut}</div>
              </div>
              <hr className="my-4 text-border-light" />
            </div>
            <div
              onClick={() => bookingTabToggle()}
              className="flex items-center cursor-pointer justify-between border-b pb-4 mb-4 border-border-light"
            >
              <div>{t(`Product-details.Policy Details`)}</div>
              <img
                className="w-3 h-3"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                alt=""
              />
            </div>
            <div
              onClick={() => policyTabToggle()}
              className="cursor-pointer border-b pb-4 mb-4 border-border-light"
            >
              <div className="flex items-center justify-between">
                <div>{t(`Product-details.Cancellation & Refund Policy`)}</div>
                <img
                  className="w-3 h-3"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                  alt=""
                />
              </div>
              <div className="mt-4 flex">
                <div className="text-red">
                  {t(
                    policyInfo &&
                      `Cancellation-policy-data.${changecase(policyInfo.name)}`
                  )}{" "}
                </div>
                <div>{t("Booking-request.Policy on Cancellation")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 웹 */}
      <div className="web:block phone:hidden text-left mx-auto lg:max-w-1216 p-8">
        <div className="grid grid-cols-2 w-full gap-1">
          <div
            className="w-full rounded-lg bg-no-repeat rounded-bl-lg h-[500px] bg-center bg-cover block"
            style={{
              backgroundImage: `url(${
                newImageList.length !== 0 && newImageList[0].url
              })`,
            }}
          />
          <div className="grid relative grid-cols-2 gap-1 grid-rows-2">
            {newImageList?.map((item: any, idx: number) => {
              if (idx === 0 || idx > 4) {
                return "";
              } else {
                return (
                  <div
                    key={idx}
                    className="bg-center bg-no-repeat object-cover rounded-lg bg-cover"
                    style={{
                      backgroundImage: `url(${item.url})`,
                    }}
                  />
                );
              }
            })}
            <div
              className="absolute z-10 px-4 py-2 font-bold bg-gainsboro bottom-0 right-8 mb-4 cursor-pointer shadow-ms rounded-lg"
              onClick={() => {
                allPhotoView();
              }}
            >
              {/* View {newImageList?.length} Photos */}
              {t("Product-details.View photos")}
            </div>
          </div>
        </div>
        <div className="flex pt-8">
          <div className="w-[800px]">
            <div className="text-sm">
              Home &nbsp; {">"} &nbsp;
              {destinationInfo && destinationInfo.country} &nbsp; {">"} &nbsp;
              {destinationInfo && t(`Kor-state.${destinationInfo.state}`)}{" "}
              &nbsp; {">"} &nbsp;
              {translate ? translateTitle : title}
            </div>
            <div className="py-8">
              <div className="font-bold text-3.5xl mb-8">
                {translate ? translateTitle : title}
              </div>
              <div className="flex flex-wrap justify-start">
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src={typeInfo?.iconUrl}
                    className="w-5 h-5"
                    alt={typeInfo?.displayName}
                  />
                  <div className="ml-2">
                    {t(typeInfo && `Private-Type-data.${typeInfo?.enName}`)}
                  </div>
                </div>
                {locationThemes &&
                  locationThemes?.map((item: any, key: any) => {
                    const data = item.themeInfo;
                    return (
                      <div key={key} className="flex items-center mr-8 mb-8">
                        <img
                          src={data?.iconUrl}
                          className="w-5 h-5"
                          alt={data?.displayName}
                        />
                        <div className="ml-2">
                          {t(`Theme-data.${data?.enName}`)}
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="flex flex-wrap justify-start">
                <div className="flex items-center mr-8 mb-8">
                  <Icons
                    iconName="cancellation-policy"
                    className="mr-2 w-5 h-5"
                  />
                  <p>
                    {t(
                      policyInfo &&
                        `Cancellation-policy-data.${changecase(
                          policyInfo?.name
                        )}`
                    )}
                  </p>
                </div>
                <div className="flex items-center mr-8 mb-8">
                  <Icons
                    iconName="booking-available"
                    className="mr-2 w-5 h-5"
                  />
                  <p>
                    {t(
                      confirmMethod &&
                        `Confirmation-data.${changecase(confirmMethod)}`
                    )}
                  </p>
                </div>
                <div className="flex items-center mr-8 mb-8">
                  <Icons
                    iconName="booking-available"
                    className="mr-2 w-5 h-5"
                  />
                  <p>
                    {t(
                      advanceDate
                        ? "Advance-notice-data.Available Today"
                        : "Advance-notice-data.Unavailable Today"
                    )}
                  </p>
                </div>
              </div>
              <div
                onClick={() => {
                  setTranslate((translate) => !translate);
                }}
                className="underline text-link inline-block font-bold text-xl mb-4 hover:text-light-green cursor-pointer"
              >
                {translate ? "Show original language" : "Translate"}
              </div>
              <div className="text-base break-words">
                {translate ? translateDesc : description}
              </div>
            </div>

            <DetailTheme
              needPosition="relative"
              theme={
                t("Product-details.Hosted By") +
                " " +
                `${hostInfo && hostInfo.hostName}`
              }
            >
              <div
                onClick={() => {
                  if (!isMember()) {
                    alert("로그인이 되어있어야 가능합니다");
                    navigate("/log-in", {
                      replace: true,
                      state: { nextPath: `/details/${id}` },
                    });
                  } else {
                    // setInquiryModal(true);
                    alert(
                      "좀 더 빠른 답변을 위해 지금은 채널톡으로 문의해주세요"
                    );
                  }
                }}
                className="absolute -top-[69.3px] right-0 px-4 py-2 text-sm border border-input-border-color rounded-lg cursor-pointer flex items-center"
              >
                <img
                  className="w-4 h-4 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/message.svg"
                  alt=""
                />
                <div>{t("Message-box.1:1 Inquiry")}</div>
              </div>
              <DetailInfo imgUrl={hostInfo && hostInfo.imageUrl}>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t("Product-details.Host Verified")}
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t(`Product-details.Joined in {{Joined_date}}`, {
                    Joined_date: create,
                  })}
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t("Product-details.Response Rate")}: {hostInfo?.resRate}%
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px]">
                  {t("Product-details.Language")}:{" "}
                  {hostInfo?.languageCode[0]
                    ? t(`Language.${hostInfo?.languageCode[0]}`)
                    : ""}{" "}
                  {hostInfo?.languageCode[1]
                    ? t(`Language.${hostInfo?.languageCode[1]}`)
                    : ""}{" "}
                  {hostInfo?.languageCode[2]
                    ? t(`Language.${hostInfo?.languageCode[2]}`)
                    : ""}{" "}
                </div>
              </DetailInfo>
            </DetailTheme>
            <DetailTheme theme="Product-details.Ground Details">
              <DetailInfo title="Product-details.Facilty">
                {facilities && facilities.length !== 0 ? (
                  facilities.map((item: any, key: number) => {
                    const data = item.facilityList;
                    return (
                      <div
                        key={key}
                        className="flex items-center w-[191px] mr-[9px] mb-4 "
                      >
                        <img
                          src={data?.iconUrl}
                          alt={data?.enName}
                          className="w-5 h-5"
                        />
                        <div className="ml-2">
                          {t(`Facility-data.${data?.enName}`)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Activity">
                {activities && activities.length !== 0 ? (
                  activities.map((item: any, key: number) => {
                    const data = item.activity;
                    return (
                      <div
                        key={key}
                        className="flex items-center w-[191px] mr-[9px] mb-4 "
                      >
                        <img
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-5 h-5"
                        />
                        <div className="ml-1">
                          {t(`Activity-data.${data.enName}`)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Allowed Details">
                {restricts && restricts.length !== 0 ? (
                  restricts.map((item: any, key: number) => {
                    const data = item.allowedList;
                    if (data.allowed === true) {
                      return (
                        <div
                          key={key}
                          className="flex items-center w-[191px] mr-[9px] mb-4 "
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-5 h-5"
                          />
                          <div className="ml-1">
                            {t(`Allowed-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Prohibited Details">
                {restricts && restricts.length !== 0 ? (
                  restricts.map((item: any, key: number) => {
                    const data = item.allowedList;
                    if (data.allowed === false) {
                      return (
                        <div
                          key={key}
                          className="flex items-center w-[191px] mr-[9px] mb-4 "
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-5 h-5"
                          />
                          <div className="ml-1">
                            {t(`Prohibited-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Special Facility">
                {specialFacility ? (
                  <div className="break-all">
                    {translate ? translateSpecialFacility : specialFacility}
                  </div>
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo
                title="Product-details.Extra Options"
                className="flex-col"
              >
                {extraOptions &&
                !!extraOptions[0]?.title &&
                !!extraOptions[0]?.description ? (
                  extraOptions.map(
                    (
                      {
                        title,
                        description,
                        maxOfNumber,
                        price,
                        paymentMethod,
                      }: any,
                      key: number,
                      item: any
                    ) => {
                      return (
                        <div key={key} className="last:mb-0 mb-4">
                          <div className="flex">
                            {paymentMethod === "ON_SITE_PAYMENT" ? (
                              <Icons
                                className="mr-2 mb-2 w-5 h-5"
                                iconName="on-site-payment-only"
                              />
                            ) : (
                              <Icons
                                className="mr-2 mb-2 w-5 h-5"
                                iconName="available-in-extra-option"
                              />
                            )}
                            <div className="mr-2">
                              {translate ? translateExtraTitle : title}
                            </div>
                            <div>{inputPriceComma(price)}￦</div>
                          </div>
                          <div>
                            {translate ? translateExtraDesc : description}
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
            </DetailTheme>

            <DetailTheme
              needPosition="relative"
              theme="Product-details.Available Ground"
              _ref={element}
            >
              <div className="w-[full] h-40 mb-8 shadow-card rounded-lg flex text-left">
                <img
                  className="w-66 bg-[#eee] rounded-l-lg"
                  src={newImageList.length !== 0 ? newImageList[0]?.url : ""}
                  alt=""
                />
                <div className="w-[380px] p-2">
                  <div className="text-xl font-bold text-ellipsis whitespace-nowrap overflow-hidden">
                    {translate ? translateTitle : title}
                  </div>

                  <div className="flex mt-6 h-5 items-center">
                    <img
                      src={typeInfo?.iconUrl}
                      className="w-5 h-5"
                      alt={typeInfo?.displayName}
                    />
                    <div className="ml-2">
                      {t(typeInfo && `Private-Type-data.${typeInfo.enName}`)}
                    </div>
                  </div>
                  <div className="flex my-4 h-5 items-center">
                    <img
                      className="w-6 h-5 mr-2"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-ground.svg"
                      alt=""
                    />
                    <div className="mr-4">X {unitCount}</div>
                    <img
                      className="w-5 h-5 mr-2"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                      alt=""
                    />
                    <div className="flex-1 whitespace-nowrap text-ellipsis overflow-hidden">
                      <div>
                        {t(
                          accessibleVehicle &&
                            `Parking-accessible-data.${accessibleVehicle}`
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex h-5 items-center">
                    <img
                      className="w-5 h-5 mr-2"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                      alt=""
                    />
                    <div>{spaceSize} m²</div>
                  </div>
                </div>
                <div className="min-w-[144px] h-40 border-l border-input-text rounded-r-lg p-2 flex flex-col justify-between">
                  <div className="text-xs">
                    <span className="text-xl font-bold">{minimumPrice()}</span>
                    {"\n"}
                    {t("Product-details./ 1 night per vehicle")}
                  </div>
                  <Button
                    onClick={() => {
                      handleOpen();
                    }}
                    className="w-32 py-2 bg-light-green text-pure-white rounded-lg text-center"
                    btnText={t(
                      isOpen
                        ? "Product-details.Cancel"
                        : "Product-details.Select"
                    )}
                  />
                </div>
              </div>

              {isOpen ? (
                <div
                  className={`absolute w-80 ${
                    isOpen && currentLan !== "j"
                      ? "top-[-256px]"
                      : currentLan === "j" && isOpen
                      ? "top-[-329px]"
                      : "top-0"
                  }  -right-[352px] rounded-t-lg border-b border-input-text`}
                >
                  <div className="px-2 py-2 text-sm text-pure-white bg-light-green rounded-t-lg">
                    {t(
                      "Parking-accessible-data.Parking-accessible-tooltip-title"
                    )}
                  </div>
                  <div className="px-2 py-4 border-x border-input-text bg-pure-white">
                    <ul>
                      {vehicles?.map((item, idx) => {
                        const title = item.title;
                        return (
                          <li
                            className={`${
                              accessibleVehicle === title
                                ? "text-light-green"
                                : ""
                            }
                            ${
                              title === "Only Backpacker"
                                ? "text-main-green"
                                : ""
                            }
                            `}
                            key={idx}
                          >
                            {t(`Parking-accessible-data.${title}`)}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              ) : (
                ""
              )}
              {isOpen ? (
                <div className="absolute top-0 -right-[352px]">
                  <div className="w-80 rounded-t-lg border-b border-input-text">
                    <div className="px-2 py-2 text-pure-white bg-light-green rounded-t-lg">
                      {t("Product-details.Booking Details")}
                    </div>
                    <div className="px-2 py-4 border-x border-input-text bg-pure-white">
                      <div className="font-bold  text-base">
                        {t("Product-details.Selected Ground")}
                      </div>
                      <div className="text-main-green">
                        {translate ? translateTitle : title}
                      </div>
                    </div>
                    <div className="px-2 py-4 border-x bg-pure-white border-t border-input-text">
                      <div className="mb-2 text-base font-bold">
                        {t("Product-details.Dates")}
                      </div>
                      <div className="text-sm justify-between items-center">
                        <DayPicker
                          advanceDate={advanceDate}
                          datePrice={setDayPrice}
                          calendars={calendars}
                          from_={(date: any) => {
                            setStartDate(date);
                          }}
                          to_={(date: any) => {
                            setEndDate(date);
                          }}
                        />
                      </div>
                    </div>
                    <div className="px-2 py-4 bg-pure-white border-x border-t border-input-text">
                      <div className="mb-2 text-base font-bold">
                        {t("Product-details.Ground Unit")}
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="w-[166px]">
                          {t("Product-details.Number of Ground Unit")}
                        </div>
                        <Icons
                          onClick={() => {
                            setGroundUnitNumber(
                              groundUnitNumber > 1
                                ? groundUnitNumber - 1
                                : groundUnitNumber
                            );
                          }}
                          iconName="minus-circle"
                        />
                        {groundUnitNumber}
                        <Icons
                          onClick={() => {
                            setGroundUnitNumber(
                              unitNum <= groundUnitNumber
                                ? groundUnitNumber
                                : groundUnitNumber + 1
                            );
                          }}
                          iconName="plus-circle"
                        />
                      </div>
                    </div>
                    {
                      // extraPayment.length !== 0||
                      extraPayment?.length === 1 &&
                      extraPayment[0]?.name &&
                      extraPayment[0]?.price ? (
                        <div className="px-2 py-4 bg-pure-white border-t border-x border-input-text">
                          <div className="mb-2 text-base font-bold">
                            {t(`Product-details.Extra Option(s)`)}
                          </div>
                          {extraPayment &&
                            extraPayment.map((item: any, idx: number) => {
                              if (item === undefined) {
                                <></>;
                              } else {
                                return (
                                  <div
                                    key={idx}
                                    className="flex mb-4 last:mb-0 items-center justify-between"
                                  >
                                    <div className="text-sm w-[166px]">
                                      <div>{item && item.name}</div>
                                      <div>{item && item.price}</div>
                                    </div>
                                    <Icons
                                      onClick={() => {
                                        handleMinusClickOption(idx, item);
                                      }}
                                      iconName="minus-circle"
                                      className="w-4 h-4"
                                    />
                                    {item && item.amount}
                                    <Icons
                                      onClick={() => {
                                        handlePlusClickOption(idx, item);
                                      }}
                                      iconName="plus-circle"
                                      className="w-4 h-4"
                                    />
                                  </div>
                                );
                              }
                            })}
                        </div>
                      ) : (
                        <div />
                      )
                    }
                    <div className="px-2 py-4 bg-pure-white border-t border-x border-input-text">
                      <div className="flex justify-between items-center">
                        <div className="text-base font-bold">
                          {t("Product-details.Total")}
                        </div>
                        <div className="text-base font-bold">
                          {totalPrice === "NaN"
                            ? "0"
                            : inputPriceComma(totalPrice)}
                          ￦
                        </div>
                      </div>
                      <Button
                        onClick={() => {
                          booking();
                        }}
                        className="w-full mt-4 py-2 text-center bg-light-green text-pure-white rounded-lg"
                        btnText={t("Product-details.Booking Request")}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {isOpen ? (
                <div className="w-full shadow-card py-4 px-2">
                  <DetailInfo title="Product-details.Ground Type">
                    <img
                      src={typeInfo?.iconUrl}
                      className="w-5 h-5"
                      alt={typeInfo?.displayName}
                    />
                    <div className="ml-2">
                      {t(typeInfo && `Private-Type-data.${typeInfo?.enName}`)}
                    </div>
                  </DetailInfo>
                  <DetailInfo title="Product-details.Ground Details" noFlex>
                    <div className="flex items-center">
                      <img
                        className="w-5 h-5 mr-2"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-ground.svg"
                        alt=""
                      />
                      <div>
                        {t("Product-details.Number of Unit")} : {unitCount}{" "}
                        {t("Product-details.Ground Unit")}
                      </div>
                    </div>
                    <div className="flex items-center my-[20px]">
                      <img
                        className="w-5 h-5 mr-2"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                        alt=""
                      />
                      <div>
                        {t("Parking-accessible-data.Accessible Vehicle")} :{" "}
                        {t(`Parking-accessible-data.${accessibleVehicle}`)}
                      </div>
                    </div>
                    <div className="flex">
                      <img
                        className="w-5 h-5 mr-2"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                        alt=""
                      />
                      <div>
                        {t("Product-details.Ground Size")} : {spaceSize} m²
                      </div>
                    </div>
                  </DetailInfo>
                  <DetailInfo
                    nomargin
                    className="mb-0"
                    title="Product-details.Price"
                  >
                    {t("Product-details.From")} {minimumPrice()}{" "}
                    {t("Product-details./ 1 night per vehicle")}
                  </DetailInfo>
                </div>
              ) : (
                ""
              )}
            </DetailTheme>

            <DetailTheme kor theme="Product-details.Location">
              <div className="my-8 h-[400px]">
                <GoogleMap
                  mapLocation={{
                    lat: Number(
                      `${indicator && indicator.latitude - Number(x)}`
                    ),
                    lng: Number(
                      `${indicator && indicator.longitude + Number(y)}`
                    ),
                  }}
                />
              </div>
              <DetailInfo title="Product-details.Address" withIcon>
                <Icons iconName="real-address" className="mr-2 w-5 h-5" />
                <div>
                  {t(
                    "Product-details.Information provided after booking confirmed"
                  )}
                </div>
              </DetailInfo>
              {/* <DetailInfo withIcon title="How To Get There">{direction}</DetailInfo> */}
              <DetailInfo
                noFlex
                withIcon
                title="Product-details.Nearby Facilities"
              >
                {nearbyFacilities?.length !== 0 ? (
                  nearbyFacilities &&
                  nearbyFacilities.map((item: any, i: number) => {
                    if (item !== null) {
                      return (
                        <div
                          key={i}
                          className="flex items-center mb-4 last:mb-0"
                        >
                          <div className="w-[224px] flex">
                            <img
                              className="mr-2 w-5 h-5"
                              alt=""
                              src={
                                item.facility === "EV_CHARGING_STATION"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/ev-charge-station.svg"
                                  : item.facility === "CONVENIENCE_STORE"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/convenience-store.svg"
                                  : item.facility === "GAS_STATION"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/gas-station.svg"
                                  : item.facility === "SUPERMARKET"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/supermarket.svg"
                                  : item.facility === "TOILET_24HR"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/24hr-toilet.svg"
                                  : ""
                              }
                            />
                            <div>
                              {t(
                                `Nearby-facility.${changeFacility(
                                  item.facility
                                )}`
                              )}
                            </div>
                          </div>
                          <div>{item.name}</div>
                          <div> ({item.distance}Km)</div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>

              <DetailInfo
                withIcon
                title={`Add-private-ground.Recommending Tourist Spot`}
              >
                {touristSpots &&
                !!touristSpots[0]?.title &&
                !!touristSpots[0]?.description &&
                !!touristSpots[0]?.photoUrl ? (
                  touristSpots.map(
                    ({ title, description, photoUrl }: any, key: number) => {
                      return (
                        <div
                          key={key}
                          className="w-50 h-[161px] shadow-card rounded-lg relative"
                        >
                          <div
                            onClick={() => {
                              touristInfo(
                                translate ? translateTourTitle : title,
                                translate ? translateTourDesc : description,
                                photoUrl
                              );
                            }}
                            className="opacity-0 hover:opacity-50 flex justify-center  w-full h-32 cursor-pointer items-center hover:bg-black rounded-t-lg absolute"
                          >
                            <Icons iconName="view-detail" className="w-8 h-8" />
                          </div>
                          <img
                            className="h-32 w-full rounded-t-lg"
                            src={photoUrl}
                            alt=""
                          />
                          <div className="whitespace-nowrap text-ellipsis w-full overflow-hidden h-[33px] leading-[33px] rounded-b-lg px-2 text-sm my-auto">
                            {translate ? translateTourTitle : title}
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
            </DetailTheme>
            <DetailTheme theme="Product-details.Policy">
              <DetailInfo withIcon title="Product-details.Check In / Out">
                <div className="mr-44">
                  {t("Product-details.Check in")} :{" "}
                  {bookingTime && bookingTime.checkIn}
                </div>
                <div>
                  {t("Product-details.Check out")} :{" "}
                  {bookingTime && bookingTime.checkOut}
                </div>
              </DetailInfo>
              <DetailInfo withIcon title="Product-details.Confirmation">
                <div>
                  {t(
                    "Confirmation-data.You will receive booking confirmation-hours",
                    { confirm: "24" }
                  )}
                </div>
                <div>
                  {t(
                    "Confirmation-data.In the event that you do not receive an email from us"
                  )}
                </div>
              </DetailInfo>
              <DetailInfo withIcon title="Product-details.Advance Notice">
                {advanceDate
                  ? t("Advance-notice-data.Booking is not available today")
                  : t("Advance-notice-data.Booking is available today")}
              </DetailInfo>
              <DetailInfo
                withIcon
                title={"Product-details.Additional Rule / Policy"}
              >
                {extraPolicy ? (
                  <div className="w-full break-normal break-all">
                    {translate ? translateExtraPolicy : extraPolicy}
                  </div>
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo
                noFlex
                withIcon
                title={"Product-details.Cancellation & Refund Policy"}
              >
                <div className="font-bold mb-4 flex">
                  <div className="text-pure-red">
                    {t(
                      policyInfo &&
                        `Cancellation-policy-data.${changecase(
                          policyInfo.name
                        )}`
                    )}{" "}
                  </div>
                  <div>{t("Product-details.Policy on Cancellation")}</div>
                </div>
                <div>
                  {policyInfo?.firstRange !== null
                    ? t(
                        "Cancellation-policy-data.{{days}}100% refund of the payment",
                        { days: policyInfo?.firstRange }
                      ) + "\n"
                    : ""}
                  {policyInfo?.secondRange !== null
                    ? t(
                        "Cancellation-policy-data.{{days}}70% refund of the payment",
                        { days: policyInfo?.secondRange }
                      ) + "\n"
                    : ""}
                  {policyInfo?.thirdRange !== null
                    ? t(
                        "Cancellation-policy-data.{{days}}50% refund of the payment",
                        { days: policyInfo?.thirdRange }
                      ) + "\n"
                    : ""}
                  {policyInfo?.fourthRange !== null
                    ? t(
                        "Cancellation-policy-data.{{days}}30% refund of the payment",
                        { days: policyInfo?.fourthRange }
                      ) + "\n"
                    : ""}
                  {policyInfo?.fifthRange !== null
                    ? t(
                        "Cancellation-policy-data.{{days}}0% refund of the payment",
                        { days: policyInfo?.fifthRange }
                      )
                    : ""}
                </div>
              </DetailInfo>
            </DetailTheme>
          </div>

          <div className="flex flex-col ml-8">
            <div className="w-80 h-52 rounded-lg p-4 flex flex-col justify-between border border-input-text ">
              <div className="flex justify-between items-center">
                <Icons iconName="best-tag" className="h-11 w-11" />
                <div className="flex items-end">
                  <div className="text-input-text text-base mr-1">
                    {minimumPrice() === "예약마감" ? "" : "From"}
                  </div>
                  <div className="text-2xl font-bold">{minimumPrice()}</div>
                </div>
              </div>
              <Button
                onClick={onMoveElement}
                className="bg-light-green w-full py-2 text-center rounded-lg text-pure-white"
                btnText={t("Product-details.Go Booking")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
