import React from "react";
import { useTranslation } from "react-i18next";

interface DetailInfoProps {
  className?: string;
  children?: React.ReactNode;
  kor?: boolean;
  needPosition?: string;

  title?: string;
  theme?: string;
  _ref?:any;
}

const DetailTheme = ({
  className,
  kor,
  needPosition,
  theme,
  children,
  _ref

}: DetailInfoProps) => {
  const {t} = useTranslation();
  return (
    <div ref={_ref} className={`${className}`}>
      <hr className="text-input-text" />

      <div className="py-8">
        <div className="font-bold text-2xl mb-8">{t(`${theme}`)}</div>
        <div className={`mb-4 ${needPosition}`}>
            {children}
      </div>
      </div>
    </div>
  );
};

export default DetailTheme;
