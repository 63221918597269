import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface hostInfoProps {
    id?: number;
    email?: string;
    name?: string;
    
    bizType?: string;
    hostName?: string;
    hostImage?: string;
    hostCountryCode?: string;
    hostMobile?: string;
    hostLanguage?: string;
    hostCurrency?: string;
    CompanyMobile?: string;
    groundUrl?: string;
    bizUrl?: string;
}

const initialState = {
    hostInfo: <hostInfoProps> {
        id: 0,
        email: '',
        name: '',
        bizType: '',
        hostName: '',
        hostImage: '',

        hostCountryCode: '',
        hostMobile: '',

        hostLanguage: '',
        hostCurrency: '',

        CompanyMobile: '',
        groundUrl: '',
        bizUrl: ''
    }
};

const hostSlice = createSlice({
    name: 'hostInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        
    }
});

export default hostSlice.reducer