import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

const BookingComplete = () => {
  const [token, setToken] = useState("");
  const [bookingInfo, setBookingInfo] = useState<{
    amount: number;
    apply_num: string;
    buyer_addr: string;
    buyer_email: string;
    buyer_name: string;
    buyer_postcode: string;
    buyer_tel: string;
    cancel_amount: number;
    cancel_history: {};
    cancel_reason: any;
    cancel_receiptUrls: any;
    cancelled_at: number;
    card_code: string;
    card_name: string;
    card_quota: number;
    card_type: number;
    channel: string;
    currency: string;
    escrow: boolean;
    imp_uid: string;
    merchant_uid: string;
    paid_at: number;
    pay_method: string;
    pg_id: string;
    pg_name: any;
    pg_provider: string;
    pg_tid: string;
    receipt_url: string;
    started_at: number;
    status: string;
    user_agent: string;
  }>({} as any);
  const navigate = useNavigate();
  const location = useLocation();

  const url = location?.search?.split("&");
  const impUid = url[0]?.split("=")[1];
  const merchantUid = url[1]?.split("=")[1];
  const impSuccess = url[2]?.split("=")[1];

  const {
    amount,
    apply_num,
    buyer_addr,
    buyer_email,
    buyer_name,
    buyer_postcode,
    buyer_tel,
    cancel_amount,
    cancel_history,
    cancel_reason,
    cancel_receiptUrls,
    cancelled_at,
    card_code,
    card_name,
    card_quota,
    card_type,
    channel,
    currency,
    escrow,
    imp_uid,
    merchant_uid,
    paid_at,
    pay_method,
    pg_id,
    pg_name,
    pg_provider,
    pg_tid,
    receipt_url,
    started_at,
    status,
    user_agent,
  } = bookingInfo;

  const bookingData = JSON.parse(localStorage.getItem("book") as any);
  const memberId = localStorage.getItem("m");

  const {
    checkIn,
    checkOut,
    currencyId,
    currentImage,
    hasCar,
    hostId,
    optionInfo,
    pgId,
    pgTitle,
    plateNumber,
    price,
    totalPrice,
    unitAmount,
  } = bookingData !== null && bookingData;
  const optionValue = optionInfo && optionInfo[0];

  const extraValue = [
    {
      amount: optionValue?.amount,
      id: optionValue?.pgExtraOptionId?.id,
      price: optionValue?.pgExtraOptionId?.price,
    },
  ];

  const optionPrice = String(
    optionValue?.amount !== undefined &&
      optionValue?.pgExtraOptionId?.price !== undefined
      ? optionValue?.amount * optionValue?.pgExtraOptionId?.price
      : "0"
  );

  const handleBooking = () => {
    requestToMustOutdoor({
      method: "POST",
      url: `/api/v1/payment/info?impUid=${impUid}`,
    }).then((res) => {
      const value = res.data.data;
      setBookingInfo(value.response);
      return value.response;
    });
    requestToMustOutdoor({
      method: "POST",
      url: `/api/v1/payment/get/token`,
    }).then((res) => {
      const data = res.data.data;
      setToken(data);
      return data;
    });
  };

  const newData = {
    applyNum: apply_num,
    buyerInfo: {
      address: buyer_addr,
      email: buyer_email,
      mobile: buyer_tel,
      name: buyer_name,
      postcode: buyer_postcode,
    },
    cancelReason: cancel_reason,
    cancelledAt: cancelled_at,
    cardName: card_name,
    cardNum: card_code,
    cardQuota: card_quota,
    checkIn: checkIn,
    checkOut: checkOut,
    currency: currency,
    currencyId: currencyId,
    currentImage: currentImage,
    escrow: escrow,
    extraOption:
      optionValue?.amount !== undefined &&
      optionValue?.pgExtraOptionId?.id !== undefined &&
      optionValue?.pgExtraOptionId?.price !== undefined
        ? extraValue
        : [],
    failReason: "",
    hasCar: hasCar,
    hostId: hostId,
    iamportUid: imp_uid,
    // merchantUid: "예약번호+epoch time+totalMemberId값",
    merchantUid: merchant_uid,
    method: pay_method,
    name: pgTitle,
    note: "머스트아웃도어 개별 메모",
    optionPrice: optionPrice,
    paidAt: paid_at,
    pgId: pgId,
    pgProvider: pg_provider,
    pgTid: pg_tid,
    pgTitle: pgTitle,
    plateNumber: plateNumber,
    price: price,
    receiptUrl: receipt_url,
    refundPrice: 0,
    status: status,
    succeed: true,
    token: token,
    totalMemberId: Number(memberId),
    totalPrice: Number(totalPrice),
    totalServerPrice: totalPrice,
    unitAmount: unitAmount,
  };

  const handleNewBooking = () => {
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v1/booking/new",
      data: newData,
    })
      .then((res) => {
        localStorage.removeItem("book");
        navigate("/member/booking", { replace: true });
        const value = res.data.data;
        return value;
      })
      .catch((err) => {
        alert("결제오류");
      });
  };

  useEffect(() => {
    handleBooking();
  }, []);

  useEffect(() => {
    if (apply_num) {
      handleNewBooking();
    }
  }, [apply_num]);

  return <div>예약중입니다. 잠시만 기다려주세요</div>;
};

export default BookingComplete;
