import HostWrap from "components/Host/hostWrap";
import AddButton from "elements/AddButton";
import Button from "elements/Button";

import Icons from "elements/Icons";
import React, { useEffect, useState } from "react";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

interface editGalleryData {
  images: [] | any;
  id?: number;
}

const EditGroundGallery = ({ id, galleryEditData }: editGalleryData | any) => {
  const hostNum = localStorage.getItem("hostNum");
  const [imgUrl, setImgUrl] = useState<any>([]);
  const [currentImage, setCurrentImage] = useState<any>({});
  const { preImageDtoList, preCurrentImage } = galleryEditData;

  const imageList = preImageDtoList;
  const currentImageList = preCurrentImage;

  useEffect(() => {
    const imgList = imageList?.map((item: any) => {
      const value = {
        fileName: item.fileName,
        profile: item.profile,
        url: item.url,
      };
      return value;
    });
    if (imageList?.length > 0) {
      setImgUrl([...imgList]);
    }
    const current = currentImageList;
    const currentValue = {
      fileName: current?.fileName,
      profile: current?.profile,
      url: current?.url,
    };
    if (currentImage) {
      setCurrentImage(currentValue);
    }
  }, [currentImageList, imageList]);

  const hiddenInput = React.useRef<any>(null);
  const handleClick = (e: any) => {
    hiddenInput.current.click();
  };

  const featureInput = React.useRef<any>(null);
  const featureClick = (e: any) => {
    featureInput.current.click();
  };

  const HandleFeature = (e: any) => {
    let files = e.target.files[0];
    const fileSize = files.size;
    const data = {
      multipartFiles: files,
    };
    // 1024 ** 2 * 5 = 5MB;
    if (fileSize > 1024 ** 2 * 5) {
      return alert("파일 사이즈는 5mb 보다 작아야합니다.");
    } else if (imgUrl?.length > 20) {
      return alert("최대 업로드 갯수는 20개 입니다.");
    } else {
      requestToMustOutdoor
        .post("/guest/upload/file", data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        })
        .then((res) => {
          const result = res.data.data;
          const imgName = result[0];
          const imgUrl_ = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${imgName}`;
          setCurrentImage({
            fileName: imgName,
            url: imgUrl_,
            profile: true,
          });
          return res.data.data;
        })
        .catch((err) => {});
    }
  };

  const HandleFile = (e: any) => {
    let files = e.target.files[0];
    const fileSize = files.size;
    const data = {
      multipartFiles: files,
    };
    // 1024 ** 2 * 5 = 5MB;
    if (fileSize > 1024 ** 2 * 5) {
      return alert("파일 사이즈는 5mb 보다 작아야합니다.");
    } else if (imgUrl?.length > 20) {
      return alert("최대 업로드 갯수는 20개 입니다.");
    } else {
      requestToMustOutdoor
        .post("/guest/upload/file", data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        })
        .then((res) => {
          const result = res.data.data;
          const imgName = result[0];
          const imgUrl_ = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${imgName}`;
          setImgUrl((pre: any) => [
            ...pre,
            {
              fileName: imgName,
              url: imgUrl_,
              profile: false,
            },
          ]);
          return res.data.data;
        });
    }
  };

  const currentData = {
    images: imgUrl,
    currentImage,
  };

  const modiGall = () => {
    requestToMustOutdoor({
      method: "PATCH",
      url: `/api/v2/private-ground/${id}`,
      data: { ...currentData, hostId: Number(hostNum) },
    })
      .then((res) => {
        window.location.reload();
        return res;
      })
      .catch((err) => {});
  };

  return (
    <div className="web:flex phone:block">
      <div className="web:w-160 phone:mx-4">
        <div className="text-left min-h-[28rem]">
          <HostWrap title="대표이미지">
            <input
              ref={featureInput}
              type="file"
              onChange={(e) => {
                HandleFeature(e);
                e.target.value = ""
              }}
              accept=".jpg, .jpeg, .png, .pdf"
              className="hidden"
              id="images"
            />
            {currentImage?.fileName ? (
              <div className="relative mt-4">
                <img
                  className="phone:h-[192px] web:h-[128px] phone:w-full phone:mb-4 web:mb-0 rounded-lg border border-input-border-color"
                  src={`${currentImage?.url}`}
                  alt="대표이미지"
                ></img>
                <div className="absolute top-2 left-2 text-gold">대표</div>
                <img
                  onClick={() => {
                    setCurrentImage({});
                  }}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                  className="absolute w-6 h-6 cursor-pointer top-2 right-2"
                  alt=""
                />
              </div>
            ) : (
              <div className="mt-4">
                <div
                  onClick={(e) => featureClick(e)}
                  className="cursor-pointer web:w-52 web:h-[128px] phone:h-48 phone:w-full rounded-lg border border-input-border-color mb-2 flex flex-col justify-center items-center"
                >
                  <Icons iconName="gallery" className="w-12 h-12" />
                  <AddButton title="Add-private-ground.Gallery" />
                </div>
              </div>
            )}
          </HostWrap>
          <HostWrap
            column
            toolTitle={"이제 멋진 사진을 공개할 차례입니다.\n(최소 5 장 이상)"}
            toolSubTitle={
              "- 첫번째 사진은 대표 메인 사진으로 설정됩니다.(드래그 이동/변경 가능) 야외 공간부터 주변 풍경까지 매력적인 포인트를 놓치지 말고 하나하나 담아주세요.\n- 전문가 사진이 아니어도 괜찮아요. 깔끔하게 촬영한 사진이면 충분히 매력을 뽐낼 수 있어요~!!  주의) 과도한 필터 및 보정은 역효과를 가져올 수 있습니다."
            }
            title="Add-private-ground.Ground Gallery"
            help
            host
          >
            <div className="mt-2">
              <input
                onChange={(e) => {
                  HandleFile(e);
                  e.target.value = ""
                }}
                ref={hiddenInput}
                className="hidden"
                id="images"
                type="file"
                accept=".jpg, .jpeg, .png, .pdf"
              />
              <div
                className={`${
                  imgUrl?.length > 0 ? "web:grid web:grid-cols-3 web:gap-2" : ""
                }`}
              >
                {imgUrl?.length > 0 ? (
                  imgUrl?.map((item: any, idx: number) => {
                    if (idx === 0) {
                      return (
                        <div key={idx} className="relative">
                          <img
                            className="phone:h-[192px] web:h-[128px] phone:w-full phone:mb-4 web:mb-0 rounded-lg border border-input-border-color"
                            src={`${item.url}`}
                            alt="대표이미지"
                          ></img>
                          <img
                            onClick={() => {
                              const image = imgUrl;
                              const newImage = image.filter(
                                (x: any) => x.fileName !== item.fileName
                              );
                              setImgUrl(newImage);
                            }}
                            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                            className="absolute w-6 h-6 cursor-pointer top-2 right-2"
                            alt=""
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={idx} className="relative">
                          <img
                            draggable={true}
                            key={idx}
                            alt=""
                            className="w-52 web:h-[128px] phone:w-full phone:h-48 rounded-lg border border-input-border-color mr-2 mb-2 [&:nth-child(3n)]:mr-0"
                            src={`${item.url}`}
                          />
                          <img
                            onClick={() => {
                              const image = imgUrl;
                              const newImage = image.filter(
                                (x: any) => x.fileName !== item.fileName
                              );
                              setImgUrl(newImage);
                            }}
                            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                            className="absolute w-6 h-6 cursor-pointer top-2 right-2"
                            alt=""
                          />
                        </div>
                      );
                    }
                  })
                ) : (
                  <div
                    onClick={(e) => handleClick(e)}
                    className="web:min-h-[382px] web:w-160 phone:w-full phone:h-48 rounded-lg border border-input-border-color flex flex-col justify-center items-center"
                  >
                    <Icons
                      iconName="gallery"
                      className="web:w-32 web:h-32 phone:w-16 phone:h-16"
                    />
                    <AddButton title={"Gallery"} className="my-4" />
                    <div className="web:inline-block phone:hidden text-center">
                      Upload the attractive features of your private ground that
                      guests might love.
                    </div>
                  </div>
                )}
                {imgUrl?.length > 0 && imgUrl?.length < 20 ? (
                  <div
                    onClick={(e) => handleClick(e)}
                    className="cursor-pointer web:w-52 web:h-[128px] phone:h-48 phone:w-full rounded-lg border border-input-border-color mb-2 flex flex-col justify-center items-center"
                  >
                    <Icons iconName="gallery" className="phone:w-16 phone:h-16" />
                    <AddButton title="Gallery" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </HostWrap>
          <div className="mt-12 mb-8 justify-end flex">
            <Button
              onClick={() => {
                if (window.confirm("정말 수정하시겠어요?")) {
                  modiGall();
                }
              }}
              btnText={"SAVE"}
              className="px-4 py-3 rounded-lg border border-input-border-color text-pure-white bg-tan"
            ></Button>
          </div>
        </div>
        <div className="grow ml-4"></div>
      </div>
    </div>
  );
};

export default EditGroundGallery;
