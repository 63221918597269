import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/store";

import HostWrap from "components/Host/hostWrap";
import HostInput from "elements/HostInput";
import Button from "elements/Button";
import CheckBoxDefault from "elements/CheckBoxDefault";

import { unitNumber, Vehicle } from "pages/Register/constants";

import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

import { generalInfo } from "../../../redux/slice/addPrivateSlice";

interface GenenralInfoProps {
  onNext?: any;
  onChange?: (e: any) => void;
  generalData?: any;
  privateData?: any;
  types?: any;
}

const GeneralInfo = ({ generalData, onNext }: GenenralInfoProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const currentData = useAppSelector((state) => state.privateGround.General);

  const [types, setTypes] = useState([]);
  const [themes, setThemes] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [unitNum, setUnitNum] = useState(0);

  const [accessibleVehicle, setVehicle] = useState("");
  const [accessibleVehicleName, setVehicleName] = useState("");

  const [spaceSize, setSpaceSize] = useState("");

  const [type, setType] = useState<number>(0);
  const [locationTheme, setLocationThemeList] = useState<any | [] | number>([]);

  const unitCount = Number(unitNum);

  const page = currentData;

  useEffect(() => {
    let AV = page?.accessibleVehicle;
    if (AV === "BACKPACKER") {
      AV = "Only Backpacker";
    } else if (AV === "AV1") {
      AV = "Up to compact / light size";
    } else if (AV === "AV2") {
      AV = "Up to mid / large size";
    } else if (AV === "AV3") {
      AV = "Up to compact RV (3 pax)";
    } else if (AV === "AV4") {
      AV = "Up to mid / large RV (5 pax)";
    } else if (AV === "AV5") {
      AV = "Small/mid Caravan or Trailer(3 pax)";
    } else if (AV === "AV6") {
      AV = "Large Caravan or Trailer (5 pax)";
    }
    setTitle(page?.title);
    setDescription(page?.description);
    setType(page?.typeId);
    setUnitNum(page?.unitCount);
    setVehicleName(page?.accessibleVehicle);
    setVehicle(AV);
    setSpaceSize(page?.spaceSize);
    if (page?.themeIds !== undefined) {
      setLocationThemeList(page?.themeIds);
    }
  }, [
    page?.accessibleVehicle,
    page?.description,
    page?.themeIds,
    page?.spaceSize,
    page?.title,
    page?.typeId,
    page?.unitCount,
    page,
  ]);

  const handleVehicle = (e: any) => {
    let AV = e.target.value;
    if (AV === "Only Backpacker") {
      AV = "BACKPACKER";
    } else if (AV === "Up to compact / light size") {
      AV = "AV1";
    } else if (AV === "Up to mid / large size") {
      AV = "AV2";
    } else if (AV === "Up to compact RV (3 pax)") {
      AV = "AV3";
    } else if (AV === "Up to mid / large RV (5 pax)") {
      AV = "AV4";
    } else if (AV === "Small/mid Caravan or Trailer(3 pax)") {
      AV = "AV5";
    } else if (AV === "Large Caravan or Trailer (5 pax)") {
      AV = "AV6";
    }
    setVehicleName(AV);
    setVehicle(e.target.value);
  };

  const handleType = (checked: string, item: number, e: any) => {
    if (checked && !type) {
      const result = Number(item);
      setType(result);
    } else if (!checked) {
      setType(0);
    }
    if (type && checked) {
      alert("1개만 선택 가능합니다");
      e.target.checked = false;
    }
  };

  const LocationThemeSetting = (checked: string, item: string, e: any) => {
    if (checked) {
      const result = Number(item);
      setLocationThemeList([...locationTheme, result]);
    } else if (!checked) {
      const result = Number(item);
      setLocationThemeList(locationTheme.filter((el: any) => el !== result));
    }
    if (locationTheme.length > 2 && checked) {
      const result = Number(item);
      alert("최대 3개까지 선택 가능합니다");
      setLocationThemeList(locationTheme.filter((el: any) => el !== result));
      e.target.checked = false;
    }
  };

  const data = {
    title: title,
    description: TextareaReplaceNewLine(description),
    typeId: type,
    themeIds: locationTheme,
    accessibleVehicle: accessibleVehicleName,
    unitCount: unitCount,
    spaceSize: spaceSize,
  };

  const handleSpaceSize = (e: any) => {
    const currnetSpaceSize = Number(e.target.value);
    setSpaceSize(currnetSpaceSize.toFixed(2));
  };

  const handleGeneralInfoData = () => {
    if (title === "") {
      return alert(t("Please input title"));
    } else if (description === "") {
      return alert(t("Please input description."));
    } else if (data.typeId === 0) {
      return alert(t("Please check at least one type."));
    } else if (data.unitCount === 0) {
      return alert(t("Please input number of unit."));
    } else if (data.accessibleVehicle === "") {
      return alert(t("Please select accessible vehicle."));
    } else if (data.spaceSize === "") {
      return alert(t("Please input ground size."));
    } else if (data.themeIds.length === 0) {
      return alert(t("Please check upto 3 theme."));
    }
    dispatch(generalInfo(data));
    generalData(data);
    onNext();
  };
  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/private-ground/fetch/icons`,
    })
      .then((res) => {
        const data = res.data.data;
        setTypes(data.types);
        setThemes(data.themes);
      })
      .catch((err) => {});
  }, []);

  return (
    <div className="web:flex phone:block">
      <div className="web:w-160 web:mx-0 phone:mx-4">
        <div className="text-left">
          <HostWrap
            help
            host
            toolTitle="Tooltip-add-private-ground.Gen info title-tooltip title"
            toolSubTitle={
              "Tooltip-add-private-ground.Gen info title-tooltip desc"
            }
            toolEx={
              "Tooltip-add-private-ground.Gen info title-tooltip ex"
            }
            title="Add-private-ground.Title*"
          >
            <HostInput
              input
              inputMaxLength={50}
              inputValue={page?.title}
              inputLengthPosition="right-0"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              size="XL"
              placeholder="Add-private-ground.Name of Private Ground"
            />
          </HostWrap>
          <HostWrap
            help
            toolTitle={"Tooltip-add-private-ground.Gen info description-tooltip title"}
            toolSubTitle="Tooltip-add-private-ground.Gen info description-tooltip desc"
            host
            title={"Add-private-ground.Description*"}
          >
            <HostInput
              inputValue={page?.description}
              inputMaxLength={500}
              textarea
              inputLengthPosition="right-0"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              size="XL"
              rows={5}
              placeholder="Add-private-ground.Describe the Private Ground for travelers"
            />
          </HostWrap>
          <HostWrap
            help
            host
            title="Add-private-ground.Private Ground Type*"
            toolTitle={"Tooltip-add-private-ground.Gen info pg type-tooltip title"}
            toolSubTitle="Tooltip-add-private-ground.Gen info pg type-tooltip desc"
          >
            <div
              className={`flex web:flex-row phone:flex-col flex-wrap text-sm mt-4`}
            >
              {types?.map((item: any, idx: number) => {
                const iconUrl = item.iconUrl;
                const enName = item.enName;
                const id = item.id;
                return (
                  <CheckBoxDefault
                    key={idx}
                    id={id}
                    imageUrl={iconUrl}
                    defaultChecked={
                      (page?.typeId === id) === true ? true : false
                    }
                    onChange={(e: any) => {
                      handleType(e.target.checked, e.target.id, e);
                    }}
                    title={`Private-Type-data.${enName}`}
                    value={enName}
                  />
                );
              })}
            </div>
          </HostWrap>
          <HostWrap
            help
            toolTitle="Tooltip-add-private-ground.Gen info availability-tooltip title"
            toolSubTitle={
              "Tooltip-add-private-ground.Gen info availability-tooltip desc"
            }
            toolEx={
              "Tooltip-add-private-ground.Gen info availability-tooltip ex"
            }
            host
            title="Add-private-ground.Availability*"
          >
            <div className="flex web:flex-row phone:flex-col">
              <HostInput
                formTitle={t("Add-private-ground.Number of Unit")}
                select
                selected={String(unitNum)}
                onChange={(e) => setUnitNum(e.target.value)}
                options={unitNumber}
                size={"S"}
                placeholder="Add-private-ground.Select"
              />
              <HostInput
                select
                selectTrans="Parking-accessible-data"
                selected={accessibleVehicle}
                onChange={handleVehicle}
                options={Vehicle}
                formTitle={t("Add-private-ground.Accessible Vehicle")}
                size="S"
                placeholder="Add-private-ground.Select"
              />
              <HostInput
                inputOnText
                inputOnTextValue="m²"
                type="number"
                placeholder="Add-private-ground.Input ground size"
                inputMaxLength={3}
                inputLengthPosition="right-0 bottom-[-27px]"
                onChange={(e) => handleSpaceSize(e)}
                inputValue={page?.spaceSize}
                input
                nomargin
                inputOnTextPosition="right-2 top-[23px]"
                className="text-right pr-8 mt-4"
                formTitle={t("Add-private-ground.Ground Size")}
                size="S"
              />
            </div>
          </HostWrap>
          <HostWrap
            host
            help
            title="Add-private-ground.Location Theme*"
            subTitle="Add-private-ground.(Choose only upto 3 themes)"
            toolTitle={
              "Tooltip-add-private-ground.Gen info theme-tooltip title"
            }
            toolSubTitle="Tooltip-add-private-ground.Gen info theme-tooltip desc"
          >
            <div
              className={`flex web:flex-row phone:flex-col flex-wrap text-sm mt-4`}
            >
              {themes?.map((item: any, idx: number) => {
                const iconUrl = item.iconUrl;
                const enName = item.enName;
                const id = item.id;
                return (
                  <CheckBoxDefault
                    key={idx}
                    id={id}
                    imageUrl={iconUrl}
                    defaultChecked={
                      page?.themeIds?.includes(id) === true ? true : false
                    }
                    onChange={(e: any) => {
                      LocationThemeSetting(
                        e.target.checked,
                        e.currentTarget.id,
                        e
                      );
                    }}
                    title={`Theme-data.${enName}`}
                    value={enName}
                  />
                );
              })}
            </div>
          </HostWrap>
          <div className="mt-12 mb-8 justify-end flex">
            <Button
              onClick={() => {
                handleGeneralInfoData();
              }}
              className={`bg-light-green text-pure-white items-center fill-pure-white px-4 py-3 rounded-lg`}
              btnText={t("Add-private-ground.Continue")}
            ></Button>
          </div>
        </div>
      </div>
      <div className="w-full ml-4"></div>
    </div>
  );
};

export default GeneralInfo;
