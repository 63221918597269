import React, { useEffect, useState } from "react";

import HostHeader from "components/Host/hostHeader";
import HostWrap from "components/Host/hostWrap";

import { certify } from "./constants";

import Button from "elements/Button";
import HostInput from "elements/HostInput";
import { useNavigate, useLocation } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useTranslation } from "react-i18next";
import NewModal from "elements/newModal";
import { changeSpace } from "utils/changeCase";
import MoblieHeader from "components/moblieHeader";

interface stateProps {
  hasAc?: boolean;
  hasAt?: boolean;
  hasPg?: boolean;
  countryCode?: string;
  currency?: string;
  languageCode?: string;
  name?: string;
  mobile?: string;
  imageUrl?: string;
  bizType?: any;
  hostId?: number;
  type?: string;
}

const BusinessInfo = () => {
  const { t } = useTranslation();

  const [partnerModal, setPartnerModal] = useState(false);
  const [Code, setCode] = useState<any>();
  // PG
  const [operatingCountry, setOperatingCountry] = useState("");

  const [selectCode, setSelectCode] = useState("");
  const [telephone, setTelephone] = useState("");

  const [selected, setSelected] = useState("");
  const [bisType, setBisType] = useState<boolean>();

  const [groundFile, setGroundFile] = useState("");

  const [bizNumber, setBizNumber] = useState("");
  const [bizFile, setBizFile] = useState("");

  // AC
  const [bizName, setBizName] = useState("");
  const [tourismNum, setTourismNum] = useState("");
  const [tourismFile, setTourismFile] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const {
    hasAc,
    hasAt,
    hasPg,
    countryCode,
    imageUrl,
    languageCode,
    name,
    mobile,
    bizType,
    type,
  } = location?.state as stateProps;

  const m = Number(localStorage.getItem("m"));
  const hostNum = Number(localStorage.getItem("hostNum"));

  const pgInfo = {
    bizNumber: bizNumber.replaceAll(/[^0-9]/g, ""),
    bizType: bisType,
    bizUri: bizFile,
    countryCode: selectCode.replace(/[[a-zA-Z\s]/g, ""),
    groundUri: groundFile,
    telephone: telephone.replaceAll(/[^0-9]/g, ""),
  };

  const acInfo = {
    bizName: bizName,
    bizNumber: bizNumber.replaceAll(/[^0-9]/g, ""),
    bizUri: bizFile,
    telephone: telephone,
    telephoneCountryCode: selectCode.replace(/[[a-zA-Z\s]/g, ""),
    tourismNumber: tourismNum,
    tourismUri: tourismFile,
  };

  const atInfo = {
    bizName: "가평 하늘애펜션",
    bizNumber: "2342345",
    bizUri: "uri",
    telephone: "27681234",
    telephoneCountryCode: "+82",
    tourismNumber: "2342345",
    tourismUri: "uri",
  };

  const businessType = bizType && bizType[0];

  const pgData = {
    hasAc: hasAc,
    hasAt: hasAt,
    hasPg: hasPg,
    bizType: bizType,
    country: operatingCountry,
    imageUrl: imageUrl,
    languageCode: languageCode,
    mobile: mobile,
    mobileCountryCode: countryCode,
    name: name,
    pgInfo,
    totalMemberId: m,
    totalMobileNum: countryCode ? countryCode + mobile : "",
  };

  const acData = {
    hasAc: hasAc,
    hasAt: hasAt,
    hasPg: hasPg,
    bizType: bizType,
    country: operatingCountry,
    imageUrl: imageUrl,
    languageCode: languageCode,
    mobile: mobile,
    mobileCountryCode: countryCode,
    name: name,
    acInfo,
    totalMemberId: m,
    totalMobileNum: countryCode ? countryCode + mobile : "",
  };

  const atData = {
    hasAc: hasAc,
    hasAt: hasAt,
    hasPg: hasPg,
    bizType: bizType,
    // country: operatingCountry,
    imageUrl: imageUrl,
    languageCode: languageCode,
    mobile: mobile,
    mobileCountryCode: countryCode,
    name: name,
    atInfo,
    totalMemberId: m,
    totalMobileNum: countryCode ? countryCode + mobile : "",
  };

  const handleCertify = (e: any) => {
    setSelected(e.target.value);
    if (selected === "Ownership Certificate of Private Property(Ground)") {
      setBizFile("");
      setGroundFile("");
      setBizNumber("");
    } else {
      setBizFile("");
      setGroundFile("");
      setBizNumber("");
    }
  };

  useEffect(() => {
    const selectedValue = selected;
    if (
      selectedValue === "Ownership Certificate of Private Property(Ground)*"
    ) {
      setBisType(false);
    } else {
      setBisType(true);
    }
  }, [bisType, selected]);

  const isLogin = localStorage.getItem("isLogin");

  const handleFile = async (e: any) => {
    const data = {
      multipartFiles: e.target.files[0],
    };
    const response = await requestToMustOutdoor({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}guest/upload/file`,
      data,
      headers: {
        JWT: `${isLogin}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
    const imgUrl = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${response.data.data}`;
    if (businessType === "PG") {
      setGroundFile(imgUrl);
    } else if (businessType === "AC") {
      setTourismFile(imgUrl);
    }
    return response.data;
  };
  const handleBiz = async (e: any) => {
    const data = {
      multipartFiles: e.target.files[0],
    };
    const response = await requestToMustOutdoor({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}guest/upload/file`,
      data,
      headers: {
        JWT: `${isLogin}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
    const imgUrl = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${response.data.data}`;
    setBizFile(imgUrl);
    return response;
  };

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}guest/main/common/code`,
    }).then((res) => {
      const country = res.data.data.countryCodeMap;
      setCode(country);
    });
  }, []);

  const SaveHost = () => {
    if (businessType === "AC" && bizName === "") {
      return alert("업체명을 입력해주세요.");
    } else if (operatingCountry === "") {
      return alert("운영국가를 선택해주세요.");
    } else if (selectCode === "") {
      return alert("국가코드를 선택해주세요.");
    } else if (telephone === "") {
      return alert("연락처를 입력해주세요.");
    } else if (bizNumber === "" && businessType === "AC") {
      return alert("사업자 등록 번호를 입력해주세요.");
    } else if (
      businessType === "PG" &&
      selected === "Ownership Certificate of Private Property(Ground)" &&
      groundFile === "" &&
      bisType === false
    ) {
      return alert("사유지 증명 서류를 업로드 해주세요.");
    } else if (selected === "" && businessType === "PG") {
      return alert("서비스 운영 방식을 선택해주세요");
    } else if (
      selected === "Ownership Certificate as Business Registration" &&
      bizNumber === "" &&
      bisType === true
    ) {
      return alert("사업자 등록 번호를 입력해주세요.");
    } else if (
      businessType === "PG" &&
      selected === "Ownership Certificate as Business Registration" &&
      bizFile === "" &&
      bisType === true
    ) {
      return alert("사유지 증명 서류를 업로드 해주세요.");
    } else {
      type === "update"
        ? requestToMustOutdoor({
            method: "PATCH",
            url: `/api/v1/host/update/info/${hostNum}`,
            data:
              businessType === "PG"
                ? pgData
                : businessType === "AC"
                ? acData
                : businessType === "AT"
                ? atData
                : "",
          }).then((res) => {
            const data = res.data.data;
            localStorage.setItem("STATUS", "HOST");
            const itemStatus = {
              acInfo: data?.acInfo,
              atInfo: data?.atInfo,
              pgInfo: data?.pgInfo,
              hasAc: data?.hasAc,
              hasAt: data?.hasAt,
              hasPg: data?.hasPg,
            };
            localStorage.setItem("itemStatus", JSON.stringify(itemStatus));
            if (businessType === "PG") {
              localStorage.setItem("currentType", "PG");
              navigate("/host/myprivate");
            } else if (businessType === "AC") {
              localStorage.setItem("currentType", "AC");
              navigate("/host/myaccommodation");
            }
            return data;
          })
        : requestToMustOutdoor
            .post(
              "/api/v1/host/register/as-host",
              businessType === "PG"
                ? pgData
                : businessType === "AC"
                ? acData
                : businessType === "AT"
                ? atData
                : ""
            )
            .then((res) => {
              const data = res.data.data;
              const { createdAt, id, imageUrl, languageCode, bizType } = data;
              if (businessType === "PG") {
                localStorage.setItem("currentType", "PG");
              } else if (businessType === "AC") {
                localStorage.setItem("currentType", "AC");
              } else if (businessType === "AT") {
                localStorage.setItem("currentType", "AT");
              }
              localStorage.setItem("bizType", bizType);
              localStorage.setItem("hostNum", id);
              localStorage.setItem("profile", imageUrl);
              localStorage.setItem("country", languageCode);
              localStorage.setItem("joined", createdAt);
              localStorage.setItem("role", "HOST");
              localStorage.setItem("STATUS", "HOST");
              setPartnerModal(true);
              return res;
            })
            .catch((err) => {
              return err;
            });
    }
  };

  const operateCode = Code?.map((item: string) => {
    return changeSpace(item.replace(/[0-9/\+/\s]/g, ""));
  });

  return (
    // private ground
    <div className="text-center web:w-[864px] phone:max-w-screen-tablet phone:mt-0 web:mt-[76px] mx-auto lg:max-w-1216">
      <MoblieHeader left center="AS-Host-info.Start Hosting" />
      {partnerModal ? (
        <NewModal
          imageUrl="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/partnership.svg"
          buttonText={
            businessType === "PG"
              ? t("AS-Host-info.Continue to My Private Ground")
              : businessType === "AC"
              ? t("AS-Host-info.Continue to My Accommodation")
              : ""
          }
          buttonColor="bg-light-green text-pure-white"
          onClick={() => {
            if (businessType === "PG") {
              navigate("/host/myprivate", { replace: true });
            }
            if (businessType === "AC") {
              navigate("/host/myaccommodation", { replace: true });
            }
          }}
          title={t(
            `AS-Host-info.You are now ready to create listings for your selected Business Type.`
          )}
        />
      ) : (
        ""
      )}
      <HostHeader
        title={"AS-Host-info.Start Hosting"}
        subTitle={t(`AS-Host-info.Business information`)}
      />
      <div className="flex phone:px-4 px-[auto] web:pb-[181px] phone:pb-8 ">
        {businessType === "PG" || businessType === "AT" ? (
          <div className="web:w-160 phone:w-full">
            <div className="text-left">
              <div className="font-bold text-xl mt-4 web:hidden phone:block">
                {t(`AS-Host-info.Business information`)}
              </div>
              <HostWrap
                help
                host
                toolTitle="운영 국가를 설정해주세요."
                toolSubTitle={
                  "머스트 아웃도어를 통해 호스팅을 운영할 국가를 선택해주세요.\n(공유지 주소가 해당되는 국가와 동일하게 설정)"
                }
                title={t("AS-Host-info.Operating Country*")}
              >
                <HostInput
                  selectTrans="Country-name"
                  select
                  onChange={(e) => {
                    setOperatingCountry(e.target.value);
                  }}
                  size="XL"
                  placeholder={t("AS-Host-info.Select")}
                  options={operateCode}
                />
              </HostWrap>
              <HostWrap
                help
                toolTitle="추가 가능한 연락처가 있으신가요?"
                toolSubTitle="호스팅을 사업처럼 운영하고 싶다면 별도의 연락처를 기재하여 호스팅 전용으로 관리하시면 더욱 편리합니다. (개인 연락처 중복 가능)"
                title={t("AS-Host-info.Telephone Number")}
              >
                <HostInput
                  select
                  selectTrans="Country-code"
                  size="S"
                  placeholder={t("AS-Host-info.Select Country Code")}
                  onChange={(e: any) => setSelectCode(e.target.value)}
                  selected={selectCode}
                  options={Code}
                />
                <HostInput
                  onChange={(e: any) => {
                    setTelephone(e.target.value);
                  }}
                  input
                  size="L"
                />
              </HostWrap>
              <div>
                <HostWrap
                  help
                  toolTitle="야외 공간의 소유주임을 증명해주세요."
                  toolSubTitle="공유할 야외 공간의 소유자임을 증명할 수 있는 토지매매서류, 임대 계약서 중 1개를 업로드해주세요. 개인이 운영하는 사업체의 부지 내 공간이라면 사업자 등록증도 괜찮습니다."
                  host
                  title={"AS-Host-info.Ownership Certificate*"}
                  column
                >
                  <HostInput
                    select
                    size="XL"
                    selectTrans="AS-Host-info"
                    options={certify}
                    onChange={handleCertify}
                    selected={selected}
                    placeholder={"AS-Host-info.Select Ownership Certificate"}
                  />
                  {selected === "" ? (
                    <div></div>
                  ) : selected ===
                      "Ownership Certificate of Private Property(Ground)*" ||
                    selected === "개인호스트" ? (
                    <HostInput
                      upload
                      onChange={(e: any) => {
                        handleFile(e);
                      }}
                      formTitle="AS-Host-info.Lease or Rental Contract of a land"
                      size="XL"
                    />
                  ) : (
                    <>
                      <HostInput
                        type="number"
                        input
                        onChange={(e) => {
                          setBizNumber(e.target.value);
                        }}
                        size="XL"
                        formTitle="AS-Host-info.Business Registration Number*"
                      />
                      <HostInput
                        upload
                        onChange={(e: any) => {
                          handleBiz(e);
                        }}
                        size="XL"
                        formTitle="AS-Host-info.Business Registration Certificate*"
                      />
                    </>
                  )}
                </HostWrap>
              </div>
            </div>
            <div className="flex items-center justify-end mt-12 text-right">
              <Button
                onClick={() => {
                  SaveHost();
                }}
                className="rounded-lg bg-pending px-4 py-3 text-center inline-block text-pure-white"
                isIcon
                iconClassName="fill-pure-white"
                src={"save"}
                btnText={t("AS-Host-info.Save Host Information")}
              />
            </div>
          </div>
        ) : // Accommodation
        businessType === "AC" ? (
          <div className="web:w-160 phone:w-full">
            <div className="text-left">
              <div className="phone:block web:hidden font-bold text-xl mt-4">
                {t(`AS-Host-info.Business information`)}
              </div>
              <HostWrap title={"AS-Host-info.Name of Business"}>
                <HostInput
                  onChange={(e) => {
                    setBizName(e.target.value);
                  }}
                  input
                  size="XL"
                />
              </HostWrap>
              <HostWrap
                help
                host
                toolTitle="운영 국가를 설정해주세요."
                toolSubTitle={
                  "머스트 아웃도어를 통해 호스팅을 운영할 국가를 선택해주세요.\n(공유지 주소가 해당되는 국가와 동일하게 설정)"
                }
                title={t("AS-Host-info.Operating Country*")}
              >
                <HostInput
                  select
                  selectTrans="Country-name"
                  onChange={(e) => {
                    setOperatingCountry(e.target.value);
                  }}
                  size="XL"
                  placeholder={t("AS-Host-info.Select")}
                  options={operateCode}
                />
              </HostWrap>
              <HostWrap title={"AS-Host-info.Telephone Number"}>
                <HostInput
                  onChange={(e) => {
                    setSelectCode(e.target.value);
                  }}
                  selectTrans="Country-code"
                  select
                  size="S"
                  options={Code}
                  placeholder={"AS-Host-info.Select Country Code"}
                />
                <HostInput
                  onChange={(e) => {
                    setTelephone(e.target.value);
                  }}
                  input
                  size="L"
                />
              </HostWrap>
              <HostWrap title="AS-Host-info.Business Registration" column>
                <HostInput
                  input
                  size="XL"
                  onChange={(e: any) => {
                    setBizNumber(e.target.value);
                  }}
                  formTitle="AS-Host-info.Business Registration Number*"
                />
                <HostInput
                  upload
                  onChange={(e: any) => {
                    handleBiz(e);
                  }}
                  size="XL"
                  formTitle="AS-Host-info.Business Registration Certificate*"
                />
              </HostWrap>
              {operatingCountry === "South Korea" ? (
                <HostWrap
                  title="AS-Host-info.Tourism Business Certificate of Registration"
                  column
                >
                  <HostInput
                    input
                    onChange={(e) => {
                      setTourismNum(e.target.value);
                    }}
                    size="XL"
                    formTitle="AS-Host-info.Tourism Business Number"
                  />
                  <HostInput
                    upload
                    onChange={(e: any) => {
                      handleFile(e);
                    }}
                    size="XL"
                    formTitle="AS-Host-info.Tourism Business Certificate"
                  />
                </HostWrap>
              ) : (
                ""
              )}
            </div>
            <div className="web:max-w-1280 flex justify-end mt-12 text-right">
              <Button
                onClick={() => {
                  SaveHost();
                }}
                className="rounded-lg items-center bg-pending px-4 py-3 text-center inline-block text-pure-white"
                isIcon
                src={"save"}
                iconClassName="fill-pure-white"
                btnText={"AS-Host-info.Save Host Information"}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="phone:hidden web:ml-4 web:w-full web:text-left"></div>
    </div>
  );
};

export default BusinessInfo;
