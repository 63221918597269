import React, { useEffect } from "react";

import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import { useNavigate } from "react-router-dom";
import { previewData } from "redux/action/private";
import { RootState, useAppDispatch } from "redux/store";
import { useSelector } from "react-redux/es/exports";
import MyprivateCard from "./myprivateCard";
import { useTranslation } from "react-i18next";
import MoblieHeader from "components/moblieHeader";

const MyPrivateGround = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const hostNum = localStorage.getItem("hostNum");
  useEffect(() => {
    dispatch(previewData(hostNum ? hostNum : ""));
  }, [dispatch, hostNum]);

  const isPreview: any = useSelector<RootState>((state) => state.private);
  const value = isPreview.previewData;
  const {
    images,
    currentImage,
    title,
    id,
    typeInfo,
    locationThemes,
    confirmMethod,
    policyInfo,
    advanceDate,
    status,
    hidden,
  } = isPreview.previewData;

  const onClickPreview = () => {
    if (value.name !== "AxiosError") {
      navigate(`/preview/${hostNum}`, { state: hostNum });
    } else {
      return;
    }
  };

  localStorage.setItem("itemNum", id);

  useEffect(() => {
    const channelTalk: any = document.getElementById("ch-plugin");
    setTimeout(() => {
      const value = channelTalk;
      const result = value?.style ? value?.style : "";
      if (result) {
        value.style.cssText = "display: block !important";
      }
    }, 10);
  }, []);

  return (
    <div>
      <MoblieHeader center="Add-private-ground.My Private Ground" centerTitleImage="outdoor-private-ground" className="text-light-green" right={value.name !== "AxiosError" ? "Add-private-ground.Preview" : ""} onClick={() => {onClickPreview()}} />
      <div className="text-center pb-20 px-4 mx-auto lg:max-w-1216 py-8">
        <div className="flex">
          <HostAccount isApproved={status === "APPROVED" ? true : false} />
          <HostAccountHeader
            className="grow w-[864px]"
            titleIcon="outdoor-private-ground"
            isPrivate={value.name !== "AxiosError" ? true : false}
            onClick={() => {
              onClickPreview();
            }}
            title="Add-private-ground.My Private Ground"
            isBtn
          >
            <div>
              <div className="py-3 px-4 border rounded-lg web:block phone:hidden">
                {status === "APPROVED" || status === "PENDING" ? (
                  <div>
                    {t(
                      `My-private-ground.You have a registered private ground.`
                    )}
                    <br />
                    <div className="my-1">
                      {t(
                        `My-private-ground.Go Account Setting to add your new payout method`
                      )}
                      <span
                        className="text-link cursor-pointer ml-1"
                        onClick={() => {
                          navigate("/host/account", { state: { id: 1 } });
                        }}
                      >
                        {t(`My-private-ground.Go Payout Setting`)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div>
                    {t(
                      "Add-private-ground.You haven’t registered a private ground yet!"
                    )}
                  </div>
                )}
              </div>
              <div className="phone:block web:hidden">
                {status === "APPROVED" || status === "PENDING" ? (
                  <div>
                    <div className="flex justify-between items-center">
                      <div>
                        {t(
                          `My-private-ground.You have a registered private ground.`
                        )}
                      </div>
                      {/* <button
                        onClick={() => {
                          onClickPreview();
                        }}
                        className={`text-sm text-light-green cursor-pointer`}
                      >
                        {t("Add-private-ground.Preview")}
                      </button> */}
                    </div>
                    <div className="py-3 px-4 mt-4 border rounded-lg">
                      <div className="font-bold">
                        매출정산 지급방법을 미리 설정해주세요 !<br />
                        <span
                          className="text-link cursor-pointer"
                          onClick={() => {
                            navigate("/host/account", { state: { id: 1 } });
                          }}
                        >
                          {t(`My-private-ground.Go Payout Setting`)}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {t(
                      "Add-private-ground.You haven’t registered a private ground yet!"
                    )}
                  </div>
                )}
              </div>
              <MyprivateCard
                hidden={hidden}
                isValue={value}
                title={title}
                confirmMethod={confirmMethod}
                isPrivate={value.namsse !== "AxiosError" ? true : false}
                advanceDate={advanceDate}
                policyName={policyInfo?.name}
                type={typeInfo}
                location={locationThemes}
                status={status}
                id={id}
                mainImage={currentImage && currentImage.url}
              />
            </div>
          </HostAccountHeader>
        </div>
      </div>
    </div>
  );
};

export default MyPrivateGround;
