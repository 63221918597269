import React, { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { mainBackground } from "./constants";
import useThrottle from "hooks/useThrottle";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

import BackgroundSlider from "components/backgroundSlider";
import FullSizeModal from "elements/fullSizeModal";
import Icons from "elements/Icons";
import Modal from "elements/Modal";
import NewModal from "elements/newModal";
import { isMember } from "lib/isMember";
import Button from "elements/Button";
import MainCardLayout from "pages/Main/mainCardLayout";

const SearchBanner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [country, setCountry] = useState<any>([]);
  const [state, setState] = useState<any>([]);
  const [city, setCity] = useState<any>([]);

  const [countryList, setCountryList] = useState<any>([]);
  const [stateList, setStateList] = useState<any>([]);
  const [countryId, setCountryId] = useState<number>(1);

  const [isOpen, setIsOpen] = useState(false);

  const [isDrag, setIsDrag] = useState(false);
  const [startX, setStartX] = useState(0);

  const [isMobile, setIsMobile] = useState(false);
  const [subModal, setSubModal] = useState(false);
  const [subConfirm, setSubConfirm] = useState(false);

  const countryResult = country?.filter(
    (v: any, i: any) => country.indexOf(v) === i
  );
  const stateResult = state?.filter((v: any, i: any) => state.indexOf(v) === i);

  const scrollRef = useRef<any>(null);

  const onDragStart = (e: any) => {
    if (!scrollRef?.current) return;
    e.preventDefault();
    setIsDrag(true);
    setStartX(e.pageX + scrollRef.current.scrollLeft);
  };

  const onDragEnd = useCallback(() => setIsDrag(false), []);

  const onDragMove = useCallback(
    (e: any) => {
      // if (isDrag) {
      //   const { scrollWidth, clientWidth, scrollLeft } = scrollRef.current;
      //   scrollRef.current.scrollLeft = startX - e.pageX;
      //   if (scrollLeft === 0) {
      //     setStartX(e.pageX);
      //   } else if (scrollWidth <= clientWidth + scrollLeft) {
      //     setStartX(e.pageX + scrollLeft);
      //   }
      // }
    },
    [isDrag, startX]
  );

  const onThrottleDragMove = useThrottle(onDragMove, 10);

  // const getCountryList = async () => {
  //   const res = await requestToMustOutdoor.get("/guest/main/new/country");
  //   return res.data.data;
  // }

  // const getStateList = async (countryId:number) => {
  //   const res = await requestToMustOutdoor.get(`/guest/main/new/country/${countryId}`);
  //   return res.data.data;
  // }

  // const result = useQueries([
  //   { queryKey: ["getCountry"], queryFn: getCountryList, staleTime: Infinity,enabled: isOpen },
  //   {
  //     queryKey: ["getState"],
  //     queryFn: () => getStateList(countryId),
  //     staleTime: Infinity,
  //     enabled: !!countryId,
  //     onSuccess: (data:any) => {console.log(data)}
  //   },
  // ]);

  const handleCountryList = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: "/guest/main/new/country",
    }).then((res) => {
      const value = res.data.data;
      setCountryList(value);
      return value;
    });
  }, []);

  useEffect(() => {
    handleCountryList();
  }, [isOpen]);

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/guest/main/new/country/${countryId}`,
    }).then((res) => {
      const value = res.data.data.stateInfo;
      setStateList(value);
      return value;
    });
  }, [countryId]);

  const List = [
    {
      name: countryResult,
      stateList: [
        stateResult?.map((stateItem: string) => {
          return {
            name: stateItem,
            cityList: city?.map((cityItem: any) => {
              if (stateItem === cityItem?.state) {
                return {
                  city: cityItem?.city,
                  country: cityItem?.country,
                  description: cityItem?.description,
                  id: cityItem?.id,
                  imgUrl: cityItem?.imgUrl,
                  name: cityItem?.name,
                  state: cityItem?.state,
                };
              }
            }),
          };
        }),
      ],
    },
  ];

  useEffect(() => {
    requestToMustOutdoor("/guest/main/choice/destination")
      .then((res) => {
        const data = res.data.data;
        data.map((item: any) => {
          setCountry((pre: any) => [...pre, item.country]);
          setState((pre: any) => [...pre, item.state]);
          setCity((pre: any) => [
            ...pre,
            {
              state: item.state,
              name: item.name,
              description: item.description,
              imgUrl: item.imageUrl,
              country: item.country,
              city: item.city,
              id: item.id,
            },
          ]);
        });
        return res.data.data;
      })
      .catch((err) => {});
  }, []);

  const renderTitle = () => (
    <>
      <div className="my-8 font-bold text-5xl text-pure-white text-center">
        {t("Main.GO PLAY THE GREAT OUTDOORS")}
      </div>
      <div className="hidden sm:block text-2xl text-pure-white text-center">
        {t("Main.Adventure awaits you")}
      </div>
      <div className="text-2xl text-pure-white text-center">
        {t("Main.time to explore Outdoor Adventure")}
      </div>
    </>
  );
  const { data: mainBanner } = useQuery("banner", async () => {
    const res = await mainBackground;
    return res;
  });

  const resizingHandler = () => {
    if (window.innerWidth <= 760) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // 우선 맨 처음 1023이하면 모바일 처리
  useEffect(() => {
    if (window.innerWidth <= 760) {
      setIsMobile(true);
    }

    window.addEventListener("resize", resizingHandler);
    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);

  const handleSubscribe = () => {};

  return (
    <div className="bg-[#f8f8f8] phone:px-4 web:px-8 phone:py-8 web:py-24">
      <div>
        {subModal ? (
          <NewModal
            onClick={() => {
              if (!isMember()) {
                alert("로그인이 되어있어야 이용 가능한 서비스 입니다 :)");
                navigate("/log-in", {
                  replace: true,
                });
              } else {
                setSubModal(false);
                setSubConfirm(true);
              }
            }}
            cancelButton
            setModalOpen={() => {
              setSubModal(!subModal);
            }}
            buttonText="새로운 장소 알림 받기"
            buttonColor="bg-gold"
            title={
              "MUST OUTDOOR가 직접 발견한\n새로운 장소 알림을 받아 보세요!"
            }
            subTitle="가입 하신 이메일로 내용을 받아 보실 수 있습니다."
            imageUrl="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/contact-us-sent.svg"
          ></NewModal>
        ) : (
          ""
        )}
        {subConfirm && !subModal ? (
          <NewModal
            onClick={() => {
              setSubModal(false);
              setSubConfirm(false);
            }}
            setModalOpen={() => {
              setSubConfirm(!subConfirm);
            }}
            buttonText="확인"
            title={"새로운 장소 알림 받기를 설정 하였습니다."}
            subTitle="새로운 장소가 등록되면 알려 드리겠습니다."
            imageUrl="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-confirmed-approve.svg"
          ></NewModal>
        ) : (
          ""
        )}
        {/* <div
          onClick={() => {
            setSubModal(true);
          }}
        >
          {isMobile ? (
            <img
              className="cursor-pointer bg-center bg-cover w-full max-h-[256px]"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/main/Main+Slider+image_mobile.png"
              alt=""
            />
          ) : (
            <img
              className="cursor-pointer bg-center bg-cover object-cover w-full max-h-[640px]"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/main/Main+Slider+image_web.png"
              alt=""
            />
          )}
        </div> */}
      </div>
      {/* <div className="w-full web:py-24 phone:py-8 web:px-32 phone:px-4 lg:max-w-1216 mx-auto"> */}
      <div className="lg:max-w-1216 mx-auto">
        <div className="flex items-center justify-between web:h-80 phone:h-[159px]">
          <div className="flex items-start flex-col justify-between h-full">
            <div className="web:text-5xl phone:text-xl font-bold">{`이제 특별한 장소에서\n프라이빗하게 캠핑을 즐겨보세요!`}</div>
            <div>
              <div className="web:text-xl phone:text-sm mb-4">
                <span className="text-main-green font-bold">MUST OUTDOOR</span>
                가 직접 발견한
              </div>
              <div
                onClick={() => {
                  setSubModal(true);
                }}
                className="web:p-4 phone:text-xs web:text-base cursor-pointer phone:p-2 inline-block bg-gold rounded-lg font-bold"
              >
                {"새로운 장소 알림 받기 >"}
              </div>
            </div>
          </div>
          <div>
            <img
              className="web:w-[282px] web:h-80 phone:w-[85px] phone:h-24"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/main/Main-camping-image.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <BackgroundSlider pictures={mainBanner} interval={4000}> */}
      {/* <div className="phone:hidden web:flex flex-col">{renderTitle()}</div>
        <div className="phone:inline-block phone:bottom-4 phone:left-4 top phone:absolute web:hidden phone:text-left">
          <div className="text-2xl font-bold text-pure-white">
            GO PLAY
            <br />
            THE GREAT OUTDOORS
          </div>
          <div className="text-pure-white web:block phone:hidden">
            It's time to explore Outdoor Adventures
            <br />
            here at MUST OUTDOOR
          </div>
        </div>
        <div className="phone:hidden mt-8 w-[880px] rounded-lg bg-opacity-60 bg-input-border-color p-4 web:block">
          <div className="flex flex-col">
            <div className="flex relative">
              <input
                placeholder={t("Main.Search")}
                readOnly
                onClick={() => {
                  setIsOpen((isOpen) => !isOpen);
                }}
                className="w-full pl-10 p-2 border-b rounded-md"
              />
              <img
                className="w-6 h-6 top-[8px] left-2 absolute"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/destination.svg"
                alt="Destination"
              />
              <img
                onClick={() => {
                  setIsOpen((isOpen) => !isOpen);
                }}
                className="absolute w-6 h-6 right-4 top-[8px] cursor-pointer"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/search.svg"
                alt=""
              />
            </div>
            {isOpen ? (
              <div className="flex z-[10000] items-start">
                <div className="max-h-[144px] max-w-[160px] overflow-y-scroll">
                  {countryList?.map(
                    (
                      item: { id: number; name: string; featured: boolean },
                      idx: number
                    ) => {
                      const name = item.name;
                      const id = item.id;
                      const featured = item.featured;
                      if (featured) {
                        return (
                          <div
                            key={idx}
                            onClick={() => {
                              setCountryId(id);
                            }}
                            className="px-4 py-2 font-bold bg-pure-white hover:bg-light-green hover:statePick"
                          >
                            {t(`Country-name.${name}`)}
                          </div>
                        );
                      }
                    }
                  )}
                </div>
                <div className="bg-pure-white flex-1 max-h-48 overflow-x-scroll">
                  {stateList?.map((stateItem: any, i: number) => {
                    return (
                      <div key={i} className="flex items-center">
                        <div className="py-2 px-3 bg-pure-white w-50 text-left border-r border-input-text">
                          {t(`Kor-state.${stateItem.krName}`)}
                        </div>
                        <div
                          ref={scrollRef}
                          id="scrollDiv"
                          onMouseDown={(e) => {
                            onDragStart(e);
                          }}
                          onMouseMove={isDrag ? onThrottleDragMove : undefined}
                          onMouseUp={() => {
                            onDragEnd();
                          }}
                          onMouseLeave={() => {
                            onDragEnd();
                          }}
                          className="flex min-w-[200px] flex-nowrap w-[609px] bg-pure-white overflow-x-scroll whitespace-nowrap noScroll"
                        >
                          {stateItem?.cityInfo?.map(
                            (item: any, idx: number) => {
                              const cityName = item.city;
                              if (stateItem.name && item?.state) {
                                const city = item.city;
                                const imageUrl = item.imageUrl;
                                const description = item.description;
                                const name = item.name;
                                return (
                                  <div
                                    key={idx}
                                    className="mx-4 py-2 text-input-border-color cursor-pointer hover:text-light-green"
                                    onClick={() => {
                                      navigate(`/search/${item.id}`, {
                                        state: {
                                          imageUrl: imageUrl,
                                          name: name,
                                          desc: description,
                                          city: city,
                                        },
                                      });
                                    }}
                                  >
                                    {cityName}
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div> */}
      {/* </BackgroundSlider> */}
    </div>
  );
};

export default SearchBanner;
