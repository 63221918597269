import AcWrap from "elements/AcWrap";
import Button from "elements/Button";
import CheckBoxDefault from "elements/CheckBoxDefault";
import React, { useEffect, useState } from "react";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

const EditAcDetails = ({ acDetailData }: any) => {
  const [FacilityIcon, setFacilityIcons] = useState<any>([]);
  const [ActivityIcon, setActivityIcons] = useState<any>([]);
  const [themes, setThemes] = useState<any>([]);

  const [facilityList, setFacilityList] = useState<any>([]);
  const [activityList, setActivityList] = useState<any>([]);
  const [themeInfo, setThemeInfoList] = useState<any>([]);

  const [specialFacilityValue, setSpecialFacility] = useState("");

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/private-ground/fetch/icons`,
    })
      .then((res) => {
        const data = res.data.data;
        setFacilityIcons(data.facilities);
        setActivityIcons(data.activities);
        setThemes(data.themes);
        return data;
      })
      .catch((err) => {});
  }, []);

  const { activities, facilities, locationThemes, specialFacility } = acDetailData;

  const activityCheckId = activities?.map((item: any) => {
    const value = item.activityList;
    return value?.id;
  });
  const facilityCheckId = facilities?.map((item: any) => {
    const value = item.facilityList;
    return value?.id;
  });
  const themeCheckId = locationThemes?.map((item: any) => {
    const value = item.themeInfo;
    return value?.id;
  });

  useEffect(() => {
    if (facilityCheckId) {
      setFacilityList([...facilityCheckId]);
    }
    if (activityCheckId) {
      setActivityList([...activityCheckId]);
    }
    if (themeCheckId) {
      setThemeInfoList([...themeCheckId]);
    }
    setSpecialFacility(specialFacility);
  }, [acDetailData]);


  const Facility = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setFacilityList([...facilityList, result]);
    } else if (!checked) {
      const result = Number(item);
      setFacilityList(facilityList.filter((el: any) => el !== result));
    }
  };

  const Activities = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setActivityList([...activityList, result]);
    } else if (!checked) {
      const result = Number(item);
      setActivityList(activityList.filter((el: any) => el !== result));
    }
  };

  const Themes = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setThemeInfoList([...themeInfo, result]);
    } else if (!checked) {
      const result = Number(item);
      setThemeInfoList(themeInfo.filter((el: any) => el !== result));
    }
  };

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          title="Add-accommodation.Facility"
          help
          toolTitle="함께 이용 가능한 시설을 알려주세요."
          toolSubTitle="숙박 장소 내 편의시설이 있다면 게스트의 만족도를 더욱 높일 수 있습니다. 나열된 리스트 외 공유지만의 특별한 시설이 있다면 아래 ‘추가 시설’ 섹션에서 정보를 제공해주세요."
        >
          <div
            className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
          >
            {FacilityIcon?.map((item: any, idx: number) => {
              const iconUrl = item.iconUrl;
              const enName = item.enName;
              const id = item.id;
              return (
                <CheckBoxDefault
                  key={idx}
                  id={id}
                  imageUrl={iconUrl}
                  defaultChecked={
                    facilityList?.includes(id) === true ? true : false
                  }
                  title={`Facility-data.${enName}`}
                  value={enName}
                  onChange={(e: any) => {
                    Facility(e.target.checked, e.target.id);
                  }}
                />
              );
            })}
          </div>
        </AcWrap>
        <AcWrap
          title="Add-accommodation.Activities"
          help
          toolTitle="함께 즐길 수 있는 액티비티가 있나요?"
          toolSubTitle="숙박 장소에서 즐길 수 있는 캠프파이어, 영화감상이나 주변에서 즐길 수 있는 낚시, 갯벌 체험, 트래킹/하이킹 코스 등이 있다면 모두 체크해주세요."
        >
          <div
            className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
          >
            {ActivityIcon?.map((item: any, idx: number) => {
              const iconUrl = item.iconUrl;
              const enName = item.enName;
              const id = item.id;
              return (
                <CheckBoxDefault
                  key={idx}
                  id={id}
                  imageUrl={iconUrl}
                  defaultChecked={
                    activityList?.includes(id) === true ? true : false
                  }
                  onChange={(e: any) =>
                    Activities(e.target.checked, e.target.id)
                  }
                  value={enName}
                  title={`Activity-data.${enName}`}
                />
              );
            })}
          </div>
        </AcWrap>
        <AcWrap
          title="Add-accommodation.Location Theme*"
          help
          toolTitle={
            "숙박 장소의 특징을 가장 잘 나타내는 테마를 선택해주세요.\n(최대 3개)"
          }
          toolSubTitle="논, 밭이 보이는 시골풍경, 탁 트인 해변가 등과 같은 지형 타입과 주변 풍경은
          게스트가 방문지 선택 시 아주 중요한 요소로써 필터링 검색기능과 연계되는 부분
          입니다. 공유하는 숙박 장소의 주변 지형 및 풍경 등을 고려하여 가장 대표적인
          테마를 선택해주세요."
        >
          <div
            className={`flex web:flex-row phone:flex-col flex-wrap text-sm mt-4`}
          >
            {themes?.map((item: any, idx: number) => {
              const iconUrl = item.iconUrl;
              const enName = item.enName;
              const id = item.id;
              return (
                <CheckBoxDefault
                  key={idx}
                  id={id}
                  imageUrl={iconUrl}
                  defaultChecked={
                    themeInfo?.includes(id) === true ? true : false
                  }
                  onChange={(e: any) => Themes(e.target.checked, e.target.id)}
                  title={`Theme-data.${enName}`}
                  value={enName}
                />
              );
            })}
          </div>
        </AcWrap>
        <AcWrap
          help
          toolTitle={"별도의 시설이 있다면 추가해주세요."}
          toolSubTitle="공유하는 숙박 장소만의 특별한 시설이나 이용 방법 등 추가 내용을 입력해주세요."
          onChange={(e) => {
            setSpecialFacility(e.target.value);
          }}
          inputValue={specialFacilityValue}
          textArea
          row={7}
          placeholder="• Please describe the special facility for travelers."
          title="Add-accommodation.Special Facility"
          inputMaxLength={500}
        />
        <div className="flex w-full justify-end items-center">
          <Button
            btnText={"Add-accommodation.Save"}
            className="inline-block bg-tan rounded-lg py-3 px-4 text-pure-white"
          />
        </div>
      </div>
    </div>
  );
};

export default EditAcDetails;
