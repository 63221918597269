import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";

interface newModalProps {
  title?: string;
  subTitle?: string;
  imageUrl?: string;
  cancelButton?: boolean;
  buttonText?: string;
  buttonColor?: string;
  children?: any;
  className?: string;
  onClick?: () => void;
  setModalOpen?: any;
}

const NewModal = ({
  title,
  children,
  subTitle,
  imageUrl,
  cancelButton,
  buttonText,
  buttonColor,
  className,
  onClick,
  setModalOpen,
}: newModalProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);
  return (
    <div
      id="ModalOverlay"
      className={`fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,.7)] box-border z-[9999] ${className}`}
    >
      <div
        id="Modal"
        className="absolute top-2/4 -translate-x-2/4 -translate-y-2/4 left-2/4 web:w-full phone:w-[328px] web:max-w-[560px] max-h-[500px] box-border bg-pure-white rounded-lg"
      >
        <div className="web:p-8 phone:p-4 text-center whitespace-pre-wrap">
          {imageUrl ? (
            <img src={imageUrl} className="w-32 h-32 inline-block" alt="" />
          ) : (
            ""
          )}
          <div
            className={`font-bold ${imageUrl ? "mt-8" : ""} ${
              subTitle ? "mb-4" : ""
            } `}
          >
            {t(`${title ? title : ""}`)}
          </div>
          {children ? children : <div>{t(`${subTitle ? subTitle : ""}`)}</div>}
          <div
            className={`flex mt-8 text-sm w-full ${
              cancelButton ? "justify-between" : "justify-center"
            }`}
          >
            {cancelButton ? (
              <Button
                onClick={setModalOpen}
                btnText={t("My-profile.Cancel")}
                className="web:min-w-[160px] phone:min-w-[120px] px-2 py-3 rounded-lg border border-input-border-color"
              />
            ) : (
              ""
            )}
            <Button
              onClick={onClick}
              btnText={buttonText}
              className={`${buttonColor} web:min-w-[160px] phone:min-w-[120px] px-2 py-3 rounded-lg`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewModal;
