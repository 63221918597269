import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icons from "./Icons";

interface ModalProps {
  children?: any;
  subClassName?: string;
  className?: string;
  headerWrap?: boolean;
  headerTitle?: string;
  headerBgColor?: string;
  headerTextColor?: string;
  setModalClose?: any;
  fullScreenModal?: boolean;
  typesWidth?: string;
  setModalOpen?: any;
}

const Modal = ({
  children,
  subClassName,
  fullScreenModal,
  setModalClose,
  headerTitle,
  headerWrap,
  headerBgColor,
  headerTextColor,
  className,
  typesWidth,
  setModalOpen,
}: ModalProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <div
      id="ModalOverlay"
      className="fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,.7)] box-border z-[10002]"
    >
      <div
        id="Modal"
        className={`web:-translate-y-[50%] phone:-translate-y-[60%] left-2/4 top-2/4 -translate-x-[50%]  absolute phone:w-[calc(100%-2rem)] phone:h-[calc(100vh-200px)] ${
          typesWidth ? typesWidth : "web:w-[560px] max-h-[500px]"
        } box-border bg-pure-white rounded-lg`}
      >
        <div>
          {headerWrap ? (
            <div className="flex justify-between py-3 px-4 items-center bg-tan rounded-t-lg">
              <div></div>

              <div className="text-center font-bold text-pure-white">
                {t(`${headerTitle}`)}
              </div>
              <Icons
                onClick={setModalOpen}
                iconName="menu-close"
                className="w-4 h-4 cursor-pointer fill-pure-white"
              />
            </div>
          ) : (
            ""
          )}
          <div className="web:p-4 web:px-8 text-center phone:p-2">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
