import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import commonSlice from "./slice/commonSlice";
import hostSlice from "./slice/hostSlice";
import privateSlice from "./slice/privateSlice";
import userSlice from "./slice/userSlice";
import addPrivateSlice from './slice/addPrivateSlice'
import hostingSlice from "./slice/hostingSlice";
import accommodationSlice from "./slice/accommodationSlice";
import spaceSlice from "./slice/spaceSlice";
import spaceListSlice from "./slice/spaceListSlice";

export const store = configureStore({
    reducer: {
        userinfo: userSlice,
        hostinfo: hostSlice,
        commons: commonSlice,
        private: privateSlice,
        privateGround: addPrivateSlice.reducer,
        accommodation: accommodationSlice.reducer,
        space: spaceSlice.reducer,
        hosting: hostingSlice.reducer,
        spaces: spaceListSlice.reducer
    },
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector