import React, { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import BookingWrap from "components/Booking/bookingWrap";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { changecase, changeFacility, changeSpace } from "utils/changeCase";
import DetailInfo from "elements/DetailInfo";
import Button from "elements/Button";
import { useTranslation } from "react-i18next";
import { getEveryDiffDay } from "utils/getDiffDay";
import MoblieHeader from "components/moblieHeader";
import { changeDate } from "utils/changeDate";
import Icons from "elements/Icons";
import { useScroll } from "hooks/useScroll";

const MemberDetailView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const location: any = useLocation();
  const [pageData, setPageData] = useState<any>({} || undefined);
  const [cancelTap, setCancelTap] = useState(false);
  const [cancelReservation, setCancelReservation] = useState(false);

  const { element, onMoveElement } = useScroll();

  const [refund, setRefund] = useState("");
  const [cancelDate, setCancelDate] = useState([]);
  const [active, setActive] = useState<number[]>([]);

  // Booking Status
  // CANCELLED_BY_ADMIN : 어드민(머스트아웃도어)가 취소 (전액환불) => cancelled Booking
  // CANCELLED_BY_HOST : 호스트가 취소 (전액환불) => cancelled Booking
  // CANCELLED_BY_TRAVELER : 여행자가 취소 (환불정책에 따라 취소) => cancelled Booking
  // COMPLETED : 체크아웃 +1일 => booking history
  // CONFIRMED : 체크인 전 [호스트 수락/즉시예약시] => Current Booking
  // DECLINED : 호스트 거절 => cancelled Booking
  // PAYMENT_ERROR : 결제에러 (전액환불) => 안띄움
  // PENDING : 호스트 응답 대기 => Current Booking
  // REQUEST_CANCELLED : 게스트가 Pending 일 때 취소요청 => cancelled Booking

  const localName = localStorage.getItem("name");

  const { reserveId, pgId } = location?.state;

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `api/v1/booking/fetch/${pgId}/${reserveId}`,
    })
      .then((res) => {
        setPageData(res.data.data);
        return res.data.data;
      })
      .catch((err) => {});
  }, [params.id]);

  const {
    pgTitle,
    typeIcon,
    typeEnName,
    pgUnitAmount,
    pgAccessibleVehicle,
    pgSpaceSize,
    reservationCode,
    amount,
    pgZipcode,
    pgState,
    pgCity,
    pgAddress,
    pgExtraAddress,
    pgDirection,
    hostImage,
    checkInTime,
    checkOutTime,
    nearbyFacilities,
    bookingOptions,
    pgAdvanced,
    pgExtraPolicy,
    policyName,
    hostResponseRate,
    hostResponseTime,
    firstRange,
    secondRange,
    thirdRange,
    fourthRange,
    fifthRange,
    hostMobile,
    hostLanguages,
    hostName,
    merchantUid,
    paymentMethod,
    reservationId,
    id,
    pgCurrentImage,
    travelerId,
    travelerInfo,
    paymentId,
    status,
    checkIn,
    checkOut,
    reservationCreatedAt,
    travelerImage,
    travelerName,
    travelerMobileCode,
    travelerMobile,
    travelerLanguage,
    hostMobileCode,
    impUid,
    price,
    totalPrice,
    plateNumber,
  } = pageData;

  const now = new Date();

  const cancelBooking = () => {
    if (status === "PENDING") {
      requestToMustOutdoor({
        method: "PATCH",
        url: "/api/v1/booking/restrict/request",
        data: {
          reservationId: reservationId,
          totalMemberId: travelerId,
        },
      }).then((res) => {
        navigate("/member/booking", { replace: true });
        return res.data.data;
      });
    } else {
      requestToMustOutdoor({
        method: "POST",
        url: `/api/v1/booking/cancel`,
        data: {
          cancelReason: "",
          impUid: impUid,
          merchantUid: merchantUid,
          paymentId: paymentId,
          refundAmount: refund,
          reservationId: reservationId,
          totalMemberId: travelerId,
        },
      })
        .then((res) => {
          navigate("/member/booking", { replace: true });
          const data = res.data.data;
          return data;
        })
        .catch((err) => {
          //console.logerr);
        });
    }
  };

  const night = getEveryDiffDay(checkIn, checkOut);

  const areYouSureModal = () => {
    setCancelTap((cancelTap) => !cancelTap);
    setCancelReservation(false);
  };
  const cancelReservationModal = () => {
    setCancelTap(false);
    setCancelReservation(true);
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v1/booking/refund/price",
      data: {
        reservationId: reservationId,
        totalMemberId: travelerId,
      },
    })
      .then((res) => {
        const data = res.data.data;
        setRefund(data.refundPrice);
        return res.data.data;
      })
      .catch((err) => {
        return err;
      });
  };

  return (
    <div>
      <div className="p-8 mx-auto lg:max-w-1216 phone:hidden web:block">
        {status === "CONFIRMED" ? (
          <div className="p-4 mb-8 w-[1152px] bg-light-green rounded-lg text-pure-white flex">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-confirmed-approve.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="web:text-2xl phone:text-xl font-bold">
                {t("Booking-status-data.Booking is confirmed")}
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Redeem your outdoor life with Booking #. It’s time to explore your Outdoor Life!"
                )}
              </div>
            </div>
          </div>
        ) : status === "PENDING" ? (
          <div className="p-4 mb-8 w-full bg-pending rounded-lg text-pure-white flex">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/pending.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="web:text-2xl phone:text-xl font-bold">
                {t("Booking-status-data.Booking is Pending")}
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Your booking is requested to the host and waiting for approval."
                )}
              </div>
            </div>
          </div>
        ) : status === "REQUEST_CANCELLED" ||
          status === "CANCELLED_BY_TRAVELER" ||
          status === "CANCELLED_BY_HOST" ||
          status === "CANCELLED_BY_ADMIN" ? (
          <div className="p-4 mb-8 w-full bg-pure-white shadow-btn rounded-lg flex">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-cancel-request.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="web:text-2xl phone:text-xl font-bold text-pure-red">
                {t("Booking-status-data.Booking is cancelled")}
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Your booking is requested to the host and waiting for approval."
                )}
              </div>
            </div>
          </div>
        ) : status === "DECLINED" ? (
          <div className="p-4 mb-8 w-full bg-pure-white shadow-btn rounded-lg flex">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-cancelled-void.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="web:text-2xl phone:text-xl font-bold text-pure-red">
                {t("Booking-status-data.Booking is declined")}
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.We regret to inform you that the host is unable to accept your booking."
                )}
                booking.
              </div>
            </div>
          </div>
        ) : status === "PAYMENT_ERROR" ? (
          <div className="p-4 mb-8 w-full bg-pure-white shadow-btn rounded-lg flex">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-failed.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="web:text-2xl phone:text-xl font-bold text-pure-red">
                {t("Booking-status-data.Booking is failed")}
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Sorry, Host did not respond to your booking request."
                )}
              </div>
            </div>
          </div>
        ) : status === "COMPLETED" ? (
          <div className="p-4 mb-8 w-full shadow-btn rounded-lg flex">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/checked-in.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="web:text-2xl phone:text-xl font-bold">
                {t("Booking-status-data.Completed Booking")}
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.You’ve completed an outdoor life. Please share your experience with other travelers in the review."
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="flex">
          <div className="w-[800px]">
            <BookingWrap
              size="M"
              title="Booking-box-status.Booking Details"
              className="mb-8"
            >
              <div className="w-[full] h-40 mb-8 rounded-lg flex text-left">
                <img className="w-66  rounded-lg" src={pgCurrentImage} alt="" />
                <div className="p-2 ">
                  <div className="text-xl font-bold max-w-[415px] text-ellipsis overflow-hidden whitespace-nowrap">
                    {pgTitle}
                  </div>

                  <div className="flex mt-6 h-5">
                    <img src={typeIcon} className="w-5 h-5 mr-2" alt="" />
                    <div>{t(`Private-Type-data.${typeEnName}`)}</div>
                  </div>
                  <div className="flex my-4 h-5">
                    <Icons
                      iconName="available-ground"
                      className="w-5 h-5 mr-2"
                    />
                    <div className="mr-4">X {pgUnitAmount}</div>
                    <Icons
                      iconName="accessible-vehicle"
                      className="w-5 h-5 mr-2"
                    />
                    <div>
                      {t(`Parking-accessible-data.${pgAccessibleVehicle}`)}
                    </div>
                  </div>
                  <div className="flex h-5">
                    <Icons iconName="space-size" className="w-5 h-5 mr-2" />
                    <div>{pgSpaceSize} m²</div>
                  </div>
                </div>
              </div>
              <DetailInfo withIcon w276 title="Booking-box-status.Booking #">
                <div className="font-bold">{reservationCode}</div>
              </DetailInfo>
              <DetailInfo withIcon w276 title="Booking-box-status.Schedule">
                <div className="w-[272px]">
                  <div className="text-grey text-sm">
                    {t("Booking-box-status.Check-in")}
                  </div>
                  <div className="font-bold">{checkIn}</div>
                </div>
                <div className="w-[213px]">
                  <div className="text-grey text-sm">
                    {t("Booking-box-status.Check-out")}
                  </div>
                  <div className="font-bold">{checkOut}</div>
                </div>
                <div></div>
              </DetailInfo>
              <DetailInfo
                nomargin={bookingOptions?.length === 0}
                withIcon
                w276
                title="Booking-box-status.Ground Unit"
              >
                <div className="font-bold flex">
                  <div className="w-[272px]">{pgTitle}</div>
                  <div className="w-[131px] mr-[54px]">X {amount}</div>
                </div>
              </DetailInfo>
              {bookingOptions?.length ? (
                <DetailInfo
                  w276
                  title="Booking-box-status.Extra Option(s)"
                  className="font-bold"
                >
                  {bookingOptions?.map((item: any, key: number) => {
                    const bookingInfo = item?.pgExtraOptionId;
                    if (
                      bookingInfo?.paymentMethod === "Available In Extra Option"
                    ) {
                      return (
                        <div className="flex" key={key}>
                          <div className="w-[272px] mb-2">
                            <div>{bookingInfo?.title}</div>
                          </div>
                          <div className="w-[131px]">
                            <div>X {item.amount}</div>
                          </div>
                        </div>
                      );
                    } else {
                      return <div>-</div>;
                    }
                  })}
                </DetailInfo>
              ) : (
                ""
              )}
            </BookingWrap>
            <BookingWrap
              size="M"
              title="Booking-box-status.Booking Request By (Traveler)"
              className="mb-8"
            >
              <div className={`flex ${status === "CONFIRMED" ? "mb-8" : ""}`}>
                <Icons iconName="my-account-avatar" className="w-12 h-12" />
                <div className="text-left ml-4">
                  <div className="inline-block text-input-text">
                    {t("Booking-box-status.Nickname")}
                  </div>
                  <div className="font-bold">{travelerName}</div>
                </div>
              </div>
              <div className="flex justify-around text-left mr-[90px]">
                <div>
                  <div className="text-input-text">
                    {t("Booking-box-status.Contact Number")}
                  </div>
                  <div>
                    {travelerMobileCode} {travelerMobile ? travelerMobile : "-"}
                  </div>
                </div>
                <div>
                  <div className="text-input-text">
                    {t("Booking-box-status.Preferred Language")}
                  </div>
                  <div>{travelerLanguage ? travelerLanguage : "-"}</div>
                </div>
                <div>
                  <div className="text-input-text">
                    {t("Booking-box-status.Plate Number")}
                  </div>
                  <div>{plateNumber ? plateNumber : "-"}</div>
                </div>
              </div>
            </BookingWrap>
            <BookingWrap
              size="M"
              title="Booking-box-status.Location"
              className="mb-8"
            >
              <div>
                <div
                  className={`${
                    status === "PENDING" ||
                    status === "REQUEST_CANCELLED" ||
                    status === "CANCELLED_BY_TRAVELER" ||
                    status === "CANCELLED_BY_HOST" ||
                    status === "CANCELLED_BY_ADMIN"
                      ? ""
                      : "mb-8"
                  }`}
                >
                  <div className="font-bold mb-4">
                    {t("Booking-box-status.Address")}
                  </div>
                  <div className="flex items-center">
                    <Icons className="w-4 h-4 mr-2" iconName={"real-address"} />
                    {status !== "CONFIRMED" ? (
                      <div>
                        {t(
                          "Booking-box-status.Information provided after booking confirmed"
                        )}
                      </div>
                    ) : (
                      <div>
                        {pgZipcode +
                          " " +
                          pgState +
                          " " +
                          pgCity +
                          " " +
                          pgAddress +
                          " " +
                          pgExtraAddress}
                      </div>
                    )}
                  </div>
                </div>
                {status !== "CONFIRMED" ? (
                  <></>
                ) : (
                  <>
                    <div className="mb-8">
                      <div className="font-bold mb-4">
                        {t("Booking-box-status.How to get there")}
                      </div>
                      <div>{pgDirection}</div>
                    </div>
                    <div>
                      <div className="font-bold mb-4">
                        {t("Booking-box-status.Nearby Facilities")}
                      </div>
                      {nearbyFacilities?.map((item: any, i: number) => {
                        if (item !== null) {
                          return (
                            <div
                              key={i}
                              className="flex items-center mb-4 last:mb-0"
                            >
                              <div className="w-[224px] flex">
                                <img
                                  className="mr-2 w-5 h-5"
                                  src={
                                    item.facility === "EV_CHARGING_STATION"
                                      ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/ev-charge-station.svg"
                                      : item.facility === "CONVENIENCE_STORE"
                                      ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/convenience-store.svg"
                                      : item.facility === "GAS_STATION"
                                      ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/gas-station.svg"
                                      : item.facility === "SUPERMARKET"
                                      ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/supermarket.svg"
                                      : item.facility === "TOILET_24HR"
                                      ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/24hr-toilet.svg"
                                      : ""
                                  }
                                  alt=""
                                />
                                <div>
                                  {t(
                                    `Nearby-facility.${changeFacility(
                                      item.facility
                                    )}`
                                  )}
                                </div>
                              </div>
                              <div>{item.name}</div>
                              <div> ({item.distance}Km)</div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </>
                )}
              </div>
            </BookingWrap>
            <BookingWrap size="M" title="Booking-box-status.Policy">
              <div className="w-full mb-8 border-input-text border bg-[#eeeeee] rounded-lg p-2">
                <div className="flex font-bold items-start">
                  <Icons iconName={"warning-yellow"} className="mr-2 w-5 h-5" />
                  <div>{t("Booking-box-status.Notice")}</div>
                </div>
                <div className="text-sm">
                  {t(
                    "Booking-box-status.MUST OUTDOOR will enforce following cancellation policy"
                  )}
                </div>
              </div>
              <div className="mb-8">
                <div className="font-bold mb-4">
                  {t("Booking-box-status.Check In / Out")}
                </div>
                <div className="flex">
                  <div className="w-[224px]">
                    {t("Booking-box-status.Check-in")} : {checkInTime}
                  </div>
                  <div className="w-[224px]">
                    {t("Booking-box-status.Check-out")} : {checkOutTime}
                  </div>
                </div>
              </div>
              <div className="mb-8">
                <div className="font-bold mb-4">
                  {t("Booking-box-status.Confirmation")}
                </div>
                <div>
                  {t(
                    "Confirmation-data.You will receive booking confirmation-hours",
                    { confirm: "24" }
                  )}
                </div>
                <div>
                  {t(
                    "Confirmation-data.In the event that you do not receive an email from us"
                  )}
                </div>
              </div>
              <div className="mb-8">
                <div className="font-bold mb-4">
                  {t("Booking-box-status.Advance Notice")}
                </div>
                <div>
                  {pgAdvanced
                    ? t("Advance-notice-data.Booking is not available today")
                    : t("Advance-notice-data.Booking is available today")}
                </div>
              </div>
              <div className="mb-8">
                <div className="font-bold mb-4">
                  {t("Booking-box-status.Additional Rule / Policy")}
                </div>
                <div>{pgExtraPolicy}</div>
              </div>
              <div>
                <div className="font-bold mb-4 relative flex justify-between">
                  <div>
                    {t("Booking-box-status.Cancellation & Refund Policy")}
                  </div>
                  {status === "CONFIRMED" || status === "PENDING" ? (
                    <div
                      onClick={() => {
                        areYouSureModal();
                      }}
                      className="text-sm text-pure-red cursor-pointer"
                    >
                      {t("Booking-box-status.Cancel this booking")} {">"}
                    </div>
                  ) : (
                    ""
                  )}
                  {cancelTap ? (
                    <div className="rounded-lg w-[328px] absolute right-[-369px] bottom-0 min-h-[193px] shadow-btn text-center p-4">
                      <div className="font-bold mb-4">
                        {t(
                          "Booking-box-status.Are you sure \nyou want to cancel this booking?"
                        )}
                      </div>
                      <div className="text-xs text-input-text">
                        {t(
                          "Booking-box-status.Cancellation fee and refund will be charged and applied in accordance with Cancellation & Refund Policy."
                        )}
                      </div>
                      <hr className="my-4 text-input-text" />
                      <div className="flex justify-between text-sm">
                        <Button
                          onClick={() => {
                            setCancelTap(false);
                          }}
                          noneShadow
                          className="inline-block my-auto"
                          btnText={"Booking-box-status.Cancel"}
                        />
                        <Button
                          onClick={() => {
                            cancelReservationModal();
                            onMoveElement();
                          }}
                          className="border text-pure-red rounded-lg border-pure-red px-4 py-2"
                          btnText={
                            "Booking-box-status.Yes, cancel this booking"
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="font-bold mb-4 flex">
                  <div className="text-pure-red">
                    {t(
                      policyName &&
                        `Cancellation-policy-data.${changecase(policyName)}`
                    )}{" "}
                  </div>
                  <div>{t("Booking-box-status.Policy on Cancellation")}</div>
                </div>
                <div>
                  <div className="leading-[24px]">
                    {firstRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}100% refund of the payment",
                          { days: firstRange }
                        ) + "\n"
                      : ""}
                    {secondRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}70% refund of the payment",
                          { days: secondRange }
                        ) + "\n"
                      : ""}
                    {thirdRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}50% refund of the payment",
                          { days: thirdRange }
                        ) + "\n"
                      : ""}
                    {fourthRange !== null
                      ? fourthRange +
                        t(
                          "Cancellation-policy-data.{{days}}30% refund of the payment",
                          { days: fourthRange }
                        ) +
                        "\n"
                      : ""}
                    {fifthRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}0% refund of the payment",
                          { days: fifthRange }
                        )
                      : ""}
                  </div>
                </div>
              </div>
            </BookingWrap>
          </div>
          <div className="ml-8 w-80 h-full">
            <BookingWrap
              size="S"
              title="Booking-box-status.Contact Host"
              className="mb-8"
            >
              <div className="flex mb-4">
                {hostImage ? (
                  <img
                    src={`${hostImage}`}
                    alt=""
                    className="w-12 h-12 rounded-lg"
                  />
                ) : (
                  <img
                    className="w-12 h-12"
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg"
                    alt=""
                  />
                )}
                <div className="text-left ml-4">
                  <div className="inline-block text-input-text">
                    {t("Booking-box-status.Hosted By")}
                  </div>
                  <div className="font-bold">{hostName}</div>
                </div>
              </div>
              <div className="flex justify-between text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Preferred Language")}
                </div>
                <div className="font-bold">{hostLanguages}</div>
              </div>
              <div className="flex justify-between text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Response Rate")}
                </div>
                <div className="font-bold">{hostResponseRate}%</div>
              </div>
              <div className="flex justify-between text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Response Time")}
                </div>
                <div className="font-bold">
                  {t(
                    hostResponseTime &&
                      `Confirmation-data.${changecase(hostResponseTime)}`
                  )}
                </div>
              </div>
              <div className="flex justify-between text-sm">
                <div className="text-input-text">
                  {t("Booking-box-status.Contact Number")}
                </div>
                <div className="font-bold">{hostMobile}</div>
              </div>
              <hr className="my-4 text-input-text" />
              <div className="text-xs flex">
                {t(
                  "Booking-box-status.To protect your payment, never transfer money"
                )}
              </div>
            </BookingWrap>
            <BookingWrap
              size="S"
              title="Booking-box-status.Payment Details"
              className="mb-8"
            >
              <div className="mb-4">
                <div className="font-bold mb-4">
                  {t("Booking-box-status.Ground Charge")}
                </div>
                <div className="mb-4">
                  <div className="flex justify-between text-sm">
                    <div className="max-w-[150px]">{pgTitle}</div>
                    <div>$ {Number(price) * amount}</div>
                  </div>
                  <div className="text-xs text-grey">
                    1 ground unit x {night && night?.length - 1} night
                  </div>
                </div>
                <hr className="text-input-text" />
              </div>
              {bookingOptions?.length ? (
                <div className="mb-4">
                  <div className="font-bold mb-4">
                    {t(`Booking-box-status.Extra Option(s)`)}
                  </div>
                  {bookingOptions?.map((item: any, key: number) => {
                    const bookingInfo = item?.pgExtraOptionId;
                    if (
                      bookingInfo?.paymentMethod === "Available In Extra Option"
                    ) {
                      let priceNum = Number(bookingInfo.price);
                      return (
                        <div className="mb-2" key={key}>
                          <div>
                            <div className="flex justify-between text-sm">
                              <div>{bookingInfo.title}</div>
                              <div>$ {priceNum * item.amount}</div>
                            </div>
                          </div>
                          <div className="text-xs text-grey">
                            $ {priceNum} x {item.amount}
                          </div>
                        </div>
                      );
                    }
                  })}
                  <hr className="text-input-text" />
                </div>
              ) : (
                ""
              )}
              <div className="flex justify-between font-bold">
                <div>{t("Booking-box-status.Total Amount")}</div>
                <div>$ {totalPrice}</div>
              </div>
            </BookingWrap>
            <BookingWrap
              size="S"
              title="Booking-box-status.Payment Receipt"
              className="mb-8"
            >
              <div>
                <div className="flex justify-between items-center text-sm mb-4">
                  <div className="text-input-text">
                    {t("Booking-box-status.Date")}
                  </div>
                  <div className="font-bold">
                    {reservationCreatedAt
                      ? changeDate(reservationCreatedAt)
                      : "-"}
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm mb-4">
                  <div className="text-input-text">
                    {t("Booking-box-status.Transaction ID")}
                  </div>
                  <div className="font-bold">
                    {merchantUid ? merchantUid : "-"}
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm">
                  <div className="text-input-text">
                    {t("Booking-box-status.Payment Method")}
                  </div>
                  <div className="font-bold">
                    {paymentMethod ? paymentMethod : "-"}
                  </div>
                </div>
                <hr className="text-border-light my-4" />
                <div className="flex justify-between items-center text-sm">
                  <div className="text-input-text">
                    {t("Booking-box-status.Amount")}
                  </div>
                  <div className="font-bold">{totalPrice}</div>
                </div>
                <hr className="text-border-light my-4" />
                <div className="flex justify-between items-center">
                  <div className="font-bold text-input-text">
                    {t("Booking-box-status.Total Amount")}
                  </div>
                  <div className="font-bold text-xl text-main-green">
                    {totalPrice}
                  </div>
                </div>
              </div>
            </BookingWrap>
            {cancelReservation ? (
              <BookingWrap
                size="S"
                _ref={element}
                title="Booking-box-status.Cancel Request"
                cancel
              >
                <div className="font-bold mb-2">
                  {t("Booking-box-status.Send a cancellation request")}
                </div>
                <div className="text-sm text-input-text">
                  {t(
                    "Booking-box-status.Refund amount will be applied in accordance with Cancellation & Refund Policy."
                  )}
                </div>
                <hr className="text-input-text my-4" />
                <div className="flex justify-between text-sm mb-4">
                  <div className="text-input-text">
                    {t("Booking-box-status.Date")}
                  </div>
                  <div className="text-sm font-bold">{changeDate(now)}</div>
                </div>
                <div className="flex justify-between text-sm mb-4">
                  <div className="text-input-text">
                    {t("Booking-box-status.Booking #")}
                  </div>
                  <div className="text-sm font-bold">{reservationCode}</div>
                </div>
                <div className="flex justify-between text-sm mb-4">
                  <div className="text-input-text">
                    {t("Booking-box-status.Cancellation Policy")}
                  </div>
                  <div className="text-pure-red font-bold text-sm">
                    {t(
                      policyName &&
                        `Cancellation-policy-data.${changecase(policyName)}`
                    )}
                  </div>
                </div>
                {/* <div className="flex justify-between text-sm">
                  <div className="text-input-text">
                    {t("Booking-box-status.Refund Amount")}
                  </div>
                  <div className="text-sm font-bold">{refund}</div>
                </div> */}
                <div className="flex justify-between mt-8 font-bold text-sm items-center">
                  <Button
                    onClick={() => {
                      setCancelReservation(false);
                    }}
                    noneShadow
                    btnText={"Booking-box-status.Cancel"}
                  />
                  <Button
                    onClick={() => cancelBooking()}
                    noneShadow
                    className="text-pure-red border border-pure-red px-4 py-2 rounded-lg"
                    btnText={"Booking-box-status.Confirm Cancellation"}
                  />
                </div>
              </BookingWrap>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* 모바일 */}
      <div className="phone:block web:hidden">
        {cancelTap ? (
          <div className="rounded-lg w-[328px] fixed top-2/4 left-2/4 text-center -translate-y-[50%] bg-pure-white shadow-btn p-4 -translate-x-[50%]">
            <div className="font-bold mb-4">
              {t(
                "Booking-box-status.Are you sure \nyou want to cancel this booking?"
              )}
            </div>
            <div className="text-xs text-input-text">
              {t(
                "Booking-box-status.Cancellation fee and refund will be charged and applied in accordance with Cancellation & Refund Policy."
              )}
            </div>
            <hr className="my-4 text-input-text" />
            <div className="flex justify-between text-sm">
              <Button
                onClick={() => {
                  setCancelTap(false);
                }}
                noneShadow
                className="inline-block my-auto"
                btnText={"Booking-box-status.Cancel"}
              />
              <Button
                onClick={() => {
                  cancelReservationModal();
                  onMoveElement();
                }}
                className="border text-pure-red rounded-lg border-pure-red px-4 py-2"
                btnText={"Booking-box-status.Yes, cancel this booking"}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        {cancelReservation ? (
          <div className="rounded-lg w-[328px] fixed top-2/4 left-2/4 text-center -translate-y-[50%] bg-pure-white shadow-btn -translate-x-[50%]">
            <div className="w-full text-left text-pure-white px-4 py-3 bg-pure-red rounded-t-lg">
              {t("Booking-box-status.Cancel Request")}
            </div>
            <div className="p-4">
              <div className="font-bold mb-2">
                {t("Booking-box-status.Send a cancellation request")}
              </div>
              <div className="text-sm text-input-text">
                {t(
                  "Booking-box-status.Refund amount will be applied in accordance with Cancellation & Refund Policy."
                )}
              </div>
              <hr className="text-input-text my-4" />
              <div className="flex justify-between text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Date")}
                </div>
                <div className="text-sm font-bold">{changeDate(now)}</div>
              </div>
              <div className="flex justify-between text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Booking #")}
                </div>
                <div className="text-sm font-bold">{reservationCode}</div>
              </div>
              <div className="flex justify-between text-sm mb-4">
                <div className="text-input-text">
                  {t("Booking-box-status.Cancellation Policy")}
                </div>
                <div className="text-pure-red font-bold text-sm">
                  {t(
                    policyName &&
                      `Cancellation-policy-data.${changecase(policyName)}`
                  )}
                </div>
              </div>
              {/* <div className="flex justify-between text-sm">
                <div className="text-input-text">
                  {t("Booking-box-status.Refund Amount")}
                </div>
                <div className="text-sm font-bold">{refund}</div>
              </div> */}
              <div className="flex justify-between mt-8 font-bold text-sm items-center">
                <Button
                  onClick={() => {
                    setCancelReservation(false);
                  }}
                  noneShadow
                  btnText={"Booking-box-status.Cancel"}
                />
                <Button
                  onClick={() => cancelBooking()}
                  noneShadow
                  className="text-pure-red border border-pure-red px-4 py-2 rounded-lg"
                  btnText={"Booking-box-status.Confirm Cancellation"}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <MoblieHeader left center="Details" />
        <div className="p-4">
          {status === "CONFIRMED" ? (
            <div className="p-4 w-full bg-light-green rounded-lg text-pure-white">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-confirmed-approve.svg"
                  alt=""
                />
                <div className="text-xl font-bold">
                  {t("Booking-status-data.Booking is confirmed")}
                </div>
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Redeem your outdoor life with Booking #. It’s time to explore your Outdoor Life!"
                )}
              </div>
            </div>
          ) : status === "PENDING" ? (
            <div className="p-4 w-full bg-pending rounded-lg text-pure-white">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/pending.svg"
                  alt=""
                />
                <div className="text-xl font-bold">
                  {t("Booking-status-data.Booking is Pending")}
                </div>
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Your booking is requested to the host and waiting for approval."
                )}
              </div>
            </div>
          ) : status === "REQUEST_CANCELLED" ||
            status === "CANCELLED_BY_TRAVELER" ||
            status === "CANCELLED_BY_HOST" ||
            status === "CANCELLED_BY_ADMIN" ? (
            <div className="p-4 w-full bg-pure-white shadow-btn rounded-lg">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-cancel-request.svg"
                  alt=""
                />
                <div className="text-xl font-bold text-pure-red">
                  {t("Booking-status-data.Booking is cancelled")}
                </div>
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Your booking is requested to the host and waiting for approval."
                )}
              </div>
            </div>
          ) : status === "DECLINED" ? (
            <div className="p-4 w-full bg-pure-white shadow-btn rounded-lg">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-cancelled-void.svg"
                  alt=""
                />
                <div className="web:text-2xl phone:text-xl font-bold text-pure-red">
                  {t("Booking-status-data.Booking is declined")}
                </div>
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.We regret to inform you that the host is unable to accept your booking."
                )}
              </div>
            </div>
          ) : status === "PAYMENT_ERROR" ? (
            <div className="p-4 w-full bg-pure-white shadow-btn rounded-lg">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-failed.svg"
                  alt=""
                />
                <div className="web:text-2xl phone:text-xl font-bold text-pure-red">
                  {t("Booking-status-data.Booking is failed")}
                </div>
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.Sorry, Host did not respond to your booking request."
                )}
              </div>
            </div>
          ) : status === "COMPLETED" ? (
            <div className="p-4 w-full bg-pure-white shadow-btn rounded-lg">
              <div className="flex items-center mb-2">
                <img
                  className="w-8 h-8 mr-2"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/checked-in.svg"
                  alt=""
                />
                <div className="text-xl font-bold">
                  {t("Booking-status-data.Completed Booking")}
                </div>
              </div>
              <div className="text-sm">
                {t(
                  "Booking-status-data.You’ve completed an outdoor life. Please share your experience with other travelers in the review."
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="w-full">
            <div className="flex justify-between my-4">
              <div className="font-bold text-main-green">
                {t(`Booking-box-status.Booking Details`)}
              </div>
              <div
                className="flex items-center"
                onClick={() => {
                  !active.includes(0)
                    ? setActive((active) => [...active, 0])
                    : setActive(active.filter((x) => x !== 0));
                }}
              >
                <div className="mr-2 text-sm">
                  {t(`Booking-box-status.Details`)}
                </div>
                <img
                  className={`${
                    active.includes(0) ? "rotate-180" : ""
                  } w-3 h-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <img
              src={pgCurrentImage}
              className="rounded-lg w-full max-h-[192px]"
              alt=""
            />
            <div className="font-bold text-main-green mt-2">{pgTitle}</div>
            {active.includes(0) ? (
              <div className="pt-4">
                <div className="flex items-center mb-2">
                  <img alt="" src={typeIcon} className="w-5 h-5 mr-2" />
                  <div>{t(`Private-Type-data.${typeEnName}`)}</div>
                </div>
                <div className="flex items-center mb-2">
                  <img
                    alt=""
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available-ground.svg"
                    className="w-5 h-5 mr-2"
                  />
                  <div className="mr-4">X {pgUnitAmount}</div>
                </div>
                <div className="flex items-center mb-2">
                  <img
                    alt=""
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                    className="w-5 h-5 mr-2"
                  />
                  <div>
                    {t(`Parking-accessible-data.${pgAccessibleVehicle}`)}
                  </div>
                </div>
                <div className="flex items-center">
                  <img
                    alt=""
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                    className="w-5 h-5 mr-2"
                  />
                  <div>{pgSpaceSize} m²</div>
                </div>
              </div>
            ) : (
              ""
            )}
            <hr className="text-border-light my-4" />
            <div className="flex justify-between">
              <div className="font-bold">
                {t("Booking-box-status.Booking #")}
              </div>
              <div>
                <div>{reservationCode}</div>
                <div className="text-right text-xs text-input-text">
                  {changeDate(reservationCreatedAt)}
                </div>
              </div>
            </div>
            <hr className="text-border-light my-4" />
            <div>
              <div className="font-bold mb-2">
                {t("Booking-box-status.Schedule")}
              </div>
              <div className="flex justify-between items-center text-sm">
                <div>{t("Booking-box-status.Check-in")}</div>
                <div className="font-bold">{checkIn}</div>
              </div>
              <div className="flex justify-between items-center text-sm">
                <div>{t("Booking-box-status.Check-out")}</div>
                <div className="font-bold">{checkOut}</div>
              </div>
            </div>
            <hr className="text-border-light my-4" />
            <div>
              <div className="font-bold mb-2">
                {t("Booking-box-status.Ground Unit")}
              </div>
              <div className="flex justify-between items-center text-sm">
                <div className="max-w-[150px]">{pgTitle}</div>
                <div className="font-bold">X {amount}</div>
              </div>
            </div>
            <hr className="text-border-light my-4" />
            {active.includes(0) && bookingOptions?.length !== 0 ? (
              <div>
                <div className="font-bold mb-2">
                  {t("Booking-box-status.Extra Option(s)")}
                </div>
                <div className="flex justify-between items-center text-sm">
                  <div>{bookingOptions[0]?.pgExtraOptionId?.title}</div>
                  <div className="font-bold">X {bookingOptions[0]?.amount}</div>
                </div>
                <hr className="text-border-light my-4" />
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            <div
              onClick={() => {
                !active.includes(1)
                  ? setActive((active) => [...active, 1])
                  : setActive(active.filter((x) => x !== 1));
              }}
              className="flex justify-between items-center"
            >
              <div className="font-bold text-main-green">
                {t("Booking-box-status.Contact Host")}
              </div>
              <img
                className={`${
                  active.includes(1) ? "rotate-180" : ""
                } w-3 h-3 transition-transform`}
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                alt=""
              />
            </div>
            <div className="flex mt-4">
              <img
                className="w-12 h-12 mr-4"
                src={
                  hostImage
                    ? hostImage
                    : "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg"
                }
                alt=""
              />
              <div className="flex flex-col justify-between">
                <div className="text-input-text">
                  {t("Booking-box-status.Hosted By")}
                </div>
                <div className="font-bold">{hostName}</div>
              </div>
            </div>
            {active.includes(1) ? (
              <div>
                <div className="flex justify-between items-center text-sm my-4">
                  <div>{t("Booking-box-status.Preferred Language")}</div>
                  <div className="font-bold">
                    {hostLanguages ? hostLanguages : "-"}
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm mb-4">
                  <div>{t("Booking-box-status.Response Rate")}</div>
                  <div className="font-bold">
                    {hostResponseRate ? hostResponseRate + "%" : "-"}
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm mb-4">
                  <div>{t("Booking-box-status.Response Time")}</div>
                  <div className="font-bold">
                    {t(
                      hostResponseTime &&
                        `Confirmation-data.${changecase(hostResponseTime)}`
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm">
                  <div>{t("Booking-box-status.Contact Number")}</div>
                  <div className="font-bold">
                    {hostMobileCode && hostMobile
                      ? hostMobileCode + " " + hostMobile
                      : "-"}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <hr className="text-border-light my-4" />
            <div className="flex text-xs items-center">
              <img
                className="mr-2 w-5 h-5"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                alt=""
              />
              <div>
                {t(
                  "Booking-box-status.To protect your payment, never transfer money"
                )}
              </div>
            </div>
            <hr className="text-border-light my-4" />
          </div>

          <div>
            <div
              onClick={() => {
                !active.includes(2)
                  ? setActive((active) => [...active, 2])
                  : setActive(active.filter((x) => x !== 2));
              }}
              className="flex justify-between items-center"
            >
              <div className="font-bold text-main-green">
                {t("Booking-box-status.Booking Request By (Traveler)")}
              </div>
              <img
                className={`${
                  active.includes(2) ? "rotate-180" : ""
                } w-3 h-3 transition-transform`}
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                alt=""
              />
            </div>
            <div className="flex mt-4">
              <img
                className="w-12 h-12 mr-4"
                src={
                  travelerImage
                    ? travelerImage
                    : "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg"
                }
                alt=""
              />
              <div className="flex flex-col justify-between">
                <div className="text-input-text">
                  {t("Booking-box-status.Nickname")}
                </div>
                <div className="font-bold">{travelerName}</div>
              </div>
            </div>
            {active.includes(2) ? (
              <div>
                <div className="flex justify-between items-center text-sm my-4">
                  <div>{t("Booking-box-status.Contact Number")}</div>
                  <div className="font-bold">
                    {travelerMobileCode && travelerMobile
                      ? travelerMobileCode + " " + travelerMobile
                      : "-"}
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm mb-4">
                  <div>{t("Booking-box-status.Preferred Language")}</div>
                  <div className="font-bold">
                    {travelerLanguage ? travelerLanguage : "-"}
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm">
                  <div>{t("Booking-box-status.Plate Number")}</div>
                  <div className="font-bold">
                    {plateNumber ? plateNumber : "-"}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <hr className="text-border-light my-4" />
          </div>

          <div>
            <div
              className="flex justify-between items-center"
              onClick={() => {
                !active.includes(3)
                  ? setActive((active) => [...active, 3])
                  : setActive(active.filter((x) => x !== 3));
              }}
            >
              <div className="font-bold text-main-green">
                {t("Booking-box-status.Payment Details")}
              </div>
              <div className="flex items-center">
                <div className="mr-2">{totalPrice}</div>
                <img
                  className={`${
                    active.includes(3) ? "rotate-180" : ""
                  } w-3 h-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
            </div>
            {active.includes(3) ? (
              <div>
                <div className="mt-4 font-bold">
                  {t("Booking-box-status.Ground Charge")}
                </div>
                <div className="flex justify-between items-center text-sm mt-2">
                  <div className="max-w-[150px]">
                    <div>{pgTitle}</div>
                  </div>
                  <div>{Number(price) * amount}</div>
                </div>
                <div className="text-xs text-input-text mt-1">
                  1 ground unit x {night && night?.length - 1} night
                </div>
                <hr className="text-border-light my-4" />

                {bookingOptions?.length ? (
                  <div className="mb-4">
                    <div className="font-bold mb-4">
                      {t("Booking-box-status.Extra Option(s)")}
                    </div>
                    {bookingOptions?.map((item: any, key: number) => {
                      const bookingInfo = item?.pgExtraOptionId;
                      if (
                        bookingInfo?.paymentMethod ===
                        "Available In Extra Option"
                      ) {
                        let priceNum = Number(bookingInfo.price);
                        return (
                          <div className="mb-2" key={key}>
                            <div>
                              <div className="flex justify-between text-sm">
                                <div>{bookingInfo.title}</div>
                                <div>$ {priceNum * item.amount}</div>
                              </div>
                            </div>
                            <div className="text-xs text-grey">
                              $ {priceNum} x {item.amount}
                            </div>
                          </div>
                        );
                      }
                    })}
                    <hr className="text-border-light my-4" />
                  </div>
                ) : (
                  ""
                )}
                <div className="flex justify-between items-center font-bold">
                  <div>{t("Booking-box-status.Total Amount")}</div>
                  <div>{totalPrice}</div>
                </div>
              </div>
            ) : (
              ""
            )}

            <hr className="text-border-light my-4" />
          </div>

          <div>
            <div
              onClick={() => {
                !active.includes(4)
                  ? setActive((active) => [...active, 4])
                  : setActive(active.filter((x) => x !== 4));
              }}
              className="flex justify-between items-center"
            >
              <div className="font-bold text-main-green">
                {t("Booking-box-status.Payment Receipt")}
              </div>
              <img
                className={`${
                  active.includes(4) ? "rotate-180" : ""
                } w-3 h-3 transition-transform`}
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                alt=""
              />
            </div>
            {active.includes(4) ? (
              <div>
                <div className="flex justify-between items-center text-sm my-4">
                  <div>{t("Booking-box-status.Date")}</div>
                  <div className="font-bold">
                    {reservationCreatedAt
                      ? changeDate(reservationCreatedAt)
                      : "-"}
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm mb-4">
                  <div>{t("Booking-box-status.Transaction ID")}</div>
                  <div className="font-bold">
                    {merchantUid ? merchantUid : "-"}
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm">
                  <div>{t("Booking-box-status.Payment Method")}</div>
                  <div className="font-bold">
                    {paymentMethod ? paymentMethod : "-"}
                  </div>
                </div>
                <hr className="text-border-light my-4" />
                <div className="flex justify-between items-center text-sm">
                  <div>{t("Booking-box-status.Amount")}</div>
                  <div className="font-bold">{totalPrice}</div>
                </div>
                <hr className="text-border-light my-4" />
                <div className="flex justify-between items-center">
                  <div className="font-bold">
                    {t("Booking-box-status.Total Amount")}
                  </div>
                  <div className="font-bold text-main-green">{totalPrice}</div>
                </div>
              </div>
            ) : (
              ""
            )}
            <hr className="text-border-light my-4" />
          </div>

          <div>
            <div
              onClick={() => {
                if (status !== "PENDING") {
                  !active.includes(5)
                    ? setActive((active) => [...active, 5])
                    : setActive(active.filter((x) => x !== 5));
                }
              }}
              className="flex justify-between items-center"
            >
              <div className="font-bold text-main-green">
                {t("Booking-box-status.Location")}
              </div>
              {status !== "PENDING" ? (
                <img
                  className={`${
                    active.includes(5) ? "rotate-180" : ""
                  } w-3 h-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              ) : (
                ""
              )}
            </div>
            {status !== "CONFIRMED" ? (
              <div className="mt-2 flex items-center">
                <img
                  className="h-4 mr-1"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/real-address.svg"
                  alt=""
                />
                <div className="text-sm">
                  {t(
                    "Booking-box-status.Information provided after booking confirmed"
                  )}
                </div>
              </div>
            ) : (
              <div className="mt-2">
                <div className="font-bold mb-2">
                  {t("Booking-box-status.Address")}
                </div>
                <div className="text-sm">
                  {pgZipcode +
                    " " +
                    pgState +
                    " " +
                    pgCity +
                    " " +
                    pgAddress +
                    " " +
                    pgExtraAddress}
                </div>
              </div>
            )}
            {active.includes(5) ? (
              <div>
                <hr className="text-border-light my-4" />
                <div className="mt-2">
                  <div className="font-bold mb-2">
                    {t("Booking-box-status.How to get there")}
                  </div>
                  <div className="text-sm">{pgDirection}</div>
                </div>
                <hr className="text-border-light my-4" />
                <div className="mt-2">
                  <div className="font-bold mb-2">
                    {t("Booking-box-status.Nearby Facilities")}
                  </div>
                  {nearbyFacilities?.map((item: any, i: number) => {
                    if (item !== null) {
                      return (
                        <div
                          key={i}
                          className="flex justify-between items-center mb-4 last:mb-0"
                        >
                          <div className="flex items-center">
                            <img
                              className="mr-2 w-4 h-4"
                              src={
                                item.facility === "EV_CHARGING_STATION"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/ev-charge-station.svg"
                                  : item.facility === "CONVENIENCE_STORE"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/convenience-store.svg"
                                  : item.facility === "GAS_STATION"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/gas-station.svg"
                                  : item.facility === "SUPERMARKET"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/supermarket.svg"
                                  : item.facility === "TOILET_24HR"
                                  ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/privateground/pg-nearby-facility/24hr-toilet.svg"
                                  : ""
                              }
                              alt=""
                            />
                            <div>
                              {t(
                                `Nearby-facility.${changeFacility(
                                  item.facility
                                )}`
                              )}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div>{item.name}</div>
                            <div> ({item.distance}Km)</div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            <hr className="text-border-light my-4" />
          </div>

          <div>
            <div className="flex justify-between my-4">
              <div className="font-bold text-main-green">
                {t("Booking-box-status.Policy")}
              </div>
              <div
                className="flex items-center"
                onClick={() => {
                  !active.includes(6)
                    ? setActive((active) => [...active, 6])
                    : setActive(active.filter((x) => x !== 6));
                }}
              >
                <div className="mr-2 text-sm">
                  {t("Booking-box-status.Details")}
                </div>
                <img
                  className={`${
                    active.includes(6) ? "rotate-180" : ""
                  } w-3 h-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="w-full mb-4 border-input-text border bg-[#eeeeee] rounded-lg p-2">
              <div className="flex font-bold items-start mb-2">
                <img
                  className="mr-2 w-5 h-5"
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                  alt=""
                />
                <div className="text-sm font-bold">
                  {t("Booking-box-status.Notice")}
                </div>
              </div>
              <div className="text-xs">
                {t(
                  "Booking-box-status.MUST OUTDOOR will enforce following cancellation policy"
                )}
              </div>
            </div>
            <div>
              <div className="font-bold mb-2">
                {t("Booking-box-status.Check In / Out")}
              </div>
              <div className="flex justify-between items-center text-sm">
                <div>{t("Booking-box-status.Check-in")}</div>
                <div className="font-bold">{checkInTime}</div>
              </div>
              <div className="flex justify-between items-center text-sm">
                <div>{t("Booking-box-status.Check-out")}</div>
                <div className="font-bold">{checkOutTime}</div>
              </div>
            </div>
            {active.includes(6) ? (
              <div>
                <hr className="text-border-light my-4" />
                <div className="mt-2">
                  <div className="font-bold mb-2">
                    {t("Booking-box-status.Confirmation")}
                  </div>
                  <div className="text-sm">
                    <div>
                      {t(
                        "Confirmation-data.You will receive booking confirmation-hours",
                        { confirm: "24" }
                      )}
                    </div>
                    <div>
                      {t(
                        "Confirmation-data.In the event that you do not receive an email from us"
                      )}
                    </div>
                  </div>
                </div>
                <hr className="text-border-light my-4" />
                <div className="mt-2">
                  <div className="font-bold mb-2">
                    {t("Booking-box-status.Advance Notice")}
                  </div>
                  <div className="text-sm">
                    {" "}
                    {pgAdvanced
                      ? t("Advance-notice-data.Booking is not available today")
                      : t("Advance-notice-data.Booking is available today")}
                  </div>
                </div>
                <hr className="text-border-light my-4" />
                <div className="mt-2">
                  <div className="font-bold mb-2">
                    {" "}
                    {t("Booking-box-status.Additional Rule / Policy")}
                  </div>
                  <div className="text-sm">{pgExtraPolicy}</div>
                </div>
              </div>
            ) : (
              ""
            )}
            <hr className="text-border-light my-4" />
            <div className="flex justify-between items-center">
              <div>{t("Booking-box-status.Cancellation & Refund Policy")}</div>
              <div
                onClick={() => {
                  areYouSureModal();
                }}
                className="cursor-pointer text-pure-red text-sm mt-4 inline-block font-bold"
              >
                {t("Booking-box-status.Cancel this booking")} {">"}
              </div>
            </div>
            <div className="font-bold flex mt-2">
              <div className="text-pure-red">
                {t(
                  policyName &&
                    `Cancellation-policy-data.${changecase(policyName)}`
                )}{" "}
              </div>
              <div>{t("Booking-box-status.Policy on Cancellation")}</div>
            </div>
            <div className="text-sm">
              <div className="leading-[24px]">
                {firstRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}100% refund of the payment",
                      { days: firstRange }
                    ) + "\n"
                  : ""}
                {secondRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}70% refund of the payment",
                      { days: secondRange }
                    ) + "\n"
                  : ""}
                {thirdRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}50% refund of the payment",
                      { days: thirdRange }
                    ) + "\n"
                  : ""}
                {fourthRange !== null
                  ? fourthRange +
                    t(
                      "Cancellation-policy-data.{{days}}30% refund of the payment",
                      { days: fourthRange }
                    ) +
                    "\n"
                  : ""}
                {fifthRange !== null
                  ? t(
                      "Cancellation-policy-data.{{days}}0% refund of the payment",
                      { days: fifthRange }
                    )
                  : ""}
              </div>
            </div>
            {status === "CONFIRMED" ? (
              <div
                onClick={() => {
                  areYouSureModal();
                }}
                className="cursor-pointer text-pure-red text-sm mt-4 inline-block font-bold"
              >
                {t("Booking-box-status.Cancel this booking")} {">"}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberDetailView;
