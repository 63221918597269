import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

export const commonData = createAsyncThunk(
    'common',
    async () => {
        try {
            const res = await requestToMustOutdoor.get("/guest/main/common/code")
            return res.data.data
        }
        catch (err: any) {
            //console.logerr)
        }
    }
);
