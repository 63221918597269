import React, { useEffect, useState } from "react";

import AcWrap from "elements/AcWrap";

import { inputPriceFormat } from "utils/commaDash";
import { useTranslation } from "react-i18next";
import Button from "elements/Button";

const EditAcExtraOption = ({ acExtraData }: any) => {
  const { t } = useTranslation();

  const [extraOption, setExtraOption] = useState<any>([{}]);

  useEffect(() => {
    setExtraOption(acExtraData);
  }, [acExtraData]);

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          // addItem
          // addOnClick={() => {
          //   addDiv();
          // }}
          help
          toolTitle="추가 옵션 사항을 설정해주세요."
          toolSubTitle={
            "제목 : 추가로 판매하고 있는 서비스 또는 상품 이름을 입력해주세요.\nEx) 바비큐 2인 세트, 낚시 장비 렌트, 갯벌 체험 등\n\n가격 : 1개당 가격을 입력해주세요.\n\n결제 방법 : 게스트가 예약 시 함께 진행하는 ‘온라인 결제 방식’ 또는 게스트 방문 시 호스트가 직접 받을 수 있는 ‘현장 결제 방식’으로 설정할 수 있습니다.\n\n최대 수량 : 하루 최대 판매 가능한 수량을 설정해주세요.\n\n설명 : 추가할 옵션에 대해 설명해주세요.\nEx) 바비큐 2인 세트 – 삼겹살 200g, 목살 200g, 소시지, 각종 야채, 햇반 및 라면 2개 등"
          }
          title="Add-accommodation.Extra Options"
        >
          {/* <ExtraOption
            preData={extraOptionData}
            setExtraOption={setExtraOptionData}
          /> */}
          <div>
            <hr className="text-input-text" />
            <div className="w-full mt-8">
              <div className="flex web:flex-row phone:flex-col items-center justify-between text-sm">
                <div className="web:w-76 phone:w-full">
                  <div>{t("Add-accommodation.Name of extra option")}</div>
                  <input
                    value={Object.keys(extraOption[0])?.length !== 0 ? extraOption[0]?.title : ""}
                    name="title"
                    onChange={(e) => {
                    //   setExtraOption({
                    //     ...extraOption,
                    //     title: e.target.value,
                    //   });
                    }}
                    className="extraInput"
                    type="text"
                  />
                </div>
                <div className="phone:mt-2 web:mt-0 web:w-76 phone:w-full">
                  <div>{t("Add-private-ground.Price (Per 1 item)")}</div>
                  <input
                    value={Object.keys(extraOption[0])?.length !== 0 ? inputPriceFormat(extraOption[0]?.price) : ""}
                    // onChange={(e) => {
                    //   setExtraOptionData({
                    //     ...extraOptionData,
                    //     price: inputPriceFormat(e.target.value),
                    //   });
                    // }}
                    onWheel={(e: any) => e.target.blur()}
                    name="price"
                    className="extraInput"
                  />
                </div>
              </div>
              <div className="flex web:flex-row phone:flex-col items-center mt-4 justify-between text-sm">
                <div className="web:w-76 phone:w-full">
                  <div>{t("Add-accommodation.Payment Method")}</div>
                  <select
                    value={Object.keys(extraOption[0])?.length !== 0 ? extraOption[0]?.paymentMethod : ""}
                    // onChange={(e) => {
                    //   setExtraOptionData({
                    //     ...extraOptionData,
                    //     paymentMethod: e.target.value,
                    //   });
                    // }}
                    name="paymentMethod"
                    className="extraInput"
                  >
                    <option value="">{t("Add-accommodation.Select")}</option>
                    <option value={"AVAILABLE_IN_EXTRA_OPTION"}>
                      {t("Extra-payment-method.Available in Extra Option")}
                    </option>
                    <option value={"On-site Payment"}>
                      {t("Extra-payment-method.On-site Payment")}
                    </option>
                  </select>
                </div>
                <div className="phone:mt-2 web:mt-0 web:w-76 phone:w-full">
                  <div>{t("Add-private-ground.Max of Number")}</div>
                  <input
                  value={Object.keys(extraOption[0])?.length !== 0 ? extraOption[0]?.maxOfNumber : ""}
                    // onChange={(e) => {
                    //   setExtraOptionData({
                    //     ...extraOptionData,
                    //     maxOfNumber: Number(e.target.value),
                    //   });
                    // }}
                    onWheel={(e: any) => e.target.blur()}
                    name="maxOfNumber"
                    className="extraInput"
                    type="number"
                  />
                </div>
              </div>
              <AcWrap
                name="description"
                tooltipHidden
                inputValue={Object.keys(extraOption[0])?.length !== 0 ? extraOption[0]?.description : ""}
                // onChange={(e) => {
                //   setExtraOptionData({
                //     ...extraOptionData,
                //     description: e.target.value,
                //   });
                // }}
                placeholder="Add-accommodation.Please describe inclusion of extra option for your traveler"
                miniTitle="Add-private-ground.Description"
                textArea
              />
            </div>
          </div>
        </AcWrap>
        <div className="flex justify-end items-center">
          <Button
            btnText={"Add-accommodation.Save"}
            className="inline-block bg-tan rounded-lg py-3 px-4 text-pure-white"
          />
        </div>
      </div>
    </div>
  );
};

export default EditAcExtraOption;
