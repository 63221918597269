import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  GoogleMap as GMap,
  LoadScript,
  MarkerF as MO_MARKER,
} from "@react-google-maps/api";
import Marker from "../assets/icons/mustoutdoor-logo/Markup-circle.svg";

interface GoogleMapProps {
  lat?: any;
  lng?: any;
  mapLocation?: any | Array<Object>;
  containerStyle?: object;
  mapviewMarkerClickHandler?: Function;
}

const GoogleMap = ({
  mapLocation,
  mapviewMarkerClickHandler,
}: GoogleMapProps) => {

  const google = window.google;

  const GooglecontainerStyle = {
    width: "100%",
    height: "100%",
    padding: "0px 0px 32px",
  };

  const OPTIONS = {
    minZoom: 14,
    maxZoom: 14,
  }

  const icons = {
    url: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/Markup-surround_resize.svg",
    // scaledSize: google && new google.maps.Size(160, 160),
    scale: 10000,
  };

  return (
    <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}`}>
      <GMap
        options={OPTIONS}
        center={isNaN(mapLocation.lat) === false || isNaN(mapLocation.lng) === false ? mapLocation : {lng: 127.027619 , lat: 37.497952}}
        zoom={14}
        mapContainerStyle={GooglecontainerStyle}
      >
        <MO_MARKER
        icon={icons} 
        position={isNaN(mapLocation.lat) === false || isNaN(mapLocation.lng) === false ? mapLocation : {lng: 127.027619 , lat: 37.497952}} />
      </GMap>
    </LoadScript>
  );
};

export default GoogleMap;
