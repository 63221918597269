import React, { useState, useEffect } from "react";

import GoogleMap from "components/GoogleMap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import DetailInfo from "elements/DetailInfo";
import DetailTheme from "elements/DetailTheme";

import { changecase } from "utils/changeCase";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import Button from "elements/Button";
import FullSizeModal from "elements/fullSizeModal";
import Icons from "elements/Icons";
import { changeDate } from "utils/changeDate";
import SideMenu from "elements/sideMenu";

const AcPreview = () => {
  const [previewData, setPreviewData] = useState([]);
  const [spaceDetail, setSpaceDetail] = useState<number[]>([]);

  const [touristModal, setTouristModal] = useState(false);
  const [allPhotoModal, setAllPhotoModal] = useState(false);
  const [touristDetail, setTouristDetail] = useState<any>([]);
  const [newImageList, setNewImageList] = useState<any[]>([]);

  const [spaceImageList, setSpaceImageList] = useState<any[]>([]);
  const [spaceImageListModal, setSpaceImageListModal] = useState(false);

  const [specialFacTab, setSpecialFacTab] = useState(false);
  const [extraTab, setExtraTab] = useState(false);
  const [locationTab, setLocationTab] = useState(false);
  const [policyTab, setPolicyTab] = useState(false);

  const [active, setActive] = useState<any[]>([]);

  const value = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (value?.state) {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v2/accommodation/search/${value?.state}`,
      }).then((res) => {
        const data = res.data.data;
        setPreviewData(data);
        return data;
      });
    } else {
      alert("잘못된 접근 방법입니다");
      navigate("/", { replace: true });
    }
  }, [value?.state]);

  const {
    title,
    spaces,
    hostInfo,
    state,
    city,
    address,
    advanceDate,
    zipCode,
    imageList,
    currentImage,
    locationThemes,
    facilities,
    destinationInfo,
    confirmMethod,
    description,
    policyInfo,
    activities,
    extraAddress,
    specialFacility,
    extraPolicy,
    extraOptions,
    directions,
    latitude,
    longitude,
    advanced,
    touristSpots,
    checkIn,
    checkOut,
  } = previewData as any;

  const create = changeDate(hostInfo?.createdAt).substr(0, 7);

  useEffect(() => {
    if (imageList && currentImage) {
      setNewImageList([currentImage]);
      setNewImageList((pre: any) => [...pre, ...imageList]);
    }
  }, [currentImage, imageList]);

  const typeCategory = spaces?.map((item: any) => {
    const typeInfo = item.typeInfo;
    const category = typeInfo?.category;
    return category;
  });

  const category = typeCategory?.filter(
    (x: string, i: string) => typeCategory?.indexOf(x) === i
  );

  const touristInfo = (title: string, desc: string, imgUrl: string) => {
    setTouristDetail({ title: title, desc: desc, imgUrl: imgUrl });
    setTouristModal(true);
  };

  const allPhotoView = () => {
    setAllPhotoModal((allPhotoModal) => !allPhotoModal);
  };

  return (
    <div>
      <SideMenu
        tabTitle="Product-details.Policy Details"
        setIsOpenTab={setPolicyTab}
        isOpenTab={policyTab}
      >
        <div>
          <span className="text-red">
            {t(
              policyInfo &&
                `Cancellation-policy-data.${changecase(policyInfo.name)}`
            )}
          </span>{" "}
          {t("Product-details.Policy on Cancellation")}
        </div>
        <div className="mt-2">
          {policyInfo?.firstRange !== null
            ? t("Cancellation-policy-data.{{days}}100% refund of the payment", {
                days: policyInfo?.firstRange,
              }) + "\n"
            : ""}
          {policyInfo?.secondRange !== null
            ? t("Cancellation-policy-data.{{days}}70% refund of the payment", {
                days: policyInfo?.secondRange,
              }) + "\n"
            : ""}
          {policyInfo?.thirdRange !== null
            ? t("Cancellation-policy-data.{{days}}50% refund of the payment", {
                days: policyInfo?.thirdRange,
              }) + "\n"
            : ""}
          {policyInfo?.fourthRange !== null
            ? t("Cancellation-policy-data.{{days}}30% refund of the payment", {
                days: policyInfo?.fourthRange,
              }) + "\n"
            : ""}
          {policyInfo?.fifthRange !== null
            ? t("Cancellation-policy-data.{{days}}0% refund of the payment", {
                days: policyInfo?.fifthRange,
              })
            : ""}
        </div>
      </SideMenu>
      <SideMenu
        tabTitle="Product-details.Location"
        setIsOpenTab={setLocationTab}
        isOpenTab={locationTab}
      >
        <div>
          <div className="w-full h-64 my-4">
            <GoogleMap
              mapLocation={{
                lat: Number(`${latitude && latitude}`),
                lng: Number(`${longitude && longitude}`),
              }}
            />
          </div>
          <div className="mt-4">
            <div>{t("Product-details.Location")}</div>
            <div className="text-sm mt-2">
              {t(
                "Product-details.Information provided after booking confirmed"
              )}
            </div>
          </div>
          <hr className="my-4 text-border-light" />
        </div>
        <div>
          <div>
            <div>{t("Product-details.Recommending Tourist Spot")}</div>
            <div className="w-50 h-[161px] shadow-card rounded-lg relative">
              <div
                onClick={() => {
                  touristInfo(
                    touristSpots[0]?.title,
                    touristSpots[0]?.description,
                    touristSpots[0]?.photoUrl
                  );
                }}
                className="opacity-0 hover:opacity-50 flex justify-center  w-full h-32 cursor-pointer items-center hover:bg-black rounded-t-lg absolute"
              >
                <img
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/view-detail.svg"
                  alt=""
                  className="w-8 h-8"
                />
              </div>
              <img
                className="h-32 w-full rounded-t-lg"
                src={touristSpots && touristSpots[0]?.photoUrl}
                alt=""
              />
              <div className="whitespace-nowrap text-ellipsis w-full overflow-hidden h-[33px] leading-[33px] rounded-b-lg px-2 text-sm my-auto">
                {touristSpots && touristSpots[0]?.title}
              </div>
            </div>
          </div>
        </div>
      </SideMenu>
      <SideMenu
        tabTitle="Product-details.Special Facility"
        setIsOpenTab={setSpecialFacTab}
        isOpenTab={specialFacTab}
      >
        {specialFacility ? specialFacility : <div>{t(`Common.N/A`)}</div>}
      </SideMenu>
      <SideMenu
        tabTitle="Product-details.Extra Options"
        setIsOpenTab={setExtraTab}
        isOpenTab={extraTab}
      >
        {extraOptions && !!extraOptions[0]?.title ? (
          extraOptions.map(
            (
              { title, description, maxOfNumber, price, paymentMethod }: any,
              key: number
            ) => {
              return (
                <div key={key} className="last:mb-0 mb-4">
                  <div className="flex justify-between items-center">
                    <div className="mr-2">{title}</div>
                    <div>{price}</div>
                  </div>
                  <div className="flex my-2">
                    {paymentMethod === "On-site Payment" ? (
                      <>
                        <Icons
                          className="mr-2 w-5 h-5"
                          iconName="on-site-payment-only"
                        />
                        <div>{t("Extra-payment-method.On-site Payment")}</div>
                      </>
                    ) : (
                      <>
                        <Icons
                          className="mr-2 w-5 h-5"
                          iconName="available-in-extra-option"
                        />
                        <div>
                          {t("Extra-payment-method.Available in Extra Option")}
                        </div>
                      </>
                    )}
                  </div>
                  <div>{description}</div>
                </div>
              );
            }
          )
        ) : (
          <div>{t(`Common.N/A`)}</div>
        )}
      </SideMenu>

      {spaceImageListModal ? (
        <FullSizeModal
          imgList={spaceImageList}
          isOpen={() => {
            setSpaceImageListModal(
              (spaceImageListModal) => !spaceImageListModal
            );
          }}
        ></FullSizeModal>
      ) : (
        ""
      )}
      {touristModal === true ? (
        <FullSizeModal
          tourist
          TouristTitle={touristDetail?.title}
          TouristDesc={touristDetail?.desc}
          isOpen={() => {
            setTouristModal((touristModal) => !touristModal);
          }}
          imgList={touristDetail?.imgUrl}
        />
      ) : (
        ""
      )}
      {allPhotoModal && newImageList ? (
        <FullSizeModal
          isOpen={() => {
            setAllPhotoModal(!allPhotoModal);
          }}
          imgList={newImageList}
        />
      ) : (
        ""
      )}
      <div className="web:hidden phone:block">
        <div
          onClick={() => {
            allPhotoView();
          }}
          className="w-full h-64 relative"
        >
          <img
            className="object-cover w-full h-full"
            src={newImageList[0]?.url}
            alt=""
          />
          <div className="absolute bottom-4 right-4 z-10 px-4 py-2 font-bold bg-[rgba(0,0,0,.4)] text-pure-white shadow-ms rounded-lg">
            {t("Product-details.View photos")}
          </div>
        </div>
        <div className="pt-4 px-4 pb-[72px] text-left w-full">
          <div className="flex flex-col">
            <div className="text-xl font-bold">{title}</div>
            <div className="flex justify-between">
              <div className="flex items-baseline">
                <img
                  className="w-4 h-4"
                  src={
                    "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/rating-star-e.svg"
                  }
                  alt=""
                />
                <div className="font-bold mx-1">NEW</div>
                <div className="text-xs text-input-text">0 Reviews</div>
              </div>
              <div className="flex">
                <img
                  className="w-6 h-6"
                  src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/wishlist-black.svg`}
                  alt=""
                />
                <img
                  className="w-6 h-6 ml-4"
                  src={`https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/share.svg`}
                  alt=""
                />
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            {category?.map((item: any, idx: number) => {
              return (
                <div className="flex items-center mb-4 text-sm" key={idx}>
                  <img
                    src={
                      item === "Glamping Types"
                        ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/glamping-type.svg"
                        : item === "Caravan Types"
                        ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/caravan-type.svg"
                        : item === "Campground Types"
                        ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/campground-type.svg"
                        : item === "Eco Friendly Types"
                        ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/eco-friendly-type.svg"
                        : ""
                    }
                    className="w-6 h-6 mr-2"
                    alt=""
                  />
                  <div>{t(`Space-type-data.${item}`)}</div>
                </div>
              );
            })}
            <div className="text-sm flex items-center mb-4">
              <img
                className="w-6 h-6 mr-2"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/cancellation-policy.svg"
                alt=""
              />
              <div>
                {t(
                  policyInfo &&
                    `Cancellation-policy-data.${changecase(policyInfo?.name)}`
                )}{" "}
                {t(`Product-details.Refund Policy`)}
              </div>
            </div>
            <div className="text-sm flex items-center mb-4">
              <img
                className="w-6 h-6 mr-2"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                alt=""
              />
              <div>
                {advanceDate
                  ? t("Advance-notice-data.Booking is not available today")
                  : t("Advance-notice-data.Booking is available today")}
              </div>
            </div>
            <div className="text-sm flex items-center">
              <img
                className="w-6 h-6 mr-2"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/confirmation.svg"
                alt=""
              />
              <div>
                {t(
                  confirmMethod &&
                    `Confirmation-data.${changecase(confirmMethod)}`
                )}
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div>{t("Product-details.Description")}</div>
            <div className="text-sm mt-2">{description}</div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div className="text-xl font-bold">
              {t("Product-details.Hosted By")} {hostInfo && hostInfo?.hostName}{" "}
            </div>
            <div className="flex items-start mt-4 text-sm">
              <img
                className="w-12 h-12 mr-4"
                src={
                  hostInfo?.imageUrl
                    ? hostInfo.imageUrl
                    : "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg"
                }
                alt=""
              />
              <div className="flex-1">
                <div className="mb-2">{t("Product-details.Host Verified")}</div>
                <div className="mb-2">
                  {t(`Product-details.Joined in {{Joined_date}}`, {
                    Joined_date: create,
                  })}
                </div>
                <div className="mb-2">
                  {t("Product-details.Response Rate")}: {hostInfo?.resRate}%
                </div>
                <div>
                  {t("Product-details.Language")}: {hostInfo?.languageCode}
                </div>
              </div>
            </div>
            <hr className="my-4 text-border-light" />
          </div>

          <div>
            <div>{t("Product-details.Accommodation Details")}</div>
            <div
              onClick={() => {
                !active.includes(0)
                  ? setActive((active) => [...active, 0])
                  : setActive(active.filter((x) => x !== 0));
              }}
              className="mt-4 cursor-pointer"
            >
              <div>{t(`Product-details.Facilty`)}</div>
              <div
                className={`flex justify-between items-center pb-2 border-border-light border-b`}
              >
                <div
                  className={`flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap`}
                >
                  {facilities && facilities.length !== 0 ? (
                    facilities.map((item: any, key: number) => {
                      const data = item.facilityList;
                      return (
                        <img
                          key={key}
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-6 h-6 mr-4 mt-2 last:mr-0"
                        />
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                </div>
                <img
                  className={`${
                    active.includes(0) ? "rotate-180" : ""
                  } w-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                  alt=""
                />
              </div>
              {active.includes(0) ? (
                <div className="mt-2 h-full transition-all">
                  {facilities && facilities.length !== 0 ? (
                    facilities.map((item: any, key: number) => {
                      const data = item.facilityList;
                      return (
                        <div
                          key={key}
                          className="mb-4 last:mb-0 flex items-center"
                        >
                          <img
                            src={data.iconUrl}
                            alt={data.enName}
                            className={`w-6 h-6`}
                          />
                          <div className="ml-1">
                            {t(`Facility-data.${data.enName}`)}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>{t(`Common.N/A`)}</div>
                  )}
                  <hr className="my-4 text-border-light" />
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <div
                onClick={() => {
                  !active.includes(1)
                    ? setActive((active) => [...active, 1])
                    : setActive(active.filter((x) => x !== 1));
                }}
                className="mt-4 cursor-pointer"
              >
                <div>{t(`Product-details.Activity`)}</div>
                <div
                  className={`flex justify-between items-center pb-2 border-border-light border-b`}
                >
                  <div className="flex items-center noScroll overflow-x-scroll pr-4 whitespace-nowrap">
                    {activities && activities.length !== 0 ? (
                      activities.map((item: any, key: number) => {
                        const data = item.activity;
                        return (
                          <img
                            key={key}
                            src={data.iconUrl}
                            alt={data.enName}
                            className="w-6 h-6 mr-4 mt-2 last:mr-0"
                          />
                        );
                      })
                    ) : (
                      <div>{t(`Common.N/A`)}</div>
                    )}
                  </div>
                  <img
                    className={`${
                      active.includes(1) ? "rotate-180" : ""
                    } w-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-down.svg"
                    alt=""
                  />
                </div>
                {active.includes(1) ? (
                  <div
                    className={`mt-2 ${
                      active.includes(1) ? "block max-h-full" : "hidden max-h-0"
                    } transition-all`}
                  >
                    {activities && activities.length !== 0 ? (
                      activities.map((item: any, key: number) => {
                        const data = item.activity;
                        return (
                          <div
                            key={key}
                            className="mb-4 last:mb-0 flex items-center"
                          >
                            <img
                              src={data.iconUrl}
                              alt={data.enName}
                              className="w-6 h-6"
                            />
                            <div className="ml-1">
                              {t(`Activity-data.${data.enName}`)}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>{t(`Common.N/A`)}</div>
                    )}
                    <hr className="my-4 text-border-light" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div>
              <div
                onClick={() => {
                  setSpecialFacTab(true);
                }}
                className="cursor-pointer mt-4"
              >
                <div className="flex items-center justify-between">
                  <div>{t(`Product-details.Special Facility`)}</div>
                  <img
                    className={`w-3 h-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                    alt=""
                  />
                </div>
                <hr className="my-4 text-border-light" />
              </div>
            </div>
            <div>
              <div
                onClick={() => {
                  setExtraTab(true);
                }}
                className="cursor-pointer mt-4"
              >
                <div className="flex items-center justify-between">
                  <div>{t(`Product-details.Extra Options`)}</div>
                  <img
                    className={`w-3 h-3 transition-transform`}
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                    alt=""
                  />
                </div>
                <hr className="my-4 text-border-light" />
              </div>
            </div>

            <div>
              <div className="font-bold text-xl">
                {t("Product-details.Available Space")}
              </div>
              <div className="text-sm mt-2">{description}</div>
              <hr className="my-4 text-border-light" />
            </div>

            <div>
              <div className="font-bold text-xl">
                {t("Product-details.Location")}
              </div>
              <div className="w-full h-64 my-4">
                <GoogleMap
                  mapLocation={{
                    lat: Number(`${latitude && latitude}`),
                    lng: Number(`${longitude && longitude}`),
                  }}
                />
              </div>
              <div
                onClick={() => {
                  setLocationTab(true);
                }}
                className="flex items-center justify-between cursor-pointer"
              >
                {t("Product-details.Location")}
                <img
                  className={`w-3 h-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                  alt=""
                />
              </div>
              <div className="mt-4">
                {t(
                  "Product-details.Information provided after booking confirmed"
                )}
              </div>

              <hr className="my-4 text-border-light" />
            </div>

            <div>
              <div className="font-bold text-xl">
                {t("Product-details.Policy")}
              </div>
              <div className="text-sm mt-2">
                <div>{t("Product-details.Check In / Out")}</div>
                <div className="my-2 flex items-center justify-between">
                  <div>{t("Product-details.Check in")}</div>
                  <div>{checkIn}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>{t("Product-details.Check out")}</div>
                  <div>{checkOut}</div>
                </div>
              </div>
              <hr className="my-4 text-border-light" />
            </div>
            <div>
              <div
                onClick={() => {
                  setPolicyTab(true);
                }}
                className="flex items-center justify-between cursor-pointer"
              >
                {t("Product-details.Policy Details")}
                <img
                  className={`w-3 h-3 transition-transform`}
                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-right.svg"
                  alt=""
                />
              </div>
              <div className="mt-2">
                <span className="text-red">
                  {t(
                    policyInfo &&
                      `Cancellation-policy-data.${changecase(policyInfo.name)}`
                  )}
                </span>
                {t("Product-details.Policy on Cancellation")}
              </div>
              <hr className="my-4 text-border-light" />
            </div>
          </div>
        </div>
      </div>

      <div className="web:block phone:hidden text-left mx-auto lg:max-w-1216 p-8">
        <div className="grid grid-cols-2 w-full gap-1">
          <div
            className="w-full h-[500px] bg-center bg-cover block"
            style={{
              backgroundImage: `url(${currentImage && currentImage.url})`,
            }}
          />
          <div className="grid relative grid-cols-2 gap-1 grid-rows-2">
            {imageList?.map((item: any, idx: number) => {
              if (idx > 4) {
                return "";
              } else {
                return (
                  <div
                    key={idx}
                    className="bg-center bg-cover"
                    style={{
                      backgroundImage: `url(${item.url})`,
                    }}
                  />
                );
              }
            })}
            <div
              className="absolute z-10 px-4 py-2 font-bold bg-gainsboro bottom-0 right-8 mb-4 cursor-pointer shadow-ms rounded-lg"
              onClick={() => {
                allPhotoView();
              }}
            >
              {/* View {imageList?.length + 1} Photos */}
              {t("Product-details.View photos")}
            </div>
          </div>
        </div>

        <div className="flex pt-8">
          <div className="max-w-[800px]">
            <div className="text-sm">
              Home &nbsp; {">"} &nbsp;
              {destinationInfo && destinationInfo.country} &nbsp; {">"} &nbsp;
              {destinationInfo && destinationInfo.state} &nbsp; {">"} &nbsp;
              {title}
            </div>
            <div className="py-8">
              <div className="font-bold text-3.5xl mb-8">{title}</div>
              <div className="flex flex-wrap justify-start">
                <div className="flex items-center mr-8 mb-8">
                  {category?.map((item: any, idx: number) => {
                    return (
                      <div
                        className="flex items-center mr-8 last:mr-0"
                        key={idx}
                      >
                        <img
                          src={
                            item === "Glamping Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/glamping-types/glamping-type.svg"
                              : item === "Caravan Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/caravan-types/caravan-type.svg"
                              : item === "Campground Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/campground-types/campground-type.svg"
                              : item === "Eco Friendly Types"
                              ? "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/accommodation/ac-space-type/eco-friendly-types/eco-friendly-type.svg"
                              : ""
                          }
                          className="w-5 h-5 mr-2"
                          alt=""
                        />
                        <div>{t(`Space-type-data.${item}`)}</div>
                      </div>
                    );
                  })}
                </div>
                {locationThemes &&
                  locationThemes?.map((item: any, key: any) => {
                    const data = item.themeInfo;
                    return (
                      <div key={key} className="flex items-center mr-8 mb-8">
                        <img
                          src={data?.iconUrl}
                          className="w-5 h-5"
                          alt={data?.displayName}
                        />
                        <div className="ml-2">
                          {t(`Theme-data.${data?.enName}`)}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="flex flex-wrap justify-start">
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/cancellation-policy.svg"
                    className="mr-2 w-5 h-5"
                    alt=""
                  />
                  <p>
                    {t(
                      policyInfo &&
                        `Cancellation-policy-data.${changecase(
                          policyInfo?.name
                        )}`
                    )}{" "}
                    {/* {t(`host.Refund Policy`)} */}
                  </p>
                </div>
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                    className="mr-2 w-5 h-5"
                    alt=""
                  />
                  <p>
                    {t(
                      confirmMethod &&
                        `Confirmation-data.${changecase(confirmMethod)}`
                    )}
                  </p>
                </div>
                <div className="flex items-center mr-8 mb-8">
                  <img
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                    className="mr-2 w-5 h-5"
                    alt=""
                  />
                  <p>
                    {t(
                      advanceDate
                        ? "Advance-notice-data.Available Today"
                        : "Advance-notice-data.Unavailable Today"
                    )}
                  </p>
                </div>
              </div>
              <div className="text-base">{description}</div>
            </div>
            <DetailTheme
              theme={
                t("Product-details.Hosted By") +
                " " +
                `${hostInfo && hostInfo?.name}`
              }
            >
              <DetailInfo imgUrl={hostInfo && hostInfo.imageUrl}>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t("Product-details.Host Verified")}
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t(`Product-details.Joined in {{Joined_date}}`, {
                    Joined_date: create,
                  })}
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] mb-4">
                  {t("Product-details.Response Rate")}: {hostInfo?.resRate}%
                </div>
                <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px]">
                  {t("Product-details.Language")}: {hostInfo?.languageCode}
                </div>
                {/* <div className="max-w-[269px] w-[269px] h-[19px] mr-[31px] ">
                Response Time : {confirmMethod}
              </div> */}
              </DetailInfo>
            </DetailTheme>

            <DetailTheme kor theme="Product-details.Ground Details">
              <DetailInfo title="Product-details.Facilty">
                {facilities && facilities.length !== 0 ? (
                  facilities.map((item: any, key: number) => {
                    const data = item.facilityList;
                    return (
                      <div
                        key={key}
                        className="flex items-center w-[191px] mr-[9px] mb-4 "
                      >
                        <img
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-5 h-5"
                        />
                        <div className="ml-2">
                          {t(`Facility-data.${data.enName}`)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Activity">
                {activities && activities.length !== 0 ? (
                  activities.map((item: any, key: number) => {
                    const data = item.activity;
                    return (
                      <div
                        key={key}
                        className="flex items-center w-[191px] mr-[9px] mb-4 "
                      >
                        <img
                          src={data.iconUrl}
                          alt={data.enName}
                          className="w-5 h-5"
                        />
                        <div className="ml-2">
                          {t(`Activity-data.${data.enName}`)}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo title="Product-details.Special Facility">
                {specialFacility ? (
                  specialFacility
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
              <DetailInfo
                title="Product-details.Extra Options"
                className="flex-col"
              >
                {extraOptions && !!extraOptions[0]?.title ? (
                  extraOptions.map(
                    (
                      {
                        title,
                        description,
                        maxOfNumber,
                        price,
                        paymentMethod,
                      }: any,
                      key: number,
                      item: any
                    ) => {
                      return (
                        <div key={key} className="last:mb-0 mb-4">
                          <div className="flex">
                            {paymentMethod === "On-site Payment" ? (
                              <Icons
                                className="mr-2 mb-2 w-5 h-5"
                                iconName="on-site-payment-only"
                              />
                            ) : (
                              <Icons
                                className="mr-2 mb-2 w-5 h-5"
                                iconName="available-in-extra-option"
                              />
                            )}
                            <div className="mr-2">{title}</div>
                            <div>{price}</div>
                          </div>
                          <div>{description}</div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div>{t(`Common.N/A`)}</div>
                )}
              </DetailInfo>
            </DetailTheme>
            <DetailTheme kor theme="Product-details.Available Space">
              {spaces?.map((item: any, idx: number) => {
                const imageList = item.imageList;
                const image = item.images[0];
                const title = item.title;
                const availableExtraGuest = item.availableExtraGuest;
                const bedAmount = item.bedAmount;
                const minStay = item.minStay;
                const spaceSize = item.spaceSize;
                const typeInfo = item.typeInfo;

                const mon = Number(item.monday);
                const tue = Number(item.tuesday);
                const wed = Number(item.wednesday);
                const thu = Number(item.thursday);
                const fri = Number(item.friday);
                const sat = Number(item.saturday);
                const sun = Number(item.sunday);

                const price = [mon, tue, wed, thu, fri, sat, sun];
                const noZero = price?.filter((x) => x !== 0);
                const minPrice = Math.min.apply(null, noZero as number[]);
                return (
                  <div key={idx}>
                    <div className="w-full h-40 flex border border-input-border-color rounded-lg mb-2">
                      <img
                        onClick={() => {
                          setSpaceImageList(imageList);
                          setSpaceImageListModal(true);
                        }}
                        src={image?.url}
                        alt=""
                        className="w-66 rounded-l-lg h-full cursor-pointer"
                      />
                      <div className="flex-1 p-2 h-40">
                        <div className="text-xl font-bold text-main-green">
                          {title}
                        </div>
                        <div className="flex flex-col h-[112px] justify-end">
                          <div className="flex items-center">
                            <img
                              className="w-5 h-5 mr-2"
                              src={typeInfo?.iconUrl}
                              alt=""
                            />
                            <div>
                              {t(`Space-type-data.${typeInfo?.enName}`)}
                            </div>
                          </div>
                          <div>
                            <div className="flex items-center mt-4">
                              <div className="flex items-center mr-4">
                                <img
                                  className="w-5 h-5 mr-2"
                                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/guest-occupancy.svg"
                                  alt=""
                                />
                                <div>X {availableExtraGuest}</div>
                              </div>
                              <div className="flex items-center mr-4">
                                <img
                                  className="w-5 h-5 mr-2"
                                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/bed.svg"
                                  alt=""
                                />
                                <div>X {bedAmount}</div>
                              </div>
                              <div className="flex items-center mr-4">
                                <img
                                  className="w-5 h-5 mr-2"
                                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/min-stay.svg"
                                  alt=""
                                />
                                <div>X {minStay}</div>
                              </div>
                              <div className="flex items-center mr-4">
                                <img
                                  className="w-5 h-5 mr-2"
                                  src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                                  alt=""
                                />
                                <div>{spaceSize}m²</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-l border-input-border-color w-36 p-2 h-40 flex flex-col justify-between">
                        <div className="text-xl font-bold">
                          {minPrice ? minPrice : 0}{" "}
                          <span className="text-xs">
                            {t(`Product-details./ night`)}
                          </span>
                        </div>
                        <Button
                          onClick={() => {
                            const Index = Number(idx);
                            !spaceDetail.includes(Index)
                              ? setSpaceDetail((spaceDetail) => [
                                  ...spaceDetail,
                                  Index,
                                ])
                              : setSpaceDetail(
                                  spaceDetail.filter((x) => x !== Index)
                                );
                          }}
                          btnText={
                            spaceDetail.includes(Number(idx))
                              ? "Product-details.Cancel"
                              : "Product-details.Select"
                          }
                          className="rounded-lg font-bold bg-light-green text-pure-white py-2"
                        />
                      </div>
                    </div>
                    {spaceDetail.includes(Number(idx)) ? (
                      <div className="mb-2 px-2 py-4 border rounded-lg border-input-border-color">
                        <div className="flex mb-8">
                          <div className="w-48">
                            {t("Product-details.Description")}
                          </div>
                          <div className="max-w-[592px] break-words">
                            {spaces[idx]?.description}
                          </div>
                        </div>
                        <div className="flex mb-8">
                          <div className="w-48">
                            {t("Product-details.Space Type")}
                          </div>
                          <div className="w-[592px] flex-1 flex items-center">
                            <div className="flex-1 flex items-center">
                              <img
                                src={spaces[idx]?.typeInfo?.iconUrl}
                                className="w-5 h-5 mr-2"
                                alt=""
                              />
                              <div>
                                {t(
                                  `Space-type-data.${spaces[idx]?.typeInfo?.enName}`
                                )}
                              </div>
                            </div>
                            <div className="flex-1">
                              Unit : {spaces[idx]?.unitCount} Units
                            </div>
                          </div>
                        </div>
                        <div className="flex mb-8">
                          <div className="w-48">
                            {t("Product-details.Space Details")}
                          </div>
                          <div className="w-[592px]">
                            <div className="flex-1 flex items-center mb-5">
                              <div className="flex-1">
                                {t("Product-details.Number of Guest")}:{" "}
                                {spaces[idx]?.totalGuestAmount}{" "}
                                {t("Product-details.Guests")}
                              </div>
                              <div className="flex-1">
                                {t("Product-details.Number of Bed")}:{" "}
                                {spaces[idx]?.bedAmount}{" "}
                                {t("Product-details.Bed")}
                              </div>
                            </div>
                            <div className="flex-1 flex items-center">
                              <div className="flex-1">
                                {t("Product-details.Minimum Stay")} :{" "}
                                {spaces[idx]?.minStay}{" "}
                                {t("Product-details.Day")}
                              </div>
                              <div className="flex-1">
                                {t("Product-details.Space Size")} :{" "}
                                {spaces[idx]?.spaceSize} m²
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex mb-8">
                          <div className="w-48">
                            {t("Product-details.Extra Guests")}
                          </div>
                          <div className="max-w-[592px] w-full flex-col">
                            <div className="mb-4">
                              {t("Product-details.Available Extra Guests")}:{" "}
                              {spaces[idx]?.availableExtraGuest}{" "}
                              {t("Product-details.Guests")}
                            </div>
                            <div className="flex items-center">
                              <div className="flex-1">
                                {t("Product-details.Adults (Age 13+)")}:{" "}
                                <span className="text-sm">
                                  {spaces[idx]?.adultExtraCharge
                                    ? spaces[idx]?.adultExtraCharge
                                    : "FREE"}
                                </span>
                              </div>
                              <div className="flex-1">
                                {t("Product-details.Children (Ages 2–12)")}:{" "}
                                <span className="text-sm">
                                  {spaces[idx]?.childrenExtraCharge
                                    ? spaces[idx]?.childrenExtraCharge
                                    : "FREE"}
                                </span>
                              </div>
                              <div className="flex-1">
                                {t("Product-details.Infant (Under 2)")}:{" "}
                                <span className="text-sm">
                                  {spaces[idx]?.infantExtraCharge
                                    ? spaces[idx]?.infantExtraCharge
                                    : "FREE"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex mb-8">
                          <div className="w-48">
                            {t("Product-details.Space Details")}
                          </div>
                          <div className="w-[592px]">
                            <div className="flex-1 flex items-center mb-5">
                              <div className="flex-1">
                                {t("Product-details.Parking Type")}
                                <br />
                                <span className="text-sm">
                                  {t(
                                    `Parking-accessible-data.${spaces[idx]?.parkingType}`
                                  )}
                                </span>
                              </div>
                              <div className="flex-1">
                                {t("Product-details.Accessible Vehicle")}
                                <br />
                                <span className="text-sm">
                                  {t(
                                    `Parking-accessible-data.${spaces[idx]?.accessibleVehicle}`
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="flex-1 flex items-center">
                              <div className="flex-1">
                                {t("Product-details.Number of Car")}:{" "}
                                {spaces[idx]?.carAmount}{" "}
                                {t("Parking-accessible-data.Car")}
                              </div>
                              <div className="flex-1">
                                {t("Product-details.Available Extra Car")}:{" "}
                                {spaces[idx]?.extraCarAmount}{" "}
                                {t("Parking-accessible-data.Car")}
                              </div>
                              <div className="flex-1">
                                {t("Product-details.Extra Price / per car")}:
                                {spaces[idx]?.extraCarCharge}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex mb-4">
                          <div className="w-48">
                            {t("Product-details.Amenities")}
                          </div>
                          <div className="w-[592px]">
                            <div className="flex flex-wrap items-center mb-4">
                              {spaces[idx]?.amenityList.map(
                                (item: any, idx: number) => {
                                  const amenityList = item?.amenityList;
                                  return (
                                    <div
                                      key={idx}
                                      className="flex mb-4 w-[196.33px] items-center"
                                    >
                                      <img
                                        src={amenityList.iconUrl}
                                        className="w-5 h-5 mr-2"
                                        alt=""
                                      />
                                      <div>
                                        {t(
                                          `Amenities-data.${amenityList?.enName}`
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex mb-8">
                          <div className="w-48">
                            {t("Product-details.Special Amenities")}
                          </div>
                          <div className="max-w-[592px] break-words">
                            {spaces[idx]?.specialAmenity}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </DetailTheme>
            <DetailTheme theme="Product-details.Location">
              <div className="my-8 h-[400px]">
                <GoogleMap
                  mapLocation={{
                    lat: Number(`${latitude && latitude}`),
                    lng: Number(`${longitude && longitude}`),
                  }}
                />
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Address")}
                </div>
                <div>
                  {zipCode}&nbsp;
                  {destinationInfo && destinationInfo.country}&nbsp;
                  {state}&nbsp;
                  {city}&nbsp;
                  {address}&nbsp;
                  {extraAddress}
                </div>
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.How To Get There")}
                </div>
                <div>
                  {directions ? directions : <div>{t(`Common.N/A`)}</div>}
                </div>
              </div>
              {touristSpots?.length !== 0 ? (
                <div className="flex mb-8">
                  <div className="w-[113px] mr-[87px] whitespace-normal">
                    {t("Product-details.Recommending Tourist Spot")}
                  </div>
                  <div className="w-50 h-[161px] shadow-card rounded-lg relative">
                    <div
                      onClick={() => {
                        touristInfo(
                          touristSpots[0]?.title,
                          touristSpots[0]?.description,
                          touristSpots[0]?.photoUrl
                        );
                      }}
                      className="opacity-0 hover:opacity-50 flex justify-center  w-full h-32 cursor-pointer items-center hover:bg-black rounded-t-lg absolute"
                    >
                      <img
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/view-detail.svg"
                        alt=""
                        className="w-8 h-8"
                      />
                    </div>
                    <img
                      className="h-32 w-full rounded-t-lg"
                      src={touristSpots && touristSpots[0]?.photoUrl}
                      alt=""
                    />
                    <div className="whitespace-nowrap text-ellipsis w-full overflow-hidden h-[33px] leading-[33px] rounded-b-lg px-2 text-sm my-auto">
                      {touristSpots && touristSpots[0]?.title}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </DetailTheme>
            <DetailTheme theme="Product-details.Policy">
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Check In / Out")}
                </div>
                <div className="flex-1">
                  {t("Product-details.Check in")} : {checkIn}
                </div>
                <div className="flex-1">
                  {t("Product-details.Check out")} : {checkOut}
                </div>
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Confirmation")}
                </div>
                <div className="w-[600px]">
                  <div className="mb-4">
                    {t(
                      "Confirmation-data.You will receive booking confirmation-hours",
                      { confirm: "24" }
                    )}
                  </div>
                  <div>
                    {t(
                      "Confirmation-data.In the event that you do not receive an email from us"
                    )}
                  </div>
                </div>
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Advance Notice")}
                </div>
                <div>
                  {!advanced
                    ? t("Advance-notice-data.Booking is not available today")
                    : t("Advance-notice-data.Booking is available today")}
                </div>
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Additional Rule / Policy")}
                </div>
                <div>
                  {extraPolicy ? extraPolicy : <div>{t(`Common.N/A`)}</div>}
                </div>
              </div>
              <div className="flex mb-8">
                <div className="w-[113px] mr-[87px] whitespace-normal">
                  {t("Product-details.Cancellation & Refund Policy")}
                </div>
                <div>
                  <div className="font-bold mb-4 flex">
                    <div className="text-pure-red">
                      {t(
                        policyInfo &&
                          `Cancellation-policy-data.${changecase(
                            policyInfo.name
                          )}`
                      )}{" "}
                    </div>
                    <div>{t("Product-details.Policy on Cancellation")}</div>
                  </div>
                  <div>
                    {policyInfo?.firstRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}100% refund of the payment",
                          { days: policyInfo?.firstRange }
                        ) + "\n"
                      : ""}
                    {policyInfo?.secondRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}70% refund of the payment",
                          { days: policyInfo?.secondRange }
                        ) + "\n"
                      : ""}
                    {policyInfo?.thirdRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}50% refund of the payment",
                          { days: policyInfo?.thirdRange }
                        ) + "\n"
                      : ""}
                    {policyInfo?.fourthRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}30% refund of the payment",
                          { days: policyInfo?.fourthRange }
                        ) + "\n"
                      : ""}
                    {policyInfo?.fifthRange !== null
                      ? t(
                          "Cancellation-policy-data.{{days}}0% refund of the payment",
                          { days: policyInfo?.fifthRange }
                        )
                      : ""}
                  </div>
                </div>
              </div>
            </DetailTheme>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcPreview;
