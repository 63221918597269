import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import HostWrap from "components/Host/hostWrap";

import Button from "elements/Button";

import HostInput from "elements/HostInput";
import ComingSoon from "elements/comingSoon";
import MoblieHeader from "components/moblieHeader";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import HostNotification from "components/Host/hostNotification";
import HostInquiry from "components/Host/hostInquiry";

const MessageBox = () => {
  const { t } = useTranslation();
  const [tabNum, setTabNum] = useState(0);
  const [messageList, setMessageList] = useState<any>([]);
  const tabInfo = [
    { id: 0, title: "Notification" },
    { id: 1, title: "Inquiry" },
  ];
  const ChangeTab = (id: number) => {
    setTabNum(id);
  };

  const hostNum = localStorage.getItem("hostNum");

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/qna/fetch/${hostNum}`,
    }).then((res) => {
      const data = res.data.data;
      setMessageList(data);
      return data;
    });
  }, []);

  return (
    <div>
      <MoblieHeader left center="Message-box.Message Box" centerTitleImage="message" />
      <div className="text-center web:p-8 phone:px-0 phone:pt-0 mx-auto lg:max-w-1216">
        <div className="flex">
          <HostAccount />
          <HostAccountHeader
            titleIcon="message"
            title="Message-box.Message Box"
          >
            <div
              className="border-b w-full h-11 flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll"
            >
              {tabInfo.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      ChangeTab(item.id);
                    }}
                    className={`${
                      item.id === tabNum
                        ? "bg-light-green text-pure-white"
                        : " text-input-text"
                    } cursor-pointer px-4 items-center flex hover:bg-light-green focus:bg-light-green rounded-t-lg hover:text-pure-white`}
                  >
                    {t(`Message-box.${item.title}`)}
                  </div>
                );
              })}
            </div>
            {tabNum === 0 ? (
              <HostNotification />
            ) : tabNum === 1 ? (
              <HostInquiry inquiryList={messageList} />
            ) : (
              ""
            )}
          </HostAccountHeader>
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
