import React, { useEffect, useRef, useState } from "react";

import HostHeader from "components/Host/hostHeader";

import Button from "elements/Button";
import HostWrap from "components/Host/hostWrap";

import HostInput from "elements/HostInput";
import { useNavigate, useLocation } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/store";
import MoblieHeader from "components/moblieHeader";
import Icons from "elements/Icons";

interface InputItem {
  id: number;
  languageCode: string;
}

const HostInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentData = useAppSelector((state) => state.hosting.HostInfo);

  const [hostName, setHostName] = useState("");
  const [hostNumber, setHostNumber] = useState("");
  const [hostCode, setHostCode] = useState("");
  // const [hostCurrency, setHostCurrency] = useState("");
  const [preLangCode, setPreLangcode] = useState("");

  const [isPhoneNum, setIsPhoneNum] = useState("");
  const [codeTrue, setCodeTrue] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const [phoneVerifyed, setPhoneVerifyed] = useState(false);

  const [mobileVerify, setMobileVerify] = useState("");

  const [code, setCode] = useState();
  const [countryCode, setCountryCode] = useState<any>([]);
  const [language, setLanguage] = useState<any>([]);

  const [imgFile, setImgFile] = useState("");

  const [errPhone, setErrPhone] = useState("");
  const [errCountryCode, setErrCountryCode] = useState("");

  const nextID = useRef<number>(1);
  const [inputItem, setInputItem] = useState<InputItem[]>([
    { id: 0, languageCode: "" },
  ]);

  const navigate = useNavigate();
  const { state }: any = useLocation();

  const hostingType = state && state[0];

  const _email = localStorage.getItem("email");
  const _name = localStorage.getItem("name");
  const id = localStorage.getItem("m");

  const refresh = localStorage.getItem("refresh");

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}guest/main/common/code`,
    })
      .then((res) => {
        const currencyData = res.data.data.currency;
        const countryData = res.data.data.countryCodeMap;
        const language = res.data.data.language;

        const countryCurrency = currencyData.map((item: any) => {
          return item.code;
        });
        const languageData = language.map((item: any) => {
          return `${item.name} ${item.originName}`;
        });
        setCountryCode(countryData);
        setCode(countryCurrency);
        setLanguage(languageData);
        return res.data.data;
      })
      .catch((err) => {});
  }, []);

  const onAddDetailDiv = () => {
    const input = {
      id: nextID.current,
      languageCode: "",
    };
    if (nextID.current === 3) {
      return alert("최대 3개까지 가능힙나다.");
    }
    setInputItem([...inputItem, input]);
    nextID.current += 1;
  };

  const handleLanguage = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    if (idx > inputItem.length) return;
    const inputItemCopy: InputItem[] = JSON.parse(JSON.stringify(inputItem));
    inputItemCopy.map((item) => {
      if (e.target.value === item.languageCode) {
        return (e.target.value = "");
      } else {
        inputItemCopy[idx].languageCode = e.target.value;
        setInputItem(inputItemCopy);
      }
    });
  };

  const languages = inputItem.map(({ languageCode }, idx: number) => {
    const value = languageCode.substring(0, 2);
    const obj = value;
    return obj;
  });

  const hostData = {
    bizType: state,
    hasAc: hostingType === "AC" ? true : false,
    hasAt: hostingType === "AT" ? true : false,
    hasPg: hostingType === "PG" ? true : false,
    languageCode: languages,
    phoneNum:
      hostCode.replace(/[[a-zA-Z\s]/g, "") +
      hostNumber.replaceAll(/[^0-9]/g, ""),
    name: hostName,
    countryCode: hostCode.replace(/[[a-zA-Z\s]/g, ""),
    mobile: hostNumber.replaceAll(/[^0-9]/g, ""),
    imageUrl: imgFile,
  };

  const HandleFile = async (e: any) => {
    let files = e.target.files[0];
    const fileSize = files.size;
    const data = {
      multipartFiles: files,
    };
    if (fileSize < 1024 ** 2 * 5) {
      const response = await requestToMustOutdoor({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}guest/upload/file`,
        data,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      });
      const imgUrl = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${response.data.data}`;
      setImgFile(imgUrl);
      return response;
    } else {
      alert("파일 사이즈는 5mb 보다 작아야합니다.");
    }
  };

  const nextPage = () => {
    if (hostName === "") {
      return alert("호스트 이름을 입력해주세요.");
    } else if (hostCode === "") {
      return alert("국가코드를 선택해주세요");
    } else if (hostNumber === "") {
      return alert("휴대폰 번호를 입력해주세요.");
    } else if (phoneVerifyed === false) {
      return alert("휴대폰 인증을 진행해주세요.");
    } else {
      // dispatch(hostInfo(hostData));
      navigate("/business-info", { state: hostData });
    }
  };

  const phoneVerify = () => {
    if (hostCode === "") {
      return alert("국가코드를 선택해주세요");
    } else if (hostNumber === "") {
      setIsPhoneNum("휴대폰 번호를 입력해주세요.");
      setPhoneError(true);
      return;
      // } else if (!phoneCheck(hostNumber.replaceAll(/[^0-9]/g, ""))) {
      //     return;
    } else {
      requestToMustOutdoor({
        method: "POST",
        url: "/api/v1/host/verify/sms",
        data: {
          mobile: hostNumber.replaceAll(/[^0-9]/g, ""),
          mobileCountryCode: hostCode.replace(/[[a-zA-Z\s]/g, ""),
          totalMemberId: Number(id),
        },
      })
        .then((res) => {
          const data = res.data.data;
          setIsPhoneNum("인증번호 전송완료");
          setPhoneError(false);
          return res.data.data;
        })
        .catch((err) => {
          const error = err.response.data;
          const code = error.code;
          const message = error.message;
          const status = error.status;
          if (
            code === "CF10" &&
            message === "이미 존재하는 전화번호입니다." &&
            status === 409
          ) {
            requestToMustOutdoor({
              method: "POST",
              url: "/auth/reissue/token",
              data: { id: Number(id) },
            }).then((res) => {
              const value = res.data.data;
              const mobile = value?.mobile;
              const mobileCountryCode = value?.mobileCountryCode;
              const country = countryCode
                ?.map((item: string) => {
                  if (item.replace(/[[a-zA-Z\s]/g, "") === mobileCountryCode) {
                    return item;
                  }
                })
                .filter((x: any) => x !== undefined);
              setHostNumber(mobile);
              setHostCode(country[0]);
              setPhoneVerifyed(true);
              setPhoneError(false);
              setCodeTrue("인증이 완료되었습니다.");
              setPhoneVerifyed(true);
            });
          }
          setIsPhoneNum("이미 사용중인 휴대폰 번호 입니다.");
          setPhoneError(true);
        });
    }
  };


  const mobileVerifyCode = () => {
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v1/host/verify/code",
      data: { verifyCode: mobileVerify },
    })
      .then((res) => {
        setCodeTrue("인증이 완료되었습니다.");
        setPhoneVerifyed(true);
        setCodeError(false);
        return res.data.data;
      })
      .catch((err) => {
        setCodeTrue("인증 번호를 다시 확인해주세요.");
        setPhoneVerifyed(false);
        setCodeError(true);
      });
  };

  const hiddenInput = React.useRef<any>(null);
  const handleClick = () => {
    hiddenInput.current.click();
  };

  return (
    <div className="text-center web:w-[864px] web:px-[auto] web:mt-[76px] web:pb-[181px] phone:pb-16 mx-auto lg:max-w-1216 xl:max-w-7xl">
      <MoblieHeader left center="Start Hosting" />
      <HostHeader
        title={"AS-Host-info.Start Hosting"}
        subTitle={"AS-Host-info.Host Information"}
      />
      <div className="flex">
        <div className="web:w-160 phone:w-full">
          <div className="text-left">
            <div className="web:hidden phone:block">
              <div className="bg-light-green w-full p-4 flex">
                {imgFile ? (
                  <div>
                    <img
                      className="w-16 h-16 rounded-lg"
                      src={`${imgFile}`}
                      alt=""
                    />
                  </div>
                ) : (
                  <Icons
                    onClick={() => handleClick()}
                    iconName="my-account-avatar"
                    className="fill-pure-white w-16 h-16"
                  />
                )}
                <div className="flex flex-col ml-4 text-pure-white text-left justify-around">
                  <div>{_name}</div>
                  <div className="text-sm">{_email}</div>
                </div>
              </div>
            </div>
            <div className="web:flex phone:hidden">
              <input
                type={"file"}
                ref={hiddenInput}
                accept=".jpeg, .jpg, .png"
                onChange={(e) => {
                  HandleFile(e);
                  e.target.value = "";
                }}
                className="hidden"
              />
              <div>
                {imgFile ? (
                  <div className="w-32 h-32 mt-12 rounded-lg">
                    <img
                      className="w-32 h-32 rounded-lg"
                      src={`${imgFile}`}
                      alt=""
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => handleClick()}
                    className="cursor-pointer w-32 h-32 mt-12 bg-light-green items-center flex rounded-lg"
                  >
                    <Icons
                      className="fill-pure-white h-24 w-24 mx-auto"
                      iconName="my-account-avatar"
                    />
                  </div>
                )}
              </div>
              <div className="ml-4 flex text-2xl flex-col mt-12 py-4">
                <p className="text-2xl mb-6">{_name}</p>
                <p className="text-xl">{_email}</p>
              </div>
            </div>
            <div className="phone:px-4 web:px-0 mx-auto phone:min-w-[320px] phone:max-w-768">
              <div>
                <HostWrap
                  help
                  toolTitle="호스트 이름을 만들어주세요."
                  toolSubTitle="실명을 노출하지 마시고 이니셜 또는 간단한 닉네임을 하나 만들어주세요."
                  mainClass="mt-8"
                  title={t("AS-Host-info.Name of Host*")}
                >
                  <HostInput
                    input
                    size="XL"
                    inputValue={hostName}
                    onChange={(e: any) => {
                      setHostName(e.target.value);
                    }}
                    placeholder={"AS-Host-info.Name of Host*"}
                  />
                </HostWrap>
              </div>
              <HostWrap
                help
                toolTitle="개인 연락처를 남겨주세요."
                toolSubTitle="개인정보 노출은 걱정하지 마세요.  오직  MUST OUTDOOR 관리자와 예약이 확정된 게스트만이 필요시 확인할 수 있는 정보입니다."
                column
                title={t("AS-Host-info.Cellphone Number*")}
              >
                <div className="flex phone:flex-col web:flex-row">
                  <HostInput
                    onChange={(e) => {
                      setHostCode(e.target.value);
                    }}
                    selected={hostCode}
                    options={countryCode}
                    className="web:mr-[30px] phone:mr-4"
                    selectTrans="Country-code"
                    select
                    size="S"
                    placeholder="AS-Host-info.Select"
                  />
                  <HostInput
                    inputOnButton
                    inputButtonOnclick={() => {
                      phoneVerify();
                    }}
                    inputOnButtonText="AS-Host-info.Send"
                    inputBtnClass="web:right-[30px] top-[16px] phone:right-0"
                    input
                    inputValue={hostNumber}
                    type="number"
                    onChange={(e) => {
                      setHostNumber(e.target.value);
                    }}
                    size="L"
                    isError={phoneError}
                  >
                    {isPhoneNum}
                  </HostInput>
                </div>
                <div className="mt-4">
                  <HostInput
                    onChange={(e) => {
                      setMobileVerify(e.target.value);
                    }}
                    inputButtonOnclick={() => {
                      mobileVerifyCode();
                    }}
                    placeholder={t("AS-Host-info.Input Verification Code")}
                    nomargin
                    input
                    type="number"
                    inputMaxLength={6}
                    inputLengthPosition="right-1 bottom-[-25px]"
                    size="XL"
                    inputOnButton
                    inputOnButtonText="AS-Host-info.Verify Code"
                    inputBtnClass="right-0"
                    className=""
                    isError={codeError}
                  >
                    {codeTrue}
                  </HostInput>
                </div>
              </HostWrap>
              <HostWrap
                help
                toolTitle="사용 언어를 설정해주세요."
                toolSubTitle="사용 중인(모국어) 주요 언어를 하나 선택해주세요. 외국어도 가능하신가요? 글로벌 운영을 위한 외국어 선택은 추후 추가될 예정입니다."
                // addBtn
                // onClick={() => {
                //   onAddDetailDiv();
                // }}
                subClass="phone:flex-col web:flex-row"
                // addBtnTitle={"Language"}
                title={t("AS-Host-info.Preferred Language")}
              >
                {inputItem.map((item, idx) => {
                  return (
                    <div key={idx}>
                      <HostInput
                        selected={
                          inputItem[idx].languageCode
                            ? inputItem[idx].languageCode
                            : preLangCode
                        }
                        onChange={(e) => {
                          handleLanguage(e, idx);
                        }}
                        options={language}
                        select
                        size="S"
                        placeholder="AS-Host-info.Select"
                      />
                      {/* <HostInput
                        className="phone:block web:hidden"
                        selected={inputItem[idx].languageCode}
                        onChange={(e) => {
                          handleLanguage(e, idx);
                        }}
                        options={language}
                        select
                        size="XL"
                        placeholder="Select Language"
                      /> */}
                    </div>
                  );
                })}
              </HostWrap>
              {/* <HostWrap
              help
                toolTitle={"사용 통화를 선택해주세요.\n(국가 기준)"}
                toolSubTitle="설정된 통화는 이후 가격 설정과 정산 및 대금 수령에 적용됩니다."
                title={t("profile.Preferred Currency")}
              >
                <HostInput
                  select
                  onChange={(e) => {
                    setHostCurrency(e.target.value);
                  }}
                  selected={hostCurrency ? hostCurrency : currentData?.currency}
                  options={code}
                  size="S"
                  placeholder="Select"
                />
              </HostWrap> */}
              <div className="phone:w-full web:w-160 mt-12 text-right">
                <Button
                  className="rounded-lg bg-light-green py-3 min-w-[111px] text-center inline-block text-pure-white px-4"
                  onClick={() => {
                    nextPage();
                  }}
                  btnText={t("AS-Host-info.Continue")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="phone:hidden web:ml-4 web:w-full"></div>
      </div>
    </div>
  );
};

export default HostInfo;
