export const BANK_NAME = [
    "BUSAN_BANK",
    "CH_BANK",
    "CITI_BANK",
    "CU_BANK",
    "DGB_BANK",
    "FSB_BANK",
    "IBK_BANK",
    "JB_BANK",
    "JEJU_BANK",
    "KAKAO_BANK",
    "KB_BANK",
    "KDB_BANK",
    "KEBHANA_BANK",
    "KFCC_BANK",
    "KJ_BANK",
    "KN_BANK",
    "K_BANK",
    "MG_KFCC_BANK",
    "NH_BANK",
    "NONGHYUP_BANK",
    "POST_BANK",
    "SC_BANK",
    "SHINHAN_BANK",
    "SUHYUP_BANK",
    "WOORI_BANK"    
]