import React from 'react';

import AuthUtil from "components/Auth/AuthUtil";
import HeadLogo from "components/Auth/authLogo";
import Button from "elements/Button";
import { useNavigate } from 'react-router-dom';

const EmailVerifyFailed = () => {
    const navigate = useNavigate();
    return (
        <HeadLogo>
          <AuthUtil
            AuthIcon={"warning-red"}
            Red
            title={"Email verification failed"}
            subTitle={"The email verification link either"}
            and
            content={`Please try again with sign up process`}
          />
          <div className="text-base text-pure-white">
            <Button
              onClick={() => {
                navigate('/sign-up')
              }}
              className="inline-block mx-auto mt-2 mb-4 bg-light-green text-pure-white text-xs rounded-lg py-2 w-[320px]"
              btnText={"Account.Continue to sign up"}
            />
          </div>
        </HeadLogo>
    );
};

export default EmailVerifyFailed;