import React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "elements/Button";
import { isMember } from "lib/isMember";

const StartHost = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div>
      <div className="text-pure-white bg-startHosting bg-cover bg-center web:h-[450px] phone:h-64 web:pr-40 phone:px-4 py-14 text-right">
        <div className="flex flex-row-reverse">
          <Button
            onClick={() => {
              if (!isMember()) {
                alert("로그인이 되어있어야 이용 가능한 서비스 입니다 :)");
                navigate("/log-in", {
                  replace: true,
                  state: { nextPath: "/type-select" },
                });
              } else {
                navigate("/type-select");
              }
            }}
            className="text-center text-base bg-light-green rounded-lg px-4 py-2.5"
            btnText={t("Partner-select.Start Hosting")}
          />
        </div>
        <div className="my-7">
          <p className="web:text-5xl phone:text-xl font-bold">
            {t("Partner-becomehost.It’s time to become a Host now")}
          </p>
        </div>
        <div className="web:text-2xl phone:text-sm">
          {t(
            "Partner-becomehost.Become a host of MUST OUTDOOR just in a few minutes of investment."
          )}
        </div>
      </div>
    </div>
  );
};

export default StartHost;
