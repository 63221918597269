import React from "react";

import { useTranslation } from "react-i18next";

import CardMini from "elements/CardMini";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

import MainCardLayout from "pages/Main/mainCardLayout";
import { useNavigate } from "react-router-dom";

import MainCustomSlider from "components/mainCustomSlider";
import { useQuery } from "react-query";

const PopularDestination = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getMainDestination = async () => {
    const res = await requestToMustOutdoor.get("guest/main/choice/destination");
    return res.data.data;
  };

  const { data: destinationData } = useQuery(
    ["getMainDestination"],
    getMainDestination
  );
  // const getMainAccommodation = async () => {
  //   const res = await requestToMustOutdoor.get("/guest/main/hot-deal");
  //   return res.data.data;
  // };

  // const { data: destinationData } = useQuery(
  //   ["getMainAccommodation"],
  //   getMainAccommodation
  // );

  return (
    <MainCardLayout gainsboro>
      <div className="web:text-[32px] phone:text-xl font-bold w-full mb-4">
        {t("Main.Popular Destinations")}
      </div>
      <div className="pb-10 web:text-2xl phone:text-base">
        {t("Main.Explore & discover")}
      </div>
      <div className="text-center">
        <MainCustomSlider>
          {destinationData?.map((item: any, idx: number) => {
            const name = item.name;
            const city = item.city;
            const desc = item.description;
            const imageUrl = item.imageUrl;
            const featured = item.featured;
            const data = {
              name,
              desc,
              imageUrl,
              city,
            };
            if (featured) {
              return (
                <CardMini
                  onClick={() => {
                    navigate(`search/${item.id}`, { state: data });
                  }}
                  key={idx}
                  title={item.city}
                  image={item.imageUrl}
                />
              );
            }
          })}
        </MainCustomSlider>
      </div>
    </MainCardLayout>
  );
};

export default PopularDestination;
