import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/store";
import { acDetails } from "redux/slice/accommodationSlice";

import AcWrap from "elements/AcWrap";
import Button from "elements/Button";
import CheckBoxDefault from "elements/CheckBoxDefault";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { TextareaReplaceNewLine } from "utils/textareaReplaceNewLine";

interface DetailProps {
  onPre?: any;
  onNext?: any;
  detailData?: any;
}

const AccommodationDetails = ({ onPre, onNext, detailData }: DetailProps) => {
  const [facilityList, setFacilityList] = useState<any>([]);
  const [activityList, setActivityList] = useState<any>([]);
  const [locationThemes, setThemeList] = useState<any>([]);

  const [FacilityIcon, setFacilityIcons] = useState<any>([]);
  const [ActivityIcon, setActivityIcons] = useState<any>([]);
  const [themes, setThemes] = useState<any>([]);
  const [specialFacility, setSpecialFacility] = useState<any>("");

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v2/private-ground/fetch/icons`,
    })
      .then((res) => {
        const data = res.data.data;
        setFacilityIcons(data.facilities);
        setActivityIcons(data.activities);
        setThemes(data.themes);
        return data;
      })
      .catch((err) => {});
  }, []);

  const preData = useAppSelector(
    (state) => state.accommodation.accommodationDetails
  );
  const dispatch = useAppDispatch();

  const page = preData;

  useEffect(() => {
    setFacilityList(page?.facilityIds);
    setActivityList(page?.activityIds);
    setThemeList(page?.themeIds);
    setSpecialFacility(page?.specialFacility);
  }, [
    page?.activityIds,
    page?.facilityIds,
    page?.specialFacility,
    page?.themeIds,
  ]);

  const Facility = (checked: string, item: number) => {
    if (checked) {
      const result = Number(item);
      setFacilityList([...facilityList, result]);
    } else if (!checked) {
      const result = Number(item);
      setFacilityList(facilityList?.filter((el: any) => el !== result));
    }
  };

  const Activities = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setActivityList([...activityList, result]);
    } else if (!checked) {
      const result = Number(item);
      setActivityList(activityList?.filter((el: any) => el !== result));
    }
  };
  const LocationThemeSetting = (checked: string, item: string, e: any) => {
    if (checked) {
      const result = Number(item);
      setThemeList([...locationThemes, result]);
    } else if (!checked) {
      const result = Number(item);
      setThemeList(locationThemes.filter((el: any) => el !== result));
    }
    if (locationThemes.length > 2 && checked) {
      const result = Number(item);
      alert("최대 3개까지 선택 가능합니다");
      setThemeList(locationThemes.filter((el: any) => el !== result));
      e.target.checked = false;
    }
  };

  const data = {
    facilityIds: facilityList,
    activityIds: activityList,
    themeIds: locationThemes,
    specialFacility: TextareaReplaceNewLine(specialFacility),
  };

  const handlePageData = () => {
    detailData(data);
    dispatch(acDetails(data));
    onNext();
  };

  return (
    <div className="py-12 flex">
      <div className="web:w-160 phone:w-full web:mx-0 phone:mx-4">
        <AcWrap
          title="Add-accommodation.Facility"
          help
          toolTitle="Tooltip-add-accommodation.AC detail facility-tooltip title"
          toolSubTitle="Tooltip-add-accommodation.AC detail facility-tooltip desc"
        >
          <div
            className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
          >
            {FacilityIcon?.map((item: any, idx: number) => {
              const iconUrl = item.iconUrl;
              const enName = item.enName;
              const id = item.id;
              return (
                <CheckBoxDefault
                  key={idx}
                  id={id}
                  imageUrl={iconUrl}
                  defaultChecked={
                    page?.facilityIds?.includes(id) === true ? true : false
                  }
                  title={`Facility-data.${enName}`}
                  value={enName}
                  onChange={(e: any) => {
                    Facility(e.target.checked, e.target.id);
                  }}
                />
              );
            })}
          </div>
        </AcWrap>
        <AcWrap
          title="Add-accommodation.Activities"
          help
          toolTitle="Tooltip-add-accommodation.AC detail activity-tooltip title"
          toolSubTitle="Tooltip-add-accommodation.AC detail activity-tooltip desc"
        >
          <div
            className={`web:flex web:flex-wrap phone:flex-col web:flex-row text-sm mt-4`}
          >
            {ActivityIcon?.map((item: any, idx: number) => {
              const iconUrl = item.iconUrl;
              const enName = item.enName;
              const id = item.id;
              return (
                <CheckBoxDefault
                  key={idx}
                  id={id}
                  imageUrl={iconUrl}
                  defaultChecked={
                    page?.activityIds?.includes(id) === true ? true : false
                  }
                  value={enName}
                  title={`Activity-data.${enName}`}
                  onChange={(e: any) =>
                    Activities(e.target.checked, e.target.id)
                  }
                />
              );
            })}
          </div>
        </AcWrap>
        <AcWrap
          title="Add-accommodation.Location Theme*"
          help
          toolTitle="Tooltip-add-accommodation.AC detail theme-tooltip title"
          toolSubTitle="Tooltip-add-accommodation.AC detail theme-tooltip desc"
        >
          <div
            className={`flex web:flex-row phone:flex-col flex-wrap text-sm mt-4`}
          >
            {themes?.map((item: any, idx: number) => {
              const iconUrl = item.iconUrl;
              const enName = item.enName;
              const id = item.id;
              return (
                <CheckBoxDefault
                  key={idx}
                  id={id}
                  imageUrl={iconUrl}
                  defaultChecked={
                    page?.themeIds?.includes(id) === true ? true : false
                  }
                  onChange={(e: any) => {
                    LocationThemeSetting(
                      e.target.checked,
                      e.currentTarget.id,
                      e
                    );
                  }}
                  title={`Theme-data.${enName}`}
                  value={enName}
                />
              );
            })}
          </div>
        </AcWrap>
        <AcWrap
          help
          toolTitle="Tooltip-add-accommodation.AC detail special-tooltip title"
          toolSubTitle="Tooltip-add-accommodation.AC detail special-tooltip desc"
          onChange={(e) => {
            setSpecialFacility(e.target.value);
          }}
          inputValue={specialFacility}
          textArea
          row={7}
          placeholder="• Please describe the special facility for travelers."
          title="Add-accommodation.Special Facility"
          inputMaxLength={500}
        />
        <div className="flex w-full justify-between items-center">
          <Button
            onClick={() => onPre()}
            btnText={"Add-accommodation.Previous"}
            className="inline-block bg-light-green rounded-lg py-3 px-4 text-pure-white"
          />
          <Button
            onClick={() => handlePageData()}
            btnText={"Add-accommodation.Continue"}
            className="inline-block bg-light-green rounded-lg py-3 px-4 text-pure-white"
          />
        </div>
      </div>
    </div>
  );
};

export default AccommodationDetails;
