import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "elements/Button";


import Toggle from "components/toggle";

import { changecase } from "utils/changeCase";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import NewModal from "elements/newModal";
import Icons from "elements/Icons";

interface myprivateCardProps {
  mainImage?: string;
  title?: string;
  type?: any;
  policyName?: any;
  confirmMethod?: any;
  isValue?: any;
  hidden?: boolean | any;
  isPrivate?: boolean;
  advanceDate?: boolean;
  location?: any;
  status?: string;
  // 차박지Id
  id?: string;
}

const MyprivateCard = ({
  mainImage,
  policyName,
  confirmMethod,
  advanceDate,
  isValue,
  title,
  type,
  location,
  isPrivate,
  status,
  hidden,
  id,
}: myprivateCardProps) => {
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const hostNumber = localStorage.getItem("hostNum");

  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    setActive(hidden);
  }, [isValue]);

  const toggleHandler = () => {
    if (active === true) {
      alert("상품이 더이상 노출되지 않습니다.");
    } else {
      alert("이제부터 상품이 여행자에게 노출됩니다.");
    }
    requestToMustOutdoor({
      method: "PATCH",
      url: `api/v2/private-ground/${id}`,
      data: { hostId: hostNumber, hidden: active },
    })
      .then((res) => {
        const data = res.data.data;
        setActive((active) => !active);
        return data;
      })
      .catch((err) => {});
  };

  const outSide = useRef<any>();

  useEffect(() => {
    const clickOutside = (e: any) => {
      if (
        mobileMenu &&
        outSide.current &&
        !outSide.current.contains(e.target)
      ) {
        setMobileMenu(false);
      }
    };

    document.addEventListener("mousedown", clickOutside);

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  });

  return (
    <div className="web:w-full relative web:h-[160px] phone:h-full mt-4 rounded-lg shadow-card flex web:flex-row phone:flex-col">
      {deleteModal ? (
        <NewModal
          setModalOpen={() => {
            setDeleteModal(!deleteModal);
          }}
          onClick={() => {
            requestToMustOutdoor({
              method: "DELETE",
              url: `/api/v2/private-ground/${id}`,
              params: { hostId: Number(hostNumber), id: id },
            })
              .then((res) => {
                navigate("/host/myprivate", { replace: true });
                return res.data.data;
              })
              .catch((err) => {
                return err;
              });
          }}
          cancelButton
          buttonText="Delete"
          buttonColor="bg-pure-red text-pure-white"
          title="Delete registered Private Ground?"
          imageUrl="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
        >
          <div className="text-sm">
            <div className="mb-2 mt-4">
              {t(`My-private-ground.You’ll permanently lose your`)}
            </div>
            <div>- Photos</div>
            <div className="my-2">- Reviews</div>
            <div>- All settings</div>
          </div>
        </NewModal>
      ) : (
        ""
      )}
      <div className="web:w-66 phone:w-full web:h-[160px] phone:h-[192px] web:border-r phone:border-none border-input-border-color">
        {mainImage ? (
          <img
            className="w-full h-full bg-cetner bg-no-repeat web:rounded-l-lg phone:rounded-lg"
            src={mainImage}
            alt=""
          />
        ) : (
          <img
            className="mx-auto my-8 w-24 h-24"
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
            alt=""
          />
        )}
      </div>
      {title && mainImage ? (
        <div className="flex">
          <div className="flex justify-between flex-1">
            <div className="p-2 flex flex-col h-full justify-between">
              <div className="overflow-hidden relative text-ellipsis text-main-green text-xl font-bold whitespace-nowrap phone:w-64 web:w-120">
                {title}
              </div>
              <div>
                <div className="phone:mt-4 web:mt-0 flex">
                  <img src={type?.iconUrl} className="h-5 w-5" alt="" />
                  <div className="ml-2">
                    {t(type && `Private-Type-data.${type?.enName}`)}
                  </div>
                </div>
                <div className="web:my-2 phone:my-4 web:flex web:flex-row phone:flex-col">
                  {location.map((item: any, i: number) => {
                    const data = item?.themeInfo;
                    return (
                      <div
                        key={i}
                        className="flex mr-2 web:mb-0 phone:mb-4 phone:last:mb-0 last:mr-0"
                      >
                        <img
                          src={data?.iconUrl}
                          alt={data?.enName}
                          className={"h-5 w-5 last:mr-0"}
                        />
                        <div className="ml-2">
                          {t(`Theme-data.${data?.enName}`)}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="web:flex web:flex-row phone:flex-col">
                  <div className="flex items-center mr-4 web:mb-0 phone:mb-4">
                    <img
                      className="w-5 h-5 mr-2"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/cancellation-policy.svg"
                      alt=""
                    />
                    <div>
                      {t(
                        policyName &&
                          `Cancellation-policy-data.${changecase(policyName)}`
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mr-4 web:mb-0 phone:mb-4">
                    <img
                      className="w-5 h-5 mr-2"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/confirmation.svg"
                      alt=""
                    />
                    <div>
                      {t(
                        confirmMethod &&
                          `Confirmation-data.${changecase(confirmMethod)}`
                      )}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <img
                      className="w-5 h-5 mr-2"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/booking-available.svg"
                      alt=""
                    />
                    <div>
                      {t(
                        advanceDate
                          ? "Advance-notice-data.Available Today"
                          : "Advance-notice-data.Unavailable Today"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-l web:block phone:hidden w-[92px] text-xs border-input-border-color">
            <div className="h-10 px-2 py-3 border border-input-border-color rounded-tr-lg">
              {t(
                `My-private-ground.${
                  status === "APPROVED" ? "Approved" : "Pending"
                }`
              )}
            </div>
            <div
              onClick={() => {
                navigate("/host/edit/private");
              }}
              className="h-10 px-2 py-3 border border-input-border-color hover:text-light-green cursor-pointer"
            >
              {t("My-private-ground.Edit")}
            </div>
            <div className="cursor-pointer w-full flex items-center px-2 py-3 border border-input-border-color">
              {/* <Toggle onClick={toggleHandler} isActive={active} /> */}
              <div className="h-[14px]"></div>
            </div>
            <div
              onClick={() => {
                if (status === "PENDING") {
                  alert("승인 대기중에는 삭제 불가능합니다");
                } else {
                  setDeleteModal(true);
                }
              }}
              className="h-10 px-2 py-3 border border-input-border-color rounded-br-lg hover:text-pure-red cursor-pointer"
            >
              {t("My-private-ground.Delete")}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center border border-input-border-color web:rounded-r-lg web:rounded-b-none phone:rounded-r-none phone:rounded-b-lg web:w-[588px] phone:w-full phone:py-4 flex flex-col justify-center">
          <div className="text-base">
            <div>
              {t(
                "Add-private-ground.You haven’t registered a private ground yet!"
              )}
            </div>
            <div>
              {t(
                "Add-private-ground.Please add a private ground for registration."
              )}
            </div>
          </div>
          <Button
            onClick={() => {
              navigate("/host/add-private");
            }}
            className="fill-pure-white bg-light-green mx-auto rounded-lg text-pure-white mt-4 items-center text-center px-4 py-3"
            isIcon
            src={"add"}
            btnText={t("Add-private-ground.Add Private Ground")}
          />
        </div>
      )}
      {title && mainImage ? (
        <div className="web:hidden relative phone:flex p-2 items-center justify-between border-t border-input-border-color">
          <div />
          <div>
            {t(
              `My-private-ground.${
                status === "APPROVED" ? "Approved" : "Pending"
              }`
            )}
          </div>
          <Icons
            onClick={() => setMobileMenu((mobileMenu) => !mobileMenu)}
            iconName="more-dots"
          ></Icons>
          {mobileMenu ? (
            <div
              ref={outSide}
              onClick={(e) => {
                if (outSide.current === e.target) {
                  setMobileMenu(false);
                }
              }}
              className="absolute rounded-lg web:hidden phone:block bg-pure-white right-3 top-7 border border-input-border-color text-center"
            >
              <div
                onClick={() => {
                  navigate("/host/edit/private");
                }}
                className="px-4 py-1 cursor-pointer hover:text-light-green"
              >
                {t("My-private-ground.Edit")}
              </div>
              <div
                onClick={() => {
                  if (status === "PENDING") {
                    alert("승인 대기중에는 삭제 불가능합니다");
                  } else {
                    setDeleteModal(true);
                  }
                }}
                className="px-4 py-1 cursor-pointer hover:text-light-green"
              >
                {t("My-private-ground.Delete")}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MyprivateCard;
