/* global google */
import React, { useEffect, useState } from "react";
import {
  GoogleMap as GMap,
  LoadScript,
  MarkerF as MO_MARKER,
} from "@react-google-maps/api";

import { useNavigate } from "react-router-dom";
interface GoogleMapProps {
  lat?: any;
  lng?: any;
  mapLocation?: any | Array<Object>;
  containerStyle?: object;
  setPosition?: { lat: number; lng: number };
  setMouseEnter?: boolean;
}

const GoogleMapMultiMarker = ({
  mapLocation,
  setPosition,
  setMouseEnter,
}: GoogleMapProps) => {
  const navigate = useNavigate();
  const [center, setCenter] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });

  const GooglecontainerStyle = {
    width: "100%",
    height: "100%",
    padding: "0px 0px 32px",
  };
  const google = window.google;

  useEffect(() => {
    setCenter(
      setPosition !== undefined ? { ...setPosition } : { lat: 0, lng: 0 }
    );
  }, [setPosition]);

  useEffect(() => {
    if (setPosition !== undefined && setMouseEnter === true) {
      setCenter({ ...setPosition });
    }
  }, [setMouseEnter, setPosition]);

  const icons = {
    url:
      "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/Markup-surround_resize.svg",
    // scaledSize: google && new google.maps.Size(160, 160),
    scale: 10000,
  };

  const OPTIONS = {
    maxZoom: 14,
    minZoom: 12,
  };
  return (
    <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}`}>
      <GMap
        options={OPTIONS}
        center={center}
        zoom={14}
        mapContainerStyle={GooglecontainerStyle}
      >
        {mapLocation?.map((item: any, idx: number) => {
          const lat = item.lat;
          const lng = item.lng;
          const title = item.title;
          const id = item.id;

          return (
            <MO_MARKER
              key={idx}
              position={{ lat, lng }}
              onMouseOver={() => {
                setCenter({
                  lat: lat,
                  lng: lng,
                });
              }}
              onClick={() => {
                navigate(`/details/${id}`, { state: id });
              }}
              icon={icons}
            ></MO_MARKER>
          );
        })}
      </GMap>
    </LoadScript>
  );
};

export default GoogleMapMultiMarker;
