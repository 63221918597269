import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "redux/store";
import { logout } from "redux/slice/userSlice";

import { requestToMustOutdoor } from "utils/requestToMustOutdoor";

import HostAccount from "components/HostAccount/hostAccount";
import HostAccountHeader from "components/HostAccount/hostAccountHeader";
import HostWrap from "components/Host/hostWrap";
import MoblieHeader from "components/moblieHeader";

import Icons from "elements/Icons";
import Button from "elements/Button";
import HostInput from "elements/HostInput";
import NewModal from "elements/newModal";
import { useQuery } from "react-query";

const MyProfile = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [imgFile, setImgFile] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassWord, setNewPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [deletePassword, setDeletePassword] = useState("");

  const [code, setCode] = useState();
  const [countryCode, setCountryCode] = useState();
  const [language, setLanguage] = useState();
  const [languageName, setLanguageName] = useState();

  const [nickName, setNickName] = useState("");
  const [nation, setNation] = useState("");

  const [currency, setCurrency] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("");

  const [mobileCode, setMobileCode] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [gender, setGender] = useState("");

  const [birth, setBirth] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const navigate = useNavigate();

  const _email = localStorage.getItem("email");
  const _name = localStorage.getItem("name");
  const id = localStorage.getItem("m");

  const genderOption = ["Female", "Male"];

  useEffect(() => {
    const birth =
      year +
      "-" +
      ("00" + month.toString()).slice(-2) +
      "-" +
      ("00" + day).slice(-2);
    setBirth(birth);
  }, [day, month, year]);

  const data = {
    birth: birth,
    countryId: 1,
    currencyId: 2,
    gender: gender,
    image: "",
    language: "KR",
    nickName: nickName,
    totalMemberId: id,
  };

  const changeInfo = () => {
    requestToMustOutdoor({
      method: "POST",
      url: `/api/v1/traveler`,
      data,
    })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {});
  };

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}guest/main/common/code`,
    })
      .then((res) => {
        const value = res.data.data;
        console.log(value)
        const currencyData = value.currency;
        const countryData = value.countryCodeMap;
        const language = value.language;

        const countryCurrency = currencyData.map((item: any) => {
          return t(`Currency.${item.code}`);
        });
        const languageData = language.map((item: any) => {
          return `${item.name} ${item.originName}`;
        });
        const languageName = language.map((item: any) => {
          return item.name;
        });
        setCountryCode(countryData);
        setCode(countryCurrency);
        setLanguage(languageData);
        setLanguageName(languageName);
        return res.data.data;
      })
      .catch((err) => {});
  }, []);

  const YearOption = () => {
    const arr = [];

    const startYear = 1900;
    const endYear = new Date().getFullYear();

    for (let i = endYear; i >= startYear; i--) {
      arr.push(i);
    }

    return arr;
  };

  const monthOption = () => {
    const arr = [];

    for (let i = 1; i <= 12; i++) {
      arr.push(i);
    }
    return arr;
  };

  const dayOption = () => {
    const arr = [];

    for (let i = 1; i <= 31; i++) {
      arr.push(i);
    }
    return arr;
  };

  const HandleFile = async (e: any) => {
    let file = e.target.files[0];

    const data = {
      multipartFiles: file,
    };

    const response = await requestToMustOutdoor({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}guest/upload/file`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    });
    const imgUrl = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${response.data.data}`;
    setImgFile(imgUrl);
    return response;
  };

  const hiddenInput = React.useRef<any>(null);
  const handleClick = (e: any) => {
    hiddenInput.current.click();
  };

  const deleteAccount = () => {
    const deletePasswordCheck = {
      pwd: deletePassword,
    };
    requestToMustOutdoor({
      method: "Delete",
      url: `/api/v1/traveler/delete/${id}`,
      data: deletePasswordCheck,
    })
      .then((res) => {
        dispatch(logout());
        navigate("/", { replace: true });
        return res.data.data;
      })
      .catch((err) => {});
  };

  const changePassword = () => {
    const passwordChangeData = {
      checkNewPw: reEnterPassword,
      newPw: newPassWord,
      presentPw: currentPassword,
      totalMemberId: id,
    };
    requestToMustOutdoor({
      method: "POST",
      url: `/api/v1/host/change/pw`,
      data: passwordChangeData,
    })
      .then((res) => {
        alert("비밀번호 변경이 완료 되었습니다.");
        setPasswordChangeModal(false);
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProfile = () => {
    requestToMustOutdoor({
      method:"GET",
      url:`/api/v1/traveler/traveler-info/${id}`
    })
    .then((res) => {
      return res.data
    })
  }
  
  const {data:profileData} = useQuery("getProfile", () => getProfile())

  console.log(profileData)

  return (
    <div>
      <MoblieHeader
        left
        center="Menu.My Profile"
        centerTitleImage="my-profile"
      />
      <div className="text-center p-8  mx-auto lg:max-w-1216">
        {passwordChangeModal === true ? (
          <NewModal
            onClick={() => changePassword()}
            cancelButton
            title="My-profile.Change Password"
            buttonColor="bg-light-green text-pure-white"
            buttonText={t("My-profile.Change Password")}
            setModalOpen={() => {
              setPasswordChangeModal(!passwordChangeModal);
            }}
          >
            <div>
              <div className="font-bold mb-8">
                <div className="text-left w-80 mx-auto">
                  <div className="mt-8">
                    <div>{t("AS-Host-info.Current Password")}</div>
                    <input
                      onChange={(e) => {
                        setCurrentPassword(e.target.value);
                      }}
                      placeholder="Enter Current Password"
                      className="text-sm pl-4 py-2 rounded-lg w-full mt-2"
                    />
                  </div>
                  <div className="my-8">
                    <div>{t("AS-Host-info.New Password")}</div>
                    <input
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                      placeholder="Enter Password"
                      className="text-sm pl-4 py-2 rounded-lg w-full mt-2"
                    />
                  </div>
                  <div className="mb-8">
                    <div>{t("Account.Re-enter password")}</div>
                    <input
                      onChange={(e) => {
                        setReEnterPassword(e.target.value);
                      }}
                      placeholder="Enter Password"
                      className="text-sm pl-4 py-2 rounded-lg w-full mt-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </NewModal>
        ) : (
          ""
        )}

        {deleteAccountModal === true ? (
          <NewModal
            imageUrl="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-red.svg"
            title="My-profile.Delete your account"
            buttonText={t("My-profile.Delete Account")}
            cancelButton
            buttonColor="bg-light-green text-pure-white"
            setModalOpen={() => {
              setDeleteAccountModal(!deleteAccountModal);
            }}
            onClick={() => deleteAccount()}
          >
            <div className="text-sm">
              <div>
                {t(
                  "My-profile.If you delete your account, please keep the following in mind:"
                )}
              </div>
              <div className="my-4">
                {t("My-profile.Your profile will be permanently deleted")}
              </div>
              <div>
                {t(
                  "My-profile.Please enter your password to delete your account"
                )}
              </div>
            </div>
          </NewModal>
        ) : (
          ""
        )}
        <div className="flex">
          <HostAccount traveler />
          <HostAccountHeader titleIcon="my-profile" title="Menu.My Profile">
            <hr className="web:mb-8 phone:hidden" />
            <div className="flex items-baseline phone:flex-col web:flex-row justify-between web:mt-12 phone:mt-0 max-w-[640px] w-full">
              <div className="flex">
                <input
                  type={"file"}
                  ref={hiddenInput}
                  accept=".jpeg, .jpg, .png"
                  onChange={(e) => {
                    HandleFile(e);
                    e.target.value = "";
                  }}
                  className="hidden"
                />
                <div>
                  {imgFile ? (
                    <div className="web:w-32 web:h-32 phone:w-16 phone:h-16 rounded-lg">
                      <img
                        className="web:w-32 web:h-32 phone:w-16 phone:h-16 rounded-lg"
                        src={`${imgFile}`}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div
                      onClick={(e) => handleClick(e)}
                      className="cursor-pointer web:w-32 web:h-32 phone:w-16 phone:h-16 bg-light-green items-center flex rounded-lg"
                    >
                      <Icons
                        className="fill-pure-white h-24 w-24 mx-auto"
                        iconName={"my-account-avatar"}
                      />
                    </div>
                  )}
                </div>
                <div className="ml-4 flex flex-col justify-around">
                  <p className="web:text-2xl phone:text-base">{_name}</p>
                  <p className="web:text-xl phone:text-xs">{_email}</p>
                </div>
              </div>
              <div
                onClick={() => {
                  setDeleteAccountModal(
                    (deleteAccountModal) => !deleteAccountModal
                  );
                }}
                className="phone:mt-1 inline-flex items-end web:text-xs phone:text-[10px] text-link cursor-pointer"
              >
                {t("My-profile.Delete Account")}
              </div>
            </div>
            <HostWrap title="My-profile.Nickname">
              <HostInput
                onChange={(e) => {
                  setNickName(e.target.value);
                }}
                input
                size="XL"
                placeholder="My-profile.Nickname"
                notitlemargin
              />
            </HostWrap>
            <HostWrap title="My-profile.Nationality">
              <HostInput
                options={languageName}
                onChange={(e) => {
                  setNation(e.target.value);
                }}
                select
                selectTrans="Language"
                placeholder="My-profile.Select"
                size="XL"
              />
            </HostWrap>
            <HostWrap title="My-profile.Contact Number">
              <HostInput
                onChange={(e) => {
                  setMobileCode(e.target.value);
                }}
                options={countryCode}
                select
                selectTrans="Country-code"
                placeholder="My-profile.Select"
                size="S"
              />
              <HostInput
                onChange={(e) => {
                  setPhoneNum(e.target.value);
                }}
                placeholder="My-profile.Contact Number"
                input
                size="L"
              />
            </HostWrap>
            <HostWrap title="My-profile.I am">
              <HostInput
                options={genderOption}
                selectTrans="My-profile"
                onChange={(e) => {
                  setGender(e.target.value);
                }}
                select
                placeholder="My-profile.Select"
                size="XL"
              />
            </HostWrap>
            <HostWrap title="My-profile.Birthday">
              <HostInput
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                select
                placeholder="My-profile.Month"
                options={monthOption()}
                size="S"
              />
              <HostInput
                onChange={(e) => {
                  setDay(e.target.value);
                }}
                select
                placeholder="My-profile.Day"
                options={dayOption()}
                size="S"
              />
              <HostInput
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                select
                placeholder="My-profile.Year"
                options={YearOption()}
                size="S"
              />
            </HostWrap>
            {/* <HostWrap title="I live in">
            <HostInput
              input
              placeholder="e.g. Seoul, South Korea / Osaka, Japan /  Manila, Philippines"
              size="XL"
            />
          </HostWrap>
          <HostWrap title="Tell more about yourself">
            <HostInput
              onChange={(e) => {
                setBio(e.target.value);
              }}
              textarea
              rows={5}
              size="XL"
              placeholder="Try to help other users get to know you. Tell them about you. Share your hobbies, favorite travel destinations, books, movies, food etc."
            />
          </HostWrap> */}
            <HostWrap title={t("My-profile.Preferred Language")}>
              <HostInput
                select
                options={language}
                size="S"
                onChange={(e) => {
                  setPreferredLanguage(e.target.value);
                }}
                placeholder="My-profile.Select"
              />
            </HostWrap>
            <HostWrap title={"My-profile.Preferred Currency"}>
              <HostInput
                select
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
                options={code}
                size="S"
                placeholder="My-profile.Select"
              />
            </HostWrap>
            <div className="inline-block">
              <Button
                onClick={() => {
                  setPasswordChangeModal(
                    (passwordChangeModal) => !passwordChangeModal
                  );
                }}
                src={"password"}
                className="mt-4 px-4 py-3 rounded-lg bg-light-green text-pure-white"
                btnText={t("My-profile.Change Password")}
                isIcon
                Icon="lock"
              />
            </div>
            <HostWrap>
              <div className="inline-block">
                <Button
                  onClick={() => {
                    changeInfo();
                  }}
                  className="px-4 py-3 rounded-lg bg-tan text-pure-white"
                  isIcon
                  src={"save"}
                  iconClassName="fill-pure-white"
                  btnText={t("My-profile.Save")}
                />
              </div>
            </HostWrap>
            {/* <HostWrap title="Preferred Language" addBtn addBtnTitle={"Language"}>
          <HostInput select size="S"/>
          </HostWrap>
          <HostWrap title="Preferred Currency">
          <HostInput select size="S"/>
          </HostWrap> */}
          </HostAccountHeader>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
