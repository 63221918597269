// 메인페이지
import mainSlider1 from 'assets/image/Main-slider-1.jpg'
import mainSlider2 from 'assets/image/Main-slider-2.jpg'

export const reasons = [
  {
    iconSrc: 'about-1',
    title: 'Outdoor Life Search',
    summary: 'From types to destination',
  },
  {
    iconSrc: 'about-2',
    title: 'Simple Step Booking',
    summary: 'Search, Pick, and Reserve',
  },
  {
    iconSrc: 'about-3',
    title: 'Get a Unique Experience',
    summary: 'No more mundane journey',
  }
];


export const mainBackground = [
  "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/adventure_c.jpg",
  "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/campervan_c.jpg",
  "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/camping_c.jpg",
  "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/image/greenhouse_c.jpg",
];
export const card = [
  {
    url: '/product/109204801',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/09/Muuido-Exterior-4-800x600.png',
    address: 'Gapyeong, S. Korea',
    title: 'Kayak',
    features: ['beachFront', 'pool', 'shop', 'wifi'],
    price: 120,
    currency: 'USD',
    stars: 4,
    reviews: 20,
  },
  {
    url: '/product/109204801',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/12/cozy-winter-camping.jpg',
    address: 'Gapyeong, S. Korea',
    title: 'Climbing',
    features: ['beachFront', 'pool', 'shop', 'cafe'],
    price: 80,
    currency: 'USD',
    stars: 3,
    reviews: 10,
  }
]
const kayak = {
  url: '/product/109204801',
  image:
    'http://www.mustoutdoor.com/wp-content/uploads/2017/09/Muuido-Exterior-4-800x600.png',
  address: 'Gapyeong, S. Korea',
  title: 'Kayak',
  features: ['beachFront', 'pool', 'shop', 'wifi'],
  price: 120,
  currency: 'USD',
  stars: 4,
  reviews: 20,
};

const climbing = {
  url: '/product/109204801',
  image:
    'http://www.mustoutdoor.com/wp-content/uploads/2017/12/cozy-winter-camping.jpg',
  address: 'Gapyeong, S. Korea',
  title: 'Climbing',
  features: ['beachFront', 'pool', 'shop', 'cafe'],
  price: 80,
  currency: 'USD',
  stars: 3,
  reviews: 10,
};

export const themes = [
  {
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/09/Muuido-Exterior-1-800x600.png',
    text: `Healing on the beach front
    and
    Blow your mind with waves and wind`,
    title: 'Beach Front',
    items: [kayak, kayak, kayak, kayak],
    url: '/search?keyword=beach',
  },
  {
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2018/01/camping-stove-2.jpg',
    text: `Healing on the mountain
    and
    Blow your mind with green and trees`,
    title: 'Mountain',
    items: [climbing, climbing, climbing, climbing],
    url: '/search?keyword=mountain',
  },
  {
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/09/Muuido-Exterior-1-800x600.png',
    text: `Healing on the beach front
    and
    Blow your mind with waves and wind`,
    title: 'Beach Front 2',
    items: [kayak, kayak, kayak, kayak],
    url: '/search?keyword=beach',
  },
  {
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2018/01/camping-stove-2.jpg',
    text: `Healing on the mountain
    and
    Blow your mind with green and trees`,
    title: 'Mountain 2',
    items: [climbing, climbing, climbing, climbing],
    url: '/search?keyword=mountain',
  },
];

export const searchList = [
  {
    text: 'South Korea',
    value: 'south-korea',
    search: 'South Korea;한국',
  },
  {
    text: 'South Korea, Gyeonggi-do',
    value: 'gyeonggi-do',
    search: 'Gyeonggi-do;경기도',
  },
  {
    text: 'South Korea, Gangwon',
    value: 'gangwon',
    search: 'Gangwon;강원;강원도;',
  },
  {
    text: 'South Korea, Gangwon, Sokcho-si',
    value: 'sokcho-si',
    search: 'Sokcho-si;속초',
  },
];

export const mustPicks = [
  climbing,
  climbing,
  climbing,
  climbing,
  kayak,
  kayak,
  kayak,
  kayak,
];

export const popular = [
  {
    id: '1',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/07/Taean-Dulegil-Main-400x300.jpg',
    title: 'Gapyeong, S. Korea',
    url: '/search?keyword=gapyeong',
  },
  {
    id: '2',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/07/Taean-Dulegil-Main-400x300.jpg',
    title: 'Gapyeong, S. Korea',
    url: '/search?keyword=gapyeong',
  },
  {
    id: '3',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/06/TP-8-400x300.jpeg',
    title: 'Gapyeong1, S. Korea',
    url: '/search?keyword=gapyeong',
  },
  {
    id: '4',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/06/TP-8-400x300.jpeg',
    title: 'Gapyeong2, S. Korea',
    url: '/search?keyword=gapyeong',
  },
  {
    id: '5',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/07/Taean-Dulegil-Main-400x300.jpg',
    title: 'Gapyeong3, S. Korea',
    url: '/search?keyword=gapyeong',
  },
  {
    id: '6',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/06/TP-8-400x300.jpeg',
    title: 'Gapyeong4, S. Korea',
    url: '/search?keyword=gapyeong',
  },
  {
    id: '7',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/07/Taean-Dulegil-Main-400x300.jpg',
    title: 'Gapyeong5, S. Korea',
    url: '/search?keyword=gapyeong',
  },
  {
    id: '8',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/06/TP-8-400x300.jpeg',
    title: 'Gapyeong6, S. Korea',
    url: '/search?keyword=gapyeong',
  },
];

export const hotDeal = [
  climbing,
  climbing,
  kayak,
  kayak,
  climbing,
  climbing,
  kayak,
  kayak,
];

export const stories = [
  {
    id: '1',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/06/TP-8-400x300.jpeg',
    title: 'How to fall in love with Autumn Camping!',
  },
  {
    id: '2',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/06/TP-8-400x300.jpeg',
    title: 'How to fall in love with Autumn Camping!',
  },
  {
    id: '3',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/06/TP-8-400x300.jpeg',
    title: 'How to fall in love with Autumn Camping!',
  },
  {
    id: '4',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/06/TP-8-400x300.jpeg',
    title: 'How to fall in love with Autumn Camping!',
  },
  {
    id: '5',
    image:
      'http://www.mustoutdoor.com/wp-content/uploads/2017/06/TP-8-400x300.jpeg',
    title: 'How to fall in love with Autumn Camping!',
  },
];

export enum SessionIds {
  mustPick = 'MustPick',
  popularDestinations = 'PopularDestinations',
  hotDeal = 'HotDeal',
}
