import { createSlice, PayloadAction } from "@reduxjs/toolkit"; 
import { previewData, privateData } from "redux/action/private";


const initialState:any = {
    detailList: {
    },
    previewData: {

    }
}

const privateSlice:any = createSlice({
    name: 'privateInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(privateData.fulfilled, (state, action) => {
            state.detailList = action.payload
        })
        .addCase(previewData.fulfilled, (state, action) => {
            state.previewData = action.payload
        })
    }
    },
    
);

export default privateSlice.reducer