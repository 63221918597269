import React, { useCallback, useEffect, useState } from "react";

import BookingWrap from "components/Booking/bookingWrap";

import Button from "elements/Button";
import DetailInfo from "elements/DetailInfo";
import HostInput from "elements/HostInput";


import { useLocation, useNavigate, useParams } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import Icons from "elements/Icons";

const BookingVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [phoneValue, setPhoneValue] = useState("");
  const [verifyCode, setVerifyCode] = useState("");

  const [verifyMessage, setVerfyMessage] = useState("");
  const [verifyErr, setVerfyErr] = useState(false);
  const [isVerfy, setisVerfy] = useState(false);

  const { requestPageData, checkData }: any = location.state;
  const phoneNum = requestPageData?.contactNumber;
  const mobileCountryCode = requestPageData?.mobileCountryCode;
  const memberId = requestPageData?.totalMemberId;
  const pgId = requestPageData?.pgId;

  useEffect(() => {
    if (phoneNum && phoneNum.length === 10) {
      setPhoneValue(phoneNum?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    } else if (phoneNum && phoneNum?.length === 11) {
      setPhoneValue(
        phoneNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    } else if (phoneNum && phoneNum?.length === 13) {
      setPhoneValue(
        phoneNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [phoneNum]);

  const verification = () => {
    requestToMustOutdoor({
      method: "POST",
      url: `/api/v1/host/verify/code`,
      data: { verifyCode: verifyCode },
    })
      .then((res) => {
        setVerfyErr(false);
        localStorage.setItem("phone", phoneNum);
        localStorage.setItem("CC", mobileCountryCode);
        setVerfyMessage("맞는 인증코드");
        return res.data.data;
      })
      .catch((err) => {
        setVerfyErr(true);
        setVerfyMessage("틀린 인증코드");
      });
  };

  const bookingCheck = () => {
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v1/booking/pre-booking",
      data: { ...checkData },
    })
      .then((res) => {
        navigate(`/payment/${pgId}`, {
          state: { requestPageData, preChcekData: res.data.data },
          replace: true,
        });
        return res.data.data;
      })
      .catch((err) => {});
  };

  const onChangeVerifyCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentCode = e.target.value;
      if (currentCode === "") {
        setVerfyErr(true);
        setVerfyMessage("인증코드를 입력해주세요");
      } else {
        setVerfyErr(false);
        setVerfyMessage("");
        setVerifyCode(currentCode);
      }
    },
    []
  );

  const sendMobile = () => {
    requestToMustOutdoor({
      method: "POST",
      url: "/api/v1/host/verify/sms",
      data: {
        mobile: requestPageData?.contactNumber,
        mobileCountryCode: requestPageData?.mobileCountryCode,
        totalMemberId: requestPageData?.totalMemberId,
      },
    })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return err;
      });
  };

  return (
    <div className="lg:max-w-1216 px-8 mx-auto pb-16">
      <div className="flex text-3.5xl items-center my-9">
        <Icons iconName="mobile-bookings" className="w-12 h-12 mr-6" />
        Booking Request
      </div>
      <div className="flex">
        <div className="w-[800px]">
          <BookingWrap size="M" title="Verification" className="mb-[67px]">
            <DetailInfo withIcon w276 title="Contact Number">
              <div className="w-full">
                <div className="flex items-center">
                  <div className="font-bold mr-8">
                    {mobileCountryCode} {phoneValue}
                  </div>
                  <div
                    className="font-bold text-sm cursor-pointer text-link"
                    onClick={() => {
                      sendMobile();
                    }}
                  >
                    코드 전송
                  </div>
                </div>
                <div className="text-xs">
                  To verify your phone number, a verification code has been sent
                  to your text message.
                </div>
              </div>
            </DetailInfo>
            <DetailInfo withIcon w276 title="Booking-request.Verification Code">
              <HostInput
                isError={verifyErr}
                inputButtonOnclick={() => {
                  verification();
                }}
                onChange={(e: any) => {
                  onChangeVerifyCode(e);
                }}
                inputOnButton
                inputOnButtonText={"휴대폰 인증"}
                placeholder="AS-Host-info.Input Verification Code"
                input
                size="L"
                nomargin
                inputBtnClass="right-0"
                messageChildren={verifyMessage}
              />
            </DetailInfo>
          </BookingWrap>
        </div>
        <div className="w-80 ml-8">
          <Button
            onClick={() => {
              bookingCheck();
            }}
            className="w-full font-bold rounded-lg bg-light-green text-pure-white text-center py-3"
            btnText={"PAYMENT"}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingVerification;
