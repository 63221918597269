import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { changeDataGetAll, changeDate } from "utils/changeDate";
import MemberInquiryCard from "./memberInquiryCard";

interface MemberInquiryProps {
  inquiryList?: any;
}

const MemberInquiry = ({ inquiryList }: MemberInquiryProps) => {
  const { t } = useTranslation();
  const [respond, setRespond] = useState<any>([]);
  return (
    <div>
      <table>
        <thead className="border-b border-input-border-color">
          <tr>
            <th className="p-4 w-full flex justify-between">
              <div>{t("Message-box.Status")}</div>
              <div>{t("Message-box.Inquiry")}</div>
              <div/>
            </th>
          </tr>
        </thead>
        {inquiryList ? (
          inquiryList?.map((item: any, idx: number) => {
            // console.log(item);
            const answered = item.answered;
            const content = item.content;
            const travelerName = item.travelerName;
            const createdAt = changeDataGetAll(item.createdAt);
            const idxNum = Number(idx);
            const id = item.id;
            const hostId = item.hostId;
            const memberId = item.memberId;
            const answer = item.answer;
            const deleted = item.deleted;
            const hostImage = item.hostImage;
            const updatedAt = changeDataGetAll(item.updatedAt);
            if (deleted === false) {
              return (
                <MemberInquiryCard
                  answer={answer}
                  id={id}
                  memberId={memberId}
                  updatedAt={updatedAt}
                  hostImage={hostImage}
                  hostId={hostId}
                  answered={answered}
                  onClick={() => {
                    !respond.includes(idxNum)
                      ? setRespond([idxNum])
                      : setRespond(respond.filter((x: number) => x !== idxNum));
                  }}
                  createdAt={createdAt}
                  memeberName={travelerName}
                  content={content}
                  key={idx}
                  respond={respond.includes(idxNum)}
                />
              );
            }
          })
        ) : (
          <tr>
            <td>문의내역이 없습니다.</td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default MemberInquiry;
