import React, { useEffect, useState } from "react";
import FaqCard from "components/Info/faqCard";

import { useNavigate } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustOutdoor";
import { useTranslation } from "react-i18next";
import Icons from "elements/Icons";

const Faq = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [forTraveler, setForTraveler] = useState<any>([]);
  const [forHost, setForHost] = useState<any>([]);

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: "/guest/faq/category/fetch",
    }).then((res) => {
      const value = res.data.data;
      value.map((item: any, idx: number) => {
        if (item?.subject === "FOR_TRAVELER") {
          setForTraveler((pre: any) => [...pre, item]);
        } else if (item?.subject === "FOR_PARTNER") {
          setForHost((pre: any) => [...pre, item]);
        }
      });
    });
  }, []);

  return (
    <div className="w-full">
      <div className="w-full h-[320px] bg-faq bg-cover text-pure-white justify-center text-5xl font-bold flex items-center">
        <div>FAQ</div>
      </div>
      <div className="py-8 web:max-w-1216 xl:max-w-1280 px-8 mx-auto text-center">
        <div className="font-bold web:text-[32px] phone:text-base">
          How can we help you? Choose the topic of help you need
        </div>
        <div className="flex justify-between web:flex-row phone:flex-col py-8 w-full">
          {/* 1 */}
          <div className="flex flex-col">
            <div className="w-full web:max-w-[560px] phone:h-full web:h-[176px] mb-4 shadow-card rounded-lg text-left flex justify-between p-4">
            <div className="web:mr-4 phone:mr-2">
                <div className="font-bold web:text-2xl phone:text-base mb-2">{t("faq.For Traveler")}</div>
                <div>
                  {t("faq.Get help and support regarding your booking")}
                </div>
              </div>
              <Icons iconName="for-traveler" className="web:w-[183px] web:h-[144px] phone:w-[95px] phone:h-[120px]" />
            </div>
            {forTraveler?.map((item: any, idx: number) => {
              return (
                <FaqCard
                  className="mb-4 last:mb-0"
                  key={idx}
                  onClick={() => {
                    navigate("detail", { state: item.id });
                  }}
                  faqSize
                  title={item.name}
                  content={item.content}
                />
              );
            })}
          </div>
          {/* 2 */}
          <hr className="my-8 border-input-border-color"/>
          <div className="flex flex-col">
            <div className="w-full web:max-w-[560px] h-full web:h-[176px] mb-4 shadow-card rounded-lg text-left flex justify-between p-4">
            <div className="web:mr-4 phone:mr-2">
                <div className="font-bold web:text-2xl phone:text-base mb-2">{t("faq.For Partner")}</div>
                <div className="text-sm">
                  {t("faq.Get help and support with regard to creating outdoor")}
              </div>
              </div>
              <Icons iconName="for-host" extension="png" className="web:w-[183px] web:h-[144px] phone:w-[128px] phone:h-[100px]" />
            </div>
            {forHost?.map((item: any, idx: number) => {
              return (
                <FaqCard
                  className="mb-4 last:mb-0"
                  onClick={() => {
                    navigate("detail", { state: item.id });
                  }}
                  key={idx}
                  faqSize
                  title={item.name}
                  content={item.content}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
