import React, { useState } from "react";

import AuthUtil from "components/Auth/AuthUtil";
import HeadLogo from "components/Auth/authLogo";
import Button from "elements/Button";
import Input from "elements/Input";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "redux/store";
import { emailCodecheck } from "redux/action/userInfo";
import { useTranslation } from "react-i18next";

const EmailConfirm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [emailCheckCode, setemailCheckCode] = useState("");

  const [emailCheckErr, setemailCheckErr] = useState(false);
  const [emailCheckMessage, setemailCheckMessage] = useState("");

  const data = {
    code: emailCheckCode,
  };

  const _userEmail = useSelector<RootState, string>((state) =>
    state.userinfo.userInfo.email ? state.userinfo.userInfo.email : ""
  );

  const emailConfirm = async () => {
    try {
      await dispatch(emailCodecheck(data))
        .unwrap()
        .then((res) => {
          setemailCheckErr(false);
          navigate("/sign-up/success", { replace: true });
          return res.data.data;
        })
        .catch((err) => {
          setemailCheckErr(true);
          setemailCheckMessage("잘못된 인증번호입니다");
        });
    } catch (err) {
      //console.logerr)
    }
  };

  const userEmail = localStorage.getItem("email");
  const reEmail = localStorage.getItem("VerifyEmail");
  return (
    <div>
      <HeadLogo>
        <AuthUtil
          AuthIcon={"contact-us-sent"}
          title={"Please verify your email"}
          subTitle={"We sent you a verification code in"}
          email={_userEmail ? _userEmail : reEmail}
          content={`Please enter the code you received below to complete signup process`}
        />
        <Input
          onChange={(e) => {
            setemailCheckCode(e.target.value);
          }}
          isError={emailCheckErr}
          inputIcon={undefined}
          placeholder={"Account.Input Verification code"}
        >
          {emailCheckMessage}
        </Input>
        <div className="text-base mt-4 text-pure-white">
          <p>
            {t(`Account.Still can’t find the email?`)}{" "}
            <span className="underline cursor-pointer">
              {t(`Account.Resend Email`)}
            </span>
          </p>
          <Button
            onClick={() => {
              emailConfirm();
            }}
            className="inline-block mx-auto mt-2 mb-4 bg-light-green text-pure-white text-xs rounded-lg py-2 px-[133px]"
            btnText={"Account.Complete"}
          />
          <p>
            {t(`Account.Need help?`)}{" "}
            <span className="underline">{t(`Account.Contact us`)}</span>
          </p>
        </div>
      </HeadLogo>
    </div>
  );
};
export default EmailConfirm;
